import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-create-info',
  templateUrl: './create-info.component.html',
  styleUrls: ['./create-info.component.css'],
  animations: [
    fade
  ]
})
export class CreateInfoComponent implements OnInit {
  _USER_TYPE_ID_PAYMENTPROCESSOR = 50;
  serviceForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  querypaymentProcessorId: number;
  paymentProcessorId: any;
  dataRecords: any;
  sub: Subscription;
  selectProductTypeData;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getPaymentProcessorType();
    this.getProductTypesList();
    this.getPaymentProcessorSystem();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      paymentProcessorTypeId: ['', Validators.required],
      systemId: ['', Validators.required],
      selectProductTypeValue: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }
  backToService(){
    this._location.back();
  }

  getPaymentProcessorType(){
    let payload: any = {};
    this.service.getPmtProcessorTypes({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectProcessorTypeData =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getProductTypesList(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getProductTypes({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectProductTypeData = records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getPaymentProcessorSystem(){
    let payload: any = {};
    this.service.getPmtProcessorSystemList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectPaymentProcessorSystemData =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  OnCreatePaymentProcessor(){
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }

    var currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = this._USER_TYPE_ID_PAYMENTPROCESSOR;
    let payload: any = {
      createdByUserId: currentUser.userId,
      paymentProcessorTypeId: this.serviceForm.value.paymentProcessorTypeId,
      systemId: this.serviceForm.value.systemId,
      productId: this.serviceForm.value.selectProductTypeValue,
      firstName: this.serviceForm.value.firstName,
      middleName: this.serviceForm.value.middleName,
      lastName: this.serviceForm.value.lastName,
      telephone: this.serviceForm.value.telephone,
      address: this.serviceForm.value.address,
      email: this.serviceForm.value.email,
      address2: this.serviceForm.value.address2,
      city: this.serviceForm.value.city,
      country: this.serviceForm.value.country,
      website: this.serviceForm.value.website,
      userTitle: this.serviceForm.value.userTitle,
      loginId: this.serviceForm.value.loginId,
      loginPwd: this.serviceForm.value.password,
      userTypeId: userTypeId
    };

    this.service.createPaymentProcessor(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if(successResponse){
        this.toastr.success(`You have created`, 'Seccessfully')
        this.router.navigate(["dashboard/listPaymentProcessor"])
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
        console.log("onFailure:" + JSON.stringify(failureResponse));
    });
    // console.log("HHHH=>", payload);
  }

}
