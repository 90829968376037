import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'src/app/service/service/spinner.service'
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';

@Component({
  selector: 'app-merchant-biller-table',
  templateUrl: './merchant-biller-table.component.html',
  styleUrls: ['./merchant-biller-table.component.css'],
  animations: [
    fade
  ]
})
export class MerchantBillerTableComponent implements OnInit {
  getForm: FormGroup;
  submitted = false;
  _POS_PURCHASE: any = 1;
  _EPAYMENT: any = 4;

  listData;
  listBody: boolean;
  searchButtonText;
  errorMessageList: boolean;

  statusData;
  selectedValue;

  merchantId: any;
  sub: any;
  message;
  data;

  accountId;
  isTrue = false;

  queryMerchantId: any;
  queryTranStatusId: any;
  selectedMerchantValue: any;
  value = false;
  selectData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  merchantDefAccount = false;
  merchInfo;

  perForm: FormGroup;
  selectedData;


  constructor(
    private _location: Location,
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.merchantId = +params['merchantId'];
        if (Number.isNaN(this.merchantId)) {
          this.merchantId = "";
        } else {
          this.getMerchantBillerList(this.merchantId);
        }
      });
  }

  ngOnInit(): void {
    this.getBillingList();
  }

  createForm() {
    this.perForm = this.fb.group({
      billerId: ['', Validators.required],
    });
  }

  onPrintTables(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();;
  }

  getMerchantBillerList(merchantId) {
    let payload: any = {
      merchantId: merchantId
    };

    this.service.getMerchantBillerList({ payload: payload }).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hideSpinner();
        var response = JSON.parse(res.records);
        this.listBody = true;
        this.listData = response;
        console.log(response)
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        console.log(res.records.message)
        this.message = res.records.message
      }
    })
  }

  getBillingList() {
    let payload: any = {}
    this.spinner.getSpinner();
    this.service.getBillingList({ payload: payload }).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hideSpinner();
        var response = JSON.parse(res.records);
        this.listBody = true;
        this.selectedData = response;
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.message = response.message;
      }
    })
  }

  backToService() {
    this._location.back();
  }

  resetForm() { }

  addMerchantBiller() {
    this.submitted = true;
    if (this.perForm.invalid) return;

    let payload: any = {
      merchantId: this.merchantId,
      billerId: this.perForm.value.billerId
    }

    this.service.addMerchantBiller({ payload: payload }).subscribe((res) => {
      if (res.success) {
        this.toastr.success(`Biller Has Created ${res.records.sno}`, 'Success');
        this.getMerchantBillerList(this.merchantId);
        $('#permissionModel').modal('hide');
      } else {
        this.toastr.error(res.messagem, 'Error');
      }
    })
    console.log("TTTTT => ", payload);
  }

}
