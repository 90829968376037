<div @fade class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Merchant Activation</h4>
                <p class="card-description">
                    Activation
                </p>
                <form class="forms-sample" [formGroup]="getForm" novalidate (ngSubmit)="passMerchantActivation()">

                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <input type="text" class="form-control-sm" formControlName="merchantId"
                                        placeholder="Merchant Id"
                                        [ngClass]="{ 'is-invalid': submitted && getForm.controls.merchantUid.errors }">
                                <div *ngIf="submitted && getForm.controls.merchantUid.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.merchantUid.errors?.required">
                                        Merchant Id is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>