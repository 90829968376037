import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-viewmerchant',
  templateUrl: './viewmerchant.component.html',
  styleUrls: ['./viewmerchant.component.css'],
  animations: [
    fade
  ]
})
export class ViewmerchantComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  getForm: FormGroup;
  querymerchantId: number;
  merchantId: any;
  sub: Subscription;
  queryUserId: number;
  userId: any;
  dataRecords: any;
  submitted = false;
  getSubmitted = false;
  message = 'Data Not Found';
  modal;
  selectData: any;
  selectProductData;
  productId;
  productName;
  distributorId;
  isTrue: boolean = false;
  event;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService,
    private _location: Location
  ) {
    this.updateForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.querymerchantId = +params['merchantId'];
        if (Number.isNaN(this.querymerchantId)) {
          this.merchantId = "";
        } else {
          this.merchantId = this.querymerchantId;
          this.distributorId = this.distributorId;
          // console.log("HHHH => ", this.merchantId)
          this.viewMerchantUserInfo(this.merchantId)
          this.getProductLists();
        }
      });

  }



  updateForm() {
    this.updatesForm = this.fb.group({
      distributorId: ['', Validators.required],
      merchantUId: ['', Validators.required],
      userId: ['', Validators.required],
      merchantIdentifier: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      productId: ['', Validators.required],
      userTitle: ['', Validators.required],
    });
  }


  ngOnInit(): void {
    this.event = 'Edit'
    this.updatesForm.disable()
  }

  onButtonClick(event) {
    if (event == 'Edit') {
      this.event = 'Disabled'
      this.updatesForm.enable()
    } else {
      this.event = 'Edit'
      this.updatesForm.disable()
    }
  }

  getProductLists(productId?) {
    let payload: any = {
      distributorId: 0
    };
    this.spinner.getSpinner();
    this.service.getProductList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      var selectedRecord: any = {};
      // alert(records)
      this.selectProductData = records;

      if (productId != null) {
        this.spinner.hideSpinner();
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.productId == productId) {
            selectedRecord = item;
            break;
          }
        };

        this.productId = selectedRecord.productId;
      } else {
        this.spinner.hideSpinner();
        // this.selectProductData = records;
        this.productId = "";
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  backToService() {
    this._location.back();
  }

  viewMerchantUserInfo(merchantId) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId,
    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      // console.log("UUUU", data)
      // this.productName = data.productName
      this.productId = data.productId
      this.updatesForm = this.fb.group({
        userId: [data.userId, Validators.required],
        merchantIdentifier: [data.merchantIdentifier],

        distributorId: [data.distributorId, Validators.required],
        productId: [data.productId, Validators.required],
        selectedMerchantGroupValue: [data.merchantGroupId, Validators.required],
        merchantUId: [data.merchantUid, Validators.required],
        merchantId: [data.merchantId, Validators.required],
        firstName: [data.firstName, Validators.required],
        middleName: [data.middleName, Validators.required],
        lastName: [data.lastName, Validators.required],
        email: [data.email, [Validators.required, Validators.email]],
        telephone: [data.telephone, Validators.required],
        address: [data.address, Validators.required],
        userTitle: [data.userTitle, Validators.required],
        address2: [data.address2, Validators.required],
        website: [data.website, Validators.required],
        city: [data.city, Validators.required],
        country: [data.country, Validators.required],
        loginId: [data.loginId, Validators.required],
      });
      // this.getProductLists(data.productId);
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  OnUpdateDistributorUser() {
    this.submitted = true;

    if (this.updatesForm.invalid) {
      return;
    }

    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      distributorId: this.updatesForm.value.distributorId,
      merchantId: this.updatesForm.value.merchantId,
      productId: this.updatesForm.value.productId,
      merchantGroupId: "2",
      firstName: this.updatesForm.value.firstName,
      middleName: this.updatesForm.value.middleName,
      lastName: this.updatesForm.value.lastName,
      telephone: this.updatesForm.value.telephone,
      address: this.updatesForm.value.address,
      email: this.updatesForm.value.email,
      address2: this.updatesForm.value.address2,
      city: this.updatesForm.value.city,
      country: this.updatesForm.value.country,
      website: this.updatesForm.value.website,
      userTitle: this.updatesForm.value.userTitle,
    };
    console.log("payload update ", payload)
    this.spinner.getSpinner();
    this.service.updateMerchantUserInfo(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if (successResponse.id) {
        this.spinner.hideSpinner();
        this.toastr.success(`You have updated ${successResponse.id}`, 'Seccessfully')
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

}
