<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth px-0">
      <div class="row w-100 mx-0">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left py-5 px-4 px-sm-5">
            <div class="brand-logo">
              <img
                src="../../../assets/images/{{ tab }}"
                style="height: 70px; width: 70px"
                alt="logo"
              />
            </div>
            <h4>Hello! let's get started</h4>
            <h6 class="font-weight-light">Sign in to continue.</h6>
            <form
              class="pt-3"
              [formGroup]="serviceForm"
              novalidate
              (ngSubmit)="login()"
            >
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Login Id"
                  formControlName="loginId"
                  [ngClass]="{
                    'is-invalid':
                      submitted && serviceForm.controls.loginId.errors
                  }"
                />
                <div
                  *ngIf="submitted && serviceForm.controls.loginId.errors"
                  class="text-danger"
                >
                  <div *ngIf="serviceForm.controls.loginId.errors.required">
                    Login Id is required
                  </div>
                </div>
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="form-control form-control-lg"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  formControlName="password"
                  [ngClass]="{
                    'is-invalid':
                      submitted && serviceForm.controls.password.errors
                  }"
                />
                <div
                  *ngIf="submitted && serviceForm.controls.password.errors"
                  class="text-danger"
                >
                  <div *ngIf="serviceForm.controls.password.errors.required">
                    Password Id is required
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <button
                  type="submit"
                  [disabled]="isLoading"
                  class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                >
                  <span *ngIf="!isLoading"> SIGN IN</span>
                  <span *ngIf="isLoading">
                    <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </button>
                <!-- <button
                  type="submit"
                  class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                >
                  SIGN IN
                </button> -->
              </div>
              <div
                class="my-2 d-flex justify-content-between align-items-center"
              >
                <div class="form-check">
                  <label class="form-check-label text-muted">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      formControlName="isSignIn"
                    />
                    Keep me signed in
                  </label>
                </div>
                <a
                  style="color: green; cursor: pointer"
                  (click)="forgetPassword()"
                  >Forgot password?</a
                >
              </div>
              <!-- <div class="mb-2">
                  <button type="button" class="btn btn-block btn-facebook auth-form-btn">
                    <i class="ti-facebook mr-2"></i>Connect using facebook
                  </button>
                </div> -->
              <div class="text-center mt-4 font-weight-light">
                WaafiPay Admin Portal <a class="text-primary">SafariOne .Inc</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
<app-footer style="margin: 0px !important"> </app-footer>
