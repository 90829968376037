import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ExcelService } from 'src/app/service/service/excel.service';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-topup-withdrawals-summary-table-report.component',
  templateUrl: './topup-withdrawals-summary-table-report.component.html',
  styleUrls: ['./topup-withdrawals-summary-table-report.component.css'],
  animations: [
    fade
  ]
})
export class TopupWithdrawalSummaryTableReportComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  Title;
  lisCustomers;
  ListOfMethods;
  currentRecordId;
  currentCardId;
  selected = 1000;
  // selected = pagination.selected;
  p: number = pagination.p;
  term: string = '';
  currentMobileNumber;
  numberOfdays;
  showForm: string = 'true';
  institutionId;
  tranType;
  startDate;
  endDate;
  distdataList;
  transactions = {};
  transactions1;
  dataSource;
  institutionName;
  resdata;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.route.queryParams.subscribe(params => {
      this.institutionId = params['institutionId'];
      this.institutionName = params['institutionName'];
      this.tranType = params['tranType'];
      this.startDate = params['startDate'];
      this.endDate = params['endDate'];
      this.getTopWithDrawalsSummary(this.institutionId, this.tranType, this.startDate, this.endDate);
    });

  }
  groupByMonthWithSort(data) {
    // Map of month numbers to month names
    const monthNames = {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December"
    };

    // Reverse map of month names to month numbers for sorting
    const monthNumbers = {};
    for (const [num, name] of Object.entries(monthNames)) {
      monthNumbers[name] = num;
    }

    // Sort data by transactionDate in descending order
    const sortedData = data.slice().sort((a, b) => {
      return new Date(b.transactionDate).getTime() - new Date(a.transactionDate).getTime();
    });


    // Group data by month and year
    const groupedData = sortedData.reduce((acc, item) => {
      // Extract year and month from the transactionDate
      const [year, month] = item.transactionDate.split('-').slice(0, 2);
      const key = `${monthNames[month]} ${year}`;

      // Initialize the month key in the accumulator if not already present
      if (!acc[key]) {
        acc[key] = [];
      }

      // Add the item to the corresponding month key
      acc[key].push(item);

      return acc;
    }, {});

    // Sort grouped data by month and year in descending order
    const sortedGroupedData = Object.keys(groupedData)
      .sort((a, b) => {
        const [monthA, yearA]: any = a.split(' ');
        const [monthB, yearB]: any = b.split(' ');
        const monthIndexA = parseInt(monthNumbers[monthA], 10);
        const monthIndexB = parseInt(monthNumbers[monthB], 10);
        if (yearA !== yearB) {
          return yearB - yearA; // Sort by year in descending order
        }
        return monthIndexB - monthIndexA; // Sort by month in descending order
      })
      .reduce((acc, key) => {
        acc[key] = groupedData[key];
        return acc;
      }, {});

    return sortedGroupedData;
  }
  groupedDataBymonth(data) {
    const groupedData = data.reduce((acc, transaction) => {
      // Extract year and month from the settlement_date
      const date = new Date(transaction.transactionDate);
      const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
      // const date = new Date();
      // const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });

      // Initialize an array for the month if it doesn't exist
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }

      // Push the transaction into the appropriate month
      acc[monthYear].push(transaction);

      return acc;
    }, {});

    console.log(groupedData);
  }

  getFilteredTransactions() {
    const startIndex = (this.p - 1) * this.selected;
    const endIndex = startIndex + this.selected;

    return Object.keys(this.transactions).reduce((filtered, month) => {
      filtered[month] = this.transactions[month].slice(startIndex, endIndex);
      return filtered;
    }, {});
  }

  isObjectNotEmpty(obj: any): boolean {

    return obj && Object.keys(obj).length > 0;
  }
  getTotalForMonth(month: string) {
    let totalChargesFees = 0;
    let totalWithdrawReversals = 0;
    let totalWithdrawals = 0;
    let totalTopups = 0;
    let totalTransationCount = 0;
    let totalNetAmount = 0;

    this.transactions[month].forEach(tran => {
      totalTransationCount += tran.transationCount;
      totalTopups += tran.topups;
      totalWithdrawals += tran.withdrawals;
      totalWithdrawReversals += tran.withdrawReversals;
      totalChargesFees += tran.chargesFees;
      totalNetAmount += tran.netAmount;
    });

    return {
      totalTransationCount: totalTransationCount,
      totalTopups: totalTopups.toFixed(2),
      totalWithdrawals: totalWithdrawals.toFixed(2),
      totalWithdrawReversals: totalWithdrawReversals.toFixed(2),
      totalChargesFees: totalChargesFees.toFixed(2),
      totalNetAmount: totalNetAmount.toFixed(2)
    };
  }
  getGrandTotal() {
    let grandTotalChargesFees = 0;
    let grandTotalWithdrawReversals = 0;
    let grandTotalWithdrawals = 0;
    let grandTotalTopups = 0;
    let grandTotalTransationCount = 0;
    let grandTotalNetAmount = 0;

    Object.keys(this.transactions).forEach(month => {
      if (this.transactions[month]) {
        this.transactions[month].forEach(tran => {
          grandTotalTransationCount += tran.transationCount;
          grandTotalTopups += tran.topups;
          grandTotalWithdrawals += tran.withdrawals;
          grandTotalWithdrawReversals += tran.withdrawReversals;
          grandTotalChargesFees += tran.chargesFees;
          grandTotalNetAmount += tran.netAmount;
        });
      }
    });

    return {
      grandTotalTransationCount: grandTotalTransationCount,
      grandTotalTopups: grandTotalTopups.toFixed(2),
      grandTotalWithdrawals: grandTotalWithdrawals.toFixed(2),
      grandTotalWithdrawReversals: grandTotalWithdrawReversals.toFixed(2),
      grandTotalChargesFees: grandTotalChargesFees.toFixed(2),
      grandTotalNetAmount: grandTotalNetAmount.toFixed(2)

    };
  }
  getMonths() {
    if (this.transactions) {
      // alert("yeeeeeeeeees")
      console.log(Object.keys(this.transactions))
      return Object.keys(this.transactions);
    } else {
      // alert('Transactions is undefined')
      console.error('Transactions is undefined');
      return [];
    }
  }

  ngOnInit(): void {
    console.log("Component Initialized");
    this.Title = "Topup Withdrawal Summary Report"
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
    } else {
      this.isThirtParty = false;

    }
  }

  backToService() {
    this._location.back();
  }
  formatTransactionAmount(amount) {
    if (amount === null || amount === undefined || amount === '') {
      return '0.00';
    }
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  getTopWithDrawalsSummary(institutionId, tranType, startDate, endDate) {
    let payload: any = {
      institutionId: institutionId,
      tranType: tranType,
      startDate: startDate,
      endDate: endDate,
    };
    this.spinner.getSpinner();
    this.service.topupWthdrawalsSummary({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        if (successResponse.success) {

          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;
          // this.listData = records;

          this.transactions = this.groupByMonthWithSort(records)
          // console.log("listData", this.listData)
          this.showForm = 'false'

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          this.toastr.error(this.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Data Not Found")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  filterTransactionsByTerm(transactions: any[]): any[] {
    console.log('Search Term:', this.term); // Debugging the search term
    console.log('Transactions:', transactions); // Debugging the data

    // Return the transactions directly if the search term is empty
    if (!this.term || this.term.trim() === '') {
      return transactions;
    }

    // If transactions are null or undefined, return an empty array
    if (!transactions || !Array.isArray(transactions)) {
      return [];
    }

    // Filter based on the term in relevant fields
    return transactions.filter(transaction =>
    (transaction.institutionName?.toLowerCase().includes(this.term.toLowerCase()) ||
      transaction.captureMode?.toLowerCase().includes(this.term.toLowerCase()) ||
      transaction.currencyCode?.toLowerCase().includes(this.term.toLowerCase()) ||
      transaction.transactionDate?.toLowerCase().includes(this.term.toLowerCase()))
    );
  }

  SelectedRecodrd(data) {
    this.currentRecordId = data.recordId;
    this.currentCardId = data.cardId;
    this.currentMobileNumber = data.mobileNumber;
  }

  print() {
    let printContents = document.getElementById('section-one').innerHTML
      + document.getElementById('section-two').innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    setTimeout(() => {
      window.location.reload();
    }, 1000)

  }

  fnPDF() {
    html2canvas(document.getElementById("section-two"), { allowTaint: true }).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) + 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height + i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(`waafipay_${moment().format('MMM Do YYYY')}_summary_report.pdf`);
      setTimeout(() => {
        window.location.reload();
      }, 1000)
      // this._location.back();
    });
  }

  downloadCSV() {
    var list = [];
    this.listData.forEach(function (el) {
      let datalist = {
        tranType: el.tranType,
        walletReference: el.walletReference,
        maskedPan: el.maskedPan,
        transactionAmount: el.transactionAmount,
        transactionCount: el.transactionCount,
        uniqueMerchants: el.uniqueMerchants,
        approved: el.approved,
        declined: el.declined,
        dayDate: el.dayDate
      }
      list.push(datalist);
    })

    var options = {
      title: 'Top Usage By Customers Report',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: true,
      useBom: false,
      headers: [' Tran Type', 'Wallet Reference', 'PAN', 'Amount', 'Transaction Count', 'Unique Merchants', 'Approved', 'Declined', 'Date']
    };
    new Angular2Csv(list, `waafipay_top_usage_customers${moment().format('MMM Do YYYY')}_report`, options);
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.listData, 'waafipay_top_usage_customers');
    // this._location.back();;
  }


}
