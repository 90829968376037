import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
declare var $: any;


@Component({
  selector: 'app-userroles',
  templateUrl: './userroles.component.html',
  styleUrls: ['./userroles.component.css'],
  animations: [
    fade
  ]
})
export class UserrolesComponent implements OnInit {
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  selectBankData;
  selectServiceChargeModeData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getRolesList();
    // this.getBankList();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      roleId: ['',],
      roleName: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getBankList();
  }

  getRolesList() {
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getWebRoleList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  resetForm() {
    this.serviceForm.reset();
  }

  getBankList() {
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getBankList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectBankData = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passPermissionConfig(role) {
    let roleId = role.roleId;
    this.router.navigate(['dashboard/assignRolePermissions'], { queryParams: { roleId } });
  }

  OnCreateRolesConfig() {
    this.submitted = true;

    alert(this.serviceForm.invalid)
    if (this.serviceForm.invalid) {
      return;
    }
    alert("welcome")
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      createdByUserId: currentUser.userId,
      // roleName: [data.roleName, Validators.required ],
      // description: [data.description, Validators.required ],
    };
    // console.log("Payload", payload)
    // this.service.createServiceConfig(payload).subscribe(successResponse => {

    //   if(successResponse.id){
    //     $('#permissionModel').modal('hide');
    //     this.toastr.success("Seccessfully: You have created Id",successResponse.id)
    //     // this.getPermissionSettings()
    //   }else {
    //     this.toastr.error("Something went wrong")
    //   }
    // }, (failureResponse: any) => {
    //   if(failureResponse.status === 401){
    //     window.location.href = 'login';
    //   }else{
    //     console.log("onFailure:" + JSON.stringify(failureResponse));
    //   }
    // });

  }

  viewRoleConfig(data) {
    let payload: any = {
      roleId: data.roleId,
    };
    this.spinner.getSpinner();
    this.service.getWebRoleInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        // var records = JSON.parse(data);
        this.serviceForm = this.fb.group({
          roleId: [data.roleId, Validators.required],
          roleName: [data.roleName, Validators.required],
          description: [data.description, Validators.required],
        });
        // console.log("kkkk", data)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });


  }

  updateRolesConfig() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
    let payload: any = {
      permissionId: this.serviceForm.value.permissionId,
      permissionName: this.serviceForm.value.permissionName,
      description: this.serviceForm.value.description
    };
    // console.log("Payload", payload)
    // this.service.updateWebPermissionInfo(payload).subscribe(successResponse => {

    //   if(successResponse.id){
    //     $('#viewModel').modal('hide');
    //     this.toastr.success("Seccessfully: You have created Id",successResponse.id)
    //     this.getPermissionSettings()
    //   }else {
    //     this.toastr.error("Something went wrong")
    //   }
    // }, (failureResponse: any) => {
    //   if(failureResponse.status === 401){
    //     window.location.href = 'login';
    //   }else{
    //     console.log("onFailure:" + JSON.stringify(failureResponse));
    //   }
    // });
  }


}
