import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "src/app/service/service/service.service";
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-merchant-daily-summary-by-dist-table',
  templateUrl: './merchant-daily-summary-by-dist-table.component.html',
  styleUrls: ['./merchant-daily-summary-by-dist-table.component.css'],
  animations: [
    fade
  ]
})
export class MerchantDailySummaryByDistTableComponent implements OnInit {
  listData;
  listBody: boolean;
  isTrue: boolean = false;
  distributorId;
  startDate;
  endDate;
  currencyId;
  tranTypeGroup;
  message;
  sub: any;
  srcAccountId;
  merchantName;
  distributorName;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.route.queryParams.subscribe(params => {
      this.distributorId = params['distributorId'];
      this.distributorName = params['distName'];
      this.tranTypeGroup = params['tranTypeGroup'];
      this.currencyId = params['currencyId'];
      this.startDate = params['startDate'];
      this.fnMerchantDailySummaryByDist(this.distributorId, this.tranTypeGroup, this.currencyId, this.startDate)
    });

  }


  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }

  onPrintInvoice(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     window.location.reload();;
  }

  fnMerchantDailySummaryByDist(distributorId?, tranTypeGroup?, currencyId?, startDate?) {
 
    let payload: any = {
      distributorId: distributorId,
      tranTypeGroup: tranTypeGroup,
      currencyId: currencyId,
      startDate: startDate,
    };

    this.spinner.getSpinner();
    this.service.getMerchantDailySummaryByDistributor({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.merchantName = records[0].merchantName;
          this.listData = records;
          console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


}
