import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/assets/config/environment';
import { ExpressRequestService } from '../express-request/express-request.service';
import { SessionStorageService } from '../session-storage/session-storage.service';


@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  uri = environment.url;
  data = [
    {
      roleId: 901,
      roleName: "Admin_Role",
      description: "Admin_Role",
      isActive: 1,
      userTypeId: 10
    },
    {
      roleId: 902,
      roleName: "Admin_View_Role",
      description: "Admin_View_Role",
      isActive: 1,
      userTypeId: 11
    },
  ];


  constructor(
    private expressService: ExpressRequestService,
    private sessionStorage: SessionStorageService,
    private http: HttpClient
    // private uri: URL
  ) {
    // localStorage.setItem('userRoles', JSON.stringify(this.data));
  }


  login(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    // console.log("hhhh", data)
    return this.http.post(this.uri + '/login', { encryptedPayload: data }).pipe(map((res: any) => res)
    )
  }
  adminResetPassword(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    // console.log("hhhh", data)
    return this.http.post(this.uri + '/adminResetPassword', { encryptedPayload: data }).pipe(map((res: any) => res)
    )
  }
  verifyTwoFactorAuthentication(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    // console.log("hhhh", data)
    return this.http.post(this.uri + '/verifyTwoFactorAuthentication', { encryptedPayload: data }).pipe(map((res: any) => res)
    )
  }

  resetAdminPasswordUser(payload) {
    console.log("getAdminHis", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/resetAdminPasswordUser', { encryptedPayload: data }).pipe(map((res: any) => res)
    )
  }

  roleMatch(allowedRoles) {
    var isMatch = false;
    var userRoles: any = this.expressService.decryptData(JSON.parse(this.sessionStorage.getPermissionData()));
    allowedRoles.forEach(element => {
      if (userRoles.indexOf(element) > -1) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;

  }

  logout() {
    return this.http.get(this.uri + '/logout').pipe(map(res => {
      if (res.hasOwnProperty("Success")) {
        //this.router.navigate(["/login"]);
        window.location.href = 'login';
      };
    })
    )
  }

  getIpAddress() {
    return this.http.get<any>(this.uri + '/getIpAddress', {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map(res => res));
  }

  isActive(payload) {
    // console.log("getAdminHis", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/isActive', { encryptedPayload: data }).pipe(map((res: any) => res))
  }

  bankDetails() {
    return this.http.get(this.uri + '/companyDetails', {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map(res => res));
  }
  dataLists() {
    return this.http.get(this.uri + '/dataLists', {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map(res => res));
  }
  passwordLockTime() {
    return this.http.get(this.uri + '/passwordLockTime', {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map(res => res));
  }

  getMenu(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getUserWebPermission', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createEventLogs(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createEventLogs', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSysconfigList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSysconfigList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSysconfigParamInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSysconfigParamInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createServiceConfig(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createServiceConfig', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCustomers(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCustomersByMobilenNmber', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateCustomerInfo(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateCustomerInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  cardReprint(payload) {

    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/reprint', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCardSummaryByMonthReport(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCardSummaryByMonthReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getBlockedCardsReport(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getBlockedCardsReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  cardActivityMonitor(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/cardActivityMonitor', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCards(payload) {

    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getcardsByMobileNumber', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getcardinfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getcardinfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getcardtokens(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getcardtokens', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementDetailReport(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementDetailReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementSummaryReport(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementSummaryReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getWaafiQrPaymentsReport(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getWaafiQrPaymentsReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCardEventLogs(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCardEventLogs', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getFailedCardTransactions(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getFailedCardTransactions', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCardStatuses(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCardStatuses', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  resendOtp(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/resendOtp', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  stopCard(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/stopCard', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  CardTokenAccess(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/CardTokenAccess', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  unStopCard(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/unStopCard', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getTopUsageByCustomers(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getTopUsageByCustomers', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  topupWthdrawalsSummary(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/topupWthdrawalsSummary', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getTopUsageByMerchant(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getTopUsageByMerchant', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  issuingSummary(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/issuingSummary', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  usageSummary(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/usageSummary', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCountries(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCountries', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createBranch(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createBranch', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  UpdateCardPersoBranches(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/UpdateCardPersoBranches', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateCardbBranch(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateCardbBranch', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  withDraw(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/withDraw', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getBranches(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getBranches', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getConfigurations(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getConfigurations', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createConfigurations(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createConfigurations', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateConfigurations(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateConfigurations', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getInstitution(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getInstitution', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getInstitutionList(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getInstitutionList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  physicalCardToggle(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/physicalCardToggle', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  customerTransactionsDetail(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/customerTransactionsDetail', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  merchantTransactionsDetail(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/merchantTransactionsDetail', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  topUsageMerchantSummary(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/topUsageMerchantSummary', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  customerActivityReport(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/customerActivityReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPhysicalCardReport(payload) {
    // console.log("hhhh", payload)
    // getUserList
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPhysicalCardReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDetailedAuthorizations(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDetailedAuthorizations', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  usageSummaryByMonth(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/usageSummaryByMonth', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDetailedTopupWithdrawals(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDetailedTopupWithdrawals', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  requestPhysicalCard(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/requestPhysicalCard', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateContactless(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateContactless', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPendingCardRecords(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPendingCardRecords', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getListOfStations(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getListOfStations', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  cardPrint(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/cardPrint', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  cardReplacement(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/cardReplacement', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  cardStatusFromRemote(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/cardStatusFromRemote', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getcardTransactions(payload) {

    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getcardTransactions', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }

  updateCardName(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateCardName', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPrintedPanFileRecords(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPrintedPanFileRecords', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDeliveredCards(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDeliveredCards', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  panFileCreateCouriers(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/panFileCreateCouriers', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantPayments(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantPayments', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementById(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementById', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  retrySettlement(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/retrySettlement', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }

  getDevicePos(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPosDevices', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPosDevicesByMerchantId(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPosDevicesByMerchantId', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createServiceRate(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createServiceRate', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getListServiceChargeModes(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getListServiceChargeModes', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getEventLogList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getEventLogList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getEventLogInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getEventLogInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getRoutesList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getRoutesList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createMerchantPmtProcessor(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createMerchantPmtProcessor', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getUserZoneList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getUserZoneList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantPaymentProvidorList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantPaymentProvidorList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateMerchantPaymentProvider(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateMerchantPaymentProvider', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantsSummary(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantsSummary', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPosMerchantsUsageSummaryReport(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPosMerchantsUsageSummaryReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getRouteInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getRouteInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getBankList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getBankList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getBankInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getBankInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getWebRoleList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getWebRoleList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getUserInfoWithWebroles(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getUserInfoWithWebroles', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateAssignUserRoles(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateAssignUserRoles', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateDistributorUser(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateDistributorUser', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateMerchantUserInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateMerchantUserInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createCustomDistributorUser(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createDistributorUserInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createDistributorUser(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createDistributorUser', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createDistributor(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createDistributor', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createMerchantUser(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createMerchantUser', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getAllPaymentProcessors(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getAllPaymentProcessors', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPaymentProcessorInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPaymentProcessorInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPmtProcessorTypes(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPmtProcessorTypes', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPmtProcessorSystemList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPmtProcessorSystemList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updatePaymentProcessorInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updatePaymentProcessorInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getProductList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getProductList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getProductOwnerList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getProductOwnerList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantInfobyDistributor(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantInfobyDistributor', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getProductTypes(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getProductTypes', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getProductInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getProductInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createProduct(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createProduct', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateProductInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateProductInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getProductService(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getProductService', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getProductServiceInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getProductServiceInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getProductServiceRates(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getProductServiceRates', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getRateTypeList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getRateTypeList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getProdServiceRateInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getProdServiceRateInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementStatusList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementStatusList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlemenInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlemenInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  adminProcessMerchantSettlementRequest(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/adminProcessMerchantSettlementRequest', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getUserAccountInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getUserAccountInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorDefaultAccount(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorDefaultAccount', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateDistributorDefAccountId(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateDistributorDefAccountId', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getUserAccountTypes(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getUserAccountTypes', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getGlAccountList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getGlAccountList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getAdminGlAccountsReport(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getAdminGlAccountsReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  changeUserPassword(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/changeUserPassword', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getAdminGlAccountsActivityReport(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getAdminGlAccountsActivityReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getAdminGlAccountsSummaryReport(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getAdminGlAccountsSummaryReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getAdminGlAccountsSummaryByDay(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getAdminGlAccountsSummaryByDay', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorsAccountList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorsAccountList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateMerchantDefAccountId(payload) {
    // console.log("Tran", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateMerchantDefAccountId', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  enableOrDisableTwoFactorAuth(payload) {
    // console.log("Tran", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/enableOrDisableTwoFactorAuth', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantAccountInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantAccountInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createSettlementRequest(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createSettlementRequest', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementRequestsByMerchant(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementRequestsByMerchant', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementRequestsByDistributor(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementRequestsByDistributor', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementRequestInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementRequestInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createSettlementInvoice(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createSettlementInvoice', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createBank(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createBank', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateBankInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateBankInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantCashiers(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantCashiers', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCashierInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCashierInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createUserAccount(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createUserAccount', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCurrencyList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCurrencyList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getTranTypeGroupList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getTranTypeGroupList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantActivitySummaryByDay(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantActivitySummaryByDay', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantActivitySummaryByMonth(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantActivitySummaryByMonth', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantDailySummaryByDistributor(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantDailySummaryByDistributor', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantCategoryCode(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantCategoryCode', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantMonthlySummaryByDistributor(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantMonthlySummaryByDistributor', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCashierTypeList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCashierTypeList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createCashier(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createCashier', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  reSendMerchantCredentials(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/reSendMerchantCredentials', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  adminProcessSettlementRequest(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/adminProcessSettlementRequest', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  rejectSettlementRequest(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/rejectSettlementRequest', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  userResetPassword(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/userResetPassword', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorCommission(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorCommission', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorsGLaccountslist(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorsGLaccountslist', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  adminDistributorsProcessGLEntry(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/adminDistributorsProcessGLEntry', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantByUID(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantByUID', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantTranHistory(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantTranHistory', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantChargebackReport(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantChargebackReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantChargebackSummaryReport(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantChargebackSummaryReport', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorCommissionActivityReports(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorCommissionActivityReports', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCommissionSummaryByMonth(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCommissionSummaryByMonth', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCommissionSummaryByYear(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCommissionSummaryByYear', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  processingPeriodicSettlementRequest(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/processingPeriodicSettlementRequest', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementAccountInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementAccountInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  merchantSettlementRequest(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/merchantSettlementRequest', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateAccessProfile(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateAccessProfile', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getUserAccessProfileStatus(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getUserAccessProfileStatus', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantSubscriptionTypes(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantSubscriptionTypes', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateMerchantSubscription(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateMerchantSubscription', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantAccountList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantAccountList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantDefaultAccount(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantDefaultAccount', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPaymentProcessorSystemList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPaymentProcessorSystemList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPaymentProcessorList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPaymentProcessorList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPmtProcTransactionSummary(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPmtProcTransactionSummary', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementInvoiceList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementInvoiceList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementInvoiceInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementInvoiceInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  adminReconsileInvoice(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/adminReconsileInvoice', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementRequestListByDash(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementRequestListByDash', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getNumOfMerchant(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getNumOfMerchant', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getNumOfCashiers(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getNumOfCashiers', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getNumOfTranByMonth(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getNumOfTranByMonth', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getNumOfSettlementByMonth(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getNumOfSettlementByMonth', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCountryList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCountryList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getCitiesList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getCitiesList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateDistributorCompanyAddress(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateDistributorCompanyAddress', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDeviceTerminalList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDeviceTerminalList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPosMerchantTransactions(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPosMerchantTransactions', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPosMerchantTranHistory(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPosMerchantTranHistory', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorCompanyAddress(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorCompanyAddress', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPaymentProcessorSystemInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPaymentProcessorSystemInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createPaymentProcessorSystem(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createPaymentProcessorSystem', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createPaymentProcessor(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createPaymentProcessor', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updatePaymentProcessorSystemInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updatePaymentProcessorSystemInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getHtgTransectionInfo(payload) {
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getHtgTransectionInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getTranStatusList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getTranStatusList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantTransactions(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantTransactions', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getUnambiguousTransactions(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getUnambiguousTransactions', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantTranChargeback(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantTranChargeback', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createTransactionChargeBack(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createTransactionChargeBack', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createBillers(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createBillers', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getBillingList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getBillingList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getBillingInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getBillingInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateBillerInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateBillerInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getBillerCategoryList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getBillerCategoryList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantBillerList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantBillerList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  addMerchantBiller(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/addMerchantBiller', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getTransactionInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getTransactionInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantTranCancellationRequests(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantTranCancellationRequests', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getUserInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getUserInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  assignWebPermissions(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/assignWebPermissions', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createMerchantGroup(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createMerchantGroup', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createMerchantSetting(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createMerchantSetting', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createRouteConfig(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createRouteConfig', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateServiceConfig(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateServiceConfig', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateCashierInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateCashierInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getUserList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getUserList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getWebRoleInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getWebRoleInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantSettings(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantSettings', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantGroupInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantGroupInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  assignGroupSettings(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/assignGroupSettings', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateMerchantGroup(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateMerchantGroup', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getMerchantGroupList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getMerchantGroupList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorMerchantList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorMerchantList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorMerchantApproval(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorMerchantApproval', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createWebPermission(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createWebPermission', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createUserInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createUserInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateWebPermissionInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateWebPermissionInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateSystemConfigInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateSystemConfigInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPermissionSettings(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPermissionSettings', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getServiceList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getServiceList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getServiceInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getServiceInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  createSystemConfig(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/createSystemConfig', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getPartnerRevenue(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getPartnerRevenue', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getLiveCycleStatus(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getLiveCycleStatus', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateLiveCycleStatus(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateLiveCycleStatus', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getGlAccountBalanceReports(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getGlAccountBalanceReports', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementModes(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementModes', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getSettlementAccountTypes(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getSettlementAccountTypes', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  addSettlementAccountInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/addSettlementAccountInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  updateSettlementAccountInfo(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/updateSettlementAccountInfo', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getAdminDistributorListByGrouping(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getAdminDistributorListByGrouping', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
  getDistributorList(payload) {
    // console.log("hhhh", payload)
    const data = this.expressService.encryptData(payload);
    return this.http.post(this.uri + '/getDistributorList', { encryptedPayload: data }, {
      headers: new HttpHeaders().set('authorization', this.sessionStorage.getToken()),
    }).pipe(map((res: any) => res)
    )
  }
}
