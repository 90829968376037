import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-gl-account-balance-reports',
  templateUrl: './gl-account-balance-reports.component.html',
  styleUrls: ['./gl-account-balance-reports.component.css'],
  animations: [
    fade
  ]
})
export class GlAccountBalanceReportsComponent implements OnInit {

  getForm: FormGroup;
  getSubmitted = false;
  listBody = false;
  selectData;
  tranStatusId;
  message;
  listData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    // this.getFrom();
    // this.fnGetStatusList();
    this.fnGlAccountBalanceReports();
  }

  ngOnInit(): void {
  }



  fnGlAccountBalanceReports() {
    this.listBody = false;
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getGlAccountBalanceReports({payload: payload}).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      // console.log("Reeq=>", responseObj)
      var data = JSON.parse(responseObj);
      if (data.success) {
        if(!data.data){
          this.spinner.hideSpinner();
          this.listBody = true;
          var records = data.records;
          this.listData = records;
        }else {
          this.message = data.records.message;
        }
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.warning(data.records.message,"Warning")
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      // this.createFailureMessage = true;
      this.toastr.error("Server seems got bad request!");
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  passTransactionInfo(info){
    let tranId = info.transactionId
    this.router.navigate(["dashboard/view/transactionInfo"], { queryParams: { tranId } })
  }

}
