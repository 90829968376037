import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-gl-accounts-summary-by-day',
  templateUrl: './gl-accounts-summary-by-day.component.html',
  styleUrls: ['./gl-accounts-summary-by-day.component.css']
})
export class GlAccountsSummaryByDayComponent implements OnInit {
  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectAccData;
  selectedData;
  selected1Data;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getCurrencyList();
    this.fnGetGlAccountList();
  }

  getFrom() {
    this.getForm = this.fb.group({
      selectedValue: ['', Validators.required],
      date: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  getCurrencyList() {
    let payload: any = {};

    this.service.getCurrencyList({ payload: payload }).subscribe((res) => {
      try {
        if (!res) {
          console.log("Errer")
        } else {
          let resObj = JSON.stringify(res.records)
          let record = JSON.parse(resObj)
          this.selectData = record;
          console.log("Data==>", record)
        }
      } catch (error) {
        console.log("Errer", error)
      }
    })
  }

  fnGetGlAccountList(sourceId?) {
    let payload: any = {};
    // this.getSpinner();
    this.service.getGlAccountList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // this.spinner.hide();
      console.log("data :=> " + data);

      var newrecord: any = {};
      newrecord.accountId = "0";
      newrecord.accountDesc = "All";
      records.push(newrecord);
      var selectedRecord: any = {};
      if (sourceId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.accountId == sourceId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectData = records;
        this.selected1Data = selectedRecord.accountId;
      } else {
        this.selectAccData = records;
        this.selected1Data = newrecord.accountId;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };
  // fnGetAccountTypesList(sourceId?) {
  //   let payload: any = {};
  //   // this.getSpinner();
  //   this.service.getUserAccountTypes({payload: payload}).subscribe(successResponse => {
  //     var responseObj = JSON.stringify(successResponse.records);
  //     var data = JSON.parse(responseObj);
  //     var records = JSON.parse(data);
  //     // this.spinner.hide();
  //     console.log("data :=> " + data);

  //     var newrecord: any = {};
  //     newrecord.accountTypeId = "0";
  //     newrecord.accountTypeDesc = "All";
  //     records.push(newrecord);
  //     var selectedRecord: any = {};
  //     if (sourceId != null) {
  //       var length = records.length;
  //       for (var i = 0; i < length; i++) {
  //         var item = records[i];
  //         if (item.accountTypeId == sourceId) {
  //           selectedRecord = item;
  //           break;
  //         }
  //       };
  //       this.selectData = records;
  //       this.selectedData = selectedRecord.accountTypeId;
  //     } else {
  //       this.selectData = records;
  //       this.selectedData = newrecord.accountTypeId;
  //     };
  //   },(failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       // this.failureMessage = true;
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // };

  passGlAccountSummaryReports() {
    this.submitted = true;

    if (this.getForm.invalid) return;

    let currencyId = this.getForm.value.selectedValue;
    let date = this.getForm.value.date;
    this.router.navigate(["dashboard/view/getGLAccountSummaryByDay"], { queryParams: { currencyId, date } })
  }

}
