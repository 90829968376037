<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">View Bank Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Bank</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate
                            (ngSubmit)="OnUpdateBankInfo()">
                            <div class="card-subtitle">Bank Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Bank Name</label>
                                        <input type="text" class="form-control" formControlName="bankName"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.bankName.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.bankName.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.bankName.errors.required">Bank Name is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="bankCode">Bank Code</label>
                                        <input type="text" class="form-control" formControlName="bankCode"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.bankCode.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.bankCode.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.bankCode.errors.required">Bank Code is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="selectedPaymentProcessorValue">Payment Processor</label>
                                        <select formControlName="selectedPaymentProcessorValue" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedPaymentProcessorValue.errors }">
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.selectedPaymentProcessorValue.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.selectedPaymentProcessorValue.errors.required">Payment Processor
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="myIp">Address</label>
                                        <input type="text" class="form-control" formControlName="address"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.address.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.address.errors.required">Address is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Update</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                            <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button>
                            <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>