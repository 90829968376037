<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Create Product Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Bank</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate
                            (ngSubmit)="OnCreateProductInfo()">
                            <div class="card-subtitle">Bank Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Product Name</label>
                                        <input type="text" class="form-control" formControlName="productName" placeholder="Product Name"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.productName.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.productName.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.productName.errors.required">Product Name is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="bankCode">Product Description</label>
                                        <input type="text" class="form-control" formControlName="productDesc" placeholder="Product Description"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.productDesc.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.productDesc.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.productDesc.errors.required">Product Description is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="selectProductOwnerValue">Product Owner</label>
                                        <select class="form-control" formControlName="selectProductOwnerValue"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectProductOwnerValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectProductOwnerData" [ngValue]="p.userTypeId">
                                                {{p.userTypeName}}
                                            </option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.selectProductOwnerValue.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.selectProductOwnerValue.errors.required">
                                                Select Product
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="selectProductTypeValue">Product Type</label>
                                        <select class="form-control" formControlName="selectProductTypeValue"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectProductTypeValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectProductTypeData" [ngValue]="p.productTypeId">
                                                {{p.productTypeDesc}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.selectProductTypeValue.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.selectProductTypeValue.errors.required">Select Product Type is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>