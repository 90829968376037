<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ Title }}</h4>
          </div>
          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <!-- <div class="row" style="margin-bottom: 0px">
          <div class="col-md-3">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control form-control mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
        </div> -->

        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-2">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
          <div class="col-md-10">
            <form
              class="forms-sample"
              [formGroup]="getDeliveredForm"
              novalidate
              (ngSubmit)="handleSubmit()"
            >
              <div class="row">
                <div class="col-md"></div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="startDate">Start Date</label>
                    <input
                      type="date"
                      class="form-control-sm"
                      formControlName="startDate"
                      placeholder="Start Date"
                    />
                    <div
                      *ngIf="
                        submitted && getDeliveredForm.controls.startDate.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="
                          getDeliveredForm.controls.startDate.errors.required
                        "
                      >
                        Start Date is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="endDate">End Date</label>
                    <input
                      type="date"
                      class="form-control-sm"
                      formControlName="endDate"
                      placeholder="End Date"
                    />
                    <div
                      *ngIf="
                        submitted && getDeliveredForm.controls.endDate.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="
                          getDeliveredForm.controls.endDate.errors.required
                        "
                      >
                        End Date is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md">
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style="margin-top: 30px"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Card Holder Name</th>
                <th>Card Number</th>

                <!-- <th>Mobile Number</th> -->
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Address</th>
                <th>Collection Method</th>
                <th>Print Location</th>

                <th>Date</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.recordId }}</td>
                <td>{{ x.cardholderName }}</td>
                <td>{{ x.cardNumber }}</td>
                <!-- <td>{{ x.mobileNumber }}</td> -->
                <td>{{ x.deliveredName }}</td>
                <td>{{ x.deliveredNumber }}</td>
                <td>{{ x.deliveredAddress }}</td>
                <td>{{ x.deliveredType }}</td>
                <td>{{ x.printLocation }}</td>

                <td>{{ x.deliveredDate | date : "dd-MMM-yy" }}</td>
                <td
                  data-target="#ResendOtpModel"
                  data-toggle="modal"
                  (click)="SelectedRecodrd(x)"
                >
                  <button type="button" class="btn btn-outline-primary btn-sm">
                    Resend OTP
                  </button>
                </td>
                <!-- <td
                  data-toggle="modal"
                  (click)="SelectedRecodrd(x)"
                  data-target="#updateSubscriptionModel"
                >
                  <button
                    type="button"
                    (click)="getListOfStations(x)"
                    class="btn btn-outline-primary btn-sm"
                  >
                    Relivery
                  </button>
                </td> -->
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-md"
  id="updateSubscriptionModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Card delivery</h4>
              <form
                class="forms-sample"
                [formGroup]="getForm"
                (ngSubmit)="handleSubmitDelivery()"
              >
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="collectionMethod">Select Method</label>
                  <select
                    class="form-control"
                    formControlName="collectionMethod"
                  >
                    <option value="null" disabled selected>--Select--</option>
                    <option *ngFor="let p of ListOfMethods" [ngValue]="p.name">
                      {{ p.name }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted && getForm.controls.collectionMethod.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="getForm.controls.collectionMethod.errors.required"
                    >
                      Branch is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Name </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder="Name"
                    formControlName="name"
                  />

                  <div
                    *ngIf="submitted && getForm.controls.name.errors"
                    class="text-danger"
                  >
                    <div *ngIf="getForm.controls.name.errors.required">
                      Name is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="mobileNumber">Mobile Number </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder="Mobile Number"
                    formControlName="mobileNumber"
                  />

                  <div
                    *ngIf="submitted && getForm.controls.mobileNumber.errors"
                    class="text-danger"
                  >
                    <div *ngIf="getForm.controls.mobileNumber.errors.required">
                      Mobile Number is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Address </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder="Address"
                    formControlName="address"
                  />

                  <div
                    *ngIf="submitted && getForm.controls.address.errors"
                    class="text-danger"
                  >
                    <div *ngIf="getForm.controls.address.errors.required">
                      Address is required
                    </div>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-md"
  id="ResendOtpModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Resend Otp</h4>
              <form
                class="forms-sample"
                [formGroup]="getForm"
                (ngSubmit)="handleSubmitResendOtp()"
              >
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="card-subtitle">
                  Are you sure you want to resend the OTP
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
