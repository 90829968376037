<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ Title }}</h4>
          </div>

          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-3">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                (click)="print()"
                type="button"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="fnPDF()"
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="downloadCSV()"
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>
        <div class="table-responsive" id="section-two">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th (click)="sortData('Mcc')" style="cursor: pointer">Mcc</th>
                <th
                  (click)="sortData('merchantIdentity')"
                  style="cursor: pointer"
                >
                  Merchant Identity
                </th>
                <th (click)="sortData('narrative')" style="cursor: pointer">
                  Narrative
                </th>
                <th
                  (click)="sortData('transactionAmount')"
                  style="cursor: pointer"
                >
                  Transaction Amount
                </th>
                <th
                  (click)="sortData('transactionCount')"
                  style="cursor: pointer"
                >
                  Count
                </th>
                <th
                  (click)="sortData('uniqueCustomers')"
                  style="cursor: pointer"
                >
                  Unique Customers
                </th>
                <th (click)="sortData('approved')" style="cursor: pointer">
                  Approved
                </th>
                <th (click)="sortData('declined')" style="cursor: pointer">
                  Declined
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.mcc }}</td>
                <td>{{ x.merchantIdentity }}</td>
                <td>{{ x.narrative }}</td>
                <td>{{ formatTransactionAmount(x.transactionAmount) }}</td>
                <td>{{ x.transactionCount }}</td>
                <td>{{ x.uniqueCustomers }}</td>
                <td>{{ x.approved }}</td>
                <td>{{ x.declined }}</td>
                <td>
                  <button
                    (click)="PassViewReport(x)"
                    type="button"
                    class="btn btn-outline-primary btn-sm ml-2"
                  >
                    View Details
                  </button>
                </td>
              </tr>
              <tr style="background-color: #09df66be">
                <td>Total:</td>
                <td></td>
                <td></td>
                <td>{{ sumTransactionAmount | number : "1.2-2" }}</td>
                <td>{{ sumTransactionCount }}</td>
                <td>{{ sumUniqueCustomers }}</td>
                <td>{{ sumApproved }}</td>
                <td>{{ sumDeclined }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
