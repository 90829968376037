<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-11">
                        <h4 class="card-title">Payment Provider Invoice</h4>
                    </div>
                    <div class="col-md-1">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backtowebrole()">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate (ngSubmit)="OnProcessInvoice()">
                            <div class="card-subtitle">Account Info</div>
                            <div style="border: 1px solid #009e44; background: #009e44; margin-bottom: 10px;"></div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Account Id</label>
                                        <input type="number" class="form-control" formControlName="accountId"
                                            placeholder="Account Id" readonly
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.accountId.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.accountId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.accountId.errors.required">Account Id is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="isActive">Account Status</label>
                                        <input type="text" class="form-control" formControlName="statusDesc"
                                            placeholder="Account Status" readonly
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.statusDesc.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.statusDesc.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.statusDesc.errors.required">Account Status is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="isActive">Payment To</label>
                                        <select class="form-control" id="sel1" formControlName="settledBank"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.settledBank.errors }">
                                            <option *ngFor="let x of bankDetail" [ngValue]="x.bankName">{{x.bankName}}
                                            </option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.settledBank.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.settledBank.errors.required">Payment To is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="isActive">Payment Terms</label>
                                        <select class="form-control" id="sel1" formControlName="settledTerms"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.settledTerms.errors }">
                                            <option *ngFor="let x of companyList" [ngValue]="x.days" >{{x.terms}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.settledTerms.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.settledTerms.errors.required">Payment Terms is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Amount</label>
                                        <input type="text" class="form-control" formControlName="settlementAmount"
                                            placeholder="Amount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.settlementAmount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.settlementAmount.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.settlementAmount.errors.required">Amount is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="isActive">Description</label>
                                        <input type="text" class="form-control" formControlName="description"
                                            placeholder="Description"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.description.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.description.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.description.errors.required">Description is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                            <!-- <button type="submit" class="btn btn-primary mr-2">Create</button> -->
                            <!-- <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>