import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "src/app/service/service/service.service";
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-merchant-account-info',
  templateUrl: './merchant-account-info.component.html',
  styleUrls: ['./merchant-account-info.component.css'],
  animations: [
    fade
  ]
})
export class MerchantAccountInfoComponent implements OnInit {
  updatesForm: FormGroup;
  sub: Subscription;
  queryReqId: number;
  ReqId: any;
  submitted = false;
  merchantId;
  accountId;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.updateForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.merchantId = +params['merchantId'];
        this.accountId = +params['accountId'];
        if (Number.isNaN(this.merchantId)) {
          this.merchantId = "";
        } else {
          // console.log("HHHH => ", this.MerchantId)
          this.getSettlementInfo(this.merchantId, this.accountId)
        }
      });
      
  }

  backToService(){
    this._location.back();
  }


  updateForm() {
    this.updatesForm = this.fb.group({
      accountId: ['', Validators.required],
      currencyCode: ['', Validators.required],
      accountTypeId: ['', Validators.required],
      userId: ['', Validators.required],
      debit: ['', Validators.required],
      credit: ['', Validators.required],
      createdByUserId: ['', Validators.required],
      createdDate: ['', Validators.required],
      accountStatusId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  getSettlementInfo(merchantId, accountId){
    let payload: any = {
      merchantId: merchantId,
      accountId: accountId
    };
    this.spinner.getSpinner();
    this.service.getMerchantAccountInfo({payload: payload}).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      this.spinner.hideSpinner();
      var data = JSON.parse(responseObj);
      console.log("UUUU", data)
      this.updatesForm = this.fb.group({
        accountId: [data.accountId, Validators.required],
        currencyCode: [data.currency, Validators.required],
        accountTypeId: [data.accountTypeId, Validators.required],
        userId: [data.userId, Validators.required],
        debit: [data.debit, Validators.required],
        credit: [data.credit, Validators.required],
        createdByUserId: [data.createdByUserId, Validators.required],
        createdDate: [data.createdDate, Validators.required],
        accountStatusId: [data.accountStatusId, Validators.required],
      });
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
     console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


}
