import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'src/app/service/service/spinner.service'
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';

@Component({
  selector: 'app-merchant-payment-provider-table',
  templateUrl: './merchant-payment-provider-table.component.html',
  styleUrls: ['./merchant-payment-provider-table.component.css'],
  animations: [
    fade
  ]
})
export class MerchantPaymentProviderTableComponent implements OnInit {

  getForm: FormGroup;
  submitted = false;
  _POS_PURCHASE: any = 1;
  _EPAYMENT: any = 4;

  listData;
  listBody: boolean;
  searchButtonText;
  errorMessageList: boolean;

  statusData;
  selectedValue;

  merchantId: any;
  sub: any;
  message;
  data;

  accountId;
  isTrue = false;
  resonForm: FormGroup;

  queryMerchantId: any;
  queryTranStatusId: any;
  selectedMerchantValue: any;
  value = false;
  selectData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  merchantDefAccount = false;
  merchInfo;
  seqId;
  merchantList;
  addressList;



  constructor(
    private _location: Location,
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    // this.selectData 
    this.getUserAddress();
    this.resonedForm();

    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.queryMerchantId = +params['merchantId'];
        if (Number.isNaN(this.queryMerchantId)) {
          this.merchantId = "";
        } else {
          this.merchantId = this.queryMerchantId;
          this.fnGetMerchantPaymentProvidorList(this.queryMerchantId);
          this.getMerchantInfo(this.queryMerchantId);
        }
        //this.fnListTransactionHistoryWithMerchantId(this.queryMerchantId);
      });

    this.accountId = +this._sessionStorageService.getAccountId();
  }

  ngOnInit(): void {

  }

  getUserAddress() {
    this.service.getIpAddress().subscribe(res => {
      console.log(res);
      this.addressList = res;
      console.log(window.location.href);
    })
  }

  resonedForm() {
    this.resonForm = this.fb.group({
      reson: ['', Validators.required],
    });
  }

  getMerchantInfo(merchantId) {
    console.log("YYYYY=>", merchantId)
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId,
    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      this.merchantList = data;
      console.log("UUUU", data)

    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onPrintTables(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();;
  }

  backToService() {
    this._location.back();
  }

  fnGetMerchantPaymentProvidorList(merchantId) {
    this.listBody = false;
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId

    };

    this.spinner.getSpinner();
    this.service.getMerchantPaymentProvidorList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (!successResponse.records.message) {
        this.listBody = true;
      }
      if (successResponse.success) {
        var obj2 = {};
        var arr3: any = [];
        this.spinner.hideSpinner();
        var records = JSON.parse(data);

        records.forEach(el => {
          var record = {};
          if (obj2[el.merchantName]) {
            record = {
              seqId: el.seqId, merchantId: el.merchantId, merchantUid: el.merchantUid, paymentProcessorId: el.paymentProcessorId,
              paymentProcessorTypeId: el.paymentProcessorTypeId, isEnabled: el.isEnabled, createdByUserId: el.createdByUserId, description: el.description,
              categoryCodeId: el.categoryCodeId, productId: el.productId, bankId: el.bankId, createdDate: el.createdDate,
            }
            obj2[el.merchantName].push(record);
          } else {
            record = {
              seqId: el.seqId, merchantId: el.merchantId, merchantUid: el.merchantUid, paymentProcessorId: el.paymentProcessorId,
              paymentProcessorTypeId: el.paymentProcessorTypeId, isEnabled: el.isEnabled, createdByUserId: el.createdByUserId, description: el.description,
              categoryCodeId: el.categoryCodeId, productId: el.productId, bankId: el.bankId, createdDate: el.createdDate
            }
            obj2[el.merchantName] = [];
            obj2[el.merchantName].push(record);
            arr3.push({ merchantName: el.merchantName, records: obj2[el.merchantName] });
          }
        });
        // console.log("All ", successResponse)
        this.listData = arr3;
        this.listBody = true;
        this.isTrue = false;
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.listData = null;
        this.isTrue = true;
        this.message = data.message;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };

  fnGetInfo(x) {
    this.seqId = x.seqId;
    console.log("OOOOO", x);
  }

  fnAllowPayment() {
    // this.submitted = true;
    // if (this.resonForm.invalid) {
    //   return;
    // }
    let payload: any = {
      seqId: this.seqId,
      isEnabled: 1
    };
    console.log("Allowed", payload)
    this.spinner.getSpinner();
    this.service.updateMerchantPaymentProvider({ payload: payload }).subscribe(successResponse => {
      var responseObj = successResponse.records;
      var data = responseObj;
      console.log("UUUU=>", successResponse)
      if (successResponse.success) {
        if (data.seqId) {
          this.toastr.success(`You have allowed payment method Id is ${data.seqId}`, 'Successfully')
          $('#allowModel').modal('hide');
          this.spinner.hideSpinner();
          this.fnGetMerchantPaymentProvidorList(this.queryMerchantId);
          this.fnCreateEventLogs({ previousValue: "DisAllowed", newValue: "Allowed", eventTypeId: 6})
        } else {
          this.spinner.hideSpinner();
          this.toastr.error('InComing request is wrong', 'Error')
        }
      } else {
        this.toastr.warning(successResponse.error, 'Warning')
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  fnDisAllowPayment() {
    this.submitted = true;
    if (this.resonForm.invalid) {
      return;
    }
    let payload: any = {
      seqId: this.seqId,
      isEnabled: 0
    };
    console.log("DisAllowed", payload)
    this.spinner.getSpinner();
    this.service.updateMerchantPaymentProvider({ payload: payload }).subscribe(successResponse => {
      var responseObj = successResponse.records;
      var data = responseObj;
      console.log("UUUU=>", successResponse)
      if (successResponse.success) {
        if (data.seqId) {
          this.toastr.success(`You have disallowed payment method Id is ${data.seqId}`, 'Successfully')
          $('#disAllowtModel').modal('hide');
          this.spinner.hideSpinner();
          this.fnGetMerchantPaymentProvidorList(this.queryMerchantId);
          this.fnCreateEventLogs({ previousValue: "Allowed", newValue: "DisAllowed", eventTypeId: 7})
        } else {
          this.spinner.hideSpinner();
          this.toastr.error('InComing request is wrong', 'Error')
        }
      } else {
        this.toastr.warning(successResponse.error, 'Warning')
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  fnCreateEventLogs(selectData) {
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: this.merchantList.userId,
      eventTypeId: selectData.eventTypeId,
      previousValue: selectData.previousValue,
      newValue: selectData.newValue,
      reson: this.resonForm.value.reson,
      sessionId: currentUser.referenceId,
      address: JSON.stringify(this.addressList),
      ipAddress: this.addressList,
      applicationId: currentUser.userTypeId,
      doneByUserId: currentUser.userId,
    };
    console.log("jajaHHH", payload)
    this.service.createEventLogs({ payload: payload }).subscribe((res) => {
      console.log(res)

    })
  }


  createPaymentMethod() {
    let merchantId = this.queryMerchantId;
    this.router.navigate(["dashboard/view/addPaymentMethod"], { queryParams: { merchantId } });
  }

}
