import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "src/app/service/service/service.service";
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-reset-password-info',
  templateUrl: './reset-password-info.component.html',
  styleUrls: ['./reset-password-info.component.css'],
  animations: [
    fade
  ]
})
export class ResetPasswordInfoComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  getForm: FormGroup;
  querymerchantId: number;
  merchantId: any;
  sub: Subscription;
  queryUserId: number;
  userId: any;
  dataRecords: any;
  submitted = false;
  getSubmitted = false;
  message = 'Data Not Found';
  modal;
  selectData: any;
  selectProductData;
  productId;
  isTrue: boolean = false;
  event;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService,
    private _location: Location
  ) {
    this.updateForm();
    this.getProductLists();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.querymerchantId = +params['merchantId'];
        if (Number.isNaN(this.querymerchantId)) {
          this.merchantId = "";
        } else {
          this.merchantId = this.querymerchantId;
          // console.log("HHHH => ", this.merchantId)
          this.viewMerchantUserInfo(this.merchantId)
        }
      });

  }



  updateForm() {
    this.updatesForm = this.fb.group({
      merchantUId: ['', Validators.required],
      userId: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      userTitle: ['', Validators.required],
    });
  }


  ngOnInit(): void {
    this.event = 'Edit'
    this.updatesForm.disable()
  }

  onButtonClick(event) {
    if (event == 'Edit') {
      this.event = 'Disabled'
      this.updatesForm.enable()
    } else {
      this.event = 'Edit'
      this.updatesForm.disable()
    }
  }

  getProductLists(productId?) {
    let payload: any = {
      distributorId: 0
    };
    this.spinner.getSpinner();
    this.service.getProductList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      var selectedRecord: any = {};
      if (productId != null) {
        this.spinner.hideSpinner();
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.productId == productId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectProductData = records;
        this.productId = selectedRecord.productId;
      } else {
        this.spinner.hideSpinner();
        this.selectProductData = records;
        this.productId = "";
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  backToService() {
    this._location.back();
  }

  viewMerchantUserInfo(merchantId) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId,
    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      // console.log("UUUU", data)
      this.updatesForm = this.fb.group({
        userId: [data.userId, Validators.required],
        merchantUId: [data.merchantUid, Validators.required],
        email: [data.email, [Validators.required, Validators.email]],
        userTitle: [data.userTitle, Validators.required],
        merchantId: [data.merchantId, Validators.required],
        loginId: [data.loginId, Validators.required],
      });
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  processResetPasswordRequest() {
    this.submitted = true;
    if (this.updatesForm.invalid) {
      return;
    }
    let payload = {
      merchantId: this.updatesForm.value.merchantId,
      loginId: this.updatesForm.value.loginId,
      to: this.updatesForm.value.email
    };

    this.spinner.getSpinner();
    console.log("payload=>", payload);
    this.service.userResetPassword(payload).subscribe(successResponse => {
      console.table(successResponse);
      // this.displayAccount = true;
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      this.toastr.success(data.message);

      // this.settlementAmount = null;
      // this.fnGetAccountStatus(this.accountStatusId);
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse.error.records.message));
        this.toastr.warning(failureResponse.error.records.message);
        this.spinner.hideSpinner();
      }
    });
  }

}
