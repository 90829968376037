<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Account Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to Accounts</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="updatesForm" novalidate>

                        <div class="card-subtitle">Account Info</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="isActive">Account Id</label>
                                    <input type="text" class="form-control" formControlName="accountId"
                                        placeholder="Account Id" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.accountId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.accountId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.accountId.errors.required">Account Id
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Currency Code</label>
                                    <input type="text" class="form-control" formControlName="currencyCode"
                                        placeholder="Currency Code" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.currencyCode.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.currencyCode.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.currencyCode.errors.required">Currency Code
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Created By User Id</label>
                                    <input type="text" class="form-control" formControlName="createdByUserId"
                                        placeholder="Created By User Id" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.createdByUserId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.createdByUserId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.createdByUserId.errors.required">CreatedByUserId is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Account Type Id</label>
                                    <input type="text" class="form-control" formControlName="accountTypeId"
                                        placeholder="Account Type Id" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.accountTypeId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.accountTypeId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.accountTypeId.errors.required">Account Type Id
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Debit</label>
                                    <input type="text" class="form-control" formControlName="debit"
                                        placeholder="debit" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.debit.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.debit.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.debit.errors.required">Debit is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Credit</label>
                                    <input type="text" class="form-control" formControlName="credit"
                                        placeholder="Credit" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.credit.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.credit.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.credit.errors.required">Credit is
                                            required</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">UserId</label>
                                    <input type="text" class="form-control" formControlName="userId"
                                        placeholder="UserId" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.userId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.userId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.userId.errors.required">UserId is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Account Status Id</label>
                                    <input type="text" class="form-control" formControlName="accountStatusId"
                                        placeholder="accountStatusId" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.accountStatusId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.accountStatusId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.accountStatusId.errors.required">AccountStatusId is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Created Date</label>
                                    <input type="text" class="form-control" formControlName="createdDate"
                                        placeholder="Created Date" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.createdDate.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.createdDate.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.createdDate.errors.required">Created Date is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-center">
                                <button type="submit" class="btn btn-primary" style="width: 30%;">Update</button>
                                <!-- <button class="btn btn-default">Centered button</button> -->
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

