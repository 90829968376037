import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { fade } from 'src/app/animations/animations';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-createdist',
  templateUrl: './createdist.component.html',
  styleUrls: ['./createdist.component.css'],
  animations: [
    fade
  ]
})
export class CreateDistibutorUserComponent implements OnInit {

  _USER_TYPE_ID_DISTRIBUTOR = 20;
  serviceForm: FormGroup;
  queryUserId: number;
  userId: any;
  dataRecords: any;
  sub: Subscription;
  submitted: boolean = false;
  selectCountryData;
  selectCitiesData;
  selectMessage;
  selectedMessage;
  dataList;
  WebRoleList;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _location: Location,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getCountryList();
    this.getCitiesList();
    this.getWebRoleList();
    this.getDistributorList();
  }

  // getUserLists() {
  //   let currentUser = this._sessionStorageService.getSessionData();
  //   let userTypeId = 0;
  //   let payload: any = {
  //     userId: currentUser.userId,
  //     userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

  //   };
  //   this.spinner.getSpinner();
  //   this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
  //     if (successResponse.records) {
  //       this.spinner.hideSpinner();
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       var records = JSON.parse(data);
  //       this.dataList = records;
  //       // console.log("kkkk", records)
  //     } else {
  //       this.spinner.hideSpinner();
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if (failureResponse.status === 401) {
  //       window.location.href = 'login';
  //     } else {
  //       this.spinner.hideSpinner();
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }
  getDistributorList() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getDistributorList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  createForm() {
    this.serviceForm = this.fb.group({
      distributorId: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // telephone: ['', Validators.required],
      // address: ['', Validators.required],
      userTitle: ['', Validators.required],
      // address2: ['', Validators.required],
      // website: ['', Validators.required],
      // city: ['', Validators.required],
      // country: ['', Validators.required],
      roleId: ['', Validators.required],
      loginId: ['', Validators.required],
      // password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }

  getWebRoleList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getWebRoleList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      // console.log("Country=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.WebRoleList = records;
      } else {
        this.spinner.hideSpinner();
        this.selectMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getCountryList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCountryList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      // console.log("Country=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCountryData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getCitiesList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCitiesList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      console.log("Cities=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCitiesData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectedMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  OnCreateDistributorUser() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = this._USER_TYPE_ID_DISTRIBUTOR;
    let payload: any = {
      createdByUserId: currentUser.userId,
      firstName: this.serviceForm.value.firstName,
      middleName: this.serviceForm.value.middleName,
      lastName: this.serviceForm.value.lastName,
      email: this.serviceForm.value.email,
      userTitle: this.serviceForm.value.userTitle,
      loginId: this.serviceForm.value.loginId,
      distributorId: this.serviceForm.value.distributorId,
      userTypeId: userTypeId,
      pRoleId: this.serviceForm.value.roleId,
    };

    this.spinner.getSpinner()
    this.service.createCustomDistributorUser(payload).subscribe(response => {
      if (response.success) {
        this.spinner.hideSpinner();
        this.toastr.success(response.message)
        this.router.navigate(["dashboard/listDistributorUserInfo"]);
      } else {
        this.toastr.error(response.message);
        this.spinner.hideSpinner();
      }
    }, (failureResponse: any) => {
      this.spinner.hideSpinner();
      this.toastr.warning(failureResponse.error.message.error.message, "Warning");
      console.log("onFailure:" + JSON.stringify(failureResponse.error.message.error.message));
    });
  }


}
