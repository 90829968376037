import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import {Router} from '@angular/router'
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { pagination } from 'src/app/service/service/pagination';
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-servicesconfig',
  templateUrl: './servicesconfig.component.html',
  styleUrls: ['./servicesconfig.component.css'],
  animations: [
    fade
  ]
})
export class ServicesconfigComponent implements OnInit {

  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  selectServiceChargeModeData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      serviceCode: ['', Validators.required ],
      serviceDesc: ['', Validators.required ],
      isActive: ['', Validators.required ],
      serviceChargeModeId: ['', Validators.required ],
      isTransactional: ['', Validators.required ],
      serviceProfileDesc: ['', Validators.required ],
      minInTxAmount: ['', Validators.required ],
      maxInTxAmount: ['', Validators.required ],
      minOutTxAmount: ['', Validators.required ],
      maxOutTxAmount: ['', Validators.required ],
      dailyInTxLimit: ['', Validators.required ],
      dailyOutTxLimit: ['', Validators.required ],
      monthlyInTxLimit: ['', Validators.required ],
      monthlyOutTxLimit: ['', Validators.required ],
    });
  }

  ngOnInit(): void {
    this.getServiceList();
  }

  getServiceList(){
    let payload: any = {};
    this.service.getServiceList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  
  
  passServiceCreation() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userId = currentUser.userId;
    this.router.navigate(['dashboard/view/createServiceInfo'], { queryParams: { userId } });
  }
  passServiceUpdated(x) {
    let serviceId = x.serviceId;
    this.router.navigate(['dashboard/view/updateServiceInfo'], { queryParams: { serviceId } });
  }

  resetForm(){
    this.serviceForm.reset();
  }

  OnCreateServiceSetting() {
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      createdByUserId: currentUser.userId,
      serviceProfileDesc: this.serviceForm.value.serviceProfileDesc,
      serviceChargeModeId: this.serviceForm.value.serviceChargeModeId,
      serviceCode: this.serviceForm.value.serviceCode,
      serviceDesc: this.serviceForm.value.serviceDesc,
      isActive: this.serviceForm.value.isActive,
      isTransactional: this.serviceForm.value.isTransactional,
      minInTxAmount: this.serviceForm.value.minInTxAmount,
      minOutTxAmount: this.serviceForm.value.minOutTxAmount,
      maxInTxAmount: this.serviceForm.value.maxInTxAmount,
      maxOutTxAmount: this.serviceForm.value.maxOutTxAmount,
      dailyInTxLimit: this.serviceForm.value.dailyInTxLimit,
      dailyOutTxLimit: this.serviceForm.value.dailyOutTxLimit,
      monthlyInTxLimit: this.serviceForm.value.monthlyInTxLimit,
      monthlyOutTxLimit: this.serviceForm.value.monthlyOutTxLimit
    };
    this.service.createServiceConfig(payload).subscribe(successResponse => {

      if(successResponse.id){
        $('#createModel').modal('hide');
        this.toastr.success("Seccessfully: You have created Id",successResponse.id)
        this.getServiceList()
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  viewServiceSetting(data){

    let payload: any = {
      serviceId: data.serviceId,
    };

    this.service.getServiceInfo({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        // var records = JSON.parse(data);
        this.serviceForm = this.fb.group({
          serviceId: [data.serviceId, Validators.required],
          serviceProfileDesc: [data.serviceProfileDesc, Validators.required],
          selectServiceChargeModeValue: [data.serviceChargeModeId, Validators.required],
          serviceCode: [data.serviceCode, Validators.required],
          serviceDesc: [data.serviceDesc, Validators.required],
          serviceChargeModeId: [data.serviceChargeModeId, Validators.required],
          isActive: [data.isActive, Validators.required],
          isTransactional: [data.isTransactional, Validators.required],
          minInTxAmount: [data.minInTxAmount, Validators.required],
          minOutTxAmount: [data.minOutTxAmount, Validators.required],
          maxInTxAmount: [data.maxInTxAmount, Validators.required],
          maxOutTxAmount: [data.maxOutTxAmount, Validators.required],
          dailyInTxLimit: [data.dailyInTxLimit, Validators.required],
          dailyOutTxLimit: [data.dailyOutTxLimit, Validators.required],
          monthlyInTxLimit: [data.monthlyInTxLimit, Validators.required],
          monthlyOutTxLimit: [data.monthlyOutTxLimit, Validators.required]
        });
        // console.log("kkkk", data)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
    
    
  }

  updateServiceSetting(){
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      serviceProfileDesc: this.serviceForm.value.serviceProfileDesc,
      serviceChargeModeId: this.serviceForm.value.serviceChargeModeId,
      serviceCode: this.serviceForm.value.serviceCode,
      serviceDesc: this.serviceForm.value.serviceDesc,
      isActive: this.serviceForm.value.isActive,
      isTransactional: this.serviceForm.value.isTransactional,
      minInTxAmount: this.serviceForm.value.minInTxAmount,
      minOutTxAmount: this.serviceForm.value.minOutTxAmount,
      maxInTxAmount: this.serviceForm.value.maxInTxAmount,
      maxOutTxAmount: this.serviceForm.value.maxOutTxAmount,
      dailyInTxLimit: this.serviceForm.value.dailyInTxLimit,
      dailyOutTxLimit: this.serviceForm.value.dailyOutTxLimit,
      monthlyInTxLimit: this.serviceForm.value.monthlyInTxLimit,
      monthlyOutTxLimit: this.serviceForm.value.monthlyOutTxLimit
    };
    console.log("Payload", payload)
    this.service.updateServiceConfig(payload).subscribe(successResponse => {

      if(successResponse.id){
        $('#viewModel').modal('hide');
        this.toastr.success("Seccessfully: You have Updatetd",successResponse.id)
        this.getServiceList()
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  

}
