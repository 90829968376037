<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">All Banks</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary"
                                (click)="passCreateBankResetFields()">Create Bank</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Bank Id</th>
                                <th>Bank Name</th>
                                <th>Bank Code</th>
                                <th>Address</th>
                                <th>Payment Processor Id</th>
                                <th>Action</th>
                                <th>Process</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td>{{ x.bankId}}</td>
                                <td>{{ x.bankName}}</td>
                                <td>{{ x.bankCode}}</td>
                                <td>{{ x.address}}</td>
                                <td>{{ x.paymentProcessorId}}</td>
                                <td (click)="passViewBankInfo(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                                <td (click)="passViewBankProcessIdInfo(x)" >
                                    <button type="button" class="btn btn-outline-primary btn-sm">process</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <!-- <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>