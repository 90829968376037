<div @fade class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Merchant Approvals</h4>
                <p class="card-description">
                    Distributor
                </p>
                <form class="forms-sample" [formGroup]="getForm" novalidate (ngSubmit)="passMerchantTableView()">

                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <select class="form-control-sm" name="selectData" formControlName="selectData"
                                    [ngClass]="{ 'is-invalid': getSubmitted && getForm.controls.selectData.errors }">
                                    <option [selected]="true" value=null disabled selected>--Select--</option>
                                    <option *ngFor="let c of dataList" [ngValue]="c.referenceId">{{c.userTitle}}
                                    </option>
                                </select>
                                <div *ngIf="getSubmitted && getForm.controls.selectData.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.selectData.errors?.required">
                                        Select Distributor is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>