import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-view-billing-info',
  templateUrl: './view-billing-info.component.html',
  styleUrls: ['./view-billing-info.component.css'],
  animations: [
    fade
  ]
})
export class ViewBillingInfoComponent implements OnInit {

  billerId;

  serviceForm: FormGroup;
  submitted = false

  selectBillingCategory;
  selectPaymentProcessorList;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.loadBillerCategories();
    this.loadBillingPaymentProcessor();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.billerId = params['billerId']
        this.getBillingInfo(this.billerId);
      } else {
        _location.back();
      }
    });
  }

  ngOnInit(): void {
  }

  createForm() {
    this.serviceForm = this.fb.group({
      billerId: ['', Validators.required],
      billerCode: ['', Validators.required],
      billerName: ['', Validators.required],
      billerCategoryId: ['', Validators.required],
      paymentProcessorId: ['', Validators.required],
    });
  }

  loadBillingPaymentProcessor() {
    let payload: any = {}
    this.spinner.getSpinner();
    this.service.getPaymentProcessorList({ payload: payload }).subscribe((res) => {
      if (res.success) {
        this.spinner.hideSpinner();
        var response = JSON.parse(res.records);
        this.selectPaymentProcessorList = response
        // console.log("Gaa=> ", response)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(response.message, 'Error');
      }
    })
  }

  loadBillerCategories() {
    let payload: any = {}
    this.spinner.getSpinner();
    this.service.getBillerCategoryList({ payload: payload }).subscribe((res) => {
      if (res.success) {
        this.spinner.hideSpinner();
        var response = JSON.parse(res.records);

        this.selectBillingCategory = response
      } else {
        console.log(response)
        this.spinner.hideSpinner();
        // this.toastr.error(response.message, 'Error');
      }
    })
  }

  backToService() {
    this._location.back();
  }

  getBillingInfo(billerId) {
    let payload: any = {
      billerId: billerId
    }

    this.service.getBillingInfo({ payload: payload }).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hideSpinner();
        var data = res.records;
        // console.log("HH=>", data)
        this.serviceForm = this.fb.group({
          billerId: [data.billerId, Validators.required],
          billerCode: [data.billerCode, Validators.required],
          billerName: [data.billerName, Validators.required],
          billerCategoryId: [data.billerCategoryId, Validators.required],
          paymentProcessorId: [data.paymentProcessorId, Validators.required],
        });
      } else {
        this.spinner.hideSpinner();
        // this.listBody = false;
        // this.message = response.message;
      }
    })
  }

  updateBillerInfo() {
    this.submitted = true;
    if (this.serviceForm.invalid) return;
    let currentUser = this._sessionStorageService.getSessionData()
    let payload: any = {
      billerId: this.serviceForm.value.billerId,
      billerCode: this.serviceForm.value.billerCode,
      billerName: this.serviceForm.value.billerName,
      billerCategoryId: this.serviceForm.value.billerCategoryId,
      paymentProcessorId: this.serviceForm.value.paymentProcessorId,
      createdByUserId: currentUser.userId
    }
    // console.log("Payload=>", payload)
    this.service.updateBillerInfo({ payload: payload }).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hideSpinner();
        this.toastr.success(`Biller Has Updated ${res.records.billerId}`, 'Success');
        this.router.navigate(["dashboard/billingList"]);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(res.messagem, 'Error');
        // this.message = response.message;
      }
    })
  }


}
