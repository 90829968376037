import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';

@Component({
  selector: 'app-merchantgroups',
  templateUrl: './merchantgroups.component.html',
  styleUrls: ['./merchantgroups.component.css'],
  animations: [
    fade
  ]
})
export class MerchantgroupsComponent implements OnInit {
  dataList;
  serviceForm: FormGroup;
  createdForm: FormGroup;
  submitted = false;
  modal;
  selectBankData;
  selectServiceChargeModeData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getForm();
    this.createForm();
    this.getMerchantGroupList();
    // this.getBankList();
  }

  getForm() {
    this.serviceForm = this.fb.group({
      merchantGroupId: ['', Validators.required ],
      groupName: ['', Validators.required ],
      description: ['', Validators.required ],
    });
  }
  createForm() {
    this.createdForm = this.fb.group({
      groupName: ['', Validators.required ],
      description: ['', Validators.required ],
    });
  }

  ngOnInit(): void {
    this.getBankList();
  }

  getMerchantGroupList(){
    let payload: any = {};
    this.service.getMerchantGroupList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  resetForm(){
    this.serviceForm.reset();
  }

  passAssignSettingsValue(merchantGroup){
    let merchantGroupId = merchantGroup.merchantGroupId;
    this.router.navigate(['dashboard/assignGroupSettings'], { queryParams: { merchantGroupId } });
  }

  getBankList(){
    let payload: any = {};
    this.service.getBankList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectBankData =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  OnCreateMerchantGroupConfig() {
    
    this.submitted = true;
 
    if (this.createdForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      createdByUserId: currentUser.userId,
      groupName: this.createdForm.value.groupName,
      description: this.createdForm.value.description
    };
    this.service.createMerchantGroup(payload).subscribe(successResponse => {

      if(successResponse.id){
        $('#createModel').modal('hide');
        this.toastr.success("Seccessfully: You have created Id",successResponse.id)
        this.getMerchantGroupList();
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }



  viewMerchantGroupInfo(x){
    this.serviceForm = this.fb.group({
      merchantGroupId: [x.merchantGroupId, Validators.required ],
      groupName: [x.groupName, Validators.required ],
      description: [x.description, Validators.required ],
    });
  }

  updateMerchantGroupConfig(){
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      merchantGroupId: this.serviceForm.value.merchantGroupId,
      groupName: this.serviceForm.value.groupName,
      description: this.serviceForm.value.description,
      doneByUserId: currentUser.userId,
    };
    this.service.updateMerchantGroup({payload: payload}).subscribe(successResponse => {
      let response = successResponse.records;
      if(response.merchantGroupId){
        this.toastr.success(`You have updated ${response.MerchantGroupId}`,'Seccessfully')
      }else {
        this.toastr.error("Something went wrong")
      }
      // console.log("OOO=>", response.merchantGroupId)
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

}
