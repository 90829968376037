<div @fade class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Transaction Info</h4>
        <p class="card-description">Transaction</p>
        <form
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="handleSubmit()"
        >
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control-sm"
                  formControlName="transectionId"
                  placeholder="transectionId Id"
                  [ngClass]="{
                    'is-invalid':
                      getSubmitted && getForm.controls.transectionId.errors
                  }"
                />

                <div
                  *ngIf="getSubmitted && getForm.controls.transectionId.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.transectionId.errors.required">
                    transection Id is required
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div class="col-md-3">
                            <div class="form-group">
                                <select class="form-control-sm" formControlName="selectedData"
                                [ngClass]="{ 'is-invalid': submitted && getForm.controls.selectedData.errors }">
                                    <option *ngFor="let c of selectData" [ngValue]="c.tranStatusId">{{c.tranStatusDesc}}</option>
                                </select>
                                <div *ngIf="submitted && getForm.controls.selectedData.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.selectedData.errors.required">Merchant Id is required
                                    </div>
                                </div>
                            </div>
                        </div> -->
