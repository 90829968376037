<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Transaction Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Transaction</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate>
                            <div class="card-subtitle">Transaction Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div *ngIf="listBody">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Transaction Id</label>
                                            <input type="text" class="form-control" formControlName="transactionId"
                                                readonly placeholder="Transaction Id"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.transactionId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.transactionId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.transactionId.errors.required">
                                                    Transaction Id is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Amount</label>
                                            <input type="text" class="form-control" formControlName="amount" readonly
                                                placeholder="Amount"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.amount.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.amount.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.amount.errors.required">
                                                    Amount is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Cashier Id</label>
                                            <input type="text" class="form-control" formControlName="cashierId" readonly
                                                placeholder="Cashier Id"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.cashierId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.cashierId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.cashierId.errors.required">
                                                    Cashier Id is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">PayerBank Id</label>
                                            <input type="text" class="form-control" formControlName="payerBankId"
                                                readonly placeholder="PayerBank Id"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.payerBankId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.payerBankId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.payerBankId.errors.required">
                                                    Payer Bank Id is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">Status Id</label>
                                            <input type="text" class="form-control" formControlName="statusId"
                                                placeholder="Status Id" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.statusId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.statusId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.statusId.errors.required">Status Id is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Account Id</label>
                                            <input type="text" class="form-control" formControlName="accountId"
                                                placeholder="AccountId" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.accountId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.accountId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.accountId.errors.required">AccountId is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">CreatedDate</label>
                                            <input type="text" class="form-control" formControlName="createdDate"
                                                placeholder="CreatedDate" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.createdDate.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.createdDate.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.createdDate.errors.required">
                                                    CreatedDate is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Currency</label>
                                            <input type="email" class="form-control" formControlName="currency"
                                                placeholder="currency" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.currency.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.currency.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.currency.errors.required">Currency is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Description</label>
                                            <input type="text" class="form-control" formControlName="description"
                                                placeholder="Description" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.description.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.description.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.description.errors.required">
                                                    Description is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">PayerIdType</label>
                                            <input type="text" class="form-control" formControlName="payerIdType"
                                                placeholder="PayerIdType" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.payerIdType.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.payerIdType.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.payerIdType.errors.required">
                                                    PayerIdType is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Payment Channel</label>
                                            <input type="text" class="form-control" formControlName="paymentChannel"
                                                placeholder="paymentChannel One" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.paymentChannel.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.paymentChannel.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.paymentChannel.errors.required">Payment
                                                    Channel is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">InvoiceId</label>
                                            <input type="text" class="form-control" formControlName="invoiceId"
                                                placeholder="InvoiceId" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.invoiceId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.invoiceId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.invoiceId.errors.required">invoiceId
                                                    is required</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Issuer TransactionId</label>
                                            <input type="text" class="form-control" readonly
                                                formControlName="issuerTransactionId" placeholder="issuerTransactionId"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.issuerTransactionId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.issuerTransactionId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.issuerTransactionId.errors.required">
                                                    Issuer TransactionId is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Pos DeviceId</label>
                                            <input type="text" class="form-control" formControlName="posDeviceId"
                                                placeholder="Pos DeviceId" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.posDeviceId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.posDeviceId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.posDeviceId.errors.required">Pos
                                                    DeviceId is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">PayerId</label>
                                            <input type="text" class="form-control" formControlName="payerId"
                                                placeholder="Payer Id" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.payerId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.payerId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.payerId.errors.required">Payer Id is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Merchant Id</label>
                                            <input type="text" class="form-control" formControlName="merchantId"
                                                placeholder="Merchant Id" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.merchantId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.merchantId.errors.required">Merchant Id
                                                    is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div *ngIf='!listBody' class="container">
                            <div style="text-align: center; padding: 24px;">
                                {{message}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>