import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service"
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';



@Component({
  selector: 'app-updaterout',
  templateUrl: './updaterout.component.html',
  styleUrls: ['./updaterout.component.css'],
  animations: [
    fade
  ]
})
export class UpdateroutComponent implements OnInit {
  queryrouteId: number;
  routeId: any;
  dataRecords: any;
  sub: Subscription;
  dataList;
  serviceForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  modal;
  selectBankData;
  selectServiceChargeModeData;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getForms();
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.queryrouteId = +params['routeId'];
      if (Number.isNaN(this.queryrouteId)) {
        this.routeId = "";
      } else {
        this.routeId = this.queryrouteId;
        this.viewRountesConfig(this.routeId)
      }
    });
  }

  getForms() {
    this.getForm = this.fb.group({
      bankId: ['', Validators.required ],
      routeId: ['', Validators.required ],
      routePrefix: ['', Validators.required ],
      isActive: ['', Validators.required ],
      selectedBank: ['', Validators.required ],
      currencyId: ['', Validators.required ],
      currencyCode: ['', Validators.required ],
      currencyDescription: ['', Validators.required ],
      
    });
  }

  ngOnInit(): void {
    this.getBankList();
  }

  getBankList(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getBankList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectBankData =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  backtowebrole(){
    this.router.navigate(['dashboard/listRouteConfig']);
  }

  viewRountesConfig(routeId){

    let payload: any = {
      routeId: routeId,
    };
    this.spinner.getSpinner();
    this.service.getRouteInfo({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        // var records = JSON.parse(data);
        this.getForm = this.fb.group({
          routeId: [data.routeId, Validators.required ],
          routePrefix: [data.routePrefix, Validators.required ],
          isActive: [data.isActive, Validators.required ],
          selectedBank: [data.bankId, Validators.required ],
          currencyId: [data.currencyId, Validators.required ],
          currencyCode: [data.currencyCode, Validators.required ],
          currencyDescription: [data.currencyDescription, Validators.required ],
        });
        // console.log("kkkk", data)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
    
    
  }

  updateRoutesConfig(){
    this.submitted = true;
 
    if (this.getForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      routeId: this.getForm.value.routeId,
      isActive: this.getForm.value.isActive,
      routePrefix: this.getForm.value.routePrefix,
      bankId: this.getForm.value.selectedBank,
      currencyCode: this.getForm.value.currencyCode,
      currencyDescription: this.getForm.value.currencyDescription
    };
    this.spinner.getSpinner();
    this.service.updateWebPermissionInfo(payload).subscribe(successResponse => {

      if(successResponse.id){
        this.spinner.hideSpinner();
        this.toastr.success("Seccessfully: You have created Id",successResponse.id)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
}
