<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h4 class="card-title">Reset Merchant</h4>
                    </div>
                    <div class="col-md-4">
                        <h4 class="card-title" style="float: right;">
                            <button (click)="onButtonClick(event)" class="btn btn-primary mr-2">{{event}}</button>
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to Service</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="updatesForm" novalidate
                        (ngSubmit)="processResetPasswordRequest()">

                        <div class="card-subtitle">Merchant Info</div>
                        <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">User Title</label>
                                    <input type="text" class="form-control" formControlName="userTitle"
                                        placeholder="User Title" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.userTitle.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.userTitle.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.userTitle.errors.required">
                                            User Title is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">User ID</label>
                                    <input type="text" class="form-control" formControlName="userId"
                                        placeholder="User ID" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.userId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.userId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.userId.errors.required">
                                            User ID Id is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Merchant UID</label>
                                    <input type="text" class="form-control" formControlName="merchantUId"
                                        placeholder="Merchant UID" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.merchantUId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.merchantUId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.merchantUId.errors.required">
                                            Merchant UID Id is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-subtitle">Reset Form</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email</label>
                                    <input type="email" class="form-control" formControlName="email"
                                        placeholder="Email"
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.email.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.email.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.email.errors.required">Email is
                                            required</div>
                                        <div *ngIf="updatesForm.controls.email.errors.email">It`s Not An Email
                                            Plz Check</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-center">
                                <button type="submit" class="btn btn-primary" style="width: 30%;">Reset Password</button>
                                <!-- <button class="btn btn-default">Centered button</button> -->
                            </div>
                        </div>
                        <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

