import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/assets/config/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'distributor-portal';
  favIcon: HTMLLinkElement = document.querySelector('#Ico');
  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor(
    private router: Router
  ) {
    console.log("tada", `'${environment.ico}'`)
    this.favIcon.href = `assets/images/${environment.ico}`;
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.router.navigate(['login']);
    });
  }

  keyPress(event: KeyboardEvent): void {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  setTimeout(): void {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 600000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
