<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">View Merchant Subscription Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backToService()">Back to Service</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm">
                            <div class="card-subtitle">Subscription Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="isActive">Merchant Name</label>
                                        <input type="text" class="form-control" formControlName="merchantName"
                                            placeholder="Merchant Name"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantName.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.merchantName.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.merchantName.errors.required">Merchant Name
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Subscription Status</label>
                                        <input type="text" class="form-control" formControlName="subscriptionStatus"
                                            placeholder="Subscription Status"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.subscriptionStatus.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.subscriptionStatus.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.subscriptionStatus.errors.required">Subscription Status
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Charges</label>
                                        <input type="text" class="form-control" formControlName="charges"
                                            placeholder="Charges"
                                            [ngClass]=" { 'is-invalid' : submitted && serviceForm.controls.charges.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.charges.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.charges.errors.required">
                                                Charges is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Subscription Type</label>
                                        <input type="text" class="form-control" formControlName="subscriptionTypeDesc"
                                            placeholder="Subscription Type"
                                            [ngClass]=" { 'is-invalid' : submitted && serviceForm.controls.subscriptionTypeDesc.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.subscriptionTypeDesc.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.subscriptionTypeDesc.errors.required">
                                                Subscription Type is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Subscription Expiry Date</label>
                                        <input type="text" class="form-control" formControlName="subscriptionExpDate"
                                            placeholder="Subscription Expiry Date"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.subscriptionExpDate.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.subscriptionExpDate.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.subscriptionExpDate.errors.required">Subscription Expiry Date
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                

                            <!-- <button type="submit" class="btn btn-primary mr-2">Process</button> -->
                            <!-- <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>