<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">Customer and Card Details</h4>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="merchantUid">Cutsomer Id</label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    [(ngModel)]="customerId"
                    placeholder="Customer Id"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="merchantName">Mobile Number</label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    [(ngModel)]="mobileName"
                    placeholder="Mobile Name"
                    readonly
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="customerName">Customer Name</label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    [(ngModel)]="customerName"
                    placeholder="Customer Name"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 grid-margin stretch-card mt-5">
            <div class="card">
              <div class="row" style="margin-bottom: 5px">
                <div class="col-md-2">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    [(ngModel)]="term"
                    aria-hidden="true"
                    placeholder="Search here..."
                    style="float: left; margin: 5px"
                  />
                </div>
              </div>

              <div class="table-responsive">
                <table
                  class="table-striped display expandable-table"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th style="width: 5%">Id</th>
                      <th style="width: 10%">Card Name</th>
                      <th style="width: 10%">Card</th>

                      <th style="width: 7%">Is Blocked</th>
                      <th style="width: 7%">Is Deleted</th>

                      <th>Balance</th>
                      <th style="width: 10%">Freezed Balance</th>
                      <th style="width: 10%">Expiry Year</th>
                      <th style="width: 15%">Physical Card Status</th>
                      <th style="width: 15%">Registered At</th>
                      <th
                        style="width: 17%"
                        *ngIf="IsallowedCardReplacement == 'true'"
                      >
                        Is allowed for replacement
                      </th>

                      <th *ngIf="cardStatusFromRemotePermission == 'true'">
                        Status From Remote
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style="white-space: nowrap"
                      *ngFor="
                        let x of listData
                          | filter : term
                          | paginate
                            : { itemsPerPage: selected, currentPage: p }
                      "
                    >
                      <td class="py-1">{{ x.cardId }}</td>

                      <td>{{ x.embossingName }}</td>
                      <td>{{ x.maskedPan }}</td>

                      <td>
                        <span
                          class="badge badge-success"
                          *ngIf="x.isBlocked == '0'"
                        >
                          {{ x.isBlocked ? "Yes" : "No" }}</span
                        >
                        <span
                          class="badge badge-danger"
                          *ngIf="x.isBlocked == '1'"
                        >
                          {{ x.isBlocked ? "Yes" : "No" }}</span
                        >
                      </td>

                      <td>
                        <span
                          class="badge badge-success"
                          *ngIf="x.isDeleted == '0'"
                        >
                          {{ x.isDeleted ? "Yes" : "No" }}</span
                        >
                        <span
                          class="badge badge-danger"
                          *ngIf="x.isDeleted == '1'"
                        >
                          {{ x.isDeleted ? "Yes" : "No" }}
                        </span>
                      </td>

                      <td>{{ x.currencyCode }}{{ x.balance }}</td>
                      <td>{{ x.freezedBalance }}</td>
                      <td class="py-1">
                        {{ x.validDate | date : "yy" }}
                      </td>
                      <td>
                        {{ x.isDeleted == "1" ? "Deleted" : x.cardStatus }}
                      </td>

                      <td>{{ x.createdAt | date : "YYYY-MM-dd" }}</td>
                      <td *ngIf="IsallowedCardReplacement == 'true'">
                        <div
                          *ngIf="x.isBlocked == '1'"
                          class="custom-control custom-switch"
                          (click)="selectedRow(x)"
                        >
                          <input
                            [(ngModel)]="x.isAllowedForReplacement"
                            type="checkbox"
                            class="custom-control-input"
                            name="switch"
                            [checked]="x.isAllowedForReplacement == 1"
                          />
                          <label class="custom-control-label"> </label>
                        </div>
                      </td>
                      <td *ngIf="cardStatusFromRemotePermission == 'true'">
                        <button
                          (click)="cardStatusFromRemote(x)"
                          type="button"
                          class="btn btn-outline-primary btn-sm"
                        >
                          Status From Remote
                        </button>
                      </td>
                      <td>
                        <button
                          *ngIf="x.isDeleted == '0'"
                          (click)="viewCardDetail(x)"
                          type="button"
                          class="btn btn-outline-primary btn-sm ml-2"
                        >
                          View Detail
                        </button>

                        <button
                          *ngIf="x.balance != '0'"
                          data-toggle="modal"
                          data-target="#ReverseModel"
                          (click)="selectedRecodrd(x)"
                          type="button"
                          class="btn btn-outline-primary btn-sm ml-2"
                        >
                          Reverse Balance
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style="width: 100%; margin-top: 1%">
                  <h4 class="card-title" style="float: left">
                    <select class="form-control-sm" [(ngModel)]="selected">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </h4>
                  <pagination-controls
                    style="float: right"
                    (pageChange)="p = $event"
                  ></pagination-controls>
                </div>
                <div *ngIf="!listBody" class="container">
                  <div style="text-align: center; padding: 24px">
                    {{ message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- model -->
<div
  class="modal fade bd-example-modal-md"
  id="ReverseModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Reverse</h4>
              <form
                class="forms-sample"
                [formGroup]="getUpdateForm"
                (ngSubmit)="Reverse()"
              >
                <div class="card-subtitle">
                  Are you sure you want to refund Card balance of ${{ amount }}
                  to Customer Wallet
                </div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Replacement model -->

<div
  class="modal fade bd-example-modal-md"
  id="ReplacementModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Card Replacement</h4>
              <form
                class="forms-sample"
                [formGroup]="getReplacementForm"
                (ngSubmit)="hanldeCardReplacement()"
              >
                <div class="card-subtitle">
                  Please confirm with the reason you want to allow/disable
                  replacement for this card. Remember that we will notify the
                  customer for this change.
                </div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>

                <div class="form-group">
                  <label for="exampleInputUsername1">Reason</label>
                  <textarea
                    type="text"
                    class="form-control"
                    formControlName="description"
                    placeholder="Description"
                    [ngClass]="{
                      'is-invalid':
                        submitted &&
                        getReplacementForm.controls.description.errors
                    }"
                  ></textarea>
                  <div
                    *ngIf="
                      submitted &&
                      getReplacementForm.controls.description.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        getReplacementForm.controls.description.errors.required
                      "
                    >
                      Description is required
                    </div>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- cardStatusFromRemote model -->
<div
  class="modal fade bd-example-modal-md"
  id="statusFromRemoteModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Card Replacement</h4> -->
              <form class="forms-sample" [formGroup]="getReplacementForm">
                <!-- <div class="card-subtitle">
                  Card Status From Remote is {{ statusCardFromRemote }}
                </div> -->

                <div
                  class="alert"
                  [ngClass]="{
                    'alert-success': statusCardFromRemote === 'Approved',
                    'alert-danger': statusCardFromRemote !== 'Approved'
                  }"
                  role="alert"
                >
                  Card Status =>
                  <strong>{{ statusCardFromRemote }}</strong>
                </div>

                <!-- <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div> -->
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
