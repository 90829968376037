import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';

@Component({
  selector: 'app-service-product',
  templateUrl: './service-product.component.html',
  styleUrls: ['./service-product.component.css'],
  animations: [
    fade
  ]
})
export class ServiceProductComponent implements OnInit {
  dataList;
  sub: Subscription;
  queryProductId: number;
  productId: any;
  productMessage: string;
  queryProductName: number;
  productName: any;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  message;
  listBody: boolean;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryProductId = +params['productId'];
        this.queryProductName = params['productName'];
        if (Number.isNaN(this.queryProductId)) {
          this.productId = "";
          this.productName = "";
        } else {
          this.productId = this.queryProductId;
          this.productName = this.queryProductName;
          this.fnListProductServices(this.productId, this.queryProductName);
        }
        //this.fnListTransactionHistoryWithMerchantId(this.queryMerchantId);
      });
   }

  ngOnInit(): void {
  }

  fnListProductServices(productId, productName) {
    let payload: any = {
      productId: productId
    };
    this.service.getProductService({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      if (successResponse.success) {
        this.listBody = false;
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.listBody = true;
        this.message = data.records.message;
      }
    }, (failureResponse: string) => {
      this.toastr.error("Something went wrong")
      //this.displayCreateButton = true;
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  passCreateProductServiceFields(){
    var currentUser = this._sessionStorageService.getSessionData();
    let userId = currentUser.userId;
    this.router.navigate(['dashboard/view/createProductServices'], { queryParams: { userId } });
  }

  passViewProductInfo(x){
    let serviceProfileId = x.serviceProfileId;
    let productId = this.productId;
    this.router.navigate(['dashboard/view/getProductServices'], { queryParams: { serviceProfileId, productId } });
  }

  passViewProductProcessInfo(x){
    let serviceProfileId = x.serviceProfileId;
    this.router.navigate(['dashboard/view/listProductServiceRate'], { queryParams: { serviceProfileId } });
  }

}
