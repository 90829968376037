import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-createdist',
  templateUrl: './createdist.component.html',
  styleUrls: ['./createdist.component.css'],
  animations: [
    fade
  ]
})
export class CreatedistComponent implements OnInit {

  _USER_TYPE_ID_DISTRIBUTOR = 20;
  serviceForm: FormGroup;
  queryUserId: number;
  userId: any;
  dataRecords: any;
  sub: Subscription;
  submitted: boolean = false;
  selectCountryData;
  selectCitiesData;
  selectMessage;
  selectedMessage;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getCountryList();
    this.getCitiesList();
  }


  createForm() {
    this.serviceForm = this.fb.group({
      companyName: ['', Validators.required],
      companyEmail: ['', Validators.required],
      companyPhone: ['', Validators.required],
      companyAddress: ['', Validators.required],
      companyWebsite: ['', Validators.required],
      cityId: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      loginId: ['', Validators.required],
      // password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit(): void {
  }

  backToService() {
    this.router.navigate(["dashboard/listDistributorUserInfo"])
  }

  getCountryList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCountryList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      // console.log("Country=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCountryData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getCitiesList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCitiesList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      console.log("Cities=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCitiesData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectedMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  OnCreateDistributorUser() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = this._USER_TYPE_ID_DISTRIBUTOR;
    let payload: any = {
      createdByUserId: currentUser.userId,
      firstName: this.serviceForm.value.firstName,
      middleName: this.serviceForm.value.middleName,
      lastName: this.serviceForm.value.lastName,
      telephone: this.serviceForm.value.telephone,
      address: this.serviceForm.value.address,
      email: this.serviceForm.value.email,
      address2: this.serviceForm.value.address2,
      city: this.serviceForm.value.city,
      country: this.serviceForm.value.country,
      website: this.serviceForm.value.website,
      userTitle: this.serviceForm.value.userTitle,
      loginId: this.serviceForm.value.loginId,
      // loginPwd: this.serviceForm.value.password,
      userTypeId: userTypeId
    };
    // console.log("payload", payload)
    this.spinner.getSpinner()
    this.service.createDistributorUser(payload).subscribe(successResponse => {
      console.log("HHHHHH=> ", successResponse)

      // let { id } = successResponse.message.payLoad.id;

      if (successResponse.id) {
        this.spinner.hideSpinner();
        this.toastr.success(`You have created Id ${successResponse.id}`, "Seccessfully")
        this.router.navigate(["dashboard/listDistributorUserInfo"]);
      } else {
        this.toastr.error("Something went wrong");
        this.spinner.hideSpinner();
      }
    }, (failureResponse: any) => {
      this.spinner.hideSpinner();
      this.toastr.warning(failureResponse.error.message.error.message, "Warning");
      console.log("onFailure:" + JSON.stringify(failureResponse.error.message.error.message));
    });
  }


}
