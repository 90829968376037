<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth px-0">
      <div class="row w-100 mx-0">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left py-5 px-4 px-sm-5">
            <div class="brand-logo">
              <img
                src="../../../assets/images/{{ tab }}"
                style="height: 70px; width: 70px"
                alt="logo"
              />
            </div>
            <h4>Two Factor Authentication (2FA)</h4>
            <h6 class="font-weight-light">
              Enter the OTP code that was sent to your email.
            </h6>
            <form
              class="pt-3"
              [formGroup]="serviceForm"
              novalidate
              (ngSubmit)="twoFactorAuthentication()"
            >
              <div class="form-group">
                <input
                  type="password"
                  class="form-control form-control-lg"
                  placeholder="OTP Code"
                  formControlName="otpCode"
                  [ngClass]="{
                    'is-invalid':
                      submitted && serviceForm.controls.otpCode.errors
                  }"
                />
                <div
                  *ngIf="submitted && serviceForm.controls.otpCode.errors"
                  class="text-danger"
                >
                  <div *ngIf="serviceForm.controls.otpCode.errors.required">
                    OTP Code is required
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <button
                  type="submit"
                  [disabled]="isLoading"
                  class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                >
                  <span *ngIf="!isLoading"> Authenticate</span>
                  <span *ngIf="isLoading">
                    <i
                      class="fa fa-spinner fa-spin"
                      [style.fontSize.px]="spinnerSize"
                    ></i>
                  </span>
                </button>
                <!-- <button
                  type="submit"
                  class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                >
                  Authenticate
                </button> -->
              </div>

              <div class="text-center mt-4 font-weight-light">
                WaafiPay Admin Portal <a class="text-primary">SafariOne .Inc</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
<app-footer style="margin: 0px !important"> </app-footer>
