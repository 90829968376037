import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ExcelService } from 'src/app/service/service/excel.service';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-gl-account-summary-table',
  templateUrl: './gl-account-summary-table.component.html',
  styleUrls: ['./gl-account-summary-table.component.css'],
  animations: [
    fade
  ]
})
export class GlAccountSummaryTableComponent implements OnInit {

  getForm: FormGroup;
  getSubmitted = false;
  listBody = false;
  selectData;
  tranStatusId;
  message;
  listData;

  submitted: boolean = false;

  accountTypeId;
  accountId;
  startDate;
  endDate;
  currencyId;
  sumCredit;
  sumDebit;
  sumTotal;
  sumPrevious;

  selected = pagination.selected;
  p: number = pagination.p;
  term;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private excelService: ExcelService,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.route.queryParams.subscribe(params => {
      // this.accountId = params['accountId'];
      // this.accountTypeId = params['accountTypeId'];
      this.currencyId = params['currencyId'];
      this.startDate = params['startDate'];
      this.endDate = params['endDate'];
      this.fnGlAccountSummaryReports(this.currencyId, this.startDate, this.endDate);
    });
    // this.fnGlAccountSummaryReports();

  }

  ngOnInit(): void {
  }

  // print() {
  //   // this.submitted = true;
  //   // if (this.getForm.invalid) return;
  //   let type = 1;
  //   let no = 1;
  //   this.router.navigate(["dashboard/print"], { queryParams: { startDate: this.startDate, currencyId: this.currencyId, no, endDate: this.endDate, type } })
  // }
  // fnPdf() {
  //   let type = 1;
  //   let no = 2;
  //   this.router.navigate(["dashboard/print"], { queryParams: { startDate: this.startDate, currencyId: this.currencyId, no, endDate: this.endDate, type } })
  // }
  // exportAsXLSX() {
  //   let type = 1;
  //   let no = 3;
  //   this.router.navigate(["dashboard/print"], { queryParams: { startDate: this.startDate, currencyId: this.currencyId, no, endDate: this.endDate, type } })
  // }

  backToService() {
    this._location.back();
  }

  fnGlAccountSummaryReports(currencyId?, startDate?, endDate?) {
    this.listBody = false;
    let payload: any = {
      currencyId: currencyId,
      startDate: startDate,
      endDate: endDate
    };
    this.spinner.getSpinner();
    this.service.getAdminGlAccountsSummaryReport({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      console.log("Reeq=>", JSON.parse(data.records))
      if (data.success) {
        if (data.data) {
          this.spinner.hideSpinner();
          this.listBody = true;
          let credit = null;
          let debit = null;
          let previous = null;
          let total = null;
          let list = [];
          JSON.parse(data.records).forEach(function (el) {
            let record = {
              accountId: el.accountId,
              accountNo: el.accountNo,
              accountDesc: el.accountDesc,
              previousBalance: el.previousBalance,
              debit: el.debit,
              credit: el.credit,
              balance: el.balance
              // balance: el.credit - el.debit
            }
            list.push(record)
            console.log("For", el);
            debit += el.debit;
            credit += el.credit;
            previous += el.previousBalance;
            total += el.credit - el.debit;
          })
          this.sumCredit = credit;
          this.sumDebit = debit;
          this.sumPrevious = previous;
          this.sumTotal = credit - debit;
          this.listData = list;
        } else {
          this.spinner.hideSpinner();
          this.message = data.records.message;
          this.listBody = false;
        }
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.warning(data.records.message, "Warning")
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      // this.createFailureMessage = true;
      this.toastr.error("Server seems got bad request!");
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  passTransactionInfo(info) {
    let tranId = info.transactionId
    this.router.navigate(["dashboard/view/transactionInfo"], { queryParams: { tranId } })
  }

  print() {
    let printContents = document.getElementById('section-one').innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    setTimeout(() => {
      window.location.reload();
    }, 1000)
    // this._location.back();
  }
  fnPDF() {
    html2canvas(document.getElementById("section-one"), { allowTaint: true }).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) + 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height + i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(`waafipay_${moment().format('MMM Do YYYY')}_waafipay_getGLAccountSummary_Report.pdf`);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000)
      // this._location.back();
    });
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.listData, 'waafipay_getGLAccountSummary');
    // this._location.back();
  }
}
