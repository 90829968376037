<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <h4 class="card-title">Merchant Account List</h4>
                    </div>
                    <div class="col-md-3">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back</button>
                        </h4>
                        <h4 class="card-title mr-2" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="createMerchantAccount()">Add Account</button>
                        </h4>
                    </div>
                    <!-- <div class="col-md-2 m-0">
                    </div> -->
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Account Id</th>
                                <th>Currency Code</th>
                                <th>Account Type Id</th>
                                <th>CreatedByUser Id</th>
                                <th>User Id</th>
                                <th>Settlement Info</th>
                                <th>View</th>
                                <th>Default A/C</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listBody">
                            <tr
                                *ngFor="let x of listData | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td>
                                    {{ x.accountId}}
                                </td>
                                <td>{{ x.currency}}</td>
                                <td>{{ x.accountTypeId}}</td>
                                <td>{{ x.createdByUserId}}</td>
                                <td>{{ x.userId}}</td>
                                <td (click)="fnViewMerchantSettlementInfo(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                                <td (click)="viewMerchantAccountsInfo(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                                <td (click)="updateMerchantDefAccountIds(x)">
                                    <div class="custom-control custom-switch">
                                        <input [(ngModel)]="x.accountId == accountId" type="checkbox"
                                            class="custom-control-input"  name="switch">
                                        <label class="custom-control-label">
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>