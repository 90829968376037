import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from 'src/app/service/service/service.service';
import { SessionStorageService } from 'src/app/service/session-storage/session-storage.service';
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-table-merchant-approvals',
  templateUrl: './table-merchant-approvals.component.html',
  styleUrls: ['./table-merchant-approvals.component.css'],
  animations: [
    fade
  ]
})
export class TableMerchantApprovalsComponent implements OnInit {
  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  _USER_TYPE_ID_CASHIER = "40";
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  sub: any;
  selectCycle;

  selected = pagination.selected;
  p: number = pagination.p;
  term;
  dataLists;
  merchantList;
  cashierTypeList;
  selectedData;
  isApproved: boolean = false;
  validData = 'abc';



  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryuserTypeId = +params['userTypeId'];
        if (Number.isNaN(this.queryuserTypeId)) {
          this.userTypeId = "";
        } else {
          this.userTypeId = this.queryuserTypeId;
          console.log("HHHH => ", this.userTypeId)
          this.getMerchantUserList(this.queryuserTypeId)
        }
      });
  }

  ngOnInit(): void {

  }



  getMerchantUserList(userTypeId?) {
    // this.getSubmitted = true;
    // if (this.getForm.invalid) {
    //     return;
    // }
    let payload: any = {
      distributorId: userTypeId,
      cycleStatusId: 1

    };
    console.log(payload)

    this.isTrue = true;
    this.spinner.getSpinner();
    this.service.getDistributorMerchantApproval({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;
          console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }



  passApprovelData(x){
    let merchantId = x.merchantId;
    this.router.navigate(["dashboard/view/approvalMerchantAndCashier"], { queryParams: { merchantId }});
  }
}
