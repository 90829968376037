import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "src/app/service/service/service.service";
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import AppID from 'ibmcloud-appid-js';
import * as moment from 'moment';


@Component({
  selector: 'app-merchant-active-info',
  templateUrl: './merchant-active-info.component.html',
  styleUrls: ['./merchant-active-info.component.css'],
  animations: [
    fade
  ]
})
export class MerchantActiveInfoComponent implements OnInit {
  _USER_TYPE_ID_MERCHANT = 30;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  getForm: FormGroup;
  updateAccessForm: FormGroup;
  updateResonForm: FormGroup;
  querymerchantId: number;
  merchantId: any;
  sub: Subscription;
  queryUserId: number;
  userId: any;
  dataRecords: any;
  submitted = false;
  getSubmitted = false;
  message = 'Data Not Found';
  modal;
  selectData: any;
  selectProductData;
  productId;
  isTrue: boolean = false;
  event;
  selectAccess;
  accessProfileId;
  btnDisabled: boolean = false;
  dataList;
  eventTypeId;
  addressList;
  statusData;
  newValue;
  selectedValue;


  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService,
    private _location: Location
  ) {
    this.updateForm();
    this.updatedAccessForm();
    this.updateResonsForm();
    this.getUpdateInfo();
    this.getUserAddress();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.querymerchantId = +params['merchantId'];
        if (Number.isNaN(this.querymerchantId)) {
          this.merchantId = "";
        } else {
          this.merchantId = this.querymerchantId;
          // console.log("HHHH => ", this.merchantId)
          this.viewMerchantUserInfo(this.merchantId)
        }
      });

  }




  updateForm() {
    this.updatesForm = this.fb.group({
      distributorId: [''],
      merchantUId: [''],
      userId: [''],
      selectedAccess: ['', Validators.required],
      productName: [''],
      userTitle: [''],
    });
  }


  async ngOnInit() {
    this.event = 'Edit'
    this.updatesForm.disable()
    console.log(this.statusData)
  }

  onChange(deviceValue) {
    if (this.selectedValue === this.selectAccess[deviceValue.target.selectedIndex -1].profileStatusId) {
      this.newValue = this.selectAccess[deviceValue.target.selectedIndex -1].profileStatusDesc;
      console.log(this.selectAccess[deviceValue.target.selectedIndex -1].profileStatusDesc);
    } 
  }

  getUserAddress() {
    this.service.getIpAddress().subscribe(res => {
      console.log(res);
      this.addressList = res;
      console.log(window.location.href);
    })
  }

  updatedAccessForm() {
    this.updateAccessForm = this.fb.group({
      selectedAccess: ['', Validators.required],

    });
  }
  updateResonsForm() {
    this.updateResonForm = this.fb.group({
      reson: ['', Validators.required],
    });
  }

  getUpdateInfo() {
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getUserAccessProfileStatus({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // console.log("data :=> ", records);
      this.selectAccess = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onButtonClick(event) {
    if (event == 'Edit') {
      this.event = 'Disabled'
      this.updatesForm.enable()
      this.btnDisabled = true;
    } else {
      this.event = 'Edit'
      this.updatesForm.disable()
      this.btnDisabled = false;
    }
  }

  backToService() {
    this._location.back();
  }

  viewMerchantUserInfo(merchantId) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId,
    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      console.log("UUUU", data)
      this.accessProfileId = data.accessProfileId;
      this.dataList = data;
      this.updatesForm = this.fb.group({
        userId: [data.userId, Validators.required],
        distributorId: [data.distributorId, Validators.required],
        productName: [data.productName, Validators.required],
        merchantUId: [data.merchantUid, Validators.required],
        selectedMerchantGroupValue: [data.merchantGroupId, Validators.required],
        selectedAccess: [data.profileStatusId, Validators.required],
        userTitle: [data.userTitle, Validators.required],

      });
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onUpdateAccessProfileInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updatesForm.invalid || this.updateResonForm.invalid) {
      return;
    }
    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.updatesForm.value.selectedAccess
    };
    console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (data.accessProfileId) {
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        this.viewMerchantUserInfo(this.merchantId);
        this.fnCreateEventLogs();
      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  fnCreateEventLogs() {
    var currentUser = this._sessionStorageService.getSessionData();

    // if (this.updatesForm.value.selectedAccess === 1) {
    //   this.eventTypeId = 5
    //   this.newValue = 'disabled'
    // } else if (this.updatesForm.value.selectedAccess === 3) {
    //   this.eventTypeId = 3
    //   this.newValue = 'suspended'
    // } else if (this.updatesForm.value.selectedAccess === 4) {
    //   this.eventTypeId = 4
    //   this.newValue = 'active'
    // }
    // console.log(currentUser);
    let payload: any = {
      userId: this.dataList.userId,
      eventTypeId: 1,
      previousValue: this.dataList.profileStatusDesc,
      newValue: this.newValue,
      reson: this.updateResonForm.value.reson,
      sessionId: currentUser.referenceId,
      address: JSON.stringify(this.addressList),
      ipAddress: this.addressList,
      applicationId: currentUser.userTypeId,
      doneByUserId: currentUser.userId,
    };
    console.log("jajaHHH", payload)
    this.service.createEventLogs({ payload: payload }).subscribe((res) => {
      console.log(res)

    })
    console.log(payload)
  }

}
