import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-distributor-commission-table',
  templateUrl: './distributor-commission-table.component.html',
  styleUrls: ['./distributor-commission-table.component.css'],
  animations: [
    fade
  ]
})
export class DistributorCommissionTableComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;

  queryDistributorId;
  startDate;
  queryStartDate;
  endDate;
  queryEndDate;
  payerId;
  queryPayerId;
  statusId;
  queryStatusId;

  queryuserDistributorId: any;
  sub: any;
  srcAccountId;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.route.queryParams.subscribe(params => {
      this.queryDistributorId= params['distributorId'];
      this.queryStartDate = params['startDate'];
      this.queryEndDate = params['endDate'];
      this.distributorId = this.queryDistributorId;
      this.startDate = this.queryStartDate;
      this.endDate = this.queryEndDate;
      this.fnListDistributorCommissionReport(this.distributorId, this.startDate, this.endDate,)
    });

  }


  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }

  onPrintInvoice(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     window.location.reload();;
  }



  fnListDistributorCommissionReport(distributorId?, startDate?, endDate?) {
    let payload: any = {
      distributorId: distributorId,
      startDate: startDate,
      endDate: endDate,
      recordCount: 100
    };
    this.spinner.getSpinner();
    this.service.getDistributorCommissionActivityReports({ payload: payload }).subscribe((successResponse:any )=> {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;
          // console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  };

}
