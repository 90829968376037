// @ Module
import { AgmCoreModule } from '@agm/core';
import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QRCodeModule } from 'angularx-qrcode';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { DistributorListComponent } from './components/contents/waafi-card/branches/distributorList/distributor-list.component';
import { SpinnerService } from './service/service/spinner.service';
// @ Components
import { AppRoutingModule, routingComponents } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { ChangeUserPasswordComponent } from './components/contents/accounts/change-user-password/change-user-password.component';
import { CreateDistibutorUserComponent } from './components/contents/distributorsUsers/createdist.component';
import { EventLogsComponent } from './components/contents/event-log/event-logs/event-logs.component';
import { EventLogInfoComponent } from './components/contents/event-log/event-logs/view/event-log-info/event-log-info.component';
import { HomeComponent } from './components/contents/home/home.component';
import { BillingMgComponent } from './components/contents/merchant-management/billing-mg/billing-mg.component';
import { CreateBillersComponent } from './components/contents/merchant-management/billing-mg/create-billers/create-billers.component';
import { ViewBillingInfoComponent } from './components/contents/merchant-management/billing-mg/view-billing-info/view-billing-info.component';
import { CashierActivationComponent } from './components/contents/merchant-management/cashier-activation/cashier-activation.component';
import { CashierActiveTableComponent } from './components/contents/merchant-management/cashier-activation/view/cashier-active-table/cashier-active-table.component';
import { AccountsTableComponent } from './components/contents/merchant-management/merchant-accounts/accounts-table/accounts-table.component';
import { CreateMerchantAccountsComponent } from './components/contents/merchant-management/merchant-accounts/create-merchant-accounts/create-merchant-accounts.component';
import { MerchantAccountsComponent } from './components/contents/merchant-management/merchant-accounts/merchant-accounts.component';
import { MerchantAccountInfoComponent } from './components/contents/merchant-management/merchant-accounts/view/merchant-account-info/merchant-account-info.component';
import { SettlementAccountInfoComponent } from './components/contents/merchant-management/merchant-accounts/view/settlement-account-info/settlement-account-info.component';
import { MerchantActivationComponent } from './components/contents/merchant-management/merchant-activation/merchant-activation.component';
import { MerchantActiveInfoComponent } from './components/contents/merchant-management/merchant-activation/view/merchant-active-info/merchant-active-info.component';
import { ListMerchantApprovalsComponent } from './components/contents/merchant-management/merchant-approvals/list-merchant-approvals/list-merchant-approvals.component';
import { TableMerchantApprovalsComponent } from './components/contents/merchant-management/merchant-approvals/list-merchant-approvals/table-merchant-approvals/table-merchant-approvals.component';
import { CreateCashierMerchantApprovalComponent } from './components/contents/merchant-management/merchant-approvals/list-merchant-approvals/table-merchant-approvals/view/create-cashier-merchant-approval/create-cashier-merchant-approval.component';
import { MerchantBillerTableComponent } from './components/contents/merchant-management/merchant-billers/merchant-biller-table/merchant-biller-table.component';
import { MerchantBillersComponent } from './components/contents/merchant-management/merchant-billers/merchant-billers.component';
import { MerchantPaymentProviderTableComponent } from './components/contents/merchant-management/merchant-payment-provider/merchant-payment-provider-table/merchant-payment-provider-table.component';
import { AddPaymentMethodComponent } from './components/contents/merchant-management/merchant-payment-provider/merchant-payment-provider-table/view/add-payment-method/add-payment-method.component';
import { MerchantPaymentProviderComponent } from './components/contents/merchant-management/merchant-payment-provider/merchant-payment-provider.component';
import { ResetMerchantPasswordComponent } from './components/contents/merchant-management/reset-merchant-password/reset-merchant-password.component';
import { ResetPasswordInfoComponent } from './components/contents/merchant-management/reset-merchant-password/view/reset-password-info/reset-password-info.component';
import { NotfoundComponent } from './components/contents/notfound/notfound.component';
import { CreateInvoiceComponent } from './components/contents/payment-provider/create-invoice/create-invoice.component';
import { GetCreateInvoiceComponent } from './components/contents/payment-provider/create-invoice/get-create-invoice/get-create-invoice.component';
import { GetInvoiceListComponent } from './components/contents/payment-provider/get-invoice-list/get-invoice-list.component';
import { GetInvoiceInfoComponent } from './components/contents/payment-provider/get-invoice-list/view/get-invoice-info/get-invoice-info.component';
import { ProcessInvoiceInfoComponent } from './components/contents/payment-provider/get-invoice-list/view/process-invoice-info/process-invoice-info.component';
import { ProcessInvoiceComponent } from './components/contents/payment-provider/process-invoice/process-invoice.component';
import { ProductListComponent } from './components/contents/product-managment/product-list/product-list.component';
import { CreateProductComponent } from './components/contents/product-managment/product-list/view/create-product/create-product.component';
import { GetInfoProductComponent } from './components/contents/product-managment/product-list/view/get-info-product/get-info-product.component';
import { ServiceProductComponent } from './components/contents/product-managment/product-list/view/service-product/service-product.component';
import { CreateRateComponent } from './components/contents/product-managment/product-list/view/service-product/service-rate/create-rate/create-rate.component';
import { GetRateComponent } from './components/contents/product-managment/product-list/view/service-product/service-rate/get-rate/get-rate.component';
import { ServiceRateComponent } from './components/contents/product-managment/product-list/view/service-product/service-rate/service-rate.component';
import { CreatesServiceComponent } from './components/contents/product-managment/product-list/view/service-product/view/creates-service/creates-service.component';
import { ViewServiceComponent } from './components/contents/product-managment/product-list/view/service-product/view/view-service/view-service.component';
import { ChargeBackReportsComponent } from './components/contents/reports/charge-back-reports/charge-back-reports.component';
import { ChargeBackTableComponent } from './components/contents/reports/charge-back-reports/charge-back-table/charge-back-table.component';
import { ChargeBackSummaryReportsComponent } from './components/contents/reports/charge-back-summary-reports/charge-back-summary-reports.component';
import { ChargeBackSummaryTableComponent } from './components/contents/reports/charge-back-summary-reports/charge-back-summary-table/charge-back-summary-table.component';
import { DistCommissionByMonthReportComponent } from './components/contents/reports/dist-commission-by-month-report/dist-commission-by-month-report.component';
import { DistCommissionByMonthTableReportComponent } from './components/contents/reports/dist-commission-by-month-report/view/dist-commission-by-month-table-report/dist-commission-by-month-table-report.component';
import { DistCommissionByYearReportComponent } from './components/contents/reports/dist-commission-by-year-report/dist-commission-by-year-report.component';
import { DistCommissionByYearTableReportComponent } from './components/contents/reports/dist-commission-by-year-report/view/dist-commission-by-year-table-report/dist-commission-by-year-table-report.component';
import { DistributorCommissionReportsComponent } from './components/contents/reports/distributor-commission-reports/distributor-commission-reports.component';
import { DistributorCommissionTableComponent } from './components/contents/reports/distributor-commission-reports/distributor-commission-table/distributor-commission-table.component';
import { GlAccountActivityReportComponent } from './components/contents/reports/gl-account-activity-report/gl-account-activity-report.component';
import { GlAccountActivityTableReportComponent } from './components/contents/reports/gl-account-activity-report/view/gl-account-activity-table-report/gl-account-activity-table-report.component';
import { GlAccountBalanceReportsComponent } from './components/contents/reports/gl-account-balance-reports/gl-account-balance-reports.component';
import { GlAccountSummaryReportsComponent } from './components/contents/reports/gl-account-summary-reports/gl-account-summary-reports.component';
import { GlAccountSummaryTableComponent } from './components/contents/reports/gl-account-summary-reports/view/gl-account-summary-table/gl-account-summary-table.component';
import { GlAccountsSummaryByDayComponent } from './components/contents/reports/gl-accounts-summary-by-day/gl-accounts-summary-by-day.component';
import { GlAccountsSummaryByDayTableComponent } from './components/contents/reports/gl-accounts-summary-by-day/view/gl-accounts-summary-by-day-table/gl-accounts-summary-by-day-table.component';
import { MerchantActivitySummaryByDayComponent } from './components/contents/reports/merchant-activity-summary-by-day/merchant-activity-summary-by-day.component';
import { MerchantActivitySummaryByDayTableComponent } from './components/contents/reports/merchant-activity-summary-by-day/view/merchant-activity-summary-by-day-table/merchant-activity-summary-by-day-table.component';
import { MerchantActivitySummaryByMonthComponent } from './components/contents/reports/merchant-activity-summary-by-month/merchant-activity-summary-by-month.component';
import { MerchantActivitySummaryByMonthTableComponent } from './components/contents/reports/merchant-activity-summary-by-month/view/merchant-activity-summary-by-month-table/merchant-activity-summary-by-month-table.component';
import { MerchantActivityTableComponent } from './components/contents/reports/merchant-activity/merchant-activity-table/merchant-activity-table.component';
import { MerchantActivityComponent } from './components/contents/reports/merchant-activity/merchant-activity.component';
import { MerchantDailySummaryByDistComponent } from './components/contents/reports/merchant-daily-summary-by-dist/merchant-daily-summary-by-dist.component';
import { MerchantDailySummaryByDistTableComponent } from './components/contents/reports/merchant-daily-summary-by-dist/view/merchant-daily-summary-by-dist-table/merchant-daily-summary-by-dist-table.component';
import { MerchantMonthlySummaryByDistComponent } from './components/contents/reports/merchant-monthly-summary-by-dist/merchant-monthly-summary-by-dist.component';
import { MerchantMonthlySummaryByDistTableComponent } from './components/contents/reports/merchant-monthly-summary-by-dist/view/merchant-monthly-summary-by-dist-table/merchant-monthly-summary-by-dist-table.component';
import { MerchantSubscriptionComponent } from './components/contents/reports/merchant-subscription/merchant-subscription.component';
import { GetSubscriptionReportComponent } from './components/contents/reports/merchant-subscription/view/get-subscription-report/get-subscription-report.component';
import { MerchantUsageSummaryReportComponent } from './components/contents/reports/merchant-usage-summary-report/merchant-usage-summary-report.component';
import { MerchantUsageSummaryTableReportComponent } from './components/contents/reports/merchant-usage-summary-report/view/merchant-usage-summary-table-report/merchant-usage-summary-table-report.component';
import { PaymentTranSummaryReportsComponent } from './components/contents/reports/payment-tran-summary-reports/payment-tran-summary-reports.component';
import { PaymentTranSummaryTableComponent } from './components/contents/reports/payment-tran-summary-reports/payment-tran-summary-table/payment-tran-summary-table.component';
import { PosMerchantActivityReportComponent } from './components/contents/reports/pos-merchant-activity-report/pos-merchant-activity-report.component';
import { PosMerchantActivityTableReportComponent } from './components/contents/reports/pos-merchant-activity-report/view/pos-merchant-activity-table-report/pos-merchant-activity-table-report.component';
import { PosMerchantUsageSummaryReportComponent } from './components/contents/reports/pos-merchant-usage-summary-report/pos-merchant-usage-summary-report.component';
import { PosMerchantUsageSummaryTableReportComponent } from './components/contents/reports/pos-merchant-usage-summary-report/view/pos-merchant-usage-summary-table-report/pos-merchant-usage-summary-table-report.component';
import { PrintPageComponent } from './components/contents/reports/print-page/print-page.component';
import { AssignwebpermissionComponent } from './components/contents/settings/assignwebpermission/assignwebpermission.component';
import { MerchantconfigComponent } from './components/contents/settings/merchantconfig/merchantconfig.component';
import { MerchantgroupsComponent } from './components/contents/settings/merchantgroups/merchantgroups.component';
import { SettingconfigComponent } from './components/contents/settings/merchantgroups/settingconfig/settingconfig.component';
import { RoutconfigComponent } from './components/contents/settings/routconfig/routconfig.component';
import { CreateroutComponent } from './components/contents/settings/routconfig/view/createrout/createrout.component';
import { UpdateroutComponent } from './components/contents/settings/routconfig/view/updaterout/updaterout.component';
import { ServicesconfigComponent } from './components/contents/settings/servicesconfig/servicesconfig.component';
import { CreateServiceComponent } from './components/contents/settings/servicesconfig/view/create-service/create-service.component';
import { UpdateServiceComponent } from './components/contents/settings/servicesconfig/view/update-service/update-service.component';
import { SysconfigComponent } from './components/contents/settings/sysconfig/sysconfig.component';
import { CreateComponent } from './components/contents/settings/sysconfig/view/create/create.component';
import { UpdateComponent } from './components/contents/settings/sysconfig/view/update/update.component';
import { UserrolesComponent } from './components/contents/settings/userroles/userroles.component';
import { WebpermissionComponent } from './components/contents/settings/webpermission/webpermission.component';
import { GenerateSettlementComponent } from './components/contents/settlement-management/settlement-direct/generate-settlement/generate-settlement.component';
import { SettlementDirectComponent } from './components/contents/settlement-management/settlement-direct/settlement-direct.component';
import { SettlementCommissionTableComponent } from './components/contents/settlement-management/settlement-distributor-commission/settlement-commission-table/settlement-commission-table.component';
import { ProcessCommissionComponent } from './components/contents/settlement-management/settlement-distributor-commission/settlement-commission-table/view/process-commission/process-commission.component';
import { SettlementDistributorCommissionComponent } from './components/contents/settlement-management/settlement-distributor-commission/settlement-distributor-commission.component';
import { SettlementHistoryComponent } from './components/contents/settlement-management/settlement-history/settlement-history.component';
import { SettlementTableComponent } from './components/contents/settlement-management/settlement-history/settlement-table/settlement-table.component';
import { GetSettlementInfoComponent } from './components/contents/settlement-management/settlement-history/settlement-table/view/get-settlement-info/get-settlement-info.component';
import { ProcessSettlementInfoComponent } from './components/contents/settlement-management/settlement-history/settlement-table/view/process-settlement-info/process-settlement-info.component';
import { SettlementPeriodicRequestComponent } from './components/contents/settlement-management/settlement-periodic-request/settlement-periodic-request.component';
import { SettlementRequestListComponent } from './components/contents/settlement-management/settlement-request-list/settlement-request-list.component';
import { SettlementRequestTableComponent } from './components/contents/settlement-management/settlement-request-list/settlement-request-table/settlement-request-table.component';
import { GetSettlementRequestInfoComponent } from './components/contents/settlement-management/settlement-request-list/settlement-request-table/view/get-settlement-request-info/get-settlement-request-info.component';
import { CreateSettlementRequestComponent } from './components/contents/settlement-management/settlement-request/create-settlement-request/create-settlement-request.component';
import { SettlementRequestComponent } from './components/contents/settlement-management/settlement-request/settlement-request.component';
import { DeviceTerminalsListComponent } from './components/contents/terminal-management/device-terminals-list/device-terminals-list.component';
import { PosTransactionComponent } from './components/contents/terminal-management/pos-transaction/pos-transaction.component';
import { PosTransactionTableInfoComponent } from './components/contents/terminal-management/pos-transaction/view/pos-transaction-table-info/pos-transaction-table-info.component';
import { ChargebacksComponent } from './components/contents/transactions/chargebacks/chargebacks.component';
import { GetChargebacksListComponent } from './components/contents/transactions/chargebacks/view/get-chargebacks-list/get-chargebacks-list.component';
import { GetChargebacksComponent } from './components/contents/transactions/chargebacks/view/get-chargebacks/get-chargebacks.component';
import { TransactionCancellationComponent } from './components/contents/transactions/transaction-cancellation/transaction-cancellation.component';
import { TransactionHistoryComponent } from './components/contents/transactions/transaction-history/transaction-history.component';
import { TransactionInfoComponent } from './components/contents/transactions/transaction-info/transaction-info.component';
import { ViewTransactionInfoComponent } from './components/contents/transactions/transaction-info/view-transaction-info/view-transaction-info.component';
import { TransactionTableComponent } from './components/contents/transactions/transaction-table/transaction-table.component';
import { TransactionViewComponent } from './components/contents/transactions/transaction-table/transaction-view/transaction-view.component';
import { UnAmbiguousTransactionsComponent } from './components/contents/transactions/un-ambiguous-transactions/un-ambiguous-transactions.component';
import { AdminViewerComponent } from './components/contents/users/admin-viewer/admin-viewer.component';
import { AdminComponent } from './components/contents/users/admin/admin.component';
import { CreateusersComponent } from './components/contents/users/admin/view/createusers/createusers.component';
import { ViewusersComponent } from './components/contents/users/admin/view/viewusers/viewusers.component';
import { AssignUserRolesComponent } from './components/contents/users/assign-user-roles/assign-user-roles.component';
import { CashierCreateInfoComponent } from './components/contents/users/cashier/cashier-create-info/cashier-create-info.component';
import { CashierTableComponent } from './components/contents/users/cashier/cashier-table/cashier-table.component';
import { CashierComponent } from './components/contents/users/cashier/cashier.component';
import { ListCashierInfoComponent } from './components/contents/users/cashier/list-cashier-info/list-cashier-info.component';
import { ResendCredentialsComponent } from './components/contents/users/cashier/resend-credentials/resend-credentials.component';
import { DistributorComponent } from './components/contents/users/distributor/distributor.component';
import { CreatedistComponent } from './components/contents/users/distributor/view/createdist/createdist.component';
import { CreateDistributorAccountComponent } from './components/contents/users/distributor/view/distributor-accounts/create-distributor-account/create-distributor-account.component';
import { DistributorAccountsComponent } from './components/contents/users/distributor/view/distributor-accounts/distributor-accounts.component';
import { ViewdistComponent } from './components/contents/users/distributor/view/viewdist/viewdist.component';
// import { MerchantTableComponent } from './components/contents/users/merchant/merchant-table/merchant-table.component';
import { DistCommissionReportComponent } from './components/contents/reports/distributor-commission-new-report/dist-commission-report.component';
import { DistCommissionTableReportNewComponent } from './components/contents/reports/distributor-commission-new-report/dist-commission-table-report-new/dist-commission-table-report-new.component';
import { getWaafiQrPaymentsReportComponent } from './components/contents/reports/get-waafi-qr-payments-report/get-waafi-qr-payments-report.component';
import { TopUsageMerchantSummaryDistComponent } from './components/contents/reports/top-usage-merchant-summary/top-usage-merchant-summary-dist.component';
import { TopUsageMerchantSummaryTableComponent } from './components/contents/reports/top-usage-merchant-summary/view/top-usage-merchant-summary-table.component';
import { MerchantDetailsComponent } from './components/contents/terminal-management/merchant-details/merchant-details.component';
import { MerchantTableComponent } from './components/contents/terminal-management/merchant-table/merchant-table.component';
import { Pos_Merchant } from './components/contents/terminal-management/pos_merchant/pos_merchant.component';
import { ViewHtgTransectionInfo } from './components/contents/transactions/view-htg-transaction-info/view-htg-transection-info.component';
import { MerchantComponent } from './components/contents/users/merchant/merchant.component';
import { CreatemerchantComponent } from './components/contents/users/merchant/view/createmerchant/createmerchant.component';
import { ViewmerchantComponent } from './components/contents/users/merchant/view/viewmerchant/viewmerchant.component';
import { PartnerComponent } from './components/contents/users/partner/partner.component';
import { ThirdPartyMerchantComponent } from './components/contents/users/third-party-merchant/third-party-merchant.component';
import { BanksComponent } from './components/contents/vendormanagement/banks/banks.component';
import { CreateBankComponent } from './components/contents/vendormanagement/banks/create-bank/create-bank.component';
import { ViewBankComponent } from './components/contents/vendormanagement/banks/view-bank/view-bank.component';
import { ViewProcessComponent } from './components/contents/vendormanagement/banks/view-process/view-process.component';
import { CreatePaymentProcessorSystemComponent } from './components/contents/vendormanagement/payment-processor-system/create-payment-processor-system/create-payment-processor-system.component';
import { PaymentProcessorSystemComponent } from './components/contents/vendormanagement/payment-processor-system/payment-processor-system.component';
import { ViewPaymentProcessorSystemComponent } from './components/contents/vendormanagement/payment-processor-system/view-payment-processor-system/view-payment-processor-system.component';
import { CreateInfoComponent } from './components/contents/vendormanagement/paymentprocessor/create-info/create-info.component';
import { PaymentprocessorComponent } from './components/contents/vendormanagement/paymentprocessor/paymentprocessor.component';
import { ViewInfoComponent } from './components/contents/vendormanagement/paymentprocessor/view-info/view-info.component';
import { BlockedCardsReportDistr } from './components/contents/waafi-card/blocked-cards-report/blocked-cards-report-Distr.component';
import { BlockedCardsReportTable } from './components/contents/waafi-card/blocked-cards-report/view/blocked-cards-report-table.component';
import { BranchComponent } from './components/contents/waafi-card/branches/distributorList/view/Branch.component';
import { CustomerActivityReportDistComponent } from './components/contents/waafi-card/card-customer-activity-report/customer-activity-report-dist.component';
import { ViewCustomerActivityReportComponent } from './components/contents/waafi-card/card-customer-activity-report/view/view-customer-activity-report.component';
import { CardDistributors } from './components/contents/waafi-card/card-distributors/card-distributors-component';
import { CardMcHourlyDistrReport } from './components/contents/waafi-card/Card-Mc-Hourly-Report/Card-Mc-Hourly-Report-Distr.component';
import { CardMcHourlyReportTable } from './components/contents/waafi-card/Card-Mc-Hourly-Report/view/Card-Mc-Hourly-Report-Table.component';
import { CardSummaryByMonthReportDist } from './components/contents/waafi-card/card-summary-by-month-report/card-summary-by-month-report-Distr.component';
import { CardSummaryByMonthReportTable } from './components/contents/waafi-card/card-summary-by-month-report/view/card-summary-by-month-report.component';
import { DistributorListCardCustomersComponent } from './components/contents/waafi-card/cardCustomers/distributor-list-card-customers-component';
import { CustomersTableComponent } from './components/contents/waafi-card/cardCustomers/viewCustomers/waafi-customers-table.component';
import { DistributorListDeliveredCardComponent } from './components/contents/waafi-card/cardDelivered/distributor-list-delivered-cards.component';
import { DeliveredCardsComponent } from './components/contents/waafi-card/cardDelivered/view/Delivered-Cards.component';
import { CardPendingRequests } from './components/contents/waafi-card/CardPendingRequests/Card-Pending-Requests.component';
import { DistributorListPrintedCardComponent } from './components/contents/waafi-card/cardprintedList/distributor-list-printed-cards.component';
import { PrintedCardsComponent } from './components/contents/waafi-card/cardprintedList/view/Printed-Cards.component';
import { CardTransDistributorListComponent } from './components/contents/waafi-card/cardTransections/trans-distributor-list.component';
import { CardTransactionsComponent } from './components/contents/waafi-card/cardTransections/view/card-transactions-table.component';
import { ConfigurationComponent } from './components/contents/waafi-card/configuration/Configuration.component';
import { DetailedAuthorizationsReportComponent } from './components/contents/waafi-card/get-detailed-authorizations-report/get-detailed-authorizations-report.component';
import { DetailedTopupWithdrawalsReportComponent } from './components/contents/waafi-card/get-detailed-topup-withdrawals-report/get-detailed-topup-withdrawals-report.component';
import { GetSettlementDetailReportComponent } from './components/contents/waafi-card/get-settlement-detail-Report/get-settlement-detail-report.component';
import { GetSettlementSummaryReportComponent } from './components/contents/waafi-card/get-settlement-summary-report/get-settlement-summary-report.component';
import { TopUsageByCustomersDistComponent } from './components/contents/waafi-card/get-Top-Usage-By-Customers-report/top-usage-by-customers-dist.component';
import { CustomerTransactionDetails } from './components/contents/waafi-card/get-Top-Usage-By-Customers-report/viewCustomerDetail/view-customer-transaction-detail-component';
import { TopUsageByCustomersTableComponent } from './components/contents/waafi-card/get-Top-Usage-By-Customers-report/viewTopUsage/Top-Usage-By-Customers.component';
import { TopUsageByMerchantReportDist } from './components/contents/waafi-card/get-Top-Usage-By-Merchant-report/top-usage-by-merchant-report-distr.component';
import { TopUsgaeMerchantTransactionsDetailComponent } from './components/contents/waafi-card/get-Top-Usage-By-Merchant-report/view/merchantTransactionsDetail/view-merchant-transactions-detail-component';
import { TopUsageByMerchantReportTableComponent } from './components/contents/waafi-card/get-Top-Usage-By-Merchant-report/view/Top-Usage-By-Merchant-Report.component';
import { IssuingSummaryReportComponent } from './components/contents/waafi-card/issuing-summary-report/issuing-summary-report.component';
import { PhysicalCardReportDistr } from './components/contents/waafi-card/physical-card-report/physical-card-report-Distr.component';
import { PhysicalCardReportTable } from './components/contents/waafi-card/physical-card-report/view/physical-card-report-table.component';
import { TopupWithdrawalsSummaryReportComponent } from './components/contents/waafi-card/topup-withdrawals-summary-report/topup-withdrawals-summary-report.component';
import { TopupWithdrawalSummaryTableReportComponent } from './components/contents/waafi-card/topup-withdrawals-summary-report/view/topup-withdrawals-summary-table-report/topup-withdrawals-summary-table-report.component';
import { UsageSummaryByMonthReportComponent } from './components/contents/waafi-card/usage-summary-by-month-report/usage-summary-by-month-report.component';
import { UsageSummaryReportComponent } from './components/contents/waafi-card/usage-summary-report/usage-summary-report.component';
import { ViewCardDeatilsReportComponent } from './components/contents/waafi-card/viewCards/view-card-report.component';
import { ViewCardDeatilComponent } from './components/contents/waafi-card/viewCards/viewCardDetail/view-card-detail.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { ResetAdminPasswordComponent } from './components/reset-admin-password/reset-admin-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RemoveSpaces, SidebarComponent } from './components/sidebar/sidebar.component';
import { TwoFactorAuthenticationComponent } from './components/Two-Factor-Authentication/two-factor-authentication.component';
import { ExcelService } from './service/service/excel.service';
import { ServiceService } from './service/service/service.service';
@NgModule({
  declarations: [
    AppComponent,
    RemoveSpaces,
    routingComponents,
    LoginComponent,
    TwoFactorAuthenticationComponent,
    CardDistributors,
    BlockedCardsReportDistr,
    BlockedCardsReportTable,
    CardSummaryByMonthReportDist,
    CardSummaryByMonthReportTable,
    PhysicalCardReportDistr,
    PhysicalCardReportTable,
    TopUsageByCustomersDistComponent,
    ViewCustomerActivityReportComponent,
    CustomerActivityReportDistComponent,
    TopUsageMerchantSummaryDistComponent,
    TopUsageMerchantSummaryTableComponent,
    TopUsgaeMerchantTransactionsDetailComponent,
    CardMcHourlyReportTable,
    CardMcHourlyDistrReport,
    TopUsageByMerchantReportDist,
    CustomerTransactionDetails,
    SidebarComponent,
    Pos_Merchant,
    ViewCardDeatilComponent,
    CardPendingRequests,
    HeaderComponent,
    FooterComponent,
    DistributorListCardCustomersComponent,
    CustomersTableComponent,
    TopupWithdrawalsSummaryReportComponent,
    TopupWithdrawalSummaryTableReportComponent,
    DistCommissionTableReportNewComponent,
    DistCommissionReportComponent,
    ViewHtgTransectionInfo,
    DashboardComponent,
    HomeComponent,
    SysconfigComponent,
    MerchantDetailsComponent,
    WebpermissionComponent,
    CardTransDistributorListComponent,
    DistributorListPrintedCardComponent,
    getWaafiQrPaymentsReportComponent,
    GetSettlementDetailReportComponent,
    GetSettlementSummaryReportComponent,
    DeliveredCardsComponent,
    DistributorListDeliveredCardComponent,
    DetailedAuthorizationsReportComponent,
    DetailedTopupWithdrawalsReportComponent,
    UsageSummaryByMonthReportComponent,
    TopUsageByCustomersTableComponent,
    TopUsageByMerchantReportTableComponent,
    IssuingSummaryReportComponent,
    UsageSummaryReportComponent,
    PrintedCardsComponent,
    CardTransactionsComponent,
    ServicesconfigComponent,
    NotfoundComponent,
    RoutconfigComponent,
    ConfigurationComponent,
    BranchComponent,
    UserrolesComponent,
    DistributorListComponent,
    MerchantconfigComponent,
    MerchantgroupsComponent,
    AssignwebpermissionComponent,
    ViewCardDeatilsReportComponent,
    CreateDistibutorUserComponent,
    AdminComponent,
    DistributorComponent,
    MerchantComponent,
    CashierComponent,
    AssignUserRolesComponent,
    PaymentprocessorComponent,
    CreateComponent,
    UpdateComponent,
    CreateServiceComponent,
    UpdateServiceComponent,
    CreateroutComponent,
    UpdateroutComponent,
    SettingconfigComponent,
    ViewusersComponent,
    CreateusersComponent,
    CreatedistComponent,
    ViewdistComponent,
    ViewmerchantComponent,
    CreatemerchantComponent,
    TransactionHistoryComponent,
    TransactionTableComponent,
    MerchantTableComponent,
    TransactionViewComponent,
    TransactionInfoComponent,
    ViewTransactionInfoComponent,
    TransactionCancellationComponent,
    MerchantAccountsComponent,
    AccountsTableComponent,
    ListCashierInfoComponent,
    ViewInfoComponent,
    CreateInfoComponent,
    PaymentProcessorSystemComponent,
    ViewPaymentProcessorSystemComponent,
    CreatePaymentProcessorSystemComponent,
    BanksComponent,
    ViewBankComponent,
    CreateBankComponent,
    ViewProcessComponent,
    ProductListComponent,
    CreateProductComponent,
    GetInfoProductComponent,
    ServiceProductComponent,
    CreatesServiceComponent,
    ViewServiceComponent,
    ServiceRateComponent,
    CreateRateComponent,
    GetRateComponent,
    SettlementHistoryComponent,
    SettlementTableComponent,
    GetSettlementInfoComponent,
    ProcessSettlementInfoComponent,
    SettlementDirectComponent,
    GenerateSettlementComponent,
    SettlementRequestComponent,
    CreateSettlementRequestComponent,
    SettlementRequestListComponent,
    SettlementRequestTableComponent,
    GetSettlementRequestInfoComponent,
    SettlementPeriodicRequestComponent,
    SettlementDistributorCommissionComponent,
    SettlementCommissionTableComponent,
    ProcessCommissionComponent,
    MerchantSubscriptionComponent,
    GetSubscriptionReportComponent,
    MerchantActivityComponent,
    MerchantActivityTableComponent,
    ChargeBackReportsComponent,
    ChargeBackTableComponent,
    ChargeBackSummaryReportsComponent,
    ChargeBackSummaryTableComponent,
    DistributorCommissionReportsComponent,
    DistributorCommissionTableComponent,
    PaymentTranSummaryReportsComponent,
    PaymentTranSummaryTableComponent,
    GetInvoiceListComponent,
    GetInvoiceInfoComponent,
    ProcessInvoiceInfoComponent,
    ProcessInvoiceComponent,
    CreateInvoiceComponent,
    GetCreateInvoiceComponent,
    CashierTableComponent,
    CashierCreateInfoComponent,
    DistributorAccountsComponent,
    CreateDistributorAccountComponent,
    CreateMerchantAccountsComponent,
    SettlementAccountInfoComponent,
    MerchantAccountInfoComponent,
    ChargebacksComponent,
    GetChargebacksListComponent,
    GetChargebacksComponent,
    BillingMgComponent,
    CreateBillersComponent,
    MerchantBillersComponent,
    MerchantBillerTableComponent,
    ViewBillingInfoComponent,
    ListMerchantApprovalsComponent,
    TableMerchantApprovalsComponent,
    GlAccountBalanceReportsComponent,
    GlAccountSummaryReportsComponent,
    GlAccountSummaryTableComponent,
    MerchantActivitySummaryByMonthComponent,
    MerchantActivitySummaryByDayComponent,
    MerchantActivitySummaryByDayTableComponent,
    MerchantActivitySummaryByMonthTableComponent,
    MerchantDailySummaryByDistComponent,
    MerchantDailySummaryByDistTableComponent,
    MerchantMonthlySummaryByDistComponent,
    MerchantMonthlySummaryByDistTableComponent,
    PartnerComponent,
    ForbiddenComponent,
    ThirdPartyMerchantComponent,
    AdminViewerComponent,
    ResendCredentialsComponent,
    MerchantActivationComponent,
    MerchantActiveInfoComponent,
    CashierActivationComponent,
    CashierActiveTableComponent,
    EventLogsComponent,
    EventLogInfoComponent,
    MerchantUsageSummaryReportComponent,
    MerchantUsageSummaryTableReportComponent,
    CreateCashierMerchantApprovalComponent,
    MerchantPaymentProviderComponent,
    MerchantPaymentProviderTableComponent,
    AddPaymentMethodComponent,
    PrintPageComponent,
    GlAccountActivityReportComponent,
    GlAccountActivityTableReportComponent,
    ChangeUserPasswordComponent,
    GlAccountsSummaryByDayComponent,
    GlAccountsSummaryByDayTableComponent,
    UnAmbiguousTransactionsComponent,
    DistCommissionByMonthReportComponent,
    DistCommissionByMonthTableReportComponent,
    DistCommissionByYearReportComponent,
    DistCommissionByYearTableReportComponent,
    ResetMerchantPasswordComponent,
    ResetPasswordInfoComponent,
    DeviceTerminalsListComponent,
    PosTransactionComponent,
    PosTransactionTableInfoComponent,
    PosMerchantActivityReportComponent,
    PosMerchantActivityTableReportComponent,
    PosMerchantUsageSummaryReportComponent,
    PosMerchantUsageSummaryTableReportComponent,
    ResetAdminPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    QRCodeModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    ToastrModule.forRoot(
      {
        closeButton: true,
      }
    ),

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB53UQ1KWvjXmWDhqt-asZYiJYWauujL7s'
    })

  ],
  providers: [ServiceService, SpinnerService, DatePipe, ExcelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
