import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-admin-viewer',
  templateUrl: './admin-viewer.component.html',
  styleUrls: ['./admin-viewer.component.css'],
  animations: [
    fade
  ]
})
export class AdminViewerComponent implements OnInit {

  _USER_TYPE_ID_USER = 60;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_CASHIER = 40;
  _USER_TYPE_ID_PAYMENTPROCESSOR = 50;
  partnerListBody: boolean;
  distributorListBody: boolean;
  cashierListBody: boolean;
  merchantListBody: boolean;
  adminListBody: boolean;
  dataListByAdmin;
  dataListByDistributor;
  dataListByMerchant;
  dataListByCashier;
  dataListByBartner;
  sysForm: FormGroup;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  listBody: boolean = false;

  selected = pagination.selected;
  p: number = pagination.p;
  term;


  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.createAdminForm();
  }

  createForm() {
    this.sysForm = this.fb.group({
      paramId: [''],
      paramName: ['', Validators.required],
      paramCode: ['', Validators.required],
      paramValue: ['', Validators.required],
    });
  }

  createAdminForm() {
    this.serviceForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit(): void {
    this.getUserListsByAdmin();
  }

  getUserListsByAdmin() {
    let userTypeId = 0;
    let payload: any = {
      userTypeId: this._USER_TYPE_ID_USER
    };
    this.spinner.getSpinner();
    this.service.getUserList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataListByAdmin = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passRoleIdValue(user) {
    let userId = user.userId;
    this.router.navigate(['dashboard/assignUserRoles'], { queryParams: { userId } });
  }
  passUserInfo(user) {
    let userId = user.userId;
    this.router.navigate(['dashboard/view/userInfo'], { queryParams: { userId } });
  }

  passUserCreateInfo() {
    this.router.navigate(['dashboard/view/createUserInfo']);
  }
  enableOrDisableTwoFactorAuth(data) {
    let payload: any = {
      ptwo_factor_enabled: data.isTwoFactorEnabled === 1 ? 0 : 1,
      PAccessProfileId: data.accessProfileId,
    };

    this.spinner.getSpinner();
    this.service.enableOrDisableTwoFactorAuth({ payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        this.getUserListsByAdmin()
      } else {
        this.toastr.error(successResponse.records.message)
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
}
