<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Merchant Activity Summary By Month Report</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Activity</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <p style="color: green;">Merchant Id: <Span style="color: black;">{{merchantId}}</Span></p>
                        <p style="color: green;">Merchant Name: <Span style="color: black;">{{merchantName}}</Span></p>
                    </div>
                    <div class="col-md-2">
                        <p style=" text-align: end; color: green;">
                            Start Date: <Span style="color: black;">{{startDate}}</Span>
                        </p>
                        <p style=" text-align: end; color: green;">
                            End Date: <Span style="color: black;">{{endDate}}</Span>
                        </p>

                    </div>
                </div>
                <div class="table-responsive" id="section-one">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <!-- <th>Merchant Id</th>
                                <th>Merchant Name</th> -->
                                <th>TranTypeId</th>
                                <!-- <th>TranTypeName</th> -->
                                <th>Status</th>
                                <th>TranDate</th>
                                <th>Number Of Tran</th>
                                <th>Credit</th>
                                <th>Debit</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody
                            *ngFor="let x of listData | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                            <tr style="background: rgba(183, 253, 213, 0.445);">
                                <th style="padding: 10px;">{{x.tranTypeName}}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let p of x.records">
                                <!-- <td>{{ x.merchantId}}</td>
                                <td>{{ x.merchantName}}</td> -->
                                <td>{{ p.tranTypeId}}</td>
                                <!-- <td>{{ p.tranTypeName}}</td> -->
                                <td>{{ p.statusid}}</td>
                                <td>{{ p.tranDate | date:'dd-MMM-yy - HH:mm:ss'}}</td>
                                <td>{{ p.ntrans | number:'1.2-2'}}</td>
                                <td>{{ p.credit |number:'1.2-2'}}</td>
                                <td>{{ p.debit |number:'1.2-2'}}</td>
                                <td>{{ p.balance |number:'1.2-2'}}</td>

                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>