import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
// import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-resend-credentials',
  templateUrl: './resend-credentials.component.html',
  styleUrls: ['./resend-credentials.component.css'],
  animations: [
    fade
  ]
})
export class ResendCredentialsComponent implements OnInit {

  _USER_TYPE_ID_CASHIER = 40;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  merchantUid;
  merchantName;
  cashierId;
  queryCashierId;
  event;
  data;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    // private spinner: NgxSpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.queryCashierId = params['cashierId'];
        this.cashierId = this.queryCashierId;
        this.getCashierInfo(this.cashierId);
      } else {
        _location.back();
      }
    });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      userTitle: ['', Validators.required],
    });
  }


  backToCashier() {
    this._location.back();
  }


  ngOnInit(): void {
    this.event = 'Edit'
    this.serviceForm.disable()
  }

  onButtonClick(event){
    if(event == 'Edit'){
      this.event = 'Disabled'
      this.serviceForm.enable()
    }else{
      this.event = 'Edit'
      this.serviceForm.disable()
    }
  }

  getCashierInfo(cashierId) {
    let payload: any = {
      cashierId: cashierId

    };
    this.spinner.getSpinner();
    this.service.getCashierInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        // this.merchantUid = data.merchantUid;
        // this.merchantName = data.firstName + " " + data.middleName + " " + data.lastName;
        this.serviceForm = this.fb.group({
          email: [data.email, [Validators.required, Validators.email]],
          userTitle: [data.userTitle, Validators.required],
        });
        this.data = data
        // console.log("kkkk", data)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  OnCreateCashierUser() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
  }

  OnResetMerchantCredentials() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }

    let payload:any = {
      userId: this.data.userId,
      merchantUid: this.data.merchantUid,
      accessKey: this.data.accessKey,
      email: this.data.email,
      userTitle:  this.data.userTitle,
      cashierTypeId: this.data.cashierTypeId
    }

    console.log("payload", payload)

    this.service.reSendMerchantCredentials({payload: payload}).subscribe((res: any) => {
      this.toastr.success(`You have send at ${res.records.email}`, "Seccessfully")
      setTimeout(() => {
        this._location.back();
      },1000)
      console.log(res);
    })
  }

}
