import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';




@Component({
  selector: 'app-settlement-periodic-request',
  templateUrl: './settlement-periodic-request.component.html',
  styleUrls: ['./settlement-periodic-request.component.css'],
  animations: [
    fade
  ]
})
export class SettlementPeriodicRequestComponent implements OnInit {
  submitted = false;
  getForm: FormGroup;
  dataList;
  optionMenu;
  selected;
  isSelected: boolean;
  isSelected1: boolean;
  distributorId;
  merchantId;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();

  }

  getFrom() {
    this.getForm = this.fb.group({
      description: ['', Validators.required],
      closingDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  passClosingDate(){
    this.submitted = true;
    if(this.getForm.invalid) return;

    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      description: this.getForm.value.description,
      doneByUserId: currentUser.userId,
      closingDate: this.getForm.value.closingDate
    };
    this.spinner.getSpinner();
    this.service.processingPeriodicSettlementRequest(payload).subscribe(successResponse => {
        var responseObj = JSON.stringify(successResponse);
        var data = JSON.parse(responseObj);
        // console.log("Data=> ", data.requestId);
        if(data.requestId){
          this.spinner.hideSpinner();
          this.toastr.success(`You have rejected the settlement request id ${data.requestId}`, 'Seccessfully!');
        }else{
          this.spinner.hideSpinner();
          this.toastr.warning("this request something went wrong", data.error);
        }
        // console.log("uuuu", data)
      },(failureResponse: any) => {
        if(failureResponse.status === 401){
          window.location.href = 'login';
        }else{
          // this.failureMessage = true;
          this.spinner.hideSpinner();
          console.log("onFailure:" + JSON.stringify(failureResponse));
        }
    });
  }

}
