import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { environment } from 'src/assets/config/environment';
import { ExpressRequestService } from '../../service/express-request/express-request.service';
import { ServiceService } from '../../service/service/service.service';
import { SessionStorageService } from '../../service/session-storage/session-storage.service';
@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.css']
})
export class TwoFactorAuthenticationComponent implements OnInit {

  serviceForm: FormGroup;
  // loginId: string = "";
  // password: string = "";
  adminUserTypeId = "10";
  resultMessage: string;
  IsSignIt: boolean;
  appName;
  submitted = false;
  message;
  tab = environment.tab;
  pass;
  loginId
  isLoading;
  spinnerSize = 24;
  constructor(
    private service: ServiceService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _location: Location,
    private spinner: SpinnerService,
    private sessionStorage: SessionStorageService,
    private expressRequestService: ExpressRequestService) {
    this.route.queryParams.subscribe(params => {
      this.isLoading = false;
      let twofactorsessionData = this.sessionStorage.twofactorSessionData();
      let decryptedParams: any = this.expressRequestService.decryptData(twofactorsessionData);
      if (decryptedParams != undefined) {
        this.message = decryptedParams.message;
        this.loginId = decryptedParams.loginId;
        this.pass = decryptedParams.pass;
        this.createForm(this.loginId, this.pass);
      } else {
        _location.back();
      }
      // this.toastr.success(this.message);

    });
  }

  ngOnInit() {
  }

  createForm(loginId, pass) {
    this.serviceForm = this.fb.group({
      loginId: [loginId, Validators.required],
      password: [pass, Validators.required],
      isSignIn: [''],
      otpCode: ['', Validators.required],
    });
  }

  twoFactorAuthentication() {
    this.resultMessage = "";

    this.submitted = true;
    if (this.serviceForm.invalid) {
      return;
    }

    let payload: any = {
      otpCode: this.serviceForm.value.otpCode,
      loginId: this.loginId,
      pass: this.pass,
    };
    this.isLoading = true;
    this.service.verifyTwoFactorAuthentication(payload).subscribe(successResponse => {
      console.log(successResponse);
      if (successResponse.success) {
        var responseObj = JSON.stringify(successResponse);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        // this.toastr.success(successResponse.records.message);
        this.loginAgain()
      } else {
        console.log(successResponse.records);
        this.toastr.warning(successResponse.records.message);
        this.isLoading = false;
      }

    }, (failureResponse: any) => {
      // if (failureResponse.status === 401) {
      //   window.location.href = 'login';
      // } else {
      // this.failureMessage = true;
      this.isLoading = false;
      console.log("onFailure:" + JSON.stringify(failureResponse.error.records.message));
      this.toastr.warning(failureResponse.error.records.message);
      this.spinner.hideSpinner();
      // }
    });
  }

  loginAgain() {
    this.resultMessage = "";
    if (this.serviceForm.value.isSignIn) {
      let isSignIn: any = {
        loginId: this.serviceForm.value.loginId,
        password: this.serviceForm.value.password,
        isSignIn: ''
      }
      let isStore: any = this.expressRequestService.encryptData(isSignIn);
      this.sessionStorage.setIsSignInData(isStore)
    } else {
      let isSignIn: any = {
        loginId: this.serviceForm.value.loginId,
        password: this.serviceForm.value.password,
        isSignIn: ''
      }
      let isStore: any = this.expressRequestService.encryptData(isSignIn);
      this.sessionStorage.setIsSignInData(isStore)
    }

    this.submitted = true;
    if (this.serviceForm.invalid) {
      return;
    }

    let payload: any = {
      // loginId: this.serviceForm.value.loginId,
      // password: this.serviceForm.value.password,
      loginId: this.serviceForm.value.loginId,
      password: this.serviceForm.value.password,
      istwoFactorNeeded: false,
    };
    // console.log("Payload ", payload);
    this.isLoading = true;
    this.service.login(payload).subscribe(res => {
      let record = res.records;
      if (res.success) {
        this.sessionStorage.twofactorSessionDataRemove()
        if (record.userTypeId === 10) {
          this.sessionStorage.setSessionData(record);
          this.sessionStorage.setToken(res.token);
          this.router.navigate(['dashboard/home']).then(() => {
            window.location.reload();
          });
          this.sessionStorage.setPermissionData(JSON.stringify(this.expressRequestService.encryptData([record.roleName])))
          this.toastr.success(res.message, 'Seccessfully');
          this.isLoading = false;
        } else if (record.userTypeId == 60) {
          this.sessionStorage.setSessionData(record);
          this.sessionStorage.setToken(res.token);
          this.isLoading = false;
          this.router.navigate(['dashboard/home']).then(() => {
            window.location.reload();
          });
          this.sessionStorage.setPermissionData(JSON.stringify(this.expressRequestService.encryptData([record.roleName])))
          this.toastr.success(res.message, 'Seccessfully');

        } else {
          this.isLoading = false;
          this.toastr.warning("This user is`t admin", 'Warning')
        }
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.toastr.warning(res.records.message);
        // this.toastr.warning("Login id or password are Incorrect!");

      }
    })
  }

}
