import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-distributor',
  templateUrl: './distributor.component.html',
  styleUrls: ['./distributor.component.css'],
  animations: [
    fade
  ]
})
export class DistributorComponent implements OnInit {

  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  submitted = false;
  modal;
  listBody: boolean = false;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private spinner: SpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.updateForm();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  updateForm() {
    this.updatesForm = this.fb.group({
      distributorId: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getDistributorUserLists();
  }

  getDistributorUserLists() {
    let userTypeId = 0;
    let payload: any = {
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getUserList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  viewDistributorUserInfo(x) {
    this.updatesForm = this.fb.group({
      // userId: [x.userId],
      distributorId: [x.referenceId, Validators.required],
      firstName: [x.firstName, Validators.required],
      middleName: [x.middleName, Validators.required],
      lastName: [x.lastName, Validators.required],
      email: [x.email, [Validators.required, Validators.email]],
      telephone: [x.telephone, Validators.required],
      address: [x.address, Validators.required],
      userTitle: [x.userTitle, Validators.required],
      address2: [x.address2, Validators.required],
      website: [x.website, Validators.required],
      city: [x.city, Validators.required],
      country: [x.country, Validators.required]
    });
  }

  OnCreateDistributorUser() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = this._USER_TYPE_ID_DISTRIBUTOR;
    let payload: any = {
      createdByUserId: currentUser.userId,
      firstName: this.serviceForm.value.firstName,
      middleName: this.serviceForm.value.middleName,
      lastName: this.serviceForm.value.lastName,
      telephone: this.serviceForm.value.telephone,
      address: this.serviceForm.value.address,
      email: this.serviceForm.value.email,
      address2: this.serviceForm.value.address2,
      city: this.serviceForm.value.city,
      country: this.serviceForm.value.country,
      website: this.serviceForm.value.website,
      userTitle: this.serviceForm.value.userTitle,
      loginId: this.serviceForm.value.loginId,
      loginPwd: this.serviceForm.value.password,
      userTypeId: userTypeId
    };
    this.spinner.getSpinner();
    this.service.createDistributorUser(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if (successResponse.id) {
        $('#createModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success("Seccessfully: You have created Id", successResponse.id)
        this.getDistributorUserLists()
      } else {
        this.toastr.error("Something went wrong")
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  OnUpdateDistributorUser() {
    this.submitted = true;

    if (this.updatesForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      distributorId: this.updatesForm.value.distributorId,
      firstName: this.updatesForm.value.firstName,
      middleName: this.updatesForm.value.middleName,
      lastName: this.updatesForm.value.lastName,
      telephone: this.updatesForm.value.telephone,
      address: this.updatesForm.value.address,
      email: this.updatesForm.value.email,
      address2: this.updatesForm.value.address2,
      city: this.updatesForm.value.city,
      country: this.updatesForm.value.country,
      website: this.updatesForm.value.website,
      userTitle: this.updatesForm.value.userTitle,
    };
    this.spinner.getSpinner();
    this.service.updateDistributorUser(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if (successResponse.status == 'SUCCESS') {
        $('#createModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success("Seccessfully: You have updated")
        this.serviceForm.reset(this.serviceForm.value);
        this.getDistributorUserLists();
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  passCreateView() {
    this.router.navigate(["dashboard/view/createDistributorInfo"])
  }
  passCreateDistrUser() {
    this.router.navigate(["dashboard/createDistributorUser"])
  }
  passDistriputorListView(x) {
    let distributorId = x.referenceId;
    this.router.navigate(["dashboard/view/getDistributorAccountList"], { queryParams: { distributorId } })
  }
  passUpdateView(x) {
    let distributorId = x.referenceId;
    this.router.navigate(["dashboard/view/updateDistributorInfo"], { queryParams: { distributorId } })
  }

  enableOrDisableTwoFactorAuth(data) {
    let payload: any = {
      ptwo_factor_enabled: data.isTwoFactorEnabled === 1 ? 0 : 1,
      PAccessProfileId: data.accessProfileId,
    };

    this.spinner.getSpinner();
    this.service.enableOrDisableTwoFactorAuth({ payload }).subscribe(successResponse => {
      if (successResponse.success) {
        data.isTwoFactorEnabled === 1 ? 0 : 1
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        this.getDistributorUserLists()
      } else {
        this.toastr.error(successResponse.records.message)
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
}
