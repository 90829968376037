<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-8">
                <h4 class="card-title">Distriputors</h4>
              </div>
              <div class="col-md-2">
                <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="passCreateView()"
                >
                  Add Distributor
                </button>
              </div>
              <div class="col-md-2">
                <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="passCreateDistrUser()"
                >
                  Add Distributor User
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-3">
            <input
              type="text"
              class="form-control-sm"
              [(ngModel)]="term"
              placeholder="Search here..."
              style="float: left"
            />
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>
        <div class="table-responsive">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Distributor Id</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Contact Number</th>
                <th>Address</th>
                <th>User Title</th>
                <th>Profile Status</th>
                <th>isTwoFactorEnabled</th>
                <th>Dist: Acc</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let x of dataList
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.referenceId }}</td>
                <td>
                  {{ x.firstName + " " + x.middleName + " " + x.lastName }}
                </td>
                <td>{{ x.email }}</td>
                <td>{{ x.telephone }}</td>
                <td>{{ x.address }}</td>
                <td>{{ x.userTitle }}</td>
                <td>{{ x.profileStatusDesc }}</td>
                <td (click)="enableOrDisableTwoFactorAuth(x)">
                  <div class="custom-control custom-switch">
                    <input
                      [(ngModel)]="x.isTwoFactorEnabled"
                      type="checkbox"
                      class="custom-control-input"
                      name="switch"
                      [checked]="x.isTwoFactorEnabled == 1"
                    />
                    <label class="custom-control-label"> </label>
                  </div>
                </td>

                <td (click)="passDistriputorListView(x)">
                  <button type="button" class="btn btn-outline-primary btn-sm">
                    view
                  </button>
                </td>
                <td (click)="passUpdateView(x)">
                  <button type="button" class="btn btn-outline-primary btn-sm">
                    view
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
