import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css'],
  animations: [
    fade
  ]
})
export class TransactionHistoryComponent implements OnInit {

  getForm: FormGroup;
  getSubmitted = false;
  selectData;
  tranStatusId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.fnGetStatusList();
  }

  getFrom() {
    this.getForm = this.fb.group({
      merchantId: ['', Validators.required],
      selectedData: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  fnGetStatusList(sourceId?) {
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getTranStatusList({ payload: payload }).subscribe(successResponse => {
      let responseObj = JSON.stringify(successResponse.records);
      // let data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      var records = JSON.parse(JSON.parse(responseObj));
      // console.log("data :=> ", records);

      var newrecord: any = {};
      newrecord.tranStatusId = "0";
      newrecord.tranStatusDesc = "All";
      records.push(newrecord);
      this.selectData = records;


    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };

  passTransaction() {
    this.getSubmitted = true;
    if (this.getForm.invalid) {
      return;
    }
    let tranStatusId = this.getForm.value.selectedData;
    let merchantId = this.getForm.value.merchantId;
    this.router.navigate(["dashboard/view/transactionHistory"], { queryParams: { merchantId, tranStatusId } })
  }

}
