<div @fade class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row" *ngIf="showDistributor">
          <div class="col-md-10">
            <h4 class="card-title">POS Transactions</h4>
          </div>

          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <h4 class="card-title" *ngIf="showDistributor == false">
          POS Transactions
        </h4>
        <p class="card-description" *ngIf="showDistributor == false">
          Select Distributor
        </p>
        <form
          *ngIf="showDistributor == false"
          class="forms-sample"
          [formGroup]="getDistrForm"
          novalidate
          (ngSubmit)="handleSubmitDistr()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <select
                  class="form-control-sm"
                  name="selectData"
                  formControlName="selectData"
                  [ngClass]="{
                    'is-invalid':
                      getSubmitted && getDistrForm.controls.selectData.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distributorList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="
                    getSubmitted && getDistrForm.controls.selectData.errors
                  "
                  class="text-danger"
                >
                  <div
                    *ngIf="getDistrForm.controls.selectData.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>

        <div class="row" style="margin-bottom: 0px" *ngIf="showDistributor">
          <div class="col-md-3">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-8">
            <form
              class="forms-sample"
              [formGroup]="getForm"
              novalidate
              (ngSubmit)="handleSubmit()"
            >
              <div class="row">
                <div class="col-md">
                  <div class="form-group">
                    <label for="cashierId">Partner UID</label>
                    <input
                      formControlName="cashierId"
                      type="text"
                      class="form-control-sm"
                      placeholder="Partner UID"
                      style="float: left"
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="startDate">Start Date</label>
                    <input
                      type="date"
                      class="form-control-sm"
                      formControlName="startDate"
                      placeholder="Start Date"
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="endDate">End Date</label>
                    <input
                      type="date"
                      class="form-control-sm form-control-sm"
                      formControlName="endDate"
                      placeholder="End Date"
                    />
                  </div>
                </div>

                <div class="col-md">
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style="margin-top: 30px"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-warning text-white"
                      style="margin-top: 30px"
                      (click)="clearForm()"
                    >
                      Clear
                    </button>
                  </div>
                </div>

                <!-- <div class="col-md">
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-primary"
                      style="margin-top: 30px"
                    >
                      Clear
                    </button>
                  </div>
                </div> -->
              </div>
            </form>
          </div>
        </div>

        <div class="table-responsive" *ngIf="showDistributor">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>PaymentId</th>
                <th>Cashier Name</th>
                <th>Partner UID</th>
                <th>Amount</th>
                <th>Card</th>
                <th>Date</th>
                <th>Trace Number</th>
                <th>Charge Amount</th>
                <th>RRN</th>
                <th>Approval Number</th>
                <th>Settlement</th>

                <th>State</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let x of dataList
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <!-- <td class="py-1">{{ x.sn }}</td> -->
                <td>{{ x.paymentId }}</td>
                <td>{{ x.cashierName }}</td>
                <td>{{ x.cashierIdentifier }}</td>
                <td>{{ x.transactionAmount }}</td>
                <td>{{ x.accountNumber }}</td>
                <td>{{ x.transactionDate | date : "dd-MMM-yy - HH:mm:ss" }}</td>
                <td>{{ x.traceNumber }}</td>
                <td>{{ x.transactionFeeAmount }}</td>
                <td>{{ x.retrievalRefNumber }}</td>
                <td>{{ x.approvalCode }}</td>
                <td>{{ x.settlementId }}</td>
                <td class="font-weight-medium">
                  <div class="{{ x.status }}">{{ x.resState }}</div>
                </td>
                <td>
                  <button
                    (click)="getSettlementById(x)"
                    type="button"
                    class="btn btn-outline-primary btn-sm ml-2"
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- model -->
<div
  class="modal fade bd-example-modal-md"
  id="settlementModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">settlement</h4>

              <div class="row" style="margin-bottom: 0px">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="startDate" className="text-white">Search</label>
                    <input
                      type="text"
                      class="form-control form-control-sm mb-1"
                      [(ngModel)]="term"
                      aria-hidden="true"
                      placeholder="Search here..."
                      style="float: left"
                    />
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table
                  class="table-striped display expandable-table"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>SettlementId</th>
                      <th>Time</th>
                      <th>Amount</th>
                      <th>Processor Id</th>
                      <th>MerchantId</th>
                      <th>Description</th>
                      <th>IssuerErrorDesc</th>
                      <th>Status</th>
                      <th *ngIf="isAllowedRetrySettlement == 'true'">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let x of settlementList
                          | filter : term
                          | paginate
                            : { itemsPerPage: selected, currentPage: p }
                      "
                    >
                      <td>{{ x.settlementId }}</td>
                      <td>
                        {{ x.settlementTime | date : "dd-MMM-yy - HH:mm:ss" }}
                      </td>
                      <td>{{ x.currencySymbol }}{{ x.amount }}</td>
                      <td>{{ x.issuerSettlementId }}</td>
                      <td>{{ x.merchantId }}</td>
                      <td>{{ x.description }}</td>
                      <td>{{ x.issuerErrorDescription }}</td>
                      <td>{{ x.settlementStatus }}</td>
                      <td *ngIf="isAllowedRetrySettlement == 'true'">
                        <button
                          *ngIf="x.settlementStatus != 'Completed'"
                          (click)="retrySettlement(x)"
                          type="button"
                          class="btn btn-outline-primary btn-sm ml-2"
                        >
                          Retry
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style="width: 100%; margin-top: 1%">
                  <h4 class="card-title" style="float: left">
                    <select class="form-control-sm" [(ngModel)]="selected">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </h4>
                  <pagination-controls
                    style="float: right"
                    (pageChange)="p = $event"
                  ></pagination-controls>
                </div>
                <div *ngIf="!listBody" class="container">
                  <div style="text-align: center; padding: 24px">
                    {{ message }}
                  </div>
                </div>
              </div>
              <button class="btn btn-danger" data-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
