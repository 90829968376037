import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-get-create-invoice',
  templateUrl: './get-create-invoice.component.html',
  styleUrls: ['./get-create-invoice.component.css'],
  animations: [
    fade
  ]
})
export class GetCreateInvoiceComponent implements OnInit {

  serviceForm: FormGroup;
  sub: Subscription;
  queryinvoiceNo: number;
  invoiceNo: any;
  submitted = false;
  statusDesc;
  accountId;
  balance;
  settlementAmount;
  settlementAccount;
  settlementModeDesc;
  settlementCycleId;
  bankName;
  currency;
  data;
  invoiceIds;
  paymentTo;
  dueDate;
  paymentTerms;
  description;
  invoiceAmount;
  createdDate;
  bankDetail;
  companyList;

  queryAccountId;
  userId;
  queryUserId;
  startDate;
  queryStartDate;
  endDate;
  queryEndDate;
  queryStatusDesc;
  queryDoneByUserId;
  doneByUserId;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.accountId = params.accountId;
        this.userId = params.userId;
        this.startDate = params.startDate;
        this.endDate = params.endDate;
        this.statusDesc = params.statusDesc;
        this.doneByUserId = params.doneByUserId;
        this.getInvoiceInfo(this.accountId, this.statusDesc);
      } else {
        _location.back();
      }

    });

    this.service.bankDetails().subscribe((res: any) => {
      this.bankDetail = res.bankInfo;
      console.log('responces => ', res.bankInfo);
    });
    this.service.dataLists().subscribe((res: any) => {
      this.companyList = res;
      console.log('responceslist => ', res);
    });

  }



  createForm() {
    this.serviceForm = this.fb.group({
      accountId: ['', Validators.required],
      statusDesc: ['', Validators.required],
      settledBank: ['', Validators.required],
      settledTerms: ['', Validators.required],
      settlementAmount: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  getInvoiceInfo(accountId, statusDesc) {
    this.serviceForm = this.fb.group({
      accountId: [accountId, Validators.required],
      statusDesc: [statusDesc, Validators.required],
      settledBank: ['', Validators.required],
      settledTerms: ['', Validators.required],
      settlementAmount: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  backtowebrole() {
    this._location.back();
  }


  OnProcessInvoice() {
    this.submitted = true;
    if (this.serviceForm.invalid) return;

    let payload: any = {
      doneByUserId: this.doneByUserId,
      userId: this.userId,
      accountId: this.accountId,
      amount: this.serviceForm.value.settlementAmount,
      description: this.serviceForm.value.description,
      paymentTerms: this.serviceForm.value.settledTerms,
      paymentTo: this.serviceForm.value.settledBank,
      startDate: this.startDate,
      endDate: this.endDate,
      isCredit: "0"
    };

    console.log("PayMent=====>", payload);
    this.spinner.getSpinner();
    this.service.createSettlementInvoice(payload).subscribe(successResponse => {
      console.log("successResponse", successResponse)
      if (successResponse.invoiceNo) {
        this.spinner.hideSpinner();
        this.toastr.success(`You have created invoiceNo ${successResponse.invoiceNo}`, 'Seccessfully')
        let invoiceNo = successResponse.invoiceNo;
        this.router.navigate(["dashboard/view/getInvoicelist"], { queryParams: { invoiceNo } })
        // console.log("Data =>", successResponse);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(failureResponse.statusText, 'Error')
        // console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  ngOnInit(): void {
  }

}
