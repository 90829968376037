<div @fade class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Cashiers Management</h4>
                <p class="card-description">
                    Cashiers
                </p>
                <form class="forms-sample" [formGroup]="serviceForm" novalidate (ngSubmit)="passMerchantCashiersView()">

                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <!-- <label for="merchantId">Merchant Id</label> -->
                                <input type="text" class="form-control-sm" formControlName="merchantId"
                                    placeholder="Merchant Id"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantId.errors }">
                                <div *ngIf="submitted && serviceForm.controls.merchantId.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.merchantId.errors.required">Merchant Id is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- 
<div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-10">
                            <h4 class="card-title">Cashier</h4>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="merchantId">Merchant UID</label>
                                        <input type="text" class="form-control" formControlName="merchantUid"
                                            placeholder="Merchant Id" readonly
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantUid.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.merchantUid.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.merchantUid.errors.required">Merchant UID is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="merchantId">Merchant Name</label>
                                        <input type="text" class="form-control" formControlName="merchantName"
                                            placeholder="Merchant Id" readonly
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantName.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.merchantName.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.merchantName.errors.required">Merchant Name is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <h4 class="card-title" style="float: right;">
                                <button type="submit" class="btn btn-primary" data-toggle="modal"
                                    data-target="#createModel">Add New Cashier</button>
                            </h4>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table-striped display expandable-table" style="width:100%">
                            <thead>
                                <tr>
                                    <th>Cashier Id</th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Contact Number</th>
                                    <th>Address</th>
                                    <th>User Title</th>
                                    <th>Profile Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of dataList">
                                    <td class="py-1">{{x.referenceId}}</td>
                                    <td>{{ x.firstName + " " + x.middleName + " " + x.lastName}}</td>
                                    <td>{{ x.email}}</td>
                                    <td>{{ x.telephone}}</td>
                                    <td>{{ x.address}}</td>
                                    <td>{{ x.userTitle}}</td>
                                    <td>{{ x.profileStatusDesc}}</td>
                                    <td (click)="viewDistributorUserInfo(x)">
                                        <a data-toggle="modal" data-target="#viewModel" style="color: #009e44; cursor: pointer;">
                                            <i class="material-icons">&#xE417;</i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
