import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common';
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-viewusers',
  templateUrl: './viewusers.component.html',
  styleUrls: ['./viewusers.component.css'],
  animations: [
    fade
  ]
})
export class ViewusersComponent implements OnInit {

  serviceForm: FormGroup;
  queryUserId: number;
  userId: any;
  dataRecords: any;
  sub: Subscription;
  submitted: boolean = false;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _location: Location,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryUserId = +params['userId'];
        if (Number.isNaN(this.queryUserId)) {
          this.userId = "";
        } else {
          this.userId = this.queryUserId;
          this.getUserInfo(this.userId);
        }
      });
  }


  createForm() {
    this.serviceForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }


  ngOnInit(): void {
  }

  backToService(){
    this._location.back();
  }

  getUserInfo(userId){
    let payload: any = {
      userId: userId
    };
    this.spinner.getSpinner();
    this.service.getUserInfo({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.serviceForm = this.fb.group({
          // userId: [x.userId],
          firstName: [data.firstName, Validators.required],
          middleName: [data.middleName, Validators.required],
          lastName: [data.lastName, Validators.required],
          email: [data.email, [Validators.required, Validators.email]],
          telephone: [data.telephone, Validators.required],
          address: [data.address, Validators.required],
          userTitle: [data.userTitle, Validators.required],
          address2: [data.address2, Validators.required],
          website: [data.website, Validators.required],
          city: [data.city, Validators.required],
          country: [data.country, Validators.required]
        });
        // console.log("kkkk", data)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  OnUpdateUser(){

  }

}
