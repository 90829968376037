<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <!-- <h4 class="card-title">{{ Title }}</h4> -->
          </div>

          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <div id="section-one">
          <h4 class="text-center">Physical Card Report</h4>

          <p class="text-center">
            {{ startDate | date : "yyyy-MM-dd" }} -
            {{ endDate | date : "yyyy-MM-dd" }}
          </p>
          <p class="text-center">Status: {{ pState }}</p>
        </div>

        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-3">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>

          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                (click)="print()"
                type="button"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="fnPDF()"
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="downloadCSV()"
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>

        <div class="table-responsive" id="section-two">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Card Id</th>
                <th>Mobile Number</th>
                <th>CardHolder Name</th>
                <th>Masked PAN</th>
                <th>Tracking Number</th>

                <th>Requested Date</th>
                <th>Physical Card Status</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.cardId }}</td>

                <td>{{ x.mobileNumber }}</td>
                <td>{{ x.cardholderName }}</td>
                <td>{{ x.cardNumber }}</td>
                <td>{{ x.trackingNumber }}</td>
                <td>{{ x.requestedDate | date : "dd-MMM-yy - HH:mm:ss" }}</td>
                <td>{{ x.status }}</td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%" class="d-print-none">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container d-print-none">
            <div style="text-align: center; padding: 24px">{{ message }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-md"
  id="cardEventsModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Card Events</h4>
              <p class="">
                CardId:<strong>{{ selectedCard }} </strong>
              </p>
              <div class="row" style="margin-bottom: 0px">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="startDate" className="text-white">Search</label>
                    <input
                      type="text"
                      class="form-control form-control-sm mb-1"
                      [(ngModel)]="term"
                      aria-hidden="true"
                      placeholder="Search here..."
                      style="float: left"
                    />
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table
                  class="table-striped display expandable-table"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Event Name</th>
                      <th>Previos Value</th>

                      <th>New Value</th>
                      <th>Description</th>
                      <th>Event Source</th>
                      <th>User</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let x of CardEventLogs
                          | filter : term
                          | paginate
                            : { itemsPerPage: selected, currentPage: p }
                      "
                    >
                      <td>{{ x.eventName }}</td>
                      <td>{{ x.prevValue }}</td>
                      <!-- <td>{{ x.tokenUniqueRef }}</td> -->
                      <td>{{ x.newValue }}</td>

                      <td>{{ x.description }}</td>
                      <td>{{ x.eventSource }}</td>
                      <td>{{ x.userTitle }}</td>
                      <td>
                        {{ x.createdAt | date : "dd-MMM-yy - HH:mm:ss" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style="width: 100%; margin-top: 1%">
                  <h4 class="card-title" style="float: left">
                    <select class="form-control-sm" [(ngModel)]="selected">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </h4>
                  <pagination-controls
                    style="float: right"
                    (pageChange)="p = $event"
                  ></pagination-controls>
                </div>
                <div *ngIf="!listBody" class="container">
                  <div style="text-align: center; padding: 24px">
                    {{ message }}
                  </div>
                </div>
              </div>
              <button class="btn btn-danger" data-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
