<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ merchantTitle }}</h4>
          </div>
          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-9">
            <form
              class="forms-sample"
              [formGroup]="getForm"
              novalidate
              (ngSubmit)="handleSubmit()"
            >
              <div class="row">
                <div class="col-md">
                  <div class="form-group">
                    <label for="cashierId">Mobile Number</label>
                    <input
                      formControlName="mobileNumber"
                      type="text"
                      maxlength="13"
                      minlength="0"
                      class="form-control-sm form-control-sm-sm"
                      placeholder="Mobile Number"
                      style="float: left"
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="pLastFourDigit">Card Last 4 Digit</label>
                    <input
                      formControlName="pLastFourDigit"
                      type="text"
                      maxlength="4"
                      minlength="4"
                      class="form-control-sm form-control-sm-sm"
                      placeholder="Last 4 digits of the card"
                      style="float: left"
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="trackingNumber">Tracking Number</label>
                    <input
                      formControlName="trackingNumber"
                      type="text"
                      class="form-control-sm"
                      [(ngModel)]="trackingNumber"
                      placeholder="Tracking Number "
                    />
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style="margin-top: 30px"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-warning text-white"
                      style="margin-top: 30px"
                      (click)="clearForm()"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Created Date</th>
                <th>View Deatils</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                style="white-space: nowrap"
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.customerId }}</td>
                <td>
                  {{ x.fullName }}
                </td>
                <td>{{ x.mobileNumber }}</td>
                <td>{{ x.email }}</td>
                <td class="py-1">
                  {{ x.createdAt | date : "dd-MMM-yy" }}
                </td>
                <td>
                  <button
                    (click)="ViewCustomerCards(x)"
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                  >
                    Deatils
                  </button>
                  <button
                    (click)="selectedCustomer(x)"
                    type="button"
                    data-toggle="modal"
                    data-target="#updateCustomerModel"
                    class="btn btn-outline-primary btn-sm ml-2"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade bd-example-modal-md"
  id="updateCustomerModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Edit Customer Name</h4>
              <form
                class="forms-sample"
                [formGroup]="updateForm"
                (ngSubmit)="handleEditCustomer()"
              >
                <!-- <div class="card-subtitle">Access Profile</div> -->
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>

                <div class="form-group">
                  <label for="name">First Name</label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    id="firstName"
                    placeholder="First Name"
                    formControlName="firstName"
                    pattern="^[a-zA-Z0-9 ]*$"
                    minlength="3"
                    maxlength="12"
                    [ngClass]="{
                      'is-invalid':
                        submitted && updateForm.controls.firstName.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && updateForm.controls.firstName.errors"
                    class="text-danger"
                  >
                    <div *ngIf="updateForm.controls.firstName.errors.required">
                      First Name is required
                    </div>

                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.firstName.errors?.minlength"
                    >
                      First Name must be at least 3 characters long.
                    </div>
                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.firstName.errors?.maxlength"
                    >
                      First Name cannot be more than 20 characters long.
                    </div>
                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.firstName.errors?.pattern"
                    >
                      First Name can only contain alphanumeric characters and no
                      spaces.
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="name">Middle Name</label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    id="middleName"
                    placeholder="Middle Name"
                    formControlName="middleName"
                    pattern="^[a-zA-Z0-9 ]*$"
                    minlength="3"
                    maxlength="12"
                    [ngClass]="{
                      'is-invalid':
                        submitted && updateForm.controls.middleName.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && updateForm.controls.middleName.errors"
                    class="text-danger"
                  >
                    <div *ngIf="updateForm.controls.middleName.errors.required">
                      Middle Name is required
                    </div>

                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.middleName.errors?.minlength"
                    >
                      Middle Name must be at least 3 characters long.
                    </div>
                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.middleName.errors?.maxlength"
                    >
                      Middle Name cannot be more than 20 characters long.
                    </div>
                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.middleName.errors?.pattern"
                    >
                      Middle Name can only contain alphanumeric characters and
                      no spaces.
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="name">Last Name</label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    id="lastName"
                    placeholder="Last Name"
                    formControlName="lastName"
                    pattern="^[a-zA-Z0-9 ]*$"
                    minlength="3"
                    maxlength="12"
                    [ngClass]="{
                      'is-invalid':
                        submitted && updateForm.controls.lastName.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && updateForm.controls.lastName.errors"
                    class="text-danger"
                  >
                    <div *ngIf="updateForm.controls.lastName.errors.required">
                      Last Name is required
                    </div>

                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.lastName.errors?.minlength"
                    >
                      Last Name must be at least 3 characters long.
                    </div>
                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.lastName.errors?.maxlength"
                    >
                      Last Name cannot be more than 20 characters long.
                    </div>
                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.lastName.errors?.pattern"
                    >
                      Last Name can only contain alphanumeric characters and no
                      spaces.
                    </div>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
