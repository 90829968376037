import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service"
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-process-invoice-info',
  templateUrl: './process-invoice-info.component.html',
  styleUrls: ['./process-invoice-info.component.css'],
  animations: [
    fade
  ]
})
export class ProcessInvoiceInfoComponent implements OnInit {
  dataList;
  serviceForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  modal;
  selectDistributorData;
  selectServiceChargeModeData;

  sub: Subscription;
  queryInvoiceNo: number;
  InvoiceNo: any;
  list;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private spinner: SpinnerService,
    private _location: Location,
    private route: ActivatedRoute,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.InvoiceNo = params.invoiceNo;
        this.getInvoiceInfo(this.InvoiceNo);
      }else{ 
        _location.back();
      }
    });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      invoiceNo: ['', Validators.required ],
      invoiceAmount: ['', Validators.required ],
      settledDate: ['', Validators.required ],
      vendorRef: ['', Validators.required ],  
      remarks: ['', Validators.required ],
    });
  }


  ngOnInit(): void {
  }


  getInvoiceInfo(invoiceNo) {
    let payload: any = {
      invoiceNo: invoiceNo,
    };
    this.spinner.getSpinner();
    this.service.getSettlementInvoiceInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      console.log("$$$$$$=>", data)
      this.list = data;
      this.spinner.hideSpinner();
      this.serviceForm = this.fb.group({
        invoiceNo: [data.invoiceNo, Validators.required ],
        invoiceAmount: [data.invoiceAmount, Validators.required ],
        settledDate: ['', Validators.required ],
        vendorRef: ['', Validators.required ],  
        remarks: ['', Validators.required ],
      });
      // this.serviceForm = this.fb.group({
      //   invoiceNo: [data.invoiceNo, Validators.required ],
      //   invoiceAmount: [data.settledDate, Validators.required ],
      // });


    },
      (failureResponse: string) => {
        this.spinner.hideSpinner();
         console.log("onFailure:" + JSON.stringify(failureResponse));
      });
  }


  backtowebrole(){
    this._location.back();
  }

  OnProcessInvoice() {
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      invoiceNo: this.serviceForm.value.invoiceNo,
      settledAmount: this.serviceForm.value.invoiceAmount,
      settledDate: this.serviceForm.value.settledDate,
      vendorRef: this.serviceForm.value.vendorRef,
      doneByUserId: this.list.createdByUserId,
      remarks: this.serviceForm.value.remarks

    };
    this.spinner.getSpinner();
    this.service.adminReconsileInvoice(payload).subscribe(successResponse => {
      // console.log("successResponse", successResponse)
      if(successResponse.invoiceNo){
        let invoiceNo = successResponse.invoiceNo;
        this.spinner.hideSpinner();
        this.toastr.success(`You have processed Id ${successResponse.invoiceNo}`, 'Seccessfully')
        this.router.navigate(['dashboard/view/getInvoicelist'], { queryParams: { invoiceNo }})
        .then(() => {
          window.location.reload();
        });
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        this.toastr.error(failureResponse.statusText, 'Error')
        // console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

}
