<div @fade class="row">
    <div class="col grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h4 class="card-title">Re Send Merchant Credentials</h4>
                    </div>
                    <div class="col-md-4">
                        <h4 class="card-title" style="float: right;">
                            <!-- <button (click)="onButtonClick()" class="btn btn-primary mr-2">Edit</button> -->
                            <button (click)="onButtonClick(event)" class="btn btn-primary mr-2">{{event}}</button>
                            <button type="submit" class="btn btn-primary" (click)="backToCashier()">Back To
                                Cashier</button>
                        </h4>
                    </div>
                </div>
                <form class="forms-sample"  [formGroup]="serviceForm" novalidate (ngSubmit)="OnResetMerchantCredentials()">
                    <!-- <fieldset [disabled]="isDisabled"> -->
                        <div class="card-subtitle">Merchant Info</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">User Id</label>
                                    <input type="text" class="form-control" formControlName="userId"
                                        placeholder="User Id" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userId.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.userId.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.userId.errors.required">User Id required
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">Merchant Name</label>
                                    <input type="text" class="form-control" formControlName="userTitle"
                                        placeholder="User Title" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userTitle.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.userTitle.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.userTitle.errors.required">User Title is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email</label>
                                    <input type="email" class="form-control" formControlName="email" placeholder="Email"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.email.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.email.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.email.errors.required">Email is required</div>
                                        <div *ngIf="serviceForm.controls.email.errors.email">It`s Not An Email Plz Check
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">API-Key</label>
                                    <input type="text" class="form-control" formControlName="accessKey"
                                        placeholder="API-Key" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.accessKey.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.accessKey.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.accessKey.errors.required">API-Key is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col text-center">
                                <button type="submit" class="btn btn-primary" style="width: 30%;">Send</button>
                                <!-- <button class="btn btn-default">Centered button</button> -->
                            </div>
                        </div>
                    <!-- </fieldset> -->
                </form>
            </div>
        </div>
    </div>
</div>