import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup,  FormBuilder, FormArray, FormControl,  Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-settingconfig',
  templateUrl: './settingconfig.component.html',
  styleUrls: ['./settingconfig.component.css'],
  animations: [
    fade
  ]
})
export class SettingconfigComponent implements OnInit {

  querymerchantGroupId: number;
  merchantGroupId: any;
  dataRecords: any;
  sub: Subscription;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  selectBankData;
  selectServiceChargeModeData;
  // dataSet = [{}]
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.querymerchantGroupId = +params['merchantGroupId'];
        if (Number.isNaN(this.querymerchantGroupId)) {
          this.merchantGroupId = "";
        } else {
          this.merchantGroupId = this.querymerchantGroupId;
          this.viewGroupSettings(this.merchantGroupId);
        }
        //this.fnListTransactionHistoryWithMerchantId(this.queryMerchantId);
      });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      merchantGroupId: ['', Validators.required ],
      groupName: ['', Validators.required ],
      description: ['', Validators.required ],
      selectedRecord: new FormArray([])
    });
  }


  ngOnInit(): void {
  }

  viewGroupSettings(merchantGroupId){
    var groupSettingIds = [];
    let payload: any = {
      merchantGroupId: merchantGroupId,
    };
    this.spinner.getSpinner();
    // console.log("PAYLOAD=> ", payload)
    this.service.getMerchantGroupInfo({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        if (data.groupSettings != "") {
          //roleIds = JSON.parse(data.groupSettings);
          JSON.parse(data.groupSettings).forEach(element => {
            groupSettingIds.push(element);
          });
        } else {
          this.spinner.hideSpinner();
          groupSettingIds = [];
        }
        this.getGroupSettingsList(groupSettingIds, data.merchantGroupId, data.groupName, data.description);
        // console.log("kkkk", data)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getGroupSettingsList(groupSettings?, merchantGroupId?, groupName?, description?){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getMerchantSettings({payload: payload}).subscribe(successResponse => {
      this.spinner.hideSpinner();
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      this.dataRecords = records;
      let controls = this.dataRecords.map(c => new FormControl(false));
      groupSettings.forEach(element => {
        controls[element - 1].setValue(true);
      });
      this.serviceForm = this.fb.group({
        merchantGroupId: [merchantGroupId, Validators.required],
        groupName: [groupName, Validators.required],
        description: [description, Validators.required],
        dataRecords: new FormArray(controls)
      });
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure: " + JSON.stringify(failureResponse));
    });
  }

  backtowebrole(){
    this.router.navigate(['dashboard/listMerchantGroups']);
  }
  onAssignSettings(){
    var rolesArray = [];
    var settingsArrayString;
    if (this.serviceForm.invalid) {
      return;
    }
    const selectedOrderIds = this.serviceForm.value.dataRecords
      .map((v, i) => v ? this.dataRecords[i].featureId : null)
      .filter(v => v !== null);
    settingsArrayString = this.convertToStringArray(selectedOrderIds);

    let payload: any = {
      groupId: this.serviceForm.value.merchantGroupId,
      settings: settingsArrayString
    };
    // console.table(payload);
    this.spinner.getSpinner();
    this.service.assignGroupSettings(payload).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      this.serviceForm.reset(this.serviceForm.value);
      // console.log("UUUUU=> ", data)
      setTimeout(() => {
        this.toastr.success('You assigned a group setting', 'Successfully')
        this.router.navigate(['dashboard/listMerchantGroups']);
      }, 1000);
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  convertToStringArray(obj) {
    var arr = "[";
    arr += obj + "]";
    return arr;
  }


}
