<div @fade class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Institution List</h4>
        <!-- <p class="card-description">Distributors List</p> -->

        <div class="table-responsive" id="section-two">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Institution Name</th>
                <th>Code</th>
                <th>Prefix</th>
                <th>Allow Physical Request</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of distDataList
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td>{{ x.institutionId }}</td>
                <td>{{ x.institutionName }}</td>
                <td>{{ x.institutionCode }}</td>
                <td>{{ x.prefix }}</td>
                <!-- <td>{{ x.allowPhysicalRequest }}</td> -->
                <td (click)="toggleAllowPhysicalRequest(x)">
                  <div class="custom-control custom-switch">
                    <input
                      [(ngModel)]="x.allowPhysicalRequest"
                      type="checkbox"
                      class="custom-control-input"
                      name="switch"
                      [checked]="x.allowPhysicalRequest == 1"
                    />
                    <label class="custom-control-label"> </label>
                  </div>
                </td>
                <!-- <td>{{ x.createdDate | date: 'yyyy-MM-dd' }}</td> -->
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%" class="d-print-none">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container d-print-none">
            <div style="text-align: center; padding: 24px">{{ message }}</div>
          </div>
        </div>

        <!-- <form
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="PassViewReport()"
        >
          <div class="row">

              <div class="form-group">
                <label for="selectDistributor">Select Distributor</label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="SelectedMonth">Select a Month</label>
                <select
                  class="form-control-sm"
                  formControlName="SelectedMonth"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.SelectedMonth.errors
                  }"
                >
                  <option *ngFor="let c of monthsList" [ngValue]="c.id">
                    {{ c.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.SelectedMonth.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.SelectedMonth.errors?.required">
                    please Select Month
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="startDate">Transaction Date </label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="transactionDate"
                  placeholder="Transaction Date"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.transactionDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.transactionDate.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.transactionDate.errors?.required"
                  >
                    Transaction Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form> -->
      </div>
    </div>
  </div>
</div>
