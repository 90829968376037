import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-create-billers',
  templateUrl: './create-billers.component.html',
  styleUrls: ['./create-billers.component.css'],
  animations: [
    fade
  ]
})
export class CreateBillersComponent implements OnInit {

  serviceForm: FormGroup;
  submitted = false;

  selectBillingCategory;
  selectPaymentProcessorList;


  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.loadBillerCategories();
    this.loadBillingPaymentProcessor();
  }

  ngOnInit(): void {
  }

  createForm() {
    this.serviceForm = this.fb.group({
      billerCode: ['', Validators.required],
      billerName: ['', Validators.required],
      billerCategoryId: ['', Validators.required],
      paymentProcessorId: ['', Validators.required],     
    });
  }

  loadBillingPaymentProcessor(){
    let payload:any = {}
    this.spinner.getSpinner();
    this.service.getPaymentProcessorList({payload: payload}).subscribe((res) => {
      if(res.success){
        this.spinner.hideSpinner();
        var response = JSON.parse(res.records);
        this.selectPaymentProcessorList = response
        // console.log("Gaa=> ", response)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error(response.message, 'Error');
      }
    })
  }

  loadBillerCategories(){
    let payload:any = {}
    this.spinner.getSpinner();
    this.service.getBillerCategoryList({payload: payload}).subscribe((res) => {
      if(res.success){
        this.spinner.hideSpinner();
        var response = JSON.parse(res.records);
        this.selectBillingCategory = response
      }else {
        this.spinner.hideSpinner();
        this.toastr.error(response.message, 'Error');
      }
    })
  }

  backToService(){
    this._location.back();
  }



  createBillersInfo(){
    this.submitted = true;
    if(this.serviceForm.invalid)return;
    let currentUser = this._sessionStorageService.getSessionData()
    let payload:any = {
      billerCode: this.serviceForm.value.billerCode,
      billerName: this.serviceForm.value.billerName,
      billerCategoryId: this.serviceForm.value.billerCategoryId,
      paymentProcessorId: this.serviceForm.value.paymentProcessorId,
      createdUserId: currentUser.userId

    }
    this.service.createBillers({payload: payload}).subscribe((res) => {
      if(res.success){
        this.toastr.success(`Biller Has Created ${res.billerId}`, 'Success');
        this._location.back();
      }else{
        this.toastr.error(res.messagem, 'Error');
      }
    })
  }
}
