import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-customers-table',
  templateUrl: './waafi-customers-table.component.html',
  styleUrls: ['./waafi-customers-table.component.css'],
  animations: [
    fade
  ]
})
export class CustomersTableComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  getDistrForm: FormGroup;
  sub: any;
  selectCycle;
  showDistributor = false;
  merchantTitle;
  distributorList;
  lisCustomers;
  updateForm: FormGroup;
  selected = pagination.selected;
  p: number = pagination.p;
  term;
  trackingNumber;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.distributorId = params['distributorId'];
        this.getCustomers(this.distributorId, "", "", "", true);
        this.getFrom();
        // this.getDistributor();
        this.getDistrFrom();
        this.updatedCustomersForm();
      } else {
        _location.back();
      }
    });

  }
  getFrom() {
    this.getForm = this.fb.group({
      mobileNumber: ['', Validators.required],
      pLastFourDigit: ['', Validators.required],
      trackingNumber: [''],
    });
  }
  handleSubmit() {
    this.submitted = true;
    let mobileNumber = this.getForm.value.mobileNumber == undefined ? "" : this.getForm.value.mobileNumber;
    let pLastFourDigit = this.getForm.value.pLastFourDigit == undefined ? "" : this.getForm.value.pLastFourDigit;
    let trackingNumber = this.getForm.value.trackingNumber == undefined ? "" : this.getForm.value.trackingNumber;
    // if (this.getForm.invalid) {
    //   return;
    // }

    this.getCustomers(this.distributorId, mobileNumber, pLastFourDigit, trackingNumber, false);
  }

  getDistributor() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distributorList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  // handleSubmitDistr() {
  //   let distributorId = this.getDistrForm.value.selectData
  //   this.distributorId = distributorId
  //   if (this.getDistrForm.invalid) {
  //     return;
  //   }
  //   this.showDistributor = true;
  //   this.getCustomers(distributorId, "", "", true);
  // }
  getDistrFrom() {
    this.getDistrForm = this.fb.group({
      selectData: ['', Validators.required],
    });
  }
  // backToService() {
  //   this.showDistributor = false;
  //   this.dataList = []
  //   this.listData = []
  //   this.lisCustomers = []
  // }
  backToService() {
    this._location.back();
  }

  ngOnInit(): void {
    // console.log("PPPPP=>", this.selectData)
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
      this.merchantTitle = "Waafi Card Customers"
    } else {
      this.isThirtParty = false;
      this.merchantTitle = "Waafi Card Customers"
    }
  }

  getCustomers(distributorId, mobileNumber, pLastFourDigit, trackingNumber, isOnload) {
    let payload: any = {
      distributorId: distributorId,
      pNumber: mobileNumber,
      pLastFourDigit: pLastFourDigit,
      trackingNumber: trackingNumber,
    };

    this.isTrue = true;
    this.spinner.getSpinner();
    this.service.getCustomers({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          // this.listData = records;
          this.listData = this.removeDuplicateRecords(records);

          if (isOnload) {
            this.lisCustomers = records
          }

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
          this.listData = [];
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
        this.listData = []
        this.lisCustomers = []
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  clearForm() {
    this.getForm.reset();
    this.listData = this.lisCustomers;
  }

  ViewCustomerCards(data) {
    // let merchantId = data.mobileNumber;
    let customerName = data.fullName;
    let mobileNumber = data.mobileNumber;
    let customerId = data.customerId;
    this.router.navigate(["dashboard/view/cardDetails"], { queryParams: { mobileNumber, customerName, customerId, distributorId: this.distributorId } })
  }

  selectedCustomer(x) {
    this.updateForm = this.fb.group({
      customerId: [x.customerId, Validators.required],
      firstName: [x.firstName, [Validators.required, Validators.minLength(3), Validators.maxLength(12), Validators.pattern('^[a-zA-Z0-9]*$')]],
      middleName: [x.middleName, [Validators.required, Validators.minLength(3), Validators.maxLength(12), Validators.pattern('^[a-zA-Z0-9]*$')]],
      lastName: [x.lastName, [Validators.required, Validators.minLength(3), Validators.maxLength(12), Validators.pattern('^[a-zA-Z0-9]*$')]],
      previosName: [x.fullName, Validators.required],
    });
  }
  handleEditCustomer() {
    this.submitted = true;
    if (this.updateForm.invalid) {
      return;
    }
    let ObjData: any = {
      payload: {
        customerId: this.updateForm.value.customerId,
        firstName: this.updateForm.value.firstName.toUpperCase(),
        middleName: this.updateForm.value.middleName.toUpperCase(),
        lastName: this.updateForm.value.lastName.toUpperCase(),
      },
      previosName: this.updateForm.value.previosName,
      eventSource: "Distributor",
    };
    this.spinner.getSpinner();
    this.service.updateCustomerInfo(ObjData).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (successResponse.success) {
        this.toastr.success(successResponse.records.message);
        $('#updateCustomerModel').modal('hide');
        this.spinner.hideSpinner();
        this.getCustomers(this.distributorId, "", "", "", true);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message, 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  updatedCustomersForm() {
    this.updateForm = this.fb.group({
      customerId: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(12), Validators.pattern('^[a-zA-Z0-9]*$')]],
      middleName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(12), Validators.pattern('^[a-zA-Z0-9]*$')]],
      lastName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(12), Validators.pattern('^[a-zA-Z0-9]*$')]],
      previosName: [''],
    });
  }

  removeDuplicateRecords(records) {
    const uniqueRecords = [];
    const seen = new Set();

    records.forEach(record => {
      if (!seen.has(record.customerId)) {
        uniqueRecords.push(record);
        seen.add(record.customerId);
      }
    });

    return uniqueRecords;
  }

}
