<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Distriputors Accounts</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="passCreateView()">Add Account</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <select class="form-control form-control-sm" [(ngModel)]="selected">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                            </select>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Account Id</th>
                                <th>Currency Code</th>
                                <th>Account Type Id</th>
                                <th>CreatedByUser Id</th>
                                <th>User Id</th>
                                <th>Account Status</th>
                                <th>View</th>
                                <th>Default A/C</th>
                                <!-- <th>Dist: Acc</th>
                                <th>Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td class="py-1">{{x.accountId}}</td>
                                <td>{{ x.currency}}</td>
                                <td>{{ x.accountTypeId}}</td>
                                <td>{{ x.createdByUserId}}</td>
                                <td>{{ x.userId}}</td>
                                <td>{{ x.accountStatusDesc}}</td>
                                <!-- <td>{{ x.profileStatusDesc}}</td> -->
                                <td (click)="passDistriputorListView(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                                <td (click)="updateDistributorDefAccountIds(x)">
                                    <div class="custom-control custom-switch">
                                        <input [(ngModel)]="x.accountId == accountId" type="checkbox"
                                            class="custom-control-input"  name="switch">
                                        <label class="custom-control-label">
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%;">
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>