<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-11">
                        <h4 class="card-title">Create Route</h4>
                    </div>
                    <div class="col-md-1">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backtowebrole()">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate  (ngSubmit)="OnCreateRoutesConfig()">
                            <div class="card-subtitle">Route Settings</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                            <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Route Prefix</label>
                                            <input type="text" class="form-control"  formControlName="routePrefix" placeholder="Route Prefix"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.routePrefix.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.routePrefix.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.routePrefix.errors.required">Route Prefix    is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">Is Active</label>
                                        <select class="form-control" formControlName="isActive" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.isActive.errors }">
                                            <option [ngValue]="1" selected>Yes</option>
                                            <option [ngValue]="0">No</option>
                                        </select>   
                                        <div *ngIf="submitted && serviceForm.controls.isActive.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.isActive.errors.required">IsActive is required</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">Bank Id</label>
                                            <select class="form-control" formControlName="selectedBank" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedBank.errors }">
                                                <option *ngFor="let p of selectBankData" [ngValue]="p.bankId">{{p.bankName}}</option>
                                            </select>
                                            <div *ngIf="submitted && serviceForm.controls.selectedBank.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.selectedBank.errors.required">Charge Mode Id is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">Currency Code</label>
                                                <select class="form-control" formControlName="currencyId">
                                                    <option [ngValue]="1" selected>USD</option>
                                                    <option [ngValue]="2">EBR</option>
                                                    <option [ngValue]="3">SSH</option>
                                                    <option [ngValue]="4">EUR</option>
                                                    <option [ngValue]="5">SLSH</option>
                                                </select>
                                                <div *ngIf="submitted && serviceForm.controls.currencyId.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.currencyId.errors.required">Currency Code is required</div>
                                                </div>
                                        </div>
                                    </div>
                                   
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                            <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button>
                            <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
