<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Create Distributor Account</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to Service</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate
                                (ngSubmit)="OnCreateUserAccount()">

                                <div class="card-subtitle">Distributor Account</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">User Id</label>
                                            <input type="text" class="form-control" formControlName="userId" readonly
                                                placeholder="User Id"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.userId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.userId.errors.required">
                                                    User Id is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Account No</label>
                                            <input type="text" class="form-control" formControlName="accountNo"
                                                placeholder="Account No"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.accountNo.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.accountNo.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.accountNo.errors.required">
                                                    Account No is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Currency Code</label>
                                            <select class="form-control" formControlName="currencyId" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.currencyId.errors }">
                                                <option *ngFor="let p of selectCurrencyData" [ngValue]="p.currencyId">{{p.currencyCode}}</option>
                                              </select>
                                            <div *ngIf="submitted && serviceForm.controls.currencyId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.currencyId.errors.required">Currency Code is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Account Types</label>
                                            <select class="form-control" formControlName="accountTypeId" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.accountTypeId.errors }">
                                                <option *ngFor="let p of selectAccountTypesData" [ngValue]="p.accountTypeId">{{p.accountTypeDesc}}</option>
                                              </select>
                                            <div *ngIf="submitted && serviceForm.controls.accountTypeId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.accountTypeId.errors.required">Account Types is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-center">
                                        <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                                        <!-- <button class="btn btn-default">Centered button</button> -->
                                    </div>
                                </div>
                                <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                            </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

