<div @fade class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md">
            <h4 class="card-title">{{title}}</h4>
          </div>
          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary mb-2"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <!-- <p class="card-description">Distributors List</p> -->

        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-2">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>

          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                (click)="print()"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="fnPDF()"
                type="button"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="button"
                (click)="exportAsXLSX()"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>

        <div class="table-responsive" id="section-one">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Service Name</th>
                <th>TrackingNumber</th>
                <th>Pan</th>
                <th>WalletReference</th>
                <th>Amount</th>
                <th>Count</th>
                <th>AcquirerCountry</th>
                <th>Mdes3Ds</th>
                <th>Approved</th>
                <th>Reversed</th>
                <th>Declined</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td>{{ x.serviceName }}</td>
                <td>{{ x.trackingNumber }}</td>
                <td>{{ x.maskedPan }}</td>
                <td>{{ x.walletReference }}</td>
                <td>{{ formatTransactionAmount(x.transactionAmount) }}</td>
                <td>{{ x.transactionCount }}</td>
                <td>{{ x.acquirerCountry }}</td>
                <td>{{ x.mdes3Ds }}</td>
                <td>{{ x.approved }}</td>
                <td>{{ x.reversed }}</td>
                <td>{{ x.declined }}</td>

                <!-- <td>{{ x.createdDate | date: 'yyyy-MM-dd' }}</td> -->
              </tr>
              <tr style="background-color: #09df66be">
                <td>Total:</td>
                <td></td>

                <td></td>
                <td></td>
                <td>{{ sumTranAmount | number : "1.2-2" }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%" class="d-print-none">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container d-print-none">
            <div style="text-align: center; padding: 24px">{{ message }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
