import { formatDate, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ExcelService } from 'src/app/service/service/excel.service';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-card-transactions-table',
  templateUrl: './card-transactions-table.component.html',
  styleUrls: ['./card-transactions-table.component.css'],

  animations: [
    fade
  ]
})
export class CardTransactionsComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  merchantTitle;
  onloadCardTrasnsection;
  selected = 50;
  p: number = pagination.p;
  term;
  statusList;
  cardTransactionList;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private excelService: ExcelService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {

        this.distributorId = params['distributorId'];
        this.updatedAccessForm();
        this.updatedCycleForm();
        this.subscripsForm();
        this.createForm();
        this.updateForm();
        // this.getProductLists();
        // this.getcards();
        this.getFrom();
        this.getCardTransactions("0", "", "", "", "", "", "");
      } else {
        _location.back();
      }
    });

  }
  getFrom() {
    let currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.getForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      status: ['', Validators.required],
      transactionId: ['',],
      pLastFourDigit: ['', Validators.required, Validators.minLength(4)],
      pNumber: ['', Validators.required, Validators.minLength(13)],
    });
  }
  createForm() {
    this.serviceForm = this.fb.group({
      distributorId: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      productId: ['', Validators.required],
      userTitle: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  updateForm() {
    this.updatesForm = this.fb.group({
      distributorId: ['', Validators.required],
      merchantUId: ['', Validators.required],
      userId: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      productId: ['', Validators.required],
      userTitle: ['', Validators.required],
    });
  }
  updatedAccessForm() {
    this.updateAccessForm = this.fb.group({
      selectedAccess: ['', Validators.required],
    });
  }
  updatedCycleForm() {
    this.updateCycleForm = this.fb.group({
      selectedCycle: ['', Validators.required],
    });
  }

  subscripsForm() {
    this.subscripForm = this.fb.group({
      subscriptionAutoRenewal: ['', Validators.required],
      selectedSubscription: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // console.log("PPPPP=>", this.selectData)
    this.statusList = [{ id: "", name: "ALL" }, { id: "Completed", name: "Completed" }, { id: "Approved", name: "Approved" }, { id: "Declined", name: "Declined" }, { id: "Failed", name: "Failed" }, { id: "Declined", name: "Declined" }, { id: "Timeout", name: "Timeout" }, { id: "Reversed", name: "Reversed" }, { id: "Refuded", name: "Refuded" }];
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
      this.merchantTitle = "Cards Transactions"
    } else {
      this.isThirtParty = false;
      this.merchantTitle = "Cards Transactions"
    }
  }
  handleSubmit() {
    this.submitted = true;
    let startDate = this.getForm.value.startDate == undefined ? "" : this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate == undefined ? "" : this.getForm.value.endDate;
    let pLastFourDigit = this.getForm.value.pLastFourDigit == undefined ? "" : this.getForm.value.pLastFourDigit.toString();
    let pNumber = this.getForm.value.pNumber == undefined ? "" : this.getForm.value.pNumber.toString();
    let status = this.getForm.value.status == undefined ? "" : this.getForm.value.status;
    let transactionId = this.getForm.value.transactionId == undefined ? "" : this.getForm.value.transactionId;

    this.getCardTransactions("0", startDate, endDate, pLastFourDigit, pNumber, status, transactionId);
  }
  getDistributorId() {
    if (!this.userTypeId) {
      console.log("IIIII222=>", this.userTypeId)
      this.submitted = true;
    } else {
      console.log("IIIII333=>", this.userTypeId)
      this.serviceForm = this.fb.group({
        distributorId: [this.userTypeId, Validators.required],
        firstName: ['', Validators.required],
        middleName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        telephone: ['', Validators.required],
        address: ['', Validators.required],
        address2: ['', Validators.required],
        website: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', Validators.required],
        productId: ['', Validators.required],
        userTitle: ['', Validators.required],
        loginId: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]]
      });
    }
    // this.distributorId = this.userTypeId;
  }

  getOnloadData() {

    this.cardTransactionList = this.onloadCardTrasnsection;
    // this.getFrom.reset()
    this.getForm.reset();
  }
  formatTransactionAmount(amount) {
    if (amount === null || amount === undefined || amount === '') {
      return '0.00';
    }
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  // getUserLists(){
  //   let userTypeId = 0;
  //   let payload: any = {
  //     userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

  //   };

  //   this.service.getUserList({payload: payload}).subscribe(successResponse => {

  //     // console.log("haaa", successResponse)
  //     if(successResponse.records){
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       var records = JSON.parse(data);
  //       this.dataList =records;
  //       console.log("kkkk", records)
  //     }else {
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }


  getcards() {
    // this.getSubmitted = true;
    // if (this.getForm.invalid) {
    //     return;
    // }
    // userTypeId?, merchantTypeId?
    let payload: any = {
      pNumber: "",

    };


    this.isTrue = true;
    this.spinner.getSpinner();
    this.service.getCards({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getCardTransactions(cardId, pStartDate, pStartEnd, pLastFourDigit, pNumber, status, transactionId) {
    let payload: any = {
      distributorId: this.distributorId,
      cardId: cardId,
      pStartDate: pStartDate,
      pStartEnd: pStartEnd,
      pLastFourDigit: pLastFourDigit,
      pNumber: pNumber,
      status: status,
      transactionId: transactionId,
    };
    this.spinner.getSpinner();
    this.service.getcardTransactions({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.cardTransactionList = records;
        if (pStartDate == "") {
          this.onloadCardTrasnsection = records
        }

      } else {

        this.spinner.hideSpinner();
        this.cardTransactionList = []
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  backToService() {
    this._location.back();
  }

  viewTransactionsInfo(data) {
    let merchantId = data.merchantId;
    this.router.navigate(["dashboard/view/transactionHistory"], { queryParams: { merchantId } })
  }
  viewMerchantsDetails(data) {
    let merchantId = data.walletId;
    this.router.navigate(["dashboard/view/cardDetails"], { queryParams: { merchantId } })
  }


  getUpdateInfo(data) {
    this.accessProfileId = data.accessProfileId;
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getUserAccessProfileStatus({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // console.log("data :=> ", records);
      this.selectAccess = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getLiveCycleInfo(data) {
    this.merchantId = data.merchantId;
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getLiveCycleStatus({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      console.log("data :=> ", records);
      this.selectCycle = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onUpdateAccessProfileInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateAccessForm.invalid) {
      return;
    }
    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.updateAccessForm.value.selectedAccess
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (data.accessProfileId) {
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        // this.getMerchantUserList(this.userTypeId, this.queryMerchantTypeId);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onUpdateLiveCycleInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateCycleForm.invalid) {
      return;
    }
    let payload: any = {
      merchantId: this.merchantId,
      lifeCycleStatusId: this.updateCycleForm.value.selectedCycle
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateLiveCycleStatus({ payload: payload }).subscribe(successResponse => {
      var responseObj = successResponse.records;
      var data = responseObj;
      // console.log("UUUU=>", successResponse)
      if (data.merchantId) {
        this.toastr.success(`You have updated to ${data.merchantId}`, 'Successfully')
        $('#updateliveModel').modal('hide');
        this.spinner.hideSpinner();
        // this.getMerchantUserList(this.userTypeId);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  passCreateView() {
    let distributorId = this.userTypeId;
    this.router.navigate(["dashboard/view/createMerchantInfo"], { queryParams: { distributorId } })
  }
  passUpdateView(x) {
    let merchantId = x.merchantId;
    this.router.navigate(["dashboard/view/updateMerchantInfo"], { queryParams: { merchantId } })
  }


  // getMerchantSubscriptionTypesList() {
  //   let expressRoute = "/getMerchantSubscriptionTypesList";

  // }

  onUpdateMerchantSubscriptionInfo() {
    this.submitted = true
    if (this.subscripForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      merchantId: this.merchantId,
      subscriptionTypeId: this.subscripForm.value.selectedSubscription,
      doneByUserId: currentUser.userId
    };
    if (this.subscripForm.value.subscriptionAutoRenewal == true) {
      payload.subscriptionAutoRenewal = 1;
    } else {
      payload.subscriptionAutoRenewal = 0;
    }
    // console.log("QAAAAAAAQ2", payload)
    this.spinner.getSpinner();
    this.service.updateMerchantSubscription({ payload: payload }).subscribe(successResponse => {
      var data = successResponse;
      // console.log("data :=> " + JSON.stringify(data));
      if (data.success) {
        this.spinner.hideSpinner();
        var records = data.records;
        // console.log("QAAAAAAAQ1", records)
        this.toastr.success('You updated subscription', 'Successfully');
      } else {
        // console.log("QAAAAAAQ")
        this.spinner.hideSpinner();
        this.toastr.error('Someting went wron!!', 'Error')
      }

    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      //console.log("onFailure:" + JSON.stringify(failureResponse));
      this.toastr.error('Something went wron for this request', 'Error')
    });

  }

  viewSubscriptionInfo(sub) {
    this.merchantId = sub.merchantId;
    console.log(sub)

    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getMerchantSubscriptionTypes({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      //console.log("data :=> " + JSON.stringify(data));
      var records = JSON.parse(data.records);

      if (records) {
        this.spinner.hideSpinner();
        var selectedRecord: any = {};
        if (this.subscripForm.value.selectedSubscription != null) {
          var length = records.length;
          for (var i = 0; i < length; i++) {
            var item = records[i];
            if (item.subscriptionTypeId == this.subscripForm.value.selectedSubscription) {
              selectedRecord = item;
              break;
            }
          };
          this.selectSubscription = records;
          this.subscripForm = this.fb.group({
            selectedSubscription: [selectedRecord.subscriptionTypeId, Validators.required],
          });
        }
        // this.selectSubscription = records;
        // console.log("FAAAAAQ1", records)
      } else {
        this.spinner.hideSpinner();
        // console.log("FAAAAAQ")
      }

    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  passMerchantAccountList(x) {

    let merchantId = x.merchantId;
    this.router.navigate(["dashboard/view/merchantAccountList"], { queryParams: { merchantId } })
  }
  passListCashierInfo(x) {

    let merchantId = x.merchantId;
    this.router.navigate(["dashboard/view/getCashierList"], { queryParams: { merchantId } })
  }

  print() {
    let printContents = document.getElementById('section-one').innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    setTimeout(() => {
      window.location.reload();
    }, 1000)
    // this._location.back();
  }
  fnPDF() {
    html2canvas(document.getElementById("section-one"), { allowTaint: true }).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) + 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height + i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(`waafipay_${moment().format('MMM Do YYYY')}_card_transactions.pdf`);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000)
      // this._location.back();
    });
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.cardTransactionList, 'waafipay_card_transactions');
    // this._location.back();
  }
}
