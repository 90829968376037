import { Component, OnInit } from '@angular/core';
import { ExpressRequestService } from '../../../service/express-request/express-request.service'
import { SessionStorageService } from '../../../service/session-storage/session-storage.service';
import { Router } from '@angular/router'
import { ServiceService } from 'src/app/service/service/service.service';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userTitle: any;
  appName
  dataList;
  NotList;
  numTranList;
  numSettleList;
  numMerchantList;
  numCashierList;
  unSettled;

  constructor(
    private router: Router,
    private service: ServiceService,
    private spinner: SpinnerService,
    private expressRequestService: ExpressRequestService,
    private _sessionStorage: SessionStorageService
  ) {
    this.loadSettlementByDash();
    this.loadAllDataDash();
    // console.log("Avrege is => ", this.percentage(400, 700))

  }

  percentage(partialValue, totalValue) {
    let data = totalValue - partialValue;
    let percent = data / partialValue;
    return percent * 100;
  }

  ngOnInit(): void {
    var currentUser = this._sessionStorage.getSessionData();
    this.userTitle = currentUser.userTitle;
  }

  loadSettlementByDash() {
    let payload: any = {
      limit: 10
    }
    this.spinner.getSpinner();
    this.service.getSettlementRequestListByDash({ payload: payload }).subscribe((res) => {
      if (res.success) {
        this.spinner.hideSpinner();
        const response = JSON.stringify(res.records)
        const records = JSON.parse(response);
        const data = JSON.parse(records);
        this.unSettled = data.length;
        this.dataList = data;
        var arr = [];
        data.forEach((el) => {
          let list = { userTitle: el.userTitle, createdDate: moment(el.createdDate, "YYYYMMDD").fromNow() }
          arr.push(list)
        })
        this.NotList = arr;
        // console.log("Data ==>", arr)
      } else {
        console.log("Error comming out");
        this.spinner.hideSpinner();
      }
    })
  }

  loadAllDataDash() {
    let payload: any = {

    }
    this.spinner.getSpinner();
    this.service.getNumOfTranByMonth({ payload: payload }).subscribe((res) => {
      if (res.success) {
        this.spinner.hideSpinner();
        const response = JSON.stringify(res.records)
        const data = JSON.parse(response);
        // const data = JSON.parse(records);
        this.numTranList = data.numOfTranByMonth;
        // console.log("Data ==>", data)
      } else {
        console.log("Error comming out");
        this.spinner.hideSpinner();
      }
    })
    this.service.getNumOfSettlementByMonth({ payload: payload }).subscribe((res) => {
      if (res.success) {
        this.spinner.hideSpinner();
        const response = JSON.stringify(res.records)
        const data = JSON.parse(response);
        this.numSettleList = data.numOfSettlementByMonth;
      } else {
        console.log("Error comming out");
        this.spinner.hideSpinner();
      }
    })
    this.service.getNumOfMerchant({ payload: payload }).subscribe((res) => {
      if (res.success) {
        this.spinner.hideSpinner();
        const response = JSON.stringify(res.records)
        const data = JSON.parse(response);
        this.numMerchantList = data.numOfMerchant;
      } else {
        console.log("Error comming out");
        this.spinner.hideSpinner();
      }
    })
    this.service.getNumOfCashiers({ payload: payload }).subscribe((res) => {
      if (res.success) {
        this.spinner.hideSpinner();
        const response = JSON.stringify(res.records)
        const data = JSON.parse(response);
        this.numCashierList = data.numOfCashier;
      } else {
        this.spinner.hideSpinner();
        console.log("Error comming out");
      }
    })
  }

}
