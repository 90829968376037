import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-merchant-accounts',
  templateUrl: './merchant-accounts.component.html',
  styleUrls: ['./merchant-accounts.component.css'],
  animations: [
    fade
  ]
})
export class MerchantAccountsComponent implements OnInit {
  getForm: FormGroup;
  getSubmitted = false;
  selectData;
  tranStatusId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
  }

  getFrom() {
    this.getForm = this.fb.group({
      merchantId: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  passTransaction() {
    this.getSubmitted = true;
    if (this.getForm.invalid) {
      return;
    }
    let merchantId = this.getForm.value.merchantId;
    this.router.navigate(["dashboard/view/merchantAccountList"], { queryParams: { merchantId } })
  }

}
