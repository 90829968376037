import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

import { ServiceService } from "src/app/service/service/service.service";
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-distributor',
  templateUrl: './Configuration.component.html',
  styleUrls: ['./Configuration.component.css'],
  animations: [
    fade
  ]
})
export class ConfigurationComponent implements OnInit {

  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  submitted = false;
  modal;
  isUpdate: boolean = false;
  listBody: boolean = false;
  distributorId;
  selected = pagination.selected;
  p: number = pagination.p;
  institutionId;
  term;
  selectrRecordId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private spinner: SpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.updateForm();

    var currentUser = this._sessionStorageService.getSessionData();
    this.distributorId = currentUser.referenceId
    this.getConfigurations()
  }


  updateForm() {
    this.updatesForm = this.fb.group({
      name: ['', Validators.required],
      paramValue: ['', Validators.required],
      sattledId: ['',]
    });
  }

  ngOnInit(): void {
    this.getConfigurations();

  }

  hanldeSubmit() {
    if (this.isUpdate) {
      this.updateConfigurations();
    } else {
      this.createConfigurations();
    }
  }

  getConfigurations() {
    let payload: any = {
      // distributorId: this.distributorId
    };
    this.spinner.getSpinner();
    this.service.getConfigurations({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  ShowModelCreateBranch() {
    $('#createBranchModel').modal('show');
    this.isUpdate = false;
    this.updatesForm.reset();
  }
  modelOnCancel() {
    $('#createBranchModel').modal('hide');
    this.updatesForm.reset();
    this.isUpdate = false;
  }
  viewUpdateBranch(x) {
    this.updatesForm = this.fb.group({
      name: [x.paramName, Validators.required],
      paramValue: [x.paramValue, Validators.required],
    });
    this.selectrRecordId = x.id;
    this.isUpdate = true;
  }

  createConfigurations() {
    this.submitted = true;
    if (this.updatesForm.invalid) {
      return;
    }
    let payload: any = {
      name: this.updatesForm.value.name,
      paramValue: this.updatesForm.value.paramValue,
    };

    this.spinner.getSpinner();
    this.service.createConfigurations({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        $('#createBranchModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        this.updatesForm.reset();
        this.getConfigurations()
      } else {
        this.toastr.error(successResponse.records.message)
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  updateConfigurations() {
    this.submitted = true;

    if (this.updatesForm.invalid) {
      return;
    }
    let payload: any = {
      recordId: this.selectrRecordId,
      name: this.updatesForm.value.name,
      paramValue: this.updatesForm.value.paramValue,
    };

    this.spinner.getSpinner();
    this.service.updateConfigurations({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        $('#createBranchModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        this.updatesForm.reset();
        this.isUpdate = false;
        this.getConfigurations()
      } else {
        this.toastr.error(successResponse.records.message)
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  passCreateView() {
    this.router.navigate(["dashboard/view/createBranch"])
  }
  passDistriputorListView(x) {
    let distributorId = x.referenceId;
    this.router.navigate(["dashboard/view/getDistributorAccountList"], { queryParams: { distributorId } })
  }
  passUpdateView(x) {
    let distributorId = x.referenceId;
    this.router.navigate(["dashboard/view/updateDistributorInfo"], { queryParams: { distributorId } })
  }


}
