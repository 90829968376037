import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-settlement-request-table',
  templateUrl: './settlement-request-table.component.html',
  styleUrls: ['./settlement-request-table.component.css'],
  animations: [
    fade
  ]
})
export class SettlementRequestTableComponent implements OnInit {

  serviceForm: FormGroup;
  processForm: FormGroup;
  sub: Subscription;
  queryDistributorId: number;
  distributorId: any;
  queryMerchantId: number;
  MerchantId: any;
  submitted = false;
  merchantId;
  dataList;
  listBody = false;
  message;
  isTrue: boolean = false;
  settlementRequestId;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.procesForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryMerchantId = +params['merchantId'];
        this.queryDistributorId = +params['distributorId'];
        if (Number.isNaN(this.queryMerchantId) && Number.isNaN(this.queryDistributorId)) {
          this.MerchantId = "";
          this.distributorId = "";
        } else {
          this.MerchantId = this.queryMerchantId;
          this.distributorId = this.queryDistributorId;
          if (this.queryMerchantId) {
            this.getSettlementByMerchant(this.MerchantId)
          } else {
            this.getSettlementByDistributor(this.distributorId)
          }
        }
      });

  }

  createForm() {
    this.serviceForm = this.fb.group({
      settlementRequestId: ['', Validators.required],
    });
  }
  procesForm() {
    this.processForm = this.fb.group({
      settlementRequestId: ['', Validators.required],
      remarks: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }


  getSettlementByMerchant(merchantId) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId

    };
    this.isTrue = true;
    this.spinner.getSpinner();
    this.service.getSettlementRequestsByMerchant({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      console.log("haaa", successResponse)
      if (successResponse.success) {
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.listBody = true;
        var records = JSON.parse(data);
        this.dataList = records;
        this.listBody = true;
        console.log("kkkk", successResponse)
      } else {
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.listBody = false;
        this.message = data.message;
        console.log("kkkk", data.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  getSettlementByDistributor(distributorId?) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      distributorId: distributorId

    };
    this.isTrue = true;
    this.spinner.hideSpinner();
    this.service.getSettlementRequestsByDistributor({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa1111111", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.dataList = records;
          this.listBody = true;
          // console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  passSettlementReqInfo(x) {
    let settlementRequestId = x.settlementRequestId;
    this.router.navigate(["dashboard/view/getSettlementRequestsInfo"], { queryParams: { settlementRequestId } });
  }

  passProcessSettlementInfo(x) {
    this.serviceForm = this.fb.group({
      settlementRequestId: [x.settlementRequestId, Validators.required],
    });
  }

  OnProcessSettlement() {
    this.submitted = true;
    if (this.serviceForm.invalid) return;

    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      settlementRequestId: this.serviceForm.value.settlementRequestId,
      doneByUserId: currentUser.userId,
    };
    this.spinner.getSpinner();
    this.service.adminProcessSettlementRequest(payload).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      if (data.settlementId) {
        this.spinner.hideSpinner();
        this.toastr.success(`You processed a settlement ${data.settlementId}`, 'Successfully!');
        $('#createModel').modal('hide');
        // this.getSettlementByDistributor(this.queryDistributorId);
        this.getSettlementByMerchant(this.queryMerchantId);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error('Something went wrong', 'Error');
      }
    }, (failureResponse: any) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
      this.toastr.warning(failureResponse.error.message, 'Warning');
      // this.toastr.error(`${failureResponse.error.message.error.code} Bad request issue`, 'Error');
    });

  }
  passRejectSettlementInfo(x) {
    this.settlementRequestId = x.settlementRequestId;
    // this.processForm = this.fb.group({
    //   settlementRequestId: [x.settlementRequestId, Validators.required],
    // });
  }
  RejectSettlementRequest() {
    this.submitted = true;
    if (this.processForm.invalid) return;

    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      settlementRequestId: this.settlementRequestId,
      remarks: this.processForm.value.remarks
    };
    this.spinner.getSpinner();
    this.service.rejectSettlementRequest(payload).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      if (data) {
        this.toastr.success(`You have rejected the settlement request id ${data.settlementRequestId}`, 'Seccessfully!');
        this.spinner.hideSpinner();
        // console.log("DDDDD=>", data)
        $('#processModel').modal('hide');
        // this.getSettlementByDistributor(this.queryDistributorId);
        this.getSettlementByMerchant(this.queryMerchantId);
      } else {
        this.spinner.hideSpinner();
        this.toastr.warning("this request something went wrong", data.error);
      }
      // console.log("uuuu", data)
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        this.toastr.warning(failureResponse.error.message.message, 'Warning');
        // console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

}
