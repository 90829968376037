import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
// import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-list-cashier-info',
  templateUrl: './list-cashier-info.component.html',
  styleUrls: ['./list-cashier-info.component.css'],
  animations: [
    fade
  ]
})
export class ListCashierInfoComponent implements OnInit {

  _USER_TYPE_ID_CASHIER = 40;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  merchantUid;
  merchantName;
  cashierId;
  queryCashierId;
  event;
  cashierIdentifier;
  currencyId;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    // private spinner: NgxSpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.queryCashierId = params['cashierId'];
        this.cashierId = this.queryCashierId;
        this.getCashierInfo(this.cashierId);
      } else {
        _location.back();
      }
    });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      userId: ['', Validators.required],
      cashierIdentifier: ['', Validators.required],
      profileStatusDesc: ['', Validators.required],
      accessKey: ['', Validators.required]
    });
  }


  backToCashier() {
    this._location.back();
  }


  ngOnInit(): void {
    this.event = 'Edit'
    this.serviceForm.disable()
  }

  onButtonClick(event){
    if(event == 'Edit'){
      this.event = 'Disabled'
      this.serviceForm.enable()
    }else{
      this.event = 'Edit'
      this.serviceForm.disable()
    }
  }

  getCashierInfo(cashierId) {
    let payload: any = {
      cashierId: cashierId

    };
    this.spinner.getSpinner();
    this.service.getCashierInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.cashierId = data.cashierId;
        this.cashierIdentifier = data.cashierIdentifier;
        this.currencyId = data.currencyId;
        // this.merchantUid = data.merchantUid;
        // this.merchantName = data.firstName + " " + data.middleName + " " + data.lastName;
        this.serviceForm = this.fb.group({
          firstName: [data.firstName, Validators.required],
          middleName: [data.middleName, Validators.required],
          lastName: [data.lastName, Validators.required],
          email: [data.email, [Validators.required, Validators.email]],
          telephone: [data.telephone, Validators.required],
          address: [data.address, Validators.required],
          userTitle: [data.userTitle, Validators.required],
          address2: [data.address2, Validators.required],
          website: [data.website, Validators.required],
          city: [data.city, Validators.required],
          country: [data.country, Validators.required],
          userId: [data.userId, Validators.required],
          cashierIdentifier: [data.cashierIdentifier],
          profileStatusDesc: [data.profileStatusDesc, Validators.required],
          accessKey: [data.accessKey, Validators.required]
        });
        // console.log("kkkk", data)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  OnCreateCashierUser() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      // doneByUserId: currentUser.userId,
      cashierId: this.cashierId,
      userTitle: this.serviceForm.value.userTitle,
      firstName: this.serviceForm.value.firstName,
      middleName: this.serviceForm.value.middleName,
      lastName: this.serviceForm.value.lastName,
      email: this.serviceForm.value.email,
      telephone: this.serviceForm.value.telephone,
      address: this.serviceForm.value.address,
      address2: this.serviceForm.value.address2,
      city: this.serviceForm.value.city,
      country: this.serviceForm.value.country,
      website: this.serviceForm.value.website,
      // cashierIdentifier: this.cashierIdentifier,
    };

    console.log("PAYLOD=====> ", payload);

    this.service.updateCashierInfo({ payload: payload }).subscribe(sucessResponse => {
      this.toastr.success(`You have just updated! cashierId: ${sucessResponse.records.cashierId}`, "Seccessfully")
      setTimeout(() => {
        this._location.back();
      },1000)
      // console.log(sucessResponse)
    })

  }

}
