<div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">View User Role</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backtowebrole()">Back To User Role</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-subtitle">User Id</div>
                        <div style="background: #009e44; height: 40px; border-radius: 10px; margin-bottom: 10px;"></div>
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate  (ngSubmit)="assignUserInfo()">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">User Id</label>
                                        <input type="text" class="form-control" formControlName="userId" placeholder="User Id" readonly
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userId.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.userId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.userId.errors.required">User Id is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-subtitle">Web Roles</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-6">          
                                
                                    <!-- <table>
                                        <tr formArrayName="dataRecords" *ngFor="let item of dataRecords; let i = index">
                                            <td>
                                                <label>
                                                    <input type="checkbox" [formControlName]="i">
                                                    {{dataRecords[i].roleName}}
                                                </label>
                                            </td>
                                        </tr>
                                    </table> -->
                                    <div *ngFor="let item of dataRecords">
                                        <input type="checkbox"
                                        (change)="onChange($event, item.roleId)"
                                        [checked]="checked(item.roleId)"
                                      >
                                        {{item.description}}
                                      </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Update</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                            <!-- <button type="submit" class="btn btn-primary">Update</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>