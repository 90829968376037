import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-charge-back-summary-reports',
  templateUrl: './charge-back-summary-reports.component.html',
  styleUrls: ['./charge-back-summary-reports.component.css'],
  animations: [
    fade
  ]
})
export class ChargeBackSummaryReportsComponent implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedValue;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
  }

  getFrom(){
    this.getForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  passChargeBackSummaryReports(){
    this.submitted= true;

    if(this.getForm.invalid) return;

    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate;
    this.router.navigate(["dashboard/view/getChargebackSummaryReport"], { queryParams: { startDate, endDate }})

  }

}
