<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <h4 class="card-title">Create Settlement Merchant Account</h4>
                    </div>
                    <div class="col-md-3">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backToService()">Back to Settle Account</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" (ngSubmit)="OnProcessSettlementRequest()">
                            <div class="card-subtitle">Account Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="isActive">Account Id</label>
                                        <input type="text" class="form-control" [(ngModel)]="accountId" formControlName="accountId"
                                            placeholder="Account Id"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.accountId.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.accountId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.accountId.errors.required">Account Id
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Account Status</label>
                                        <input type="text" class="form-control" formControlName="statusDesc" [(ngModel)]="statusDesc"
                                            placeholder="Account Status"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.statusDesc.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.statusDesc.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.statusDesc.errors.required">Account Status
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Settlement Amount</label>
                                        <input type="text" class="form-control" formControlName="settlementAmount"
                                            placeholder="Settlement Account" [(ngModel)]="settlementAmount"
                                            [ngClass]=" { 'is-invalid' : submitted && serviceForm.controls.settlementAmount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.settlementAmount.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.settlementAmount.errors.required">
                                                Settlement Amount is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Description</label>
                                        <input type="text" class="form-control" formControlName="description"
                                            placeholder="Settlement Mode" [(ngModel)]="description"
                                            [ngClass]=" { 'is-invalid' : submitted && serviceForm.controls.description.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.description.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.description.errors.required">
                                                Description is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary mr-2">Process</button>
                            <!-- <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>