import { Component, OnInit } from '@angular/core';
import {Location, DatePipe  } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-transaction-view',
  templateUrl: './transaction-view.component.html',
  styleUrls: ['./transaction-view.component.css'],
  providers: [DatePipe],
  animations: [
    fade
  ]
})
export class TransactionViewComponent implements OnInit {

  serviceForm: FormGroup;
  sub: any;
  queryTranId: any;
  tranId;
  submitted = false;
  listBody = false;
  message;

  constructor(
    private datePipe: DatePipe,
    private _location: Location,
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.queryTranId = +params['tranId'];
        if (Number.isNaN(this.queryTranId)) {
          this.tranId = "";
        } else {
          this.tranId = this.queryTranId;
          this.getTransactionInfo(this.queryTranId);
        }
        //this.fnListTransactionHistoryWithtranId(this.queryMerchantId);
      });
   }


   createForm() {
    this.serviceForm = this.fb.group({
      transactionId: ['', Validators.required],
      amount: ['', Validators.required],
      cashierId: ['', Validators.required],
      payerBankId: ['', Validators.required],
      statusId: ['', [Validators.required, Validators.email]],
      accountId: ['', Validators.required],
      createdDate: ['', Validators.required],
      currency: ['', Validators.required],
      description: ['', Validators.required],
      payerIdType: ['', Validators.required],
      paymentChannel: ['', Validators.required],
      invoiceId: ['', Validators.required],
      issuerTransactionId: ['', Validators.required],
      posDeviceId: ['', Validators.required],
      payerId: ['', Validators.required],
      merchantId: ['', Validators.required],

    });
  }

  ngOnInit(): void {
  }

  backToService(){
    this._location.back();
  }

  getTransactionInfo(tranId){
    // console.log("Id ", info.tranId);
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      transactionId: tranId,
      userId: currentUser.userId,
    };
    this.spinner.getSpinner();
    this.service.getTransactionInfo({payload: payload}).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      // this.message = successResponse.records.message;
      // console.log("yes ",successResponse)
      if (successResponse.success) {
        this.spinner.hideSpinner();
        // console.log("yes ",data)
        this.listBody = true;
        this.serviceForm = this.fb.group({
          transactionId: [data.transactionId, Validators.required],
          amount: [data.amount, Validators.required],
          cashierId: [data.cashierId, Validators.required],
          payerBankId: [data.payerBankId, Validators.required],
          statusId: [data.statusId, [Validators.required, Validators.email]],
          accountId: [data.accountId, Validators.required],
          createdDate: [this.datePipe.transform(data.createdDate, 'MMM dd, yyyy : hh:mm:ss'), Validators.required],
          currency: [data.currency, Validators.required],
          description: [data.description, Validators.required],
          payerIdType: [data.payerIdType, Validators.required],
          paymentChannel: [data.paymentChannel, Validators.required],
          invoiceId: [data.invoiceId, Validators.required],
          issuerTransactionId: [data.issuerTransactionId, Validators.required],
          posDeviceId: [data.posDeviceId, Validators.required],
          payerId: [data.payerId, Validators.required],
          merchantId: [data.merchantId, Validators.required],
    
        });
      }else {
        this.listBody = false;
        this.message = data.message;
        this.spinner.hideSpinner();
      }
            
    },(failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

}
