import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-settlement-account-info',
  templateUrl: './settlement-account-info.component.html',
  styleUrls: ['./settlement-account-info.component.css'],
  animations: [
    fade
  ]
})
export class SettlementAccountInfoComponent implements OnInit {

  serviceForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  querypaymentProcessorId: number;
  paymentProcessorId: any;
  dataRecords: any;
  sub: Subscription;
  selectProductOwnerData;
  selectProductTypeData;
  queryaccountId: number;
  accountId: any;
  selectedCycleId;
  selectSettlementModeData;
  selectedSettlementModeValue;
  check: boolean = false;
  settlementAccountId;
  selectBankData;
  selectedBankValue;
  selectAccountTypesData;
  selectedAccountTypeValue;
  distributorId;
  data;
  accountID;
  currencyId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.fnGetSettlementModeList();
    this.fnGetBankList();
    this.fnGetSettlementAccountTypes();
    this.fnGetSettlementModeList();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.settlementAccountId = +params['settlementAccountId'];
        this.accountId = +params['accountId'];
        this.currencyId = +params['currencyId'];
        if (Number.isNaN(this.settlementAccountId)) {
          this.settlementAccountId = "";
        } else {
          this.fnViewMerchantSettlementInfo(this.settlementAccountId, this.accountId, this.currencyId)
        }
      });

  }

  createForm() {
    this.serviceForm = this.fb.group({
      selectedBankValue: ['', Validators.required],
      settlementAccount: ['', Validators.required],
      selectedSettlementModeValue: ['', Validators.required],
      selectedAccountTypeValue: ['', Validators.required],
      settlementCycleId: ['', Validators.required],
    });
  }

  fnViewMerchantSettlementInfo(settlementAccountId, accountId, currencyId) {
    this.check = false;
    console.log('SettlId', settlementAccountId)
    this.accountID = accountId;
    this.currencyId = currencyId;
    this.settlementAccountId = settlementAccountId;
    let expressRoute = "/getSettlementAccountInfo";
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      settlementAccountId: settlementAccountId,
    };
    if (settlementAccountId == 0) {
      this.serviceForm = this.fb.group({
        settlementAccount: ['', Validators.required],
        selectedSettlementModeValue: ['', Validators.required],
        settlementCycleId: [1, Validators.required],
        selectedBankValue: ['', Validators.required],
        selectedAccountTypeValue: ['', Validators.required],
      });
    }
    this.spinner.getSpinner()

    this.service.getSettlementAccountInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      console.log('haaaah', successResponse)
      this.check = successResponse.success;
      this.distributorId = data.merchantId;
      this.spinner.hideSpinner();
      this.serviceForm = this.fb.group({
        selectedBankValue: [data.issuerId, Validators.required],
        settlementAccount: [data.settlementAccountNo, Validators.required],
        selectedSettlementModeValue: [data.settlementModeId, Validators.required],
        selectedAccountTypeValue: [data.settlementAccountTypeId, Validators.required],
        settlementCycleId: [data.settlementCycleId, Validators.required],
      });
      // this.serviceForm = this.fb.group({
      //   // merchantId: [{ value: merchant.merchantId, disabled: true }, Validators.required],
      //   settlementAccount: [{ value: data.settlementAccountNo, disabled: true }, Validators.required],
      //   selectedSettlementModeValue: [{ value: '', disabled: true }, Validators.required],
      //   settlementCycleId: [{ value: data.settlementCycleId, disabled: true }, Validators.required],
      //   selectedBankValue: [{ value: '', disabled: true }, Validators.required],
      //   selectedAccountTypeValue: [{ value: data.accountTypeId, disabled: true }, Validators.required],
      // });
      this.data = data;

    }, (failureResponse: string) => {
      // this.toastr.error("Server seems got bad request!");
      //console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onChanges() {
    if (!this.check) {
      this.addSettlementAccountInfo();
      // console.log('yes',this.data)
    } else {
      this.onUpdateMerchantSettlementInfo(this.data);
      // console.log('no', this.data)
    }
  }

  backToService() {
    this._location.back();
  }

  ngOnInit(): void {
  }

  fnGetSettlementModeList(merchantSettlmentId?) {
    let payload: any = {};
    this.service.getSettlementModes({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      console.log("data :=> " + JSON.stringify(data));
      var records = JSON.parse(data);
      var selectedRecord: any = {};
      if (merchantSettlmentId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.settlementModeId == merchantSettlmentId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectSettlementModeData = records;
        this.selectedSettlementModeValue = selectedRecord.merchantSettlmentId;
      } else {
        this.selectSettlementModeData = records;
        this.selectedSettlementModeValue = "";
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  fnGetBankList(bankId?) {
    let expressRoute = "/getBanksList";
    let payload: any = {
    };
    this.service.getBankList({ payload: payload }).subscribe(successResponse => {
      var data = JSON.parse(successResponse.records);
      console.log("data :=> ", JSON.parse(successResponse.records));

      var records = data;
      var selectedRecord: any = {};
      if (bankId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.bankId == bankId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectBankData = records;
        this.selectedBankValue = selectedRecord.bankId;
      } else {
        this.selectBankData = records;
        this.selectedBankValue = "";
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  fnGetSettlementAccountTypes(accountTypeId?) {
    let expressRoute = '/getSettlementAccountTypes';
    let payload: any = {
    };
    this.service.getSettlementAccountTypes({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      // console.log("data :=> " + responseObj);
      var records = JSON.parse(data);
      var selectedRecord: any = {};
      if (accountTypeId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.settlementAccountTypeId == accountTypeId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectAccountTypesData = records;
        this.selectedAccountTypeValue = selectedRecord.settlementAccountTypeId;
      } else {
        this.selectAccountTypesData = records;
        this.selectedAccountTypeValue = "";
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  addSettlementAccountInfo() {
    // console.log('kkkkkk',this.data)
    this.submitted = true;
    // stop here if form is invalid
    if (this.serviceForm.invalid) {
      return this.toastr.warning('Please fill in this form');
    }
    // let expressRoute = "/addSettlementAccountInfo";
    let payload: any = {
      // settlementAccountId: settlementAccount.settlementAccountId,
      settlementAccountNo: this.serviceForm.value.settlementAccount,
      accountId: this.accountID,
      currencyId: this.currencyId,
      issuerId: this.serviceForm.value.selectedBankValue,
      settlementModeId: this.serviceForm.value.selectedSettlementModeValue,
      settlementCycleId: this.serviceForm.value.settlementCycleId,
      settlementAccountTypeId: this.serviceForm.value.selectedAccountTypeValue,
    };
    // console.log('aadPayload', payload);
    this.spinner.getSpinner();
    this.service.addSettlementAccountInfo(payload).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      // console.log('OnAdd Method:', data);
      this.toastr.success('Successfully added');
      this.serviceForm.reset(this.serviceForm.value);
      // this.fnListMerchantUserInfo(this.addMerchantSettlementForm.value.settlementAccount);
      this._location.back();
    }, (failureResponse: string) => {
      this.toastr.error("Server seems got bad request!");
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onUpdateMerchantSettlementInfo(settlementAccount) {
    // console.log('jjjj', settlementAccount)
    this.submitted = true;
    // stop here if form is invalid
    if (this.serviceForm.invalid) {
      return this.toastr.warning('Please fill in this form');
    }
    let expressRoute = "/updateSettlementAccountInfo";
    let payload: any = {
      settlementAccountId: settlementAccount.settlementAccountId,
      settlementAccountNo: this.serviceForm.value.settlementAccount,
      currencyId: settlementAccount.currencyId,
      issuerId: this.serviceForm.value.selectedBankValue,
      settlementModeId: this.serviceForm.value.selectedSettlementModeValue,
      settlementCycleId: this.serviceForm.value.settlementCycleId,
      settlementAccountTypeId: this.serviceForm.value.selectedAccountTypeValue,
    };
    // console.log('HAAHEEYY', payload)
    // console.log('UpdatePayload', payload)
    this.spinner.getSpinner();
    this.service.updateSettlementAccountInfo(payload).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      // console.log('OnUpdate Method:', data);
      this.spinner.hideSpinner();
      this.toastr.success('Successfully updated');
      this.serviceForm.reset(this.serviceForm.value);
      this._location.back();
      // console.log('GGGGG => ', this.addMerchantSettlementForm.value.settlementAccount)
      // this.fnListMerchantUserInfo(this.addMerchantSettlementForm.value.settlementAccount);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000)
    }, (failureResponse: string) => {
      this.toastr.error("Server seems got bad request!");
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }





}
