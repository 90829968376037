<div @fade class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Create Settlement Merchant Account</h4>
                <p class="card-description">
                    Merchant Account
                </p>
                <form class="forms-sample" [formGroup]="getForm" novalidate (ngSubmit)="passSettlementDirectView()">

                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <select class="form-control-sm" formControlName="selectedData" [(ngModel)]="selected"
                                (change)="selectInput($event)"
                                [ngClass]="{ 'is-invalid': submitted && getForm.controls.selectedData.errors }">
                                    <option  [ngValue]="0">Merchant Id</option>
                                    <option  [ngValue]="1">Distributor Id</option>
                                </select>
                                <div *ngIf="submitted && getForm.controls.selectedData.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.selectedData.errors.required">Select Account is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="isSelected">
                            <div class="form-group">
                                <input type="text" class="form-control-sm" formControlName="Ids"
                                    placeholder="Id`s" [(ngModel)]="merchantId"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.Ids.errors }">
                                <div *ngIf="submitted && getForm.controls.Ids.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.Ids.errors?.required">
                                        Id`s is required</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-3" *ngIf="isSelected1">
                            <div class="form-group">
                                <input type="text" class="form-control" formControlName="distributorId"
                                    placeholder="Distributor Id" [(ngModel)]="distributorId"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.distributorId.errors }">
                                <div *ngIf="submitted && getForm.controls.distributorId.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.distributorId.errors?.required">
                                        Distributor Id is required</div>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-md-3">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>