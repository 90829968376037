import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
declare var $: any;
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-distributor-accounts',
  templateUrl: './distributor-accounts.component.html',
  styleUrls: ['./distributor-accounts.component.css'],
  animations: [
    fade
  ]
})
export class DistributorAccountsComponent implements OnInit {

  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  submitted = false;
  modal;

  queryDistributorId: number;
  distributorId: any;
  dataRecords: any;
  sub: Subscription;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  accountId;
  message;
  merchantDefAccount;
  distInfo;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private _sessionStorageService: SessionStorageService
  ) {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryDistributorId = +params['distributorId'];
        if (Number.isNaN(this.queryDistributorId)) {
          this.distributorId = "";
        } else {
          this.distributorId = this.queryDistributorId;
          this.getDistributorAccountList(this.distributorId);
          this.fnGetDistributorDefaultAccount(this.distributorId);
          this.fnGetDistributorInfo(this.distributorId);
        }
      });
  }


  ngOnInit(): void {
    
  }

  fnGetDistributorInfo(distributorId) {
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      distributorId: distributorId
    }
    this.spinner.getSpinner();
    this.service.getDistributorInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        // var records = JSON.parse(data);
        this.distInfo = data;
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getDistributorAccountList(distributorId) {
    let payload: any = {
      distributorId: distributorId
    }
    this.spinner.getSpinner();
    this.service.getDistributorsAccountList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  fnGetDistributorDefaultAccount(distributorId) {
    let payload: any = {
      distributorId: distributorId

    };
    this.spinner.getSpinner();
    this.service.getDistributorDefaultAccount({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (!successResponse.records.message) {
        this.spinner.hideSpinner();
      }
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var records = data;
        this.accountId = records.accountId;
      } else {
        this.spinner.hideSpinner();
        this.message = data.message;
        this.spinner.hideSpinner();
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  updateDistributorDefAccountIds(data: any) {
    this.merchantDefAccount = false;

    let payload = {
      accountId: data.accountId,
      distributorId: this.distributorId
    };
    // console.log("HHHH", data)
    this._sessionStorageService.setAccountId(data.accountId);
    this.accountId = payload.accountId;

    this.spinner.getSpinner();

    this.service.updateDistributorDefAccountId({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      if (data != null) {
        this.spinner.hideSpinner();
        this.getDistributorAccountList(this.distributorId)
        this.toastr.success('Successfully changed your account!', 'Success');
      } else {
        // this.listBody = false;
        this.merchantDefAccount = false;
        this.toastr.warning('No Records Found', 'Warnig');
        this.spinner.hideSpinner();
      };
    }, (failureResponse: string) => {
      this.toastr.error("Server seems got bad request!");
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });

    // console.log('AccountID',  payload)

  }


  passCreateView() {
    let userId = this.distInfo.userId;
    let createdByUserId = this.distInfo.createdByUserId;
    this.router.navigate(["dashboard/view/createDistributorAccount"], { queryParams: { userId, createdByUserId } });
  }

}
