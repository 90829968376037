import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-settlement-history',
  templateUrl: './settlement-history.component.html',
  styleUrls: ['./settlement-history.component.css'],
  animations: [
    fade
  ]
})
export class SettlementHistoryComponent implements OnInit {
  getSubmitted = false;
  getForm: FormGroup;
  dataList;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getSettlementStatusLists();

  }

  getFrom(){
    this.getForm = this.fb.group({
      selectData: ['', Validators.required],
    });
  }


  ngOnInit(): void {
  }



  getSettlementStatusLists(){
    let userTypeId = 0;
    let payload: any = {

    };

    this.service.getSettlementStatusList({payload: payload}).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }



  passSettlementTableView(){
    this.getSubmitted = true;
    if(this.getForm.invalid){
      return;
    }
    let statusId = this.getForm.value.selectData
    this.router.navigate(["dashboard/view/listSettlementList"], { queryParams: { statusId } });
  }

}
