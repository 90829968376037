import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-payment-tran-summary-reports',
  templateUrl: './payment-tran-summary-reports.component.html',
  styleUrls: ['./payment-tran-summary-reports.component.css'],
  animations: [
    fade
  ]
})
export class PaymentTranSummaryReportsComponent implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedValue;
  data;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.fnGetPaymentProcessorList();

  }

  getFrom(){
    this.getForm = this.fb.group({
      merchantId: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      startTranId: [''],
      endTranId: [''],
      selectedValue: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  onChanges(deviceValue) {
    this.data = this.selectData[deviceValue.target.selectedIndex];
    // console.log(deviceValue.target.selectedIndex);
  }

  fnGetPaymentProcessorList(sourceId?) {
    let payload: any = {};
    // this.getSpinner();
    this.service.getPaymentProcessorList({payload: payload}).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // this.spinner.hide();
      // console.log("data :=> " + successResponse);

      var newrecord: any = {};
      newrecord.tranStatusId = "0";
      newrecord.tranStatusDesc = "All";
      records.push(newrecord);
      var selectedRecord: any = {};
      if (sourceId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.tranStatusId == sourceId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectData = records;
        this.selectedValue = selectedRecord.tranStatusId;
      } else {
        this.selectData = records;
        this.selectedValue = newrecord.tranStatusId;
      };
    },(failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };

  passPaymentProcessorReports(){
    this.submitted = true;
    if(this.getForm.invalid){
      return;
    }
    
    let merchantId = this.getForm.value.merchantId;
    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate;
    let startTranId = this.getForm.value.startTranId;
    let endTranId = this.getForm.value.endTranId;
    let accountId = this.data.accountId;
    let userId = this.data.userId;
    this.router.navigate(["dashboard/view/listTransummary"], { queryParams: { merchantId, startDate, endDate, startTranId, endTranId, accountId, userId }});
  }

}
