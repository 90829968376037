import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-pos-transaction',
  templateUrl: './pos-transaction.component.html',
  styleUrls: ['./pos-transaction.component.css'],
  animations: [
    fade
  ]
})
export class PosTransactionComponent implements OnInit {
  submitted = false;
  getForm: FormGroup;
  getDistrForm: FormGroup;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  getSubmitted = false;
  selectData;
  tranStatusId;
  distributorList;
  showDistributor = false;
  selectedDistributorId;
  dataList;
  distributorId;
  latesTransections;
  listBody: boolean = false;
  selected = 100;
  p: number = pagination.p;
  term;
  settlementList
  rrn;
  cashierId;
  isAllowedRetrySettlement;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getDistrFrom();
    // this.fnGetStatusList();
    // this.getLastPosTransections();
    this.getUserLists()
    const per = this._sessionStorageService.checkPermission('retry_settlement');
    this.isAllowedRetrySettlement = per
  }
  getDistrFrom() {
    this.getDistrForm = this.fb.group({
      selectData: ['', Validators.required],
    });
  }
  getFrom() {
    this.getForm = this.fb.group({
      cashierId: ['', Validators.required],
      endDate: ['', Validators.required],
      startDate: ['', Validators.required],
    });
  }
  handleSubmit() {
    this.submitted = true;
    let cashierId = this.getForm.value.cashierId;
    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate;

    // if (this.getForm.invalid) {
    //   return;
    // }

    // this.getMerchantPayments();
    this.getLastPosTransections(this.selectedDistributorId)
  }

  ngOnInit(): void {
    console.log("hello")
  }
  backToService() {
    this.showDistributor = false;
    this.dataList = [];
    this.selectedDistributorId = 0;
  }
  clearForm() {
    this.dataList = this.latesTransections;
    this.getForm.reset();
  }
  fnGetStatusList(sourceId?) {
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getTranStatusList({ payload: payload }).subscribe(successResponse => {
      let responseObj = JSON.stringify(successResponse.records);
      // let data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      var records = JSON.parse(JSON.parse(responseObj));
      // console.log("data :=> ", records);

      var newrecord: any = {};
      newrecord.tranStatusId = "0";
      newrecord.tranStatusDesc = "All";
      records.push(newrecord);
      this.selectData = records;


    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };
  getUserLists() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distributorList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  passTransaction(distributorId) {
    this.getSubmitted = true;
    if (this.getForm.invalid) {
      return;
    }
    let tranStatusId = this.getForm.value.selectedData;
    let merchantId = this.getForm.value.merchantId;
    this.router.navigate(["dashboard/view/getPosTransactionInfo"], { queryParams: { merchantId, tranStatusId } })
  }

  getLastPosTransections(distributorId) {
    let payload: any = {
      distributorId: distributorId,
      pCashierId: this.getForm.value.cashierId == undefined ? "" : this.getForm.value.cashierId,
      startDate: this.getForm.value.startDate == undefined ? "" : this.getForm.value.startDate,
      endDate: this.getForm.value.endDate == undefined ? "" : this.getForm.value.endDate,
      merchantId: '',
      pState: '',
      pIssettlementprocessedId: ''
    };

    this.spinner.getSpinner();
    this.service.getMerchantPayments({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.showDistributor = true;
        records?.forEach((el: any) => {
          el.transactionAmount = `${el.currencySymbol}${el.transactionAmount == undefined ? 0 : el.transactionAmount.toFixed(2)}`
          el.transactionFeeAmount = `${el.currencySymbol}${el.transactionFeeAmount == undefined ? 0 : el.transactionFeeAmount.toFixed(2)}`
          el.cashierName = `${el.cashierName} (${el.cashierId})`
          el.merchantName = `${el.merchantName} (${el.merchantId})`
          el.currentSettlementId = el.settlementId
          if (el.settlementId != null && el.settlementId != undefined) {
            el.settlementId = `${el.settlementId} (${el.isSettlementProcessed == 0 ? 'Pending' : 'Completed'})`
          } else {
            el.settlementId = "N/A"
          }
          if (el.traceNumber == null || el.traceNumber == undefined || el.traceNumber == 0) {
            el.traceNumber = "N/A"
          }

          if (el.resState == "APPROVED") {
            el.status = "badge badge-success"
          } else {
            // el.resState = `${el.settlementId} (${el.isSettlementProcessed })`
            el.status = "badge badge-danger"
          }
        })
        this.dataList = records;
        this.latesTransections = records;
      } else {
        this.showDistributor = true;
        this.dataList = []

        this.spinner.hideSpinner();
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getSettlementById(row) {
    if (row.currentSettlementId == null || undefined) {
      return this.toastr.warning("Settment Id Not Found")
    }
    let payload: any = {
      settlementId: row.currentSettlementId,
    };

    this.cashierId = row.cashierId;
    this.rrn = row.retrievalRefNumber

    this.spinner.getSpinner();
    this.service.getSettlementById({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.settlementList = records;
        $('#settlementModel').modal('show');
      } else {
        this.settlementList = []
        $('#settlementModel').modal('show');
        this.spinner.hideSpinner();
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  retrySettlement(row) {

    let payload: any = {
      cashierId: this.cashierId,
      rrn: this.rrn,
      resolveType: 'credit',
    };

    this.spinner.getSpinner();
    this.service.retrySettlement({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      if (successResponse.success) {
        this.spinner.hideSpinner();
        // var responseObj = JSON.stringify(successResponse.records);
        // var data = JSON.parse(responseObj);
        // var records = JSON.parse(data);
        $('#settlementModel').modal('hide');
        this.toastr.success(successResponse.records.message)

      } else {

        this.spinner.hideSpinner();
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  handleSubmitDistr() {
    let distributorId = this.getDistrForm.value.selectData
    this.getLastPosTransections(distributorId);
    this.selectedDistributorId = distributorId


  }
}
