<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ Title }}</h4>
          </div>
          <div class="col-md-2" *ngIf="showMerchantForm === 'false'">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>

          <form
            [formGroup]="getForm"
            class="forms-sample ml-3"
            novalidate
            *ngIf="showMerchantForm === 'true'"
            (ngSubmit)="handleSubmit()"
          >
            <div class="row">
              <div class="col-md">
                <div class="form-group">
                  <label for="selectDistributor">Select Distributor</label>
                  <select
                    class="form-control-sm"
                    name="selectDistributor"
                    formControlName="selectDistributor"
                    [ngClass]="{
                      'is-invalid':
                        getSubmitted &&
                        getForm.controls.selectDistributor.errors
                    }"
                  >
                    <option [selected]="true" value="null" disabled selected>
                      --Select--
                    </option>
                    <option
                      *ngFor="let c of distdataList"
                      [ngValue]="c.referenceId"
                    >
                      {{ c.userTitle }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted && getForm.controls.selectDistributor.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        getForm.controls.selectDistributor.errors?.required
                      "
                    >
                      Select Distributor is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="form-group">
                  <label for="selectedValue">Select Day</label>
                  <select
                    class="form-control-sm"
                    formControlName="selectedValue"
                    [ngClass]="{
                      'is-invalid':
                        submitted && getForm.controls.selectedValue.errors
                    }"
                  >
                    <option value="null">Select a Day</option>
                    <option *ngFor="let c of numberOfdays" [ngValue]="c.id">
                      {{ c.name }}
                    </option>
                  </select>
                  <div
                    *ngIf="submitted && getForm.controls.selectedValue.errors"
                    class="text-danger"
                  >
                    <div
                      *ngIf="getForm.controls.selectedValue.errors?.required"
                    >
                      please Select Day
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-2 mt-2">
                <div class="form-group mt-4">
                  <button type="submit" class="btn btn-primary">Search</button>
                </div>
              </div>
            </div>
          </form>

          <div
            class="row"
            style="margin-bottom: 0px"
            *ngIf="showMerchantForm === 'false'"
          >
            <div class="col-md">
              <div class="form-group">
                <label for="startDate" className="text-white">Search</label>
                <input
                  type="text"
                  class="form-control-sm mb-2"
                  [(ngModel)]="term"
                  placeholder="Search here..."
                  style="float: left"
                />
              </div>
            </div>
          </div>

          <div class="table-responsive" *ngIf="showMerchantForm === 'false'">
            <table
              class="table-striped display expandable-table"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th>InstitutionName</th>
                  <th>RegisteredDate</th>
                  <th>TotalRegistered</th>
                  <th>PhysicalActive</th>
                  <th>PhysicalPrinted</th>
                  <th>PhysicalRequested</th>
                  <th>TotalBlocked</th>
                  <th>TotalBlockedByAdmin</th>
                  <th>ContactlessEnabled</th>
                  <th>CurrentBalance</th>
                  <th>ShadowBalance</th>
                </tr>
              </thead>
              <tbody *ngIf="listBody">
                <tr
                  *ngFor="
                    let x of listData
                      | filter : term
                      | paginate : { itemsPerPage: selected, currentPage: p }
                  "
                >
                  <td class="py-1">{{ x.institutionName }}</td>
                  <td>{{ x.registeredDate }}</td>
                  <td>{{ x.totalRegistered }}</td>
                  <td>{{ x.physicalActive }}</td>
                  <td>{{ x.physicalPrinted }}</td>
                  <td>{{ x.physicalRequested }}</td>
                  <td>{{ x.totalBlocked }}</td>
                  <td>{{ x.totalBlockedByAdmin }}</td>
                  <td>{{ x.contactlessEnabled }}</td>
                  <td>{{ formatTransactionAmount(x.currentBalance) }}</td>
                  <td>{{ formatTransactionAmount(x.shadowBalance) }}</td>
                </tr>
              </tbody>
            </table>
            <div style="width: 100%; margin-top: 1%">
              <h4 class="card-title" style="float: left">
                <select class="form-control-sm" [(ngModel)]="selected">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="1000">1000</option>
                  <option value="2000">2000</option>
                  <option value="4000">4000</option>
                  <option value="6000">6000</option>
                  <option value="8000">8000</option>
                  <option value="10000">10000</option>
                </select>
              </h4>
              <pagination-controls
                style="float: right"
                (pageChange)="p = $event"
              ></pagination-controls>
            </div>
            <div *ngIf="!listBody" class="container">
              <div style="text-align: center; padding: 24px">
                {{ message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
