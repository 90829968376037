import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-create-settlement-request',
  templateUrl: './create-settlement-request.component.html',
  styleUrls: ['./create-settlement-request.component.css'],
  animations: [
    fade
  ]
})
export class CreateSettlementRequestComponent implements OnInit {

  serviceForm: FormGroup;
  sub: Subscription;
  queryDistributorId: number;
  distributorId: any;
  queryMerchantId: number;
  MerchantId: any;
  submitted = false;
  merchantId;

  statusDesc;
  accountId;
  balance;
  settlementAmount;
  settlementAccount;
  settlementModeDesc;
  settlementCycleId;
  bankName;
  description;
  currency;
  userId;
  
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryMerchantId = +params['merchantId'];
        this.queryDistributorId = +params['distributorId'];
        if (Number.isNaN(this.queryMerchantId) && Number.isNaN(this.queryDistributorId)) {
          this.MerchantId = "";
          this.distributorId = "";
        } else {
          this.MerchantId = this.queryMerchantId;
          this.distributorId = this.queryDistributorId;
          if(this.queryMerchantId){
            this.getSettlementMerchantAccountInfo(this.MerchantId)
          }else{
            this.getSettlementDistributorAccountInfo(this.distributorId)
          }
        }
      });

  }

  createForm() {
    this.serviceForm = this.fb.group({
      statusDesc: ['', Validators.required],
      settlementAmount: ['', Validators.required],
      description: ['', Validators.required],
      accountId: ['', Validators.required]
    });
  }

  backToService() {
    this._location.back();
  }

  ngOnInit(): void {
  }
  getSettlementMerchantAccountInfo(merchantId) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId
    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.merchantId = data.merchantId;
      // console.log("MEEE111=> ", data)
      this.statusDesc = data.profileStatusDesc;
      this.accountId = data.accountId;
      this.userId = data.userId;
    },(failureResponse: string) => {
      this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getSettlementDistributorAccountInfo(distributorId) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      distributorId: distributorId
    };
    this.spinner.getSpinner();
    this.service.getDistributorInfo({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.merchantId = data.merchantId;
      // console.log("MEEE222=> ", data)
      this.statusDesc = data.profileStatusDesc;
      this.accountId = data.accountId;
      this.userId = data.userId;
    },(failureResponse: string) => {
      this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  OnProcessSettlementRequest(){
    this.submitted = true;
    if(this.serviceForm.invalid){
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      userId: this.userId,
      accountId: this.accountId,
      amount: this.settlementAmount,
      description: this.description,
    };
    // console.log("Payload KKK", payload);
    this.spinner.getSpinner();
    this.service.createSettlementRequest(payload).subscribe(successResponse => {
      this.spinner.hideSpinner();
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      
      // console.log("MEEE222=> ", data)
    },(failureResponse: any) => {
      this.spinner.hideSpinner();
        // console.log("onFailure:" + JSON.stringify(failureResponse));
        this.toastr.error(`${failureResponse.error.message.error.code} Bad request issue`, 'Error');
    });
  }


}
