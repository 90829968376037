<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ Title }}</h4>
          </div>

          <div class="col-md-2" *ngIf="showForm === 'false'">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <form
          *ngIf="showForm === 'true'"
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="handleSubmit()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="selectDistributor">Select Distributor</label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="SelectedMonth">Select a Month</label>
                <select
                  class="form-control-sm"
                  formControlName="SelectedMonth"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.SelectedMonth.errors
                  }"
                >
                  <option *ngFor="let c of monthsList" [ngValue]="c.id">
                    {{ c.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.SelectedMonth.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.SelectedMonth.errors?.required">
                    please Select Month
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="SelectedTranStatus">Select a Tran Status</label>
                <select
                  class="form-control-sm"
                  formControlName="SelectedTranStatus"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.SelectedTranStatus.errors
                  }"
                >
                  <option *ngFor="let c of stateList" [ngValue]="c">
                    {{ c }}
                  </option>
                </select>
                <div
                  *ngIf="
                    submitted && getForm.controls.SelectedTranStatus.errors
                  "
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.SelectedTranStatus.errors?.required"
                  >
                    please Select Tran Status
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>

        <div
          class="row"
          style="margin-bottom: 0px"
          *ngIf="showForm === 'false'"
        >
          <div class="col-md-3">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control form-control mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
        </div>

        <div class="table-responsive" *ngIf="showForm === 'false'">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>TransactionDate</th>
                <th>CurrencyCode</th>
                <th>Amount</th>
                <th>Topups</th>
                <th>LoadAuth</th>
                <th>LoadAdjustment</th>
                <th>Withdrawals</th>
                <th>Authorizations</th>
                <th>AuthzReversals</th>
                <th>AuthzAdjustment</th>
                <th>MarkupAmount</th>
                <th>MarkupRate</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.transactionDate }}</td>
                <td>{{ x.currencyCode }}</td>
                <td>{{ formatTransactionAmount(x.transactionAmount) }}</td>
                <td>{{ x.topups }}</td>
                <td>{{ x.loadAuth }}</td>
                <td>{{ x.loadAdjustment }}</td>
                <td>{{ x.withdrawals }}</td>
                <td>{{ x.authorizations }}</td>
                <td>{{ x.authzReversals }}</td>
                <td>{{ x.authzAdjustment }}</td>

                <td>{{ x.markupAmount }}</td>
                <td>{{ x.markupRate }}</td>
              </tr>
              <tr style="background-color: #09df66be">
                <td>Total:</td>
                <td></td>
                <td>{{ sumOfAmount | number : "1.2-2" }}</td>
                <td>{{ sumOfTopups }}</td>
                <td>{{ sumloadAuth }}</td>
                <td>{{ sumloadAdjustment }}</td>
                <td>{{ sumWithdrawals | number : "1.2-2" }}</td>
                <td>{{ sumAuthorizations }}</td>
                <td>{{ sumauthzReversals }}</td>
                <td></td>
                <td>{{ sumMarkupAmount }}</td>
                <td>{{ sumMarkupRate }}</td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="3000">30000</option>
                <option value="5000">5000</option>
                <option value="7000">7000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
