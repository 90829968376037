<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10 ">
                        <h4 class="card-title">All Service Settings</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="passServiceCreation()">Create Service</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Service Id</th>
                                <th>Service Code</th>
                                <th>Service Description</th>
                                <th>Is Active</th>
                                <th>Is Transactional</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td class="py-1">{{x.serviceId}}</td>
                                <td>{{x.serviceCode}}</td>
                                <td>{{x.serviceDesc}}</td>
                                <td>{{x.isActive}}</td>
                                <td>{{x.isTransactional}}</td>
                                <td (click)="passServiceUpdated(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <!-- <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="createModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Create Service Settings</h4>
                            <form class="forms-sample" [formGroup]="serviceForm" novalidate  (ngSubmit)="OnCreateServiceSetting()">
                                <div class="card-subtitle">Service Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                                <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="exampleInputUsername1">Service Code</label>
                                                <input type="text" class="form-control" formControlName="serviceCode" placeholder="Service Codee"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceCode.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.serviceCode.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.serviceCode.errors.required">Service Code is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Service Description</label>
                                                <input type="text" class="form-control"  formControlName="serviceDesc" placeholder="service Description"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceDesc.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.serviceDesc.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.serviceDesc.errors.required">service Description    is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="isActive">Is Active</label>
                                            <select class="form-control" formControlName="isActive" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.isActive.errors }">
                                                <option [ngValue]="1" selected>Yes</option>
                                                <option [ngValue]="0">No</option>
                                            </select>   
                                            <div *ngIf="submitted && serviceForm.controls.isActive.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.isActive.errors.required">IsActive is required</div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="isActive">Is Transactional</label>
                                            <select class="form-control" formControlName="isTransactional" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.isTransactional.errors }">
                                                <option [ngValue]="1" selected>Yes</option>
                                                <option [ngValue]="0">No</option>
                                            </select>   
                                            <div *ngIf="submitted && serviceForm.controls.isTransactional.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.isTransactional.errors.required">IsTransactional is required</div>
                                            </div>
                                            </div>
                                        </div>
                                       
                                </div>
                                <div class="card-subtitle">Profile Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">Charge Mode Id</label>
                                            <select class="form-control" formControlName="serviceChargeModeId" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceChargeModeId.errors }">
                                                <option *ngFor="let p of selectServiceChargeModeData" [ngValue]="p.chargeModeId">{{p.chargeModeDesc}}</option>
                                            </select>
                                            <div *ngIf="submitted && serviceForm.controls.serviceChargeModeId.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.serviceChargeModeId.errors.required">Charge Mode Id is required</div>
                                            </div>
                                    </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Service Profile Description</label>
                                            <input type="text" class="form-control" formControlName="serviceProfileDesc" placeholder="Profile Description"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceProfileDesc.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.serviceProfileDesc.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.serviceProfileDesc.errors.required">Profile Description is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">MinInTxAmount</label>
                                            <input type="text" class="form-control"  formControlName="minInTxAmount" placeholder="MinInTxAmount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.minInTxAmount.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.minInTxAmount.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.minInTxAmount.errors.required">MinInTxAmount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">MinOutTxAmount</label>
                                            <input type="text" class="form-control"  formControlName="minOutTxAmount" placeholder="MinOutTxAmount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.minOutTxAmount.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.minOutTxAmount.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.minOutTxAmount.errors.required">MinInTxAmount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                        
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">DailyInTxLimit</label>
                                            <input type="text" class="form-control" formControlName="dailyInTxLimit" placeholder="DailyInTxLimit"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.dailyInTxLimit.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.dailyInTxLimit.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.dailyInTxLimit.errors.required">DailyInTxLimit is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">DailyOutTxLimit</label>
                                            <input type="text" class="form-control" formControlName="dailyOutTxLimit" placeholder="DailyOutTxLimit"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.dailyOutTxLimit.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.dailyOutTxLimit.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.dailyOutTxLimit.errors.required">DailyOutTxLimit is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">MonthlyInTxLimit</label>
                                            <input type="text" class="form-control"  formControlName="monthlyInTxLimit" placeholder="MonthlyInTxLimit"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.monthlyInTxLimit.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.monthlyInTxLimit.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.monthlyInTxLimit.errors.required">MonthlyInTxLimit is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">MonthlyOutTxLimit</label>
                                            <input type="text" class="form-control"  formControlName="monthlyOutTxLimit" placeholder="MonthlyOutTxLimit"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.monthlyOutTxLimit.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.monthlyOutTxLimit.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.monthlyOutTxLimit.errors.required">MinInTxAmount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                 </div>
                                 <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">maxInTxAmount</label>
                                            <input type="text" class="form-control"  formControlName="maxInTxAmount" placeholder="maxInTxAmount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.maxInTxAmount.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.maxInTxAmount.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.maxInTxAmount.errors.required">maxInTxAmount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">MaxOutTxAmount</label>
                                            <input type="text" class="form-control"  formControlName="maxOutTxAmount" placeholder="MaxOutTxAmount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.maxOutTxAmount.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.maxOutTxAmount.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.maxOutTxAmount.errors.required">MaxInTxAmount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="modal fade bd-example-modal-md" id="viewModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">View Service Settings</h4>
                            <form class="forms-sample" [formGroup]="serviceForm" novalidate  (ngSubmit)="updateServiceSetting()">
                                <div class="card-subtitle">Service Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                                <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="exampleInputUsername1">Service Code</label>
                                                <input type="text" class="form-control" formControlName="serviceCode" placeholder="Service Codee" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceCode.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.serviceCode.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.serviceCode.errors.required">Service Code is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Service Description</label>
                                                <input type="text" class="form-control"  formControlName="serviceDesc" placeholder="service Description"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceDesc.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.serviceDesc.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.serviceDesc.errors.required">service Description    is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="isActive">Is Active</label>
                                            <select class="form-control" formControlName="isActive" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.isActive.errors }">
                                                <option [selected]="true" [ngValue]="1" selected>Yes</option>
                                                <option [ngValue]="0">No</option>
                                            </select>   
                                            <div *ngIf="submitted && serviceForm.controls.isActive.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.isActive.errors?.required">IsActive is required</div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="isActive">Is Transactional</label>
                                            <select class="form-control" formControlName="isTransactional" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.isTransactional.errors }">
                                                <option [ngValue]="1" selected>Yes</option>
                                                <option [ngValue]="0">No</option>
                                            </select>   
                                            <div *ngIf="submitted && serviceForm.controls.isTransactional.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.isTransactional.errors?.required">IsTransactional Code is required</div>
                                            </div>
                                            </div>
                                        </div>
                                       
                                </div>
                                <div class="card-subtitle">Profile Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">Charge Mode Id</label>
                                        <select class="form-control" formControlName="serviceChargeModeId" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceChargeModeId.errors }">
                                            <option *ngFor="let p of selectServiceChargeModeData" [ngValue]="p.chargeModeId">{{p.chargeModeDesc}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.serviceChargeModeId.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.serviceChargeModeId.errors.required">Charge Mode Id is required</div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Service Profile Description</label>
                                            <input type="text" class="form-control" formControlName="serviceProfileDesc" placeholder="Profile Description"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceProfileDesc.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.serviceProfileDesc.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.serviceProfileDesc.errors.required">Profile Description is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">MaxInTxAmount</label>
                                            <input type="text" class="form-control"  formControlName="maxInTxAmount" placeholder="maxInTxAmount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.maxInTxAmount.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.maxInTxAmount.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.maxInTxAmount.errors.required">MaxInTxAmount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">MinOutTxAmount</label>
                                            <input type="text" class="form-control"  formControlName="minOutTxAmount" placeholder="MinOutTxAmount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.minOutTxAmount.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.minOutTxAmount.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.minOutTxAmount.errors.required">MinInTxAmount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                        
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">DailyInTxLimit</label>
                                            <input type="text" class="form-control" formControlName="dailyInTxLimit" placeholder="DailyInTxLimit"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.dailyInTxLimit.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.dailyInTxLimit.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.dailyInTxLimit.errors.required">DailyInTxLimit is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">DailyOutTxLimit</label>
                                            <input type="text" class="form-control" formControlName="dailyOutTxLimit" placeholder="DailyOutTxLimit"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.dailyOutTxLimit.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.dailyOutTxLimit.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.dailyOutTxLimit.errors.required">DailyOutTxLimit is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">MonthlyInTxLimit</label>
                                            <input type="text" class="form-control"  formControlName="monthlyInTxLimit" placeholder="MonthlyInTxLimit"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.monthlyInTxLimit.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.monthlyInTxLimit.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.monthlyInTxLimit.errors.required">MonthlyInTxLimit is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">MonthlyOutTxLimit</label>
                                            <input type="text" class="form-control"  formControlName="monthlyOutTxLimit" placeholder="MonthlyOutTxLimit"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.monthlyOutTxLimit.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.monthlyOutTxLimit.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.monthlyOutTxLimit.errors.required">MinInTxAmount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                 </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->