import { Injectable } from '@angular/core';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthenticated$: any;


  constructor(private http: HttpClient, public _sessionStorageService: SessionStorageService) { }

  public isAuthenticated(): boolean {
    var currentUser = this._sessionStorageService.getSessionData();
    if (currentUser)
      return true;
    return false;
    // Check whether the user is available and return
    // true or false
  }
  

}
