<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-11">
                        <h4 class="card-title">View System Settings</h4>
                    </div>
                    <div class="col-md-1">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backtowebrole()">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="sysForm" (ngSubmit)="updateSystemConfig()">
                            <div class="card-subtitle">System Settings</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Parameter Name</label>
                                        <input type="text" class="form-control" id="paramName" formControlName="paramName" placeholder="Parameter Name"
                                        [ngClass]="{ 'is-invalid': submitted && sysForm.controls.paramName.errors }">
                                        <div *ngIf="submitted && sysForm.controls.paramName.errors" class="text-danger">
                                            <div *ngIf="sysForm.controls.paramName.errors.required">Parameter Name is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Parameter Code</label>
                                        <input type="text" class="form-control" id="paramCode" formControlName="paramCode" placeholder="Parameter Code"
                                        [ngClass]="{ 'is-invalid': submitted && sysForm.controls.paramCode.errors }">
                                        <div *ngIf="submitted && sysForm.controls.paramCode.errors" class="text-danger">
                                            <div *ngIf="sysForm.controls.paramCode.errors.required">Parameter Code is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Parameter Value</label>
                                        <input type="text" class="form-control" id="paramValue" formControlName="paramValue" placeholder="Parameter Value"
                                        [ngClass]="{ 'is-invalid': submitted && sysForm.controls.paramValue.errors }">
                                        <div *ngIf="submitted && sysForm.controls.paramValue.errors" class="text-danger">
                                            <div *ngIf="sysForm.controls.paramValue.errors.required">Parameter Value is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Update</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>                    
                            <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button> -->
                            <!-- <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
