<div @fade class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Merchant Activity Report</h4>
        <p class="card-description">Activity Report</p>
        <form
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="passMerchantActivityReports()"
        >
          <div class="row">
            <div class="col-md">
              <div class="form-group">
                <label for="merchantId">Merchant Id</label>
                <input
                  type="text"
                  class="form-control-sm"
                  formControlName="merchantId"
                  placeholder="Merchant Id"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.merchantId.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.merchantId.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.merchantId.errors?.required">
                    Merchant Id is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="startDate">Start Date</label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="startDate"
                  placeholder="Start Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.startDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.startDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.startDate.errors?.required">
                    Start Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="endDate">End Date</label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="endDate"
                  placeholder="End Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.endDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.endDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.endDate.errors?.required">
                    End Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="payerId">Customer Account</label>
                <input
                  type="text"
                  class="form-control-sm"
                  formControlName="payerId"
                  placeholder="Customer Account"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.payerId.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.payerId.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.payerId.errors?.required">
                    CustomerNo is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="selectedCurrency">Currency</label>
                <select
                  class="form-control-sm"
                  formControlName="selectedCurrency"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectedCurrency.errors
                  }"
                >
                  <option
                    *ngFor="let c of currencyData"
                    [ngValue]="c.currencyId"
                  >
                    {{ c.currencyCode }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectedCurrency.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectedCurrency.errors?.required"
                  >
                    Currency is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="selectedValue">Status</label>
                <select
                  class="form-control-sm"
                  formControlName="selectedValue"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectedValue.errors
                  }"
                >
                  <option
                    *ngFor="let c of selectData"
                    [ngValue]="c.tranStatusId"
                  >
                    {{ c.tranStatusDesc }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectedValue.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.selectedValue.errors?.required">
                    Status is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
