<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row" *ngIf="showDistributor">
          <div class="col-md-10">
            <h4 class="card-title">{{ merchantTitle }}</h4>
          </div>
          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>
        <p class="card-description" *ngIf="showDistributor == false">
          Pos Merchants
        </p>

        <form
          *ngIf="showDistributor == false"
          class="forms-sample"
          [formGroup]="getDistrForm"
          novalidate
          (ngSubmit)="handleSubmitDistr()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <select
                  class="form-control-sm"
                  name="selectData"
                  formControlName="selectData"
                  [ngClass]="{
                    'is-invalid':
                      getSubmitted && getDistrForm.controls.selectData.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distributorList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="
                    getSubmitted && getDistrForm.controls.selectData.errors
                  "
                  class="text-danger"
                >
                  <div
                    *ngIf="getDistrForm.controls.selectData.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>

        <div class="row" style="margin-bottom: 0px" *ngIf="showDistributor">
          <div class="col-md-2">
            <input
              type="text"
              class="form-control form-control-sm"
              [(ngModel)]="term"
              placeholder="Search here..."
              style="float: left"
            />
          </div>
          <div class="col-md-10">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>
        <div class="table-responsive" *ngIf="showDistributor">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>MerchantId</th>
                <th>Partner UID</th>
                <th>Contact Number</th>
                <th>Merchant Name</th>
                <th>Product Name</th>
                <th>Created Date</th>
                <th>Status</th>
                <th>View Deatils</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.referenceId }}</td>

                <td>
                  {{ x.merchantIdentifier }}
                </td>
                <td>{{ x.telephone }}</td>
                <td>{{ x.merchantName }}</td>
                <td>{{ x.productName }}</td>
                <td class="py-1">
                  {{ x.createdDate | date : "dd-MMM-yy" }}
                </td>
                <td>
                  <span
                    style="color: #009e44; margin: 4px"
                    *ngIf="x.profileStatusDesc == 'active'"
                    >●</span
                  >
                  <span
                    style="color: #e90202; margin: 4px"
                    *ngIf="x.profileStatusDesc == 'disabled'"
                    >●</span
                  >
                  <span
                    style="color: #300202; margin: 4px"
                    *ngIf="x.profileStatusDesc == 'suspended'"
                    >●</span
                  >
                  <span
                    style="color: #f8a50a; margin: 4px"
                    *ngIf="x.profileStatusDesc == 'inactive'"
                    >●</span
                  >{{ x.profileStatusDesc }}
                </td>
                <td (click)="viewMerchantsDetails(x)">
                  <button type="button" class="btn btn-outline-primary btn-sm">
                    Deatils
                  </button>
                </td>

                <!-- <td (click)="passListCashierInfo(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td> -->
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-md"
  id="updateAccessModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Update Status Profile</h4>
              <form
                class="forms-sample"
                [formGroup]="updateAccessForm"
                (ngSubmit)="onUpdateAccessProfileInfo()"
              >
                <div class="card-subtitle">Access Profile</div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Status</label>
                  <select class="form-control" formControlName="selectedAccess">
                    <option value="null" disabled selected>--Select--</option>
                    <option
                      *ngFor="let p of selectAccess"
                      [ngValue]="p.profileStatusId"
                    >
                      {{ p.profileStatusDesc }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      updateAccessForm.controls.selectedAccess.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        updateAccessForm.controls.selectedAccess.errors.required
                      "
                    >
                      Status Profile is required
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-md"
  id="updateSubscriptionModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Merchant Subscription</h4>
              <form
                class="forms-sample"
                [formGroup]="subscripForm"
                (ngSubmit)="onUpdateMerchantSubscriptionInfo()"
              >
                <div class="card-subtitle">Subscription Type</div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Subscription</label>
                  <select
                    class="form-control"
                    formControlName="selectedSubscription"
                  >
                    <option value="null" disabled selected>--Select--</option>
                    <option
                      *ngFor="let p of selectSubscription"
                      [ngValue]="p.subscriptionTypeId"
                    >
                      {{ p.subscriptionTypeDesc }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      subscripForm.controls.selectedSubscription.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        subscripForm.controls.selectedSubscription.errors
                          .required
                      "
                    >
                      Status Profile is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <label class="form-check-label" style="margin: 20px">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        style="height: 20px; width: 20px"
                        formControlName="subscriptionAutoRenewal"
                      />
                      Subscription Auto Renewal
                    </label>
                  </div>
                  <div
                    *ngIf="
                      submitted &&
                      subscripForm.controls.subscriptionAutoRenewal.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        subscripForm.controls.subscriptionAutoRenewal.errors
                          .required
                      "
                    >
                      Subscription auto renewal is required
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
