<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-5">
            <h4 class="card-title">Card Details</h4>
          </div>
          <!-- action buttons -->
          <div class="col-md-7">
            <div class="d-flex justify-content-end">
              <!-- <div>
                <h4 class="card-title" style="float: right">
                  <button
                    type="button"
                    (click)="updateContactless()"
                    class="btn btn-dark"
                  >
                    {{ contactlessCardbtnName }}
                  </button>
                </h4>
              </div> -->
              <div class="ml-2" *ngIf="cardStatus == 'NotRequested'">
                <h4
                  class="card-title"
                  *ngIf="requestPhysicalCardPerm == 'true'"
                  style="float: right"
                >
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#RequestPhysicalModel"
                    class="btn btn-danger"
                    style="background-color: #a3d557; border-color: #a3d557"
                  >
                    Request Physical
                  </button>
                </h4>
              </div>
              <!-- <div class="ml-2">
                <h4 class="card-title" style="float: right">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#modifyModel"
                    (click)="viewUpdate()"
                    class="btn btn-primary"
                    style="background-color: #6f9307; border-color: #6f9307"
                  >
                    Edit Card Holder Name
                  </button>
                </h4>
              </div> -->

              <div class="ml-2" *ngIf="showBlockBtn">
                <h4
                  class="card-title"
                  *ngIf="blockCardPerm == 'true'"
                  style="float: right"
                >
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#updateAccessModel"
                    class="btn btn-danger"
                  >
                    Block Card
                  </button>
                </h4>
              </div>
              <div class="ml-2" *ngIf="showBlockBtn == false">
                <h4
                  class="card-title"
                  *ngIf="unBlockCardPerm == 'true'"
                  style="float: right"
                >
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#UnblockModel"
                    class="btn btn-warning text-white"
                  >
                    UnBlock
                  </button>
                </h4>
              </div>

              <div class="ml-2">
                <h4 class="card-title">
                  <button
                    type="button"
                    class="btn btn-warning text-white"
                    style="background-color: #757474; border-color: #585858"
                    (click)="backToService()"
                  >
                    Back
                  </button>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <!-- display card info  -->
        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg">
            <div class="form-group">
              <label for="cardId">Card Id</label>
              <input
                type="text"
                class="form-control bg-light"
                [(ngModel)]="cardId"
                placeholder="Card Id"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="form-group">
              <label for="cardHolderName">Card Holder Name</label>
              <input
                type="text"
                class="form-control bg-light"
                [(ngModel)]="cardHolderName"
                placeholder="Card Holder Name"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="form-group">
              <label for="mobileNumber">Mobile Number</label>
              <input
                type="text"
                class="form-control bg-light"
                [(ngModel)]="mobileNumber"
                placeholder="Mobile Number"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="form-group">
              <label for="cardNumberMask">Masked PAN</label>
              <input
                type="text"
                class="form-control bg-light"
                [(ngModel)]="cardNumberMask"
                placeholder="Masked PAN"
                readonly
              />
            </div>
          </div>

          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="form-group">
              <label for="trackingNumber">Tracking Number</label>
              <input
                type="text"
                class="form-control bg-light"
                [(ngModel)]="trackingNumber"
                placeholder="Tracking Number"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="form-group">
              <label for="cardStatus">Physical Card Status</label>
              <input
                type="text"
                class="form-control bg-light"
                [(ngModel)]="cardStatus"
                placeholder="Card Status"
                readonly
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-4 col-lg-2">
            <div class="form-group">
              <label for="status">Card Status</label>
              <input
                type="text"
                [class]="'form-control bg-light ' + cardstatusColor"
                [(ngModel)]="status"
                placeholder="status"
                readonly
              />
            </div>
          </div>
          <div
            class="col-sm-6 col-md-4 col-lg-2"
            *ngIf="showBlockReason == 'true'"
          >
            <div class="form-group">
              <label for="blockReason">Block Reason</label>
              <input
                type="text"
                class="form-control bg-light text-danger"
                [(ngModel)]="blockReason"
                placeholder="blockReason"
                readonly
              />
            </div>
          </div>
        </div>
        <!-- nav -->
        <div class="row">
          <div class="col-md-12 grid-margin stretch-card mt-5">
            <div class="card">
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    class="nav-item nav-link active"
                    id="nav-card-tab"
                    data-toggle="tab"
                    href="#nav-card"
                    role="tab"
                    aria-controls="nav-card"
                    aria-selected="true"
                  >
                    Card Transactions</a
                  >
                  <a
                    class="nav-item nav-link"
                    id="nav-api-tab"
                    data-toggle="tab"
                    href="#nav-falied_transactions"
                    role="tab"
                    aria-controls="nav-card"
                    aria-selected="true"
                  >
                    Failed Transactions
                  </a>
                  <a
                    class="nav-item nav-link"
                    id="nav-api-tab"
                    data-toggle="tab"
                    href="#nav-card-token"
                    role="tab"
                    aria-controls="nav-card"
                    aria-selected="true"
                  >
                    Card Tokens</a
                  >
                  <a
                    class="nav-item nav-link"
                    id="nav-api-tab"
                    data-toggle="tab"
                    href="#nav-card-event"
                    role="tab"
                    aria-controls="nav-card"
                    aria-selected="true"
                  >
                    Card Events</a
                  >
                </div>
              </nav>

              <div class="tab-content" id="nav-tabContent">
                <!-- card transactions -->
                <div
                  class="tab-pane fade show active"
                  id="nav-card"
                  role="tabpanel"
                  aria-labelledby="nav-card-tab"
                >
                  <div class="row" style="margin-bottom: 0px">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="startDate" className="text-white"
                          >Search</label
                        >
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          [(ngModel)]="term"
                          aria-hidden="true"
                          placeholder="Search here..."
                          style="float: left"
                        />
                      </div>
                    </div>

                    <div class="col-md-3"></div>
                    <div class="col-md-7">
                      <form
                        class="forms-sample"
                        [formGroup]="getForm"
                        novalidate
                        (ngSubmit)="handleSubmit()"
                      >
                        <div class="row">
                          <div class="col-md">
                            <div class="form-group">
                              <label for="startDate">Start Date</label>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                formControlName="startDate"
                                placeholder="Start Date"
                                [ngClass]="{
                                  'is-invalid':
                                    submitted &&
                                    getForm.controls.startDate.errors
                                }"
                              />
                              <div
                                *ngIf="
                                  submitted && getForm.controls.startDate.errors
                                "
                                class="text-danger"
                              >
                                <div
                                  *ngIf="
                                    getForm.controls.startDate.errors?.required
                                  "
                                >
                                  Start Date is required
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md">
                            <div class="form-group">
                              <label for="endDate">End Date</label>
                              <input
                                type="date"
                                class="form-control form-control-sm"
                                formControlName="endDate"
                                placeholder="End Date"
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && getForm.controls.endDate.errors
                                }"
                              />
                              <div
                                *ngIf="
                                  submitted && getForm.controls.endDate.errors
                                "
                                class="text-danger"
                              >
                                <div
                                  *ngIf="
                                    getForm.controls.endDate.errors?.required
                                  "
                                >
                                  End Date is required
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md">
                            <div class="form-group">
                              <button
                                type="submit"
                                class="btn btn-primary"
                                style="margin-top: 30px"
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table
                      class="table-striped display expandable-table"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Type</th>
                          <th>Name</th>
                          <th>Amount</th>
                          <th>Fee</th>
                          <th>Remarks</th>
                          <th>Processor Id</th>
                          <th>Date</th>
                          <th>State</th>
                          <!-- <th>balance</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let x of cardTransactionList
                              | filter : term
                              | paginate
                                : { itemsPerPage: selected, currentPage: p }
                          "
                        >
                          <td>{{ x.transactionId }}</td>
                          <td>{{ x.tranType }}</td>
                          <td>{{ x.embossingName }}</td>
                          <td>{{ x.currency }}{{ x.tranAmount }}</td>
                          <td>{{ x.currency }}{{ x.tranFee }}</td>

                          <td>{{ x.remarks }}</td>
                          <td>{{ x.sndTranId }}</td>
                          <td>
                            {{ x.sndTranDate | date : "dd-MMM-yy - HH:mm:ss" }}
                          </td>
                          <td>
                            <span
                              class="badge badge-success"
                              *ngIf="x.state == 'Completed'"
                            >
                              {{ x.state }}</span
                            >
                            <span
                              class="badge badge-warning"
                              *ngIf="x.state != 'Completed'"
                            >
                              {{ x.state }}</span
                            >
                          </td>
                          <!-- <td>{{ x.previosBalance }}</td> -->
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%">
                      <h4 class="card-title" style="float: left">
                        <select class="form-control-sm" [(ngModel)]="selected">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </h4>
                      <pagination-controls
                        style="float: right"
                        (pageChange)="p = $event"
                      ></pagination-controls>
                    </div>
                    <div *ngIf="!listBody" class="container">
                      <div style="text-align: center; padding: 24px">
                        {{ message }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card failed Transactions -->
                <div
                  class="tab-pane fade show"
                  id="nav-falied_transactions"
                  role="tabpanel"
                  aria-labelledby="nav-api-tab"
                >
                  <div class="row" style="margin-bottom: 0px">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="startDate" className="text-white"
                          >Search</label
                        >
                        <input
                          type="text"
                          class="form-control form-control-sm mb-1"
                          [(ngModel)]="term"
                          aria-hidden="true"
                          placeholder="Search here..."
                          style="float: left"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table
                      class="table-striped display expandable-table"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Tran Id</th>
                          <th>Service Name</th>
                          <th>Mobile</th>
                          <th>Amount</th>

                          <th>Narrative</th>
                          <th>SndTranId</th>
                          <th>Status</th>
                          <th>Description</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let x of failedTransactions
                              | filter : term
                              | paginate
                                : { itemsPerPage: selected, currentPage: p }
                          "
                        >
                          <td>{{ x.tranId }}</td>
                          <td>{{ x.serviceName }}</td>
                          <td>{{ x.walletMobileNo }}</td>
                          <td>{{ x.tranAmount }}</td>

                          <td>{{ x.narrative }}</td>
                          <td>{{ x.sndTranId }}</td>
                          <td>{{ x.status }}</td>
                          <td>{{ x.failureDesc }}</td>
                          <td>
                            {{ x.createdAt | date : "dd-MMM-yy - HH:mm:ss" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%">
                      <h4 class="card-title" style="float: left">
                        <select class="form-control-sm" [(ngModel)]="selected">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </h4>
                      <pagination-controls
                        style="float: right"
                        (pageChange)="p = $event"
                      ></pagination-controls>
                    </div>
                    <div *ngIf="!listBody" class="container">
                      <div style="text-align: center; padding: 24px">
                        {{ message }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card tokens-->
                <div
                  class="tab-pane fade show"
                  id="nav-card-token"
                  role="tabpanel"
                  aria-labelledby="nav-api-tab"
                >
                  <div class="row" style="margin-bottom: 0px">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="startDate" className="text-white"
                          >Search</label
                        >
                        <input
                          type="text"
                          class="form-control form-control-sm mb-1"
                          [(ngModel)]="term"
                          aria-hidden="true"
                          placeholder="Search here..."
                          style="float: left"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table
                      class="table-striped display expandable-table"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Tokenized PAN</th>
                          <!-- <th>TUR</th> -->
                          <th>expiry</th>

                          <th>deviceName</th>
                          <th>Digitized Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let x of cardTokensList
                              | filter : term
                              | paginate
                                : { itemsPerPage: selected, currentPage: p }
                          "
                        >
                          <td>{{ x.cardTokenId }}</td>
                          <td>{{ x.maskedFpan }}</td>
                          <!-- <td>{{ x.tokenUniqueRef }}</td> -->
                          <td>{{ x.expiry | date : "yyyy" }}</td>

                          <td>{{ x.deviceName }}</td>
                          <td>
                            {{ x.createdAt | date : "dd-MMM-yy - HH:mm:ss" }}
                          </td>

                          <td>
                            <span
                              class="badge badge-success"
                              *ngIf="x.cardTokenStatus == 'Active'"
                            >
                              {{ x.cardTokenStatus }}
                            </span>
                            <span
                              class="badge badge-danger"
                              *ngIf="x.cardTokenStatus != 'Active'"
                            >
                              {{ x.cardTokenStatus }}
                            </span>
                          </td>
                          <td>
                            <button
                              *ngIf="x.cardTokenStatus == 'Active'"
                              type="button"
                              (click)="selectedToken('block')"
                              class="btn btn-outline-warning btn-sm"
                              data-toggle="modal"
                              data-target="#updateCardTokens"
                            >
                              Block Token
                            </button>
                            <button
                              *ngIf="x.cardTokenStatus != 'Active'"
                              (click)="selectedToken('unblock')"
                              type="button"
                              class="btn btn-outline-primary btn-sm"
                              data-toggle="modal"
                              data-target="#updateCardTokens"
                            >
                              UnBlock Token
                            </button>
                            <button
                              (click)="selectedToken('delete')"
                              type="button"
                              class="btn btn-outline-danger btn-sm ml-2"
                              data-toggle="modal"
                              data-target="#updateCardTokens"
                            >
                              Delete Token
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%">
                      <h4 class="card-title" style="float: left">
                        <select class="form-control-sm" [(ngModel)]="selected">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </h4>
                      <pagination-controls
                        style="float: right"
                        (pageChange)="p = $event"
                      ></pagination-controls>
                    </div>
                    <div *ngIf="!listBody" class="container">
                      <div style="text-align: center; padding: 24px">
                        {{ message }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card events -->
                <div
                  class="tab-pane fade show"
                  id="nav-card-event"
                  role="tabpanel"
                  aria-labelledby="nav-api-tab"
                >
                  <div class="row" style="margin-bottom: 0px">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="startDate" className="text-white"
                          >Search</label
                        >
                        <input
                          type="text"
                          class="form-control form-control-sm mb-1"
                          [(ngModel)]="term"
                          aria-hidden="true"
                          placeholder="Search here..."
                          style="float: left"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table
                      class="table-striped display expandable-table"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Event Name</th>
                          <th>Previos Value</th>

                          <th>New Value</th>
                          <th>Description</th>
                          <th>Event Source</th>
                          <th>Created by</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let x of CardEventLogs
                              | filter : term
                              | paginate
                                : { itemsPerPage: selected, currentPage: p }
                          "
                        >
                          <td>{{ x.eventName }}</td>
                          <td>{{ x.prevValue }}</td>
                          <!-- <td>{{ x.tokenUniqueRef }}</td> -->
                          <td>{{ x.newValue }}</td>

                          <td>{{ x.description }}</td>
                          <td>{{ x.eventSource }}</td>
                          <td>{{ x.userTitle }}</td>
                          <td>
                            {{ x.createdAt | date : "dd-MMM-yy - HH:mm:ss" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%">
                      <h4 class="card-title" style="float: left">
                        <select class="form-control-sm" [(ngModel)]="selected">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </h4>
                      <pagination-controls
                        style="float: right"
                        (pageChange)="p = $event"
                      ></pagination-controls>
                    </div>
                    <div *ngIf="!listBody" class="container">
                      <div style="text-align: center; padding: 24px">
                        {{ message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-md"
  id="updateAccessModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Block Card Temporarily</h4>
              <form
                class="forms-sample"
                [formGroup]="updateAccessForm"
                (ngSubmit)="StopCard()"
              >
                <div class="card-subtitle">
                  You can temporarily block this card to prevent unauthorized
                  Transections Choose a reason from the list below and click the
                  button to block card.you can unblock it anytime if needed.
                </div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group" *ngIf="showBlockReasonInput">
                  <label for="exampleInputUsername1">Block Reason</label>
                  <select class="form-control" formControlName="selectedAccess">
                    <option value="null" disabled selected>--Select--</option>
                    <option
                      *ngFor="let p of cardStatusesList"
                      [ngValue]="p.statusId"
                    >
                      {{ p.description }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      updateAccessForm.controls.selectedAccess.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        updateAccessForm.controls.selectedAccess.errors.required
                      "
                    >
                      Block Reason is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Description </label>
                  <textarea
                    type="text"
                    class="form-control"
                    formControlName="Remarks"
                    placeholder="Description"
                    [ngClass]="{
                      'is-invalid':
                        submitted && updateAccessForm.controls.Remarks.errors
                    }"
                  ></textarea>
                  <div
                    *ngIf="
                      submitted && updateAccessForm.controls.Remarks.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="updateAccessForm.controls.Remarks.errors.required"
                    >
                      Description is required
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group">
                  <label for="merchantUid">Description </label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="cardId"
                    placeholder="Description"
                  />
                </div> -->

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- unblock model -->
<div
  class="modal fade bd-example-modal-md"
  id="UnblockModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div
                  class="alert alert-warning d-flex align-items-center"
                  role="alert"
                >
                  <i class="bi bi-exclamation-triangle-fill"></i>
                  <div>
                    <strong>Warning!</strong> Are you sure you want to unblock
                    this card, which was previously blocked
                    {{ isAdminBlocked == "1" ? "by the admin" : "" }}? Please
                    refer card events for previous detailed block/unblock
                    actions.
                  </div>
                </div>
              </div>
              <form
                class="forms-sample"
                [formGroup]="UpdateUnBlockForm"
                (ngSubmit)="unBlockCard()"
              >
                <div class="card-subtitle"></div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>

                <div class="form-group">
                  <label for="exampleInputUsername1">Description </label>
                  <textarea
                    type="text"
                    class="form-control"
                    formControlName="Remarks"
                    placeholder="Description"
                    [ngClass]="{
                      'is-invalid':
                        submitted && UpdateUnBlockForm.controls.Remarks.errors
                    }"
                  ></textarea>
                  <div
                    *ngIf="
                      submitted && UpdateUnBlockForm.controls.Remarks.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="UpdateUnBlockForm.controls.Remarks.errors.required"
                    >
                      Description is required
                    </div>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Request Physical -->
<div
  class="modal fade bd-example-modal-md"
  id="RequestPhysicalModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Request Physical Card</h4>
              <form
                class="forms-sample"
                [formGroup]="requestPhysicalForm"
                (ngSubmit)="SubmitRequestPhysicalCard()"
              >
                <div class="card-subtitle"></div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>

                <div class="form-group">
                  <label for="exampleInputUsername1">Select Branch</label>
                  <select class="form-control" formControlName="branchId">
                    <option value="null" disabled selected>--Select--</option>
                    <option *ngFor="let p of branchList" [ngValue]="p.id">
                      {{ p.name }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted && requestPhysicalForm.controls.branchId.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        requestPhysicalForm.controls.branchId.errors.required
                      "
                    >
                      branch is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="exampleInputUsername1">Address </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder="address"
                    formControlName="addressLine"
                  />

                  <div
                    *ngIf="
                      submitted &&
                      requestPhysicalForm.controls.addressLine.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        requestPhysicalForm.controls.addressLine.errors.required
                      "
                    >
                      address is required
                    </div>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- card tokens model -->
<div
  class="modal fade bd-example-modal-md"
  id="updateCardTokens"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ TokenModelTitle }}</h4>
              <form
                class="forms-sample"
                [formGroup]="updateAccessForm"
                (ngSubmit)="handleSubmitCardtoken()"
              >
                <!-- <div class="card-subtitle"></div> -->
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="exampleInputUsername1"> Reason</label>
                  <select class="form-control" formControlName="selectedAccess">
                    <option value="null" disabled selected>--Select--</option>
                    <option
                      *ngFor="let p of cardStatusesList"
                      [ngValue]="p.statusId"
                    >
                      {{ p.description }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      updateAccessForm.controls.selectedAccess.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        updateAccessForm.controls.selectedAccess.errors.required
                      "
                    >
                      Reason is required
                    </div>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- update card colderName -->
<div
  class="modal fade bd-example-modal-md"
  id="modifyModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Modify Name</h4>
              <form
                class="forms-sample"
                [formGroup]="updateForm"
                (ngSubmit)="HandleUpdateName()"
              >
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="name">Current Name </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder="Name"
                    formControlName="name"
                    readonly
                  />

                  <div
                    *ngIf="submitted && updateForm.controls.name.errors"
                    class="text-danger"
                  >
                    <div *ngIf="updateForm.controls.name.errors.required">
                      Name is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="name">Cardholder Name</label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    id="newName"
                    placeholder="Cardholder"
                    formControlName="newName"
                    [(ngModel)]="inputText"
                    (input)="onInputChange($event)"
                    pattern="^[a-zA-Z0-9 ]*$"
                    minlength="3"
                    maxlength="20"
                    [ngClass]="{
                      'is-invalid':
                        submitted && updateForm.controls.newName.errors
                    }"
                  />
                  <div
                    *ngIf="submitted && updateForm.controls.newName.errors"
                    class="text-danger"
                  >
                    <div *ngIf="updateForm.controls.newName.errors.required">
                      Cardholder is required
                    </div>

                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.newName.errors?.minlength"
                    >
                      Cardholder must be at least 3 characters long.
                    </div>
                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.newName.errors?.maxlength"
                    >
                      Cardholder cannot be more than 20 characters long.
                    </div>
                    <div
                      class="text-danger"
                      *ngIf="updateForm.controls.newName.errors?.pattern"
                    >
                      Cardholder Name can only contain alphanumeric characters
                      and spaces.
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
