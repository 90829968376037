import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';



@Component({
  selector: 'app-event-log-info',
  templateUrl: './event-log-info.component.html',
  styleUrls: ['./event-log-info.component.css'],
  animations: [
    fade
  ]
})
export class EventLogInfoComponent implements OnInit {

  serviceForm: FormGroup;
  queryUserId: number;
  queryEventLogId: number;
  userId: any;
  eventLogId: any;
  dataRecords: any;
  sub: Subscription;
  submitted: boolean = false;

  lat = 2.0462;
  lng = 45.3341;

  userTitle;
  firstName;
  middleName;
  lastName;
  email;
  telephone;
  merchantName;

  country_code;
  country_name;
  city;
  state;
  reson;


  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _location: Location,
    private _sessionStorageService: SessionStorageService
  ) {

    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryUserId = +params['userId'];
        this.queryEventLogId = +params['eLogId'];
        if (Number.isNaN(this.queryUserId)) {
          this.userId = "";
        } else {
          this.userId = this.queryUserId;
          this.eventLogId = this.queryEventLogId;
          this.getUserInfo(this.userId);
          this.getEventLogInfo(this.eventLogId);
        }
      });
  }




  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }

  getUserInfo(userId) {
    let payload: any = {
      userId: userId
    };
    this.spinner.getSpinner();
    this.service.getUserInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.userTitle = data.userTitle;
        this.firstName = data.firstName;
        this.middleName = data.middleName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.telephone = data.telephone;
        console.log("kkkk", data)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getEventLogInfo(eventLogId?) {
    let payload: any = {
      eventLogId: eventLogId
    };
    this.spinner.getSpinner();
    this.service.getEventLogInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.merchantName = data.merchantName;
        this.reson = data.reson;
        if (data.address != null) {
          let address = JSON.parse(data.address);
          this.country_code = address.country_code;
          this.country_name = address.country_name;
          this.city = address.city;
          this.state = address.state;
        } else {
          this.country_code = "";
          this.country_name = "";
          this.city = "";
          this.state = "";
        }
        // this.country_code = address.country_code;
        // this.country_name = address.country_name;
        // this.city = address.city;
        // this.state = address.state;
        // console.log("kkkk", data)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  OnUpdateUser() {

  }


}
