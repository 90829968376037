import { formatDate, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'view-card-details',
  templateUrl: './view-card-detail.component.html',
  styleUrls: ['./view-card-detail.component.css'],
  animations: [
    fade
  ]
})
export class ViewCardDeatilComponent implements OnInit {
  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_CASHIER = 40;
  _CASHIERTYPEID_POS = 1;
  _CASHIERTYPEID_STORE = 2;
  _CASHIERTYPEID_APIUSERS = 3;
  dataList;
  posTransectionsData;
  serviceForm: FormGroup;
  updateAccessForm: FormGroup;
  UpdateUnBlockForm: FormGroup;
  submitted = false;
  modal;
  getForm: FormGroup;
  customerId;
  mobileName;
  cardHolderName;
  cardNumberMask;
  cardCreatedDate;
  showprintCardbtn: boolean = false;
  cardStatus;
  customerName;
  merchantId;
  queryMerchantId;
  merchantIdentifier;
  groupName;
  data;
  cardId;
  listBody: boolean;
  message;
  isThirtParty: boolean = false;
  showPhysicalCardBtn: boolean = false;
  accessProfileId;
  selectAccess;
  posDevicesList
  listData;
  isTrue = false;
  selected = 50;
  p: number = pagination.p;
  term;
  waafiCardList;
  walletId;
  mobileNumber;
  cardAccountList;
  merchInfo;
  accountId;
  cashierList;
  physicalCardbtnName;
  tokenizedPAN;
  requestedDate;
  tokenizedPANExpiryDate;
  isBlocked: number = 0;
  ValidDate;
  TUR;
  cardTokensList;
  cardReasonsList;
  cardBtnName;
  subscripForm: FormGroup;
  requestPhysicalForm: FormGroup;
  createdDate;
  trackingNumber;
  showBlockBtn;
  cardIsblocked;
  cardTransactionList;
  showBlockReasonInput: boolean = true;
  cardStatusesList;
  contactlessCardbtnName;
  dafualtObj;
  branchList;
  blockReason;
  isAdminBlocked;
  contactlessBtnColor;
  distributorId;
  currentmobileNumber;
  isContactlessEnabled = 0;
  TokenModelTitle;
  status;
  showBlockReason;
  cardstatusColor;
  tokenAction;
  updateForm: FormGroup;
  inputText;
  CardEventLogs;
  failedTransactions;
  requestPhysicalCardPerm;
  blockCardPerm;
  unBlockCardPerm;
  btnDisabled: boolean = true;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.route.queryParams.subscribe(params => {
      this.requestedPhysicalForm();
      this.UpdatedUnBlockForm();
      if (Object.keys(params).length != 0) {
        this.distributorId = params['distributorId'];
        const currentUser = this._sessionStorageService.getSessionData();
        this.updatesForm()
        this.cardId = params['cardId'];
        this.mobileNumber = params['mobileNumber'];
        this.getcardinfo(this.cardId)
        this.getCardEventLogs(this.cardId)
        this.getCardStatuses()

        this.getFrom();
        this.getcardtokens(this.cardId)
        let obj = { cardId: this.cardId, pStartDate: "", pStartEnd: "", pLastFourDigit: "", pNumber: "", status: "" }
        this.dafualtObj = obj
        this.getCardTransactions(obj);
        this.getBranches();
        // this.getFailedCardTransactions(this.cardId, this.trackingNumber);
        this.requestPhysicalCardPerm = this._sessionStorageService.checkPermission('request_physical_card_permission');
        this.blockCardPerm = this._sessionStorageService.checkPermission('block_card_permission');
        this.unBlockCardPerm = this._sessionStorageService.checkPermission('un_block_card_permission');

      } else {
        _location.back();
      }
    });
    this.updatedAccessForm();
  }

  onload(obj) {

    this.cardHolderName = obj.embossingName;
    // this.merchantId = this.queryMerchantId;
    this.cardStatus = obj.cardStatus;
    this.isBlocked = obj.isBlocked;
    this.blockReason = obj.blockReason;
    this.cardNumberMask = obj.maskedPan;
    this.trackingNumber = obj.trackingNumber;

    this.status = "Active";
    if (obj.isBlocked) {
      this.status = "BLOCKED"
    }

    if (obj.isAdminBlocked) {
      this.status += " (By ADMIN)"
    }
    if (obj.isAdminBlocked == 1 || obj.isBlocked == 1) {
      this.cardstatusColor = "text-danger"
      this.showBlockReason = "true"
    } else {
      this.cardstatusColor = "text-success"
      this.showBlockReason = "false"
    }

    // this.cardIsblocked = obj.isBlocked ? "Yes" : "No"
    // this.isAdminBlocked = obj.isAdminBlocked ? "Yes" : "No"
    this.isContactlessEnabled = obj.isContactlessEnabled;
    this.contactlessCardbtnName = obj.isContactlessEnabled ? "Black list Contactless" : "White list Contactless"

    const dateString = obj.validDate;
    // Get the current year
    const currentYear = new Date().getFullYear();
    // Create a new date string with the current year
    const fullDateString = `${currentYear}/${dateString}`;
    // Create a new Date object
    const date = new Date(fullDateString);
    // Extract the year
    const year = date.getFullYear();
    this.ValidDate = year;
    // this.ValidDate = obj.validDate;
    console.log(year);

    if (this.isBlocked == 0) {

      this.showBlockReasonInput = true
      this.showBlockBtn = true
      this.cardBtnName = "Block Card";
      if (this.cardStatus == "NotRequested") {
        this.showprintCardbtn = false
        this.showPhysicalCardBtn = false
      } else if (this.cardStatus == "Requested") {
        this.showprintCardbtn = true
        // this.showPhysicalCardBtn = true
      } else if (this.cardStatus == "Active") {
        this.showprintCardbtn = false
        // this.showPhysicalCardBtn = true
        this.physicalCardbtnName = "Deactivate Physical Card"
      } else if (this.cardStatus == "NotActive") {
        this.showprintCardbtn = false
        // this.showPhysicalCardBtn = true
        this.physicalCardbtnName = "Enable Physical Card"
      }
    } else {
      this.cardBtnName = "Un Block Card";
      this.showBlockReasonInput = false
      this.showBlockBtn = false
    }


  }
  handleSubmit() {
    this.submitted = true;
    let startDate = this.getForm.value.startDate == undefined ? "" : this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate == undefined ? "" : this.getForm.value.endDate;
    let obj = { cardId: this.cardId, pStartDate: startDate, pStartEnd: endDate, pLastFourDigit: "", pNumber: "" }
    // if (this.getForm.invalid) {
    //   return;
    // }
    this.getCardTransactions(obj);
  }
  updateContactless() {
    let payload: any = {
      cardId: this.cardId,
      Pstate: this.isContactlessEnabled == 1 ? 0 : 1
    };
    this.spinner.getSpinner();
    // stopCard
    this.service.updateContactless({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (successResponse.success) {
        this.toastr.success(successResponse.records.message);

        this.spinner.hideSpinner();
        this.getcardinfo(this.cardId);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message, 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  updatedAccessForm() {
    this.updateAccessForm = this.fb.group({
      selectedAccess: ['', Validators.required],
      Remarks: ['', Validators.required],
    });
  }
  requestedPhysicalForm() {
    this.requestPhysicalForm = this.fb.group({
      branchId: ['', Validators.required],
      addressLine: ['', Validators.required],
    });
  }
  UpdatedUnBlockForm() {
    this.UpdateUnBlockForm = this.fb.group({
      Remarks: ['', Validators.required],
    });
  }
  backToService() {
    this._location.back();
  }
  getFrom() {
    let currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.getForm = this.fb.group({
      endDate: [currentDate, Validators.required],
      startDate: [currentDate, Validators.required],
      pLastFourDigit: ['', Validators.required],
      pNumber: ['', Validators.required],
    });
  }
  ngOnInit(): void {


  }

  getUpdateInfo(data) {
    this.accessProfileId = data.accessProfileId;
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getUserAccessProfileStatus({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // console.log("data :=> ", records);
      // this.selectAccess = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  StopCard() {
    this.submitted = true;

    if (this.updateAccessForm.invalid) {
      return;
    }
    const now = new Date();
    let timestamp = now.toISOString();
    let payload: any = {
      timestamp: timestamp,
      cardId: this.cardId,
      mobileNumber: this.mobileNumber,
      reasonCode: this.updateAccessForm.value.selectedAccess,
      reason: this.updateAccessForm.value.Remarks,
    };
    payload.reasonCode = payload.reasonCode.toString();
    this.spinner.getSpinner();
    // stopCard
    this.service.stopCard({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (successResponse.success) {
        this.toastr.success(successResponse.records.message);
        // this.toastr.success(`You have updated to ${successResponse.records.message}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        // this.getMerchantInfo(this.merchantId);
        this.getcardinfo(this.cardId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message, 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  unBlockCard() {

    this.submitted = true;
    const now = new Date();
    let timestamp = now.toISOString();
    if (this.UpdateUnBlockForm.invalid) {
      return;
    }

    let payload: any = {
      timestamp: timestamp,
      cardId: this.cardId,
      mobileNumber: this.mobileNumber,
      reasonCode: "",
      reason: this.UpdateUnBlockForm.value.Remarks,
    };

    this.spinner.getSpinner();
    this.service.unStopCard({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (successResponse.success) {
        this.toastr.success(successResponse.records.message);
        // this.toastr.success(`You have updated to ${successResponse.records.message}`, 'Successfully')
        $('#UnblockModel').modal('hide');
        this.spinner.hideSpinner();
        // this.getMerchantInfo(this.merchantId);
        this.getcardinfo(this.cardId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message, 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  SubmitRequestPhysicalCard() {
    this.submitted = true;
    if (this.requestPhysicalForm.invalid) {
      return;
    }

    let payload: any = {
      cardId: this.cardId,
      mobileNumber: this.mobileNumber,
      branchId: this.requestPhysicalForm.value.branchId,
      addressLine: this.requestPhysicalForm.value.addressLine,
    };

    payload.branchId = payload.branchId.toString()
    // payload.cardId = Number(payload.cardId)
    this.spinner.getSpinner();
    this.service.requestPhysicalCard({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (successResponse.success) {
        this.toastr.success(successResponse.records.message);
        $('#RequestPhysicalModel').modal('hide');
        this.spinner.hideSpinner();
        this.getcardinfo(this.cardId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message, 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  onUpdateAccessProfileInfoMerchant() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.serviceForm.invalid) {
      return;
    }

    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.serviceForm.value.selectedAccess
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (data.accessProfileId) {
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        this.getMerchantInfo(this.merchantId);


      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  getMerchantInfo(merchantId) {
    let currentUser = this._sessionStorageService.getSessionData();
    this.listBody = false;
    let payload: any = {
      // userId: currentUser.userId,
      merchantId: merchantId

    };
    this.serviceForm = this.fb.group({
      userId: [currentUser.userId, Validators.required],
      userTitle: [currentUser.userTitle, Validators.required],
      selectedAccess: [currentUser.profileStatusId, Validators.required],
    });
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.data = data;

        this.merchantIdentifier = data.merchantIdentifier;


        this.createdDate = data.createdDate;

        this.accessProfileId = data.accessProfileId;
        this.groupName = data.groupName;


        if (data.merchantTypeId == 1) {
          this.isThirtParty = true;
        } else {
          this.isThirtParty = false;
        }
        console.log("kkkk", data)
      } else {
        this.toastr.error("Something went wrong")
        this.spinner.hideSpinner();
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getCardTransactions(data) {

    // cardId: "292"
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.distributorId,
      cardId: this.cardId,
      pStartDate: data.pStartDate,
      pStartEnd: data.pStartEnd,
      pLastFourDigit: data.pLastFourDigit,
      pNumber: data.pNumber,
      status: data.status == undefined ? "" : data.status,
      transactionId: ""
    };
    this.spinner.getSpinner();
    this.service.getcardTransactions({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.cardTransactionList = records;

      } else {

        this.spinner.hideSpinner();
        this.cardTransactionList = []
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getcardtokens(cardId) {
    let payload: any = {
      cardId: cardId,
    };
    this.spinner.getSpinner();
    this.service.getcardtokens({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.cardTokensList = records;

      } else {
        this.spinner.hideSpinner();
        // this.toastr.warning(successResponse.records.message);
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getCardStatuses() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCardStatuses({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.cardStatusesList = records;

      } else {
        this.spinner.hideSpinner();
        // this.toastr.warning(successResponse.records.message);
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getcardinfo(cardId) {
    let payload: any = {
      cardId: cardId,
    };
    this.spinner.getSpinner();
    this.service.getcardinfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.onload(data)
        this.getFailedCardTransactions(this.cardId, data.trackingNumber)

      } else {
        this.spinner.hideSpinner();
        // this.toastr.warning(successResponse.records.message);
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passMerchantCashiersView() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
  }

  viewPosInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_POS) {
      this.posListBody = true;
      return true;
    }
    this.listBody = true;
    this.message = "Data Not Found";
    return false;
  }

  viewWebStoreInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_STORE) {
      this.webStoreListBody = true;
      return true;
    }
    this.listBody = true;
    this.message = "Data Not Found";
    return false;
  }

  getCardTransectionOnSelectedCard = function (data) {
    // let startDate = this.getForm.value.startDate == undefined ? "" : this.getForm.value.startDate;
    // let endDate = this.getForm.value.endDate == undefined ? "" : this.getForm.value.endDate;

    let obj = { cardId: data.cardId, pStartDate: "", pStartEnd: "", pLastFourDigit: "", pNumber: "", status: "" }
    this.cardId = data.cardId
    this.getCardTransactions(obj);

  }

  viewApiUsersInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_APIUSERS) {
      this.apiListBody = true;
      return true;
    }
    return false;
  }
  getBranches() {
    let payload: any = {
      distributorId: this.distributorId
    };
    this.spinner.getSpinner();
    this.service.getBranches({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.branchList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  OnCreateCashierUser() {
    let merchantId = this.data.merchantId;
    let merchantUid = this.data.merchantUid;
    this.router.navigate(["dashboard/view/createCashierInfo"], { queryParams: { merchantId, merchantUid } });
  }
  OnViewCashierUser(x) {
    let cashierId = x.cashierId;
    this.router.navigate(["dashboard/view/getCashierInfo"], { queryParams: { cashierId } });
  }
  OnViewReSendCredentials(x) {
    let cashierId = x.cashierId;
    this.router.navigate(["dashboard/view/getReSendCredentials"], { queryParams: { cashierId } });
  }

  selectedToken(val) {

    this.tokenAction = val;
    if (val == "block") {
      this.TokenModelTitle = "Block Token"
    } else if (val == "unblock") {
      this.TokenModelTitle = "Un Block Token"
    } else if (val == "delete") {
      this.TokenModelTitle = "Delete Token"
    }

  }

  handleSubmitCardtoken() {
    this.submitted = true;

    if (this.updateAccessForm.value.selectedAccess == '') {
      return;
    }

    let obj1 = {
      cardId: this.cardId,
      mobileNumber: this.mobileNumber,
      stopReasonID: this.updateAccessForm.value.selectedAccess,
      tokenAction: this.tokenAction,
    }
    let obj2 = {
      cardId: this.cardId,
      mobileNumber: this.mobileNumber,
      unStopReasonID: this.updateAccessForm.value.selectedAccess,
      tokenAction: this.tokenAction,
    }
    obj1.stopReasonID = obj1.stopReasonID.toString();
    obj2.unStopReasonID = obj2.unStopReasonID.toString();
    let payload: any = {
    };
    if (this.tokenAction == "block") {
      payload = obj1
    } else {
      payload = obj2
    }
    payload.cardId = payload.cardId.toString();
    payload.mobileNumber = payload.mobileNumber.toString();
    this.spinner.getSpinner();
    // stopCard
    this.service.CardTokenAccess({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (successResponse.success) {
        this.toastr.success(successResponse.records.message);
        $('#updateCardTokens').modal('hide');
        this.spinner.hideSpinner();
        // this.getcardinfo(this.cardId)
        this.getcardtokens(this.cardId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message, 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  updatesForm() {
    this.updateForm = this.fb.group({
      name: ['', Validators.required],
      newName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      id: ['', Validators.required],
    });
  }
  viewUpdate() {
    this.updateForm = this.fb.group({
      name: [this.cardHolderName, Validators.required],
      id: [this.cardId, Validators.required],
      newName: [this.cardHolderName, Validators.required],
    });

  }

  HandleUpdateName() {
    this.submitted = true
    if (this.updateForm.invalid) {
      return;
    }
    let payload: any = {
      id: this.updateForm.value.id,
      name: this.updateForm.value.newName.toUpperCase(),
      previosName: this.updateForm.value.name,
    };


    this.spinner.getSpinner();
    this.service.updateCardName({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        $('#modifyModel').modal('hide');
        this.toastr.success(successResponse.records.message)
        this.getcardinfo(this.cardId)  // this.getPendingCardRecords()
        this.spinner.hideSpinner();
      } else {

        this.spinner.hideSpinner();
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.inputText = this.capitalizeWords(input.value);
  }
  capitalizeWords(str: string): string {
    return str.toUpperCase();
  }
  getCardEventLogs(cardId) {
    let payload: any = {
      cardid: cardId
    };
    this.spinner.getSpinner();
    this.service.getCardEventLogs({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.CardEventLogs = records;

      } else {
        this.spinner.hideSpinner();
        // this.toastr.warning(successResponse.records.message);
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getFailedCardTransactions(cardId, trackingNumber) {
    let payload: any = {
      cardid: cardId,
      trackingNumber: trackingNumber
    };
    this.spinner.getSpinner();
    this.service.getFailedCardTransactions({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.failedTransactions = records;

      } else {
        this.spinner.hideSpinner();
        // this.toastr.warning(successResponse.records.message);
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
}
