import { formatDate, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ExpressRequestService } from 'src/app/service/express-request/express-request.service';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'card-details',
  templateUrl: './view-card-report.component.html',
  styleUrls: ['./view-card-report.component.css'],
  animations: [
    fade
  ]
})
export class ViewCardDeatilsReportComponent implements OnInit {
  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_CASHIER = 40;
  _CASHIERTYPEID_POS = 1;
  _CASHIERTYPEID_STORE = 2;
  _CASHIERTYPEID_APIUSERS = 3;
  dataList;
  posTransectionsData;
  serviceForm: FormGroup;
  updateAccessForm: FormGroup;
  getUpdateForm: FormGroup;
  getReplacementForm: FormGroup;
  submitted = false;
  modal;
  getForm: FormGroup;
  customerId;
  mobileName;
  customerName;
  merchantId;
  queryMerchantId;
  merchantIdentifier;
  groupName;
  data;
  cardId;
  listBody: boolean;
  message;
  isThirtParty: boolean = false;
  accessProfileId;
  selectAccess;
  posDevicesList
  listData;
  isTrue = false;
  selected = 50;
  p: number = pagination.p;
  term;
  waafiCardList;
  walletId;
  mobileNumber;
  cardAccountList;
  merchInfo;
  accountId;
  cashierList;
  subscripForm: FormGroup;
  createdDate;
  distributorId;
  cardTransactionList;
  ListOfStations;
  amount;
  currentCardId;
  btnDisabled: boolean = true;
  trackingNumber;
  selectedCard;
  statusCardFromRemote;
  IsallowedCardReplacement = "false"
  cardStatusFromRemotePermission = "false"
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private _sessionStorageService: SessionStorageService,
    private expressRequestService: ExpressRequestService,
  ) {

    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.queryMerchantId = params['mobileNumber'];
        this.mobileNumber = params['mobileNumber'];
        this.customerName = params['customerName'];
        this.customerId = params['customerId'];
        this.mobileName = params['mobileNumber'];
        this.distributorId = params['distributorId'];
        this.getFrom();
        this.getReplacementsFrom()
        this.getUpdatesFrom();
        this.getWaafiCards(this.mobileNumber);
        const per = this._sessionStorageService.checkPermission('card_replacement');
        const card_status_from_remotePermission = this._sessionStorageService.checkPermission('card_status_from_remote');

        this.IsallowedCardReplacement = per
        this.cardStatusFromRemotePermission = card_status_from_remotePermission
      } else {
        _location.back();
      }
    });
    this.updatedAccessForm();
  }
  handleSubmit() {
    this.submitted = true;
    let startDate = this.getForm.value.startDate == undefined ? "" : this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate == undefined ? "" : this.getForm.value.endDate;
    let obj = { cardId: this.cardId, pStartDate: startDate, pStartEnd: endDate, pLastFourDigit: "", pNumber: "" }
    if (this.getForm.invalid) {
      return;
    }

    this.getCardTransactions(obj);
  }
  updatedAccessForm() {
    this.updateAccessForm = this.fb.group({
      selectedAccess: ['', Validators.required],
    });
  }
  backToService() {
    this._location.back();
  }
  getFrom() {
    let currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.getForm = this.fb.group({
      // endDate: [currentDate, Validators.required],
      // startDate: [currentDate, Validators.required],
      // pLastFourDigit: ['', Validators.required],
      pNumber: ['', Validators.required],
    });
  }
  generateRequestId(length = 16) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  checkPermission() {
    const myValue = localStorage.getItem('permission');
    alert(myValue)
    console.log(myValue);

  }
  getUpdatesFrom() {
    this.getUpdateForm = this.fb.group({
      amount: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  getReplacementsFrom() {
    this.getReplacementForm = this.fb.group({
      description: ['', Validators.required],
    });

  }
  selectedRecodrd(x) {
    this.getUpdateForm = this.fb.group({
      amount: [x.balance, Validators.required],
      description: ['', Validators.required],
    });
    this.currentCardId = x.cardId
    this.amount = x.balance
    this.mobileNumber = x.walletReference
  }
  Reverse() {
    this.submitted = true;
    const now = new Date();
    let timestamp = now.toISOString();

    // if (this.getUpdateForm.invalid) {
    //   return;
    // }

    let payload: any = {
      timestamp: timestamp,
      requestId: this.generateRequestId(),
      cardId: this.currentCardId,
      mobileNumber: this.mobileNumber,
      description: "Withdraw",
      amount: "0",
    };
    payload.cardId = payload.cardId.toString();
    payload.amount = payload.amount.toString();
    this.spinner.getSpinner();
    this.service.withDraw({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (successResponse.success) {
        this.toastr.success(successResponse.records.message);
        // this.toastr.success(`You have updated to ${successResponse.records.message}`, 'Successfully')
        $('#ReverseModel').modal('hide');
        this.spinner.hideSpinner();
        this.getWaafiCards(this.mobileNumber);

      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message, 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  ngOnInit(): void {
  }

  selectedRow(x) {
    if (x.isDeleted == "1") return this.toastr.error("This card is no longer available as it has been deleted")

    if (x.isBlocked == "1") {
      this.selectedCard = x.cardId
      $('#ReplacementModel').modal('show');
    } else {
      this.toastr.warning("Card are replaceable only if they are blocked")
    }
  }
  getUpdateInfo(data) {
    this.accessProfileId = data.accessProfileId;
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getUserAccessProfileStatus({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // console.log("data :=> ", records);
      this.selectAccess = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onUpdateAccessProfileInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateAccessForm.invalid) {
      return;
    }

    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.updateAccessForm.value.selectedAccess
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (data.accessProfileId) {
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        this.getMerchantInfo(this.merchantId);

      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  onUpdateAccessProfileInfoMerchant() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.serviceForm.invalid) {
      return;
    }

    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.serviceForm.value.selectedAccess
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (data.accessProfileId) {
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        this.getMerchantInfo(this.merchantId);


      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getMerchantInfo(merchantId) {
    let currentUser = this._sessionStorageService.getSessionData();
    this.listBody = false;
    let payload: any = {
      // userId: currentUser.userId,
      merchantId: merchantId

    };
    this.serviceForm = this.fb.group({
      userId: [currentUser.userId, Validators.required],
      userTitle: [currentUser.userTitle, Validators.required],
      selectedAccess: [currentUser.profileStatusId, Validators.required],
    });
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.data = data;

        this.merchantIdentifier = data.merchantIdentifier;


        this.createdDate = data.createdDate;

        this.accessProfileId = data.accessProfileId;
        this.groupName = data.groupName;


        if (data.merchantTypeId == 1) {
          this.isThirtParty = true;
        } else {
          this.isThirtParty = false;
        }
        console.log("kkkk", data)
      } else {
        this.toastr.error("Something went wrong")
        this.spinner.hideSpinner();
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getWaafiCards(mobileNumber) {

    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.distributorId,
      mobileNumber: mobileNumber
    };
    this.spinner.getSpinner();
    this.service.getCards({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.listData = records;


      } else {
        this.spinner.hideSpinner();
        this.listData = [];
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getCardTransactions(data) {

    // cardId: "292"
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.distributorId,
      cardId: data.cardId,
      pStartDate: data.pStartDate,
      pStartEnd: data.pStartEnd,
      pLastFourDigit: data.pLastFourDigit,
      pNumber: data.pNumber
    };
    this.spinner.getSpinner();
    this.service.getcardTransactions({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.cardTransactionList = records;

      } else {

        this.spinner.hideSpinner();
        this.cardTransactionList = []
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  hanldeCardReplacement() {
    this.submitted = true
    if (this.getReplacementForm.invalid) {
      return;
    }

    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.distributorId,
      cardId: this.selectedCard,
      mobileNumber: this.mobileNumber,
      description: this.getReplacementForm.value.description
    };
    this.spinner.getSpinner();
    this.service.cardReplacement({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        $('#ReplacementModel').modal('hide');
        this.getWaafiCards(this.mobileNumber);
      } else {
        this.spinner.hideSpinner();
        this.cardTransactionList = []
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  cardStatusFromRemote(row) {
    let payload: any = {
      cardId: row.cardId,
    };
    this.spinner.getSpinner();
    this.service.cardStatusFromRemote({ payload: payload }).subscribe(successResponse => {
      successResponse = this.expressRequestService.decryptData(successResponse);
      if (successResponse.success) {
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        $('#statusFromRemoteModel').modal('show');
        this.statusCardFromRemote = successResponse.records.message
      } else {
        this.statusCardFromRemote = successResponse.records.message
        $('#statusFromRemoteModel').modal('show');
        this.spinner.hideSpinner();
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      this.statusCardFromRemote = ""
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passMerchantCashiersView() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
  }

  viewPosInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_POS) {
      this.posListBody = true;
      return true;
    }
    this.listBody = true;
    this.message = "Data Not Found";
    return false;
  }

  viewWebStoreInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_STORE) {
      this.webStoreListBody = true;
      return true;
    }
    this.listBody = true;
    this.message = "Data Not Found";
    return false;
  }
  viewCardDetail = function (data) {
    // let startDate = this.getForm.value.startDate == undefined ? "" : this.getForm.value.startDate;
    // let endDate = this.getForm.value.endDate == undefined ? "" : this.getForm.value.endDate;
    let cardHolderName = data.embossingName;
    let cardId = data.cardId;
    let cardNumberMask = data.maskedPan;
    let cardStatus = data.cardStatus;
    let cardCreatedDate = data.validDate;
    let mobileNumber = data.walletReference;


    this.router.navigate(["dashboard/view/singleCardDetail"], { queryParams: { cardId, mobileNumber, distributorId: this.distributorId } })

    // let obj = { cardId: data.cardId, pStartDate: "", pStartEnd: "", pLastFourDigit: "", pNumber: "" }
    // this.cardId = data.cardId
    // this.getCardTransactions(obj);

  }

  viewApiUsersInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_APIUSERS) {
      this.apiListBody = true;
      return true;
    }
    return false;
  }


  OnCreateCashierUser() {
    let merchantId = this.data.merchantId;
    let merchantUid = this.data.merchantUid;
    this.router.navigate(["dashboard/view/createCashierInfo"], { queryParams: { merchantId, merchantUid } });
  }
  OnViewCashierUser(x) {
    let cashierId = x.cashierId;
    this.router.navigate(["dashboard/view/getCashierInfo"], { queryParams: { cashierId } });
  }
  OnViewReSendCredentials(x) {
    let cashierId = x.cashierId;
    this.router.navigate(["dashboard/view/getReSendCredentials"], { queryParams: { cashierId } });
  }


}
