<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">List Settlement Request</h4>
                    </div>
                    <div class="col-md-2" *ngIf="isTrue">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Settlement</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Settl Req Id</th>
                                <th>Description</th>
                                <th>Status Id</th>
                                <th>Amount</th>
                                <th>Account Id</th>
                                <th>Requested By User Id</th>
                                <th>Requested For User Id</th>
                                <th>Created Date</th>
                                <th>Action</th>
                                <th>Process</th>
                                <th>Rejects</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listBody">
                            <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td>{{ x.settlementRequestId}}</td>
                                <td>{{ x.description}}</td>
                                <td>{{ x.statusId}}</td>
                                <td>{{ x.amount}}</td>
                                <td>{{ x.accountId}}</td>
                                <td>{{ x.requestedByUserId}}</td>
                                <td>{{ x.requestedForUserId}}</td>
                                <td>{{ x.createdDate | date:'dd-MMM-yy - HH:mm:ss'}}</td>
                                <td (click)="passSettlementReqInfo(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                                <td (click)="passProcessSettlementInfo(x)" data-toggle="modal"
                                    data-target="#createModel">
                                    <button type="button" class="btn btn-outline-primary btn-sm">process</button>
                                </td>
                                <td (click)="passRejectSettlementInfo(x)" data-toggle="modal"
                                    data-target="#processModel">
                                    <button type="button" class="btn btn-outline-primary btn-sm">reject</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-md" id="createModel" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

    <div class="modal-dialog modal-sm-12" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Process Settlement</h4>
                            <form class="forms-sample" [formGroup]="serviceForm" novalidate
                                (ngSubmit)="OnProcessSettlement()">
                                <div class="card-subtitle">Process</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Settlement Request Id</label>
                                    <input type="text" class="form-control" formControlName="settlementRequestId"
                                        placeholder="Settlement Request Id" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.settlementRequestId.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.settlementRequestId.errors"
                                        class="text-danger">
                                        <div *ngIf="serviceForm.controls.settlementRequestId.errors.required">Settlement Request Id
                                            is required</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade bd-example-modal-md" id="processModel" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

    <div class="modal-dialog modal-sm-12" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Reject Settlement</h4>
                            <form class="forms-sample" [formGroup]="processForm" novalidate
                                (ngSubmit)="RejectSettlementRequest()">
                                <div class="card-subtitle">Process</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Settlement Request Id</label>
                                    <input type="text" class="form-control" formControlName="settlementRequestId"
                                        placeholder="Settlement Request Id" readonly [(ngModel)]="settlementRequestId"
                                        [ngClass]="{ 'is-invalid': submitted && processForm.controls.settlementRequestId.errors }">
                                    <div *ngIf="submitted && processForm.controls.settlementRequestId.errors"
                                        class="text-danger">
                                        <div *ngIf="processForm.controls.settlementRequestId.errors.required">Settlement Request Id
                                            is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Remarks</label>
                                    <input type="text" class="form-control" formControlName="remarks"
                                        placeholder="Remarks"
                                        [ngClass]="{ 'is-invalid': submitted && processForm.controls.remarks.errors }">
                                    <div *ngIf="submitted && processForm.controls.remarks.errors"
                                        class="text-danger">
                                        <div *ngIf="processForm.controls.remarks.errors.required">Remarks
                                            is required</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>