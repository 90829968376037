import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';



@Component({
  selector: 'app-payment-tran-summary-table',
  templateUrl: './payment-tran-summary-table.component.html',
  styleUrls: ['./payment-tran-summary-table.component.css'],
  animations: [
    fade
  ]
})
export class PaymentTranSummaryTableComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;

  merchantId;
  queryMerchantId;
  accountId;
  queryAccountId;
  queryStartTranId;
  queryEndTranId;
  startTranId;
  endTranId;
  startDate;
  queryStartDate;
  endDate;
  queryEndDate;
  payerId;
  queryPayerId;
  userId;
  queryuserId;

  queryuserDistributorId: any;
  sub: any;
  srcAccountId;

  listsData;
  sumDebit;
  sumCredit;
  balance;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.route.queryParams.subscribe(params => {
      this.queryMerchantId = params['merchantId'];
      this.queryStartDate = params['startDate'];
      this.queryEndDate = params['endDate'];
      this.queryStartTranId = params['startTranId'];
      this.queryEndTranId = params['endTranId'];
      this.queryAccountId = params['accountId'];
      this.queryuserId = params['userId'];
      this.merchantId = this.queryMerchantId;
      this.startDate = this.queryStartDate;
      this.endDate = this.queryEndDate;
      this.startTranId = this.queryStartTranId;
      this.endTranId = this.queryEndTranId;
      this.accountId = this.queryAccountId;
      this.userId = this.queryuserId;
      this.fnListPaymentTranSummaryReports(this.merchantId, this.startDate, this.endDate, this.startTranId, this.endTranId, this.accountId, this.userId)
    });

  }


  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }

  onPrintInvoice(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     window.location.reload();;
  }

  fnListPaymentTranSummaryReports(merchantId?, startDate?, endDate?, startTranId?, endTranId?, accountId?, userId?) {
 
    let payload: any = {
      userId: userId,
      accountId: accountId,
      startDate: startDate,
      endDate: endDate
    };
    if (this.merchantId != undefined) {
      payload.merchantId = this.merchantId
    }
    if (this.startTranId != "") {
      payload.startTranId = this.startTranId
    }
    if (this.endTranId != "") {
      payload.endTranId = this.endTranId
    }
     this.spinner.getSpinner();
    this.service.getPmtProcTransactionSummary(payload).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var record = JSON.parse(data.records);


      if (data.totalRecords != 0) {
        this.spinner.hideSpinner();
        var obj2 = {};
        var arr3: any = [];
        var records = [];
        var sumDebit = null;
        var sumCredit = null;
        var balance = null;
        record.forEach(el => {
          var record = {};
          if (el.tranTypeDesc) {
            sumDebit += el.totalDebit;
            sumCredit += el.totalCredit;
            balance += el.totalDebit - el.totalCredit;
          }
          if (obj2[el.tranTypeDesc]) {
            record = { status: el.tranStatusDesc, debit: el.totalDebit, credit: el.totalCredit, balance: el.totalDebit - el.totalCredit }
            obj2[el.tranTypeDesc].push(record);
          } else {
            record = { status: el.tranStatusDesc, debit: el.totalDebit, credit: el.totalCredit, balance: el.totalDebit - el.totalCredit }
            obj2[el.tranTypeDesc] = [];
            obj2[el.tranTypeDesc].push(record);
            arr3.push({ tranType: el.tranTypeDesc, records: obj2[el.tranTypeDesc] });
          }
        });
        

        this.sumDebit = sumDebit;
        this.sumCredit = sumCredit;
        this.balance = balance;

        this.listBody = true;
        this.listsData = arr3;
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.message = 'No Record'
        //console.log("No Record");
      };
    }, (failureResponse: any) => {
      if (failureResponse.userId== 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

}
