import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
declare var $: any;


@Component({
  selector: 'app-merchant-activity-table',
  templateUrl: './merchant-activity-table.component.html',
  styleUrls: ['./merchant-activity-table.component.css'],
  animations: [
    fade
  ]
})
export class MerchantActivityTableComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;

  merchantId;
  queryMerchantId;
  startDate;
  queryStartDate;
  endDate;
  queryEndDate;
  payerId;
  queryPayerId;
  statusId;
  queryStatusId;

  queryuserDistributorId: any;
  sub: any;
  srcAccountId;
  currency;
  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.route.queryParams.subscribe(params => {
      this.queryMerchantId = params['merchantId'];
      this.queryStartDate = params['startDate'];
      this.queryEndDate = params['endDate'];
      this.queryPayerId = params['payerId'];
      this.queryStatusId = params['statusId'];
      this.currency = params['currency'];
      this.merchantId = this.queryMerchantId;
      this.startDate = this.queryStartDate;
      this.endDate = this.queryEndDate;
      this.payerId = this.queryPayerId;
      this.statusId = this.queryStatusId;
      this.fnListTransactionHistoryWithMerchantId(this.merchantId, this.startDate, this.endDate, this.payerId, this.statusId, this.currency)
    });

  }


  ngOnInit(): void {
  }


  pageChange(event) {
    this.p = event;
    console.log("event => ", event + " selected => ", this.selected);
  }

  backToService() {
    this._location.back();
  }

  onPrintInvoice(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();;
  }

  fnListTransactionHistoryWithMerchantId(merchantId?, startDate?, endDate?, payerId?, statusId?, currency?) {

    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId,
      statusId: statusId,
      payerId: payerId,
      currency: currency,
      startDate: startDate,
      endDate: endDate
    };
    // this.getSpinner();
    if (this.payerId) {
      payload.payerId = this.payerId;
    } else {
      payload.payerId = "";
    }
    // this.payLoad = payload;
    // console.log("payload", this.payerId)

    if (merchantId != null) {
      payload.merchantId = merchantId;
      this.merchantId = merchantId;
    } else {
      payload.merchantId = this.merchantId;
      console.log(payload.merchantId);
    }
    this.spinner.getSpinner();
    this.service.getMerchantTranHistory({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;
          // console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

}
