import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-list-merchant-approvals',
  templateUrl: './list-merchant-approvals.component.html',
  styleUrls: ['./list-merchant-approvals.component.css'],
  animations: [
    fade
  ]
})
export class ListMerchantApprovalsComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message = 'Data Not Found';
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    

  }

  getFrom(){
    this.getForm = this.fb.group({
      selectData: ['', Validators.required],
    });
  }


  ngOnInit(): void {
    this.getUserLists();
    console.log("PPPPP=>", this.selectData)
  }



  getUserLists(){
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }



  passMerchantTableView(){
    let userTypeId = this.getForm.value.selectData
    this.router.navigate(["dashboard/view/merchantApprovals"], { queryParams: { userTypeId } })
  }

}
