<div @fade class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">POS Merchant Usage Summary Report</h4>
                <p class="card-description">
                    Usage Summary Report
                </p>
                <form class="forms-sample" [formGroup]="getForm" novalidate (ngSubmit)="passMerchantUsageSummaryReports()">

                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="selectedValue">DistributorId</label>
                                <select class="form-control-sm" name="distributorId" formControlName="distributorId"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.distributorId.errors }">
                                    <option [selected]="true" value=null disabled selected>--Select--</option>
                                    <option *ngFor="let c of dataList" [ngValue]="c.referenceId">{{c.userTitle}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && getForm.controls.distributorId.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.distributorId.errors?.required">
                                        Select Distributor is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="selectedValue">Currency</label>
                                <select class="form-control-sm" formControlName="selectedValue" [(ngModel)]="selectedData"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.selectedValue.errors }">
                                    <option *ngFor="let c of selectData" [ngValue]="c.currencyCode">{{c.currencyCode}}</option>
                                </select>
                                <div *ngIf="submitted && getForm.controls.selectedValue.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.selectedValue.errors?.required">
                                        Currency is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="startDate">Start Date</label>
                                <input type="date" class="form-control-sm" formControlName="startDate"
                                    placeholder="Start Date"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.startDate.errors }">
                                <div *ngIf="submitted && getForm.controls.startDate.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.startDate.errors?.required">
                                        Start Date is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="endDate">End Date</label>
                                <input type="date" class="form-control-sm" formControlName="endDate" placeholder="End Date"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.endDate.errors }">
                                <div *ngIf="submitted && getForm.controls.endDate.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.endDate.errors?.required">
                                        End Date is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 mt-2">
                            <div class="form-group mt-4">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>