import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from 'src/app/service/service/service.service';
import { SessionStorageService } from 'src/app/service/session-storage/session-storage.service';
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-create-cashier-merchant-approval',
  templateUrl: './create-cashier-merchant-approval.component.html',
  styleUrls: ['./create-cashier-merchant-approval.component.css'],
  animations: [
    fade
  ]
})
export class CreateCashierMerchantApprovalComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  _USER_TYPE_ID_CASHIER = 40;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  querymerchantId: any;
  sub: any;
  selectCycle;
  selectProccesor;

  selected = pagination.selected;
  p: number = pagination.p;
  term;
  dataLists;
  merchantList;
  cashierTypeList;
  selectedData;
  isApproved: boolean = false;
  validData = 'abc';
  addressList;



  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getUserAddress();
    this.getCashierTypeList();
    this.getMerchantPaymentProccessorInfo();
    this.updatedCycleForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.querymerchantId = +params['merchantId'];
        if (Number.isNaN(this.querymerchantId)) {
          this.merchantId = "";
        } else {
          this.merchantId = this.querymerchantId;
          console.log("HHHH => ", this.merchantId)
          this.getLiveCycleInfo(this.querymerchantId)
        }
      });
  }

  ngOnInit(): void {

  }

  getUserAddress() {
    this.service.getIpAddress().subscribe(res => {
      console.log(res);
      this.addressList = res;
      console.log(window.location.href);
    })
  }

  backToCashier() {
    this._location.back();
  }

  updatedCycleForm() {
    this.updateCycleForm = this.fb.group({
      merchantId: ['', Validators.required],
      merchantUid: ['', Validators.required],
      merchantName: ['', Validators.required],
      selectedCycle: ['', Validators.required],
      selectData: ['', Validators.required],
      paymentProcessor: ['', Validators.required],
      reson: ['', Validators.required],
    });
  }

  onOptionsSelected(event) {
    // console.log("|||||||||||||||====>", this.updateCycleForm.value.selectedCycle)
    if (this.updateCycleForm.value.selectedCycle.lifeCycleStatusId === 3) {
      this.isApproved = true;
      this.updateCycleForm = this.fb.group({
        selectedCycle: [this.updateCycleForm.value.selectedCycle, Validators.required],
        selectData: ['', Validators.required],
        paymentProcessor: ['', Validators.required],
        reson: ['', Validators.required],
        merchantId: [this.merchantList.merchantId, Validators.required],
        merchantUid: [this.merchantList.merchantUid, Validators.required],
        merchantName: [this.merchantList.userTitle, Validators.required],
      });
    } else {
      this.isApproved = false;
      this.updateCycleForm = this.fb.group({
        selectedCycle: [this.updateCycleForm.value.selectedCycle, Validators.required],
        selectData: [this.updateCycleForm.value.selectData ? this.updateCycleForm.value.selectData : 1, Validators.required],
        merchantId: [this.merchantList.merchantId, Validators.required],
        merchantUid: [this.merchantList.merchantUid, Validators.required],
        merchantName: [this.merchantList.userTitle, Validators.required],
        reson: ['', Validators.required],
      });
    }
    const value = event.target.value;
    // console.log("IS-AP=> ", this.updateCycleForm.value.selectData)
  }

  getLiveCycleInfo(merchantId) {
    this.merchantId = merchantId;
    this.getMerchantInfo(merchantId);
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getLiveCycleStatus({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      console.log("data :=> ", records[0]);
      this.selectCycle = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getMerchantPaymentProccessorInfo() {
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getPaymentProcessorList({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      console.log("data111 :=> ", records);
      this.selectProccesor = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  getMerchantInfo(merchantId) {
    console.log("YYYYY=>", merchantId)
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId,
    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      this.merchantList = data;
      this.updateCycleForm = this.fb.group({
        merchantId: [data.merchantId, Validators.required],
        merchantUid: [data.merchantUid, Validators.required],
        merchantName: [data.userTitle, Validators.required],
        selectedCycle: ['', Validators.required],
        selectData: ['', Validators.required],
        paymentProcessor: ['', Validators.required],
        reson: ['', Validators.required],
      });
      console.log("UUUU", data)

    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  getCashierTypeList() {
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getCashierTypeList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        // var newrecord: any = {};
        // newrecord.cashierTypeId = "0";
        // newrecord.description = "Not At All";
        // records.push(newrecord);
        this.dataLists = records;
        // this.cashierTypeList = ...records;      
        console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  changeUpdates() {
    this.submitted = true;
    if (this.updateCycleForm.invalid) {
      return;
    }
    if (this.updateCycleForm.value.selectedCycle.lifeCycleStatusId === 3) {
      this.OnCreateMerchantCashier(this.updateCycleForm.value.selectData.lifeCycleStatusId);
      this.onCreateMerchantPaymentProccesor();
      this.onUpdateLiveCycleInfo();
      this.fnCreateEventLogs(this.updateCycleForm.value.selectedCycle.lifeCycleStatus)
      this.submitted = false;
      this.updateCycleForm.reset();
    } else {
      this.onUpdateLiveCycleInfo();
      this.fnCreateEventLogs(this.updateCycleForm.value.selectedCycle.lifeCycleStatus);
      this.submitted = false;
      this.updateCycleForm.reset();
    }
  
  }



  onUpdateLiveCycleInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateCycleForm.invalid) {
      return;
    }
    let payload: any = {
      merchantId: this.merchantId,
      lifeCycleStatusId: this.updateCycleForm.value.selectedCycle.lifeCycleStatusId
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateLiveCycleStatus({ payload: payload }).subscribe(successResponse => {
      var responseObj = successResponse.records;
      var data = responseObj;
      // console.log("UUUU=>", successResponse) 
      if (data.merchantId) {
        this.toastr.success(`You have updated to ${data.merchantId}`, 'Successfully')
        $('#updateliveModel').modal('hide');
        this._location.back();
        this.spinner.hideSpinner();
      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  onChange($event) {
    console.log(this.updateCycleForm.value.selectData);
    // I want to do something here with the new selectedDevice, but what I
    // get here is always the last selection, not the one I just selected.
}
  OnCreateMerchantCashier(data) {
    var cashierType;
    if (data === 1) {
      cashierType = "POS_DEVICE";
    } else if (data === 2) {
      cashierType = "WEB_PORTAL";
    } else {
      cashierType = "API_USER";
    }
    //  console.log("HHHHHH=> ", payload)
    this.spinner.getSpinner();


    var currentUser = this._sessionStorageService.getSessionData();
    // let payload: any = {
    //   createdByUserId: currentUser.userId,
    //   userTypeId: this._USER_TYPE_ID_CASHIER,
    //   merchantId: this.merchantList.merchantId,
    //   firstName: this.merchantList.firstName,
    //   middleName: this.merchantList.middleName,
    //   lastName: this.merchantList.lastName,
    //   telephone: this.merchantList.telephone,
    //   address: this.merchantList.address,
    //   email: this.merchantList.email,
    //   userTitle: this.merchantList.userTitle,
    //   address2: this.merchantList.address2,
    //   website: this.merchantList.website,
    //   city: this.merchantList.city,
    //   country: this.merchantList.country,
    //   loginId: this.merchantList.loginId + 'Cashier',
    //   cashierTypeId: this.updateCycleForm.value.selectData
    // };
    let payload: any = {
      createdByUserId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_CASHIER,
      merchantId: this.merchantList.merchantId,
      firstName: this.merchantList.firstName,
      middleName: this.merchantList.middleName,
      lastName: this.merchantList.lastName,
      telephone: this.merchantList.telephone,
      address: this.merchantList.address,
      email: this.merchantList.email,
      userTitle: this.merchantList.userTitle,
      address2: this.merchantList.address2,
      website: this.merchantList.website,
      city: this.merchantList.city,
      country: this.merchantList.country,
      loginId: this.merchantList.loginId + 'Cashier',
      // loginPwd: this.merchantList.password,
      cashierTypeId: this.updateCycleForm.value.selectData
    };
    console.log("HHHHHH=> 4444 ", payload)
    this.spinner.getSpinner();
    this.service.createCashier(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if(successResponse){
        this.spinner.hideSpinner();
        this.toastr.success(`You have created`, 'Seccessfully')
        this._location.back();
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse.error.message.error.message));
        this.toastr.error(failureResponse.error.message.error.message)
    });
  }



  onCreateMerchantPaymentProccesor(){
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateCycleForm.invalid) {
      return;
    }
    let payload: any = {
      merchantId: this.merchantList.merchantId,
      paymentProccessorId: this.updateCycleForm.value.paymentProcessor.paymentProcessorId,
      isEnabled: 1,
      createdByUserId: currentUser.userId
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.createMerchantPmtProcessor({ payload: payload }).subscribe(successResponse => {
      var responseObj = successResponse.records;
      var data = responseObj;
      // console.log("UUUU=>", successResponse) 
      if (successResponse.success) {
        this.toastr.success(`You have updated payment Id is ${data.seqId}`, 'Successfully')
        $('#updateliveModel').modal('hide');
        this.spinner.hideSpinner();
        // this.getMerchantUserList(this.userTypeId);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(data.error, 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  fnCreateEventLogs(selectData) {
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: this.merchantList.userId,
      eventTypeId: 4,
      previousValue: this.selectCycle[0].lifeCycleStatus,
      newValue: selectData + " to (" + this.updateCycleForm.value.paymentProcessor.paymentProcessorTypeDesc +")",
      reson: this.updateCycleForm.value.reson,
      sessionId: currentUser.referenceId,
      address: JSON.stringify(this.addressList),
      ipAddress: this.addressList,
      applicationId: currentUser.userTypeId,
      doneByUserId: currentUser.userId,
    };
    console.log("jajaHHH", payload)
    this.service.createEventLogs({ payload: payload }).subscribe((res) => {
      console.log(res)

    })
    console.log(payload)
  }

}
