import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common';
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-cashier-activation',
  templateUrl: './cashier-activation.component.html',
  styleUrls: ['./cashier-activation.component.css'],
  animations: [
    fade
  ]
})

export class CashierActivationComponent implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();

  }

  getFrom(){
    this.getForm = this.fb.group({
      cashierId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  passMerchantActivation(){
    this.submitted = true;
    if(this.getForm.invalid){
      return;
    }
    let cashierId = this.getForm.value.cashierId;
    this.router.navigate(["dashboard/view/getCashierActivationInfo"], { queryParams: { cashierId }});
  }

}
