<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">All Route Settings</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="passCreation()">Create Route</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Route Id</th>
                                <th>Route Prefix</th>
                                <th>Issuer Id</th>
                                <th>Currency</th>
                                <th>Is Active</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td class="py-1">{{x.routeId}}</td>
                                <td>{{x.routePrefix}}</td>
                                <td>{{x.bankId}}</td>
                                <td>{{x.currencyCode}}</td>
                                <td>{{x.isActive}}</td>
                                <td (click)="passUpdated(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <!-- <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="createModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Create Route</h4>
                            <form class="forms-sample" [formGroup]="serviceForm" novalidate  (ngSubmit)="OnCreateRoutesConfig()">
                                <div class="card-subtitle">Route Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                                <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Route Prefix</label>
                                                <input type="text" class="form-control"  formControlName="routePrefix" placeholder="Route Prefix"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.routePrefix.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.routePrefix.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.routePrefix.errors.required">Route Prefix    is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="isActive">Is Active</label>
                                            <select class="form-control" formControlName="isActive" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.isActive.errors }">
                                                <option [ngValue]="1" selected>Yes</option>
                                                <option [ngValue]="0">No</option>
                                            </select>   
                                            <div *ngIf="submitted && serviceForm.controls.isActive.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.isActive.errors.required">IsActive is required</div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="isActive">Bank Id</label>
                                                <select class="form-control" formControlName="selectedBank" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedBank.errors }">
                                                    <option *ngFor="let p of selectBankData" [ngValue]="p.bankId">{{p.bankName}}</option>
                                                </select>
                                                <div *ngIf="submitted && serviceForm.controls.selectedBank.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.selectedBank.errors.required">Charge Mode Id is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="isActive">Currency Code</label>
                                                    <select class="form-control" formControlName="currencyId">
                                                        <option [ngValue]="1" selected>USD</option>
                                                        <option [ngValue]="2">EBR</option>
                                                        <option [ngValue]="3">SSH</option>
                                                        <option [ngValue]="4">EUR</option>
                                                        <option [ngValue]="5">SLSH</option>
                                                    </select>
                                                    <div *ngIf="submitted && serviceForm.controls.currencyId.errors" class="text-danger">
                                                        <div *ngIf="serviceForm.controls.currencyId.errors.required">Currency Code is required</div>
                                                    </div>
                                            </div>
                                        </div>
                                       
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade bd-example-modal-md" id="viewModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">View Route</h4>
                            <form class="forms-sample" [formGroup]="getForm" novalidate  (ngSubmit)="updateRoutesConfig()">
                                <div class="card-subtitle">Route Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                                <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputUsername1">Route Id</label>
                                                <input type="text" class="form-control" formControlName="routeId" placeholder="Route Ide" readonly
                                                [ngClass]="{ 'is-invalid': submitted && getForm.controls.routeId.errors }">
                                                <div *ngIf="submitted && getForm.controls.routeId.errors" class="text-danger">
                                                    <div *ngIf="getForm.controls.routeId.errors.required">Route Id is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Route Prefix</label>
                                                <input type="text" class="form-control"  formControlName="routePrefix" placeholder="Route Prefix"
                                                [ngClass]="{ 'is-invalid': submitted && getForm.controls.routePrefix.errors }">
                                                <div *ngIf="submitted && getForm.controls.routePrefix.errors" class="text-danger">
                                                    <div *ngIf="getForm.controls.routePrefix.errors.required">Route Prefix    is required</div>
                                                </div>
                                            </div>
                                        </div>                                    
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="isActive">Is Active</label>
                                        <select class="form-control" formControlName="isActive" [ngClass]="{ 'is-invalid': submitted && getForm.controls.isActive.errors }">
                                            <option [ngValue]="1" selected>Yes</option>
                                            <option [ngValue]="0">No</option>
                                        </select>   
                                        <div *ngIf="submitted && getForm.controls.isActive.errors" class="text-danger">
                                            <div *ngIf="getForm.controls.isActive.errors.required">IsActive is required</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="isActive">Bank Id</label>
                                            <select class="form-control" formControlName="selectedBank" [ngClass]="{ 'is-invalid': submitted && getForm.controls.selectedBank.errors }">
                                                <option *ngFor="let p of selectBankData" [ngValue]="p.bankId">{{p.bankName}}</option>
                                            </select>
                                            <div *ngIf="submitted && getForm.controls.selectedBank.errors" class="text-danger">
                                                <div *ngIf="getForm.controls.selectedBank.errors.required">Charge Mode Id is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Currency Code</label>
                                            <input type="text" class="form-control" formControlName="currencyCode" placeholder="Route Ide"
                                            [ngClass]="{ 'is-invalid': submitted && getForm.controls.currencyCode.errors }">
                                            <div *ngIf="submitted && getForm.controls.currencyCode.errors" class="text-danger">
                                                <div *ngIf="getForm.controls.currencyCode.errors.required">Currency Code is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Currency Description</label>
                                            <input type="text" class="form-control"  formControlName="currencyDescription" placeholder="Route Prefix"
                                            [ngClass]="{ 'is-invalid': submitted && getForm.controls.currencyDescription.errors }">
                                            <div *ngIf="submitted && getForm.controls.currencyDescription.errors" class="text-danger">
                                                <div *ngIf="getForm.controls.currencyDescription.errors.required">Currency Description is required</div>
                                            </div>
                                        </div>
                                    </div>                                    
                            </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->