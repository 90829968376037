import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css'],
  animations: [
    fade
  ]
})
export class PartnerComponent implements OnInit {

  _USER_TYPE_ID_PAYMENTPROCESSOR = 50;
  dataListByBartner;

  selected = pagination.selected;
  p: number = pagination.p;
  term;
  listBody: boolean = false;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getUserListsByBartner();
  }

  ngOnInit(): void {
  }

  passCreateView() { }

  getUserListsByBartner() {
    let userTypeId = 0;
    let payload: any = {
      userTypeId: this._USER_TYPE_ID_PAYMENTPROCESSOR
    };
    this.spinner.getSpinner();
    this.service.getUserList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataListByBartner = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passRoleIdValue(user) {
    let userId = user.userId;
    this.router.navigate(['dashboard/assignUserRoles'], { queryParams: { userId } });
  }
  passUserInfo(user) {
    let userId = user.userId;
    this.router.navigate(['dashboard/view/userInfo'], { queryParams: { userId } });
  }
  enableOrDisableTwoFactorAuth(data) {
    let payload: any = {
      ptwo_factor_enabled: data.isTwoFactorEnabled === 1 ? 0 : 1,
      PAccessProfileId: data.accessProfileId,
    };

    this.spinner.getSpinner();
    this.service.enableOrDisableTwoFactorAuth({ payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        this.getUserListsByBartner();
      } else {
        this.toastr.error(successResponse.records.message)
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

}
