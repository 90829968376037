import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-merchant-activity-summary-by-day',
  templateUrl: './merchant-activity-summary-by-day.component.html',
  styleUrls: ['./merchant-activity-summary-by-day.component.css'],
  animations: [
    fade
  ]
})
export class MerchantActivitySummaryByDayComponent implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedValue;
  selectGroupData;
  
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getCurrencyList();
    this.fnGetTranTypeGroupList();
  }

  getFrom(){
    this.getForm = this.fb.group({
      merchantId: ['', Validators.required],
      selectedValue: ['', Validators.required],
      selectedGroupValue: ['', Validators.required],
      startDate: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  getCurrencyList(){
    let payload:any = {};

    this.service.getCurrencyList({payload:payload}).subscribe((res) => {
      try {
        if(!res){
          console.log("Errer")
        }else{
          let resObj = JSON.stringify(res.records)
          let record = JSON.parse(resObj)
          this.selectData = record;
          console.log("Data==>", record)
        }
      } catch (error) {
        console.log("Errer", error)
      }
    })
  }

  fnGetTranTypeGroupList(){
    let payload:any = {};

    this.service.getTranTypeGroupList({payload:payload}).subscribe((res) => {
      try {
        if(!res){
          console.log("Errer")
        }else{
          let resObj = JSON.stringify(res.records)
          let record = JSON.parse(resObj)
          let data = JSON.parse(record)
          this.selectGroupData = data;
          console.log("Data==>", data)
        }
      } catch (error) {
        console.log("Errer", error)
      }
    })
  }

  passMerchantActivityByDayReports(){
    this.submitted= true;

    if(this.getForm.invalid) return;

    let merchantId = this.getForm.value.merchantId;
    let tranTypeGroup = this.getForm.value.selectedGroupValue;
    let currencyId = this.getForm.value.selectedValue;
    let startDate = this.getForm.value.startDate;
    this.router.navigate(["dashboard/view/merchantActivitySummaryByDay"], { queryParams: { merchantId, tranTypeGroup, startDate, currencyId }})
  }

}
