import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';

@Component({
  selector: 'app-paymentprocessor',
  templateUrl: './paymentprocessor.component.html',
  styleUrls: ['./paymentprocessor.component.css'],
  animations: [
    fade
  ]
})
export class PaymentprocessorComponent implements OnInit {
  dataList;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  modal;
  selectServiceChargeModeData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    
  }
  

  ngOnInit(): void {
    this.getAllPaymentProcessors();
    // this.getPaymentProcessorType();
    // this.getPaymentProcessorSystem();
  }

  getAllPaymentProcessors(){
    let payload: any = {};
    this.service.getAllPaymentProcessors({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  // viewPaymentProcessorInfo(paymentProcessor){
  //   let payload: any = {
  //     paymentProcessorId: paymentProcessor.paymentProcessorId,
  //   };
  //   this.service.getPaymentProcessorInfo({payload: payload}).subscribe(successResponse => {
  //     if(successResponse.records){
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       this.updatesForm = this.fb.group({
  //         firstName: [data.firstName, Validators.required],
  //         middleName: [data.middleName, Validators.required],
  //         lastName: [data.lastName, Validators.required],
  //         email: [data.email, [Validators.required, Validators.email]],
  //         telephone: [data.telephone, Validators.required],
  //         address: [data.address, Validators.required],
  //         userTitle: [data.userTitle, Validators.required],
  //         address2: [data.address2, Validators.required],
  //         website: [data.website, Validators.required],
  //         city: [data.city, Validators.required],
  //         country: [data.country, Validators.required],
  //         paymentProcessorTypeId: [data.paymentProcessorTypeId, Validators.required],
  //         systemId: [data.systemId, Validators.required]
  //       });
  //       // console.log("kkkk", records)
  //     }else {
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }
  // getPaymentProcessorType(){
  //   let payload: any = {};
  //   this.service.getPmtProcessorTypes({payload: payload}).subscribe(successResponse => {
  //     if(successResponse.records){
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       var records = JSON.parse(data);
  //       this.selectProcessorTypeData =records;
  //       // console.log("kkkk", records)
  //     }else {
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }
  // getPaymentProcessorSystem(){
  //   let payload: any = {};
  //   this.service.getPmtProcessorSystemList({payload: payload}).subscribe(successResponse => {
  //     if(successResponse.records){
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       var records = JSON.parse(data);
  //       this.selectPaymentProcessorSystemData =records;
  //       // console.log("kkkk", records)
  //     }else {
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }

  passPaymentProccess(x) {
    let paymentProcessorId = x.paymentProcessorId;
    this.router.navigate(['dashboard/view/listPaymentInfo'], { queryParams: { paymentProcessorId } });
  }
  passPaymentProccessCreate() {
    this.router.navigate(['dashboard/view/createPaymentInfo']);
  }


  resetForm(){

  }

 
  

}
