import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup,  FormBuilder, FormArray, FormControl,  Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-assignwebpermission',
  templateUrl: './assignwebpermission.component.html',
  styleUrls: ['./assignwebpermission.component.css'],
  animations: [
    fade
  ]
})
export class AssignwebpermissionComponent implements OnInit {
  queryRoleId: number;
  roleId: any;
  dataRecords: any;
  sub: Subscription;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  selectBankData;
  selectServiceChargeModeData;
  // dataSet = [{}]
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryRoleId = +params['roleId'];
        if (Number.isNaN(this.queryRoleId)) {
          this.roleId = "";
        } else {
          this.roleId = this.queryRoleId;
          this.viewRoleConfig(this.roleId);
        }
        //this.fnListTransactionHistoryWithMerchantId(this.queryMerchantId);
      });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      roleId: ['', Validators.required ],
      roleName: ['', Validators.required ],
      description: ['', Validators.required ],
      selectedRecord: new FormArray([])
    });
  }

  ngOnInit(): void {
  }


  backtowebrole(){
    this.router.navigate(['dashboard/listUserRole']);
  }

  viewRoleConfig(roleId){
    var permissionIds;
    let payload: any = {
      roleId: roleId,
    };
    this.spinner.getSpinner();
    this.service.getWebRoleInfo({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        if (data.rolePermissions != "") {
          permissionIds = JSON.parse(data.rolePermissions);
        } else {
          this.spinner.hideSpinner();
          permissionIds = [];
        }
        this.getWebPermissionsList(permissionIds, data.roleId, data.roleName, data.description);
        // console.log("kkkk", data)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
    
    
  }

  getWebPermissionsList(rolePermissions?, roleId?, roleName?, description?){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getPermissionSettings({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        // console.log("88888888", JSON.parse(data))
        this.dataRecords = records;
        let controls = this.dataRecords.map(c => new FormControl(false));
        rolePermissions.forEach(element => {
          controls[element - 1].setValue(true);
        });
        // var records = JSON.parse(data);
        this.serviceForm = this.fb.group({
          roleId: [roleId, Validators.required ],
          roleName: [roleName, Validators.required ],
          description: [description, Validators.required ],
          dataRecords: new FormArray(controls)
        });
        
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
    
  }

  assignWebPermissions(){
    var permissionArray = [];
    var permissionArrayString;
    if (this.serviceForm.invalid) {
      return;
    }
    this.serviceForm.value.dataRecords.forEach((element, i) => {

      if (element == true) {
        var orignalValue = i + 1;
        permissionArray.push(orignalValue);
      }
    });
    permissionArrayString = this.convertToStringArray(permissionArray);
    let payload: any = {
      roleId: this.serviceForm.value.roleId,
      permissions: permissionArrayString
    };
    this.spinner.getSpinner();
    this.service.assignWebPermissions(payload).subscribe(successResponse => {
      if(successResponse){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse);
        var data = JSON.parse(responseObj);
        // var records = JSON.parse(data);
        this.toastr.success(`Successfully assigned roleId ${data.roleId}`)
        console.log("88888888", responseObj)
        this.router.navigate(['dashboard/listUserRole']);
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner()
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });    

  }

  convertToStringArray(obj) {
    var arr = "[";
    arr += obj + "]";
    return arr;
  }

}
