<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ Title }}</h4>
          </div>
        </div>

        <form
          [formGroup]="getForm"
          class="forms-sample"
          novalidate
          *ngIf="showForm === 'true'"
          (ngSubmit)="PassViewReport()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="mobileNumber">Mobile Number</label>
                <input
                  type="text"
                  class="form-control-sm"
                  formControlName="mobileNumber"
                  placeholder="Mobile Number"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.mobileNumber.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.mobileNumber.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.mobileNumber.errors.required">
                    Mobile Number is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="startDate">Start Date (Optional) </label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="startDate"
                  placeholder="Start Date "
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.startDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.startDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.startDate.errors?.required">
                    Start Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="startDate">End Date (Optional)</label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="endDate"
                  placeholder="End Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.endDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.endDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.endDate.errors?.required">
                    End Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
