<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">All Payment Processors</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="passPaymentProccessCreate()">Create Payment</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Payment Processor Id</th>
                                <th>Title</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>AccountId</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td class="py-1">{{x.paymentProcessorId}}</td>
                                <td>{{ x.userTitle}}</td>
                                <td>{{ x.profileStatusDesc}}</td>
                                <td>{{ x.paymentProcessorTypeDesc}}</td>
                                <td>{{ x.accountId}}</td>
                                <td (click)="passPaymentProccess(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>  
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <!-- <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" id="viewModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">View Payment Processor Info</h4>
                            <form class="forms-sample" [formGroup]="updatesForm" novalidate
                                (ngSubmit)="OnUpdatePaymentProcessorInfo()">
                                <div class="card-subtitle">Access Profile</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">User Title</label>
                                            <input type="text" class="form-control" formControlName="userTitle"
                                                placeholder="user Title"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.userTitle.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.userTitle.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.userTitle.errors.required">userTitle is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Payment Processor Type</label>
                                            <select class="form-control" formControlName="paymentProcessorTypeId" [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.paymentProcessorTypeId.errors }">
                                                <option *ngFor="let p of selectProcessorTypeData" [ngValue]="p.paymentProcessorTypeId">{{p.description}}</option>
                                              </select>
                                            <div *ngIf="submitted && updatesForm.controls.paymentProcessorTypeId.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.paymentProcessorTypeId.errors.required">Description is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Payment Processor System</label>
                                            <select class="form-control" formControlName="systemId" [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.systemId.errors }">
                                                <option *ngFor="let p of selectPaymentProcessorSystemData" [ngValue]="p.systemId">{{p.systemName}}</option>
                                              </select>
                                            <div *ngIf="submitted && updatesForm.controls.systemId.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.systemId.errors.required">System Id is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-subtitle">Profile Info</div>
                                <div
                                    style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">First Name</label>
                                            <input type="text" class="form-control" formControlName="firstName"
                                                placeholder="First Name"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.firstName.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.firstName.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.firstName.errors.required">First Name
                                                    is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Middle Name</label>
                                            <input type="text" class="form-control" formControlName="middleName"
                                                placeholder="Middle Name"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.middleName.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.middleName.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.middleName.errors.required">Middle Name
                                                    is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Last Name</label>
                                            <input type="text" class="form-control" formControlName="lastName"
                                                placeholder="Last Name"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.lastName.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.lastName.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.lastName.errors.required">Last Name is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Email</label>
                                            <input type="email" class="form-control" formControlName="email"
                                                placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.email.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.email.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.email.errors.required">Email is
                                                    required</div>
                                                <div *ngIf="updatesForm.controls.email.errors.email">It`s Not An Email
                                                    Plz Check</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Contact Number</label>
                                            <input type="number" class="form-control" formControlName="telephone"
                                                placeholder="Contact Number"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.telephone.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.telephone.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.telephone.errors.required">Contact
                                                    Number is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Website</label>
                                            <input type="text" class="form-control" formControlName="website"
                                                placeholder="Website"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.website.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.website.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.website.errors.required">Website is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address Line One</label>
                                            <input type="text" class="form-control" formControlName="address"
                                                placeholder="Address One"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.address.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.address.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.address.errors.required">Address One is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address Line Two</label>
                                            <input type="text" class="form-control" formControlName="address2"
                                                placeholder="Address Two"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.address2.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.address2.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.address2.errors.required">Address Two
                                                    is required</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">City</label>
                                            <input type="text" class="form-control" formControlName="city"
                                                placeholder="City"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.city.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.city.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.city.errors.required">City is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Country</label>
                                            <input type="text" class="form-control" formControlName="country"
                                                placeholder="Country"
                                                [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.country.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.country.errors"
                                                class="text-danger">
                                                <div *ngIf="updatesForm.controls.country.errors.required">Country is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Update</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="modal fade" id="createModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Create Payment Processor Info</h4>
                            <form class="forms-sample" [formGroup]="serviceForm" novalidate
                                (ngSubmit)="OnCreatePaymentProcessor()">
                                <div class="card-subtitle">Profile</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Payment Processor Type</label>
                                            <select class="form-control" formControlName="paymentProcessorTypeId" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.paymentProcessorTypeId.errors }">
                                                <option *ngFor="let p of selectProcessorTypeData" [ngValue]="p.paymentProcessorTypeId">{{p.description}}</option>
                                              </select>
                                            <div *ngIf="submitted && serviceForm.controls.paymentProcessorTypeId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.paymentProcessorTypeId.errors.required">Description is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Payment Processor System</label>
                                            <select class="form-control" formControlName="systemId" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.systemId.errors }">
                                                <option *ngFor="let p of selectPaymentProcessorSystemData" [ngValue]="p.systemId">{{p.systemName}}</option>
                                              </select>
                                            <div *ngIf="submitted && serviceForm.controls.systemId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.systemId.errors.required">System Name is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-subtitle">Profile Info</div>
                                <div
                                    style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">First Name</label>
                                            <input type="text" class="form-control" formControlName="firstName"
                                                placeholder="First Name"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.firstName.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.firstName.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.firstName.errors.required">First Name
                                                    is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Middle Name</label>
                                            <input type="text" class="form-control" formControlName="middleName"
                                                placeholder="Middle Name"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.middleName.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.middleName.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.middleName.errors.required">Middle Name
                                                    is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Last Name</label>
                                            <input type="text" class="form-control" formControlName="lastName"
                                                placeholder="Last Name"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.lastName.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.lastName.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.lastName.errors.required">Last Name is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Email</label>
                                            <input type="email" class="form-control" formControlName="email"
                                                placeholder="Email"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.email.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.email.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.email.errors.required">Email is
                                                    required</div>
                                                <div *ngIf="serviceForm.controls.email.errors.email">Email must be a valid email</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Contact Number</label>
                                            <input type="number" class="form-control" formControlName="telephone"
                                                placeholder="Contact Number"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.telephone.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.telephone.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.telephone.errors.required">Contact
                                                    Number is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Website</label>
                                            <input type="text" class="form-control" formControlName="website"
                                                placeholder="Website"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.website.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.website.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.website.errors.required">Website is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address Line One</label>
                                            <input type="text" class="form-control" formControlName="address"
                                                placeholder="Address One"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.address.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.address.errors.required">Address One is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address Line Two</label>
                                            <input type="text" class="form-control" formControlName="address2"
                                                placeholder="Address Two"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address2.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.address2.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.address2.errors.required">Address Two
                                                    is required</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">City</label>
                                            <input type="text" class="form-control" formControlName="city"
                                                placeholder="City"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.city.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.city.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.city.errors.required">City is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Country</label>
                                            <input type="text" class="form-control" formControlName="country"
                                                placeholder="Country"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.country.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.country.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.country.errors.required">Country is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-subtitle">Access Profile</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                                <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputUsername1">User Title</label>
                                                <input type="text" class="form-control" formControlName="userTitle" placeholder="user Title"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userTitle.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.userTitle.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.userTitle.errors.required">userTitle is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Login Id</label>
                                                <input type="text" class="form-control"  formControlName="loginId" placeholder="Login ID"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.loginId.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.loginId.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.loginId.errors.required">Login Id is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="isActive">Password</label>
                                                <input type="password" class="form-control"  formControlName="password" placeholder="Password"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.password.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.password.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.password.errors.required">Password is required</div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->