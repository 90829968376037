<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-11">
                        <h4 class="card-title">Process Settlement Invoice</h4>
                    </div>
                    <div class="col-md-1">
                        <!-- <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backtowebrole()">Back</button>
                        </h4> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate (ngSubmit)="OnProcessInvoice()">
                            <div class="card-subtitle">Settlement Invoice</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Invoice No</label>
                                        <input type="number" class="form-control-sm" formControlName="invoiceNo"
                                            placeholder="Invoice No"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.invoiceNo.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.invoiceNo.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.invoiceNo.errors.required">Invoice No is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="isActive">Settled Date</label>
                                        <input type="date" class="form-control-sm" formControlName="settledDate"
                                            placeholder="Settled Date"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.settledDate.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.settledDate.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.settledDate.errors.required">Settled Date
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="isActive">Settled Amount</label>
                                        <input type="text" class="form-control-sm" formControlName="invoiceAmount"
                                            placeholder="Settled Amount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.invoiceAmount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.invoiceAmount.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.invoiceAmount.errors.required">Settled
                                                Amount is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Vendor Ref</label>
                                        <input type="text" class="form-control-sm" formControlName="vendorRef"
                                            placeholder="Vendor Ref"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.vendorRef.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.vendorRef.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.vendorRef.errors.required">Vendor Ref is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="isActive">Remarks</label>
                                        <input type="text" class="form-control-sm" formControlName="remarks"
                                            placeholder="Remarks"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.remarks.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.remarks.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.remarks.errors.required">Remarks is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-primary" style="width: 60%; margin-top: 13%;">Process</button>
                                    </div>
                                </div>
                              
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Vendor Ref</label>
                                        <input type="text" class="form-control-sm" formControlName="vendorRef"
                                            placeholder="Vendor Ref"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.vendorRef.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.vendorRef.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.vendorRef.errors.required">Vendor Ref is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="isActive">Remarks</label>
                                        <input type="text" class="form-control-sm" formControlName="remarks"
                                            placeholder="Remarks"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.remarks.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.remarks.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.remarks.errors.required">Remarks is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Process</button>
                                </div>
                            </div> -->
                            <!-- <button type="submit" class="btn btn-primary mr-2">Process</button> -->
                            <!-- <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>