import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.css'],
  animations: [
    fade
  ]
})
export class CreateServiceComponent implements OnInit {
  queryUserId: number;
  userId: any;
  dataRecords: any;
  sub: Subscription;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  selectServiceChargeModeData;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getServiceChargeMode();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryUserId = +params['userId'];
        if (Number.isNaN(this.queryUserId)) {
          this.userId = "";
        } else {
          this.userId = this.queryUserId;
        }
      });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      serviceCode: ['', Validators.required],
      serviceDesc: ['', Validators.required],
      isActive: ['', Validators.required],
      serviceChargeModeId: ['', Validators.required],
      isTransactional: ['', Validators.required],
      serviceProfileDesc: ['', Validators.required],
      minInTxAmount: ['', Validators.required],
      maxInTxAmount: ['', Validators.required],
      minOutTxAmount: ['', Validators.required],
      maxOutTxAmount: ['', Validators.required],
      dailyInTxLimit: ['', Validators.required],
      dailyOutTxLimit: ['', Validators.required],
      monthlyInTxLimit: ['', Validators.required],
      monthlyOutTxLimit: ['', Validators.required],
    });
  }


  ngOnInit(): void {
  }

  getServiceChargeMode(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getListServiceChargeModes({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectServiceChargeModeData =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  OnCreateService() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
    let payload: any = {
      createdByUserId: this.userId,
      serviceProfileDesc: this.serviceForm.value.serviceProfileDesc,
      serviceChargeModeId: this.serviceForm.value.serviceChargeModeId,
      serviceCode: this.serviceForm.value.serviceCode,
      serviceDesc: this.serviceForm.value.serviceDesc,
      isActive: this.serviceForm.value.isActive,
      isTransactional: this.serviceForm.value.isTransactional,
      minInTxAmount: this.serviceForm.value.minInTxAmount,
      minOutTxAmount: this.serviceForm.value.minOutTxAmount,
      maxInTxAmount: this.serviceForm.value.maxInTxAmount,
      maxOutTxAmount: this.serviceForm.value.maxOutTxAmount,
      dailyInTxLimit: this.serviceForm.value.dailyInTxLimit,
      dailyOutTxLimit: this.serviceForm.value.dailyOutTxLimit,
      monthlyInTxLimit: this.serviceForm.value.monthlyInTxLimit,
      monthlyOutTxLimit: this.serviceForm.value.monthlyOutTxLimit
    };
    this.spinner.getSpinner();
    this.service.createServiceConfig(payload).subscribe(successResponse => {

      if (successResponse.id) {
        $('#createModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success(`You have created Id ${successResponse.id}`,'Seccessfully')
        this.router.navigate(['dashboard/listServiceConfig'])
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  backToService() {
    this._location.back()
  }

}
