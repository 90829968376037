import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ExcelService } from 'src/app/service/service/excel.service';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-top-usage-by-customers-dist.component',
  templateUrl: './top-usage-by-customers-dist.component.html',
  styleUrls: ['./top-usage-by-customers-dist.component.css'],
  animations: [
    fade
  ]
})
export class TopUsageByCustomersDistComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  Title;
  lisCustomers;
  ListOfMethods;
  currentRecordId;
  currentCardId;
  selected = 1000;
  // selected = pagination.selected;
  p: number = pagination.p;
  term;
  currentMobileNumber;
  numberOfdays;
  showForm: string = 'true';
  institutionId;
  distdataList;
  sumDeclined = null;
  sumApproved = null;
  sumUniqueMerchants = null;
  sumTransactionCount = null;
  sumTransactionAmount = null;
  sortDirection: boolean = true;
  currentSortField: string;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    // this.showForm = true
    this.getDistributorList();
    this.getFrom();
    this.numberOfdays = [{ id: 1, name: "Today" }, { id: 3, name: "Last 3 Days" }, { id: 7, name: "Last 7 Days" }, { id: 30, name: "Last 30 Days" }]
  }
  getFrom() {
    this.getForm = this.fb.group({
      selectedValue: ['', Validators.required],
      selectDistributor: ['', Validators.required],
    });
  }
  getDistributorList() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distdataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  // getInstitution() {
  //   const currentUser = this._sessionStorageService.getSessionData();
  //   let payload: any = {
  //     // distributorId: this.distributorId
  //     distributorId: currentUser.referenceId,
  //   };
  //   this.spinner.getSpinner();
  //   this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
  //     if (successResponse.records) {
  //       this.spinner.hideSpinner();
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       this.institutionId = data.institutionId
  //       // console.log("kkkk", records)
  //     } else {
  //       this.spinner.hideSpinner();
  //       this.toastr.error(successResponse.records.message)
  //     }
  //   }, (failureResponse: any) => {
  //     if (failureResponse.status === 401) {
  //       window.location.href = 'login';
  //     } else {
  //       this.spinner.hideSpinner();
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }
  ngOnInit(): void {
    this.Title = "Top Usage By Customers"
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
    } else {
      this.isThirtParty = false;

    }
  }

  backToService() {
    // this._location.back();
    this.showForm = "true"
    this.listData = []

  }
  handleSubmit() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.getForm.value.selectDistributor,
    };

    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        // this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.institutionId = data.institutionId
        this.PassViewReport();
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  PassViewReport() {

    let institutionId = this.institutionId;
    let pdays = this.getForm.value.selectedValue;

    this.router.navigate(["dashboard/view/getTopUsageByCustomers"], { queryParams: { institutionId, pdays } })
  }
}
