<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Create Product Service Profile</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backToService()">Back to Service</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" (ngSubmit)="OnCreateService()">
                            <div class="card-subtitle">Product Service</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Service Code</label>
                                            <input type="text" class="form-control" formControlName="serviceCode" placeholder="Service Codee"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceCode.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.serviceCode.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.serviceCode.errors.required">Service Code is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Service Description</label>
                                            <input type="text" class="form-control"  formControlName="serviceDesc" placeholder="service Description"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceDesc.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.serviceDesc.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.serviceDesc.errors.required">service Description    is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">Is Active</label>
                                        <select class="form-control" formControlName="isActive" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.isActive.errors }">
                                            <option [ngValue]="1" selected>Yes</option>
                                            <option [ngValue]="0">No</option>
                                        </select>   
                                        <div *ngIf="submitted && serviceForm.controls.isActive.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.isActive.errors.required">IsActive is required</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="isActive">Is Transactional</label>
                                        <select class="form-control" formControlName="isTransactional" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.isTransactional.errors }">
                                            <option [ngValue]="1" selected>Yes</option>
                                            <option [ngValue]="0">No</option>
                                        </select>   
                                        <div *ngIf="submitted && serviceForm.controls.isTransactional.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.isTransactional.errors.required">IsTransactional is required</div>
                                        </div>
                                        </div>
                                    </div>
                                   
                            </div>
                            <div class="card-subtitle">Profile Settings</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="isActive">Charge Mode Id</label>
                                        <select class="form-control" formControlName="serviceChargeModeId" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceChargeModeId.errors }">
                                            <option *ngFor="let p of selectServiceChargeModeData" [ngValue]="p.chargeModeId">{{p.chargeModeDesc}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.serviceChargeModeId.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.serviceChargeModeId.errors.required">Charge Mode Id is required</div>
                                        </div>
                                </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Service Profile Description</label>
                                        <input type="text" class="form-control" formControlName="serviceProfileDesc" placeholder="Profile Description"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.serviceProfileDesc.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.serviceProfileDesc.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.serviceProfileDesc.errors.required">Profile Description is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">MinInTxAmount</label>
                                        <input type="text" class="form-control"  formControlName="minInTxAmount" placeholder="MinInTxAmount"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.minInTxAmount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.minInTxAmount.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.minInTxAmount.errors.required">MinInTxAmount is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">MinOutTxAmount</label>
                                        <input type="text" class="form-control"  formControlName="minOutTxAmount" placeholder="MinOutTxAmount"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.minOutTxAmount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.minOutTxAmount.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.minOutTxAmount.errors.required">MinInTxAmount is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                    
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">DailyInTxLimit</label>
                                        <input type="text" class="form-control" formControlName="dailyInTxLimit" placeholder="DailyInTxLimit"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.dailyInTxLimit.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.dailyInTxLimit.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.dailyInTxLimit.errors.required">DailyInTxLimit is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">DailyOutTxLimit</label>
                                        <input type="text" class="form-control" formControlName="dailyOutTxLimit" placeholder="DailyOutTxLimit"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.dailyOutTxLimit.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.dailyOutTxLimit.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.dailyOutTxLimit.errors.required">DailyOutTxLimit is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">MonthlyInTxLimit</label>
                                        <input type="text" class="form-control"  formControlName="monthlyInTxLimit" placeholder="MonthlyInTxLimit"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.monthlyInTxLimit.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.monthlyInTxLimit.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.monthlyInTxLimit.errors.required">MonthlyInTxLimit is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">MonthlyOutTxLimit</label>
                                        <input type="text" class="form-control"  formControlName="monthlyOutTxLimit" placeholder="MonthlyOutTxLimit"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.monthlyOutTxLimit.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.monthlyOutTxLimit.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.monthlyOutTxLimit.errors.required">MinInTxAmount is required</div>
                                        </div>
                                    </div>
                                </div>
                               
                             </div>
                             <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">maxInTxAmount</label>
                                        <input type="text" class="form-control"  formControlName="maxInTxAmount" placeholder="maxInTxAmount"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.maxInTxAmount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.maxInTxAmount.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.maxInTxAmount.errors.required">maxInTxAmount is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">MaxOutTxAmount</label>
                                        <input type="text" class="form-control"  formControlName="maxOutTxAmount" placeholder="MaxOutTxAmount"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.maxOutTxAmount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.maxOutTxAmount.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.maxOutTxAmount.errors.required">MaxInTxAmount is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                                        
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
