<div @fade class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Commission Summary Reports</h4>
        <p class="card-description">Commission Summary Reports</p>
        <form
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="passDistributorCommissionSummaryByMonthReports()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="selectDistributor">Select Distributor</label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="month">Month</label>
                <select
                  class="form-control-sm"
                  name="month"
                  formControlName="month"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.month.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option *ngFor="let c of months" [ngValue]="c.no">
                    {{ c.month }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.month.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.month.errors?.required">
                    Select Month is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="year">Year</label>
                <select
                  class="form-control-sm"
                  [(ngModel)]="year"
                  name="year"
                  formControlName="year"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.year.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option *ngFor="let c of years" [ngValue]="c.year">
                    {{ c.year }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.year.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.year.errors?.required">
                    Select Year is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="selectedValue">Currency</label>
                <select
                  class="form-control-sm"
                  formControlName="selectedValue"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectedValue.errors
                  }"
                >
                  <option *ngFor="let c of selectData" [ngValue]="c.currencyId">
                    {{ c.currencyCode }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectedValue.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.selectedValue.errors?.required">
                    Currency is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
