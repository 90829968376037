<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h4 class="card-title">View Distributor Info</h4>
                    </div>
                    <div class="col-md-4">
                        <h4 class="card-title" style="float: right;">
                            <button (click)="onButtonClick(event)" class="btn btn-primary mr-2">{{event}}</button>
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Distriputors</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="updatesForm" novalidate
                            (ngSubmit)="OnUpdateDistributorUser()">
                            <div class="card-subtitle">Access Profile</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">User Title</label>
                                        <input type="text" class="form-control" formControlName="userTitle"
                                            placeholder="user Title" readonly
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.userTitle.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.userTitle.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.userTitle.errors.required">userTitle is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Login Id</label>
                                            <input type="text" class="form-control"  formControlName="loginId" placeholder="Login ID"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.loginId.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.loginId.errors" class="text-danger">
                                                <div *ngIf="updatesForm.controls.loginId.errors.required">Login Id is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="isActive">Password</label>
                                            <input type="password" class="form-control"  formControlName="password" placeholder="Password"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.password.errors }">
                                            <div *ngIf="submitted && updatesForm.controls.password.errors" class="text-danger">
                                                <div *ngIf="updatesForm.controls.password.errors.required">Password is required</div>
                                            </div>
                                        </div>
                                    </div> -->

                            </div>
                            <div class="card-subtitle">Company Profile</div>
                            <div
                                style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="isActive">Company Name</label>
                                        <input type="text" class="form-control" formControlName="companyName"
                                            placeholder="Company Name"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.companyName.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.companyName.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.companyName.errors.required">Company Name
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Company Email</label>
                                        <input type="email" class="form-control" formControlName="companyEmail"
                                            placeholder="Company Email"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.companyEmail.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.companyEmail.errors" class="text-danger">
                                            <div *ngIf="updatesForm.controls.companyEmail.errors.required">Company Email is required
                                            </div>
                                            <div *ngIf="updatesForm.controls.companyEmail.errors.email">It`s Not An Company Email Plz
                                                Check</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="isActive">Company Phone</label>
                                        <input type="number" class="form-control" formControlName="companyPhone"
                                            placeholder="Company Phone"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.companyPhone.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.companyPhone.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.companyPhone.errors.required">Company Phone
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="isActive">Company Address</label>
                                        <input type="text" class="form-control" formControlName="companyAddress"
                                            placeholder="Company Address"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.companyAddress.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.companyAddress.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.companyAddress.errors.required">Company
                                                Address is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="isActive">Company Website</label>
                                        <input type="text" class="form-control" formControlName="companyWebsite"
                                            placeholder="Company Website"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.companyWebsite.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.companyWebsite.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.companyWebsite.errors.required">Company
                                                Website is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">City Id</label>
                                        <select class="form-control" formControlName="cityId" placeholder="City Id"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.cityId.errors }">
                                            <option *ngFor="let p of selectCitiesData" [ngValue]="p.cityId">
                                                {{p.cityName}}</option>
                                        </select>
                                        <div *ngIf="submitted && updatesForm.controls.cityId.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.cityId.errors.required">City Id is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-subtitle">Contact Information</div>
                            <div
                                style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="isActive">First Name</label>
                                        <input type="text" class="form-control" formControlName="firstName"
                                            placeholder="First Name"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.firstName.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.firstName.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.firstName.errors.required">First Name is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Middle Name</label>
                                        <input type="text" class="form-control" formControlName="middleName"
                                            placeholder="Middle Name"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.middleName.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.middleName.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.middleName.errors.required">Middle Name is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Last Name</label>
                                        <input type="text" class="form-control" formControlName="lastName"
                                            placeholder="Last Name"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.lastName.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.lastName.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.lastName.errors.required">Last Name is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Email</label>
                                        <input type="email" class="form-control" formControlName="email"
                                            placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.email.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.email.errors" class="text-danger">
                                            <div *ngIf="updatesForm.controls.email.errors.required">Email is required
                                            </div>
                                            <div *ngIf="updatesForm.controls.email.errors.email">It`s Not An Email Plz
                                                Check</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Contact Number</label>
                                        <input type="number" class="form-control" formControlName="telephone"
                                            placeholder="Contact Number"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.telephone.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.telephone.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.telephone.errors.required">Contact Number
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Website</label>
                                        <input type="text" class="form-control" formControlName="website"
                                            placeholder="Website"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.website.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.website.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.website.errors.required">Website is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Address Line One</label>
                                        <input type="text" class="form-control" formControlName="address"
                                            placeholder="Address One"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.address.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.address.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.address.errors.required">Address One is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Address Line Two</label>
                                        <input type="text" class="form-control" formControlName="address2"
                                            placeholder="Address Two"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.address2.errors }">
                                        <div *ngIf="submitted && updatesForm.controls.address2.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.address2.errors.required">Address Two is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">City</label>
                                        <select class="form-control" formControlName="city"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.city.errors }">
                                            <option *ngFor="let p of selectCitiesData" [ngValue]="p.cityName">
                                                {{p.cityName}}</option>
                                        </select>
                                        <div *ngIf="submitted && updatesForm.controls.city.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.city.errors.required">City is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Country</label>
                                        <select class="form-control" formControlName="country"
                                            [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.country.errors }">
                                            <option *ngFor="let p of selectCountryData" [ngValue]="p.countryName">
                                                {{p.countryName}}</option>
                                        </select>
                                        <div *ngIf="submitted && updatesForm.controls.country.errors"
                                            class="text-danger">
                                            <div *ngIf="updatesForm.controls.country.errors.required">Country is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Update</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                            <!-- <button type="submit" class="btn btn-primary mr-2">Update</button>
                            <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>