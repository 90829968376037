<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <h4 class="card-title">Change Password</h4>
                    </div>
                    <div class="col-md-3">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-subtitle">Change Password</div>
                        <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                        <form [formGroup]="signupForm" novalidate autocomplete="off">
                            <!-- current password -->
                            <!-- <div class="col-md-4">
                                <div class="form-group">
                                    <label for="email" class="control-label font-weight-bold">Current Password</label>
                                    <input type="password" class="form-control" formControlName="currentPassword"
                                        placeholder="Your current password"
                                        [ngClass]="{ 'is-valid': (f.currentPassword.dirty || submitted) && !f.currentPassword.errors, 'is-invalid': (f.currentPassword.dirty || submitted) && f.currentPassword.errors }" />
                                    <div class="invalid-feedback">
                                        <span class="text-danger" *ngIf="f.currentPassword.errors?.required">Current password is required</span>
                                    </div>
                                </div>
                            </div> -->

                            <!-- password -->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="password" class="control-label font-weight-bold">Password</label>
                                    <input type="password" formControlName="password" class="form-control"
                                        name="password" placeholder="Choose a password"
                                        [ngClass]="{ 'is-valid': (f.password.dirty || submitted) && passwordValid, 'is-invalid': (f.password.dirty || submitted) && !passwordValid }" />
                                    <div class="invalid-feedback">
                                        <!-- required -->
                                        <span class="text-danger"
                                            *ngIf="(f.password.dirty || submitted) && !requiredValid">Password is
                                            required</span>

                                        <!-- password validation rules -->
                                        <div id="validation-rules" class="mt-3" *ngIf="requiredValid">
                                            <!-- minlength -->
                                            <div
                                                [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }">
                                                <i
                                                    [attr.class]="minLengthValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must be at least 8 characters long
                                            </div>

                                            <!-- requires digit -->
                                            <div
                                                [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }">
                                                <i
                                                    [attr.class]="requiresDigitValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must contain at least 1 digit
                                            </div>

                                            <!-- requires uppercase -->
                                            <div
                                                [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }">
                                                <i
                                                    [attr.class]="requiresUppercaseValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must contain at least 1 uppercase character
                                            </div>

                                            <!-- requires lowercase -->
                                            <div
                                                [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }">
                                                <i
                                                    [attr.class]="requiresLowercaseValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must contain at least 1 lowercase character
                                            </div>

                                            <!-- requires special characters -->
                                            <div
                                                [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }">
                                                <i
                                                    [attr.class]="requiresSpecialCharsValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must contain at least 1 special character
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- confirm password -->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="confirmPassword" class="control-label font-weight-bold">Confirm
                                        password</label>
                                    <input type="password" formControlName="confirmPassword" class="form-control"
                                        name="confirmPassword" placeholder="Confirm your password"
                                        [ngClass]="{ 'is-valid': (submitted || f.confirmPassword.dirty) && !f.confirmPassword.errors, 'is-invalid': (submitted || f.confirmPassword.dirty) && f.confirmPassword.errors }">
                                    <div class="invalid-feedback"
                                        *ngIf="(submitted || f.confirmPassword.dirty) && f.confirmPassword.errors">
                                        <div *ngIf="f.confirmPassword.errors.required">Confirm your password</div>
                                        <div *ngIf="f.confirmPassword.errors.minLength">Password must be at least 8
                                            characters long</div>
                                        <div *ngIf="f.confirmPassword.errors.mismatch">Passwords do not match</div>
                                    </div>
                                </div>
                            </div>

                            <!-- submit button -->
                            <div class="col-md-4">
                                <a id="popover" rel="popover"
                                    style="float: right; margin-bottom: 20px; color: #000; font-weight: bold; cursor: pointer;"
                                    data-content="
                                1. The password field are required! <br/>
                                2. It must be at least 8 characters long! <br/>
                                3. It must contain at least 1 digit! <br/>
                                4. It must contain at least 1 uppercase character! <br/>
                                5. It must contain at least 1 lowercase character! <br/>
                                6. It must contain at least 1 special character! <br/>
                                7. You cannot use any of your last 4 passwords
                                " data-html="true"  data-original-title="Password requirements">Password requirements <i
                                        style="margin-top: 40px;" class="ti-settings menu-icon"></i></a>
                                <div class="form-group">
                                    <button type="submit" data-toggle="modal" data-target="#updateAccessModel"
                                        class="btn btn-block btn-primary" [disabled]="isWorking">
                                        <i [attr.class]="isWorking ? 'bi-hourglass-split' : 'bi-check2-square'"></i> {{
                                        isWorking ? 'Working on it...' : 'Change Password' }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-md" id="updateAccessModel" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

    <div class="modal-dialog modal-md-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col grid-margin stretch-card">
                    <!-- <div class="card"> -->
                    <div class="card-body">
                        <h4 class="card-title">Update Status By Reson</h4>
                        <form class="forms-sample" [formGroup]="updateResonForm" (ngSubmit)="onSubmitChangePassword()">
                            <div class="card-subtitle">Reson</div>
                            <div
                                style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                            </div>
                            <div class="form-group">
                                <label for="exampleInputUsername1">Reson</label>
                                <input type="text" class="form-control" formControlName="reson" placeholder="Reson"
                                    [ngClass]="{ 'is-invalid': submitted && updateResonForm.controls.reson.errors }">
                                <div *ngIf="submitted && updateResonForm.controls.reson.errors" class="text-danger">
                                    <div *ngIf="updateResonForm.controls.reson.errors.required">
                                        Reson is required
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary mr-2">Submit</button>
                            <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                        </form>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>