<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Event Log Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <!-- <form class="forms-sample" novalidate  (ngSubmit)="OnUpdateUser()"> -->
                        <div class="card-subtitle">Event Changed User</div>
                        <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">From User</label>
                                    <input type="text" class="form-control" [(ngModel)]="userTitle" readonly
                                        placeholder="From User">

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">To User</label>
                                    <input type="text" class="form-control" [(ngModel)]="merchantName" readonly
                                        placeholder="To User">

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">The Reson</label>
                                    <input type="text" class="form-control" [(ngModel)]="reson" readonly
                                        placeholder="The Reson">

                                </div>
                            </div>

                            <!-- <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="isActive">Password</label>
                                            <input type="password" class="form-control"  [(ngModel)]="password" placeholder="Password"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.password.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.password.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.password.errors.required">Password is required</div>
                                            </div>
                                        </div>
                                    </div> -->

                        </div>
                        <div class="card-subtitle">Event Changed User Info</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="isActive">First Name</label>
                                    <input type="text" class="form-control" [(ngModel)]="firstName"readonly
                                        placeholder="First Name">

                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Middle Name</label>
                                    <input type="text" class="form-control" [(ngModel)]="middleName" readonly
                                        placeholder="Middle Name">

                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Last Name</label>
                                    <input type="text" class="form-control" [(ngModel)]="lastName" readonly
                                        placeholder="Last Name">

                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email</label>
                                    <input type="email" class="form-control" [(ngModel)]="email" placeholder="Email" readonly>

                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Contact Number</label>
                                    <input type="number" class="form-control" [(ngModel)]="telephone" readonly
                                        placeholder="Contact Number">

                                </div>
                            </div>
                        </div>

                        <div class="card-subtitle">Event Changed User Address</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">Country Code</label>
                                    <input type="text" class="form-control" [(ngModel)]="country_code"
                                        placeholder="Country Code" readonly>

                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Country Name</label>
                                    <input type="text" class="form-control" [(ngModel)]="country_name"
                                        placeholder="Country Name" readonly>

                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">City</label>
                                    <input type="text" class="form-control" [(ngModel)]="city" placeholder="City"
                                        readonly>

                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">State</label>
                                    <input type="text" class="form-control" [(ngModel)]="state" placeholder="State"
                                        readonly>

                                </div>
                            </div>
                        </div>
                        <div class="card-subtitle">Event Changed User Location</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <!-- <div class="row"> -->
                        <agm-map [latitude]="lat" [longitude]="lng">
                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                        </agm-map>
                        <!-- </div> -->
                        <!-- <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Update</button>
                                    <button class="btn btn-default">Centered button</button>
                                </div>
                            </div> -->
                        <!-- <button type="submit" class="btn btn-primary mr-2">Update</button> -->
                        <!-- <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>