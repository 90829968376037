import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-viewdist',
  templateUrl: './viewdist.component.html',
  styleUrls: ['./viewdist.component.css'],
  animations: [
    fade
  ]
})
export class ViewdistComponent implements OnInit {

  updatesForm: FormGroup;
  queryDistributorId: number;
  distributorId: any;
  dataRecords: any;
  sub: Subscription;
  submitted: boolean = false;
  event;
  selectCountryData;
  selectMessage;
  selectCitiesData;
  selectedMessage;
  companyAddress;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.updateForm();
    this.getCountryList();
    this.getCitiesList();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryDistributorId = +params['distributorId'];
        if (Number.isNaN(this.queryDistributorId)) {
          this.distributorId = "";
        } else {
          this.distributorId = this.queryDistributorId;
          this.getDistributorCompanyAddress(this.distributorId);
          this.getDistributorInfo(this.distributorId);
        }
      });
  }


  updateForm() {
    this.updatesForm = this.fb.group({
      distributorId: ['', Validators.required],
      companyName: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.email]],
      companyPhone: ['', Validators.required],
      companyAddress: ['', Validators.required],
      companyWebsite: ['', Validators.required],
      cityId: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.event = 'Edit';
    this.updatesForm.disable();
  }

  onButtonClick(event) {
    if (event == 'Edit') {
      this.event = 'Disabled'
      this.updatesForm.enable()
    } else {
      this.event = 'Edit'
      this.updatesForm.disable()
    }
  }

  backToService() {
    this.router.navigate(["dashboard/listDistributorUserInfo"])
  }

  getCountryList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCountryList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      // console.log("Country=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCountryData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getCitiesList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCitiesList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      console.log("Cities=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCitiesData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectedMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  getDistributorCompanyAddress(distributorId) {
    let payload: any = {
      distributorId: distributorId
    };
    this.spinner.getSpinner();
    this.service.getDistributorCompanyAddress({ payload: payload }).subscribe(successResponse => {

      // console.log("haaa", successResponse)
      if (successResponse.records) {
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.companyAddress = data;
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  getDistributorInfo(distributorId) {
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      distributorId: distributorId
    };
    this.spinner.getSpinner();
    this.service.getDistributorInfo({ payload: payload }).subscribe(successResponse => {

      // console.log("haaa", successResponse)
      if (successResponse.records) {
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.updatesForm = this.fb.group({
          // userId: [x.userId],
          firstName: [data.firstName, Validators.required],
          middleName: [data.middleName, Validators.required],
          lastName: [data.lastName, Validators.required],
          email: [data.email, [Validators.required, Validators.email]],
          telephone: [data.telephone, Validators.required],
          address: [data.address, Validators.required],
          userTitle: [data.userTitle, Validators.required],
          address2: [data.address2, Validators.required],
          website: [data.website, Validators.required],
          city: [data.city, Validators.required],
          country: [data.country, Validators.required],
          companyName: [this.companyAddress?.companyName, Validators.required],
          companyEmail: [this.companyAddress?.companyEmail, [Validators.required, Validators.email]],
          companyPhone: [this.companyAddress?.companyPhone, Validators.required],
          companyAddress: [this.companyAddress?.companyAddress, Validators.required],
          companyWebsite: [this.companyAddress?.companyWebsite, Validators.required],
          cityId: [this.companyAddress.cityId, Validators.required],
        });
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }


  OnUpdateDistributorUser() {
    this.submitted = true;

    if (this.updatesForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      distributorId: this.distributorId,
      firstName: this.updatesForm.value.firstName,
      middleName: this.updatesForm.value.middleName,
      lastName: this.updatesForm.value.lastName,
      telephone: this.updatesForm.value.telephone,
      address: this.updatesForm.value.address,
      email: this.updatesForm.value.email,
      address2: this.updatesForm.value.address2,
      city: this.updatesForm.value.city,
      country: this.updatesForm.value.country,
      website: this.updatesForm.value.website,
      userTitle: this.updatesForm.value.userTitle,
    };
    this.spinner.getSpinner();
    this.service.updateDistributorUser(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if (successResponse.status == 'SUCCESS') {
        this.spinner.hideSpinner();
        // this.toastr.success(`You have updated `, 'Seccessfully')
        // this.router.navigate(["dashboard/listDistributorUserInfo"])
        this.updateDistributorCompanyAddress();
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  updateDistributorCompanyAddress() {

    let payload: any = {
      distributorId: this.distributorId,
      companyName: this.updatesForm.value.companyName,
      companyAddress: this.updatesForm.value.companyAddress,
      cityId: this.updatesForm.value.cityId,
      companyEmail: this.updatesForm.value.companyEmail,
      companyPhone: this.updatesForm.value.companyPhone,
      companyWebsite: this.updatesForm.value.companyWebsite,
    };
    console.log("U=> ", payload)
    this.spinner.getSpinner();
    this.service.updateDistributorCompanyAddress({ payload: payload }).subscribe(successResponse => {
      console.log("HHHHHH=> ", successResponse)
      if (successResponse.records.status == 'SUCCESS') {
        this.spinner.hideSpinner();
        this.toastr.success(`You have updated `, 'Seccessfully')
        this.router.navigate(["dashboard/listDistributorUserInfo"])
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

}
