import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-settlement-commission-table',
  templateUrl: './settlement-commission-table.component.html',
  styleUrls: ['./settlement-commission-table.component.css'],
  animations: [
    fade
  ]
})
export class SettlementCommissionTableComponent implements OnInit {
  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userdistributorId;
  queryuserDistributorId:any;
  sub:any;
  srcAccountId;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryuserDistributorId = +params['distributorId'];
        if (Number.isNaN(this.queryuserDistributorId)) {
          this.userdistributorId = "";
        } else {
          this.userdistributorId = this.queryuserDistributorId;
          console.log("HHHH => ", this.userdistributorId)
          this.getDistributorCommissionList(this.userdistributorId)
        }
      });
  }


  ngOnInit(): void {
  }

  backToService(){
    this._location.back();
  }

  getDistributorCommissionList(distributorId){
    let payload: any = {
      distributorId: distributorId
    };
    this.spinner.getSpinner();
    this.service.getDistributorCommission({payload: payload}).subscribe(successResponse => {
      console.log("haaa", successResponse)
      if(successResponse.records){
        if(!successResponse.records.message){
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData =records;
          console.log("kkkk", records)
        }else{
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }
        
      }else {
        this.listBody = false;
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });    
  }
  
  getProcessCommission(x){
    let srcAccountId = x.accountId
    this.router.navigate(["dashboard/view/processDistributorCommission"], { queryParams: { srcAccountId } })
  }
}
