<div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <!-- <div class="row">
                    <div class="col-md-8">
                        <h4 class="card-title">Settlement Repotrs</h4>
                    </div>
                    <div class="col-md-4">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-raised btn-primary btn-round waves-effect ml-2" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backToService()">Back</button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" (click)="print()" class="btn btn-raised btn-secondary btn-round waves-effect" style="max-height: 45px;">
                                <i class="zmdi zmdi-print"></i>
                            </button>
                        </h4>
                    </div>
                </div> -->
                <!-- <div class="container"> -->
                <div class="row" id="section-one">
                    <div class="col-md-12 col-md-offset-3 body-main">
                        <div class="col-md-12" style="margin-top: 40px;">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="img" alt="Invoce Template" height="80"
                                                src="assets/images/waafipay-nav.png" />
                                        </div>
                                        <div class="col-md-8 text-right">
                                            <!-- <qrcode [errorCorrectionLevel]="'M'" [colorDark]="'#000000ff'"
                                            [colorLight]="'#ffffffff'"
                                                [qrdata]="'{data: dsjfhjskdafh}'" [width]="'100'"></qrcode> -->
                                        </div>
                                    </div>
                                    <div class="row" style="margin-top: 60px;">
                                        <div class="col-md-4">
                                            <!-- <p><strong>REPORT</strong></p> -->
                                            <p><strong>PRINTED BY:</strong>&nbsp;
                                                {{partnerTitle}}</p>
                                            <p><strong>REPORT NAME:</strong>&nbsp; {{sumType}}
                                            </p>
                                        </div>
                                        <!-- <div class="col-md-4 text-center">

                                            <p><strong>START DATE:</strong> {{dateStartDate}}
                                            </p>
                                            <p><strong>END DATE:</strong> {{dateEndtDate}}</p>
                                        </div> -->
                                        <div class="col-md-8 text-right">

                                            <p><strong>START DATE:</strong> {{dateStartDate}}
                                            </p>
                                            <p><strong>END DATE:</strong> {{dateEndtDate}}</p>
                                            <!-- <p>info@ch.com</p> -->
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div *ngIf="type == 1" class="table-responsive" style="margin-top: 20px;">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>GL AccountId</th>
                                            <th>Account No</th>
                                            <th>Account Title</th>
                                            <th>Previous Balance</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="listBody">
                                        <tr *ngFor="let x of listData">
                                            <td>{{ x.accountId}}</td>
                                            <td>{{ x.accountNo}}</td>
                                            <td>{{ x.accountDesc}}</td>
                                            <td>{{ x.previousBalance}}</td>
                                            <td>{{ x.debit}}</td>
                                            <td>{{ x.credit}}</td>
                                            <td>{{ x.balance}}</td>
                                        </tr>
                                        <tr style="color: green; font-weight: bold;">
                                            <td></td>
                                            <td></td>
                                            <td>Total</td>
                                            <td>{{sumPrevious}}</td>
                                            <td>{{ sumDebit}}</td>
                                            <td>{{ sumCredit}}</td>
                                            <td>{{ sumTotal}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="type == 2" class="table-responsive" style="margin-top: 20px;">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Record Id</th>
                                            <th>CreatedDate</th>
                                            <th>Account Type Desc</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <!-- <th>Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="listBody">
                                        <tr *ngFor="let x of listData">
                                            <td>{{ x.recordId}}</td>
                                            <td>{{ x.createdDate | date:'dd-MMM-yy - HH:mm:ss'}}</td>
                                            <td>{{ x.accountTypeDesc}}</td>
                                            <td>{{ x.debit}}</td>
                                            <td>{{ x.credit}}</td>
                                        </tr>
                                        <tr style="color: green; font-weight: bold;">
                                            <td></td>
                                            <td></td>
                                            <td>Total</td>
                                            <td>{{ sumDebit}}</td>
                                            <td>{{ sumCredit}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="type == 3" class="table-responsive" style="margin-top: 20px;">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Account No</th>
                                            <th>Account Title</th>
                                            <th>Refernce Number</th>
                                            <th>Debit</th>
                                            <th>Credit</th>
                                            <!-- <th>Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="listBody">
                                        <tr *ngFor="let x of listData">
                                            <td>{{ x.dayDate | date:'dd-MMM-yy - HH:mm:ss'}}</td>
                                            <td>{{ x.accountNo}}</td>
                                            <td>{{ x.accountDesc}}</td>
                                            <td>{{ x.journalNumber}}</td>
                                            <td>{{ x.debit}}</td>
                                            <td>{{ x.credit}}</td>
                                        </tr>
                                        <tr style="color: green; font-weight: bold;">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Total</td>
                                            <td>{{ sumDebit}}</td>
                                            <td>{{ sumCredit}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- </div> -->
                            <div>
                                <div class="col-md-12">
                                    <p><b>Created Date :</b> {{dateNow}}</p> <br />
                                    <p><b>Signature</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->

            </div>
        </div>
    </div>
</div>