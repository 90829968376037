<div @fade class="row">
    <div class="col grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h4 class="card-title">Cashier Activation</h4>
                    </div>
                    <div class="col-md-4">
                        <h4 class="card-title" style="float: right;">
                            <!-- <button (click)="onButtonClick()" class="btn btn-primary mr-2">Edit</button> -->
                            <button (click)="onButtonClick(event)" class="btn btn-primary mr-2">{{event}}</button>
                            <button type="submit" class="btn btn-primary" (click)="backToCashier()">Back To
                                Cashier</button>
                        </h4>
                    </div>
                </div>
                <form class="forms-sample"  [formGroup]="serviceForm" novalidate>
                    <!-- <fieldset [disabled]="isDisabled"> -->
                        <div class="card-subtitle">Activation</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">User Id</label>
                                    <input type="text" class="form-control" formControlName="userId"
                                        placeholder="User Id" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userId.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.userId.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.userId.errors.required">User Id required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">Cashier Name</label>
                                    <input type="text" class="form-control" formControlName="userTitle"
                                        placeholder="User Title" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userTitle.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.userTitle.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.userTitle.errors.required">User Title is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">Profile Status</label>
                                    <select class="form-control" formControlName="selectedAccess" (change)="onChange($event)" [(ngModel)]="selectedValue"  [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedAccess.errors }">
                                        <option value=null disabled selected>--Select--</option>
                                        <option *ngFor="let p of selectAccess"  [ngValue]="p.profileStatusId">
                                        {{p.profileStatusDesc}}</option>
                                    </select>
                                    <div *ngIf="submitted && serviceForm.controls.selectedAccess.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.selectedAccess.errors.required">Status Profile is required</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">API-Key</label>
                                    <input type="text" class="form-control" formControlName="accessKey"
                                        placeholder="API-Key" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.accessKey.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.accessKey.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.accessKey.errors.required">API-Key is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col text-center">
                                <button type="submit" [disabled]="!btnDisabled"  data-toggle="modal" data-target="#updateAccessModel" class="btn btn-primary" style="width: 30%;">Update</button>
                                <!-- <button class="btn btn-default">Centered button</button> -->
                            </div>
                        </div>
                    <!-- </fieldset> -->
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-md" id="updateAccessModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <!-- <div class="card"> -->
                        <div class="card-body">
                            <h4 class="card-title">Update Status By Reson</h4>
                            <form class="forms-sample" [formGroup]="updateResonForm" (ngSubmit)="onUpdateAccessProfileInfo()">
                                <div class="card-subtitle">Reson</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Reson</label>
                                    <input type="text" class="form-control" formControlName="reson"
                                        placeholder="Reson"
                                        [ngClass]="{ 'is-invalid': submitted && updateResonForm.controls.reson.errors }">
                                    <div *ngIf="submitted && updateResonForm.controls.reson.errors"
                                        class="text-danger">
                                        <div *ngIf="updateResonForm.controls.reson.errors.required">
                                            Reson is required
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>