import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {

  constructor(private _location: Location, private router: Router) { }

  ngOnInit(): void {
  }

  goBackToPage(){
    this.router.navigate(['dashboard/home'])
  }

}
