import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service"
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
  animations: [
    fade
  ]
})
export class CreateComponent implements OnInit {
  queryUserId: number;
  userId: any;
  dataRecords: any;
  sub: Subscription;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  roleId;
  selectBankData;
  selectServiceChargeModeData;
  // dataSet = [{}]
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryUserId = +params['userId'];
        if (Number.isNaN(this.queryUserId)) {
          this.userId = "";
        } else {
          this.userId = this.queryUserId;
        }
      });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      paramId: [''],
      paramName: ['', Validators.required],
      paramCode: ['', Validators.required],
      paramValue: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }
  OnCreateSystem() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }

    let payload: any = {
      createdByUserId: this.userId,
      paramCode: this.serviceForm.value.paramCode,
      paramValue: this.serviceForm.value.paramValue,
      paramName: this.serviceForm.value.paramName
    };
    this.spinner.getSpinner();
    this.service.createSystemConfig(payload).subscribe(successResponse => {

      if (successResponse.id) {
        $('#sysConfigModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success("Seccessfully: You have created Id", successResponse.id)
        this.router.navigate(['dashboard/listSystemConfig'])
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  backtowebrole() {
    this._location.back();
  }
}
