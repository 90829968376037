<div @fade class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Processing Periodic Settlement</h4>
                <p class="card-description">
                    Periodic Settlement
                </p>
                <form class="forms-sample" [formGroup]="getForm" novalidate (ngSubmit)="passClosingDate()">

                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <input type="text" class="form-control-sm" formControlName="description"
                                    placeholder="Description"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.description.errors }">
                                <div *ngIf="submitted && getForm.controls.description.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.description.errors?.required">
                                        Description is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <input type="date" class="form-control-sm" formControlName="closingDate"
                                    placeholder="closing Date"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.closingDate.errors }">
                                <div *ngIf="submitted && getForm.controls.closingDate.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.closingDate.errors?.required">
                                        closing Date is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>