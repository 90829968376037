import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-usage-summary-by-month-report.component',
  templateUrl: './usage-summary-by-month-report.component.html',
  styleUrls: ['./usage-summary-by-month-report.component.css'],
  animations: [
    fade
  ]
})
export class UsageSummaryByMonthReportComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  Title;
  lisCustomers;
  ListOfMethods;
  currentRecordId;
  currentCardId;
  selected = 1000;
  // selected = pagination.selected;
  p: number = pagination.p;
  term;
  currentMobileNumber;
  numberOfdays;
  showForm: string = 'true';
  institutionId;
  stateList;
  TranType;
  monthsList;
  distdataList

  sumOfTran = null;
  sumOfAmount = null;
  sumAmount = null;
  sumOfTopups = null;
  sumloadAuth = null;
  sumloadAdjustment = null;
  sumWithdrawals = null;
  sumAuthorizations = null;
  sumauthzReversals = null;
  sumMarkupAmount = null;
  sumMarkupRate = null;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    // this.showForm = true
    this.getDistributorList();
    this.getFrom();
    this.numberOfdays = [{ id: 1, name: "Today" }, { id: 3, name: "Last 3 Days" }, { id: 7, name: "Last 7 Days" }, { id: 30, name: "Last 30 Days" }]
    this.stateList = ['Inprogress', 'Failed', 'Ambiguous', 'Completed', 'CreditPending', 'CreditCancelled', 'Timeout', 'Reversed', 'PartiallyReversed', 'Refuded', 'PartiallyRefunded']
    this.monthsList = [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" }
    ];
  }
  getFrom() {
    this.getForm = this.fb.group({
      selectDistributor: ['', Validators.required],
      SelectedMonth: ['', Validators.required],
      SelectedTranStatus: ['', Validators.required],
    });
  }

  getDistributorList() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distdataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  ngOnInit(): void {
    this.Title = "Usage Summary By Month Report"
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
    } else {
      this.isThirtParty = false;

    }
  }

  backToService() {
    // this._location.back();
    this.showForm = "true"
    this.listData = []
    this.sumOfAmount = null
    this.sumOfTopups = null;
    this.sumloadAuth = null;
    this.sumloadAdjustment = null;
    this.sumWithdrawals = null;
    this.sumAuthorizations = null;
    this.sumauthzReversals = null;
    this.sumMarkupAmount = null;
    this.sumMarkupRate = null;
    this.getFrom()

  }
  formatTransactionAmount(amount) {
    if (amount === null || amount === undefined || amount === '') {
      return '0.00';
    }
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  getSummaryByMonth(institutionId) {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    // const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      institutionId: institutionId,
      pMonth: this.getForm.value.SelectedMonth,
      pTranStatus: this.getForm.value.SelectedTranStatus,
    };
    this.spinner.getSpinner();
    this.service.usageSummaryByMonth({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;
          this.showForm = 'false'
          records.forEach((el, index, arry) => {
            let record = {};
            this.sumOfAmount += el.transactionAmount;
            this.sumOfTopups += el.topups;
            this.sumloadAuth += el.loadAuth;
            this.sumloadAdjustment += el.loadAdjustment;
            this.sumWithdrawals += el.withdrawals;
            this.sumAuthorizations += el.authorizations;
            this.sumauthzReversals += el.authzReversals;
            this.sumMarkupAmount += el.markupAmount;
            this.sumMarkupRate += el.markupRate;
            // console.log("gaaaa", record)
          });

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
          this.toastr.warning(this.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  handleSubmit() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.getForm.value.selectDistributor,
    };
    let pdays = this.getForm.value.selectedValue
    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        // this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        let institutionId = data.institutionId
        // this.institutionId = data.institutionId
        this.getSummaryByMonth(institutionId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  SelectedRecodrd(data) {
    this.currentRecordId = data.recordId;
    this.currentCardId = data.cardId;
    this.currentMobileNumber = data.mobileNumber;
  }
}
