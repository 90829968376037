import { Component, OnInit } from '@angular/core';
import { ExpressRequestService } from '../../service/express-request/express-request.service'
import { SessionStorageService } from '../../service/session-storage/session-storage.service';
import { ServiceService } from '../../service/service/service.service';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { ToastrService } from "ngx-toastr";
import { environment } from 'src/assets/config/environment'

@Component({
  selector: 'app-reset-admin-password',
  templateUrl: './reset-admin-password.component.html',
  styleUrls: ['./reset-admin-password.component.css']
})
export class ResetAdminPasswordComponent implements OnInit {

  serviceForm: FormGroup;
  // loginId: string = "";
  // password: string = "";
  adminUserTypeId = "10";
  resultMessage: string;
  IsSignIt: boolean;
  appName;
  submitted = false;
  tab = environment.tab;

  constructor(
    private service: ServiceService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private sessionStorage: SessionStorageService,
    private expressRequestService: ExpressRequestService) {
    this.createForm();

  }

  ngOnInit() {
  }

  createForm() {
    this.serviceForm = this.fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])],
    });
  }



  resetAdminPassword() {
    this.resultMessage = "";

    this.submitted = true;
    if (this.serviceForm.invalid) {
      return;
    }

    let payload: any = {
      email: this.serviceForm.value.email,
      // userTypeId: 10,
    };
    console.log("Payload ", payload);

    this.service.adminResetPassword(payload).subscribe(successResponse => {
      console.log(successResponse);
      if (successResponse.success) {
        var responseObj = JSON.stringify(successResponse);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.toastr.success(data.message);
        setTimeout(() => {
          this.router.navigate(['login']).then(() => {
            window.location.reload();
          });

        }, 3000);

      } else {
        console.log(successResponse.records);
        this.toastr.warning(successResponse.records.message);
      }

    }, (failureResponse: any) => {
      // if (failureResponse.status === 401) {
      //   window.location.href = 'login';
      // } else {
      // this.failureMessage = true;
      console.log("onFailure:" + JSON.stringify(failureResponse.error.records.message));
      this.toastr.warning(failureResponse.error.records.message);
      this.spinner.hideSpinner();
      // }
    });
  }

}
