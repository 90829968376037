<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ Title }}</h4>
          </div>
        </div>

        <form
          [formGroup]="getForm"
          class="forms-sample"
          novalidate
          *ngIf="showForm === 'true'"
          (ngSubmit)="handleSubmit()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="selectDistributor">Select Distributor</label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      getSubmitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="selectedValue">Select Day</label>
                <select
                  class="form-control-sm"
                  formControlName="selectedValue"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectedValue.errors
                  }"
                >
                  <option value="null">Select a Day</option>
                  <option *ngFor="let c of numberOfdays" [ngValue]="c.id">
                    {{ c.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectedValue.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.selectedValue.errors?.required">
                    please Select Day
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
