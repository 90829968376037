<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Settlement Account Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Accounts</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate (ngSubmit)="onChanges()">
                            <div class="card-subtitle">Account Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Issuer Id</label>
                                        <select class="form-control" formControlName="selectedBankValue"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedBankValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectBankData" [ngValue]="p.bankId">
                                                {{p.bankName}}
                                            </option>
                                        </select>

                                        <div *ngIf="submitted && serviceForm.controls.selectedBankValue.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.selectedBankValue.errors.required">Issuer
                                                Id is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="bankCode">Settlement Account No</label>
                                        <input type="text" class="form-control" formControlName="settlementAccount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.settlementAccount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.settlementAccount.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.settlementAccount.errors.required">Account
                                                No is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="selectedSettlementModeValue">Settlment Acc Mode</label>
                                        <select class="form-control" formControlName="selectedSettlementModeValue"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedSettlementModeValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectSettlementModeData" [ngValue]="p.settlementModeId">
                                                {{p.settlementModeDesc}}
                                            </option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.selectedSettlementModeValue.errors"
                                            class="text-danger">
                                            <div
                                                *ngIf="serviceForm.controls.selectedSettlementModeValue.errors.required">
                                                Select Account Mode
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="selectedAccountTypeValue">Settlement Account Type</label>
                                        <select class="form-control" formControlName="selectedAccountTypeValue" [(ngModel)]="selectedAccountTypeValue"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedAccountTypeValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectAccountTypesData" [ngValue]="p.settlementAccountTypeId">
                                                {{p.settlementAccountTypeDesc}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.selectedAccountTypeValue.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.selectedAccountTypeValue.errors.required">
                                                Select Account Type is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="settlementCycleId">Settlement Cycle</label>
                                        <select class="form-control" formControlName="settlementCycleId"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.settlementCycleId.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option value="0">NoN Regular</option>
                                            <option value="1">Regular</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.settlementCycleId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.settlementCycleId.errors.required">Select
                                                Cycle Id Type is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>