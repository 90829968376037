<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="tab-pane fade show active" id="nav-admin" role="tabpanel" aria-labelledby="nav-admin-tab">
                    <div class="row">
                        <div class="col-md-10">
                            <h4 class="card-title">Daily Event Logs</h4>
                        </div>
                        <!-- <div class="col-md-2">
                            <h4 class="card-title" style="float: right;">
                                <button type="submit" class="btn btn-primary" (click)="passUserCreateInfo()">Add
                                    User</button>
                            </h4>
                        </div> -->
                    </div>
                    <div class="row" style="margin-bottom: 0px;">
                        <div class="col-md-3">
                            <input type="text" class="form-control-sm" [(ngModel)]="term"
                                placeholder="Search here..." style="float: left;">
                        </div>
                        <div class="col-md-9">
                            <h4 class="card-title" style="float: right;">
                                <button type="submit"
                                    style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                    class="btn">
                                    <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                                </button>
                            </h4>
                            <h4 class="card-title" style="float: right;">
                                <button type="submit"
                                    style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                    class="btn">
                                    <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                                </button>
                            </h4>
                            <h4 class="card-title" style="float: right;">
                                <button type="submit"
                                    style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                    class="btn">
                                    <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                                </button>
                            </h4>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table-striped display expandable-table" style="width:100%">
                            <thead>
                                <tr>
                                    <th>Log Event Id</th>
                                    <th>Updated Date</th>
                                    <th>Merchant Name</th>
                                    <th>Event Type Desc</th>
                                    <th>IP Address</th>
                                    <th>Previous Value</th>
                                    <th>New Value</th>
                                    <th>Reson</th>
                                    <th>DoneByUser</th>
                                    <th>Action</th>
                                    <th>View Roles</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                    <ng-container>
                                        <td class="py-1">{{ x.logEventId}}</td>
                                        <td>{{ x.updatedDate | date:'dd-MMM-yyyy - HH:mm:ss'}}</td>
                                        <td>{{ x.merchantName}}</td>
                                        <td>{{ x.eventTypeDesc}}</td>
                                        <td>{{ x.ipAddress}}</td>
                                        <td>{{ x.previousValue}}</td>
                                        <td>{{ x.newValue}}</td>
                                        <td>{{ x.reson}}</td>
                                        <td>{{ x.userTitle}}</td>
                                        <td (click)="passUserInfo(x)">
                                            <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                        </td>
                                        <td (click)="passRoleIdValue(x)">
                                            <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                        </td>
                                    </ng-container>

                                </tr>
                            </tbody>
                        </table>
                        <div style="width: 100%; margin-top: 1%;">
                            <h4 class="card-title" style="float: left;">
                                <select class="form-control-sm" [(ngModel)]="selected">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </h4>
                            <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                        </div>
                        <div *ngIf='!listBody' class="container">
                            <div style="text-align: center; padding: 24px;">
                                {{message}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>