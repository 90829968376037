import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-generate-settlement',
  templateUrl: './generate-settlement.component.html',
  styleUrls: ['./generate-settlement.component.css'],
  animations: [
    fade
  ]
})
export class GenerateSettlementComponent implements OnInit {

  serviceForm: FormGroup;
  sub: Subscription;
  queryMerchantId: number;
  MerchantId: any;
  submitted = false;
  merchantId;

  statusDesc;
  accountId;
  balance;
  settlementAmount;
  settlementAccount;
  settlementModeDesc;
  settlementCycleId;
  bankName;
  currency;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryMerchantId = +params['merchantId'];
        if (Number.isNaN(this.queryMerchantId)) {
          this.MerchantId = "";
        } else {
          this.MerchantId = this.queryMerchantId;
          // console.log("HHHH => ", this.MerchantId)
          this.getSettlementMerchantAccountInfo(this.MerchantId)
        }
      });

  }

  createForm() {
    this.serviceForm = this.fb.group({
      statusDesc: ['', Validators.required],
      settlementAccount: ['', Validators.required],
      settlementModeDesc: ['', Validators.required],
      balance: ['', Validators.required],
      settlementAmount: ['', Validators.required],
      settlementCycleId: ['', Validators.required],
      bankName: ['', Validators.required],
      accountId: ['', Validators.required]
    });
  }

  backToService() {
    this._location.back();
  }


 


  ngOnInit(): void {
  }

 

  getSettlementMerchantAccountInfo(merchantId) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId
    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.merchantId = data.merchantId;
      // console.log("MEEE=> ", data)
      this.spinner.hideSpinner();
      // this.serviceForm = this.fb.group({
      //   statusDesc: [data.profileStatusDesc, Validators.required],
      //   accountId: [data.accountId, Validators.required]
      // });
      this.statusDesc = data.profileStatusDesc;
      this.accountId = data.accountId;
      this.getMerchantAccountInfo(data.merchantId)
      this.fnGetAccountInfo(data.accountId, data.userId);
    },(failureResponse: string) => {
      this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

 

  fnGetAccountInfo(accountId, userId) {
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: userId,
      accountId: accountId
    };
    this.spinner.getSpinner();
    this.service.getUserAccountInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      // console.log("YYYYYY=>", data)
      let balance = data.currency + " " + (data.credit - data.debit);
      this.balance = balance;
      this.currency = data.currency;
      this.getMerchantAccountInfo(data.settlementAccountId);
      
      // this.fnGetAccountStatus(this.accountStatusId);
    },
      (failureResponse: string) => {
        this.spinner.hideSpinner();
         console.log("onFailure:" + JSON.stringify(failureResponse));
      });
  };

  getMerchantAccountInfo(settlementAccountId){
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      settlementAccountId: settlementAccountId
    };
    this.spinner.getSpinner();
    this.service.getSettlementAccountInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      // console.log("TTTTT=>", data)
      this.settlementAccount = data.settlementAccountId;
      this.settlementModeDesc = data.settlementModeDesc;
      this.settlementCycleId = data.settlementCycleId;
      this.bankName = data.issuerName;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  OnProcessSettlementRequest() {
    this.submitted = true;
    if(this.serviceForm.invalid){
      return;
    }

    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      merchantId: this.merchantId,
      accountId: this.accountId,
      amount: this.settlementAmount,
      doneByUserId: currentUser.userId,
      currency: this.currency
    };
    this.spinner.getSpinner();
    this.service.merchantSettlementRequest({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      // console.log("SSSSSSSSS=>", data)
    }, (failureResponse: any) => {
      this.toastr.error(`${failureResponse.error.message.error.code} Bad request issue`, 'Error');
      // console.log("onFailure:" + JSON.stringify(failureResponse.error.message.error.code));
    });

  }
}
