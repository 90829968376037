import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import * as moment from 'moment';


@Component({
  selector: 'app-dist-commission-by-month-report',
  templateUrl: './dist-commission-by-month-report.component.html',
  styleUrls: ['./dist-commission-by-month-report.component.css'],
  animations: [
    fade
  ]
})
export class DistCommissionByMonthReportComponent implements OnInit {


  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedValue;
  years;
  year;
  month;
  months = [{ no: 1, month: "January" }, { no: 2, month: "February" }, { no: 3, month: "March" }, { no: 4, month: "April" },
    { no: 5, month: "May" }, { no: 6, month: "June" }, { no: 7, month: "July" }, { no: 8, month: "August" }, { no: 9, month: "September" }, 
    { no: 10, month: "October" }, { no: 11, month: "November" }, { no: 12, month: "December" }];

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getUserLists();
    this.getCurrencyList();
  }

  getFrom() {
    var date = new Date,
      years = [],
      year = date.getFullYear();
    for (var i = year; i > year - 8; i--) {
      let record = { year: i }
      years.push(record);
    }
    this.year = year;
    this.years = years;
    this.month = date.getMonth();

    this.getForm = this.fb.group({
      year: ['', Validators.required],
      month: [date.getMonth(), Validators.required],
      selectedValue: [1, Validators.required],
      selectData: ['', Validators.required]
    });
  }

  ngOnInit(): void {

  }

  getCurrencyList(){
    let payload:any = {};

    this.service.getCurrencyList({payload:payload}).subscribe((res) => {
      try {
        if(!res){
          console.log("Errer")
        }else{
          let resObj = JSON.stringify(res.records)
          let record = JSON.parse(resObj)
          this.selectData = record;
          console.log("Data==>", record)
        }
      } catch (error) {
        console.log("Errer", error)
      }
    })
  }

  getUserLists() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  passDistributorCommissionSummaryByMonthReports() {
    this.submitted = true;

    if (this.getForm.invalid) return;
    let distributorId = this.getForm.value.selectData;
    let year = this.getForm.value.year;
    let month = this.getForm.value.month;
    let currencyId = this.getForm.value.selectedValue;
    let data: any = {
      distributorId: distributorId,
      year: year,
      month: month,
      currencyId: currencyId
    }

    this.router.navigate(["dashboard/view/distCommisionSummaryByMonth"], { queryParams:{data:btoa(JSON.stringify(data)) }});
    // this.router.navigate(["dashboard/view/distCommisionSummaryByMonth"], { queryParams: { distributorId, year, month, currencyId }});

  }

}
