import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

// import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PasswordValidators } from "./password-validators";

@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.css'],
  animations: [
    fade
  ]
})
export class ChangeUserPasswordComponent implements OnInit {

  updateResonForm: FormGroup;
  addressList;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService,
    private spinner: SpinnerService
  ) {
    this.updateResonsForm();
    this.getUserAddress();
    $(function () {
      $("#popover").popover();
    });
  }

  ngOnInit(): void {
  }

  updateResonsForm() {
    this.updateResonForm = this.fb.group({
      reson: ['', Validators.required],
    });
  }

  getUserAddress() {
    this.service.getIpAddress().subscribe(res => {
      console.log(res);
      this.addressList = res;
      console.log(window.location.href);
    })
  }

  submitted = false;
  isWorking = false;

  signupForm = new FormGroup(
    {
      // currentPassword: new FormControl(null, [Validators.required]),
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          // PasswordValidators.patternValidator(new RegExp("(?=.*[0-9])"), {
          //   requiresDigit: true
          // }),
          // PasswordValidators.patternValidator(new RegExp("(?=.*[A-Z])"), {
          //   requiresUppercase: true
          // }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[a-z])"), {
            requiresLowercase: true
          }),
          // PasswordValidators.patternValidator(new RegExp("(?=.*[$@^!%*?&])"), {
          //   requiresSpecialChars: true
          // })
        ])
      ),
      confirmPassword: new FormControl(null, [
        Validators.required,
        Validators.minLength(8)
      ])
    },
    {
      validators: PasswordValidators.MatchValidator
    }
  );

  // convenience getter for easy access to form controls
  get f() {
    return this.signupForm.controls;
  }

  get passwordValid() {
    return this.signupForm.controls["password"].errors === null;
  }

  get requiredValid() {
    return !this.signupForm.controls["password"].hasError("required");
  }

  get minLengthValid() {
    return !this.signupForm.controls["password"].hasError("minlength");
  }

  get requiresDigitValid() {
    return !this.signupForm.controls["password"].hasError("requiresDigit");
  }

  get requiresUppercaseValid() {
    return !this.signupForm.controls["password"].hasError("requiresUppercase");
  }

  get requiresLowercaseValid() {
    return !this.signupForm.controls["password"].hasError("requiresLowercase");
  }

  get requiresSpecialCharsValid() {
    return !this.signupForm.controls["password"].hasError("requiresSpecialChars");
  }

  onSubmitChangePassword() {
    this.submitted = true;
    if (this.signupForm.invalid) return;

    const currentUser = this._sessionStorageService.getSessionData();
    const payload: any = {
      loginId: currentUser.loginId,
      newPassword: this.signupForm.value.password,
      userId: currentUser.userId,
      ipAddress: this.addressList,
      changeReson: this.updateResonForm.value.reson,
      channel: 1
    }
    this.isWorking = true;
    this.signupForm.disable();
    this.spinner.getSpinner();
    this.service.changeUserPassword({ payload: payload }).subscribe(successResponse => {
      // if(successResponse.success){
      this.isWorking = false;
      this.signupForm.enable();
      if (successResponse.success) {
        const message = successResponse.records.loginId;
        this.toastr.success('You successfully changed password', "Success");
        $('#updateAccessModel').modal('hide');
        $(function () {
          $("#popover").popover('hide');
        });
        setTimeout(() => {
          this.router.navigate(["dashboard/home"])
        }, 1000);
      } else {
        this.spinner.hideSpinner();
        this.toastr.warning(successResponse.records.message);
      }
      // }else{
      //   this.spinner.hideSpinner();
      //   window.location.href = 'login';
      // }
    })
  }

  //   onSubmit() {
  //     this.submitted = true;

  //     if (this.signupForm.invalid) {
  //       return;
  //     }

  //     this.isWorking = true;
  //     this.signupForm.disable();

  //     setTimeout(() => {
  //       this.isWorking = false;
  //       this.signupForm.enable();
  //     }, 1500);
  //   }
}