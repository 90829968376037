import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpUrlEncodingCodec } from '@angular/common/http';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-chargebacks',
  templateUrl: './chargebacks.component.html',
  styleUrls: ['./chargebacks.component.css'],
  animations: [
    fade
  ]
})
export class ChargebacksComponent implements OnInit {
  codec = new HttpUrlEncodingCodec;
  getForm: FormGroup;
  getSubmitted = false;
  selectData;
  tranStatusId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
  }

  getFrom() {
    this.getForm = this.fb.group({
      payerId: ['', Validators.required],
      currentDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }


  passChargeBack() {
    this.getSubmitted = true;
    if (this.getForm.invalid) {
      return;
    }
    // let payerId = encodeURI(this.getForm.value.payerId);
    // let currentDate = encodeURI(this.getForm.value.currentDate);
    const payerId = encodeURIComponent(btoa(this.getForm.value.payerId));
    const currentDate = encodeURIComponent(btoa(this.getForm.value.currentDate));
    console.log(payerId);
    // const dec = atob(decodeURIComponent(enc));
    console.log(currentDate);
    this.router.navigate(["dashboard/view/chargebackList"], { queryParams: { payerId, currentDate } })
  }
}
