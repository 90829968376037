<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row" *ngIf="showDistributor">
          <div class="col-md-10">
            <h4 class="card-title">{{ merchantTitle }}</h4>
          </div>
          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>
        <p class="card-description" *ngIf="showDistributor == false">
          Card Pending Requests
        </p>
        <form
          *ngIf="showDistributor == false"
          class="forms-sample"
          [formGroup]="getDistrForm"
          novalidate
          (ngSubmit)="handleSubmitDistr()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <select
                  class="form-control-sm"
                  name="selectData"
                  formControlName="selectData"
                  [ngClass]="{
                    'is-invalid':
                      getSubmitted && getDistrForm.controls.selectData.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distributorList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="
                    getSubmitted && getDistrForm.controls.selectData.errors
                  "
                  class="text-danger"
                >
                  <div
                    *ngIf="getDistrForm.controls.selectData.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>
        <div class="row" style="margin-bottom: 0px" *ngIf="showDistributor">
          <!-- <div class="col-md-2">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control form-control"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div> -->

          <div class="col-md-2">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control form-control mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>

          <div class="col-md-10">
            <form
              class="forms-sample"
              [formGroup]="getForm"
              novalidate
              (ngSubmit)="handleSubmit()"
            >
              <div class="row">
                <div class="col-md"></div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="startDate">Start Date</label>
                    <input
                      type="date"
                      class="form-control-sm"
                      formControlName="startDate"
                      placeholder="Start Date"
                    />
                    <div
                      *ngIf="submitted && getForm.controls.startDate.errors"
                      class="text-danger"
                    >
                      <div *ngIf="getForm.controls.startDate.errors.required">
                        Start Date is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="endDate">End Date</label>
                    <input
                      type="date"
                      class="form-control-sm"
                      formControlName="endDate"
                      placeholder="End Date"
                    />
                    <div
                      *ngIf="submitted && getForm.controls.endDate.errors"
                      class="text-danger"
                    >
                      <div *ngIf="getForm.controls.endDate.errors.required">
                        End Date is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md">
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style="margin-top: 30px"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="table-responsive" *ngIf="showDistributor">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Card Holder Name</th>
                <th>Card</th>
                <th>Mobile Number</th>
                <th>Print Location</th>
                <th>Print Uid</th>
                <th>Date</th>
                <th>Status</th>
                <!-- <th>Print</th> -->
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.recordId }}</td>
                <td>{{ x.cardholderName }}</td>
                <td>{{ x.cardNumber }}</td>
                <td>{{ x.mobileNumber }}</td>
                <td>{{ x.printLocation }}</td>
                <td>{{ x.printUid }}</td>

                <td>{{ x.requestedDate | date : "dd-MMM-yy" }}</td>
                <td class="py-1">
                  {{ x.status == "PANParsed" ? "Ready To Print" : x.status }}
                </td>

                <!-- <td (click)="passCardPrint(x)">
                  <button type="button" class="btn btn-outline-primary btn-sm">
                    {{ x.printName }}
                  </button>
                </td> -->
                <td (click)="SelectedRecodrd(x)">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#updateSubscriptionModel"
                    (click)="getListOfStations(x)"
                    class="btn btn-outline-primary btn-sm mr-2"
                  >
                    Redirect
                  </button>
                  <button
                    *ngIf="x.status == 'Accepted'"
                    type="button"
                    (click)="cardReprint(x)"
                    class="btn btn-outline-primary btn-sm"
                  >
                    Request Physical Card
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div
  class="modal fade bd-example-modal-md"
  id="updateAccessModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Update Status Profile</h4>
              <form
                class="forms-sample"
                [formGroup]="updateAccessForm"
                (ngSubmit)="onUpdateAccessProfileInfo()"
              >
                <div class="card-subtitle">Access Profile</div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Status</label>
                  <select class="form-control" formControlName="selectedAccess">
                    <option value="null" disabled selected>--Select--</option>
                    <option
                      *ngFor="let p of selectAccess"
                      [ngValue]="p.profileStatusId"
                    >
                      {{ p.profileStatusDesc }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      updateAccessForm.controls.selectedAccess.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        updateAccessForm.controls.selectedAccess.errors.required
                      "
                    >
                      Status Profile is required
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div
  class="modal fade bd-example-modal-md"
  id="updateSubscriptionModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Stations</h4>
              <form
                class="forms-sample"
                [formGroup]="stationsForm"
                (ngSubmit)="HandleSubmitUpdateStations()"
              >
                <!-- <div class="card-subtitle">Select Station</div> -->
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Select Station</label>
                  <select
                    class="form-control"
                    formControlName="instutitionBranchId"
                  >
                    <option value="null" disabled selected>--Select--</option>
                    <option
                      *ngFor="let p of ListOfStations"
                      [ngValue]="p.instutitionBranchId"
                    >
                      {{ p.location }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      stationsForm.controls.instutitionBranchId.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        stationsForm.controls.instutitionBranchId.errors
                          .required
                      "
                    >
                      Branch is required
                    </div>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
