import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { fade } from 'src/app/animations/animations';


@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.css'],
  animations: [
    fade
  ]
})
export class CashierComponent implements OnInit {
  _USER_TYPE_ID_CASHIER = 40;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      merchantId: ['', Validators.required],
    });
  }


  ngOnInit(): void {
  
  }

  passMerchantCashiersView(){
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }

    let merchantId = this.serviceForm.value.merchantId
    this.router.navigate(["dashboard/view/getCashierList"], { queryParams: { merchantId } })
  }

}
