import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
declare var $: any;


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
  animations: [
    fade
  ]
})
export class ProductListComponent implements OnInit {
  dataList;

  selected = pagination.selected;
  p: number = pagination.p;
  term;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getProductList();
  }

  ngOnInit(): void {
  }

  getProductList() {
    let payload: any = { PdistributorId: 0 };
    this.spinner.getSpinner();
    this.service.getProductList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.toastr.error("Something went wrong")
        this.spinner.hideSpinner();
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passCreateProductFields() {
    var currentUser = this._sessionStorageService.getSessionData();
    let userId = currentUser.userId;
    this.router.navigate(['dashboard/view/createProductInfo'], { queryParams: { userId } });
  }

  passViewProductInfo(product) {
    let productId = product.productId;
    this.router.navigate(['dashboard/view/getProductInfo'], { queryParams: { productId } });
  }

  passViewProductProcessInfo(product) {
    let productId = product.productId;
    let productName = product.productDesc;
    this.router.navigate(['dashboard/view/listProductServices'], { queryParams: { productId: productId, productName: productName } });
  }

}
