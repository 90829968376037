import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service"
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';



@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css'],
  animations: [
    fade
  ]
})
export class UpdateComponent implements OnInit {
  queryParamId: number;
  sub: Subscription;
  paramId: any;
  dataList;
  sysForm: FormGroup;
  submitted = false;
  modal;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.queryParamId = +params['paramId'];
        if (Number.isNaN(this.queryParamId)) {
          this.paramId = "";
        } else {
          this.paramId = this.queryParamId;
          this.viewSysconfigParamInfo(this.paramId)
        }
      });
  }

  createForm() {
    this.sysForm = this.fb.group({
      paramId: [''],
      paramName: ['', Validators.required],
      paramCode: ['', Validators.required],
      paramValue: ['', Validators.required],
    });
  }


  ngOnInit(): void {
  }

  viewSysconfigParamInfo(paramId) {
    let payload: any = {
      paramId: paramId,
    };
    this.spinner.getSpinner();
    this.service.getSysconfigParamInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      this.spinner.hideSpinner();
      var data = JSON.parse(responseObj);
      // console.log("UUUU", responseObj)
      this.sysForm = this.fb.group({
        paramName: [data.paramName, Validators.required],
        paramCode: [data.paramCode, Validators.required],
        paramValue: [data.paramValue, Validators.required],
      });
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  updateSystemConfig() {
    this.submitted = true;

    if (this.sysForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      paramId: this.paramId,
      paramCode: this.sysForm.value.paramCode,
      paramValue: this.sysForm.value.paramValue,
      paramName: this.sysForm.value.paramName,
    };
    // console.log("Payload", payload)
    this.spinner.getSpinner();
    this.service.updateSystemConfigInfo(payload).subscribe(successResponse => {

      if (successResponse.id) {
        $('#viewModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success(`You have created Id ${successResponse.id}`,'Seccessfully')
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  backtowebrole() {
    this.location.back()
  }



}
