import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-card-distributors-component',
  templateUrl: './card-distributors-component.html',
  styleUrls: ['./card-distributors-component.css'],
  animations: [
    fade
  ]
})
export class CardDistributors implements OnInit {
  listBody: boolean;
  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectAccData;
  distDataList;
  p: number = pagination.p;
  selectedData;
  selected = 100;
  TranType;
  selected1Data;
  institutionId;
  institutionName;
  monthsList;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getInstitutionList();
  }

  getFrom() {
    this.getForm = this.fb.group({
      // selectDistributor: ['', Validators.required],
      // SelectedMonth: ['', Validators.required],
      transactionDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }
  getInstitutionList() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      distribtorID: 0
    };
    this.spinner.getSpinner();
    this.service.getInstitutionList({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        this.listBody = true;
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distDataList = records;
        // console.log("kkkk", records)
      } else {
        this.listBody = false;
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.listBody = false;
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }



  handleSubmit() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.getForm.value.selectDistributor,
    };
    let pdays = this.getForm.value.selectedValue
    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        let institutionId = data.institutionId
        this.institutionId = data.institutionId
        this.institutionName = data.institutionName
        // this.PassViewReport(this.institutionId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  PassViewReport() {
    this.submitted = true;

    if (this.getForm.invalid) return;

    let transactionDate = this.getForm.value.transactionDate;

    this.router.navigate(["dashboard/view/CardMcHourlyReport"], { queryParams: { transactionDate } })
  }
  toggleAllowPhysicalRequest(data) {
    let payload: any = {
      institutionCode: data.institutionCode,
    };

    this.spinner.getSpinner();
    this.service.physicalCardToggle({ payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        this.getInstitutionList()
      } else {
        this.toastr.error(successResponse.records.message)
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

}
