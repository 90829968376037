<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-11">
            <h4 class="card-title">Gl Account Summary Reports</h4>
          </div>
          <div class="col-md-1">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
                <!-- <i class="ti-printer menu-icon"></i> -->
              </button>
            </h4>
          </div>
        </div>
        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-3">
            <input
              type="text"
              class="form-control-sm"
              [(ngModel)]="term"
              placeholder="Search here..."
              style="float: left"
            />
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                (click)="print()"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="fnPDF()"
                type="button"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="button"
                (click)="exportAsXLSX()"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>

          <!-- <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div> -->
        </div>
        <div class="table-responsive" id="section-one">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>GL AccountId</th>
                <th>Account No</th>
                <th>Account Title</th>
                <th>Previous Balance</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Balance</th>
                <!-- <th>Action</th> -->
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td>{{ x.accountId }}</td>
                <td>{{ x.accountNo }}</td>
                <td>{{ x.accountDesc }}</td>
                <td>{{ x.previousBalance }}</td>
                <td>{{ x.debit | number : "1.2-2" }}</td>
                <td>{{ x.credit | number : "1.2-2" }}</td>
                <td>{{ x.balance | number : "1.2-2" }}</td>
                <!-- <td (click)="passTransactionInfo(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">process</button>
                                </td> -->
              </tr>
              <tr style="color: green; font-weight: bold">
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{{ sumPrevious | number : "1.2-2" }}</td>
                <td>{{ sumDebit | number : "1.2-2" }}</td>
                <td>{{ sumCredit | number : "1.2-2" }}</td>
                <td>{{ sumTotal | number : "1.2-2" }}</td>
                <!-- <td (click)="passTransactionInfo(x)">
                                    <button type="button" class="btn btn-outline-primary btn-sm">process</button>
                                </td> -->
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
