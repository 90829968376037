import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css'],
  animations: [
    fade
  ]
})
export class CreateProductComponent implements OnInit {
  serviceForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  querypaymentProcessorId: number;
  paymentProcessorId: any;
  dataRecords: any;
  sub: Subscription;
  selectProductOwnerData;
  selectProductTypeData;
  queryUserId: number;
  UserId: any;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getProductOwnerLists();
    this.getProductTypesList();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryUserId = +params['userId'];
        if (Number.isNaN(this.queryUserId)) {
          this.UserId = "";
        } else {
          this.UserId = this.queryUserId;
        }
      });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      productName: ['', Validators.required],
      productDesc: ['', Validators.required],
      selectProductOwnerValue: ['', Validators.required],
      selectProductTypeValue: ['', Validators.required]
    });
  }

  backToService() {
    this._location.back();
  }

  ngOnInit(): void {
  }

  getProductOwnerLists(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getProductOwnerList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectProductOwnerData = records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  getProductTypesList(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getProductTypes({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectProductTypeData = records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  OnCreateProductInfo(){
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
    let payload: any = {
      productName: this.serviceForm.value.productName,
      productDesc: this.serviceForm.value.productDesc,
      productOwner: this.serviceForm.value.selectProductOwnerValue,
      productType: this.serviceForm.value.selectProductTypeValue,
      createdByUserId: this.UserId
    };
    this.spinner.getSpinner();
    this.service.createProduct(payload).subscribe(successResponse => {

      if (successResponse.id) {
        this.spinner.hideSpinner();
        this.toastr.success(`You have created Id ${successResponse.id}`,'Seccessfully')
        this.router.navigate(['dashboard/listProductInfo']);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


}
