import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
    providedIn: 'root'
  })
export class SpinnerService {

    constructor(private spinner: NgxSpinnerService){

    }
    getSpinner(){
        this.spinner.show(undefined, {
            type: "ball-fussion",
            size: "medium",
            // bdColor: "rgba(1, 1, 1, 0.9)",
            color: "#009e44",
            fullScreen: true
          });
    }


    hideSpinner(){
        this.spinner.hide();
    }

}