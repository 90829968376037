<div @fade class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Merchant Daily Summary By Distributor Reports</h4>
                <p class="card-description">
                    Summary By Distributor Reports
                </p>
                <form class="forms-sample" [formGroup]="getForm" novalidate
                    (ngSubmit)="passMerchantDailySummaryByDistReports()">

                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="selectData">Distributor</label>
                                <select class="form-control-sm" name="selectData" formControlName="selectData"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.selectData.errors }">
                                    <option [selected]="true" value=null disabled selected>--Select--</option>
                                    <option *ngFor="let c of dataList" [ngValue]="{ id: c.referenceId, name: c.userTitle}">{{c.userTitle}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && getForm.controls.selectData.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.selectData.errors?.required">
                                        Select Distributor is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="selectedGroupValue">Tran Type As Group</label>
                                <select class="form-control-sm" formControlName="selectedGroupValue"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.selectedGroupValue.errors }">
                                    <option *ngFor="let c of selectGroupData" [ngValue]="c.tranTypeGroupId">
                                        {{c.tranTypeGroup}}</option>
                                </select>
                                <div *ngIf="submitted && getForm.controls.selectedGroupValue.errors"
                                    class="text-danger">
                                    <div *ngIf="getForm.controls.selectedGroupValue.errors?.required">
                                        Tran Type As Group is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="selectedValue">Currency</label>
                                <select class="form-control-sm" formControlName="selectedValue"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.selectedValue.errors }">
                                    <option *ngFor="let c of selectData" [ngValue]="c.currencyId">{{c.currencyCode}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && getForm.controls.selectedValue.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.selectedValue.errors?.required">
                                        Currency is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="startDate">Start Date</label>
                                <input type="date" class="form-control-sm" formControlName="startDate"
                                    placeholder="Start Date"
                                    [ngClass]="{ 'is-invalid': submitted && getForm.controls.startDate.errors }">
                                <div *ngIf="submitted && getForm.controls.startDate.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.startDate.errors?.required">
                                        Start Date is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 mt-2">
                            <div class="form-group mt-4">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>