import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-blocked-cards-report-Distr.component',
  templateUrl: './blocked-cards-report-Distr.component.html',
  styleUrls: ['./blocked-cards-report-Distr.component.css'],
  animations: [
    fade
  ]
})
export class BlockedCardsReportDistr implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedDate;
  distdataList;
  selectedData;
  TranType;
  selected1Data;
  institutionId;
  institutionName;
  monthsList;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getDistributorList();

    this.monthsList = [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" }
    ];
  }

  getFrom() {
    this.getForm = this.fb.group({
      selectDistributor: [0],
      mobileNumber: [''],
      // endDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }
  getDistributorList() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    // this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        // this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distdataList = records;
        // console.log("kkkk", records)
      } else {
        // this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  handleSubmit() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.getForm.value.selectDistributor,
    };
    let pdays = this.getForm.value.selectedValue
    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        // this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        let institutionId = 0
        // this.institutionId = data.institutionId
        if (this.getForm.value.selectDistributor != "0") {
          institutionId = data.institutionId
        } else {
          institutionId = 0;
        }

        this.institutionName = data.institutionName
        this.PassCardSummaryByMonthReport(institutionId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  PassCardSummaryByMonthReport(institutionId) {
    this.submitted = true;

    if (this.getForm.invalid) return;
    let pNumber = this.getForm.value.mobileNumber;

    this.router.navigate(["dashboard/view/BlockedCardsReport"], { queryParams: { institutionId, pNumber, } })
  }


}
