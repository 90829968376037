import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from 'src/app/service/service/service.service';
import { SessionStorageService } from 'src/app/service/session-storage/session-storage.service';
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.css'],
  animations: [
    fade
  ]
})
export class AddPaymentMethodComponent implements OnInit {


  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  _USER_TYPE_ID_CASHIER = "40";
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  querymerchantId: any;
  sub: any;
  selectCycle;
  selectProccesor;

  selected = pagination.selected;
  p: number = pagination.p;
  term;
  dataLists;
  merchantList;
  cashierTypeList;
  selectedData;
  isApproved: boolean = false;
  validData = 'abc';
  addressList;



  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getUserAddress();
    this.updatedCycleForm();
    this.getMerchantPaymentProccessorInfo();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.querymerchantId = +params['merchantId'];
        if (Number.isNaN(this.querymerchantId)) {
          this.merchantId = "";
        } else {
          this.merchantId = this.querymerchantId;
          console.log("HHHH => ", this.merchantId)
          this.getMerchantInfo(this.querymerchantId)
        }
      });
  }

  ngOnInit(): void {

  }

  getUserAddress() {
    this.service.getIpAddress().subscribe(res => {
      console.log(res);
      this.addressList = res;
      console.log(window.location.href);
    })
  }

  backToCashier() {
    this._location.back();
  }

  updatedCycleForm() {
    this.updateCycleForm = this.fb.group({
      merchantId: ['', Validators.required],
      merchantUid: ['', Validators.required],
      merchantName: ['', Validators.required],
      selectedCycle: ['', Validators.required],
      selectData: ['', Validators.required],
      paymentProcessor: ['', Validators.required],
      reson: ['', Validators.required],
    });
  }


  getMerchantPaymentProccessorInfo() {
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getPaymentProcessorList({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      console.log("data111 :=> ", records);
      this.selectProccesor = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  getMerchantInfo(merchantId) {
    console.log("YYYYY=>", merchantId)
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId,
    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if(successResponse.success){
        this.spinner.hideSpinner();
        this.merchantList = data;
        this.updateCycleForm = this.fb.group({
          merchantId: [data.merchantId, Validators.required],
          merchantUid: [data.merchantUid, Validators.required],
          merchantName: [data.userTitle, Validators.required],
          paymentProcessor: ['', Validators.required],
          reson: ['', Validators.required],
        });
      }else{
        this.toastr.warning(data.message, 'Warning')
      }

    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  multiCreate(){
    this.onCreateMerchantPaymentProccesor();
    this.fnCreateEventLogs();
  }

  onCreateMerchantPaymentProccesor(){
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateCycleForm.invalid) {
      return;
    }
    let payload: any = {
      merchantId: this.merchantList.merchantId,
      paymentProccessorId: this.updateCycleForm.value.paymentProcessor.paymentProcessorId,
      isEnabled: 1,
      createdByUserId: currentUser.userId
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.createMerchantPmtProcessor({ payload: payload }).subscribe(successResponse => {
      var responseObj = successResponse.records;
      var data = responseObj;
      console.log("UUUU=>", successResponse) 
      if(successResponse.success){
        if (data.seqId) {
          this.toastr.success(`You have updated payment Id is ${data.seqId}`, 'Successfully')
          $('#updateliveModel').modal('hide');
          this.spinner.hideSpinner();
          this._location.back();
        } else {
          this.spinner.hideSpinner();
          this.toastr.error('InComing request is wrong', 'Error')
        }
      }else{
        this.toastr.warning(successResponse.error, 'Warning')
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  fnCreateEventLogs() {
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: this.merchantList.userId,
      eventTypeId: 8,
      previousValue: "New Payment Method",
      newValue: "(" + this.updateCycleForm.value.paymentProcessor.paymentProcessorTypeDesc +")",
      reson: this.updateCycleForm.value.reson,
      sessionId: currentUser.referenceId,
      address: JSON.stringify(this.addressList),
      ipAddress: this.addressList.IPv4,
      applicationId: currentUser.userTypeId,
      doneByUserId: currentUser.userId,
    };
    console.log("jajaHHH", payload)
    this.service.createEventLogs({ payload: payload }).subscribe((res) => {
      console.log(res)

    })
  }


}
