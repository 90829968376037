import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-issuing-summary-report.component',
  templateUrl: './issuing-summary-report.component.html',
  styleUrls: ['./issuing-summary-report.component.css'],
  animations: [
    fade
  ]
})
export class IssuingSummaryReportComponent implements OnInit {
  public lineChartData: ChartDataset[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];
  public lineChartLabels: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartOptions: ChartOptions = {
    responsive: true,
  };
  public lineChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  // data
  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  Title;
  lisCustomers;
  ListOfMethods;
  currentRecordId;
  currentCardId;
  selected = 1000;
  // selected = pagination.selected;
  p: number = pagination.p;
  term;
  currentMobileNumber;
  numberOfdays;
  showMerchantForm: string = 'true';
  institutionId;
  chartData;
  chartLabels;
  chartColors;
  chartType;
  chartLegend;
  distdataList
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    // this.showMerchantForm = true
    this.getDistributorList();
    // this.getInstitution();
    this.getFrom();
    this.numberOfdays = [{ id: 1, name: "Today" }, { id: 3, name: "Last 3 Days" }, { id: 7, name: "Last 7 Days" }, { id: 30, name: "Last 30 Days" }]
  }
  getFrom() {
    this.getForm = this.fb.group({
      selectedValue: ['', Validators.required],
      selectDistributor: ['', Validators.required],
    });
  }
  getDistributorList() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distdataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  // getInstitution() {
  //   const currentUser = this._sessionStorageService.getSessionData();
  //   let payload: any = {
  //     // distributorId: this.distributorId
  //     distributorId: currentUser.referenceId,
  //   };
  //   this.spinner.getSpinner();
  //   this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
  //     if (successResponse.records) {
  //       this.spinner.hideSpinner();
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       this.institutionId = data.institutionId
  //       // console.log("kkkk", records)
  //     } else {
  //       this.spinner.hideSpinner();
  //       this.toastr.error(successResponse.records.message)
  //     }
  //   }, (failureResponse: any) => {
  //     if (failureResponse.status === 401) {
  //       window.location.href = 'login';
  //     } else {
  //       this.spinner.hideSpinner();
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }
  ngOnInit(): void {
    this.Title = "Issuing Summary Report"
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
    } else {
      this.isThirtParty = false;

    }
  }

  backToService() {
    // this._location.back();
    this.showMerchantForm = "true"
    this.listData = []

  }
  formatTransactionAmount(amount) {
    if (amount === null || amount === undefined || amount === '') {
      return '0.00';
    }
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  handleSubmit() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.getForm.value.selectDistributor,
    };
    let pdays = this.getForm.value.selectedValue
    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        // this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        let institutionId = data.institutionId
        // this.institutionId = data.institutionId
        this.issuingSummary(institutionId, pdays)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  issuingSummary(institutionId, pdays) {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      institutionId: institutionId,
      pDays: pdays,
    };

    this.spinner.getSpinner();
    this.service.issuingSummary({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;
          this.showMerchantForm = 'false'

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          this.toastr.error(this.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Data Not Found")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }


  SelectedRecodrd(data) {
    this.currentRecordId = data.recordId;
    this.currentCardId = data.cardId;
    this.currentMobileNumber = data.mobileNumber;
  }
}
