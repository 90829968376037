import { Injectable } from '@angular/core';
import { ExpressRequestService } from '../express-request/express-request.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor(private expressStorage: ExpressRequestService) {

  }

  setSessionData(res: string) {
    var userData = JSON.stringify(res);
    localStorage.setItem('currentUser', userData);
    //console.log("currentUser - SET :=> " + userData);
  }

  getSessionData() {
    var currentUser = window.localStorage.getItem('currentUser');
    var data = JSON.parse(currentUser);
    //console.log("currentUser - GET :=> " + JSON.stringify(data));
    return data;
  }

  setToken(res: string) {
    var userData = JSON.stringify(res);
    localStorage.setItem('token', userData);
    //console.log("currentUser - SET :=> " + userData);
  }
  twofactorSessionData() {
    var encryptedData = sessionStorage.getItem('sessionData');
    return encryptedData;
  }
  twofactorSessionDataRemove() {
    sessionStorage.removeItem('sessionData');
  }
  setIUserPermissions(res: any) {
    // var userData = JSON.stringify(res);
    var records = JSON.parse(res);
    var userPer: any = this.expressStorage.encryptData(records);
    localStorage.setItem('perData', userPer);
    //console.log("currentUser - SET :=> " + userData);
  }
  getPermissions() {
    var perData = window.localStorage.getItem('perData');
    var userPermessions: any = JSON.stringify(this.expressStorage.decryptData(perData));
    return userPermessions;
  }
  checkPermission(permissionName: string) {
    const perData = window.localStorage.getItem('perData');
    if (!perData) {
      return 'false';
    }

    // Decrypt and parse the permissions data
    const userPermessions: any = this.expressStorage.decryptData(perData);

    // Ensure the decrypted data is an array
    if (!userPermessions || !Array.isArray(userPermessions)) {
      return 'false';
    }


    // Check if the permission exists in the array
    return userPermessions.some(permission => permission.permissionName === permissionName) ? 'true' : 'false';
  }


  setIsSignInData(res: string) {
    var userData = JSON.stringify(res);
    localStorage.setItem('IsSignIn', userData);
    //console.log("currentUser - SET :=> " + userData);
  }

  getIsSignInData() {
    var currentUser = window.localStorage.getItem('IsSignIn');
    var data = JSON.parse(currentUser);
    //console.log("currentUser - GET :=> " + JSON.stringify(data));
    return data;
  }
  // removeIsSignInData() {
  //   var currentUser = localStorage.clear();;
  //   //console.log("currentUser - GET :=> " + JSON.stringify(data));
  //   return currentUser;
  // }


  getToken() {
    var currentUser = window.localStorage.getItem('token');
    var data = JSON.parse(currentUser);
    //console.log("currentUser - GET :=> " + JSON.stringify(data));
    return data;
  }

  removeSessionData() {
    window.localStorage.removeItem('currentUser');
    //console.log("currentUser - Removed.");
  }

  setAccountId(res: string) {
    // var userData = JSON.stringify(res);
    window.localStorage.setItem('accountId', res);
    //console.log("AccountId - SET :=> " + userData);
  }
  getAccountId() {
    var account = window.localStorage.getItem('accountId');
    //console.log("AccountId - GET :=> " + JSON.stringify(data));
    return account;
  }

  setPermissionData(res: string) {
    var userData = JSON.stringify(res);
    window.localStorage.setItem('permission', userData);
    //console.log("currentUser - SET :=> " + userData);
  }

  getPermissionData() {
    var currentUser = window.localStorage.getItem('permission');
    var data = JSON.parse(currentUser);
    //console.log("currentUser - GET :=> " + JSON.stringify(data));
    return data;
  }

}
