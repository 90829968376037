import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'src/app/service/service/spinner.service'
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';


@Component({
  selector: 'app-accounts-table',
  templateUrl: './accounts-table.component.html',
  styleUrls: ['./accounts-table.component.css'],
  animations: [
    fade
  ]
})
export class AccountsTableComponent implements OnInit {

  getForm: FormGroup;
  submitted = false;
  _POS_PURCHASE: any = 1;
  _EPAYMENT: any = 4;

  listData;
  listBody: boolean;
  searchButtonText;
  errorMessageList: boolean;

  statusData;
  selectedValue;

  merchantId: any;
  sub: any;
  message;
  data;

  accountId;
  isTrue = false;

  queryMerchantId: any;
  queryTranStatusId: any;
  selectedMerchantValue: any;
  value = false;
  selectData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  merchantDefAccount = false;
  merchInfo;


  constructor(
    private _location: Location,
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    // this.selectData 
    // this.selected.push();

    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.queryMerchantId = +params['merchantId'];
        if (Number.isNaN(this.queryMerchantId)) {
          this.merchantId = "";
        } else {
          this.merchantId = this.queryMerchantId;
          this.fnGetMerchantAccountList(this.queryMerchantId);
          this.fnGetMerchantDefaultAccount(this.queryMerchantId);
          this.getMerchantInfo(this.queryMerchantId);
        }
        //this.fnListTransactionHistoryWithMerchantId(this.queryMerchantId);
      });

    this.accountId = +this._sessionStorageService.getAccountId();
  }

  ngOnInit(): void {

  }

  onPrintTables(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();;
  }

  getMerchantInfo(merchantId){
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId
    };

    this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      
      console.log("msg: ", responseObj)
      if (!successResponse.records.message) {
        this.data = successResponse.message;
        this.listBody = true;
        this.spinner.hideSpinner();
      }
      if (successResponse.success) {
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
         this.merchInfo = data;
         this.listBody = true;
        console.log("All ", data)
      } else {
        this.spinner.hideSpinner();
        this.message = data.message;
        this.spinner.hideSpinner();
        this.listBody = false;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  fnGetMerchantDefaultAccount(merchantId) {
    let payload: any = {
      merchantId: merchantId

    };
    if (merchantId != null) {
      payload.merchantId = merchantId;
      this.merchantId = merchantId;
    } else {
      payload.merchantId = this.merchantId;
    };
    // console.log("payload", payload)
    this.spinner.getSpinner();
    this.service.getMerchantDefaultAccount({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.data = successResponse.message;
      // console.log("msg: ", data)
      if (!successResponse.records.message) {
        this.listBody = true;
        this.spinner.hideSpinner();
      }
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var records = data;
        this.accountId = records.accountId;
        // console.log("All ", records)
      } else {
        this.spinner.hideSpinner();
        this.message = data.message;
        this.spinner.hideSpinner();
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  isAllSelected(x) {
    // console.log("Data=> ", x)
  }

  backToService() {
    this._location.back();
  }

  fnGetMerchantAccountList(merchantId) {
    this.listBody = false;
    // const currentUser = this.sessionStorage.getSessionData();
    let payload: any = {
      merchantId: merchantId

    };
    if (merchantId != null) {
      payload.merchantId = merchantId;
      this.merchantId = merchantId;
    } else {
      payload.merchantId = this.merchantId;
    };
    // console.log("payload", payload)

    this.spinner.getSpinner();
    this.service.getMerchantAccountList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.data = successResponse.message;
      // console.log("msg: ", data)
      if (!successResponse.records.message) {
        this.listBody = true;
      }
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var records = data;
        // console.log("All ", records)
        this.listData = records;
        this.listBody = true;
        this.isTrue = false;
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.listData = null;
        this.isTrue = true;
        this.message = data.message;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };

  updateMerchantDefAccountIds(data: any) {
    this.merchantDefAccount = false;
   
    let payload = {
      merchantId: this.merchantId,
      accountId: data.accountId
    };
    // console.log("HHHH", data)
    this._sessionStorageService.setAccountId(data.accountId);
    this.accountId = payload.accountId;

    this.spinner.getSpinner();

    this.service.updateMerchantDefAccountId(payload).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);

      // console.log("No Data ###", data);
      if (data != null) {
        this.spinner.hideSpinner();
        this.fnGetMerchantAccountList(this.merchantId)
        this.toastr.success('Successfully changed your account!', 'Success');
        // window.location.reload();
        // this.merchantDefAccount = true;
        // this.MerchantDefAccount = data.success;
      } else {
        this.listBody = false;
        this.merchantDefAccount = false;
        this.toastr.warning('No Records Found');
        this.spinner.hideSpinner();
        // console.log("No Record", this.listBody);
        //$scope.fnResultMessage("divtransactionstatuslist", _FAILUREMESSAGE, "No Records Found"); 
      };
    }, (failureResponse: string) => {
      this.toastr.error("Server seems got bad request!");
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });

    // console.log('AccountID',  payload)

  }

  viewMerchantAccountsInfo(x) {
    let merchantId = this.merchantId;
    let accountId = x.accountId;
    this.router.navigate(["dashboard/view/getMerchantAccountInfo"], {queryParams: {merchantId, accountId}});
  }

  fnViewMerchantSettlementInfo(x){
    let settlementAccountId = x.settlementAccountId;
    let accountId = x.accountId;
    let currencyId = x.currencyId;
    this.router.navigate(["dashboard/view/getMerchantSettlementAccount"], {queryParams: {settlementAccountId, accountId, currencyId}});
  }


  createMerchantAccount(){
    let userId = this.merchInfo.userId;
    let createdByUserId = this.merchInfo.createdByUserId;
    this.router.navigate(["dashboard/view/createMerchantAccount"], { queryParams: { userId, createdByUserId } });
  }
}
