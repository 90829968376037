<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ Title }}</h4>
          </div>

          <div class="col-md-2" *ngIf="showForm === 'false'">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <form
          [formGroup]="getForm"
          class="forms-sample"
          novalidate
          *ngIf="showForm === 'true'"
          (ngSubmit)="handleSubmit()"
        >
          <div class="row">
            <div class="col-md">
              <div class="form-group">
                <label for="selectDistributor">Select Distributor</label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      getSubmitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="selectedValue">Select Tran Type</label>
                <select
                  class="form-control-sm"
                  formControlName="selectedtranType"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectedtranType.errors
                  }"
                >
                  <option value="null">Select a TranType</option>
                  <option *ngFor="let c of TranType" [ngValue]="c.id">
                    {{ c.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectedtranType.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectedtranType.errors?.required"
                  >
                    please Select Tran Type
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="selectedValue">Select Mode</label>
                <select
                  class="form-control-sm"
                  formControlName="selectedmode"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectedmode.errors
                  }"
                >
                  <option value="null">Select Mode</option>
                  <option *ngFor="let c of modes" [ngValue]="c.id">
                    {{ c.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectedmode.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.selectedmode.errors?.required">
                    please Select Mode
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md">
              <div class="form-group">
                <label for="seleselectedcountryctedValue">Select Country</label>
                <select
                  class="form-control-sm"
                  formControlName="selectedcountry"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectedcountry.errors
                  }"
                >
                  <option value="null">Select Country</option>
                  <option *ngFor="let c of countriesList" [ngValue]="c.name">
                    {{ c.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectedcountry.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectedcountry.errors?.required"
                  >
                    please Select country
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md">
              <div class="form-group">
                <label for="startDate">Start Date</label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="startDate"
                  placeholder="Start Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.startDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.startDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.startDate.errors?.required">
                    Start Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="endDate">End Date</label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="endDate"
                  placeholder="End Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.endDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.endDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.endDate.errors?.required">
                    End Date is required
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>

        <div
          class="row"
          style="margin-bottom: 0px"
          *ngIf="showForm === 'false'"
        >
          <div class="col-md-2">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
        </div>

        <div class="table-responsive" *ngIf="showForm === 'false'">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th
                  (click)="sortData('transactionDate')"
                  style="cursor: pointer"
                  class="sortable-header"
                >
                  TransactionDate
                </th>
                <th
                  (click)="sortData('totalTransactions')"
                  style="cursor: pointer"
                  class="sortable-header"
                >
                  TotalTransactions
                </th>
                <th
                  (click)="sortData('transactionType')"
                  style="cursor: pointer"
                  class="sortable-header"
                >
                  TransactionType
                </th>
                <th
                  (click)="sortData('acquirerCountry')"
                  style="cursor: pointer"
                  class="sortable-header"
                >
                  AcquirerCountry
                </th>
                <th
                  (click)="sortData('approvedTransactions')"
                  style="cursor: pointer"
                  class="sortable-header"
                >
                  ApprovedTransactions
                </th>
                <th
                  (click)="sortData('declinedTransactions')"
                  style="cursor: pointer"
                  class="sortable-header"
                >
                  DeclinedTransactions
                </th>
                <th
                  (click)="sortData('refundedTransactions')"
                  style="cursor: pointer"
                  class="sortable-header"
                >
                  RefundedTransactions
                </th>
                <th
                  (click)="sortData('unclearTransactions')"
                  style="cursor: pointer"
                  class="sortable-header"
                >
                  UnclearTransactions
                </th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td>{{ x.transactionDate | date : "dd-MMM-yy - HH:mm:ss" }}</td>
                <td>{{ x.totalTransactions }}</td>
                <!-- <td>{{ formatTransactionAmount(x.transactionAmount) }}</td> -->
                <td>{{ x.transactionType }}</td>
                <!-- <td>{{ x.tranFeeType }}</td> -->
                <td>{{ x.acquirerCountry }}</td>
                <td>{{ x.approvedTransactions }}</td>
                <td>{{ x.declinedTransactions }}</td>
                <td>{{ x.refundedTransactions }}</td>
                <td>{{ x.unclearTransactions }}</td>
              </tr>
              <tr style="background-color: #09df66be">
                <td>Total:</td>
                <td>{{ sumOftotalTransactions }}</td>
                <td></td>

                <td></td>
                <td>
                  {{ sumOfapprovedTransactions | number : "1.2-2" }}
                </td>
                <td>
                  {{ sumdeclinedTransactions | number : "1.2-2" }}
                </td>
                <td>
                  {{ sumrefundedTransactions | number : "1.2-2" }}
                </td>
                <td>
                  {{ sumofunclearTransactions | number : "1.2-2" }}
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
