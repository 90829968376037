import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '../service/auth-guard/auth-guard.service';

import { ChangeUserPasswordComponent } from '../components/contents/accounts/change-user-password/change-user-password.component';
import { CreateDistibutorUserComponent } from '../components/contents/distributorsUsers/createdist.component';
import { EventLogsComponent } from '../components/contents/event-log/event-logs/event-logs.component';
import { EventLogInfoComponent } from '../components/contents/event-log/event-logs/view/event-log-info/event-log-info.component';
import { HomeComponent } from '../components/contents/home/home.component';
import { BillingMgComponent } from '../components/contents/merchant-management/billing-mg/billing-mg.component';
import { CreateBillersComponent } from '../components/contents/merchant-management/billing-mg/create-billers/create-billers.component';
import { ViewBillingInfoComponent } from '../components/contents/merchant-management/billing-mg/view-billing-info/view-billing-info.component';
import { CashierActivationComponent } from '../components/contents/merchant-management/cashier-activation/cashier-activation.component';
import { CashierActiveTableComponent } from '../components/contents/merchant-management/cashier-activation/view/cashier-active-table/cashier-active-table.component';
import { AccountsTableComponent } from '../components/contents/merchant-management/merchant-accounts/accounts-table/accounts-table.component';
import { CreateMerchantAccountsComponent } from '../components/contents/merchant-management/merchant-accounts/create-merchant-accounts/create-merchant-accounts.component';
import { MerchantAccountsComponent } from '../components/contents/merchant-management/merchant-accounts/merchant-accounts.component';
import { MerchantAccountInfoComponent } from '../components/contents/merchant-management/merchant-accounts/view/merchant-account-info/merchant-account-info.component';
import { SettlementAccountInfoComponent } from '../components/contents/merchant-management/merchant-accounts/view/settlement-account-info/settlement-account-info.component';
import { MerchantActivationComponent } from '../components/contents/merchant-management/merchant-activation/merchant-activation.component';
import { MerchantActiveInfoComponent } from '../components/contents/merchant-management/merchant-activation/view/merchant-active-info/merchant-active-info.component';
import { ListMerchantApprovalsComponent } from '../components/contents/merchant-management/merchant-approvals/list-merchant-approvals/list-merchant-approvals.component';
import { TableMerchantApprovalsComponent } from '../components/contents/merchant-management/merchant-approvals/list-merchant-approvals/table-merchant-approvals/table-merchant-approvals.component';
import { CreateCashierMerchantApprovalComponent } from '../components/contents/merchant-management/merchant-approvals/list-merchant-approvals/table-merchant-approvals/view/create-cashier-merchant-approval/create-cashier-merchant-approval.component';
import { MerchantBillerTableComponent } from '../components/contents/merchant-management/merchant-billers/merchant-biller-table/merchant-biller-table.component';
import { MerchantBillersComponent } from '../components/contents/merchant-management/merchant-billers/merchant-billers.component';
import { MerchantPaymentProviderTableComponent } from '../components/contents/merchant-management/merchant-payment-provider/merchant-payment-provider-table/merchant-payment-provider-table.component';
import { AddPaymentMethodComponent } from '../components/contents/merchant-management/merchant-payment-provider/merchant-payment-provider-table/view/add-payment-method/add-payment-method.component';
import { MerchantPaymentProviderComponent } from '../components/contents/merchant-management/merchant-payment-provider/merchant-payment-provider.component';
import { ResetMerchantPasswordComponent } from '../components/contents/merchant-management/reset-merchant-password/reset-merchant-password.component';
import { ResetPasswordInfoComponent } from '../components/contents/merchant-management/reset-merchant-password/view/reset-password-info/reset-password-info.component';
import { NotfoundComponent } from '../components/contents/notfound/notfound.component';
import { CreateInvoiceComponent } from '../components/contents/payment-provider/create-invoice/create-invoice.component';
import { GetCreateInvoiceComponent } from '../components/contents/payment-provider/create-invoice/get-create-invoice/get-create-invoice.component';
import { GetInvoiceListComponent } from '../components/contents/payment-provider/get-invoice-list/get-invoice-list.component';
import { GetInvoiceInfoComponent } from '../components/contents/payment-provider/get-invoice-list/view/get-invoice-info/get-invoice-info.component';
import { ProcessInvoiceInfoComponent } from '../components/contents/payment-provider/get-invoice-list/view/process-invoice-info/process-invoice-info.component';
import { ProcessInvoiceComponent } from '../components/contents/payment-provider/process-invoice/process-invoice.component';
import { ProductListComponent } from '../components/contents/product-managment/product-list/product-list.component';
import { CreateProductComponent } from '../components/contents/product-managment/product-list/view/create-product/create-product.component';
import { GetInfoProductComponent } from '../components/contents/product-managment/product-list/view/get-info-product/get-info-product.component';
import { ServiceProductComponent } from '../components/contents/product-managment/product-list/view/service-product/service-product.component';
import { CreateRateComponent } from '../components/contents/product-managment/product-list/view/service-product/service-rate/create-rate/create-rate.component';
import { GetRateComponent } from '../components/contents/product-managment/product-list/view/service-product/service-rate/get-rate/get-rate.component';
import { ServiceRateComponent } from '../components/contents/product-managment/product-list/view/service-product/service-rate/service-rate.component';
import { CreatesServiceComponent } from '../components/contents/product-managment/product-list/view/service-product/view/creates-service/creates-service.component';
import { ViewServiceComponent } from '../components/contents/product-managment/product-list/view/service-product/view/view-service/view-service.component';
import { ChargeBackReportsComponent } from '../components/contents/reports/charge-back-reports/charge-back-reports.component';
import { ChargeBackTableComponent } from '../components/contents/reports/charge-back-reports/charge-back-table/charge-back-table.component';
import { ChargeBackSummaryReportsComponent } from '../components/contents/reports/charge-back-summary-reports/charge-back-summary-reports.component';
import { ChargeBackSummaryTableComponent } from '../components/contents/reports/charge-back-summary-reports/charge-back-summary-table/charge-back-summary-table.component';
import { DistCommissionByMonthReportComponent } from '../components/contents/reports/dist-commission-by-month-report/dist-commission-by-month-report.component';
import { DistCommissionByMonthTableReportComponent } from '../components/contents/reports/dist-commission-by-month-report/view/dist-commission-by-month-table-report/dist-commission-by-month-table-report.component';
import { DistCommissionByYearReportComponent } from '../components/contents/reports/dist-commission-by-year-report/dist-commission-by-year-report.component';
import { DistCommissionByYearTableReportComponent } from '../components/contents/reports/dist-commission-by-year-report/view/dist-commission-by-year-table-report/dist-commission-by-year-table-report.component';
import { DistributorCommissionReportsComponent } from '../components/contents/reports/distributor-commission-reports/distributor-commission-reports.component';
import { DistributorCommissionTableComponent } from '../components/contents/reports/distributor-commission-reports/distributor-commission-table/distributor-commission-table.component';
import { GlAccountActivityReportComponent } from '../components/contents/reports/gl-account-activity-report/gl-account-activity-report.component';
import { GlAccountActivityTableReportComponent } from '../components/contents/reports/gl-account-activity-report/view/gl-account-activity-table-report/gl-account-activity-table-report.component';
import { GlAccountBalanceReportsComponent } from '../components/contents/reports/gl-account-balance-reports/gl-account-balance-reports.component';
import { GlAccountSummaryReportsComponent } from '../components/contents/reports/gl-account-summary-reports/gl-account-summary-reports.component';
import { GlAccountSummaryTableComponent } from '../components/contents/reports/gl-account-summary-reports/view/gl-account-summary-table/gl-account-summary-table.component';
import { GlAccountsSummaryByDayComponent } from '../components/contents/reports/gl-accounts-summary-by-day/gl-accounts-summary-by-day.component';
import { GlAccountsSummaryByDayTableComponent } from '../components/contents/reports/gl-accounts-summary-by-day/view/gl-accounts-summary-by-day-table/gl-accounts-summary-by-day-table.component';
import { MerchantActivitySummaryByDayComponent } from '../components/contents/reports/merchant-activity-summary-by-day/merchant-activity-summary-by-day.component';
import { MerchantActivitySummaryByDayTableComponent } from '../components/contents/reports/merchant-activity-summary-by-day/view/merchant-activity-summary-by-day-table/merchant-activity-summary-by-day-table.component';
import { MerchantActivitySummaryByMonthComponent } from '../components/contents/reports/merchant-activity-summary-by-month/merchant-activity-summary-by-month.component';
import { MerchantActivitySummaryByMonthTableComponent } from '../components/contents/reports/merchant-activity-summary-by-month/view/merchant-activity-summary-by-month-table/merchant-activity-summary-by-month-table.component';
import { MerchantActivityTableComponent } from '../components/contents/reports/merchant-activity/merchant-activity-table/merchant-activity-table.component';
import { MerchantActivityComponent } from '../components/contents/reports/merchant-activity/merchant-activity.component';
import { MerchantDailySummaryByDistComponent } from '../components/contents/reports/merchant-daily-summary-by-dist/merchant-daily-summary-by-dist.component';
import { MerchantDailySummaryByDistTableComponent } from '../components/contents/reports/merchant-daily-summary-by-dist/view/merchant-daily-summary-by-dist-table/merchant-daily-summary-by-dist-table.component';
import { MerchantMonthlySummaryByDistComponent } from '../components/contents/reports/merchant-monthly-summary-by-dist/merchant-monthly-summary-by-dist.component';
import { MerchantMonthlySummaryByDistTableComponent } from '../components/contents/reports/merchant-monthly-summary-by-dist/view/merchant-monthly-summary-by-dist-table/merchant-monthly-summary-by-dist-table.component';
import { MerchantSubscriptionComponent } from '../components/contents/reports/merchant-subscription/merchant-subscription.component';
import { GetSubscriptionReportComponent } from '../components/contents/reports/merchant-subscription/view/get-subscription-report/get-subscription-report.component';
import { MerchantUsageSummaryReportComponent } from '../components/contents/reports/merchant-usage-summary-report/merchant-usage-summary-report.component';
import { MerchantUsageSummaryTableReportComponent } from '../components/contents/reports/merchant-usage-summary-report/view/merchant-usage-summary-table-report/merchant-usage-summary-table-report.component';
import { PaymentTranSummaryReportsComponent } from '../components/contents/reports/payment-tran-summary-reports/payment-tran-summary-reports.component';
import { PaymentTranSummaryTableComponent } from '../components/contents/reports/payment-tran-summary-reports/payment-tran-summary-table/payment-tran-summary-table.component';
import { PosMerchantActivityReportComponent } from '../components/contents/reports/pos-merchant-activity-report/pos-merchant-activity-report.component';
import { PosMerchantActivityTableReportComponent } from '../components/contents/reports/pos-merchant-activity-report/view/pos-merchant-activity-table-report/pos-merchant-activity-table-report.component';
import { PosMerchantUsageSummaryReportComponent } from '../components/contents/reports/pos-merchant-usage-summary-report/pos-merchant-usage-summary-report.component';
import { PosMerchantUsageSummaryTableReportComponent } from '../components/contents/reports/pos-merchant-usage-summary-report/view/pos-merchant-usage-summary-table-report/pos-merchant-usage-summary-table-report.component';
import { PrintPageComponent } from '../components/contents/reports/print-page/print-page.component';
import { AssignwebpermissionComponent } from '../components/contents/settings/assignwebpermission/assignwebpermission.component';
import { MerchantconfigComponent } from '../components/contents/settings/merchantconfig/merchantconfig.component';
import { MerchantgroupsComponent } from '../components/contents/settings/merchantgroups/merchantgroups.component';
import { SettingconfigComponent } from '../components/contents/settings/merchantgroups/settingconfig/settingconfig.component';
import { RoutconfigComponent } from '../components/contents/settings/routconfig/routconfig.component';
import { CreateroutComponent } from '../components/contents/settings/routconfig/view/createrout/createrout.component';
import { UpdateroutComponent } from '../components/contents/settings/routconfig/view/updaterout/updaterout.component';
import { ServicesconfigComponent } from '../components/contents/settings/servicesconfig/servicesconfig.component';
import { CreateServiceComponent } from '../components/contents/settings/servicesconfig/view/create-service/create-service.component';
import { UpdateServiceComponent } from '../components/contents/settings/servicesconfig/view/update-service/update-service.component';
import { SysconfigComponent } from '../components/contents/settings/sysconfig/sysconfig.component';
import { CreateComponent } from '../components/contents/settings/sysconfig/view/create/create.component';
import { UpdateComponent } from '../components/contents/settings/sysconfig/view/update/update.component';
import { UserrolesComponent } from '../components/contents/settings/userroles/userroles.component';
import { WebpermissionComponent } from '../components/contents/settings/webpermission/webpermission.component';
import { GenerateSettlementComponent } from '../components/contents/settlement-management/settlement-direct/generate-settlement/generate-settlement.component';
import { SettlementDirectComponent } from '../components/contents/settlement-management/settlement-direct/settlement-direct.component';
import { SettlementCommissionTableComponent } from '../components/contents/settlement-management/settlement-distributor-commission/settlement-commission-table/settlement-commission-table.component';
import { ProcessCommissionComponent } from '../components/contents/settlement-management/settlement-distributor-commission/settlement-commission-table/view/process-commission/process-commission.component';
import { SettlementDistributorCommissionComponent } from '../components/contents/settlement-management/settlement-distributor-commission/settlement-distributor-commission.component';
import { SettlementHistoryComponent } from '../components/contents/settlement-management/settlement-history/settlement-history.component';
import { SettlementTableComponent } from '../components/contents/settlement-management/settlement-history/settlement-table/settlement-table.component';
import { GetSettlementInfoComponent } from '../components/contents/settlement-management/settlement-history/settlement-table/view/get-settlement-info/get-settlement-info.component';
import { SettlementPeriodicRequestComponent } from '../components/contents/settlement-management/settlement-periodic-request/settlement-periodic-request.component';
import { SettlementRequestListComponent } from '../components/contents/settlement-management/settlement-request-list/settlement-request-list.component';
import { SettlementRequestTableComponent } from '../components/contents/settlement-management/settlement-request-list/settlement-request-table/settlement-request-table.component';
import { GetSettlementRequestInfoComponent } from '../components/contents/settlement-management/settlement-request-list/settlement-request-table/view/get-settlement-request-info/get-settlement-request-info.component';
import { CreateSettlementRequestComponent } from '../components/contents/settlement-management/settlement-request/create-settlement-request/create-settlement-request.component';
import { SettlementRequestComponent } from '../components/contents/settlement-management/settlement-request/settlement-request.component';
import { DeviceTerminalsListComponent } from '../components/contents/terminal-management/device-terminals-list/device-terminals-list.component';
import { PosTransactionComponent } from '../components/contents/terminal-management/pos-transaction/pos-transaction.component';
import { PosTransactionTableInfoComponent } from '../components/contents/terminal-management/pos-transaction/view/pos-transaction-table-info/pos-transaction-table-info.component';
import { ChargebacksComponent } from '../components/contents/transactions/chargebacks/chargebacks.component';
import { GetChargebacksListComponent } from '../components/contents/transactions/chargebacks/view/get-chargebacks-list/get-chargebacks-list.component';
import { GetChargebacksComponent } from '../components/contents/transactions/chargebacks/view/get-chargebacks/get-chargebacks.component';
import { TransactionCancellationComponent } from '../components/contents/transactions/transaction-cancellation/transaction-cancellation.component';
import { TransactionHistoryComponent } from '../components/contents/transactions/transaction-history/transaction-history.component';
import { TransactionInfoComponent } from '../components/contents/transactions/transaction-info/transaction-info.component';
import { ViewTransactionInfoComponent } from '../components/contents/transactions/transaction-info/view-transaction-info/view-transaction-info.component';
import { TransactionTableComponent } from '../components/contents/transactions/transaction-table/transaction-table.component';
import { TransactionViewComponent } from '../components/contents/transactions/transaction-table/transaction-view/transaction-view.component';
import { UnAmbiguousTransactionsComponent } from '../components/contents/transactions/un-ambiguous-transactions/un-ambiguous-transactions.component';
import { AdminViewerComponent } from '../components/contents/users/admin-viewer/admin-viewer.component';
import { AdminComponent } from '../components/contents/users/admin/admin.component';
import { CreateusersComponent } from '../components/contents/users/admin/view/createusers/createusers.component';
import { ViewusersComponent } from '../components/contents/users/admin/view/viewusers/viewusers.component';
import { AssignUserRolesComponent } from '../components/contents/users/assign-user-roles/assign-user-roles.component';
import { CashierCreateInfoComponent } from '../components/contents/users/cashier/cashier-create-info/cashier-create-info.component';
import { CashierTableComponent } from '../components/contents/users/cashier/cashier-table/cashier-table.component';
import { CashierComponent } from '../components/contents/users/cashier/cashier.component';
import { ListCashierInfoComponent } from '../components/contents/users/cashier/list-cashier-info/list-cashier-info.component';
import { ResendCredentialsComponent } from '../components/contents/users/cashier/resend-credentials/resend-credentials.component';
import { DistributorComponent } from '../components/contents/users/distributor/distributor.component';
import { CreatedistComponent } from '../components/contents/users/distributor/view/createdist/createdist.component';
import { CreateDistributorAccountComponent } from '../components/contents/users/distributor/view/distributor-accounts/create-distributor-account/create-distributor-account.component';
import { DistributorAccountsComponent } from '../components/contents/users/distributor/view/distributor-accounts/distributor-accounts.component';
import { ViewdistComponent } from '../components/contents/users/distributor/view/viewdist/viewdist.component';
// import { MerchantTableComponent } from '../components/contents/users/merchant/merchant-table/merchant-table.component';
import { DistCommissionReportComponent } from '../components/contents/reports/distributor-commission-new-report/dist-commission-report.component';
import { DistCommissionTableReportNewComponent } from '../components/contents/reports/distributor-commission-new-report/dist-commission-table-report-new/dist-commission-table-report-new.component';
import { getWaafiQrPaymentsReportComponent } from '../components/contents/reports/get-waafi-qr-payments-report/get-waafi-qr-payments-report.component';
import { TopUsageMerchantSummaryDistComponent } from '../components/contents/reports/top-usage-merchant-summary/top-usage-merchant-summary-dist.component';
import { TopUsageMerchantSummaryTableComponent } from '../components/contents/reports/top-usage-merchant-summary/view/top-usage-merchant-summary-table.component';
import { MerchantDetailsComponent } from '../components/contents/terminal-management/merchant-details/merchant-details.component';
import { MerchantTableComponent } from '../components/contents/terminal-management/merchant-table/merchant-table.component';
import { Pos_Merchant } from '../components/contents/terminal-management/pos_merchant/pos_merchant.component';
import { ViewHtgTransectionInfo } from '../components/contents/transactions/view-htg-transaction-info/view-htg-transection-info.component';
import { MerchantComponent } from '../components/contents/users/merchant/merchant.component';
import { CreatemerchantComponent } from '../components/contents/users/merchant/view/createmerchant/createmerchant.component';
import { ViewmerchantComponent } from '../components/contents/users/merchant/view/viewmerchant/viewmerchant.component';
import { PartnerComponent } from '../components/contents/users/partner/partner.component';
import { ThirdPartyMerchantComponent } from '../components/contents/users/third-party-merchant/third-party-merchant.component';
import { BanksComponent } from '../components/contents/vendormanagement/banks/banks.component';
import { CreateBankComponent } from '../components/contents/vendormanagement/banks/create-bank/create-bank.component';
import { ViewBankComponent } from '../components/contents/vendormanagement/banks/view-bank/view-bank.component';
import { ViewProcessComponent } from '../components/contents/vendormanagement/banks/view-process/view-process.component';
import { CreatePaymentProcessorSystemComponent } from '../components/contents/vendormanagement/payment-processor-system/create-payment-processor-system/create-payment-processor-system.component';
import { PaymentProcessorSystemComponent } from '../components/contents/vendormanagement/payment-processor-system/payment-processor-system.component';
import { ViewPaymentProcessorSystemComponent } from '../components/contents/vendormanagement/payment-processor-system/view-payment-processor-system/view-payment-processor-system.component';
import { CreateInfoComponent } from '../components/contents/vendormanagement/paymentprocessor/create-info/create-info.component';
import { PaymentprocessorComponent } from '../components/contents/vendormanagement/paymentprocessor/paymentprocessor.component';
import { ViewInfoComponent } from '../components/contents/vendormanagement/paymentprocessor/view-info/view-info.component';
import { BlockedCardsReportDistr } from '../components/contents/waafi-card/blocked-cards-report/blocked-cards-report-Distr.component';
import { BlockedCardsReportTable } from '../components/contents/waafi-card/blocked-cards-report/view/blocked-cards-report-table.component';
import { PhysicalCardReportDistr } from '../components/contents/waafi-card/physical-card-report/physical-card-report-Distr.component';
import { PhysicalCardReportTable } from '../components/contents/waafi-card/physical-card-report/view/physical-card-report-table.component';
import { DistributorListComponent } from '../components/contents/waafi-card/branches/distributorList/distributor-list.component';
import { BranchComponent } from '../components/contents/waafi-card/branches/distributorList/view/Branch.component';
import { CustomerActivityReportDistComponent } from '../components/contents/waafi-card/card-customer-activity-report/customer-activity-report-dist.component';
import { ViewCustomerActivityReportComponent } from '../components/contents/waafi-card/card-customer-activity-report/view/view-customer-activity-report.component';
import { CardDistributors } from '../components/contents/waafi-card/card-distributors/card-distributors-component';
import { CardMcHourlyDistrReport } from '../components/contents/waafi-card/Card-Mc-Hourly-Report/Card-Mc-Hourly-Report-Distr.component';
import { CardMcHourlyReportTable } from '../components/contents/waafi-card/Card-Mc-Hourly-Report/view/Card-Mc-Hourly-Report-Table.component';
import { CardSummaryByMonthReportDist } from '../components/contents/waafi-card/card-summary-by-month-report/card-summary-by-month-report-Distr.component';
import { CardSummaryByMonthReportTable } from '../components/contents/waafi-card/card-summary-by-month-report/view/card-summary-by-month-report.component';
import { DistributorListCardCustomersComponent } from '../components/contents/waafi-card/cardCustomers/distributor-list-card-customers-component';
import { CustomersTableComponent } from '../components/contents/waafi-card/cardCustomers/viewCustomers/waafi-customers-table.component';
import { DistributorListDeliveredCardComponent } from '../components/contents/waafi-card/cardDelivered/distributor-list-delivered-cards.component';
import { DeliveredCardsComponent } from '../components/contents/waafi-card/cardDelivered/view/Delivered-Cards.component';
import { CardPendingRequests } from '../components/contents/waafi-card/CardPendingRequests/Card-Pending-Requests.component';
import { DistributorListPrintedCardComponent } from '../components/contents/waafi-card/cardprintedList/distributor-list-printed-cards.component';
import { PrintedCardsComponent } from '../components/contents/waafi-card/cardprintedList/view/Printed-Cards.component';
import { CardTransDistributorListComponent } from '../components/contents/waafi-card/cardTransections/trans-distributor-list.component';
import { CardTransactionsComponent } from '../components/contents/waafi-card/cardTransections/view/card-transactions-table.component';
import { ConfigurationComponent } from '../components/contents/waafi-card/configuration/Configuration.component';
import { DetailedAuthorizationsReportComponent } from '../components/contents/waafi-card/get-detailed-authorizations-report/get-detailed-authorizations-report.component';
import { DetailedTopupWithdrawalsReportComponent } from '../components/contents/waafi-card/get-detailed-topup-withdrawals-report/get-detailed-topup-withdrawals-report.component';
import { GetSettlementDetailReportComponent } from '../components/contents/waafi-card/get-settlement-detail-Report/get-settlement-detail-report.component';
import { GetSettlementSummaryReportComponent } from '../components/contents/waafi-card/get-settlement-summary-report/get-settlement-summary-report.component';
import { TopUsageByCustomersDistComponent } from '../components/contents/waafi-card/get-Top-Usage-By-Customers-report/top-usage-by-customers-dist.component';
import { CustomerTransactionDetails } from '../components/contents/waafi-card/get-Top-Usage-By-Customers-report/viewCustomerDetail/view-customer-transaction-detail-component';
import { TopUsageByCustomersTableComponent } from '../components/contents/waafi-card/get-Top-Usage-By-Customers-report/viewTopUsage/Top-Usage-By-Customers.component';
import { TopUsageByMerchantReportDist } from '../components/contents/waafi-card/get-Top-Usage-By-Merchant-report/top-usage-by-merchant-report-distr.component';
import { TopUsgaeMerchantTransactionsDetailComponent } from '../components/contents/waafi-card/get-Top-Usage-By-Merchant-report/view/merchantTransactionsDetail/view-merchant-transactions-detail-component';
import { TopUsageByMerchantReportTableComponent } from '../components/contents/waafi-card/get-Top-Usage-By-Merchant-report/view/Top-Usage-By-Merchant-Report.component';
import { IssuingSummaryReportComponent } from '../components/contents/waafi-card/issuing-summary-report/issuing-summary-report.component';
import { TopupWithdrawalsSummaryReportComponent } from '../components/contents/waafi-card/topup-withdrawals-summary-report/topup-withdrawals-summary-report.component';
import { TopupWithdrawalSummaryTableReportComponent } from '../components/contents/waafi-card/topup-withdrawals-summary-report/view/topup-withdrawals-summary-table-report/topup-withdrawals-summary-table-report.component';
import { UsageSummaryByMonthReportComponent } from '../components/contents/waafi-card/usage-summary-by-month-report/usage-summary-by-month-report.component';
import { UsageSummaryReportComponent } from '../components/contents/waafi-card/usage-summary-report/usage-summary-report.component';
import { ViewCardDeatilsReportComponent } from '../components/contents/waafi-card/viewCards/view-card-report.component';
import { ViewCardDeatilComponent } from '../components/contents/waafi-card/viewCards/viewCardDetail/view-card-detail.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { ForbiddenComponent } from '../components/forbidden/forbidden.component';
import { LoginComponent } from '../components/login/login.component';
import { ResetAdminPasswordComponent } from '../components/reset-admin-password/reset-admin-password.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { TwoFactorAuthenticationComponent } from '../components/Two-Factor-Authentication/two-factor-authentication.component';
const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "forget", component: ResetAdminPasswordComponent },
  { path: "reset/verify", component: ResetPasswordComponent },
  { path: "forbidden", component: ForbiddenComponent },
  { path: "twoFactorAuthentication", component: TwoFactorAuthenticationComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: ['Admin_Role', 'Admin_View_Role'] },
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home", component: HomeComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "print", component: PrintPageComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //@Desc
      // Accounts

      {
        path: "change-password",
        component: ChangeUserPasswordComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] }
      },

      //@Desc
      // Settings

      {
        path: "listSystemConfig",
        component: SysconfigComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] }
      },
      {
        path: "view/createSystemInfo",
        component: CreateComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/updateSystemInfo",
        component: UpdateComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "listPermissionConfig",
        component: WebpermissionComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "getCardTransactions",
        component: CardTransDistributorListComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "printedCards",
        component: DistributorListPrintedCardComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "getDeliveredCards",
        component: DistributorListDeliveredCardComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "commissionsReport",
        component: DistCommissionReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/commissionsReport",
        component: DistCommissionTableReportNewComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/getDeliveredCardsList",
        component: DeliveredCardsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/printedCardsList",
        component: PrintedCardsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/CardTransactions",
        component: CardTransactionsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/merchantDeatils", component: MerchantDetailsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      // card reports
      {
        path: "CardDistributors",
        component: CardDistributors,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "getTopUsageByCustomers",
        component: TopUsageByCustomersDistComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/getTopUsageByCustomers",
        component: TopUsageByCustomersTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/customerTransactionDetails",
        component: CustomerTransactionDetails,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "getTopUsageByMerchant",
        component: TopUsageByMerchantReportDist,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/merchantTransactionsDetail",
        component: TopUsgaeMerchantTransactionsDetailComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/getTopUsageByMerchant",
        component: TopUsageByMerchantReportTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "IssuingSummary",
        component: IssuingSummaryReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "usageSummary",
        component: UsageSummaryReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "detailedAuthorizations",
        component: DetailedAuthorizationsReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "usageSummaryByMonth",
        component: UsageSummaryByMonthReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "getSettlementDetailReport",
        component: GetSettlementDetailReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "CardSummaryByMonthReport",
        component: CardSummaryByMonthReportDist,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "BlockedCardsReport",
        component: BlockedCardsReportDistr,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },

      {
        path: "view/BlockedCardsReport",
        component: BlockedCardsReportTable,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "physicalCardReport",
        component: PhysicalCardReportDistr,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/physicalCardReport",
        component: PhysicalCardReportTable,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/CardSummaryByMonthReport",
        component: CardSummaryByMonthReportTable,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "CardMcHourlyReport",
        component: CardMcHourlyDistrReport,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/CardMcHourlyReport",
        component: CardMcHourlyReportTable,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "customerActivityReport",
        component: CustomerActivityReportDistComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/customerActivityReport",
        component: ViewCustomerActivityReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "topUsageMerchantSummary",
        component: TopUsageMerchantSummaryDistComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/topUsageMerchantSummary",
        component: TopUsageMerchantSummaryTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "getSettlementSummaryReport",
        component: GetSettlementSummaryReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "topupWithdrawalsSummaryReport",
        component: TopupWithdrawalsSummaryReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/topupWithdrawalsSummaryReport",
        component: TopupWithdrawalSummaryTableReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "detailedTopupWithdrawals",
        component: DetailedTopupWithdrawalsReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "listServiceConfig",
        component: ServicesconfigComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/createServiceInfo",
        component: CreateServiceComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/updateServiceInfo",
        component: UpdateServiceComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      {
        path: "getWaafiQrPaymentsReport",
        component: getWaafiQrPaymentsReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },

      {
        path: "listRouteConfig",
        component: RoutconfigComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/createRouteConfig",
        component: CreateroutComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/updateRouteConfig",
        component: UpdateroutComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      {
        path: "listUserRole",
        component: UserrolesComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "listMerchantSettings",
        component: MerchantconfigComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "listMerchantGroups",
        component: MerchantgroupsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "assignRolePermissions",
        component: AssignwebpermissionComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      //@desc
      // User MGT

      {
        path: "listUserInfo",
        component: AdminComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/userInfo",
        component: ViewusersComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/createUserInfo",
        component: CreateusersComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      {
        path: "listAdminViewerUserInfo",
        component: AdminViewerComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      {
        path: "listDistributorUserInfo",
        component: DistributorComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/createDistributorInfo",
        component: CreatedistComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/updateDistributorInfo",
        component: ViewdistComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/getDistributorAccountList",
        component: DistributorAccountsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/createDistributorAccount",
        component: CreateDistributorAccountComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      //
      {
        path: "getWaafiCardReport",
        component: DistributorListCardCustomersComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/getWaafiCustomers",
        component: CustomersTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/cardDetails",
        component: ViewCardDeatilsReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "configuration",
        component: ConfigurationComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },

      {
        path: "listMerchantUserInfo",
        component: MerchantComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "thirdpartyMerchantList",
        component: ThirdPartyMerchantComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "posMerchants",
        component: Pos_Merchant,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/singleCardDetail",
        component: ViewCardDeatilComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "branches",
        component: DistributorListComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "cardPendingRequests",
        component: CardPendingRequests,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/listMerchantUserView",
        component: MerchantTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/createMerchantInfo",
        component: CreatemerchantComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] }
      },
      {
        path: "view/createBranch",
        component: BranchComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/updateMerchantInfo",
        component: ViewmerchantComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "listCashierInfo",
        component: CashierComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getCashierList",
        component: CashierTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getCashierInfo",
        component: ListCashierInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getReSendCredentials",
        component: ResendCredentialsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createCashierInfo",
        component: CashierCreateInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      {
        path: "assignUserRoles",
        component: AssignUserRolesComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      //@desc
      // Vonder MGT

      {
        path: "listPaymentProcessor",
        component: PaymentprocessorComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/listPaymentInfo",
        component: ViewInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createPaymentInfo",
        component: CreateInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "listPaymentProcessorSystem",
        component: PaymentProcessorSystemComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createPaymentProcessorSystem",
        component: CreatePaymentProcessorSystemComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getPaymentProcessorSystem",
        component: ViewPaymentProcessorSystemComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "assignGroupSettings",
        component: SettingconfigComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      //@Desc
      // Transaction

      {
        path: "listTransactionInfo",
        component: TransactionHistoryComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/transactionHistory",
        component: TransactionTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/transactionView",
        component: TransactionViewComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "viewTransactionInfo",
        component: TransactionInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "viewhtgTransactionInfo",
        component: ViewHtgTransectionInfo,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/transactionInfo",
        component: ViewTransactionInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "unambiguousTransactions",
        component: UnAmbiguousTransactionsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "listTransactionCancellation",
        component: TransactionCancellationComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "createDistributorUser",
        component: CreateDistibutorUserComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      //@Desc
      // Bank

      {
        path: "listBankInfo",
        component: BanksComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createBankInfo",
        component: CreateBankComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getBankInfo",
        component: ViewBankComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/processBankInfo",
        component: ViewProcessComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //@Desc
      // Merchant

      {
        path: "listMerchantAccount",
        component: MerchantAccountsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/merchantAccountList",
        component: AccountsTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createMerchantAccount",
        component: CreateMerchantAccountsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getMerchantSettlementAccount",
        component: SettlementAccountInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getMerchantAccountInfo",
        component: MerchantAccountInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //@Desc
      // Product

      {
        path: "listProductInfo",
        component: ProductListComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createProductInfo",
        component: CreateProductComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getProductInfo",
        component: GetInfoProductComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "view/listProductServices",
        component: ServiceProductComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createProductServices",
        component: CreatesServiceComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getProductServices",
        component: ViewServiceComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createServicesRate",
        component: CreateRateComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getServicesRate",
        component: GetRateComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "view/listProductServiceRate",
        component: ServiceRateComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //@Desc
      // Reports

      {
        path: "listSettlementInfo",
        component: SettlementHistoryComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/listSettlementList",
        component: SettlementTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getSettlementInfo",
        component: GetSettlementInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "settleMerchantAccount",
        component: SettlementDirectComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/directMerchantAccount",
        component: GenerateSettlementComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "createSettlementRequest",
        component: SettlementRequestComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createRequest",
        component: CreateSettlementRequestComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "viewSettlementRequests",
        component: SettlementRequestListComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getSettlementRequests",
        component: SettlementRequestTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getSettlementRequestsInfo",
        component: GetSettlementRequestInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "processingPeriodicSettlement",
        component: SettlementPeriodicRequestComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "viewDistributorCommission",
        component: SettlementDistributorCommissionComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getDistributorCommission",
        component: SettlementCommissionTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/processDistributorCommission",
        component: ProcessCommissionComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //@desc
      // Reports

      {
        path: "renewalMerchantSubscription",
        component: MerchantSubscriptionComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getMerchantSubscription",
        component: GetSubscriptionReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "merchantActivityReport",
        component: MerchantActivityComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getMerchantActivityReport",
        component: MerchantActivityTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },


      {
        path: "listChargebacksReport",
        component: ChargeBackReportsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getChargebacksReport",
        component: ChargeBackTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },


      {
        path: "listChargebackSummaryReport",
        component: ChargeBackSummaryReportsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getChargebackSummaryReport",
        component: ChargeBackSummaryTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },


      {
        path: "distributorCommissionActivity",
        component: DistributorCommissionReportsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getDistributorCommissionActivity",
        component: DistributorCommissionTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },


      {
        path: "gettransummary",
        component: PaymentTranSummaryReportsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/listTransummary",
        component: PaymentTranSummaryTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //@desc
      // Incoice

      {
        path: "invoicelist",
        component: GetInvoiceListComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getInvoicelist",
        component: GetInvoiceInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/processInvoicelist",
        component: ProcessInvoiceInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //

      {
        path: "processinvoice",
        component: ProcessInvoiceComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //

      {
        path: "createinvoice",
        component: CreateInvoiceComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getCreateInvoice",
        component: GetCreateInvoiceComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //@Desc
      // Tran Changeback

      {
        path: "listMerchantTranChargeBack",
        component: ChargebacksComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/chargebackList",
        component: GetChargebacksListComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/chargebacks",
        component: GetChargebacksComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //@Desc
      // Merchant MGT Billing

      {
        path: "billingList",
        component: BillingMgComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/createBillerInfo",
        component: CreateBillersComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getBillerInfo",
        component: ViewBillingInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //

      {
        path: "merchantBiller",
        component: MerchantBillersComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/merchantBillerList",
        component: MerchantBillerTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      ///

      {
        path: "resetMerchantPassword",
        component: ResetMerchantPasswordComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/resetPassword",
        component: ResetPasswordInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "merchantApprovals",
        component: ListMerchantApprovalsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/merchantApprovals",
        component: TableMerchantApprovalsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/approvalMerchantAndCashier",
        component: CreateCashierMerchantApprovalComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "merchantPaymentProvider",
        component: MerchantPaymentProviderComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/merchantPaymentProviderList",
        component: MerchantPaymentProviderTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/addPaymentMethod",
        component: AddPaymentMethodComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //

      {
        path: "listMerchantActivation",
        component: MerchantActivationComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getMerchantActivationInfo",
        component: MerchantActiveInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //

      {
        path: "listCashierActivation",
        component: CashierActivationComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getCashierActivationInfo",
        component: CashierActiveTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //@Desc
      // Report based

      {
        path: "viewGLAccountBalances",
        component: GlAccountBalanceReportsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "viewGLAccountSummary",
        component: GlAccountSummaryReportsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/getGLAccountSummary",
        component: GlAccountSummaryTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "viewGLAccountActivity",
        component: GlAccountActivityReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/getGLAccountActivity",
        component: GlAccountActivityTableReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "viewGLAccountSummaryByDay",
        component: GlAccountsSummaryByDayComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/getGLAccountSummaryByDay",
        component: GlAccountsSummaryByDayTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "merchantsUsageSummary",
        component: MerchantUsageSummaryReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getMerchantsUsageSummary",
        component: MerchantUsageSummaryTableReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "distCommisionSummaryByYear",
        component: DistCommissionByYearReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/distCommisionSummaryByYear",
        component: DistCommissionByYearTableReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "distCommisionSummaryByMonth",
        component: DistCommissionByMonthReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/distCommisionSummaryByMonth",
        component: DistCommissionByMonthTableReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //

      {
        path: "getPosMerchantReport",
        component: PosMerchantActivityReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getPosMerchantActivityReport",
        component: PosMerchantActivityTableReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "getPosMerchantsUsageSummary",
        component: PosMerchantUsageSummaryReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getPosMerchantsUsageSummaryReport",
        component: PosMerchantUsageSummaryTableReportComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "merchantSummaryByMonth",
        component: MerchantActivitySummaryByMonthComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "merchantSummaryByDay",
        component: MerchantActivitySummaryByDayComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/merchantActivitySummaryByDay",
        component: MerchantActivitySummaryByDayTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/merchantActivitySummaryByMonth",
        component: MerchantActivitySummaryByMonthTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      //

      {
        path: "distributorsMerchantSummarybyDay",
        component: MerchantDailySummaryByDistComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getDistributorsMerchantSummarybyDay",
        component: MerchantDailySummaryByDistTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },


      {
        path: "distributorsMerchantSummarybyMonth",
        component: MerchantMonthlySummaryByDistComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },
      {
        path: "view/getDistributorsMerchantSummarybyMonth",
        component: MerchantMonthlySummaryByDistTableComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role', 'Admin_View_Role'] },
      },

      {
        path: "listPartnersUserInfo",
        component: PartnerComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      // Terminal MGT
      {
        path: "getDeviceTerminals",
        component: DeviceTerminalsListComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "getPosTransactions",
        component: PosTransactionComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/getPosTransactionInfo",
        component: PosTransactionTableInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      // Event Logs
      {
        path: "listDailyEventLog",
        component: EventLogsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },
      {
        path: "view/eventLogInfo",
        component: EventLogInfoComponent,
        canActivate: [AuthGuard],
        data: { roles: ['Admin_Role'] },
      },

      { path: '**', component: NotfoundComponent }
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
  DashboardComponent, HomeComponent, LoginComponent
];
