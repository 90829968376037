<div @fade class="row">
    <div class="col grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h4 class="card-title">View Cashier Info</h4>
                    </div>
                    <div class="col-md-4">
                        <h4 class="card-title" style="float: right;">
                            <!-- <button (click)="onButtonClick()" class="btn btn-primary mr-2">Edit</button> -->
                            <button (click)="onButtonClick(event)" class="btn btn-primary mr-2">{{event}}</button>
                            <button type="submit" class="btn btn-primary" (click)="backToCashier()">Back To
                                Cashier</button>
                        </h4>
                    </div>
                </div>
                <form class="forms-sample"  [formGroup]="serviceForm" novalidate (ngSubmit)="OnCreateCashierUser()">
                    <!-- <fieldset [disabled]="isDisabled"> -->
                        <div class="card-subtitle">Merchant Info</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">User Id</label>
                                    <input type="text" class="form-control" formControlName="userId"
                                        placeholder="User Id" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userId.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.userId.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.userId.errors.required">User Id required
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">User Title</label>
                                    <input type="text" class="form-control" formControlName="userTitle"
                                        placeholder="User Title" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userTitle.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.userTitle.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.userTitle.errors.required">User Title is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">Profile Status Desc</label>
                                    <input type="text" class="form-control" formControlName="profileStatusDesc"
                                        placeholder="Profile Status Desc" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.profileStatusDesc.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.profileStatusDesc.errors"
                                        class="text-danger">
                                        <div *ngIf="serviceForm.controls.profileStatusDesc.errors.required">Profile
                                            Status Desc is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">Cashier Identifier</label>
                                    <input type="text" class="form-control" formControlName="cashierIdentifier"
                                        placeholder="Cashier Identifier" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.cashierIdentifier.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.cashierIdentifier.errors"
                                        class="text-danger">
                                        <div *ngIf="serviceForm.controls.cashierIdentifier.errors.required">Cashier
                                            Identifier is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">API-Key</label>
                                    <input type="text" class="form-control" formControlName="accessKey"
                                        placeholder="API-Key" readonly
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.accessKey.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.accessKey.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.accessKey.errors.required">API-Key is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="card-subtitle">Cashier Info</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">First Name</label>
                                    <input type="text" class="form-control" formControlName="firstName"
                                        placeholder="First Name"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.firstName.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.firstName.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.firstName.errors.required">First Name is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Middle Name</label>
                                    <input type="text" class="form-control" formControlName="middleName"
                                        placeholder="Middle Name"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.middleName.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.middleName.errors"
                                        class="text-danger">
                                        <div *ngIf="serviceForm.controls.middleName.errors.required">Middle Name is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Last Name</label>
                                    <input type="text" class="form-control" formControlName="lastName"
                                        placeholder="Last Name"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.lastName.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.lastName.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.lastName.errors.required">Last Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email</label>
                                    <input type="email" class="form-control" formControlName="email" placeholder="Email"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.email.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.email.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.email.errors.required">Email is required</div>
                                        <div *ngIf="serviceForm.controls.email.errors.email">It`s Not An Email Plz Check
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Contact Number</label>
                                    <input type="number" class="form-control" formControlName="telephone"
                                        placeholder="Contact Number"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.telephone.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.telephone.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.telephone.errors.required">Contact Number is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Website</label>
                                    <input type="text" class="form-control" formControlName="website"
                                        placeholder="Website"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.website.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.website.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.website.errors.required">Website is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Address Line One</label>
                                    <input type="text" class="form-control" formControlName="address"
                                        placeholder="Address One"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.address.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.address.errors.required">Address One is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Address Line Two</label>
                                    <input type="text" class="form-control" formControlName="address2"
                                        placeholder="Address Two"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address2.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.address2.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.address2.errors.required">Address Two is
                                            required
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">City</label>
                                    <input type="text" class="form-control" formControlName="city" placeholder="City"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.city.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.city.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.city.errors.required">City is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Country</label>
                                    <input type="text" class="form-control" formControlName="country"
                                        placeholder="Country"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.country.errors }">
                                    <div *ngIf="submitted && serviceForm.controls.country.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.country.errors.required">Country is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-center">
                                <button type="submit" class="btn btn-primary" style="width: 30%;">Update</button>
                                <!-- <button class="btn btn-default">Centered button</button> -->
                            </div>
                        </div>
                    <!-- </fieldset> -->
                </form>
            </div>
        </div>
    </div>
</div>