<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth px-0">
            <div class="row w-100 mx-0">
                <div class="col-lg-4 mx-auto">
                    <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                        <div class="brand-logo">
                            <img src="../../../assets/images/{{tab}}" style="height: 70px; width: 70px;" alt="logo">
                        </div>
                        <h4>Reset The Password</h4>
                        <h6 class="font-weight-light">Reset your password and make sure..</h6>
                        <!-- <form class="pt-3" [formGroup]="serviceForm" novalidate (ngSubmit)='resetMerchantPassword()'>
                            <div class="form-group">
                                <input type="password" class="form-control form-control-lg" placeholder="Password"
                                    formControlName="password"
                                    [ngClass]=" { 'is-invalid' : submitted && serviceForm.controls.password.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.password.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.password.errors.required">
                                                Password is required</div>
                                            <div *ngIf="serviceForm.controls.password.errors.minlength">
                                                Password should have minimum 6 characters, at least 1 uppercase letter
                                            </div>
                                        </div>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control form-control-lg" id="exampleInputPassword1"
                                    placeholder="Confirm" formControlName="confirm" minlength="6" maxlength="25"
                                    [ngClass]=" { 'is-invalid' : submitted && serviceForm.controls.confirm.errors }">
                                <div *ngIf="submitted && serviceForm.controls.confirm.errors"
                                    class="text-danger">
                                    <div *ngIf="serviceForm.controls.confirm.errors.required">
                                        Confirm Password is required</div>
                                    <div *ngIf="serviceForm.controls.confirm.errors">
                                        Passwords do not match
                                    </div>
                                </div>

                            </div>
                            <div class="mt-3">
                                <button type="submit"
                                    class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">RESET</button>
                            </div>
                        </form> -->

                        <!-- ########## -->

                        
                        <form [formGroup]="signupForm" (ngSubmit)="resetMerchantPassword()" novalidate autocomplete="off">
                            <!-- current password -->
                            <!-- <div class="col-md-4">
                                <div class="form-group">
                                    <label for="email" class="control-label font-weight-bold">Current Password</label>
                                    <input type="password" class="form-control" formControlName="currentPassword"
                                        placeholder="Your current password"
                                        [ngClass]="{ 'is-valid': (f.currentPassword.dirty || submitted) && !f.currentPassword.errors, 'is-invalid': (f.currentPassword.dirty || submitted) && f.currentPassword.errors }" />
                                    <div class="invalid-feedback">
                                        <span class="text-danger" *ngIf="f.currentPassword.errors?.required">Current password is required</span>
                                    </div>
                                </div>
                            </div> -->

                            <!-- password -->
                            <!-- <div class="col-md-4"> -->
                                <div class="form-group">
                                    <label for="password" class="control-label font-weight-bold">Password</label>
                                    <input type="password" formControlName="password" class="form-control"
                                        name="password" placeholder="Choose a password"
                                        [ngClass]="{ 'is-valid': (f.password.dirty || submitted) && passwordValid, 'is-invalid': (f.password.dirty || submitted) && !passwordValid }" />
                                    <div class="invalid-feedback">
                                        <!-- required -->
                                        <span class="text-danger"
                                            *ngIf="(f.password.dirty || submitted) && !requiredValid">Password is
                                            required</span>

                                        <!-- password validation rules -->
                                        <div id="validation-rules" class="mt-3" *ngIf="requiredValid">
                                            <!-- minlength -->
                                            <div
                                                [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }">
                                                <i
                                                    [attr.class]="minLengthValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must be at least 8 characters long
                                            </div>

                                            <!-- requires digit -->
                                            <div
                                                [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }">
                                                <i
                                                    [attr.class]="requiresDigitValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must contain at least 1 digit
                                            </div>

                                            <!-- requires uppercase -->
                                            <div
                                                [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }">
                                                <i
                                                    [attr.class]="requiresUppercaseValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must contain at least 1 uppercase character
                                            </div>

                                            <!-- requires lowercase -->
                                            <div
                                                [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }">
                                                <i
                                                    [attr.class]="requiresLowercaseValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must contain at least 1 lowercase character
                                            </div>

                                            <!-- requires special characters -->
                                            <div
                                                [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }">
                                                <i
                                                    [attr.class]="requiresSpecialCharsValid ? 'bi-check-square-fill' : 'bi-x-square'"></i>
                                                Must contain at least 1 special character
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- </div> -->

                            <!-- confirm password -->
                            <!-- <div class="col-md-4"> -->
                                <div class="form-group">
                                    <label for="confirmPassword" class="control-label font-weight-bold">Confirm
                                        password</label>
                                    <input type="password" formControlName="confirmPassword" class="form-control"
                                        name="confirmPassword" placeholder="Confirm your password"
                                        [ngClass]="{ 'is-valid': (submitted || f.confirmPassword.dirty) && !f.confirmPassword.errors, 'is-invalid': (submitted || f.confirmPassword.dirty) && f.confirmPassword.errors }">
                                    <div class="invalid-feedback"
                                        *ngIf="(submitted || f.confirmPassword.dirty) && f.confirmPassword.errors">
                                        <div *ngIf="f.confirmPassword.errors.required">Confirm your password</div>
                                        <div *ngIf="f.confirmPassword.errors.minLength">Password must be at least 8
                                            characters long</div>
                                        <div *ngIf="f.confirmPassword.errors.mismatch">Passwords do not match</div>
                                    </div>
                                </div>
                            <!-- </div> -->

                            <!-- submit button -->
                            <!-- <div class="col-md-4"> -->
                                <div class="form-group">
                                    <button type="submit" class="btn btn-block btn-primary" [disabled]="isWorking">
                                        <i [attr.class]="isWorking ? 'bi-hourglass-split' : 'bi-check2-square'"></i> {{
                                        isWorking ? 'Working on it...' : 'Change Password' }}
                                    </button>
                                </div>
                            <!-- </div> -->
                        </form>

                    </div>
                </div>
            </div>
        </div>
        <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
</div>
<!-- <app-footer style="margin: 0px !important;"> </app-footer> -->