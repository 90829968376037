import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-Printed-Cards.component',
  templateUrl: './Printed-Cards.component.html',
  styleUrls: ['./Printed-Cards.component.css'],
  animations: [
    fade
  ]
})
export class PrintedCardsComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  getForm: FormGroup;
  getPrintedFrom: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  Title;
  lisCustomers;
  ListOfMethods;
  currentSelectedData;
  currentRecordId;
  selected = pagination.selected;
  p: number = pagination.p;
  term;
  currentCardId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.distributorId = params['distributorId'];
        this.getPrintedPanFileRecords(this.distributorId, "", "");
        this.getFrom();
        this.getPrintedFroms();
        this.ListOfMethods = [{ name: "self" }, { name: "delivery" }]
      } else {
        _location.back();
      }
    });
  }
  getFrom() {
    this.getForm = this.fb.group({
      name: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      address: ['', Validators.required],
      collectionMethod: ['', Validators.required],
    });
  }
  getPrintedFroms() {
    this.getPrintedFrom = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  handleSubmit() {
    this.submitted = true
    if (this.getPrintedFrom.invalid) {
      return;
    }
    let startDate = this.getPrintedFrom.value.startDate;
    let endDate = this.getPrintedFrom.value.endDate
    this.getPrintedPanFileRecords(this.distributorId, startDate, endDate);
  }
  ngOnInit(): void {
    // console.log("PPPPP=>", this.selectData)
    this.Title = "Printed Cards"
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
    } else {
      this.isThirtParty = false;

    }
    // this.onChanges();
  }
  backToService() {
    this._location.back();
  }
  getPrintedPanFileRecords(distributorId, startDate, endDate) {
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      pState: "Printed",
      distributorId: distributorId,
      startDate: startDate,
      endDate: endDate,
    };

    this.isTrue = true;
    this.spinner.getSpinner();
    this.service.getPrintedPanFileRecords({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          records?.forEach((el: any) => {
            if (el.status == "PrintAccepted") {
              el.printName = "Validate"
            } else if (el.status == "PrintDeclined") {
              el.printName = "Retry Print"
            } else {
              el.printName = "Print"
            }

          })


          this.listData = records;

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  handleSubmitDelivery() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      panRecordId: this.currentRecordId,
      cardId: this.currentCardId,
      name: this.getForm.value.name,
      mobileNumber: this.getForm.value.mobileNumber,
      address: this.getForm.value.address,
      collectionMethod: this.getForm.value.collectionMethod,
      created_at: currentUser.userTitle,
    };

    this.spinner.getSpinner();
    this.service.panFileCreateCouriers({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        $('#updateSubscriptionModel').modal('hide');
        this.toastr.success(successResponse.records.message)
        this.getPrintedPanFileRecords(this.distributorId, "", "");
        this.spinner.hideSpinner();
      } else {

        this.spinner.hideSpinner();
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  SelectedRecodrd(data) {
    this.currentRecordId = data.recordId;
    this.currentCardId = data.cardId;
    this.currentSelectedData = data;
    this.getForm.reset();
  }
  onMethodChange(event: any) {
    const selectedMethod = event.target.value;
    if (selectedMethod == 'self') {
      this.initializeSelfDetails(selectedMethod);
    } else {
      this.clearDetails(selectedMethod);
    }

  }

  initializeSelfDetails(selectedMethod) {
    let { cardholderName, mobileNumber, printLocation } = this.currentSelectedData
    this.getForm = this.fb.group({
      name: [cardholderName, Validators.required],
      mobileNumber: [mobileNumber, Validators.required],
      address: [printLocation, Validators.required],
      collectionMethod: [selectedMethod, Validators.required],
    });
  }
  clearDetails(selectedMethod) {
    this.getForm = this.fb.group({
      name: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      address: ['', Validators.required],
      collectionMethod: [selectedMethod, Validators.required],
    });
  }

}
