import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ExcelService } from 'src/app/service/service/excel.service';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-view-merchant-transactions-detail-component',
  templateUrl: './view-merchant-transactions-detail-component.html',
  styleUrls: ['./view-merchant-transactions-detail-component.css'],
  animations: [
    fade
  ]
})
export class TopUsgaeMerchantTransactionsDetailComponent implements OnInit {
  listBody: boolean;
  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectAccData;
  distDataList;
  p: number = pagination.p;
  selectedData;
  selected = 100;
  TranType;
  listData
  selected1Data;
  institutionId;
  institutionName;
  monthsList;
  trackingNumber
  transactionDate
  merchantIdentity;
  term
  title;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  sumPreviousAmount = null;
  sumTranAmount = null;
  sumCredit = null;
  sumDebit = null;
  pdays;
  pMcc;
  narrative;
  pAcquirerCountry;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private excelService: ExcelService,
    private _location: Location,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.institutionId = params['institutionId'];
        // institutionId, pdays, pMcc, merchantIdentity, narrative, pAcquirerCountry
        this.pdays = params['pdays'];
        this.pMcc = params['pMcc'];
        this.merchantIdentity = params['merchantIdentity'];
        this.narrative = params['narrative'];
        this.pAcquirerCountry = params['pAcquirerCountry'];
        this.getFrom();
        this.getmerchantTransactionsDetail(this.institutionId, this.pdays, this.pMcc, this.merchantIdentity, this.narrative, this.pAcquirerCountry);
      } else {
        // _location.back();
      }
    });
  }

  getFrom() {
    this.getForm = this.fb.group({
      // selectDistributor: ['', Validators.required],
      // SelectedMonth: ['', Validators.required],
      transactionDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.title = "Top Usgae Merchant Transactions Detail"

  }
  getmerchantTransactionsDetail(institutionId, pdays, pMcc, merchantIdentity, narrative, pAcquirerCountry) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      institutionId: institutionId,
      pdays: pdays,
      pMcc: pMcc,
      merchantIdentity: merchantIdentity,
      narrative: narrative,
      pAcquirerCountry: pAcquirerCountry,
    };
    this.spinner.getSpinner();
    this.service.merchantTransactionsDetail({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        this.listBody = true;
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distDataList = records;
        this.listData = records;

        records.forEach((el, index, arry) => {
          let record = {};
          this.sumTranAmount += el.transactionAmount;
        });
        // console.log("kkkk", records)
      } else {
        this.listBody = false;
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.listBody = false;
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  handleSubmit() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.getForm.value.selectDistributor,
    };
    let pdays = this.getForm.value.selectedValue
    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        let institutionId = data.institutionId
        this.institutionId = data.institutionId
        this.institutionName = data.institutionName
        // this.PassViewReport(this.institutionId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  PassViewReport() {
    this.submitted = true;

    if (this.getForm.invalid) return;

    let transactionDate = this.getForm.value.transactionDate;

    this.router.navigate(["dashboard/view/CardMcHourlyReport"], { queryParams: { transactionDate } })
  }
  toggleAllowPhysicalRequest(data) {
    let payload: any = {
      institutionCode: data.institutionCode,
    };

    this.spinner.getSpinner();
    this.service.physicalCardToggle({ payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        // this.getInstitutionList()
      } else {
        this.toastr.error(successResponse.records.message)
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  backToService() {
    this._location.back();
  }
  formatTransactionAmount(amount) {
    if (amount === null || amount === undefined || amount === '') {
      return '0.00';
    }
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  print() {
    let printContents = document.getElementById('section-one').innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    setTimeout(() => {
      window.location.reload();
    }, 1000)
    // this._location.back();
  }
  fnPDF() {
    html2canvas(document.getElementById("section-one"), { allowTaint: true }).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) + 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height + i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(`waafipay_${moment().format('MMM Do YYYY')}_cutomer_transactions_by_detail.pdf`);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000)
      // this._location.back();
    });
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.listData, 'waafipay_cutomer_transactions_by_detail');
    // this._location.back();
  }
}
