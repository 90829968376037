import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';


@Component({
  selector: 'app-create-payment-processor-system',
  templateUrl: './create-payment-processor-system.component.html',
  styleUrls: ['./create-payment-processor-system.component.css'],
  animations: [
    fade
  ]
})
export class CreatePaymentProcessorSystemComponent implements OnInit {
  serviceForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  querypaymentProcessorId: number;
  paymentProcessorId: any;
  dataRecords: any;
  sub: Subscription;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      systemName: ['', Validators.required],
      description: ['', Validators.required],
      protocolId: ['', Validators.required],
      isActive: ['', Validators.required],
      systemUrl: ['', Validators.required],
      systemSecret: ['', Validators.required],
      systemUsername: ['', Validators.required],
      systemPassword: ['', Validators.required],
      myIp: ['', Validators.required],
      myPartnerId: ['', Validators.required],
    });
  }

  backToService() {
    this._location.back();
  }

  ngOnInit(): void {
  }
  OnCreatePaymentProcessorSystem() {
    this.submitted = true
    if (this.serviceForm.invalid) {
      return;
    }

    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      createdByUserId: currentUser.userId,
      systemName: this.serviceForm.value.systemName,
      description: this.serviceForm.value.description,
      protocolId: this.serviceForm.value.protocolId,
      isActive: this.serviceForm.value.isActive,
      systemUrl: this.serviceForm.value.systemUrl,
      systemSecret: this.serviceForm.value.systemSecret,
      systemUserName: this.serviceForm.value.systemUsername,
      systemPwd: this.serviceForm.value.systemPassword,
      myIp: this.serviceForm.value.myIp,
      myPartnerId: this.serviceForm.value.myPartnerId,
    };
    // console.log("CREATE ====", payload);
    this.service.createPaymentProcessorSystem(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if(successResponse){
        this.toastr.success(`You have created`, 'Seccessfully')
        this.router.navigate(["dashboard/listPaymentProcessorSystem"])
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
        console.log("onFailure:" + JSON.stringify(failureResponse));
    });

  }

}
