import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.css'],
  animations: [
    fade
  ]
})
export class BanksComponent implements OnInit {
  dataList;
  
  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getBankList();
  }

  ngOnInit(): void {
  }

  getBankList(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getBankList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passCreateBankResetFields(){
    var currentUser = this._sessionStorageService.getSessionData();
    let userId = currentUser.userId;
    this.router.navigate(["dashboard/view/createBankInfo"], { queryParams: { userId } })
  }

  passViewBankInfo(x){
    let bankId = x.bankId;
    this.router.navigate(["dashboard/view/getBankInfo"], { queryParams: { bankId } });
  }

  passViewBankProcessIdInfo(x){
    let paymentProcessorId = x.paymentProcessorId;
    this.router.navigate(['dashboard/view/processBankInfo'], { queryParams: { paymentProcessorId } });
  }

}
