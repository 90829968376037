import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SessionStorageService } from 'src/app/service/session-storage/session-storage.service';
import { ServiceService } from 'src/app/service/service/service.service';
import { ExcelService } from 'src/app/service/service/excel.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ToastrService } from "ngx-toastr";
import * as moment from 'moment';
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas';
import { Angular2Csv } from 'angular2-csv';


@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.css']
})
export class PrintPageComponent implements OnInit {
  formula:string = "Formula 1";
  dataList;
  partnerTitle;
  startDate;
  endDate;
  dateStartDate;
  dateEndtDate;
  balance = null;
  totalBalance = null;
  totalDebit = null;
  totalCredit = null;
  totalPrev = null;
  totalTran = null;
  dateNow = moment().format('MMMM Do YYYY');
  sumType;
  currencyId;
  no;
  mtoChannel;
  debitGLAccountId;
  mtoChannelIds;
  isNetSett: boolean = false;
  isAcivitySum: boolean = false;
  listData;
  listBody: boolean = false;
  message;
  type;
  glaccountid;
  date;

  accountTypeId;
  accountId;
  sumCredit;
  sumDebit;
  sumTotal;
  sumPrevious;

  constructor(
    private storage: SessionStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private service: ServiceService,
    private excelService: ExcelService,
    private spinner: SpinnerService,
    private toastr: ToastrService
  ) {
    let currentUser = this.storage.getSessionData();
    this.partnerTitle = currentUser.userTitle;
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        if (params['type'] == 1) {
          this.startDate = params['startDate'];
          this.endDate = params['endDate'];
          this.currencyId = params['currencyId'];
          this.no = params['no'];
          this.type = params['type'];
          this.fnGlAccountSummaryReports(this.currencyId, this.startDate, this.endDate);
          this.sumType = "GL Account Summary Report"
        } else if (params['type'] == 2) {
          this.startDate = params['startDate'];
          this.endDate = params['endDate'];
          this.currencyId = params['currencyId'];
          this.glaccountid = params['glaccountid'];
          this.no = params['no'];
          this.type = params['type'];
          this.fnGlAccountActivityReports(this.glaccountid, this.currencyId, this.startDate, this.endDate);
          this.sumType = "GL Account Activity Report"
        } else if (params['type'] == 3) {
          this.currencyId = params['currencyId'];
          this.date = params['date'];
          this.no = params['no'];
          this.type = params['type'];
          this.fnGlAccountSummaryByDayReports(this.currencyId, this.date);
          this.sumType = "Gl Account Summary By Day Reports"
        }
        this.dateStartDate = moment(this.startDate).format('MMM Do YYYY');
        this.dateEndtDate = moment(this.endDate).format('MMM Do YYYY');

      } else {
        _location.back();
      }
    });
  }

  ngOnInit(): void {

  }

  // backToService() {
  //   this._location.back();
  // }

  fnGlAccountSummaryReports(currencyId?, startDate?, endDate?) {
    let payload: any = {
      currencyId: currencyId,
      startDate: startDate,
      endDate: endDate
    };

    this.service.getAdminGlAccountsSummaryReport({ payload: payload }).subscribe((successResponse) => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      console.log("Reeq=>", JSON.parse(data.records))
      if (data.success) {
        if (data.data) {
          this.spinner.hideSpinner();
          this.listBody = true;
          let credit = null;
          let debit = null;
          let previous = null;
          let total = null;
          var list = [];
          JSON.parse(data.records).forEach(function (el) {
            console.log("For", el);
            let recordList = {
              accountId: el.accountId,
            }
            debit += el.debit;
            credit += el.credit;
            previous += el.previousBalance;
            total += el.balance;
          })
          this.sumCredit = credit;
          this.sumDebit = debit;
          this.sumPrevious = previous;
          this.sumTotal = total;
          let record = JSON.parse(data.records);
          this.listData = record;
          setTimeout(() => {
            if (this.no == 1) {
              this.print();
            } else if (this.no == 2) {
              this.fnPDF();
            } else if (this.no == 3) {
              this.exportAsXLSX(record);
            }
          })
        } else {
          this.spinner.hideSpinner();
          this.message = data.records.message;
          this.listBody = false;
        }
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.warning(data.records.message, "Warning")
      };
    }, ((error) => {
      console.log("ERROR: OUT OFF THE SERVER", error)
    }))
  }

  fnGlAccountActivityReports(glaccountid?, currencyId?, startDate?, endDate?) {
    let payload: any = {
      glaccountid: glaccountid,
      currencyId: currencyId,
      startDate: startDate,
      endDate: endDate
    };

    this.service.getAdminGlAccountsActivityReport({ payload: payload }).subscribe((successResponse) => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      console.log("Reeq=>", data)
      if (data.success) {
        if (!data.error) {
          this.spinner.hideSpinner();
          this.listBody = true;
          let credit = null;
          let debit = null;
          var list = [];
          JSON.parse(data.records).forEach(function (el) {
            console.log("For", el);
            let datalist = {
              recordId: el.recordId,
              createdDate: el.createdDate,
              accountTypeDesc: el.accountTypeDesc,
              debit: el.debit,
              credit: el.credit
            }
            list.push(datalist);
            debit += el.debit;
            credit += el.credit;
          })
          this.sumCredit = credit;
          this.sumDebit = debit;
          this.listData = JSON.parse(data.records);
          setTimeout(() => {
            if (this.no == 1) {
              this.print();
            } else if (this.no == 2) {
              this.fnPDF();
            } else if (this.no == 3) {
              this.exportAsXLSX(JSON.parse(data.records));
            } else if (this.no == 4) {
              this.downloadCSV(list);
            }
          })
        } else {
          this.spinner.hideSpinner();
          this.message = data.records.message;
          this.listBody = false;
        }
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.warning(data.records.message, "Warning")
      };
    }, ((error) => {
      console.log("ERROR: OUT OFF THE SERVER", error)
    }))
  }

  fnGlAccountSummaryByDayReports(currencyId?, date?) {
    this.listBody = false;
    let payload: any = {
      currencyId: currencyId,
      date: date,
    };
    this.spinner.getSpinner();
    this.service.getAdminGlAccountsSummaryByDay({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      console.log("Reeq=>", data)
      if (data.success) {
        if (!data.error) {
          this.spinner.hideSpinner();
          this.listBody = true;
          let credit = null;
          let debit = null;
          var list = [];
          JSON.parse(data.records).forEach(function (el) {
            console.log("For", el);
            let datalist = {
              dayDate: el.dayDate,
              accountNo: el.accountNo,
              accountDesc: el.accountDesc,
              journalNumber: el.journalNumber,
              debit: el.debit,
              credit: el.credit
            }
            list.push(datalist);
            debit += el.debit;
            credit += el.credit;
          })
          this.sumCredit = credit;
          this.sumDebit = debit;
          this.listData = JSON.parse(data.records);
          setTimeout(() => {
            if (this.no == 1) {
              this.print();
            } else if (this.no == 2) {
              this.fnPDF();
            } else if (this.no == 3) {
              this.exportAsXLSX(JSON.parse(data.records));
            } else if (this.no == 4) {
              this.downloadCSV(list);
            }
          })
        } else {
          this.spinner.hideSpinner();
          this.message = data.records.message;
          this.listBody = false;
        }
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.message = data.records.message;
        this.toastr.warning(data.records.message, "Warning")
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      // this.createFailureMessage = true;
      this.toastr.error("Server seems got bad request!");
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  // getNetSettlementsReports(startDate, endDate, debitGLAccountId, mtoChannelIds) {
  //   this.balance = null;
  //   this.totalBalance = null;
  //   this.totalDebit = null;
  //   this.totalCredit = null;
  //   this.totalPrev = null;
  //   this.totalTran = null;

  //   let payload: any = {
  //     startDate: startDate,
  //     endDate: endDate,
  //     glAccountId: debitGLAccountId,
  //     mtoChannelId: mtoChannelIds
  //   }

  //   this.service.getNetSettlementReport(payload).subscribe((response) => {
  //     // console.log("RESPONSE==> ", response);
  //     if (!response) {
  //       console.log("ERROR: Response")
  //     } else {
  //       this.isNetSett = true;
  //       this.dataList = response;
  //       response.forEach((el, index) => {
  //         this.balance += el.balance;
  //         this.totalDebit += el.debit;
  //         this.totalCredit += el.credit;
  //         this.totalTran += el.noOfTrans;
  //         this.mtoChannel = el.mtoChannel
  //         console.log("UUU=>", this.balance)
  //       });
  //       setTimeout(() => {
  //         if (this.no == 1) {
  //           this.print();
  //         } else if (this.no == 2) {
  //           this.fnPDF();
  //         } else if (this.no == 3) {
  //           this.exportAsXLSX(response);
  //         }
  //       })
  //     }
  //   }, ((error) => {
  //     console.log("ERROR: OUT OFF THE SERVER", error)
  //   }))

  // }


  print() {
    let printContents = document.getElementById('section-one').innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    setTimeout(() => {
      window.location.reload();
    }, 1000)
    this._location.back();
  }

  fnPDF() {
    html2canvas(document.getElementById("section-one"), { allowTaint: true }).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) + 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height + i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(`waafipay_${moment().format('MMM Do YYYY')}_summary_report.pdf`);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000)
      this._location.back();
    });
  }

  downloadCSV(data) {

    var options = {
      title: 'GL Account Activity Report',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: true,
      useBom: false,
      headers: ['Record Id', 'CreatedDate', 'Account Type Desc', 'Debit', 'Credit']
    };
    new Angular2Csv(data, `waafipay_${moment().format('MMM Do YYYY')}_summary_report`, options);
    this._location.back();
  }
  


  exportAsXLSX(data): void {
    this.excelService.exportAsExcelFile(data, 'waafipay_');
    this._location.back();;
  }


}
