export const pagination = {
    selected: 25,
    p: 1,
    adminPage: 1,
    distPage: 1,
    merchPage: 2,
    cashPage: 10,
    partePage: 12,
    p2: 12
    // p: 1
    // p: 1
    // p: 1

}