import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
declare var $: any;
import { ServiceService } from "src/app/service/service/service.service";
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-gl-accounts-summary-by-day-table',
  templateUrl: './gl-accounts-summary-by-day-table.component.html',
  styleUrls: ['./gl-accounts-summary-by-day-table.component.css'],
  animations: [
    fade
  ]
})
export class GlAccountsSummaryByDayTableComponent implements OnInit {
  getForm: FormGroup;
  getSubmitted = false;
  listBody = false;
  selectData;
  tranStatusId;
  message;
  listData;
  glaccountid;

  accountTypeId;
  accountId;
  date;
  endDate;
  currencyId;
  sumCredit;
  sumDebit;
  sumTotal;
  sumPrevious;

  selected = pagination.selected;
  p: number = pagination.p;
  term;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.route.queryParams.subscribe(params => {
      this.currencyId = params['currencyId'];
      this.date = params['date'];
      this.fnGlAccountSummaryByDayReports(this.currencyId, this.date);
    });
    // this.fnGlAccountSummaryByDayReports();
  }

  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }


  print() {
    // this.submitted = true;
    // if (this.getForm.invalid) return;
    let type = 3;
    let no = 1;
    this.router.navigate(["dashboard/print"], { queryParams: { date: this.date, currencyId: this.currencyId, no, type } })
  }
  fnPdf() {
    let type = 3;
    let no = 2;
    this.router.navigate(["dashboard/print"], { queryParams: { date: this.date, currencyId: this.currencyId, no, type } })
  }
  exportAsXLSX() {
    let type = 3;
    let no = 3;
    this.router.navigate(["dashboard/print"], { queryParams: { date: this.date, currencyId: this.currencyId, no, type } })
  }
  printCsv() {

    let type = 3;
    let no = 4;
    this.router.navigate(["dashboard/print"], { queryParams: { date: this.date, currencyId: this.currencyId, no, type } })
  }

  fnGlAccountSummaryByDayReports(currencyId?, date?) {
    this.listBody = false;
    let payload: any = {
      currencyId: currencyId,
      date: date,
    };
    this.spinner.getSpinner();
    this.service.getAdminGlAccountsSummaryByDay({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      console.log("Reeq=>", data)
      if (data.success) {
        if (!data.error) {
          this.spinner.hideSpinner();
          this.listBody = true;
          let credit = null;
          let debit = null;
          JSON.parse(data.records).forEach(function (el) {
            console.log("For", el);
            debit += el.debit;
            credit += el.credit;
          })
          this.sumCredit = credit;
          this.sumDebit = debit;
          this.listData = JSON.parse(data.records);
        } else {
          this.spinner.hideSpinner();
          this.message = data.records.message;
          this.listBody = false;
        }
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.message = data.records.message;
        this.toastr.warning(data.records.message, "Warning")
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      // this.createFailureMessage = true;
      this.toastr.error("Server seems got bad request!");
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


}
