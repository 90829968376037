import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import Swal from 'sweetalert2';
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
declare var $: any;


@Component({
  selector: 'app-view-htg-transection-info',
  templateUrl: './view-htg-transection-info.component.html',
  styleUrls: ['./view-htg-transection-info.component.css'],
  animations: [
    fade
  ]
})
export class ViewHtgTransectionInfo implements OnInit {

  getForm: FormGroup;
  getSubmitted = false;
  selectData;
  // tranStatusId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    // private swal: Swal,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    // this.fnGetStatusList();
  }

  getFrom() {
    this.getForm = this.fb.group({
      transectionId: ['', Validators.required]
      // selectedData: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  fnGetStatusList(sourceId?) {
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getTranStatusList({ payload: payload }).subscribe(successResponse => {
      let responseObj = JSON.stringify(successResponse.records);
      // let data = JSON.parse(responseObj);
      this.spinner.hideSpinner();
      var records = JSON.parse(JSON.parse(responseObj));
      // console.log("data :=> ", records);

      var newrecord: any = {};
      newrecord.tranStatusId = "0";
      newrecord.tranStatusDesc = "All";
      records.push(newrecord);
      this.selectData = records;


    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };

  handleSubmit() {
    this.getSubmitted = true;
    if (this.getForm.invalid) {
      return;
    }
    let transectionId = this.getForm.value.transectionId;

    let payload = { transectionId };
    this.spinner.getSpinner();
    this.service.getHtgTransectionInfo({ payload: payload }).subscribe(successResponse => {
      let responseObj: any = JSON.stringify(successResponse.message);
      this.spinner.hideSpinner();
      if (successResponse.success) {


        Swal.fire({
          title: successResponse.message,
          showCancelButton: true,
          confirmButtonText: "Resolve",
          showConfirmButton: true,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Swal.fire("Saved!", "", "success");
          }
        });
      } else {
        // this.toastr
        this.toastr.error(successResponse.message)
      }
      // var records = JSON.parse(JSON.parse(responseObj));
      // console.log("data :=> ", records);

      // var newrecord: any = {};
      // newrecord.tranStatusId = "0";
      // newrecord.tranStatusDesc = "All";
      // records.push(newrecord);
      // this.selectData = records;


    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        this.toastr.error("Something went wrong")
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

    // this.router.navigate(["dashboard/view/transactionHistory"], { queryParams: { transectionId } })
  }

}
