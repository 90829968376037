import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { pagination } from 'src/app/service/service/pagination';
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-webpermission',
  templateUrl: './webpermission.component.html',
  styleUrls: ['./webpermission.component.css'],
  animations: [
    fade
  ]
})
export class WebpermissionComponent implements OnInit {

  dataList;
  perForm: FormGroup;
  submitted = false;
  modal;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
  }

  createForm() {
    this.perForm = this.fb.group({
      permissionId: [''],
      permissionName: ['', Validators.required ],
      description: ['', Validators.required ],
    });
  }

  ngOnInit(): void {
    this.getPermissionSettings();
  }

  getPermissionSettings(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getPermissionSettings({payload: payload}).subscribe(successResponse => {

      // console.log("haaa", successResponse)
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  resetForm(){
    this.perForm.reset();
  }

  OnCreatePermissionConfig() {
    this.submitted = true;
 
    if (this.perForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      createdByUserId: currentUser.userId,
      permissionName: this.perForm.value.permissionName,
      description: this.perForm.value.description
    };
    // console.log("Payload", payload)
    this.spinner.getSpinner();
    this.service.createWebPermission(payload).subscribe(successResponse => {

      if(successResponse.id){
        $('#permissionModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success("Seccessfully: You have created Id",successResponse.id)
        this.getPermissionSettings()
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  viewPermissionConfig(x){
    console.log("VieW",x)
    this.perForm = this.fb.group({
      permissionId: [x.permissionId, Validators.required],
      permissionName: [x.permissionName, Validators.required],
      description: [x.description, Validators.required],
    });
  }

  updatePermissionConfig(){
    this.submitted = true;
 
    if (this.perForm.invalid) {
        return;
    }
    let payload: any = {
      permissionId: this.perForm.value.permissionId,
      permissionName: this.perForm.value.permissionName,
      description: this.perForm.value.description
    };
    // console.log("Payload", payload)
    this.spinner.getSpinner();
    this.service.updateWebPermissionInfo(payload).subscribe(successResponse => {

      if(successResponse.id){
        $('#viewModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success("Seccessfully: You have created Id",successResponse.id)
        this.getPermissionSettings()
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  

}
