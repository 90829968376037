import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-distributor-commission-reports',
  templateUrl: './distributor-commission-reports.component.html',
  styleUrls: ['./distributor-commission-reports.component.css'],
  animations: [
    fade
  ]
})
export class DistributorCommissionReportsComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedValue;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getUserLists();
  }

  getFrom(){
    this.getForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      selectData: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  getUserLists(){
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.service.getAdminDistributorListByGrouping({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passDistributorCommissionReports(){
    this.submitted= true;

    if(this.getForm.invalid) return;
    let distributorId = this.getForm.value.selectData;
    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate;
    this.router.navigate(["dashboard/view/getDistributorCommissionActivity"], { queryParams: { distributorId, startDate, endDate }});

  }

}
