import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { environment } from 'src/assets/config/environment';
import { ExpressRequestService } from '../../service/express-request/express-request.service';
import { ServiceService } from '../../service/service/service.service';
import { SessionStorageService } from '../../service/session-storage/session-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  serviceForm: FormGroup;
  // loginId: string = "";
  // password: string = "";
  adminUserTypeId = "10";
  resultMessage: string;
  IsSignIt: boolean;
  appName;
  spinnerSize = 24;
  submitted = false;
  tab = environment.tab;
  isLoading;
  constructor(
    private service: ServiceService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private sessionStorage: SessionStorageService,
    private expressRequestService: ExpressRequestService) {
    this.createForm();
    this.isLoading = false;
    let storage = this.sessionStorage.getIsSignInData()
    let isStore = this.expressRequestService.decryptData(storage);
    // console.log("IsSignIt", isStore)
    if (isStore != null) {
      if (storage.isSignIn != null) {
        this.serviceForm = this.fb.group({
          loginId: [storage.loginId, Validators.required],
          password: [storage.storage, [Validators.required, Validators.minLength(6)]],
          isSignIn: [storage.isSignIn, Validators.required],
        });
      }
    }
  }

  ngOnInit() {
  }

  createForm() {
    this.serviceForm = this.fb.group({
      loginId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      isSignIn: [''],
    });
  }

  forgetPassword() {
    this.router.navigate(['forget']).then(() => {
      window.location.reload();
    });
  }


  login() {
    this.resultMessage = "";
    if (this.serviceForm.value.isSignIn) {
      let isSignIn: any = {
        loginId: this.serviceForm.value.loginId,
        password: this.serviceForm.value.password,
        isSignIn: this.serviceForm.value.isSignIn
      }
      let isStore: any = this.expressRequestService.encryptData(isSignIn);
      this.sessionStorage.setIsSignInData(isStore)
    } else {
      let isSignIn: any = {
        loginId: this.serviceForm.value.loginId,
        password: this.serviceForm.value.password,
        isSignIn: this.serviceForm.value.isSignIn
      }
      let isStore: any = this.expressRequestService.encryptData(isSignIn);
      this.sessionStorage.setIsSignInData(isStore)
    }

    this.submitted = true;
    if (this.serviceForm.invalid) {
      return;
    }

    let payload: any = {
      loginId: this.serviceForm.value.loginId,
      password: this.serviceForm.value.password,
      istwoFactorNeeded: true,
      // userTypeId: this.adminUserTypeId
    };
    // console.log("Payload ", payload);
    this.isLoading = true;
    this.service.login(payload).subscribe(res => {
      let record = res.records;
      if (res.success) {
        if (record.userTypeId === 10) {
          this.sessionStorage.setSessionData(record);
          this.sessionStorage.setToken(res.token);

          this.router.navigate(['dashboard/home']).then(() => {
            window.location.reload();
          });
          this.sessionStorage.setPermissionData(JSON.stringify(this.expressRequestService.encryptData([record.roleName])))
          this.toastr.success(res.message, 'Seccessfully');
          this.isLoading = false;
        } else if (record.userTypeId == 60) {
          this.sessionStorage.setSessionData(record);
          this.sessionStorage.setToken(res.token);
          this.router.navigate(['dashboard/home']).then(() => {
            window.location.reload();
          });
          this.sessionStorage.setPermissionData(JSON.stringify(this.expressRequestService.encryptData([record.roleName])))
          this.toastr.success(res.message, 'Seccessfully');
          this.isLoading = false;

        } else {
          this.toastr.warning("This user is`t admin", 'Warning')
          this.isLoading = false;
        }
      } else {
        // emailIsSent
        if (res.success == false && res.isOtpEnabled) {
          let paramsData = {
            message: res.records.message,
            loginId: this.serviceForm.value.loginId,
            pass: this.serviceForm.value.password
          }
          this.toastr.success(res.records.message);
          let encParams: any = this.expressRequestService.encryptData(paramsData);
          sessionStorage.setItem("sessionData", encParams);
          this.router.navigate(['twoFactorAuthentication']).then(() => {
          });
          this.isLoading = false;
        } else {
          this.isLoading = false;
          // this.toastr.warning(res.message);
          this.toastr.warning("Login id or password are Incorrect!");
        }
      }
      // this.isLoading = false;
    })
  }

}
