<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-11">
                        <h4 class="card-title">All Roles Settings</h4>
                    </div>
                    <div class="col-md-1">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal" (click)="resetForm()" data-target="#createModel">ADD</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Role Id</th>
                                <th>Role Name</th>
                                <th>Created Date</th>
                                <th>Description</th>
                                <th>Is Active</th>
                                <th>Action</th>
                                <th>Permission</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td class="py-1">{{x.roleId}}</td>
                                <td>{{x.createdDate | date}}</td>
                                <td>{{x.roleName}}</td>
                                <td>{{x.description}}</td>
                                <td>{{x.isActive}}</td>
                                <td (click)="viewRoleConfig(x)" data-toggle="modal" data-target="#viewModel">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                                <td (click)="passPermissionConfig(x)" data-toggle="modal" data-target="#viewPermissionModel">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <!-- <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Create User Role</h4>
                            <form class="forms-sample" [formGroup]="serviceForm" novalidate  (ngSubmit)="OnCreateRolesConfig()">
                                <div class="card-subtitle">User Role Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                                <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputUsername1">Role Name</label>
                                                <input type="text" class="form-control" formControlName="roleName" placeholder="Role Name"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.roleName.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.roleName.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.roleName.errors.required">Role Name is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Description</label>
                                                <input type="text" class="form-control"  formControlName="description" placeholder="Description"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.description.errors }">
                                                <div *ngIf="submitted && serviceForm.controls.description.errors" class="text-danger">
                                                    <div *ngIf="serviceForm.controls.description.errors.required">Description is required</div>
                                                </div>
                                            </div>
                                        </div>                                       
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade bd-example-modal-md" id="viewModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div @fade class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">View User Role</h4>
                            <form class="forms-sample" [formGroup]="serviceForm" novalidate  (ngSubmit)="updateRolesConfig()">
                                <div class="card-subtitle">User Role Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Role Id</label>
                                            <input type="text" class="form-control" formControlName="roleId" placeholder="Role Id" readonly
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.roleId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.roleId.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.roleId.errors.required">Role Id is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Role Name</label>
                                            <input type="text" class="form-control" formControlName="roleName" placeholder="Role Name"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.roleName.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.roleName.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.roleName.errors.required">Role Name is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Description</label>
                                            <input type="text" class="form-control"  formControlName="description" placeholder="Description"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.description.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.description.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.description.errors.required">Description is required</div>
                                            </div>
                                        </div>
                                    </div>                                     
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>