import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-un-ambiguous-transactions',
  templateUrl: './un-ambiguous-transactions.component.html',
  styleUrls: ['./un-ambiguous-transactions.component.css']
})
export class UnAmbiguousTransactionsComponent implements OnInit {

  getForm: FormGroup;
  submitted = false;
  _POS_PURCHASE: any = 1;
  _EPAYMENT: any = 4;

  listData;
  listBody: boolean;
  searchButtonText;
  errorMessageList: boolean;

  statusData;
  selectData;
  selectedValue;

  merchantId: any;
  tranStatusId: any;
  transactionId: any;
  cashierId: any;
  amount: any;
  charges: any;
  currency: any;
  description: any;
  invoiceId: any;
  statusId: any;
  posDeviceId: any;
  paymentMethod: any;
  payerId: any;
  payerIdType: any;
  payerBankId: any;
  transactionTypeId: any;
  issuerTransactionId: any;
  createdDate: any;
  paymentChannel: any;
  accountId: any;
  divcancellationbutton: boolean;
  cancelSuccessMessage: boolean;
  cancelFailureMessage: boolean;
  sub: any;
  message;
  data;
  isTrue = false;
  queryMerchantId: any;
  queryTranStatusId: any;
  selectedMerchantValue: any;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private _location: Location,
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.fnListUnAmbiguousTransactions();
  }

  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }

  fnListUnAmbiguousTransactions() {
    let currentUser = this._sessionStorageService.getSessionData();
    this.listBody = false;
    let payload: any = {
      userId: currentUser.userId,
      recordCount: "1000"

    };

    this.spinner.getSpinner();
    this.service.getUnambiguousTransactions({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.data = data.message;
      // console.log("msg: ", data.message)
      if (!successResponse.records.message) {
        this.listBody = true;
      }
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var records = JSON.parse(data);
        // console.log("All ", records)
        this.listData = records;
        this.listBody = true;
        this.isTrue = false;
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.listData = null;
        this.isTrue = true;
        this.message = data.message;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };

  passTransactionInfo(data) {
    let tranId = data.tranId;
    this.router.navigate(["dashboard/view/transactionView"], { queryParams: { tranId } })
  }

}
