import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-settlement-direct',
  templateUrl: './settlement-direct.component.html',
  styleUrls: ['./settlement-direct.component.css'],
  animations: [
    fade
  ]
})
export class SettlementDirectComponent implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();

  }

  getFrom(){
    this.getForm = this.fb.group({
      merchantId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  passSettlementDirectView(){
    this.submitted = true;
    if(this.getForm.invalid){
      return;
    }
    let merchantId = this.getForm.value.merchantId;
    this.router.navigate(["dashboard/view/directMerchantAccount"], { queryParams: { merchantId }});
  }

}
