<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Create Merchant Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Service</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate
                            (ngSubmit)="OnCreateMerchantUser()">

                            <div class="card-subtitle">Merchant Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Distributor Id</label>
                                        <input type="text" class="form-control" formControlName="distributorId" readonly
                                            placeholder="Distributor Id"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.distributorId.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.distributorId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.distributorId.errors.required">
                                                Distributor Id is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Product</label>
                                        <select class="form-control" formControlName="productId"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.productId.errors }">
                                            <option *ngFor="let p of selectProductData" [ngValue]="p.productId">
                                                {{p.productName}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.productId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.productId.errors.required">Product is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Payment Zone</label>
                                        <select class="form-control" formControlName="userZone"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userZone.errors }">
                                            <option *ngFor="let p of selectZoneData" [ngValue]="p.zoneId">{{p.zoneDesc}}
                                            </option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.userZone.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.userZone.errors.required">Payment Zone is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-subtitle">Profile Info</div>
                            <div
                                style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="isActive">First Name</label>
                                        <input type="text" class="form-control" formControlName="firstName"
                                            placeholder="First Name"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.firstName.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.firstName.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.firstName.errors.required">First Name
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Middle Name</label>
                                        <input type="text" class="form-control" formControlName="middleName"
                                            placeholder="Middle Name"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.middleName.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.middleName.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.middleName.errors.required">Middle Name
                                                is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Last Name</label>
                                        <input type="text" class="form-control" formControlName="lastName"
                                            placeholder="Last Name"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.lastName.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.lastName.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.lastName.errors.required">Last Name is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Email</label>
                                        <input type="email" class="form-control" formControlName="email"
                                            placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.email.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.email.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.email.errors.required">Email is
                                                required</div>
                                            <div *ngIf="serviceForm.controls.email.errors.email">It`s Not An Email
                                                Plz Check</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Contact Number</label>
                                        <input type="number" class="form-control" formControlName="telephone"
                                            placeholder="Contact Number"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.telephone.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.telephone.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.telephone.errors.required">Contact
                                                Number is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Website</label>
                                        <input type="text" class="form-control" formControlName="website"
                                            placeholder="Website"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.website.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.website.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.website.errors.required">Website is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Address Line One</label>
                                        <input type="text" class="form-control" formControlName="address"
                                            placeholder="Address One"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.address.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.address.errors.required">Address One is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Address Line Two</label>
                                        <input type="text" class="form-control" formControlName="address2"
                                            placeholder="Address Two"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address2.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.address2.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.address2.errors.required">Address Two
                                                is required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">City</label>
                                        <select class="form-control" formControlName="city"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.city.errors }">
                                            <option *ngFor="let p of selectCitiesData" [ngValue]="p.cityName">
                                                {{p.cityName}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.city.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.city.errors.required">City is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Country</label>
                                        <select class="form-control" formControlName="country"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.country.errors }">
                                            <option *ngFor="let p of selectCountryData" [ngValue]="p.countryName">
                                                {{p.countryName}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.country.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.country.errors.required">Country is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Category Code</label>
                                        <select class="form-control" formControlName="merchantCategory"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantCategory.errors }">
                                            <option *ngFor="let p of selectCategoryData" [ngValue]="p.categoryCodeId">
                                                {{p.categoryCodeName}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.merchantCategory.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.merchantCategory.errors.required">Category
                                                Code is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="card-subtitle">Account Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Issuer Id</label>
                                        <select class="form-control" formControlName="selectedBankValue"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedBankValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectBankData" [ngValue]="p.bankId">
                                                {{p.bankName}}
                                            </option>
                                        </select>

                                        <div *ngIf="submitted && serviceForm.controls.selectedBankValue.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.selectedBankValue.errors.required">Issuer
                                                Id is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="bankCode">Settlement Account No</label>
                                        <input type="text" class="form-control" formControlName="settlementAccount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.settlementAccount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.settlementAccount.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.settlementAccount.errors.required">Account
                                                No is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="selectedSettlementModeValue">Settlment Acc Mode</label>
                                        <select class="form-control" formControlName="selectedSettlementModeValue"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedSettlementModeValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectSettlementModeData"
                                                [ngValue]="p.settlementModeId">
                                                {{p.settlementModeDesc}}
                                            </option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.selectedSettlementModeValue.errors"
                                            class="text-danger">
                                            <div
                                                *ngIf="serviceForm.controls.selectedSettlementModeValue.errors.required">
                                                Select Account Mode
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="selectedAccountTypeValue">Settlement Account Type</label>
                                        <select class="form-control" formControlName="selectedAccountTypeValue"
                                            [(ngModel)]="selectedAccountTypeValue"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedAccountTypeValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectAccountTypesData"
                                                [ngValue]="p.settlementAccountTypeId">
                                                {{p.settlementAccountTypeDesc}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.selectedAccountTypeValue.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.selectedAccountTypeValue.errors.required">
                                                Select Account Type is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Currency Code</label>
                                        <select class="form-control" formControlName="currencyId"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.currencyId.errors }">
                                            <option *ngFor="let p of selectCurrencyData" [ngValue]="p.currencyId">
                                                {{p.currencyCode}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.currencyId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.currencyId.errors.required">Currency Code
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="settlementCycleId">Settlement Cycle</label>
                                        <select class="form-control" formControlName="settlementCycleId"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.settlementCycleId.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option value="0">NoN Regular</option>
                                            <option value="1">Regular</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.settlementCycleId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.settlementCycleId.errors.required">Select
                                                Cycle Id Type is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Category Code</label>
                                        <select class="form-control" formControlName="merchantCategory"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantCategory.errors }">
                                            <option *ngFor="let p of selectCategoryData" [ngValue]="p.categoryCodeId">
                                                {{p.categoryCodeName}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.merchantCategory.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.merchantCategory.errors.required">Category
                                                Code is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                            </div> -->
                            <div class="card-subtitle">Access Profile Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Merchant Name</label>
                                        <input type="text" class="form-control" formControlName="userTitle"
                                            placeholder="User Title"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userTitle.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.userTitle.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.userTitle.errors.required">User Title is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Login Id</label>
                                        <input type="text" class="form-control" formControlName="loginId"
                                            placeholder="Login ID"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.loginId.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.loginId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.loginId.errors.required">Login Id is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Create</button>
                                  
                                </div>
                            </div>
                           
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>