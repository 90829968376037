import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';


@Component({
  selector: 'app-payment-processor-system',
  templateUrl: './payment-processor-system.component.html',
  styleUrls: ['./payment-processor-system.component.css'],
  animations: [
    fade
  ]
})
export class PaymentProcessorSystemComponent implements OnInit {
  dataList;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  modal;
  selectServiceChargeModeData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getAllPaymentProcessorsSystem();
  }
  ngOnInit(): void {
  }

  getAllPaymentProcessorsSystem(){
    let payload: any = {};
    this.service.getPaymentProcessorSystemList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passPaymentProccessSysCreate(){
    this.router.navigate(["dashboard/view/createPaymentProcessorSystem"]);
  }

  passPaymentProccessSys(x){
    let systemId = x.systemId
    this.router.navigate(["dashboard/view/getPaymentProcessorSystem"], { queryParams: { systemId } });

  }

}
