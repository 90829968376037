import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'pos-merchant',
  templateUrl: './pos_merchant.component.html',
  styleUrls: ['./pos_merchant.component.css'],
  animations: [
    fade
  ]
})
export class Pos_Merchant implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  getDistrForm: FormGroup;
  sub: any;
  selectCycle;
  merchantTitle;
  showDistributor = false;
  selected = pagination.selected;
  p: number = pagination.p;
  term;
  distributorList;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    // this.getFrom();
    this.updatedAccessForm();
    this.updatedCycleForm();
    this.subscripsForm();
    this.createForm();
    this.updateForm();
    this.getDistrFrom();
    // this.getProductLists();
    // this.getMerchantList();
    this.getDistributor();

    this.showDistributor = false;
    // this.sub = this.route.queryParams.subscribe(params => {

    //   this.queryuserTypeId = +params['userTypeId'];
    //   this.queryMerchantTypeId = +params['merchantTypeId'];
    //   if (Number.isNaN(this.queryuserTypeId)) {
    //     this.userTypeId = "";
    //   } else {
    //     this.userTypeId = this.queryuserTypeId;
    //     console.log("HHHH => ", this.userTypeId)
    //     // this.getMerchantUserList(this.queryuserTypeId, this.queryMerchantTypeId)
    //   }
    // });
  }
  backToService() {
    this.showDistributor = false;
    this.dataList = []
  }

  createForm() {
    this.serviceForm = this.fb.group({
      distributorId: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      productId: ['', Validators.required],
      userTitle: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  updateForm() {
    this.updatesForm = this.fb.group({
      distributorId: ['', Validators.required],
      merchantUId: ['', Validators.required],
      userId: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      productId: ['', Validators.required],
      userTitle: ['', Validators.required],
    });
  }
  updatedAccessForm() {
    this.updateAccessForm = this.fb.group({
      selectedAccess: ['', Validators.required],
    });
  }
  updatedCycleForm() {
    this.updateCycleForm = this.fb.group({
      selectedCycle: ['', Validators.required],
    });
  }

  subscripsForm() {
    this.subscripForm = this.fb.group({
      subscriptionAutoRenewal: ['', Validators.required],
      selectedSubscription: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // console.log("PPPPP=>", this.selectData)
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
      this.merchantTitle = "Merchants"
    } else {
      this.isThirtParty = false;
      this.merchantTitle = "Merchants"
    }
  }

  getDistributorId() {
    if (!this.userTypeId) {
      this.submitted = true;
    } else {

      this.serviceForm = this.fb.group({
        distributorId: [this.userTypeId, Validators.required],
        firstName: ['', Validators.required],
        middleName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        telephone: ['', Validators.required],
        address: ['', Validators.required],
        address2: ['', Validators.required],
        website: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', Validators.required],
        productId: ['', Validators.required],
        userTitle: ['', Validators.required],
        loginId: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]]
      });
    }
    // this.distributorId = this.userTypeId;
  }
  getDistrFrom() {
    this.getDistrForm = this.fb.group({
      selectData: ['', Validators.required],
    });
  }
  handleSubmitDistr() {
    let distributorId = this.getDistrForm.value.selectData
    // this.getDeviceTerminalList(distributorId);
    this.getMerchantList(distributorId);
    this.showDistributor = true;


  }
  getDistributor() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distributorList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  // getUserLists(){
  //   let userTypeId = 0;
  //   let payload: any = {
  //     userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

  //   };

  //   this.service.getUserList({payload: payload}).subscribe(successResponse => {

  //     // console.log("haaa", successResponse)
  //     if(successResponse.records){
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       var records = JSON.parse(data);
  //       this.dataList =records;
  //       console.log("kkkk", records)
  //     }else {
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }

  getMerchantUserList(userTypeId?, merchantTypeId?) {
    // this.getSubmitted = true;
    // if (this.getForm.invalid) {
    //     return;
    // }
    // userTypeId?, merchantTypeId?
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      merchantTypeId: currentUser.referenceId,
      userTypeId: currentUser.userId
    };
    // let payload: any = {
    //   userTypeId: 1001,
    //   merchantTypeId: 2

    // };
    // let payload: any = {
    //   userTypeId: userTypeId,
    //   merchantTypeId: merchantTypeId

    // };
    console.log("payload", payload)

    this.isTrue = true;
    this.spinner.getSpinner();
    this.service.getDistributorMerchantList({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;
          console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getMerchantList(distributorId) {
    // var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: distributorId,
      merchantTypeId: "2"
    };
    // let payload: any = {
    //   userTypeId: userTypeId,
    //   merchantTypeId: merchantTypeId

    // };
    console.log("payload", payload)

    this.isTrue = true;
    this.spinner.getSpinner();
    this.service.getDistributorMerchantList({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;
          console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getProductLists(productId?) {
    let payload: any = {
      distributorId: 0
    };
    this.spinner.getSpinner();
    this.service.getProductList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      var selectedRecord: any = {};
      if (productId != null) {
        this.spinner.hideSpinner();
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.productId == productId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectProductData = records;
        this.productId = selectedRecord.productId;
      } else {
        this.spinner.hideSpinner();
        this.selectProductData = records;
        this.productId = "";
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      //console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  viewTransactionsInfo(data) {
    let merchantId = data.merchantId;
    this.router.navigate(["dashboard/view/transactionHistory"], { queryParams: { merchantId } })
  }
  viewMerchantsDetails(data) {
    let merchantId = data.merchantId;
    this.router.navigate(["dashboard/view/merchantDeatils"], { queryParams: { merchantId } })
  }


  getUpdateInfo(data) {
    this.accessProfileId = data.accessProfileId;
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getUserAccessProfileStatus({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // console.log("data :=> ", records);
      this.selectAccess = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getLiveCycleInfo(data) {
    this.merchantId = data.merchantId;
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getLiveCycleStatus({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      console.log("data :=> ", records);
      this.selectCycle = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onUpdateAccessProfileInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateAccessForm.invalid) {
      return;
    }
    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.updateAccessForm.value.selectedAccess
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (data.accessProfileId) {
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        // this.getMerchantUserList(this.userTypeId, this.queryMerchantTypeId);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onUpdateLiveCycleInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateCycleForm.invalid) {
      return;
    }
    let payload: any = {
      merchantId: this.merchantId,
      lifeCycleStatusId: this.updateCycleForm.value.selectedCycle
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateLiveCycleStatus({ payload: payload }).subscribe(successResponse => {
      var responseObj = successResponse.records;
      var data = responseObj;
      // console.log("UUUU=>", successResponse)
      if (data.merchantId) {
        this.toastr.success(`You have updated to ${data.merchantId}`, 'Successfully')
        $('#updateliveModel').modal('hide');
        this.spinner.hideSpinner();
        // this.getMerchantUserList(this.userTypeId);
      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  passCreateView() {
    let distributorId = this.userTypeId;
    this.router.navigate(["dashboard/view/createMerchantInfo"], { queryParams: { distributorId } })
  }
  passUpdateView(x) {
    let merchantId = x.merchantId;
    this.router.navigate(["dashboard/view/updateMerchantInfo"], { queryParams: { merchantId } })
  }


  // getMerchantSubscriptionTypesList() {
  //   let expressRoute = "/getMerchantSubscriptionTypesList";

  // }

  onUpdateMerchantSubscriptionInfo() {
    this.submitted = true
    if (this.subscripForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      merchantId: this.merchantId,
      subscriptionTypeId: this.subscripForm.value.selectedSubscription,
      doneByUserId: currentUser.userId
    };
    if (this.subscripForm.value.subscriptionAutoRenewal == true) {
      payload.subscriptionAutoRenewal = 1;
    } else {
      payload.subscriptionAutoRenewal = 0;
    }
    // console.log("QAAAAAAAQ2", payload)
    this.spinner.getSpinner();
    this.service.updateMerchantSubscription({ payload: payload }).subscribe(successResponse => {
      var data = successResponse;
      // console.log("data :=> " + JSON.stringify(data));
      if (data.success) {
        this.spinner.hideSpinner();
        var records = data.records;
        // console.log("QAAAAAAAQ1", records)
        this.toastr.success('You updated subscription', 'Successfully');
      } else {
        // console.log("QAAAAAAQ")
        this.spinner.hideSpinner();
        this.toastr.error('Someting went wron!!', 'Error')
      }

    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      //console.log("onFailure:" + JSON.stringify(failureResponse));
      this.toastr.error('Something went wron for this request', 'Error')
    });

  }

  viewSubscriptionInfo(sub) {
    this.merchantId = sub.merchantId;
    console.log(sub)

    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getMerchantSubscriptionTypes({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      //console.log("data :=> " + JSON.stringify(data));
      var records = JSON.parse(data.records);

      if (records) {
        this.spinner.hideSpinner();
        var selectedRecord: any = {};
        if (this.subscripForm.value.selectedSubscription != null) {
          var length = records.length;
          for (var i = 0; i < length; i++) {
            var item = records[i];
            if (item.subscriptionTypeId == this.subscripForm.value.selectedSubscription) {
              selectedRecord = item;
              break;
            }
          };
          this.selectSubscription = records;
          this.subscripForm = this.fb.group({
            selectedSubscription: [selectedRecord.subscriptionTypeId, Validators.required],
          });
        }
        // this.selectSubscription = records;
        // console.log("FAAAAAQ1", records)
      } else {
        this.spinner.hideSpinner();
        // console.log("FAAAAAQ")
      }

    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  passMerchantAccountList(x) {

    let merchantId = x.merchantId;
    this.router.navigate(["dashboard/view/merchantAccountList"], { queryParams: { merchantId } })
  }
  passListCashierInfo(x) {

    let merchantId = x.merchantId;
    this.router.navigate(["dashboard/view/getCashierList"], { queryParams: { merchantId } })
  }
}
