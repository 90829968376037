<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ Title }}</h4>
          </div>

          <div class="col-md-2" *ngIf="showForm === 'false'">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <form
          *ngIf="showForm === 'true'"
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="handleSubmit()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="selectDistributor">Select Distributor</label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="TransactionDate"> Auth Date Date</label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="TransactionDate"
                  placeholder="Start Date"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.TransactionDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.TransactionDate.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.TransactionDate.errors?.required"
                  >
                    Auth Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="SelectedTranType">Select a Tran Type</label>
                <select
                  class="form-control-sm"
                  formControlName="SelectedTranType"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.SelectedTranType.errors
                  }"
                >
                  <option *ngFor="let c of TranType" [ngValue]="c.id">
                    {{ c.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.SelectedTranType.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.SelectedTranType.errors?.required"
                  >
                    please Select Tran Type
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="SelectedTranStatus">Select a Tran Status</label>
                <select
                  class="form-control-sm"
                  formControlName="SelectedTranStatus"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.SelectedTranStatus.errors
                  }"
                >
                  <option *ngFor="let c of stateList" [ngValue]="c">
                    {{ c }}
                  </option>
                </select>
                <div
                  *ngIf="
                    submitted && getForm.controls.SelectedTranStatus.errors
                  "
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.SelectedTranStatus.errors?.required"
                  >
                    please Select Tran Status
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>

        <div
          class="row"
          style="margin-bottom: 0px"
          *ngIf="showForm === 'false'"
        >
          <div class="col-md-3">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>

        <div class="table-responsive" *ngIf="showForm === 'false'">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th
                  (click)="sortData('transactionDate')"
                  style="cursor: pointer"
                >
                  Date
                </th>
                <th (click)="sortData('transactionId')" style="cursor: pointer">
                  TransactionId
                </th>

                <th
                  (click)="sortData('transactionAmount')"
                  style="cursor: pointer"
                >
                  TransactionAmount
                </th>
                <th
                  (click)="sortData('transactionType')"
                  style="cursor: pointer"
                >
                  TransactionType
                </th>
                <th (click)="sortData('maskedPan')" style="cursor: pointer">
                  PAN
                </th>
                <th (click)="sortData('mobileNumber')" style="cursor: pointer">
                  Mobile Number
                </th>
                <th
                  (click)="sortData('procTransactionId')"
                  style="cursor: pointer"
                >
                  ProcTransactionId
                </th>
                <th (click)="sortData('narrative')" style="cursor: pointer">
                  Narrative
                </th>
                <th
                  (click)="sortData('transactionState')"
                  style="cursor: pointer"
                >
                  TransactionState
                </th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td>{{ x.transactionDate | date : "dd-MMM-yy" }}</td>
                <td class="py-1">{{ x.transactionId }}</td>
                <td>{{ formatTransactionAmount(x.transactionAmount) }}</td>
                <td>{{ x.transactionType }}</td>
                <td>{{ x.maskedPan }}</td>
                <td>{{ x.mobileNumber }}</td>
                <td>{{ x.procTransactionId }}</td>
                <td>{{ x.narrative }}</td>
                <td>{{ x.transactionState }}</td>
              </tr>
              <tr style="background-color: #09df66be">
                <td>Total:</td>
                <td></td>
                <td>Amount: {{ sumOfAmount | number : "1.2-2" }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
