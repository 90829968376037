import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router'
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service"
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-get-chargebacks',
  templateUrl: './get-chargebacks.component.html',
  styleUrls: ['./get-chargebacks.component.css'],
  animations: [
    fade
  ]
})
export class GetChargebacksComponent implements OnInit {

  serviceForm: FormGroup;
  submitted = false;

  tranId;
  amount;
  payerId;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.amount = atob(decodeURIComponent(params['am']))
        this.tranId = atob(decodeURIComponent(params['tr']))
        this.payerId = atob(decodeURIComponent(params['pa']))
        // console.log("UUU ==>", this.amount, this.tranId, this.payerId)
      } else {
        _location.back();
      }
    });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      referenceId: ['', Validators.required],
      reportedDate: ['', Validators.required],
      remarks: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  OnCreateChargeBack() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      transactionId: this.tranId,
      doneByUserId: currentUser.userId,
      accountNo: this.payerId,
      remarks: this.serviceForm.value.remarks,
      charges: this.amount,
      reportedByUserId: currentUser.userId,
      reportedDate: this.serviceForm.value.reportedDate,
      referenceId: this.serviceForm.value.referenceId
    }
    this.spinner.getSpinner();
    this.service.createTransactionChargeBack(payload).subscribe(successResponse => {
      if (successResponse.transactionId) {
        this.spinner.hideSpinner();
        this.toastr.success("Seccessfully: Charge has been backed", successResponse.transactionId)
        this._location.back();
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  backtowebrole() {
    this._location.back();
  }

}
