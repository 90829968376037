import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-get-subscription-report',
  templateUrl: './get-subscription-report.component.html',
  styleUrls: ['./get-subscription-report.component.css'],
  animations: [
    fade
  ]
})
export class GetSubscriptionReportComponent implements OnInit {

  serviceForm: FormGroup;
  sub: Subscription;
  queryMerchantUid: number;
  merchantUid: any;
  submitted = false;
  statusDesc;
  accountId;
  balance;
  settlementAmount;
  settlementAccount;
  settlementModeDesc;
  settlementCycleId;
  bankName;
  currency;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      this.queryMerchantUid = params['merchantUid'];
      this.merchantUid = this.queryMerchantUid;
      this.getSubscriptionMerchantInfo(this.merchantUid);
    });

  }

  createForm() {
    this.serviceForm = this.fb.group({
      merchantName: ['', Validators.required],
      subscriptionStatus: ['', Validators.required],
      charges: ['', Validators.required],
      subscriptionTypeDesc: ['', Validators.required],
      subscriptionExpDate: ['', Validators.required]
    });
  }

  OnUpdateSettlementInfo() {
    this.submitted = true;
    if (this.serviceForm.invalid) return;
  }

  backToService() {
    this._location.back();
  }

  getSubscriptionMerchantInfo(merchantUid) {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantUid: merchantUid,
    };
    this.spinner.getSpinner();
    this.service.getMerchantByUID({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      // console.log("$$$$$$=>", data)
      let merchantName = data.firstName + "  " + data.middleName + "  " + data.lastName
      this.spinner.hideSpinner();
      this.serviceForm = this.fb.group({
        merchantName: [merchantName, Validators.required],
        subscriptionStatus: [data.profileStatusDesc, Validators.required],
        charges: [data.merchantId, Validators.required],
        subscriptionTypeDesc: [data.subscriptionTypeDesc, Validators.required],
        subscriptionExpDate: [data.subscriptionExpDate, Validators.required]
      });


    },
      (failureResponse: string) => {
        this.spinner.hideSpinner();
         console.log("onFailure:" + JSON.stringify(failureResponse));
      });
  }




  ngOnInit(): void {
  }

}
