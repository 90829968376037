import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-usage-summary-report.component',
  templateUrl: './usage-summary-report.component.html',
  styleUrls: ['./usage-summary-report.component.css'],
  animations: [
    fade
  ]
})
export class UsageSummaryReportComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  Title;
  lisCustomers;
  ListOfMethods;
  currentRecordId;
  currentCardId;
  selected = 1000;
  // selected = pagination.selected;
  p: number = pagination.p;
  term;
  currentMobileNumber;
  numberOfdays;
  showForm: string = 'true';
  institutionId;
  distdataList;
  TranType
  modes;
  countriesList;
  sumOftotalTransactions = null;
  sumOfapprovedTransactions = null;
  sumdeclinedTransactions = null;
  sumrefundedTransactions = null;
  sumofunclearTransactions = null;
  sortDirection: boolean = true;
  currentSortField: string;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    // this.showForm = true
    this.getDistributorList();
    // this.getCountries();
    this.getFrom();
    this.numberOfdays = [{ id: 1, name: "Today" }, { id: 3, name: "Last 3 Days" }, { id: 7, name: "Last 7 Days" }, { id: 30, name: "Last 30 Days" }]
    this.TranType = [
      { "id": "%", "name": "All" },
      { "id": "TopUp", "name": "TopUp" },
      { "id": "Withdraw", "name": "Withdraw" },
      { "id": "Deduct", "name": "Authorize" },
      { "id": "DeductReversal", "name": "Authorize Reversal" },
      { "id": "PartialDeductReversal", "name": "Authorize Partial Reversal" },
      { "id": "DeductAdjustment", "name": "Authorize Adjustment" },
      { "id": "LoadAuth", "name": "Refund Auth" },
      { "id": "LoadAdjustment", "name": "Refund Adjustment" },
      { "id": "LoadAuthReversal", "name": "Refund Auth Reversal" },
      { "id": "TopUpReversal", "name": "TopUpReversal" }
    ]
    this.modes = [
      { "id": "%", "name": "All" },
      { "id": "WAAFI", "name": "WAAFI" },
      { "id": "BANK", "name": "BANK" },
      { "id": "NFC", "name": "NFC" },
      { "id": "EMV", "name": "EMV" },
      { "id": "ECOM", "name": "ECOM" },
      { "id": "ADMIN", "name": "ADMIN" },
      { "id": "ADJ", "name": "ADJ" }
    ]
  }
  getFrom() {
    const currentDate = new Date().toISOString().split('T')[0];

    this.getForm = this.fb.group({
      selectDistributor: ['', Validators.required],
      selectedmode: ['', Validators.required],
      selectedtranType: ['', Validators.required],
      // selectedcountry: ['', Validators.required],
      startDate: [currentDate, Validators.required],
      endDate: [currentDate, Validators.required],
    });
  }
  getDistributorList() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distdataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getCountries() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCountries({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.countriesList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  ngOnInit(): void {
    this.Title = "Usage Summary Report"
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
    } else {
      this.isThirtParty = false;

    }
  }

  backToService() {
    this.showForm = "true"
    this.listData = []
    this.sumOftotalTransactions = null;
    this.sumOfapprovedTransactions = null;
    this.sumdeclinedTransactions = null;
    this.sumrefundedTransactions = null;
    this.sumofunclearTransactions = null;
    this.getFrom();
  }
  formatTransactionAmount(amount) {
    if (amount === null || amount === undefined || amount === '') {
      return '0.00';
    }
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  usageSummary(institutionId) {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    let payload: any = {
      institutionId: institutionId,
      startDate: this.getForm.value.startDate,
      endDate: this.getForm.value.endDate,
      TranType: this.getForm.value.selectedtranType,
      captureMode: this.getForm.value.selectedmode,
      // acquirerCountry: this.getForm.value.selectedcountry,

    };

    this.spinner.getSpinner();
    this.service.usageSummary({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.showForm = 'false'
          this.listData = records;
          records.forEach((el, index, arry) => {
            let record = {};
            this.sumOftotalTransactions += el.totalTransactions;
            this.sumOfapprovedTransactions += el.approvedTransactions;
            this.sumdeclinedTransactions += el.declinedTransactions;
            this.sumrefundedTransactions += el.refundedTransactions;
            this.sumofunclearTransactions += el.unclearTransactions;
          });

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          this.toastr.error(this.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Data Not Found")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  handleSubmit() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.getForm.value.selectDistributor,
    };
    let pdays = this.getForm.value.selectedValue
    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        // this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        let institutionId = data.institutionId
        // this.institutionId = data.institutionId
        this.usageSummary(institutionId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  SelectedRecodrd(data) {
    this.currentRecordId = data.recordId;
    this.currentCardId = data.cardId;
    this.currentMobileNumber = data.mobileNumber;
  }
  sortData(sortField: string) {
    if (this.currentSortField === sortField) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortDirection = true;
    }
    this.currentSortField = sortField;

    this.listData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return this.sortDirection ? -1 : 1;
      } else if (a[sortField] > b[sortField]) {
        return this.sortDirection ? 1 : -1;
      } else {
        return 0;
      }
    });
  }
}
