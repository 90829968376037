<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-8">
            <h4 class="card-title">View Merchant Info</h4>
          </div>
          <div class="col-md-4">
            <h4 class="card-title" style="float: right">
              <button
                (click)="onButtonClick(event)"
                class="btn btn-primary mr-2 mb-2"
              >
                {{ event }}
              </button>
              <button
                type="submit"
                class="btn btn-primary mb-2"
                (click)="backToService()"
              >
                Back to Service
              </button>
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <form
              class="forms-sample"
              [formGroup]="updatesForm"
              novalidate
              (ngSubmit)="OnUpdateDistributorUser()"
            >
              <div class="card-subtitle">Merchant Info</div>
              <div
                style="
                  border: 1px solid #009e44;
                  margin-bottom: 10px;
                  background: #009e44;
                "
              ></div>
              <div class="row">
                <div class="col-md-1">
                  <div class="form-group">
                    <label for="exampleInputUsername1">Distributor Id</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="distributorId"
                      placeholder="Distributor "
                      readonly
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.distributorId.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && updatesForm.controls.distributorId.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="
                          updatesForm.controls.distributorId.errors.required
                        "
                      >
                        Distributor Id is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Product</label>
                    <select
                      class="form-control"
                      formControlName="productId"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.productId.errors
                      }"
                    >
                      <option
                        *ngFor="let p of selectProductData"
                        [ngValue]="p.productId"
                      >
                        {{ p.productName }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && updatesForm.controls.productId.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="updatesForm.controls.productId.errors.required"
                      >
                        Product is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="exampleInputUsername1">Merchant UID</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="merchantUId"
                      placeholder="Merchant UID"
                      readonly
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.merchantUId.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && updatesForm.controls.merchantUId.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="updatesForm.controls.merchantUId.errors.required"
                      >
                        Merchant UID Id is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="exampleInputUsername1">User Title</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="userTitle"
                      placeholder="User Title"
                      readonly
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.userTitle.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.userTitle.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="updatesForm.controls.userTitle.errors.required"
                      >
                        User Title is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="exampleInputUsername1">User ID</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="userId"
                      placeholder="User ID"
                      readonly
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.userId.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.userId.errors"
                      class="text-danger"
                    >
                      <div *ngIf="updatesForm.controls.userId.errors.required">
                        User ID Id is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="exampleInputUsername1">MFS PartnerUID</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="merchantIdentifier"
                      placeholder="Merchant Identifier"
                      readonly
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          updatesForm.controls.merchantIdentifier.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted &&
                        updatesForm.controls.merchantIdentifier.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="
                          updatesForm.controls.merchantIdentifier.errors
                            .required
                        "
                      >
                        Merchant Identifier is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-subtitle">Profile Info</div>
              <div
                style="
                  border: 1px solid #009e44;
                  margin-bottom: 10px;
                  margin-top: 10px;
                  background: #009e44;
                "
              ></div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="isActive">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="firstName"
                      placeholder="First Name"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.firstName.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.firstName.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="updatesForm.controls.firstName.errors.required"
                      >
                        First Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputUsername1">Middle Name</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="middleName"
                      placeholder="Middle Name"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.middleName.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && updatesForm.controls.middleName.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="updatesForm.controls.middleName.errors.required"
                      >
                        Middle Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="lastName"
                      placeholder="Last Name"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.lastName.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.lastName.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="updatesForm.controls.lastName.errors.required"
                      >
                        Last Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      formControlName="email"
                      placeholder="Email"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.email.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.email.errors"
                      class="text-danger"
                    >
                      <div *ngIf="updatesForm.controls.email.errors.required">
                        Email is required
                      </div>
                      <div *ngIf="updatesForm.controls.email.errors.email">
                        It`s Not An Email Plz Check
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputUsername1">Contact Number</label>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="telephone"
                      placeholder="Contact Number"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.telephone.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.telephone.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="updatesForm.controls.telephone.errors.required"
                      >
                        Contact Number is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputUsername1">Website</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="website"
                      placeholder="Website"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.website.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.website.errors"
                      class="text-danger"
                    >
                      <div *ngIf="updatesForm.controls.website.errors.required">
                        Website is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Address Line One</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="address"
                      placeholder="Address One"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.address.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.address.errors"
                      class="text-danger"
                    >
                      <div *ngIf="updatesForm.controls.address.errors.required">
                        Address One is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Address Line Two</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="address2"
                      placeholder="Address Two"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.address2.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.address2.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="updatesForm.controls.address2.errors.required"
                      >
                        Address Two is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">City</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="city"
                      placeholder="City"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.city.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.city.errors"
                      class="text-danger"
                    >
                      <div *ngIf="updatesForm.controls.city.errors.required">
                        City is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Country</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="country"
                      placeholder="Country"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.country.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.country.errors"
                      class="text-danger"
                    >
                      <div *ngIf="updatesForm.controls.country.errors.required">
                        Country is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col text-center">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style="width: 30%"
                  >
                    Update
                  </button>
                  <!-- <button class="btn btn-default">Centered button</button> -->
                </div>
              </div>
              <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
