<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 *ngIf="showForm === 'true'" class="card-title">{{ Title }}</h4>
          </div>

          <div class="col-md-2" *ngIf="showForm === 'false'">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <form
          *ngIf="showForm === 'true'"
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="getSettlementSummaryReport()"
        >
          <div class="row">
            <!-- <div class="col-md-2">
              <div class="form-group">
                <label for="selectDistributor">Select Distributor</label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-2">
              <div class="form-group">
                <label for="SelectedTranType">Select a Tran Type</label>
                <select
                  class="form-control-sm"
                  formControlName="SelectedTranType"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.SelectedTranType.errors
                  }"
                >
                  <option *ngFor="let c of TranType" [ngValue]="c.id">
                    {{ c.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.SelectedTranType.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.SelectedTranType.errors?.required"
                  >
                    please Select Tran Type
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="startDate">Start Date </label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="startDate"
                  placeholder="Start Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.startDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.startDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.startDate.errors?.required">
                    start Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="endDate">End Date </label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="endDate"
                  placeholder="Start Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.endDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.endDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.endDate.errors?.required">
                    Settlement Date is required
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>

        <div *ngIf="showForm === 'false'">
          <h4 class="text-center">Settlement Summary Report</h4>
          <p class="text-center">
            {{ startDate | date : "MMMM dd,yyyy" }} -
            {{ endDate | date : "MMMM dd,yyyy" }}
          </p>
        </div>

        <div
          class="row"
          style="margin-bottom: 0px"
          *ngIf="showForm === 'false'"
        >
          <div class="col-md-2">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>

        <div class="table-responsive" *ngIf="showForm === 'false'">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th
                  (click)="sortData('settlementDate')"
                  style="cursor: pointer"
                >
                  Date
                </th>
                <th (click)="sortData('tranType')" style="cursor: pointer">
                  Type
                </th>

                <th (click)="sortData('settlementEur')" style="cursor: pointer">
                  Settlement (EUR)
                </th>
                <th (click)="sortData('settlementUsd')" style="cursor: pointer">
                  Settlement (USD)
                </th>
                <th
                  (click)="sortData('interchangeAmount')"
                  style="cursor: pointer"
                >
                  Interchange Amount
                </th>
                <th
                  (click)="sortData('avgInterchangeRate')"
                  style="cursor: pointer"
                >
                  Interchange Rate
                </th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td>{{ x.settlementDate }}</td>

                <td class="py-1">{{ x.tranType }}</td>
                <td>{{ x.settlementEur }}</td>
                <!-- <td>{{ formatTransactionAmount(x.transactionAmount) }}</td> -->
                <td>{{ x.settlementUsd }}</td>
                <td>{{ formatTransactionAmount(x.interchangeAmount) }}</td>
                <td>{{ formatTransactionAmount(x.avgInterchangeRate) }}</td>
              </tr>
              <tr style="background-color: #09df66be">
                <td>Total:</td>
                <td></td>
                <td>{{ sumSettlementEur | number : "1.2-2" }}</td>
                <td>{{ sumSettlementUsd | number : "1.2-2" }}</td>
                <td>{{ sumInterchangeAmount | number : "1.2-2" }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
