<div @fade class="row">
    <div class="col grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Update User Status & Create Cashier</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backToCashier()">Back To Cashier</button>
                        </h4>
                    </div>
                </div>
                <form class="forms-sample" [formGroup]="updateCycleForm" novalidate (ngSubmit)="changeUpdates()">
                    <div class="card-subtitle">Merchant Info</div>
                    <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Merchant Id</label>
                                <input type="text" class="form-control" formControlName="merchantId"
                                    placeholder="Merchant Id" readonly
                                    [ngClass]="{ 'is-invalid': submitted && updateCycleForm.controls.merchantId.errors }">
                                <div *ngIf="submitted && updateCycleForm.controls.merchantId.errors" class="text-danger">
                                    <div *ngIf="updateCycleForm.controls.merchantId.errors.required">Merchant Idis required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="isActive">Merchant UID</label>
                                <input type="text" class="form-control" formControlName="merchantUid"
                                    placeholder="Merchant UID" readonly
                                    [ngClass]="{ 'is-invalid': submitted && updateCycleForm.controls.merchantUid.errors }">
                                <div *ngIf="submitted && updateCycleForm.controls.merchantUid.errors" class="text-danger">
                                    <div *ngIf="updateCycleForm.controls.merchantUid.errors.required">Merchant UID is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="isActive">Merchant Name</label>
                                <input type="text" class="form-control" formControlName="merchantName"
                                    placeholder="Merchant UID" readonly
                                    [ngClass]="{ 'is-invalid': submitted && updateCycleForm.controls.merchantName.errors }">
                                <div *ngIf="submitted && updateCycleForm.controls.merchantName.errors" class="text-danger">
                                    <div *ngIf="updateCycleForm.controls.merchantName.errors.required">Merchant Name is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-subtitle">Access Profile</div>
                    <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Status</label>
                                <select class="form-control" formControlName="selectedCycle" (change)='onOptionsSelected($event)'>
                                    <option value=null disabled selected>--Select--</option>
                                    <option *ngFor="let p of selectCycle; index as i" [ngValue]="p">
                                    {{p.lifeCycleStatus}}</option>
                                </select>
                                <div *ngIf="submitted && updateCycleForm.controls.selectedCycle.errors" class="text-danger">
                                    <div *ngIf="updateCycleForm.controls.selectedCycle.errors.required">User Status is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="isApproved">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Cashier Type</label>
                                <select class="form-control" formControlName="selectData" (change)="onChange($event)"
                                    [ngClass]="{ 'is-invalid': submitted && updateCycleForm.controls.selectData.errors }">
                                    <option *ngFor="let p of dataLists" [ngValue]="p.cashierTypeId">{{p.description}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && updateCycleForm.controls.selectData.errors" class="text-danger">
                                    <div *ngIf="updateCycleForm.controls.selectData.errors.required">Cashier Type is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="isApproved">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Payment Proccosor</label>
                                <select class="form-control" formControlName="paymentProcessor"
                                    [ngClass]="{ 'is-invalid': submitted && updateCycleForm.controls.paymentProcessor.errors }">
                                    <option *ngFor="let p of selectProccesor; index as i" [ngValue]="p">{{p.paymentProcessorTypeDesc}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && updateCycleForm.controls.paymentProcessor.errors" class="text-danger">
                                    <div *ngIf="updateCycleForm.controls.paymentProcessor.errors.required">Payment Processor Id is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Reson</label>
                                <input type="text" class="form-control" formControlName="reson" placeholder="Reson"
                                    [ngClass]="{ 'is-invalid': submitted && updateCycleForm.controls.reson.errors }">
                                <div *ngIf="submitted && updateCycleForm.controls.reson.errors" class="text-danger">
                                    <div *ngIf="updateCycleForm.controls.reson.errors.required">Reson is required</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-3">
                            <div class="form-group">
                                <label for="isActive">Password</label>
                                <input type="password" class="form-control" formControlName="password"
                                    placeholder="Password"
                                    [ngClass]="{ 'is-invalid': submitted && updateCycleForm.controls.password.errors }">
                                <div *ngIf="submitted && updateCycleForm.controls.password.errors" class="text-danger">
                                    <div *ngIf="updateCycleForm.controls.password.errors.required">Password is required
                                    </div>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                            <!-- <button class="btn btn-default">Centered button</button> -->
                        </div>
                    </div>
                    <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button> -->
                </form>
            </div>
        </div>
    </div>
</div>