import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-view-payment-processor-system',
  templateUrl: './view-payment-processor-system.component.html',
  styleUrls: ['./view-payment-processor-system.component.css'],
  animations: [
    fade
  ]
})
export class ViewPaymentProcessorSystemComponent implements OnInit {
  serviceForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  querysystemId: number;
  systemId: any;
  dataRecords: any;
  sub: Subscription;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.updateForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.querysystemId = +params['systemId'];
        if (Number.isNaN(this.querysystemId)) {
          this.systemId = "";
        } else {
          this.systemId = this.querysystemId;
          this.viewPaymentProcessorSystemInfo(this.querysystemId)
        }
      });
  }
  backToService() {
    this._location.back();
  }
  updateForm() {
    this.serviceForm = this.fb.group({
      systemId: ['', Validators.required],
      systemName: ['', Validators.required],
      description: ['', Validators.required],
      protocolId: ['', Validators.required],
      isActive: ['', Validators.required],
      systemUrl: ['', Validators.required],
      systemSecret: ['', Validators.required],
      systemUsername: ['', Validators.required],
      systemPassword: ['', Validators.required],
      myIp: ['', Validators.required],
      myPartnerId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  viewPaymentProcessorSystemInfo(systemId) {
    let payload: any = {
      systemId: systemId,
    };
    this.service.getPaymentProcessorSystemInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.serviceForm = this.fb.group({
          systemId: [data.systemId, Validators.required],
          systemName: [data.systemName, Validators.required],
          description: [data.description, Validators.required],
          protocolId: [data.protocolId, Validators.required],
          protocolName: [data.protocolName, Validators.required],
          isActive: [data.isActive, Validators.required],
          systemUrl: [data.systemUrl, Validators.required],
          systemSecret: [data.systemSecret, Validators.required],
          systemUsername: [data.systemUsername, Validators.required],
          systemPassword: [data.systemPassword, Validators.required],
          myIp: [data.myIp, Validators.required],
          myPartnerId: [data.myPartnerId, Validators.required],
        });
        // console.log("kkkk", data)
      } else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  OnUpdatePaymentProcessorSystem() {
    this.submitted = true
    if (this.serviceForm.invalid) {
      return;
    }

    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      description: this.serviceForm.value.description,
      systemName: this.serviceForm.value.systemName,
      systemId: this.serviceForm.value.systemId,
      protocolId: this.serviceForm.value.protocolId,
      protocolName: this.serviceForm.value.protocolName,
      isActive: this.serviceForm.value.isActive,
      systemUrl: this.serviceForm.value.systemUrl,
      systemSecret: this.serviceForm.value.systemSecret,
      systemUserName: this.serviceForm.value.systemUsername,
      systemPwd: this.serviceForm.value.systemPassword,
      myIp: this.serviceForm.value.myIp,
      myPartnerId: this.serviceForm.value.myPartnerId,
    };
    // console.log("CREATE ====", payload);
    this.service.updatePaymentProcessorSystemInfo(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if(successResponse){
        this.toastr.success(`You have updated`, 'Seccessfully')
        this.router.navigate(["dashboard/listPaymentProcessorSystem"])
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
        console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
}
