import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-create-bank',
  templateUrl: './create-bank.component.html',
  styleUrls: ['./create-bank.component.css'],
  animations: [
    fade
  ]
})
export class CreateBankComponent implements OnInit {

  serviceForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  querypaymentProcessorId: number;
  paymentProcessorId: any;
  dataRecords: any;
  sub: Subscription;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      selectedPaymentProcessorValue: ['', Validators.required],
      address: ['', Validators.required],
      bankName: ['', Validators.required],
      bankCode: ['', Validators.required],
    });
  }

  backToService() {
    this._location.back();
  }

  ngOnInit(): void {
  }

  OnCreateBankInfo(){
    this.submitted = true;
    if (this.serviceForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let expressRoute = "/createBank";
    let payload: any = {
      createdByUserId: currentUser.userId,
      address: this.serviceForm.value.address,
      bankName: this.serviceForm.value.bankName,
      bankCode: this.serviceForm.value.bankCode,
      paymentProcessorId: this.serviceForm.value.selectedPaymentProcessorValue,
    };
    this.service.createBank(payload).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      if(data.id){
        this.toastr.success('You created a bank', 'Successfuly');
        this._location.back();
        // console.log("Haa=> ", data)
      }else{
        this.toastr.error('Something went wron', 'Error');
      }
    }, (failureResponse: string) => {
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });

  }


}
