<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">All Settlement Management</h4>
                    </div>
                    <div class="col-md-2" *ngIf="isTrue">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Settlement</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>SettId</th>
                                <th>Status</th>
                                <th>Time</th>
                                <th>Account Id</th>
                                <th>Amount</th>
                                <th>Sett Tran Id</th>
                                <th>Tran Head Id</th>
                                <th>Issuer SettId</th>
                                <th>Description</th>
                                <th>Is Credit</th>
                                <th>Action</th>
                                <th>Process</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listBody">
                            <tr *ngFor="let x of listData | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td>{{ x.settlementId}}</td>
                                <td>{{ x.settlementStatus}}</td>
                                <td>{{ x.settlementTime | date:'dd-MMM-yy - HH:mm:ss'}}</td>
                                <td>{{ x.accountId}}</td>
                                <td>{{ x.amount|number:'1.2-2' }}</td>
                                <td>{{ x.settlementTranId}}</td>
                                <td>{{ x.refTranHeadId}}</td>
                                <td>{{ x.issuerSettlementId}}</td>
                                <td>{{ x.description}}</td>
                                <td>{{ x.isCredit}}</td>
                                <td (click)="passSettlementInfo(x)" data-toggle="modal" data-target="#viewModel">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                                <td (click)="passProcessSettlementInfo(x)" data-toggle="modal"
                                    data-target="#createModel">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade bd-example-modal-md" id="createModel" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

    <div class="modal-dialog modal-sm-12" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Process Settlement</h4>
                            <form class="forms-sample" [formGroup]="createForm" novalidate
                                (ngSubmit)="OnProcessSettlement()">
                                <div class="card-subtitle">Process</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Settlement Id</label>
                                    <input type="text" class="form-control" formControlName="settlementId"
                                        placeholder="Settlement Id" readonly
                                        [ngClass]="{ 'is-invalid': submitted && createForm.controls.settlementId.errors }">
                                    <div *ngIf="submitted && createForm.controls.settlementId.errors"
                                        class="text-danger">
                                        <div *ngIf="createForm.controls.settlementId.errors.required">Settlement Id
                                            is required</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>