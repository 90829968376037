<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">View Settlement Request Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to Page</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="updatesForm" novalidate
                        (ngSubmit)="OnUpdateSettlementInfo()">

                        <div class="card-subtitle">Settlement Info</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">Settlement Request Id</label>
                                    <input type="text" class="form-control" formControlName="settlementRequestId"
                                        readonly placeholder="Settlement Request Id"
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.settlementRequestId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.settlementRequestId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.settlementRequestId.errors.required">Settlement Request Id
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Status Id</label>
                                    <input type="text" class="form-control" formControlName="statusId"
                                        readonly placeholder="Status Id"
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.statusId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.statusId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.statusId.errors.required">Status Id
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Account Id</label>
                                    <input type="text" class="form-control" formControlName="accountId"
                                        readonly placeholder="Account Id"
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.accountId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.accountId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.accountId.errors.required">Account Id is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Amount</label>
                                    <input type="text" class="form-control" formControlName="amount"
                                        readonly placeholder="Amount"
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.amount.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.amount.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.amount.errors.required">Amount is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Requested By UserId</label>
                                    <input type="text" class="form-control" formControlName="requestedByUserId"
                                        readonly placeholder="Requested By UserId"
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.requestedByUserId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.requestedByUserId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.requestedByUserId.errors.required">Requested By UserId
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Requested For UserId</label>
                                    <input type="text" class="form-control" formControlName="requestedForUserId"
                                        readonly placeholder="Requested For UserId"
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.requestedForUserId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.requestedForUserId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.requestedForUserId.errors.required">Requested For UserId is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Created Date</label>
                                    <input type="text" class="form-control" formControlName="createdDate"
                                        readonly placeholder="Created Date"
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.createdDate.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.createdDate.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.createdDate.errors.required">Created Date is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Description</label>
                                    <input type="text" class="form-control" formControlName="description"
                                        readonly placeholder="Description"
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.description.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.description.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.description.errors.required">Description is
                                            required</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button> -->
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

