import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
declare var $: any;
import { Location } from '@angular/common';
import { ServiceService } from "src/app/service/service/service.service";
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
import { Router, ActivatedRoute } from '@angular/router';
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-cashier-active-table',
  templateUrl: './cashier-active-table.component.html',
  styleUrls: ['./cashier-active-table.component.css'],
  animations: [
    fade
  ]
})
export class CashierActiveTableComponent implements OnInit {
  _USER_TYPE_ID_CASHIER = 40;
  dataList;
  serviceForm: FormGroup;
  updateAccessForm: FormGroup;
  updateResonForm: FormGroup;
  updatesForm: FormGroup;
  submitted = false;
  modal;
  btnDisabled: boolean = false;
  merchantUid;
  merchantName;
  cashierId;
  queryCashierId;
  event;
  selectAccess;
  addressList;
  accessProfileId;
  eventTypeId;
  newValue;
  selectValue;
  selectedValue;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    // private spinner: NgxSpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getUpdateInfo();
    this.getUserAddress();
    this.updatedAccessForm();
    this.updateResonsForm();
    this.updateForm();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.queryCashierId = params['cashierId'];
        this.cashierId = this.queryCashierId;
        this.getCashierInfo(this.cashierId);
      } else {
        _location.back();
      }
    });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      userTitle: ['', Validators.required],
      userId: ['', Validators.required],
      selectedAccess: ['', Validators.required],
    });
  }

  onChange(deviceValue) {
    if (this.selectedValue === this.selectAccess[deviceValue.target.selectedIndex -1].profileStatusId) {
      this.newValue = this.selectAccess[deviceValue.target.selectedIndex -1].profileStatusDesc;
      console.log(this.selectAccess[deviceValue.target.selectedIndex -1].profileStatusDesc);
    } 
  }
  
  getUserAddress(){
    this.service.getIpAddress().subscribe(res => {
      console.log(res);
      this.addressList = res;
      console.log(window.location.href);
    })
  }

  updatedAccessForm(){
    this.updateAccessForm = this.fb.group({
      selectedAccess: ['', Validators.required],
      
    });
  }
  updateResonsForm(){
    this.updateResonForm = this.fb.group({
      reson: ['', Validators.required],
    });
  }

  updateForm() {
    this.updatesForm = this.fb.group({
      distributorId: [''],
      merchantUId: [''],
      userId: [''],
      selectedAccess: ['', Validators.required],
      productName: [''],
      userTitle: [''],
    });
  }

  getUpdateInfo() {
    let payload: any = {};
    this.spinner.getSpinner();
      this.service.getUserAccessProfileStatus({ payload: payload }).subscribe(successResponse => {
        this.spinner.hideSpinner();
        let responseObj = JSON.stringify(successResponse.records);
        let data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        // console.log("data :=> ", records);
        this.selectAccess = records;
        // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  backToCashier() {
    this._location.back();
  }


  ngOnInit(): void {
    this.event = 'Edit'
    this.serviceForm.disable()
  }


  onButtonClick(event){
    if(event == 'Edit'){
      this.event = 'Disabled'
      this.serviceForm.enable()
      this.btnDisabled = true;
    }else{
      this.event = 'Edit'
      this.serviceForm.disable()
      this.btnDisabled = false;
    }
  }

  getCashierInfo(cashierId) {
    let payload: any = {
      cashierId: cashierId

    };
    this.spinner.getSpinner();
    this.service.getCashierInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.accessProfileId = data.accessProfileId;
        this.dataList = data;
        console.log("haaa=> ", data);
        // this.merchantUid = data.merchantUid;
        // this.merchantName = data.firstName + " " + data.middleName + " " + data.lastName;
        this.serviceForm = this.fb.group({
          userId: [data.userId, Validators.required],
          userTitle: [data.userTitle, Validators.required],
          selectedAccess: [data.profileStatusId, Validators.required],
        });
        // this.selectValue = data.profileStatusId;
        // console.log("kkkk", data)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  onUpdateAccessProfileInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.serviceForm.invalid || this.updateResonForm.invalid) {
      return;
    }
    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.serviceForm.value.selectedAccess
    };
    console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if(data.accessProfileId){
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide'); 
        this.spinner.hideSpinner();
        this.getCashierInfo(this.cashierId);
        this.fnCreateEventLogs();
      }else{
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  fnCreateEventLogs(){
    var currentUser = this._sessionStorageService.getSessionData();
    

    let payload: any = {
      userId: this.dataList.userId,
      eventTypeId: 1,
      previousValue: this.dataList.profileStatusDesc,
      newValue: this.newValue,
      reson: this.updateResonForm.value.reson,
      sessionId: currentUser.referenceId,
      address: JSON.stringify(this.addressList),
      ipAddress: this.addressList,
      applicationId: currentUser.userTypeId,
      doneByUserId: currentUser.userId,
    };
    console.log("payload=> ", payload)
    this.service.createEventLogs({payload: payload}).subscribe((res) => {
      console.log(res)

    })
    console.log(payload)
  }

}
