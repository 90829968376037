import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-createmerchant',
  templateUrl: './createmerchant.component.html',
  styleUrls: ['./createmerchant.component.css'],
  animations: [
    fade
  ]
})
export class CreatemerchantComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  getForm: FormGroup;
  queryDistributorId: number;
  distributorId: any;
  sub: Subscription;
  queryUserId: number;
  userId: any;
  dataRecords: any;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  productId;
  isTrue: boolean = false;
  selectCountryData;
  selectMessage;
  selectCategoryData;
  selectZoneData;
  selectSettlementModeData;
  selectedSettlementModeValue;
  selectBankData;
  selectedBankValue;
  selectAccountTypesData;
  selectedAccountTypeValue;
  selectCurrencyData;
  selectCitiesData;
  selectedMessage;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getProductLists();
    this.getFrom();
    this.getCountryList();
    this.getUserZoneList();
    this.getMerchantCategoryCodeList();
    this.fnGetSettlementModeList();
    this.fnGetBankList();
    this.fnGetSettlementAccountTypes();
    this.fnGetSettlementModeList();
    this.getCurrencyList();
    this.getCitiesList();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryDistributorId = +params['distributorId'];
        if (Number.isNaN(this.queryDistributorId)) {
          this.distributorId = "";
        } else {
          this.distributorId = this.queryDistributorId;
          // console.log("HHHH => ", this.distributorId)
        }
      });
    this.createForm();
  }


  createForm() {
    this.serviceForm = this.fb.group({
      distributorId: [this.distributorId, Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      merchantCategory: ['', Validators.required],
      productId: ['', Validators.required],
      userZone: ['', Validators.required],
      userTitle: ['', Validators.required],
      loginId: ['', Validators.required],
    });
  }
  getFrom() {
    this.getForm = this.fb.group({
      selectData: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  getCurrencyList() {
    let payload: any = {}
    this.spinner.getSpinner();
    this.service.getCurrencyList({ payload: payload }).subscribe((response) => {
      const successResponse = response;
      // console.log("response =>", successResponse);
      if (successResponse.success) {
        this.spinner.hideSpinner();
        const data = successResponse.records;
        this.selectCurrencyData = data
      } else {
        this.spinner.hideSpinner();
        this.toastr.warning('No Records Found', 'warning');
      }
      // const data = JSON.parse(successResponse);
    }, (failureResponse: string) => {
      this.toastr.error("Server seems got bad request!");
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  getCitiesList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCitiesList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      console.log("Cities=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCitiesData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectedMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  fnGetSettlementModeList(merchantSettlmentId?) {
    let payload: any = {};
    this.service.getSettlementModes({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      console.log("data :=> " + JSON.stringify(data));
      var records = JSON.parse(data);
      var selectedRecord: any = {};
      if (merchantSettlmentId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.settlementModeId == merchantSettlmentId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectSettlementModeData = records;
        this.selectedSettlementModeValue = selectedRecord.merchantSettlmentId;
      } else {
        this.selectSettlementModeData = records;
        this.selectedSettlementModeValue = "";
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  fnGetBankList(bankId?) {
    let expressRoute = "/getBanksList";
    let payload: any = {
    };
    this.service.getBankList({ payload: payload }).subscribe(successResponse => {
      var data = JSON.parse(successResponse.records);
      console.log("data :=> ", JSON.parse(successResponse.records));

      var records = data;
      var selectedRecord: any = {};
      if (bankId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.bankId == bankId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectBankData = records;
        this.selectedBankValue = selectedRecord.bankId;
      } else {
        this.selectBankData = records;
        this.selectedBankValue = "";
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  fnGetSettlementAccountTypes(accountTypeId?) {
    let expressRoute = '/getSettlementAccountTypes';
    let payload: any = {
    };
    this.service.getSettlementAccountTypes({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      // console.log("data :=> " + responseObj);
      var records = JSON.parse(data);
      var selectedRecord: any = {};
      if (accountTypeId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.settlementAccountTypeId == accountTypeId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectAccountTypesData = records;
        this.selectedAccountTypeValue = selectedRecord.settlementAccountTypeId;
      } else {
        this.selectAccountTypesData = records;
        this.selectedAccountTypeValue = "";
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getProductLists(productId?) {
    let payload: any = {
      distributorId: 0
    };
    this.spinner.getSpinner();
    this.service.getProductList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      var selectedRecord: any = {};
      if (productId != null) {
        this.spinner.hideSpinner();
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.productId == productId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectProductData = records;
        this.productId = selectedRecord.productId;
      } else {
        this.spinner.hideSpinner();
        this.selectProductData = records;
        this.productId = "";
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getUserZoneList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getUserZoneList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      // console.log("Country=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectZoneData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getCountryList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCountryList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      // console.log("Country=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCountryData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  getMerchantCategoryCodeList() {
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getMerchantCategoryCode({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      console.log("Country=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCategoryData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }


  backToService() {
    this._location.back()
  }


  OnCreateMerchantUser() {
    this.submitted = true;
    if (this.serviceForm.invalid) {
      return;
    }

    var currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = this._USER_TYPE_ID_MERCHANT;
    let payload: any = {
      createdByUserId: currentUser.userId,
      merchantGroupId: "2",
      productId: this.serviceForm.value.productId,
      zoneId: this.serviceForm.value.userZone,
      firstName: this.serviceForm.value.firstName,
      middleName: this.serviceForm.value.middleName,
      lastName: this.serviceForm.value.lastName,
      telephone: this.serviceForm.value.telephone,
      address: this.serviceForm.value.address,
      email: this.serviceForm.value.email,
      address2: this.serviceForm.value.address2,
      city: this.serviceForm.value.city,
      country: this.serviceForm.value.country,
      website: this.serviceForm.value.website,
      merchantName: this.serviceForm.value.userTitle,
      categoryCodeId: this.serviceForm.value.merchantCategory,
      userTitle: this.serviceForm.value.userTitle,
      loginId: this.serviceForm.value.loginId,
      distributorId: this.serviceForm.value.distributorId,
      userTypeId: userTypeId
    };
    // let payloads: any = {
    //   distributorId: this.serviceForm.value.distributorId,
    //   createdByUserId: currentUser.userId,
    //   merchantGroupId: "2",
    //   productId: this.serviceForm.value.productId,
    //   zoneId: this.serviceForm.value.userZone,
    //   firstName: this.serviceForm.value.firstName,
    //   middleName: this.serviceForm.value.middleName,
    //   lastName: this.serviceForm.value.lastName,
    //   telephone: this.serviceForm.value.telephone,
    //   address: this.serviceForm.value.address,
    //   email: this.serviceForm.value.email,
    //   address2: this.serviceForm.value.address2,
    //   city: this.serviceForm.value.city,
    //   country: this.serviceForm.value.country,
    //   website: this.serviceForm.value.website,
    //   merchantName: this.serviceForm.value.userTitle,
    //   categoryCodeId: this.serviceForm.value.merchantCategory,
    //   userTitle: this.serviceForm.value.userTitle,
    //   loginId: this.serviceForm.value.loginId,
    //   userTypeId: userTypeId,
    //   settlementAccountNo: this.serviceForm.value.settlementAccount,
    //   currencyId: this.serviceForm.value.currencyId,
    //   issuerId: this.serviceForm.value.selectedBankValue,
    //   settlementModeId: this.serviceForm.value.selectedSettlementModeValue,
    //   settlementCycleId: this.serviceForm.value.settlementCycleId,
    //   settlementAccountTypeId: this.serviceForm.value.selectedAccountTypeValue,
    // };

    console.log("CREATE ====", payload);
    this.spinner.getSpinner();
    this.service.createMerchantUser(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if (successResponse) {
        this.spinner.hideSpinner();
        this.toastr.success(`You have created`, 'Seccessfully')
        this.router.navigate(["dashboard/listMerchantUserInfo"])
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });

  }

}
