import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";

declare var $: any;

@Component({
  selector: 'app-Card-Pending-Requests',
  templateUrl: './Card-Pending-Requests.component.html',
  styleUrls: ['./Card-Pending-Requests.component.css'],
  animations: [
    fade
  ]
})
export class CardPendingRequests implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  getDistrForm: FormGroup;
  showDistributor = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  stationsForm: FormGroup;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  merchantTitle;
  distributorList
  lisCustomers;
  ListOfStations;
  selected = pagination.selected;
  p: number = pagination.p;
  term;
  currentRecordId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.subscripsForm();
    this.createForm();
    this.stationForm();
    this.getFrom();
    this.getDistributor();
    this.getDistrFrom();
  }
  getFrom() {
    this.getForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }
  handleSubmit() {
    this.submitted = true;
    if (this.getForm.invalid) {
      return;
    }
    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate
    this.getPendingCardRecords(this.distributorId, startDate, endDate);
  }
  handleSubmitDistr() {
    let distributorId = this.getDistrForm.value.selectData
    this.distributorId = distributorId
    // this.getDeviceTerminalList(distributorId);

    this.getPendingCardRecords(distributorId, "", "");
    this.showDistributor = true;


  }
  getDistrFrom() {
    this.getDistrForm = this.fb.group({
      selectData: ['', Validators.required],
    });
  }
  backToService() {
    this.showDistributor = false;
    this.dataList = []
    this.listData = []
    this.lisCustomers = []
  }
  getDistributor() {
    let currentUser = this._sessionStorageService.getSessionData();
    // let checkCurrentSession = this._sessionStorageService.checkPermission("modify_cardholder");
    // alert(checkCurrentSession)
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR
    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distributorList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  SelectedRecodrd(data) {
    this.currentRecordId = data.recordId;
  }
  stationForm() {
    this.stationsForm = this.fb.group({
      instutitionBranchId: ['', Validators.required],
    });
  }
  createForm() {
    this.serviceForm = this.fb.group({
      distributorId: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      productId: ['', Validators.required],
      userTitle: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  getListOfStations() {
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.distributorId,
    };
    this.spinner.getSpinner();
    this.service.getListOfStations({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.ListOfStations = records;

      } else {

        this.spinner.hideSpinner();

        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  HandleSubmitUpdateStations() {
    this.submitted = true
    if (this.stationsForm.invalid) {
      return;
    }
    let payload: any = {
      InstutitionBranchId: this.stationsForm.value.instutitionBranchId,
      RecordId: this.currentRecordId,
    };

    this.spinner.getSpinner();
    this.service.updateCardbBranch({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        $('#updateSubscriptionModel').modal('hide');
        this.toastr.success(successResponse.records.message)
        this.getPendingCardRecords(this.distributorId, "", "")
        this.spinner.hideSpinner();
      } else {

        this.spinner.hideSpinner();
        this.toastr.warning(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  subscripsForm() {
    this.subscripForm = this.fb.group({
      subscriptionAutoRenewal: ['', Validators.required],
      selectedSubscription: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    // console.log("PPPPP=>", this.selectData)
    this.merchantTitle = "Card Pending Requests"
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
    } else {
      this.isThirtParty = false;

    }
  }

  getPendingCardRecords(distributorId, startDate, endDate) {
    let payload: any = {
      pState: "PrintDeclined",
      distributorId: distributorId,
      startDate: startDate,
      endDate: endDate
    };
    this.isTrue = true;
    this.spinner.getSpinner();
    this.service.getPendingCardRecords({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          records?.forEach((el: any) => {
            if (el.status == "PrintAccepted") {
              el.printName = "Validate"
            } else if (el.status == "PrintDeclined") {
              el.printName = "Retry Print"
            } else {
              el.printName = "Print"
            }

          })
          this.listData = records;

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  clearForm() {
    this.listData = this.lisCustomers;
    this.getForm.reset();
  }

  onUpdateAccessProfileInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateAccessForm.invalid) {
      return;
    }
    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.updateAccessForm.value.selectedAccess
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (data.accessProfileId) {
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();


      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onUpdateMerchantSubscriptionInfo() {
    this.submitted = true
    if (this.subscripForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      merchantId: this.merchantId,
      subscriptionTypeId: this.subscripForm.value.selectedSubscription,
      doneByUserId: currentUser.userId
    };
    if (this.subscripForm.value.subscriptionAutoRenewal == true) {
      payload.subscriptionAutoRenewal = 1;
    } else {
      payload.subscriptionAutoRenewal = 0;
    }
    // console.log("QAAAAAAAQ2", payload)
    this.spinner.getSpinner();
    this.service.updateMerchantSubscription({ payload: payload }).subscribe(successResponse => {
      var data = successResponse;
      // console.log("data :=> " + JSON.stringify(data));
      if (data.success) {
        this.spinner.hideSpinner();
        var records = data.records;
        // console.log("QAAAAAAAQ1", records)
        this.toastr.success('You updated subscription', 'Successfully');
      } else {
        // console.log("QAAAAAAQ")
        this.spinner.hideSpinner();
        this.toastr.error('Someting went wron!!', 'Error')
      }

    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      //console.log("onFailure:" + JSON.stringify(failureResponse));
      this.toastr.error('Something went wron for this request', 'Error')
    });

  }

  passCardPrint(data) {
    let payload: any = {
      recordId: data.recordId,
      satelliteUID: data.printUid,
    };
    this.spinner.getSpinner();
    this.service.cardPrint({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
      } else {
        this.spinner.hideSpinner();
        this.toastr.warning(successResponse.records.message);
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  cardReprint(x) {
    // this.submitted = true
    // if (this.getForm.invalid) {
    //   return;
    // }
    // x.requestedDate
    // 21-Jun-24
    const givenDate = moment(x.requestedDate, 'YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'days');
    let formattedYesterday = yesterday.format('YYYY-MM-DD')

    if (givenDate.isSameOrBefore(formattedYesterday)) {
      // console.log('The given date is lower or equal to yesterday\'s date.');

      const currentUser = this._sessionStorageService.getSessionData();
      let payload: any = {
        cardId: x.cardId,
        mobileNumber: x.mobileNumber
      };

      this.spinner.getSpinner();
      this.service.cardReprint({ payload: payload }).subscribe(successResponse => {
        if (successResponse.success) {
          // $('#updateSubscriptionModel').modal('hide');
          this.toastr.success(successResponse.records.message)
          // this.getPrintedPanFileRecords(this.distributorId)
          this.getPendingCardRecords(this.distributorId, "", "");
          this.spinner.hideSpinner();
        } else {

          this.spinner.hideSpinner();
          this.toastr.warning(successResponse.records.message)
        }
      }, (failureResponse: any) => {
        if (failureResponse.status === 401) {
          window.location.href = 'login';
        } else {
          this.spinner.hideSpinner();
          console.log("onFailure:" + JSON.stringify(failureResponse));
        }
      });

    } else {

      return this.toastr.warning("Card Physical Request is in process.")
    }

  }
}
