import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Angular2Csv } from 'angular2-csv';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-blocked-cards-report-table.component',
  templateUrl: './blocked-cards-report-table.component.html',
  styleUrls: ['./blocked-cards-report-table.component.css'],
  animations: [
    fade
  ]
})
export class BlockedCardsReportTable implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  Title;
  lisCustomers;
  ListOfMethods;
  currentRecordId;
  currentCardId;
  selected = 1000;
  // selected = pagination.selected;
  p: number = pagination.p;
  term;
  currentMobileNumber;
  numberOfdays;
  showForm: string = 'true';
  institutionId;
  stateList;
  TranType;
  selectedDate;
  distdataList;
  sortDirection: boolean = true;
  currentSortField: string;
  settlementDate;
  sumInterchangeAmount = null;
  sumSettlementAmount = null;
  sumTransactionAmount = null;
  SelectedMonth;
  listDataTemp;
  pNumber;
  selectedCard;
  CardEventLogs
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.institutionId = params['institutionId'];
        this.pNumber = params['pNumber'];
        this.SelectedMonth = params['SelectedMonth'];
        this.selectedDate = this.SelectedMonth
        // this.showForm = true
        // this.getDistributorList();
        this.getBlockedCard(this.institutionId, this.pNumber,)
        this.getFrom();
        this.TranType = [
          { "id": "%", "name": "All" },
          { "id": "DEDUCT", "name": "DEDUCT" },
          { "id": "LOAD", "name": "LOAD" },
          { "id": "REVERSAL", "name": "REVERSAL" },
        ]

      }
    });
  }
  getFrom() {
    const currentDate = new Date().toISOString().split('T')[0];
    this.getForm = this.fb.group({
      selectDistributor: ['', Validators.required],
      settlementDate: [currentDate, Validators.required],
      SelectedTranType: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.Title = "Card Summary By Month Report"
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
    } else {
      this.isThirtParty = false;

    }
  }

  backToService() {
    // this._location.back();
    this._location.back();
    this.listData = []
    this.getFrom()

  }
  formatTransactionAmount(amount) {
    if (amount === null || amount === undefined || amount === '') {
      return '0.00';
    }
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  getBlockedCard(institutionId, pNumber) {
    // const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      PinstitutionId: institutionId,
      pNumber: pNumber,
    };

    this.spinner.getSpinner();
    this.service.getBlockedCardsReport({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.showForm = 'false'
          this.listData = records;
          this.listDataTemp = records;

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          this.toastr.warning(this.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  getCardEventLogs(row) {
    let payload: any = {
      cardid: row.cardId
    };
    this.selectedCard = row.cardId
    this.spinner.getSpinner();
    this.service.getCardEventLogs({ payload: payload }).subscribe(successResponse => {
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.CardEventLogs = records;
        $('#cardEventsModel').modal('show');
      } else {
        this.CardEventLogs = [];
        $('#cardEventsModel').modal('show');
        this.spinner.hideSpinner();
        // this.toastr.warning(successResponse.records.message);
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  SelectedRecodrd(data) {
    this.currentRecordId = data.recordId;
    this.currentCardId = data.cardId;
    this.currentMobileNumber = data.mobileNumber;
  }
  onPrintInvoice() {
    let printContents = document.getElementById('section-one').innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();;
  }
  sortData(sortField: string) {
    if (this.currentSortField === sortField) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortDirection = true;
    }
    this.currentSortField = sortField;

    this.listData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return this.sortDirection ? -1 : 1;
      } else if (a[sortField] > b[sortField]) {
        return this.sortDirection ? 1 : -1;
      } else {
        return 0;
      }
    });
  }
  print() {
    let sectionOne = document.getElementById('section-one');
    let sectionTwo = document.getElementById('section-two');

    let printContents = '';

    if (sectionOne) {
      printContents += sectionOne.innerHTML;
    }

    if (sectionTwo) {
      printContents += sectionTwo.innerHTML;
    }

    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      console.error("One or both sections are missing.");
    }


  }

  fnPDF() {
    html2canvas(document.getElementById("section-two"), { allowTaint: true }).then(canvas => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) + 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height + i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save(`waafipay_${moment().format('MMM Do YYYY')}_Admin_Blocked_Cards_Report.pdf`);
      setTimeout(() => {
        window.location.reload();
      }, 1000)
      // this._location.back();
    });
  }

  downloadCSV() {
    var list = [];
    this.listDataTemp.forEach(function (el) {
      let datalist = {
        cardId: el.cardId,      // `institutionName` matches the institution name
        walletReference: el.walletReference,          // `month` is the formatted month from the date
        embossingName: el.embossingName,  // Total cards by month
        maskedPan: el.maskedPan,      // Active cards
        trackingNumber: el.trackingNumber,        // Inactive cards (blocked)
        block_reason: el.block_reason,              // Printed cards
        cardStatus: el.cardStatus,    // Print requests
      }
      list.push(datalist);
    })

    var options = {
      title: 'Admin Blocked Cards Report',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: true,
      useBom: false,
      headers: [
        "id",
        "Mobile",
        "CardHolder Name",
        "Masked PAN",
        "Tracking Number	",
        "Block Reason	",
        "Is Deleted	",
        "Physical Card Status",
      ]
    };
    new Angular2Csv(list, `waafipay_Admin_Blocked_Cards_Report${moment().format('MMM Do YYYY')}_report`, options);
  }
}
