<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Merchant Approvals</h4>
                    </div>
                   
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>MerchantId</th>
                                <th>Merchant Name</th>
                                <th>Contact Number</th>
                                <th>Emails</th>
                                <th>Contact Name</th>
                                <th>Created Date</th>
                                <th>Created By</th>
                                <th>User Status</th>
                                <th>Approve</th>
                                <!-- <th>Reject</th> -->
                            </tr>
                        </thead>
                        <tbody  *ngIf="listBody">
                            <tr *ngFor="let x of listData | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td class="py-1">{{x.referenceId}}</td>
                                <td>{{ x.merchantName}}</td>
                                <td>{{ x.telephone}}</td>
                                <td>{{ x.email}}</td>
                                <td>{{ x.userTitle}}</td>
                                <td>{{ x.createdDate | date:'dd-MMM-yy - HH:mm:ss'}}</td>
                                <td>{{ x.createdByUserId }}</td>
                                <td>
                                    <button type="button" class="btn btn-outline-warning btn-sm" *ngIf="x.lifeCycleStatusId == 1">{{ x.lifeCycleStatus}}</button>
                                    <button type="button" class="btn btn-outline-secondary btn-sm" *ngIf="x.lifeCycleStatusId == 2">{{ x.lifeCycleStatus}}</button>
                                    <button type="button" class="btn btn-outline-success btn-sm" *ngIf="x.lifeCycleStatusId == 3">{{ x.lifeCycleStatus}}</button>
                                    <button type="button" class="btn btn-outline-danger btn-sm" *ngIf="x.lifeCycleStatusId == 4">{{ x.lifeCycleStatus}}</button>
                                </td>
                                <td (click)="passApprovelData(x)">
                                    <a type="button" class="btn btn-success btn-sm"><i class="ti-check text-white"></i></a>
                                </td>
                                <!-- <td (click)="passApprovelData(x)">
                                    <a type="button" class="btn btn-success btn-sm"><i class="ti-close text-white"></i></a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

