<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <h4 class="card-title">Merchant Biller List</h4>
                    </div>
                    <div class="col-md-3">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back</button>
                        </h4>
                        <h4 class="card-title mr-2" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal" (click)="resetForm()"
                                data-target="#permissionModel">Add Biller</button>
                        </h4>
                    </div>
                    <!-- <div class="col-md-2 m-0">
                    </div> -->
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>BillarId</th>
                                <th>BillerName</th>
                                <th>BillerCode</th>
                                <th>Biller Category</th>
                                <th>Payment Processor</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listBody">
                            <tr
                                *ngFor="let x of listData | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td>
                                    {{ x.billerId}}
                                </td>
                                <td>{{ x.billerName}}</td>
                                <td>{{ x.billerCode}}</td>
                                <td>{{ x.billerCategory}}</td>
                                <td>{{ x.paymentProcessor}}</td>
                                <td (click)="getBillierInfo(x)">
                                    <button type="button" class="btn btn-outline-danger btn-sm">Remove</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal" id="permissionModel" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

    <div class="modal-dialog modal-md-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-lg-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Add biller to merchant</h4>
                            <form class="forms-sample" [formGroup]="perForm" novalidate
                                (ngSubmit)="addMerchantBiller()">
                                <div class="card-subtitle">Biller to merchant</div>
                                <div
                                    style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                                </div>

                                <div class="form-group">
                                    <label for="exampleInputEmail1">Billers</label>
                                    <select class="form-control" formControlName="billerId"
                                        [ngClass]="{ 'is-invalid': submitted && perForm.controls.billerId.errors }">
                                        <option *ngFor="let p of selectedData" [ngValue]="p.billerId">{{p.billerName}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && perForm.controls.billerId.errors" class="text-danger">
                                        <div *ngIf="perForm.controls.billerId.errors.required">Billers
                                            required</div>
                                    </div>
                                </div>


                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>