import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';



@Component({
  selector: 'app-get-settlement-request-info',
  templateUrl: './get-settlement-request-info.component.html',
  styleUrls: ['./get-settlement-request-info.component.css'],
  animations: [
    fade
  ]
})
export class GetSettlementRequestInfoComponent implements OnInit {

  updatesForm: FormGroup;
  sub: Subscription;
  queryReqId: number;
  ReqId: any;
  submitted = false;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.updateForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryReqId = +params['settlementRequestId'];
        if (Number.isNaN(this.queryReqId)) {
          this.ReqId = "";
        } else {
          this.ReqId = this.queryReqId;
          // console.log("HHHH => ", this.ReqId)
          this.getSettlementRequestInfo(this.ReqId)
        }
      });
      
  }

  backToService(){
    this._location.back();
  }


  updateForm() {
    this.updatesForm = this.fb.group({
      settlementRequestId: ['', Validators.required],
      description: ['', Validators.required],
      statusId: ['', Validators.required],
      amount: ['', Validators.required],
      accountId: ['', Validators.required],
      requestedByUserId: ['', Validators.required],
      requestedForUserId: ['', Validators.required],
      createdDate: ['', Validators.required]
    });
    
  }

  ngOnInit(): void {
  }

  getSettlementRequestInfo(settlementRequestId){
    let payload: any = {
      settlementRequestId: settlementRequestId,
    };
    this.spinner.getSpinner();
    this.service.getSettlementRequestInfo({payload: payload}).subscribe(successResponse => {
      this.spinner.hideSpinner();
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      // console.log("UUUU", settlementId)
      this.updatesForm = this.fb.group({
        settlementRequestId: [data.settlementRequestId, Validators.required],
        description: [data.description, Validators.required],
        statusId: [data.statusId, Validators.required],
        amount: [data.amount, Validators.required],
        accountId: [data.accountId, Validators.required],
        requestedByUserId: [data.requestedByUserId, Validators.required],
        requestedForUserId: [data.requestedForUserId, Validators.required],
        createdDate: [data.createdDate, Validators.required]
      });
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
     console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  OnUpdateSettlementInfo(){

  }

}
