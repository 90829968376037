<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <!-- <h4 class="card-title">{{ Title }}</h4> -->
          </div>

          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <div id="section-one">
          <h4 class="text-center">Topup Withdrawal Summary Report</h4>
          <h4 class="text-center">{{ institutionName }}</h4>
          <p class="text-center">
            {{ startDate | date : "MMMM dd,yyyy" }} -
            {{ endDate | date : "MMMM dd,yyyy" }}
          </p>
        </div>

        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-2">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                (click)="print()"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="fnPDF()"
                type="button"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="button"
                (click)="exportAsXLSX()"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>

        <!-- <div class="table-responsive" id="section-one">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Institution Name</th>
                <th>Payment Method</th>
                <th>Currency</th>
                <th>Count</th>
                <th>Topups</th>
                <th>Withdrawals</th>
                <th>Reversals</th>
                <th>Fee/Charges</th>
                <th>Net Amount</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.transactionDate }}</td>
                <td>{{ x.institutionName }}</td>
                <td>{{ x.captureMode }}</td>
                <td>{{ x.currencyCode }}</td>
                <td>{{ x.transationCount }}</td>
                <td>{{ x.topups }}</td>
                <td>{{ x.withdrawals }}</td>
                <td>{{ x.withdrawReversals }}</td>
                <td>{{ x.chargesFees }}</td>
                <td>{{ x.netAmount }}</td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">{{ message }}</div>
          </div>
        </div> -->

        <!-- <div class="table-responsive">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Settlement Date</th>
                <th>Settlement Amount</th>
                <th>Settlement Currency</th>
                <th>Transaction Amount</th>
                <th>Transaction Currency</th>
                <th>Narrative</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>

              <ng-container *ngFor="let month of getMonths()">

                <tr>
                  <td colspan="9">
                    <strong>{{ month }}</strong>
                  </td>
                </tr>


                <tr *ngFor="let tran of transactions[month]">
                  <td>{{ tran.id }}</td>
                  <td>{{ tran.settlement_date }}</td>
                  <td>{{ tran.settlement_date }}</td>
                  <td>{{ tran.settlement_amount }}</td>
                  <td>{{ tran.settlement_currency }}</td>
                  <td>{{ tran.tran_amount }}</td>
                  <td>{{ tran.tran_currency }}</td>
                  <td>{{ tran.tran_narrative }}</td>
                  <td>{{ tran.tran_description }}</td>
                </tr>


                <tr style="background-color: #add8e6">
                  <td colspan="3">
                    <strong>Sub Total {{ month }}:</strong>
                  </td>
                  <td>
                    <strong>{{
                      getTotalForMonth(month).totalSettlement
                    }}</strong>
                  </td>
                  <td></td>
                  <td>
                    <strong>{{
                      getTotalForMonth(month).totalTransaction
                    }}</strong>
                  </td>
                  <td></td>
                  <td colspan="2"></td>
                </tr>
              </ng-container>


              <tr style="background-color: #90ee90">
                <td colspan="3"><strong>Grand Total:</strong></td>
                <td>
                  <strong>{{ getGrandTotal().grandSettlement }}</strong>
                </td>
                <td></td>
                <td>
                  <strong>{{ getGrandTotal().grandTransaction }}</strong>
                </td>
                <td></td>
                <td colspan="2"></td>
              </tr>
            </tbody>
          </table>
        </div> -->

        <div class="table-responsive" id="section-two">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Institution Name</th>
                <th>Payment Method</th>
                <th>Currency</th>
                <th>Count</th>
                <th>Topups</th>
                <th>Withdrawals</th>
                <th>Reversals</th>
                <th>Fee/Charges</th>
                <th>Net Amount</th>
              </tr>
            </thead>
            <tbody>
              <!-- Loop through each month -->
              <ng-container *ngFor="let month of getMonths()">
                <!-- Optional: Display the month title -->
                <tr>
                  <td colspan="10">
                    <strong>{{ month }}</strong>
                  </td>
                </tr>

                <!-- Loop through each transaction for the current month -->
                <!-- *ngFor=" let x of filterTransactionsByTerm(transactions[month]
                || []) " -->

                <tr
                  *ngFor="
                    let x of filterTransactionsByTerm(
                      getFilteredTransactions()[month] || []
                    )
                  "
                >
                  <td>{{ x?.transactionDate }}</td>
                  <td>{{ x?.institutionName }}</td>
                  <td>{{ x?.captureMode }}</td>
                  <td>{{ x?.currencyCode }}</td>
                  <td>{{ x?.transationCount }}</td>
                  <td>{{ formatTransactionAmount(x?.topups) }}</td>
                  <td>{{ formatTransactionAmount(x?.withdrawals) }}</td>
                  <td>{{ formatTransactionAmount(x?.withdrawReversals) }}</td>
                  <td>{{ formatTransactionAmount(x?.chargesFees) }}</td>
                  <td>{{ formatTransactionAmount(x?.netAmount) }}</td>
                </tr>

                <!-- Display the totals for the month -->
                <tr style="background-color: #add8e6">
                  <td colspan="4">
                    <strong>Sub Total {{ month }}:</strong>
                  </td>

                  <td>
                    <strong>{{
                      getTotalForMonth(month)?.totalTransationCount
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{ getTotalForMonth(month)?.totalTopups }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      getTotalForMonth(month)?.totalWithdrawals
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      getTotalForMonth(month)?.totalWithdrawReversals
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      getTotalForMonth(month)?.totalChargesFees
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      getTotalForMonth(month)?.totalNetAmount
                    }}</strong>
                  </td>
                </tr>
              </ng-container>

              <!-- Grand total row at the end -->
              <tr
                style="background-color: #90ee90"
                *ngIf="isObjectNotEmpty(transactions)"
              >
                <td colspan="4"><strong>Grand Total:</strong></td>
                <td>
                  <strong>{{
                    getGrandTotal()?.grandTotalTransationCount
                  }}</strong>
                </td>
                <td>
                  <strong>{{ getGrandTotal()?.grandTotalTopups }}</strong>
                </td>
                <td>
                  <strong>{{ getGrandTotal()?.grandTotalWithdrawals }}</strong>
                </td>
                <td>
                  <strong>{{
                    getGrandTotal()?.grandTotalWithdrawReversals
                  }}</strong>
                </td>
                <td>
                  <strong>{{ getGrandTotal()?.grandTotalChargesFees }}</strong>
                </td>
                <td>
                  <strong>{{ getGrandTotal()?.grandTotalNetAmount }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Pagination Controls -->
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>

          <div *ngIf="!transactions || isObjectNotEmpty(transactions)">
            <div style="text-align: center; padding: 24px">{{ message }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
