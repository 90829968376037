import { trigger, transition, state, animate, style } from "@angular/animations";

export let fade = trigger('fade', [
    // state('void', style({opacity: 0})),

    // transition(':enter, :leave', [
    //     animate(1000)
    // ])

    state('in', style({ transform: 'translateX(0)' })),
    transition('void => *', [
      style({ transform: 'translateX(-5%)' }),
      animate(100)
    ]),
    transition('* => void', [
      animate(100, style({ transform: 'translateX(5%)' }))
    ])
])