<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">Distributor Commission Summary Report</h4>
          </div>
          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>
        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-3">
            <input
              type="text"
              class="form-control form-control-sm"
              [(ngModel)]="term"
              placeholder="Search here..."
              style="float: left"
            />
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                (click)="onPrintInvoice('section-one')"
                class="btn btn-primary btn-sm ml-4"
                style="width: 80px; height: 45px"
              >
                <i class="material-icons">&#xe555;</i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <select
                style="border-radius: 5px 5px 5px 5px !important"
                class="form-control form-control-sm"
                [(ngModel)]="selected"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
          </div>
        </div>
        <div class="table-responsive" id="section-one">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>TotalMerchants</th>
                <th>MerchantDistributorId</th>
                <th>MerchantDistributor</th>
                <th>PayerBankDistributorId</th>
                <th>PayerBankDistributor</th>
                <th>TotalTranAmount</th>
                <th>TotalCharges</th>
                <th>DistCommission</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td>{{ x.totalMerchants }}</td>
                <td>{{ x.merchantDistributorId }}</td>
                <td>{{ x.merchantDistributor }}</td>
                <td>{{ x.payerBankDistributorId }}</td>
                <td>{{ x.payerBankDistributor }}</td>
                <td>{{ x.totalTranAmount | number : "1.2-2" }}</td>
                <td>{{ x.totalCharges | number : "1.2-2" }}</td>
                <td>{{ x.distCommission | number : "1.2-2" }}</td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%">
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
