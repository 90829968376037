<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-11">
                        <h4 class="card-title">All Merchant Settings</h4>
                    </div>
                    <div class="col-md-1">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="resetForm()">ADD</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Feature Id</th>
                                <th>Feature Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td class="py-1">{{x.featureId}}</td>
                                <td>{{x.featureName}}</td>
                                <td (click)="viewMerchantConfig(x)" data-toggle="modal" data-target="#viewModel">
                                    <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <!-- <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-md" id="sysConfigModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-sm-12" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Create Merchant Settings</h4>
                            <form class="forms-sample" [formGroup]="sysForm" (ngSubmit)="OnCreateMerchantConfig()">
                                <div class="card-subtitle">Merchant Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Feature Name</label>
                                    <input type="text" class="form-control" id="featureName" formControlName="featureName" placeholder="Feature Name"
                                    [ngClass]="{ 'is-invalid': submitted && sysForm.controls.featureName.errors }">
                                    <div *ngIf="submitted && sysForm.controls.featureName.errors" class="text-danger">
                                        <div *ngIf="sysForm.controls.featureName.errors.required">Feature Name is required</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade bd-example-modal-md" id="viewModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">View Merchant Settings</h4>
                            <form class="forms-sample" [formGroup]="sysForm" (ngSubmit)="updateMerchantConfig()">
                                <div class="card-subtitle">Merchant Settings</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Feature Id</label>
                                    <input type="text" class="form-control"  formControlName="featureId" placeholder="Feature Id"
                                    [ngClass]="{ 'is-invalid': submitted && sysForm.controls.featureId.errors }" readonly>
                                    <div *ngIf="submitted && sysForm.controls.featureId.errors" class="text-danger">
                                        <div *ngIf="sysForm.controls.featureId.errors.required">Feature Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Feature Name</label>
                                    <input type="text" class="form-control" formControlName="featureName" placeholder="Feature Name"
                                    [ngClass]="{ 'is-invalid': submitted && sysForm.controls.featureName.errors }">
                                    <div *ngIf="submitted && sysForm.controls.featureName.errors" class="text-danger">
                                        <div *ngIf="sysForm.controls.featureName.errors.required">Feature Name is required</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Update</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>