import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { pagination } from 'src/app/service/service/pagination';
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-sysconfig',
  templateUrl: './sysconfig.component.html',
  styleUrls: ['./sysconfig.component.css'],
  animations: [
    fade
  ]
})
export class SysconfigComponent implements OnInit {
  dataList;
  sysForm: FormGroup;
  submitted = false;
  modal;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
  }

  createForm() {
    this.sysForm = this.fb.group({
      paramId: [''],
      paramName: ['', Validators.required ],
      paramCode: ['', Validators.required ],
      paramValue: ['', Validators.required ],
    });
  }

  ngOnInit(): void {
    this.getSystemConfig();
  }

  getSystemConfig(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getSysconfigList({payload: payload}).subscribe(successResponse => {

      // console.log("haaa", successResponse)
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  resetForm(){
    this.sysForm.reset();
  }

  OnCreateSystemConfig() {
    this.submitted = true;
 
    if (this.sysForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      createdByUserId: currentUser.userId,
      paramCode: this.sysForm.value.paramCode,
      paramValue: this.sysForm.value.paramValue,
      paramName: this.sysForm.value.paramName
    };
    this.spinner.getSpinner();
    this.service.createSystemConfig(payload).subscribe(successResponse => {

      if(successResponse.id){
        $('#sysConfigModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success("Seccessfully: You have created Id",successResponse.id)
        this.getSystemConfig()
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  viewSystemConfig(x){
    // console.log("payload", x)
    this.sysForm = this.fb.group({
      paramId: [x.paramId, Validators.required ],
      paramName: [x.paramName, Validators.required ],
      paramCode: [x.paramCode, Validators.required ],
      paramValue: [x.paramValue, Validators.required ],
    });
  }

  passSystemCreation() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userId = currentUser.userId;
    this.router.navigate(['dashboard/view/createSystemInfo'], { queryParams: { userId } });
  }
  passSystemUpdated(x) {
    let paramId = x.paramId;
    this.router.navigate(['dashboard/view/updateSystemInfo'], { queryParams: { paramId } });
  }

 
}
