import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-view-process',
  templateUrl: './view-process.component.html',
  styleUrls: ['./view-process.component.css'],
  animations: [
    fade
  ]
})
export class ViewProcessComponent implements OnInit {
  updatesForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  querypaymentProcessorId: number;
  paymentProcessorId: any;
  dataRecords: any;
  sub: Subscription;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.updateForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.querypaymentProcessorId = +params['paymentProcessorId'];
        if (Number.isNaN(this.querypaymentProcessorId)) {
          this.paymentProcessorId = "";
        } else {
          this.paymentProcessorId = this.querypaymentProcessorId;
          this.viewBankInfo(this.paymentProcessorId)
        }
      });
  }
  backToService(){
    this._location.back();
  }
  updateForm() {
    this.updatesForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      paymentProcessorId: ['', Validators.required],
      address2: ['', Validators.required],
      paymentProcessorTypeDesc: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  viewBankInfo(paymentProcessorId){
    let payload: any = {
      paymentProcessorId: paymentProcessorId
    };
    this.spinner.getSpinner();
    this.service.getPaymentProcessorInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.updatesForm = this.fb.group({
          firstName: [data.firstName, Validators.required],
          middleName: [data.middleName, Validators.required],
          lastName: [data.lastName, Validators.required],
          email: [data.email, [Validators.required, Validators.email]],
          telephone: [data.telephone, Validators.required],
          address: [data.address, Validators.required],
          userTitle: [data.userTitle, Validators.required],
          paymentProcessorId: [data.paymentProcessorId, Validators.required],
          address2: [data.address2, Validators.required],
          paymentProcessorTypeDesc: [data.paymentProcessorTypeDesc, Validators.required],
        });
        // console.log("kkkk", data)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  OnUpdatePaymentProcessorInfo(){
    
    
  }

}
