<div @fade class="row">
    <div class="col grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Create Cashier Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backToCashier()">Back To Cashier</button>
                        </h4>
                    </div>
                </div>
                <form class="forms-sample" [formGroup]="serviceForm" novalidate (ngSubmit)="OnCreateCashierUser()">
                    <div class="card-subtitle">Merchant Info</div>
                    <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Merchant Id</label>
                                <input type="text" class="form-control" formControlName="merchantId"
                                    placeholder="Merchant Id" readonly
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantId.errors }">
                                <div *ngIf="submitted && serviceForm.controls.merchantId.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.merchantId.errors.required">Merchant Idis required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="isActive">Merchant UID</label>
                                <input type="text" class="form-control" formControlName="merchantUid"
                                    placeholder="Merchant UID" readonly
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantUid.errors }">
                                <div *ngIf="submitted && serviceForm.controls.merchantUid.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.merchantUid.errors.required">Merchant UID is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-subtitle">Cashier Info</div>
                    <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="isActive">First Name</label>
                                <input type="text" class="form-control" formControlName="firstName"
                                    placeholder="First Name"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.firstName.errors }">
                                <div *ngIf="submitted && serviceForm.controls.firstName.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.firstName.errors.required">First Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Middle Name</label>
                                <input type="text" class="form-control" formControlName="middleName"
                                    placeholder="Middle Name"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.middleName.errors }">
                                <div *ngIf="submitted && serviceForm.controls.middleName.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.middleName.errors.required">Middle Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Last Name</label>
                                <input type="text" class="form-control" formControlName="lastName"
                                    placeholder="Last Name"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.lastName.errors }">
                                <div *ngIf="submitted && serviceForm.controls.lastName.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.lastName.errors.required">Last Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input type="email" class="form-control" formControlName="email" placeholder="Email"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.email.errors }">
                                <div *ngIf="submitted && serviceForm.controls.email.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.email.errors.required">Email is required</div>
                                    <div *ngIf="serviceForm.controls.email.errors.email">It`s Not An Email Plz Check
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Contact Number</label>
                                <input type="number" class="form-control" formControlName="telephone"
                                    placeholder="Contact Number"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.telephone.errors }">
                                <div *ngIf="submitted && serviceForm.controls.telephone.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.telephone.errors.required">Contact Number is
                                        required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Website</label>
                                <input type="text" class="form-control" formControlName="website" placeholder="Website"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.website.errors }">
                                <div *ngIf="submitted && serviceForm.controls.website.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.website.errors.required">Website is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Address Line One</label>
                                <input type="text" class="form-control" formControlName="address"
                                    placeholder="Address One"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address.errors }">
                                <div *ngIf="submitted && serviceForm.controls.address.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.address.errors.required">Address One is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Address Line Two</label>
                                <input type="text" class="form-control" formControlName="address2"
                                    placeholder="Address Two"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.address2.errors }">
                                <div *ngIf="submitted && serviceForm.controls.address2.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.address2.errors.required">Address Two is required
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputEmail1">City</label>
                                <select class="form-control" formControlName="city"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.city.errors }">
                                    <option *ngFor="let p of selectCitiesData" [ngValue]="p.cityName">
                                        {{p.cityName}}</option>
                                </select>
                                <div *ngIf="submitted && serviceForm.controls.city.errors"
                                    class="text-danger">
                                    <div *ngIf="serviceForm.controls.city.errors.required">City is
                                        required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Country</label>
                                <select class="form-control" formControlName="country"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.country.errors }">
                                    <option *ngFor="let p of selectCountryData" [ngValue]="p.countryName">
                                        {{p.countryName}}</option>
                                </select>
                                <div *ngIf="submitted && serviceForm.controls.country.errors"
                                    class="text-danger">
                                    <div *ngIf="serviceForm.controls.country.errors.required">Country is
                                        required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-subtitle">Access Profile</div>
                    <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Cashier Type</label>
                                <select class="form-control" formControlName="selectData"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectData.errors }">
                                    <option *ngFor="let p of dataLists" [ngValue]="p.cashierTypeId">{{p.description}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && serviceForm.controls.selectData.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.selectData.errors.required">Cashier Type is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Cashier Name</label>
                                <input type="text" class="form-control" formControlName="userTitle"
                                    placeholder="user Title"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.userTitle.errors }">
                                <div *ngIf="submitted && serviceForm.controls.userTitle.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.userTitle.errors.required">User Title is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Login Id</label>
                                <input type="text" class="form-control" formControlName="loginId" placeholder="Login ID"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.loginId.errors }">
                                <div *ngIf="submitted && serviceForm.controls.loginId.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.loginId.errors.required">Login Id is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="isActive">Password</label>
                                <input type="password" class="form-control" formControlName="password"
                                    placeholder="Password"
                                    [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.password.errors }">
                                <div *ngIf="submitted && serviceForm.controls.password.errors" class="text-danger">
                                    <div *ngIf="serviceForm.controls.password.errors.required">Password is required
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                            <!-- <button class="btn btn-default">Centered button</button> -->
                        </div>
                    </div>
                    <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button> -->
                </form>
            </div>
        </div>
    </div>
</div>