import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SessionStorageService } from '../session-storage/session-storage.service';
// import * as CryptoJS from 'crypto-js';
import * as CryptoJS from '../../../../node_modules/crypto-js';
import * as crypto from 'crypto-js';
// import * as CryptoJS from '../../../../node_modules/crypto-js';
import { environment } from 'src/assets/config/environment';
// import * as CryptoJS from '../../../node_modules/crypto-js';
@Injectable({
  providedIn: 'root'
})
export class ExpressRequestService {

   // domain = location.origin;
  // uri = 'http://192.168.40.70:1000/v1';
  key = environment.cryptoKey;

  constructor(private http: HttpClient) { }

  

  // login(payload){
  //   return this.http.post('http://localhost:7010/v1/login', payload).pipe(map((res:any) => res)
  //   )
  // }

  encryptData(data): String {
    try {
      let enData = CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
      return enData;
    } catch (e) {
      // console.log('Exception Raised || ', e);
      // console.log('Exception Stack || ', e.stack);
    }
  }

  decoded(data: any): void {
    var decoded = CryptoJS.enc.Hex.parse(data).toString(CryptoJS.enc.Base64);
    return decoded;
  }

  decryptData(data): void {
    try {
      var bytes = CryptoJS.AES.decrypt(data.toString(), this.key);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (e) {
      // console.log('Exception Raised || ', e);
      // console.log('Exception Stack || ', e.stack);
    }
  }

 

  decrypt(text) {
    let password = 'd6F3Efeq';
    console.log("Text====> ", text.trim())
    const result = crypto.AES.decrypt(text.trim(), password);
    return result.toString(crypto.enc.Utf8);
  }

}
