import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
declare var $: any;


@Component({
  selector: 'app-assign-user-roles',
  templateUrl: './assign-user-roles.component.html',
  styleUrls: ['./assign-user-roles.component.css']
})
export class AssignUserRolesComponent implements OnInit {
  queryUserId: number;
  userId: any;
  dataRecords: any;
  sub: Subscription;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  roleId;
  selectBankData;
  selectServiceChargeModeData;
  // dataSet = [{}]
  selected = [];
  userRole = "";
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryUserId = +params['userId'];
        if (Number.isNaN(this.queryUserId)) {
          this.userId = "";
        } else {
          this.userId = this.queryUserId;
          this.viewUserRoleConfig(this.userId);
        }
      });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      userId: ['', Validators.required],
      dataRecords: new FormArray([])
    });
  }

  checked(item) {
    if (this.selected.indexOf(item) != -1) {
      return true;
    }
  }

  viewUserRoleConfig(userId) {
    let roleIds = [];
    let payload: any = {
      userId: userId
    };
    this.spinner.getSpinner();
    this.service.getUserInfoWithWebroles({ payload: payload }).subscribe(successResponse => {

      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      console.log("haaa", data)
      if (data.userWebRoles != null) {
        this.spinner.hideSpinner();
        //roleIds = JSON.parse(data.userWebRoles);
        JSON.parse(data.userWebRoles).forEach((element, index) => {
          roleIds.push(element);
        });
      } else {
        roleIds = [];
      }
      this.getUserRoleList(roleIds, data.userId);
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  backtowebrole() {
    // this.router.navigate(['dashboard/listUserRole']);
    this._location.back();
  }

  ngOnInit(): void {
  }

  onChange(deviceValue, item) {
    console.log(deviceValue.target.checked);
    if (deviceValue.target.checked) {
      this.userRole += item + ',';
      console.log('KKKKK', deviceValue.target.checked, this.userRole);
    } else {
      this.userRole = ""
    }
  }

  // onChange(deviceValue, item) {
  //   const isChecked = deviceValue.target.checked;

  //   // Convert userRole to an array for easier manipulation
  //   let rolesArray = this.userRole ? this.userRole.split(',').filter(Boolean) : [];

  //   if (isChecked) {
  //     // Add item to the userRole array if it's not already there
  //     if (!rolesArray.includes(item)) {
  //       rolesArray.push(item);
  //     }
  //   } else {
  //     // Remove item from the userRole array if it exists
  //     rolesArray = rolesArray.filter(role => role !== item);
  //   }

  //   // Join the array back to a string and assign it to this.userRole
  //   this.userRole = rolesArray.join(',');

  //   console.log('Checked:', isChecked, 'UserRole:', this.userRole);
  // }


  getUserRoleList(userRoles, userId) {
    let payload: any = {};
    this.spinner.hideSpinner();
    this.service.getWebRoleList({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      this.dataRecords = records;
      // console.log("HAAA", records);
      userRoles.forEach(element => {
        this.selected.push(element);
      });
      this.serviceForm = this.fb.group({
        userId: [userId, Validators.required],
      });
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  assignUserInfo() {

    var rolesArrayString;
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }

    // const selectedOrderIds = this.serviceForm.value.dataRecords
    //   .map((v, i) => v ? this.dataRecords[i].roleId : null)
    //   .filter(v => v !== null);
    // rolesArrayString = this.convertToStringArray(selectedOrderIds);
    // console.log("III=> ", this.serviceForm.value.dataRecords)

    let payload: any = {
      userId: this.serviceForm.value.userId,
      userRoles: this.convertToStringArray(this.userRole)
    };
    console.log("Paylaod=>", payload)
    this.spinner.getSpinner();
    this.service.updateAssignUserRoles(payload).subscribe(successResponse => {
      this.spinner.hideSpinner();
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      if (data) {
        this.toastr.success('Added Role Permission', "Successfully");
        setTimeout(() => {
          this._location.back()
        }, 1000);
      } else {
        this.toastr.success('Something went wrong!', "Error");
      }
      console.log("DADDDDD=>", data)
      // this.serviceForm.reset(this.serviceForm.value);
      // setTimeout(() => {
      //   this.router.navigate(['dashboard/listUserInfo']);
      // }, 3000);
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });

  }

  convertToStringArray(obj) {
    var arr = "[";
    arr += obj + "]";
    return arr;
  }


}
