import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-cashier-create-info',
  templateUrl: './cashier-create-info.component.html',
  styleUrls: ['./cashier-create-info.component.css'],
  animations: [
    fade
  ]
})
export class CashierCreateInfoComponent implements OnInit {
  _USER_TYPE_ID_CASHIER = 40;
  dataList;
  dataLists;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  merchantUid;
  merchantName;
  queryMerchantUid;
  merchantId
  queryMerchantId;
  selectCountryData;
  selectMessage;
  selectCitiesData;
  selectedMessage;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getCitiesList();
    this.getCountryList();
    this.getCashierTypeList();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.queryMerchantId = params['merchantId'];
        this.queryMerchantUid = params['merchantUid'];
        this.merchantId = this.queryMerchantId;
        this.merchantUid = this.queryMerchantUid;
        this.getMerchantInfo(this.merchantId);
      } else {
        _location.back();
      }
    });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      merchantId: [''],
      merchantUid: [''],
      selectData: ['', Validators.required],
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  getCountryList(){
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCountryList({payload: payload}).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      // console.log("Country=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCountryData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
     console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getCitiesList(){
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getCitiesList({payload: payload}).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data.records);
      console.log("Cities=> ", records)
      if (data.success) {
        this.spinner.hideSpinner();
        this.selectCitiesData = records;
      } else {
        this.spinner.hideSpinner();
        this.selectedMessage = records.message;
      };
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
     console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  getCashierTypeList(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getCashierTypeList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataLists = records;       
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  ngOnInit(): void {
  }

  getMerchantInfo(merchantId){
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId,
    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        // this.merchantUid = data.merchantUid;
        // this.merchantName = data.firstName + " " + data.middleName + " " + data.lastName;
        this.serviceForm = this.fb.group({
          merchantId: [data.merchantId],
          merchantUid: [data.merchantUid],
          selectData: ['', Validators.required],
          firstName: ['', Validators.required],
          middleName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          telephone: ['', Validators.required],
          address: ['', Validators.required],
          userTitle: ['', Validators.required],
          address2: ['', Validators.required],
          website: ['', Validators.required],
          city: ['', Validators.required],
          country: ['', Validators.required],
          loginId: ['', Validators.required],
          password: ['', [Validators.required, Validators.minLength(6)]]
        });
        
        // console.log("kkkk", data)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  backToCashier() {
    this._location.back();
  }

  OnCreateCashierUser() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }

    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      createdByUserId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_CASHIER,
      merchantId: this.serviceForm.value.merchantId,
      firstName: this.serviceForm.value.firstName,
      middleName: this.serviceForm.value.middleName,
      lastName: this.serviceForm.value.lastName,
      telephone: this.serviceForm.value.telephone,
      address: this.serviceForm.value.address,
      email: this.serviceForm.value.email,
      userTitle: this.serviceForm.value.userTitle,
      address2: this.serviceForm.value.address2,
      website: this.serviceForm.value.website,
      city: this.serviceForm.value.city,
      country: this.serviceForm.value.country,
      loginId: this.serviceForm.value.loginId,
      loginPwd: this.serviceForm.value.password,
      cashierTypeId: this.serviceForm.value.selectData
    };
    this.spinner.getSpinner();
    this.service.createCashier(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if(successResponse){
        this.spinner.hideSpinner();
        this.toastr.success(`You have created`, 'Seccessfully')
        this._location.back();
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse.error.message.error.message));
        this.toastr.error(failureResponse.error.message.error.message)
    });
  }

}
