<div @fade class="row">
    <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Reset Merchant Password</h4>
                <p class="card-description">
                    Reset Password
                </p>
                <form class="forms-sample" [formGroup]="getForm" novalidate (ngSubmit)="passTransaction()">

                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <input type="text" class="form-control-sm" formControlName="merchantId"
                                    placeholder="Merchant Id"
                                    [ngClass]="{ 'is-invalid': getSubmitted && getForm.controls.merchantId.errors }">

                                <div *ngIf="getSubmitted && getForm.controls.merchantId.errors" class="text-danger">
                                    <div *ngIf="getForm.controls.merchantId.errors.required">Merchant Id is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>