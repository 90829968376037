import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import {Router} from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service"
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-createrout',
  templateUrl: './createrout.component.html',
  styleUrls: ['./createrout.component.css'],
  animations: [
    fade
  ]
})
export class CreateroutComponent implements OnInit {
  dataList;
  serviceForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  modal;
  selectBankData;
  selectServiceChargeModeData;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private router: Router,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      routePrefix: ['', Validators.required ],
      isActive: ['', Validators.required ],
      selectedBank: ['', Validators.required ],
      currencyId: ['', Validators.required ],
    
    });
  }

  ngOnInit(): void {
    this.getBankList();
  }

  getBankList(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getBankList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectBankData =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  backtowebrole(){
    this.router.navigate(['dashboard/listRouteConfig']);
  }

  OnCreateRoutesConfig() {
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }
    let payload: any = {
      routePrefix: this.serviceForm.value.routePrefix,
      currencyId: this.serviceForm.value.currencyId, 
      bankId: this.serviceForm.value.selectedBank,
      isActive: this.serviceForm.value.isActive
    };
    this.spinner.getSpinner();
    this.service.createRouteConfig(payload).subscribe(successResponse => {

      if(successResponse.id){
        this.spinner.hideSpinner();
        this.toastr.success(`You have created Id ${successResponse.id}`, 'Seccessfully')
        this.router.navigate(['dashboard/listRouteConfig']);
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

}
