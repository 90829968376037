import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-device-terminals-list',
  templateUrl: './device-terminals-list.component.html',
  styleUrls: ['./device-terminals-list.component.css'],
  animations: [
    fade
  ]
})
export class DeviceTerminalsListComponent implements OnInit {

  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  submitted = false;
  modal;
  distributorList;
  getForm: FormGroup;
  listBody: boolean = false;
  getSubmitted = false;
  showDistributor = false;
  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private spinner: SpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.showDistributor = false
    // this.getDeviceTerminalList();
    this.getFrom();

  }
  ngOnInit(): void {
    this.getUserLists();
  }
  backToService() {
    this.showDistributor = false;
    this.dataList = []
  }
  getUserLists() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distributorList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getFrom() {
    this.getForm = this.fb.group({
      selectData: ['', Validators.required],
    });
  }
  getDeviceTerminalList(distributorId) {
    // var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: distributorId,
      // distributorId: Number(currentUser.referenceId),
    };
    this.spinner.getSpinner();
    this.service.getDevicePos({ payload: payload }).subscribe(successResponse => {

      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        this.showDistributor = true
        // console.log("kkkk", records)
      } else {
        this.showDistributor = true
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  handleSubmit() {
    let distributorId = this.getForm.value.selectData
    this.getDeviceTerminalList(distributorId);

  }
}
