<div>
    <div class="container-scroller">
        <ngx-spinner></ngx-spinner>
        <app-header></app-header>


        <div class="container-fluid page-body-wrapper">
           
              <!-- partial:partials/_settings-panel.html -->
                <div class="theme-setting-wrapper">
                    <div id="settings-trigger"><i class="ti-comments"></i></div>
                    <div id="theme-settings" class="settings-panel">
                    <i class="settings-close ti-close"></i>
                    <p class="settings-heading">Message</p>
                    <!-- <div class="sidebar-bg-options selected" id="sidebar-light-theme"><div class="img-ss rounded-circle bg-light border mr-3"></div>Light</div>
                    <div class="sidebar-bg-options" id="sidebar-dark-theme"><div class="img-ss rounded-circle bg-dark border mr-3"></div>Dark</div> -->
                    
                    </div>
                </div>

             <!-- partial -->
             <app-sidebar></app-sidebar>
            <div class="main-panel">
                <div class="content-wrapper">
                    <router-outlet></router-outlet>
                </div>
                <!-- <div class="content-wrapper"> -->
                <app-footer></app-footer>
                <!-- </div> -->
            </div>
        </div>


    </div>
</div>