import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-update-service',
  templateUrl: './update-service.component.html',
  styleUrls: ['./update-service.component.css'],
  animations: [
    fade
  ]
})
export class UpdateServiceComponent implements OnInit {

  queryServiceId: number;
  serviceId: any;
  dataRecords: any;
  sub: Subscription;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  selectServiceChargeModeData;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getServiceChargeMode();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryServiceId = +params['serviceId'];
        if (Number.isNaN(this.queryServiceId)) {
          this.serviceId = "";
        } else {
          this.serviceId = this.queryServiceId;
          this.viewServiceSetting(this.serviceId)
        }
      });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      serviceCode: ['', Validators.required],
      serviceDesc: ['', Validators.required],
      isActive: ['', Validators.required],
      serviceChargeModeId: ['', Validators.required],
      isTransactional: ['', Validators.required],
      serviceProfileDesc: ['', Validators.required],
      minInTxAmount: ['', Validators.required],
      maxInTxAmount: ['', Validators.required],
      minOutTxAmount: ['', Validators.required],
      maxOutTxAmount: ['', Validators.required],
      dailyInTxLimit: ['', Validators.required],
      dailyOutTxLimit: ['', Validators.required],
      monthlyInTxLimit: ['', Validators.required],
      monthlyOutTxLimit: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  getServiceChargeMode(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getListServiceChargeModes({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectServiceChargeModeData =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  viewServiceSetting(serviceId){

    let payload: any = {
      serviceId: serviceId,
    };
    this.spinner.getSpinner();
    this.service.getServiceInfo({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        // var records = JSON.parse(data);
        this.serviceForm = this.fb.group({
          serviceId: [data.serviceId, Validators.required],
          serviceProfileDesc: [data.serviceProfileDesc, Validators.required],
          selectServiceChargeModeValue: [data.serviceChargeModeId, Validators.required],
          serviceCode: [data.serviceCode, Validators.required],
          serviceDesc: [data.serviceDesc, Validators.required],
          serviceChargeModeId: [data.serviceChargeModeId, Validators.required],
          isActive: [data.isActive, Validators.required],
          isTransactional: [data.isTransactional, Validators.required],
          minInTxAmount: [data.minInTxAmount, Validators.required],
          minOutTxAmount: [data.minOutTxAmount, Validators.required],
          maxInTxAmount: [data.maxInTxAmount, Validators.required],
          maxOutTxAmount: [data.maxOutTxAmount, Validators.required],
          dailyInTxLimit: [data.dailyInTxLimit, Validators.required],
          dailyOutTxLimit: [data.dailyOutTxLimit, Validators.required],
          monthlyInTxLimit: [data.monthlyInTxLimit, Validators.required],
          monthlyOutTxLimit: [data.monthlyOutTxLimit, Validators.required]
        });
        // console.log("kkkk", data)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
    
    
  }

  backToService(){
    this._location.back();
  }

  updateServiceSetting(){
    this.submitted = true; 
    if (this.serviceForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      doneByUserId: currentUser.userId,
      serviceChargeModeId: this.serviceForm.value.serviceChargeModeId,
      serviceId: this.serviceForm.value.serviceId,
      serviceCode: this.serviceForm.value.serviceCode,
      serviceDesc: this.serviceForm.value.serviceDesc,
      isActive: this.serviceForm.value.isActive,
      isTransactional: this.serviceForm.value.isTransactional,
      minInTxAmount: this.serviceForm.value.minInTxAmount,
      minOutTxAmount: this.serviceForm.value.minOutTxAmount,
      maxInTxAmount: this.serviceForm.value.maxInTxAmount,
      maxOutTxAmount: this.serviceForm.value.maxOutTxAmount,
      dailyInTxLimit: this.serviceForm.value.dailyInTxLimit,
      dailyOutTxLimit: this.serviceForm.value.dailyOutTxLimit,
      monthlyInTxLimit: this.serviceForm.value.monthlyInTxLimit,
      monthlyOutTxLimit: this.serviceForm.value.monthlyOutTxLimit,
      serviceProfileDesc: this.serviceForm.value.serviceProfileDesc
    };
    this.spinner.getSpinner();
    this.service.updateServiceConfig(payload).subscribe(successResponse => {

      if(successResponse){
        this.spinner.hideSpinner();
        // $('#viewModel').modal('hide');
        this.toastr.success("Seccessfully: You have Updatetd",successResponse.id)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

}
