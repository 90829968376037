import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-merchant-monthly-summary-by-dist',
  templateUrl: './merchant-monthly-summary-by-dist.component.html',
  styleUrls: ['./merchant-monthly-summary-by-dist.component.css'],
  animations: [
    fade
  ]
})
export class MerchantMonthlySummaryByDistComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedValue;
  selectGroupData;
  selectedData;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getUserLists();
    this.getCurrencyList();
    this.fnGetTranTypeGroupList();
  }

  getFrom() {
    this.getForm = this.fb.group({
      selectData: ['', Validators.required],
      selectedValue: ['', Validators.required],
      selectedGroupValue: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  // onOptionsSelected({
  //   id,
  //   name
  // }): void {
  //   console.log(id, name, this.getForm.value.selectData.id);
  // }
  getUserLists() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  getCurrencyList() {
    let payload: any = {};

    this.service.getCurrencyList({ payload: payload }).subscribe((res) => {
      try {
        if (!res) {
          console.log("Errer")
        } else {
          let resObj = JSON.stringify(res.records)
          let record = JSON.parse(resObj)
          this.selectData = record;
          console.log("Data==>", record)
        }
      } catch (error) {
        console.log("Errer", error)
      }
    })
  }

  fnGetTranTypeGroupList() {
    let payload: any = {};

    this.service.getTranTypeGroupList({ payload: payload }).subscribe((res) => {
      try {
        if (!res) {
          console.log("Errer")
        } else {
          let resObj = JSON.stringify(res.records)
          let record = JSON.parse(resObj)
          let data = JSON.parse(record)
          this.selectGroupData = data;
          console.log("Data==>", data)
        }
      } catch (error) {
        console.log("Errer", error)
      }
    })
  }

  passMerchantMonthlySummaryByDistReports() {
    this.submitted = true;
    if (this.getForm.invalid) return;
    let distributorId = this.getForm.value.selectData.id;
    let distName = this.getForm.value.selectData.name;
    let tranTypeGroup = this.getForm.value.selectedGroupValue;
    let currencyId = this.getForm.value.selectedValue;
    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate;
    this.router.navigate(["dashboard/view/getDistributorsMerchantSummarybyMonth"], { queryParams: { distributorId, tranTypeGroup, distName, startDate, endDate, currencyId } });

  }
}
