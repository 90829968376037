<div @fade class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Blocked Cards Report</h4>
        <p class="card-description">Blocked Cards Report</p>
        <form
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="handleSubmit()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="selectDistributor">Select Distributor </label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="0" disabled selected>
                    All
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="cashierId">Mobile Number (Optional)</label>
                <input
                  formControlName="mobileNumber"
                  type="text"
                  maxlength="13"
                  minlength="0"
                  class="form-control-sm form-control-sm-sm"
                  placeholder="Mobile Number"
                  style="float: left"
                />
              </div>
            </div>

            <!-- <div class="col-md-2">
              <div class="form-group">
                <label for="startDate"> Start Date </label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="startDate"
                  placeholder="Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.startDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.startDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.startDate.errors?.required">
                    Date is required
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label for="endDate"> End Date </label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="endDate"
                  placeholder="Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.endDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.endDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.endDate.errors?.required">
                    Date is required
                  </div>
                </div>
              </div>
            </div> -->

            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
