import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import * as moment from 'moment';


@Component({
  selector: 'app-create-rate',
  templateUrl: './create-rate.component.html',
  styleUrls: ['./create-rate.component.css'],
  animations: [
    fade
  ]
})
export class CreateRateComponent implements OnInit {
  queryUserId: number;
  userId: any;
  dataRecords: any;
  sub: Subscription;
  dataList;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  serviceProfileId;
  // selectServiceChargeModeData;
  selectSenderRateTypeData;
  selectRecieverRateTypeData;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.getServiceRateList();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryUserId = +params['userId'];
        this.serviceProfileId = +params['serviceProfileId'];
        if (Number.isNaN(this.queryUserId)) {
          this.userId = "";
        } else {
          this.userId = this.queryUserId;
        }
      });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      senderRate: ['', Validators.required],
      recieverRate: ['', Validators.required],
      receiverRate: ['', Validators.required],
      minTxAmount: ['', Validators.required],
      maxTxAmount: ['', Validators.required],
      selectSenderRateTypeValue: ['', Validators.required],
      selectRecieverRateTypeValue: ['', Validators.required]
    });
  }


  ngOnInit(): void {
  }

  getServiceRateList(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getRateTypeList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectSenderRateTypeData =records
        this.selectRecieverRateTypeData =records;
        console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  OnCreateServiceRate() {
    this.submitted = true;
    console.log("hellow")
    // if (this.serviceForm.invalid) return;
    let payload: any = {
      createdByUserId: this.userId,
      serviceProfileId: this.serviceProfileId,
      senderRate: this.serviceForm.value.senderRate,
      senderRateTypeId: this.serviceForm.value.selectSenderRateTypeValue,
      recieverRate: this.serviceForm.value.recieverRate,
      receiverRateTypeId: this.serviceForm.value.selectRecieverRateTypeValue,
      minTxAmount: this.serviceForm.value.minTxAmount,
      maxTxAmount: this.serviceForm.value.maxTxAmount,
      effectiveDate: moment().format(),
      expiryDate: moment().add(1, 'years').calendar()
    };
    console.log("Payload", payload)
    this.spinner.getSpinner();
    this.service.createServiceRate(payload).subscribe(successResponse => {

      if (successResponse.id) {
        $('#createModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success(`You have created Id ${successResponse.id}`,'Seccessfully')
        this.router.navigate(['dashboard/listServiceConfig'])
      } else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  backToService() {
    this._location.back()
  }

}
