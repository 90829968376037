import { Component, OnInit } from '@angular/core';
import { ExpressRequestService } from '../../service/express-request/express-request.service'
import { SessionStorageService } from '../../service/session-storage/session-storage.service';
import { Router } from '@angular/router'
import { ServiceService } from 'src/app/service/service/service.service';
import * as moment from 'moment';
import { environment } from 'src/assets/config/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  userTitle: any;
  appName;
  dataList;
  NotList;
  message;

  nav = environment.nav;
  tab = environment.tab;

  constructor(
    private router: Router,
    private service: ServiceService,
    private expressRequestService: ExpressRequestService,
    private _sessionStorage: SessionStorageService
  ) {
    this.loadSettlementByDash();
  }

  ngOnInit() {
    var currentUser = this._sessionStorage.getSessionData();
    this.userTitle = currentUser.userTitle;
    
  }

  get myNav() : boolean {
    if (this.nav === 'waafipay-nav.png') {
      return true;
    } 
    return false;
  }

  


  loadSettlementByDash() {
    let payload: any = {
      limit: 10
    }

    this.service.getSettlementRequestListByDash({ payload: payload }).subscribe((res) => {
      if (res.success) {
        const response = JSON.stringify(res.records)
        const records = JSON.parse(response);
        const data = JSON.parse(records);
        this.dataList = data;
        var arr = [];

        data.every(function (el, index) {
          if (index < 4) {
            let list = { userTitle: el.userTitle, createdDate: moment(el.createdDate, "YYYYMMDD").fromNow() }
            arr.push(list);
            return true;
          }
        });


        this.NotList = arr;
        let days = moment(arr[0].createdDate, "YYYYMMDD").fromNow();
        // console.log("Data11 ==>", days)
      } else {
        this.message = res.records.message 
        console.log("Error comming out", res.records.message);
      }
    })
  }

  onClickLogout() {
    // this.expressRequestService.functionLogout();
    this._sessionStorage.removeSessionData();
    this.router.navigate(['/login'])
      .then(() => {
        window.location.reload();
      })
    console.log("Log Out Successful");
  }

}
