import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-pos-merchant-usage-summary-report',
  templateUrl: './pos-merchant-usage-summary-report.component.html',
  styleUrls: ['./pos-merchant-usage-summary-report.component.css'],
  animations: [
    fade
  ]
})
export class PosMerchantUsageSummaryReportComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedValue;
  selectedData;
  

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getUserLists();
    this.getCurrencyList();

  }

  getFrom(){
    this.getForm = this.fb.group({
      distributorId: ['', Validators.required],
      selectedValue: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  getUserLists(){
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getCurrencyList(){
    let currentUser = this._sessionStorageService.getSessionData();

    let payload: any = {
      // distrabutorId: currentUser.referenceId
    };

    this.service.getCurrencyList({ payload: payload })   .subscribe(successResponse => {
        var responseObj = JSON.stringify(successResponse);
        var data = JSON.parse(responseObj);
        if (data.success) {
          var records = data.records;
          this.selectedData = records[0].currencyCode;
          // // this.getdistributorTransactionsBydate(records[0].AccountId);
          this.selectData = records;
          console.log("If", records);
        } else {
          console.log("Else", data);
        }
      },(failureResponse: any) => {
        if(failureResponse.status === 401){
          window.location.href = 'login';
        }else{
          // this.failureMessage = true;
          console.log("onFailure:" + JSON.stringify(failureResponse));
        }
      });
  }


  passMerchantUsageSummaryReports(){
    this.submitted = true;
    if(this.getForm.invalid){
      return;
    }

    let distributorId = this.getForm.value.distributorId;
    let currency = this.getForm.value.selectedValue;
    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate;
    this.router.navigate(["dashboard/view/getPosMerchantsUsageSummaryReport"], { queryParams: { distributorId, currency, startDate, endDate }});
  }

}
