<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title" *ngIf="showDistributor == false">Distributor</h4>
        <p class="card-description" *ngIf="showDistributor == false">
          Distributor
        </p>

        <form
          *ngIf="showDistributor == false"
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="handleSubmit()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <select
                  class="form-control-sm"
                  name="selectData"
                  formControlName="selectData"
                  [ngClass]="{
                    'is-invalid':
                      getSubmitted && getForm.controls.selectData.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distributorList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="getSubmitted && getForm.controls.selectData.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.selectData.errors?.required">
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>

        <div class="row" *ngIf="showDistributor">
          <div class="col-md-10">
            <h4 class="card-title">Device Terminals</h4>
          </div>

          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <div class="row" style="margin-bottom: 0px" *ngIf="showDistributor">
          <div class="col-md-3">
            <input
              type="text"
              class="form-control-sm"
              [(ngModel)]="term"
              placeholder="Search here..."
              style="float: left"
            />
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>
        <div class="table-responsive" *ngIf="showDistributor">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>SN</th>
                <th>Cashier</th>
                <th>Partner UID</th>
                <th>Model</th>
                <th>Registered</th>
                <th>last Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let x of dataList
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.deviceSn }} ({{ x.deviceId }})</td>
                <td>{{ x.cashier }}</td>
                <td>{{ x.cashierIdentifier }}</td>
                <td>{{ x.model }}</td>

                <td>{{ x.registered | date : "dd-MMM-yy - HH:mm:ss" }}</td>
                <td>{{ x.lastUpdated | date : "dd-MMM-yy - HH:mm:ss" }}</td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
