import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { environment } from 'src/assets/config/environment';

@Component({
  selector: 'app-get-invoice-info',
  templateUrl: './get-invoice-info.component.html',
  styleUrls: ['./get-invoice-info.component.css'],
  animations: [
    fade
  ]
})
export class GetInvoiceInfoComponent implements OnInit {

  
  serviceForm: FormGroup;
  sub: Subscription;
  queryinvoiceNo: number;
  invoiceNo: any;
  submitted = false;
  statusDesc;
  accountId;
  balance;
  settlementAmount;
  settlementAccount;
  settlementModeDesc;
  settlementCycleId;
  bankName;
  currency;
  data;
  invoiceIds;
  paymentTo;
  dueDate;
  paymentTerms;
  description;
  invoiceAmount;
  createdDate;
  logoInvoice = environment.tab;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.queryinvoiceNo = params['invoiceNo'];
        this.invoiceNo = this.queryinvoiceNo;
        this.getInvoiceInfo(this.invoiceNo);
      }else{ 
        _location.back();
      }
    });

  }

 

  createForm() {
    this.serviceForm = this.fb.group({
      merchantName: ['', Validators.required],
      subscriptionStatus: ['', Validators.required],
      charges: ['', Validators.required],
      subscriptionTypeDesc: ['', Validators.required],
      subscriptionExpDate: ['', Validators.required]
    });
  }

  OnUpdateSettlementInfo() {
    this.submitted = true;
    if (this.serviceForm.invalid) return;
  }

  backToService() {
    this._location.back();
  }

  getInvoiceInfo(invoiceNo) {
    let payload: any = {
      invoiceNo: invoiceNo,
    };
    this.spinner.getSpinner();
    this.service.getSettlementInvoiceInfo({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      console.log("$$$$$$=>", data)
      function days(days) {
        var date = new Date();
        date.setDate(date.getDate() + days);
        return date;
      }

      this.spinner.hideSpinner();

      this.paymentTo = data.paymentTo;
      this.dueDate = days(Number(data.paymentTerms));
      this.paymentTerms = data.paymentTerms;
      this.description = data.description;
      this.invoiceAmount = data.invoiceAmount;
      this.createdDate = data.createdDate;
      // let merchantName = data.firstName + "  " + data.middleName + "  " + data.lastName

      // this.serviceForm = this.fb.group({
      //   merchantName: [merchantName, Validators.required],
      //   subscriptionStatus: [data.profileStatusDesc, Validators.required],
      //   charges: [data.merchantId, Validators.required],
      //   subscriptionTypeDesc: [data.subscriptionTypeDesc, Validators.required],
      //   subscriptionExpDate: [data.subscriptionExpDate, Validators.required]
      // });


    },
      (failureResponse: string) => {
        this.spinner.hideSpinner();
         console.log("onFailure:" + JSON.stringify(failureResponse));
      });
  }

  onPrintInvoice(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     window.location.reload();;
  }

  ngOnInit(): void {
    console.log("tttt=> ", this.logoInvoice)
  }
}
