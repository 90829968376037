import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-settlement-table',
  templateUrl: './settlement-table.component.html',
  styleUrls: ['./settlement-table.component.css'],
  animations: [
    fade
  ]
})
export class SettlementTableComponent implements OnInit {
  StatusId;
  queryStatusId:any;
  sub:any;
  isTrue: boolean = false;
  message;
  listBody: boolean;
  listData;
  createForm: FormGroup;
  submitted = false;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createdForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryStatusId = +params['statusId'];
        if (Number.isNaN(this.queryStatusId)) {
          this.StatusId = "";
        } else {
          this.StatusId = this.queryStatusId;
          // console.log("HHHH => ", this.StatusId)
          this.getSettlementList(this.StatusId)
        }
      });
  }

  createdForm(){
    this.createForm = this.fb.group({
      settlementId: ['', Validators.required]
    });
  }


  ngOnInit(): void {
  }

  backToService(){
    this._location.back();
  }

  getSettlementList(statusId?){
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      statusId: statusId

    };
    if (statusId != null) {
      payload.statusId = statusId;
      payload.recordCount = 0;
    } else {
      payload.statusId = 0;
      payload.recordCount = 0;
    };
    console.log(payload)

    this.isTrue = true;
    this.spinner.getSpinner();
    this.service.getSettlementList({payload: payload}).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if(successResponse.records){
        if(!successResponse.records.message){
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData =records;
          // console.log("kkkk", records)
        }else{
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }
        
      }else {
        this.listBody = false;
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passSettlementInfo(x){
    let reqId = x.settlementId;
    this.router.navigate(["dashboard/view/getSettlementInfo"], { queryParams: { reqId } })
  }

  passProcessSettlementInfo(x){
    this.createForm = this.fb.group({
      settlementId: [x.settlementId, Validators.required]
    });
  }
  OnProcessSettlement(){
    var currentUser = this._sessionStorageService.getSessionData();
    let expressRoute = "/adminProcessMerchantSettlementRequest";
    let payload: any = {
      settlementRequestId: this.createForm.value.settlementId,
      doneByUserId: currentUser.userId,
    };
    this.spinner.getSpinner();
    this.service.adminProcessMerchantSettlementRequest(payload).subscribe(successResponse => {

      if(successResponse.id){
        $('#createModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success(`You have processed Id ${successResponse.id}`,'Seccessfully')
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong", 'Error')
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

}
