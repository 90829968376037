<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">Manage Configuration</h4>
          </div>
          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="ShowModelCreateBranch()"
              >
                Add Configuration
              </button>
            </h4>
          </div>
        </div>
        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-3">
            <input
              type="text"
              class="form-control-sm"
              [(ngModel)]="term"
              placeholder="Search here..."
              style="float: left"
            />
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>
        <div class="table-responsive">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Value</th>
                <th>Created</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let x of dataList
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td class="py-1">{{ x.id }}</td>
                <td>
                  {{ x.paramName }}
                </td>
                <td>{{ x.paramValue }}</td>

                {{
                  x.createdAt | date : "dd-MMM-yy - HH:mm:ss"
                }}

                <td (click)="viewUpdateBranch(x)">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#createBranchModel"
                    class="btn btn-outline-primary btn-sm"
                  >
                    Edit
                  </button>
                </td>
                <!-- <td (click)="passUpdateView(x)">
                  <button type="button" class="btn btn-outline-primary btn-sm">
                    View Detail
                  </button>
                </td> -->
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- model -->
<div
  class="modal fade bd-example-modal-md"
  id="createBranchModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Create Configuration</h4>
              <form
                class="forms-sample"
                [formGroup]="updatesForm"
                (ngSubmit)="hanldeSubmit()"
              >
                <!-- <div class="card-subtitle">Select Station</div> -->
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="col-md" *ngIf="isUpdate == false">
                  <div class="form-group">
                    <label for="isActive">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="name"
                      placeholder="Configuration Name"
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.name.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && updatesForm.controls.name.errors"
                      class="text-danger"
                    >
                      <div *ngIf="updatesForm.controls.name.errors.required">
                        Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="isActive">Value</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="paramValue"
                      placeholder="paramValue "
                      [ngClass]="{
                        'is-invalid':
                          submitted && updatesForm.controls.paramValue.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && updatesForm.controls.paramValue.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="updatesForm.controls.paramValue.errors.required"
                      >
                        Value is required
                      </div>
                    </div>
                  </div>
                </div>

                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button
                  class="btn btn-light"
                  type="button"
                  (click)="modelOnCancel()"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
