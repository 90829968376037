<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">View Payment Processor System Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to
                                Payment</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate
                            (ngSubmit)="OnUpdatePaymentProcessorSystem()">
                            <div class="card-subtitle">Payment Processor</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">System Name</label>
                                        <input type="text" class="form-control" formControlName="systemName"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.systemName.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.systemName.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.systemName.errors.required">System Name is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="systemUsername">System Username</label>
                                        <input type="text" class="form-control" formControlName="systemUsername"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.systemUsername.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.systemUsername.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.systemUsername.errors.required">System
                                                Username is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="myIp">My Ip</label>
                                        <input type="text" class="form-control" formControlName="myIp"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.myIp.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.myIp.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.myIp.errors.required">My Ip is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="isActive">Is Active</label>
                                        <select formControlName="isActive" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.isActive.errors }">
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.isActive.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.isActive.errors.required">Is Active
                                            is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="systemPassword">System Password</label>
                                        <input type="password" class="form-control" formControlName="systemPassword"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.systemPassword.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.systemPassword.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.systemPassword.errors.required">System Password
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>My Partner Id</label>
                                        <input type="text" class="form-control" formControlName="myPartnerId" onCopy="return false"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.myPartnerId.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.myPartnerId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.myPartnerId.errors.required">My Partner Id
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="description">Description</label>
                                        <input type="text" class="form-control" formControlName="description"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.description.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.description.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.description.errors.required">Description
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="isActive">System Secret</label>
                                        <select formControlName="isActive" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.systemSecret.errors }">
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.systemSecret.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.systemSecret.errors.required">System Secret
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="protocolId">Protocol Id</label>
                                        <select formControlName="protocolId" class="form-control" 
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.protocolId.errors }">
                                            <option value="">--- Select Option ---</option>
                                            <option value="1">Http</option>
                                        </select>

                                        <div *ngIf="submitted && serviceForm.controls.protocolId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.protocolId.errors.required">Protocol Id
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="systemUrl">System Url</label>
                                        <input type="text" class="form-control" formControlName="systemUrl" onCopy="return false"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.systemUrl.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.systemUrl.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.systemUrl.errors.required">System Url
                                                is
                                                required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Update</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                            <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button>
                            <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>