import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-create-distributor-account',
  templateUrl: './create-distributor-account.component.html',
  styleUrls: ['./create-distributor-account.component.css'],
  animations: [
    fade
  ]
})
export class CreateDistributorAccountComponent implements OnInit {

  queryCreatedByUserId: number;
  createdByUserId: any;
  sub: Subscription;
  queryUserId: number;
  userId: any;
  serviceForm: FormGroup;
  submitted = false;
  selectAccountTypesData;
  selectCurrencyData;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryUserId = +params['userId'];
        this.queryCreatedByUserId = +params['createdByUserId'];
        if (Number.isNaN(this.queryUserId)) {
          this.userId = "";
          this.createdByUserId = "";
        } else {
          this.userId = this.queryUserId;
          this.createdByUserId = this.queryCreatedByUserId;
          // console.log("HHHH => ", this.userId)
        }
      });
      this.createForm();
      this.getUserAccountTypes();
      this.getCurrencyList();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      userId: [this.userId, Validators.required],
      accountNo: ['', Validators.required],
      accountTypeId: ['', Validators.required],
      currencyId: ['', Validators.required],     
    });
  }

  backToService(){
    this._location.back();
  }

  ngOnInit(): void {
  }

  getUserAccountTypes(){
    let payload:any = {}
    this.spinner.getSpinner()
    this.service.getUserAccountTypes({payload:payload}).subscribe((response) => {
      const successResponse = response;
      // console.log("response =>", successResponse);
      if(successResponse.success){
        this.spinner.hideSpinner();
        const data = JSON.parse(successResponse.records);
        this.selectAccountTypesData = data
      }else{
        this.spinner.hideSpinner();
        this.toastr.warning('No Records Found', 'warning');
      }
      // const data = JSON.parse(successResponse);
    }, (failureResponse: string) => {
      this.toastr.error("Server seems got bad request!");
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getCurrencyList(){
    let payload:any = {}
    this.spinner.getSpinner();
    this.service.getCurrencyList({payload:payload}).subscribe((response) => {
      const successResponse = response;
      // console.log("response =>", successResponse);
      if(successResponse.success){
        this.spinner.hideSpinner();
        const data = successResponse.records;
        this.selectCurrencyData = data
      }else {
        this.spinner.hideSpinner();
        this.toastr.warning('No Records Found', 'warning');
      }
    }, (failureResponse: string) => {
      this.toastr.error("Server seems got bad request!");
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  OnCreateUserAccount(){
    this.submitted = true;
    if(this.serviceForm.invalid)return;
    
    let payload:any = {
      userId: this.serviceForm.value.userId,
      accountNo: this.serviceForm.value.accountNo,
      accountTypeId: this.serviceForm.value.accountTypeId,
      createdByUserId: this.createdByUserId,
      currencyId: this.serviceForm.value.currencyId
    }
    this.spinner.getSpinner();
    this.service.createUserAccount(payload).subscribe(response => {
      this.spinner.hideSpinner();
      if(response.id){
        this.toastr.success(`You have created a user account id ${response.id}!`, 'Successfully')
      }
      // console.log("Responce =>> ", response);
    }, (failureResponse: string) => {
      this.toastr.error("Server seems got bad request!");
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

}
