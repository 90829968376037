<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">Create Distributor User</h4>
          </div>
          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <form
              class="forms-sample"
              [formGroup]="serviceForm"
              novalidate
              (ngSubmit)="OnCreateDistributorUser()"
            >
              <div class="card-subtitle">Contact Information</div>
              <div
                style="
                  border: 1px solid #009e44;
                  margin-bottom: 10px;
                  margin-top: 10px;
                  background: #009e44;
                "
              ></div>
              <div class="row">
                <div class="col-md-2">
                  <label for="isActive">Select Parent Distributor</label>
                  <div class="form-group">
                    <select
                      class="form-control"
                      name="distributorId"
                      formControlName="distributorId"
                      [ngClass]="{
                        'is-invalid':
                          submitted && serviceForm.controls.distributorId.errors
                      }"
                    >
                      <option [selected]="true" value="null" disabled selected>
                        --Select--
                      </option>
                      <option
                        *ngFor="let c of dataList"
                        [ngValue]="c.distributorId"
                      >
                        {{ c.userTitle }}
                      </option>
                    </select>
                    <div
                      *ngIf="
                        submitted && serviceForm.controls.distributorId.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="getForm.controls.distributorId.errors?.required"
                      >
                        Select Distributor is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="isActive">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="firstName"
                      placeholder="First Name"
                      [ngClass]="{
                        'is-invalid':
                          submitted && serviceForm.controls.firstName.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && serviceForm.controls.firstName.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="serviceForm.controls.firstName.errors.required"
                      >
                        First Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="exampleInputUsername1">Middle Name</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="middleName"
                      placeholder="Middle Name"
                      [ngClass]="{
                        'is-invalid':
                          submitted && serviceForm.controls.middleName.errors
                      }"
                    />
                    <div
                      *ngIf="
                        submitted && serviceForm.controls.middleName.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="serviceForm.controls.middleName.errors.required"
                      >
                        Middle Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="lastName"
                      placeholder="Last Name"
                      [ngClass]="{
                        'is-invalid':
                          submitted && serviceForm.controls.lastName.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && serviceForm.controls.lastName.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="serviceForm.controls.lastName.errors.required"
                      >
                        Last Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      formControlName="email"
                      placeholder="Email"
                      [ngClass]="{
                        'is-invalid':
                          submitted && serviceForm.controls.email.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && serviceForm.controls.email.errors"
                      class="text-danger"
                    >
                      <div *ngIf="serviceForm.controls.email.errors.required">
                        Email is required
                      </div>
                      <div *ngIf="serviceForm.controls.email.errors.email">
                        It`s Not An Email Plz Check
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-subtitle">Access Profile</div>
              <div
                style="
                  border: 1px solid #009e44;
                  margin-bottom: 10px;
                  background: #009e44;
                "
              ></div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exampleInputUsername1">User Title</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="userTitle"
                      placeholder="user Title"
                      [ngClass]="{
                        'is-invalid':
                          submitted && serviceForm.controls.userTitle.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && serviceForm.controls.userTitle.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="serviceForm.controls.userTitle.errors.required"
                      >
                        userTitle is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Username</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="loginId"
                      placeholder="Username"
                      [ngClass]="{
                        'is-invalid':
                          submitted && serviceForm.controls.loginId.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && serviceForm.controls.loginId.errors"
                      class="text-danger"
                    >
                      <div *ngIf="serviceForm.controls.loginId.errors.required">
                        Login Id is required
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="isActive">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      formControlName="password"
                      placeholder="Password"
                      [ngClass]="{
                        'is-invalid':
                          submitted && serviceForm.controls.password.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && serviceForm.controls.password.errors"
                      class="text-danger"
                    >
                      <div
                        *ngIf="serviceForm.controls.password.errors.required"
                      >
                        Password is required
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Select Roles</label>
                    <select
                      class="form-control"
                      formControlName="roleId"
                      [ngClass]="{
                        'is-invalid':
                          submitted && serviceForm.controls.roleId.errors
                      }"
                    >
                      <option
                        *ngFor="let p of WebRoleList"
                        [ngValue]="p.roleId"
                      >
                        {{ p.roleName }}
                      </option>
                    </select>
                    <div
                      *ngIf="submitted && serviceForm.controls.roleId.errors"
                      class="text-danger"
                    >
                      <div *ngIf="serviceForm.controls.roleId.errors.required">
                        Role is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col text-center">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style="width: 30%"
                  >
                    Submit
                  </button>
                  <!-- <button class="btn btn-default">Centered button</button> -->
                </div>
              </div>
              <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button>
                            <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
