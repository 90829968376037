import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-merchant-activity',
  templateUrl: './merchant-activity.component.html',
  styleUrls: ['./merchant-activity.component.css'],
  animations: [
    fade
  ]
})
export class MerchantActivityComponent implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedValue;
  currencyData;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.fnGetStatusList();
    this.getCurrencyList();
  }

  getFrom() {
    this.getForm = this.fb.group({
      merchantId: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      selectedCurrency: ['', Validators.required],
      payerId: [''],
      selectedValue: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  fnGetStatusList(sourceId?) {
    let payload: any = {};
    // this.getSpinner();
    this.service.getTranStatusList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // this.spinner.hide();
      // console.log("data :=> " + successResponse);

      var newrecord: any = {};
      newrecord.tranStatusId = "0";
      newrecord.tranStatusDesc = "All";
      records.push(newrecord);
      var selectedRecord: any = {};
      if (sourceId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.tranStatusId == sourceId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectData = records;
        this.selectedValue = selectedRecord.tranStatusId;
      } else {
        this.selectData = records;
        this.selectedValue = newrecord.tranStatusId;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };

  passMerchantActivityReports() {
    this.submitted = true;
    if (this.getForm.invalid) {
      return;
    }
    let merchantId = this.getForm.value.merchantId;
    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate;
    let payerId = this.getForm.value.payerId;
    let statusId = this.getForm.value.selectedValue;
    let currency = this.getForm.value.selectedCurrency;
    this.router.navigate(["dashboard/view/getMerchantActivityReport"], { queryParams: { merchantId, startDate, endDate, payerId, statusId, currency } });
  }
  getCurrencyList() {
    let payload: any = {};

    this.service.getCurrencyList({ payload: payload }).subscribe((res) => {
      try {
        if (!res) {
          console.log("Errer")
        } else {
          let resObj = JSON.stringify(res.records)
          let record = JSON.parse(resObj)
          this.currencyData = record;
          console.log("Data==>", record)
        }
      } catch (error) {
        console.log("Errer", error)
      }
    })
  }
}
