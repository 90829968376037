import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';
import * as moment from 'moment';


@Component({
  selector: 'app-get-settlement-info',
  templateUrl: './get-settlement-info.component.html',
  styleUrls: ['./get-settlement-info.component.css'],
  animations: [
    fade
  ]
})
export class GetSettlementInfoComponent implements OnInit {

  updatesForm: FormGroup;
  sub: Subscription;
  queryReqId: number;
  ReqId: any;
  submitted = false;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.updateForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryReqId = +params['reqId'];
        if (Number.isNaN(this.queryReqId)) {
          this.ReqId = "";
        } else {
          this.ReqId = this.queryReqId;
          // console.log("HHHH => ", this.ReqId)
          this.getSettlementInfo(this.ReqId)
        }
      });
      
  }

  backToService(){
    this._location.back();
  }


  updateForm() {
    this.updatesForm = this.fb.group({
      merchantId: ['', Validators.required],
      settlementId: ['', Validators.required],
      settlementTime: ['', Validators.required],
      amount: ['', Validators.required],
      settlementStatusId: ['', Validators.required],
      settlementStatus: ['', Validators.required],
      refTranHeadId: ['', Validators.required],
      settlementTranId: ['', Validators.required],
      issuerSettlementId: ['', Validators.required],
      description: ['', Validators.required],
      isCredit: ['', Validators.required],
      accountId: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  getSettlementInfo(settlementId){
    let payload: any = {
      settlementId: settlementId,
    };
    this.spinner.getSpinner();
    this.service.getSettlemenInfo({payload: payload}).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      this.spinner.hideSpinner();
      var data = JSON.parse(responseObj);
      // console.log("UUUU", settlementId)
      this.updatesForm = this.fb.group({
        merchantId: [data.merchantId, Validators.required],
        settlementId: [data.settlementId, Validators.required],
        settlementTime: [moment(data.settlementTime).format('MMM DD YYYY, HH:mm:ss'), Validators.required],
        amount: [data.amount, Validators.required],
        settlementStatusId: [data.settlementStatusId, Validators.required],
        settlementStatus: [data.settlementStatus, Validators.required],
        refTranHeadId: [data.refTranHeadId, Validators.required],
        settlementTranId: [data.settlementTranId, Validators.required],
        issuerSettlementId: [data.issuerSettlementId, Validators.required],
        description: [data.description, Validators.required],
        isCredit: [data.isCredit, Validators.required],
        accountId: [data.accountId, Validators.required]
      });
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
     console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  OnUpdateSettlementInfo(){

  }

}
