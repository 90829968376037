import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service"
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-process-invoice',
  templateUrl: './process-invoice.component.html',
  styleUrls: ['./process-invoice.component.css'],
  animations: [
    fade
  ]
})
export class ProcessInvoiceComponent implements OnInit {
  dataList;
  serviceForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  modal;
  selectDistributorData;
  selectServiceChargeModeData;

  sub: Subscription;
  queryInvoiceNo: number;
  InvoiceNo: any;
  list;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private spinner: SpinnerService,
    private _location: Location,
    private route: ActivatedRoute,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
  }

  createForm() {
    this.serviceForm = this.fb.group({
      invoiceNo: ['', Validators.required ],
      invoiceAmount: ['', Validators.required ],
      settledDate: ['', Validators.required ],
      vendorRef: ['', Validators.required ],  
      remarks: ['', Validators.required ],
    });
  }


  ngOnInit(): void {
  }



  backtowebrole(){
    this._location.back();
  }

  OnProcessInvoice() {
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      invoiceNo: this.serviceForm.value.invoiceNo,
      settledAmount: this.serviceForm.value.invoiceAmount,
      settledDate: this.serviceForm.value.settledDate,
      vendorRef: this.serviceForm.value.vendorRef,
      doneByUserId: currentUser.userId,
      remarks: this.serviceForm.value.remarks

    };
    // console.log("Payload 888", payload)
    this.spinner.getSpinner();
    this.service.adminReconsileInvoice(payload).subscribe(successResponse => {
      // console.log("successResponse", successResponse)
      if(successResponse.transactionId){
        this.spinner.hideSpinner();
        this.toastr.success(`You have processed Id ${successResponse.transactionId}`, 'Seccessfully')
        this.router.navigate(['dashboard/invoicelist'])
        .then(() => {
          window.location.reload();
        });
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        this.toastr.error(failureResponse.statusText, 'Error')
        // console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }


}
