import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'src/app/service/service/spinner.service'
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';

@Component({
  selector: 'app-billing-mg',
  templateUrl: './billing-mg.component.html',
  styleUrls: ['./billing-mg.component.css'],
  animations: [
    fade
  ]
})
export class BillingMgComponent implements OnInit {


  getForm: FormGroup;
  submitted = false;
  _POS_PURCHASE: any = 1;
  _EPAYMENT: any = 4;

  listData;
  listBody: boolean;
  searchButtonText;
  errorMessageList: boolean;

  statusData;
  selectedValue;

  merchantId: any;
  sub: any;
  message;
  data;

  accountId;
  isTrue = false;


  selected = pagination.selected;
  p: number = pagination.p;
  term;

  merchantDefAccount = false;
  merchInfo;


  constructor(
    private _location: Location,
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.getBillingList();
  }


  createBilling() {
    this.router.navigate(["dashboard/view/createBillerInfo"]);
  }

  getBillingList() {
    let payload: any = {}
    this.spinner.getSpinner();
    this.service.getBillingList({ payload: payload }).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hideSpinner();
        var response = JSON.parse(res.records);
        this.listBody = true;
        this.listData = response;
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.message = response.message;
      }
    })
  }

  getBillierInfo(x) {
    let billerId = x.billerId;
    this.router.navigate(["dashboard/view/getBillerInfo"], { queryParams: { billerId } });
  }

}
