import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-topup-withdrawals-summary-report.component',
  templateUrl: './topup-withdrawals-summary-report.component.html',
  styleUrls: ['./topup-withdrawals-summary-report.component.css'],
  animations: [
    fade
  ]
})
export class TopupWithdrawalsSummaryReportComponent implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectAccData;
  distdataList;
  selectedData;
  TranType;
  selected1Data;
  institutionId;
  institutionName;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.getCurrencyList();
    this.fnGetGlAccountList();
    this.getDistributorList();
    this.TranType = [
      { "id": "%", "name": "All" },
      { "id": "TopUp", "name": "TopUp" },
      { "id": "TopUpReversal", "name": "TopUpReversal" },
      { "id": "Withdraw", "name": "Withdraw" },
      { "id": "WithdrawReversal", "name": "WithdrawReversal" },
      { "id": "Charges", "name": "Charges" },
    ]
  }

  getFrom() {
    this.getForm = this.fb.group({
      selectDistributor: ['', Validators.required],
      SelectedTranType: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }
  getDistributorList() {
    let currentUser = this._sessionStorageService.getSessionData();
    let userTypeId = 0;
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    // this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        // this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distdataList = records;
        // console.log("kkkk", records)
      } else {
        // this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getCurrencyList() {
    let payload: any = {};

    this.service.getCurrencyList({ payload: payload }).subscribe((res) => {
      try {
        if (!res) {
          console.log("Errer")
        } else {
          let resObj = JSON.stringify(res.records)
          let record = JSON.parse(resObj)
          this.selectData = record;
          console.log("Data==>", record)
        }
      } catch (error) {
        console.log("Errer", error)
      }
    })
  }

  fnGetGlAccountList(sourceId?) {
    let payload: any = {};
    // this.getSpinner();
    this.service.getGlAccountList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // this.spinner.hide();
      console.log("data :=> " + data);

      var newrecord: any = {};
      newrecord.accountId = "0";
      newrecord.accountDesc = "All";
      records.push(newrecord);
      var selectedRecord: any = {};
      if (sourceId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.accountId == sourceId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectData = records;
        this.selected1Data = selectedRecord.accountId;
      } else {
        this.selectAccData = records;
        this.selected1Data = newrecord.accountId;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };
  handleSubmit() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.getForm.value.selectDistributor,
    };
    let pdays = this.getForm.value.selectedValue
    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        let institutionId = data.institutionId
        this.institutionId = data.institutionId
        this.institutionName = data.institutionName
        this.passTopupWithdrawalsSummaryReports()
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  passTopupWithdrawalsSummaryReports() {
    this.submitted = true;

    if (this.getForm.invalid) return;

    let institutionId = this.institutionId;
    let institutionName = this.institutionName;
    let tranType = this.getForm.value.SelectedTranType;
    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate;
    this.router.navigate(["dashboard/view/topupWithdrawalsSummaryReport"], { queryParams: { institutionId, institutionName, tranType, startDate, endDate } })
  }


}
