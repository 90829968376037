import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription }from 'rxjs';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-createusers',
  templateUrl: './createusers.component.html',
  styleUrls: ['./createusers.component.css'],
  animations: [
    fade
  ]
})
export class CreateusersComponent implements OnInit {

  serviceForm: FormGroup;
  queryUserId: number;
  userId: any;
  dataRecords: any;
  sub: Subscription;
  submitted: boolean = false;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
  }


  createForm() {
    this.serviceForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      loginId: ['', Validators.required],
      // password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit(): void {
  }

  backToService(){
    this.router.navigate(["dashboard/listUserInfo"]);
  }

  OnCreateAdminUser(){
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      createdByUserId: currentUser.userId,
      firstName: this.serviceForm.value.firstName,
      middleName: this.serviceForm.value.middleName,
      lastName: this.serviceForm.value.lastName,
      email: this.serviceForm.value.email,
      telephone: this.serviceForm.value.telephone,
      address: this.serviceForm.value.address,
      address2: this.serviceForm.value.address2,
      city: this.serviceForm.value.city,
      country: this.serviceForm.value.country,
      website: this.serviceForm.value.website,
      userTitle: this.serviceForm.value.userTitle,
      loginId: this.serviceForm.value.loginId,
      // loginPwd: this.serviceForm.value.password
    };
    this.spinner.getSpinner();
    this.service.createUserInfo(payload).subscribe(successResponse => {
      // console.log("haaa 22", successResponse)
      if(successResponse.success){
        this.spinner.hideSpinner();
        this.toastr.success(`You have created Id ${successResponse.records.userId}`,"Seccessfully")
        this.router.navigate(["dashboard/listUserInfo"])
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

}
