import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import {SpinnerService} from 'src/app/service/service/spinner.service'
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';

@Component({
  selector: 'app-get-invoice-list',
  templateUrl: './get-invoice-list.component.html',
  styleUrls: ['./get-invoice-list.component.css'],
  animations: [
    fade
  ]
})
export class GetInvoiceListComponent implements OnInit {
  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.fnListOfInvoices();
  }


  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }

  fnListOfInvoices() {
 
    let payload: any = {
      // merchantId: merchantId,
      // statusId: statusId,
      // payerId: payerId,
      // startDate: startDate,
      // endDate: endDate
    };
    this.spinner.getSpinner();
    this.service.getSettlementInvoiceList({payload: payload}).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.listData = records;
          // console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getViewInvoice(x){
    let invoiceNo = x.invoiceNo;
    this.router.navigate(["dashboard/view/getInvoicelist"], { queryParams: { invoiceNo }});
  }
  getProcessInvoice(x){
    let invoiceNo = x.invoiceNo;
    this.router.navigate(["dashboard/view/processInvoicelist"], { queryParams: { invoiceNo }});
  }

}
