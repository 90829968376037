import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ServiceService } from '../service/service.service';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public auth: AuthService, public router: Router, private service: ServiceService, private storage: SessionStorageService) { }
  // canActivate(): boolean {
  //   if (!this.auth.isAuthenticated()) {
  //     this.router.navigate(['login']);
  //     return false;
  //   }
  //   return true;
  // }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.auth.isAuthenticated()) {
      let roles = next.data["roles"] as Array<string>;
      if (roles) {
        var match = this.service.roleMatch(roles);
        if (match) {
          return true;
        } else {
          // this.router.navigate(['/forbidden']);
          return false;
        }
      }
      else
        return true;
    } else {
      this.storage.removeSessionData()
      this.router.navigate(['/login'])
        .then(() => {
          window.location.reload();
        })
      return false;
    }
  }

}
