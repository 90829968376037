import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";

declare var $: any;

@Component({
  selector: 'merchant-details',
  templateUrl: './merchant-details.component.html',
  styleUrls: ['./merchant-details.component.css'],
  animations: [
    fade
  ]
})
export class MerchantDetailsComponent implements OnInit {
  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_CASHIER = 40;
  _CASHIERTYPEID_POS = 1;
  _CASHIERTYPEID_STORE = 2;
  _CASHIERTYPEID_APIUSERS = 3;
  dataList;
  posTransectionsData;
  serviceForm: FormGroup;
  updateAccessForm: FormGroup;
  submitted = false;
  modal;
  merchantUid;
  merchantName;
  productName;
  merchantId;
  queryMerchantId;
  merchantIdentifier;
  groupName;
  data;
  listBody: boolean;
  message;
  isThirtParty: boolean = false;
  accessProfileId;
  selectAccess;
  posDevicesList
  listData;
  isTrue = false;
  selected = pagination.selected;
  p: number = pagination.p;
  term;
  merchInfo;
  accountId;
  cashierList;
  subscripForm: FormGroup;
  createdDate;
  btnDisabled: boolean = true;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.queryMerchantId = params['merchantId'];
        this.merchantId = this.queryMerchantId;
        this.fnGetMerchantAccountList(this.queryMerchantId);
        this.getMerchantInfo(this.queryMerchantId);
        this.getPosTransections(this.queryMerchantId);
        this.getPosDevicesByMerchantId(this.queryMerchantId);
        this.getCashierUserLists(this.queryMerchantId);
        this.getUpdateInfo({ accessProfileId: this.queryMerchantId });
      } else {
        // _location.back();
      }
    });
    this.updatedAccessForm();
  }

  updatedAccessForm() {
    this.updateAccessForm = this.fb.group({
      selectedAccess: ['', Validators.required],
    });
  }
  backToService() {
    this._location.back();
  }

  ngOnInit(): void {
    // this.getCashierUserLists();
  }

  getUpdateInfo(data) {
    this.accessProfileId = data.accessProfileId;
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getUserAccessProfileStatus({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      let responseObj = JSON.stringify(successResponse.records);
      let data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      // console.log("data :=> ", records);
      this.selectAccess = records;
      // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  getPosTransections(merchantId) {
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: currentUser.referenceId,
      pCashierId: '',
      startDate: '',
      endDate: '',
      merchantId: merchantId
      // userId: currentUser.userId
    };
    // let payload: any = {
    // };
    this.spinner.getSpinner();
    this.service.getMerchantPayments({ payload: payload }).subscribe(successResponse => {
      this.spinner.hideSpinner();
      // return console.log("successResponse", successResponse);
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        records.forEach((el: any) => {
          el.transactionAmount = `${el.currencySymbol}${el.transactionAmount == undefined ? 0 : el.transactionAmount.toFixed(2)}`
          el.transactionFeeAmount = `${el.currencySymbol}${el.transactionFeeAmount == undefined ? 0 : el.transactionFeeAmount.toFixed(2)}`
          el.cashierName = `${el.cashierName} (${el.cashierId})`
          el.merchantName = `${el.merchantName} (${el.merchantId})`
          if (el.settlementId != null && el.settlementId != undefined) {
            el.settlementId = `${el.settlementId} (${el.isSettlementProcessed == 0 ? 'False' : 'True'})`

          } else {
            el.settlementId = "None"
          }
          if (el.traceNumber == null || el.traceNumber == undefined || el.traceNumber == 0) {
            el.traceNumber = "None"
          }

          if (el.resState == "APPROVED") {
            el.status = "badge badge-success"
          } else {
            // el.resState = `${el.settlementId} (${el.isSettlementProcessed })`
            el.status = "badge badge-danger"
          }
          // let list = { merchantName: el.merchantName, createdDate: moment(el.createdDate, "YYYYMMDD").fromNow() }



        })
        this.posTransectionsData = records;

        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  fnGetMerchantAccountList(merchantId) {
    this.listBody = false;
    // const currentUser = this.sessionStorage.getSessionData();
    let payload: any = {
      merchantId: merchantId

    };
    if (merchantId != null) {
      payload.merchantId = merchantId;
      this.merchantId = merchantId;
    } else {
      payload.merchantId = this.merchantId;
    };
    // console.log("payload", payload)

    this.spinner.getSpinner();
    this.service.getMerchantAccountList({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.data = successResponse.message;
      // console.log("msg: ", data)
      if (!successResponse.records.message) {
        this.listBody = true;
      }
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var records = data;
        // console.log("All ", records.accountId)
        this.listData = records;

        this.listBody = true;
        this.isTrue = false;
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.listData = null;
        this.isTrue = true;
        this.message = data.message;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };
  onUpdateAccessProfileInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateAccessForm.invalid) {
      return;
    }

    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.updateAccessForm.value.selectedAccess
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (data.accessProfileId) {
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        this.getMerchantInfo(this.merchantId);
        this.getCashierUserLists(this.queryMerchantId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  onUpdateAccessProfileInfoMerchant() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.serviceForm.invalid) {
      return;
    }

    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.serviceForm.value.selectedAccess
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if (data.accessProfileId) {
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        this.getMerchantInfo(this.merchantId);
        this.getCashierUserLists(this.queryMerchantId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  // getMerchantInfo1(merchantId) {
  //   let currentUser = this._sessionStorageService.getSessionData();
  //   let payload: any = {
  //     // userId: currentUser.userId,
  //     merchantId: merchantId
  //   };

  //   this.service.getMerchantInfo({ payload: payload }).subscribe(successResponse => {
  //     var responseObj = JSON.stringify(successResponse.records);

  //     console.log("msg: ", responseObj)
  //     if (!successResponse.records.message) {
  //       this.data = successResponse.message;
  //       this.listBody = true;
  //       this.spinner.hideSpinner();
  //     }
  //     if (successResponse.success) {
  //       var data = JSON.parse(responseObj);
  //       this.spinner.hideSpinner();
  //       this.merchInfo = data;
  //       this.listBody = true;
  //       this.accountId = data.accountId;
  //       console.log("All ", data)
  //     } else {
  //       this.spinner.hideSpinner();
  //       this.message = data.message;
  //       this.spinner.hideSpinner();
  //       this.listBody = false;
  //     };
  //   }, (failureResponse: any) => {
  //     if (failureResponse.status === 401) {
  //       window.location.href = 'login';
  //     } else {
  //       this.spinner.hideSpinner();
  //       // this.failureMessage = true;
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }

  getMerchantInfo(merchantId) {
    let currentUser = this._sessionStorageService.getSessionData();
    this.listBody = false;
    let payload: any = {
      // userId: currentUser.userId,
      merchantId: merchantId

    };
    this.serviceForm = this.fb.group({
      userId: [currentUser.userId, Validators.required],
      userTitle: [currentUser.userTitle, Validators.required],
      selectedAccess: [currentUser.profileStatusId, Validators.required],
    });
    this.spinner.getSpinner();
    this.service.getMerchantInfobyDistributor({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.data = data;
        this.merchantUid = data.merchantUid;
        this.productName = data.productName;
        this.merchantIdentifier = data.merchantIdentifier;

        this.productName = data.productName;
        this.createdDate = data.createdDate;
        this.merchantName = data.firstName + " " + data.middleName + " " + data.lastName;
        this.accessProfileId = data.accessProfileId;
        this.groupName = data.groupName;
        // this.getCashierUserLists(data.merchantId);
        if (data.merchantTypeId == 1) {
          this.isThirtParty = true;
        } else {
          this.isThirtParty = false;
        }
        console.log("kkkk", data)
      } else {
        this.toastr.error("Something went wrong")
        this.spinner.hideSpinner();
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getPosDevicesByMerchantId(merchantId) {
    let payload: any = {
      merchantId: merchantId
    };
    this.spinner.getSpinner();
    this.service.getPosDevicesByMerchantId({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.posDevicesList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  passMerchantCashiersView() {
    this.submitted = true;

    if (this.serviceForm.invalid) {
      return;
    }
  }



  getCashierUserLists(merchantId) {

    let userTypeId = 0;
    let payload: any = {
      merchantId: merchantId

    };
    this.spinner.getSpinner();
    this.service.getMerchantCashiers({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.cashierList = records;
          // console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.listBody = false;
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  viewPosInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_POS) {
      this.posListBody = true;
      return true;
    }
    this.listBody = true;
    this.message = "Data Not Found";
    return false;
  }

  viewWebStoreInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_STORE) {
      this.webStoreListBody = true;
      return true;
    }
    this.listBody = true;
    this.message = "Data Not Found";
    return false;
  }

  viewApiUsersInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_APIUSERS) {
      this.apiListBody = true;
      return true;
    }
    return false;
  }


  OnCreateCashierUser() {
    let merchantId = this.data.merchantId;
    let merchantUid = this.data.merchantUid;
    this.router.navigate(["dashboard/view/createCashierInfo"], { queryParams: { merchantId, merchantUid } });
  }
  OnViewCashierUser(x) {
    let cashierId = x.cashierId;
    this.router.navigate(["dashboard/view/getCashierInfo"], { queryParams: { cashierId } });
  }
  OnViewReSendCredentials(x) {
    let cashierId = x.cashierId;
    this.router.navigate(["dashboard/view/getReSendCredentials"], { queryParams: { cashierId } });
  }


}
