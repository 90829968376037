import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.css'],
  animations: [
    fade
  ]
})
export class CreateInvoiceComponent implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  selectData;
  selectedValue;
  data;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();
    this.fnGetPaymentProcessorList();

  }

  getFrom(){
    this.getForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      selectedValue: ['', Validators.required],
    });
  }

  onChanges(deviceValue) {
    this.data = this.selectData[deviceValue.target.selectedIndex];
    // console.log("Wakan", this.data)
    // console.log(deviceValue.target.selectedIndex);
  }

  fnGetPaymentProcessorList(sourceId?) {
    let payload: any = {};
    // this.getSpinner();
    this.spinner.getSpinner();
    this.service.getPaymentProcessorList({payload: payload}).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      var records = JSON.parse(data);
      this.spinner.hideSpinner();
      // console.log("data :=> " + successResponse);

      var newrecord: any = {};
      newrecord.tranStatusId = "0";
      newrecord.tranStatusDesc = "All";
      records.push(newrecord);
      var selectedRecord: any = {};
      if (sourceId != null) {
        var length = records.length;
        for (var i = 0; i < length; i++) {
          var item = records[i];
          if (item.tranStatusId == sourceId) {
            selectedRecord = item;
            break;
          }
        };
        this.selectData = records;
        this.selectedValue = selectedRecord.tranStatusId;
      } else {
        this.spinner.hideSpinner();
        this.selectData = records;
        this.selectedValue = newrecord.tranStatusId;
      };
    },(failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };


  ngOnInit(): void {
  }

  passCreateInvoice(){
    this.submitted = true;
    if(this.getForm.invalid) return;

    let userId = this.data.userId;
    let accountId = this.data.accountId;
    let startDate = this.getForm.value.startDate;
    let endDate = this.getForm.value.endDate;
    let statusDesc = this.data.profileStatusDesc;
    let doneByUserId = this.data.createdByUserId;

    this.router.navigate(["dashboard/view/getCreateInvoice"], { queryParams: { userId, accountId, startDate, endDate, statusDesc, doneByUserId }});
    // console.log("WAAAY", payload);
  }

}
