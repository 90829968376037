<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">POS Merchant Usage Summary Report</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title"  style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive" id="section-one">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>MerchantUid</th>
                                <th>Merchant Name</th>
                                <th>Payer Distributor</th>
                                <th>Number Of Customers</th>
                                <th>Number Of Transactions</th>
                                <th>Amount</th>
                                <th>Charges</th>
                                <th>Commission Rate</th>
                                <th>Currency</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listBody">
                            <tr *ngFor="let x of listData | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                <td>{{ x.merchantUid}}</td>
                                <td>{{ x.merchantName}}</td>
                                <td>{{ x.payerDistributor}}</td>
                                <td>{{ x.customers}}</td>
                                <td>{{ x.numberOfTran}}</td>
                                <td>{{ x.amount | number:'1.2-2'}}</td>
                                <td>{{ x.charges}}</td>
                                <td>{{ x.commission}}</td>
                                <td>{{ x.currency}}</td>
                            </tr>
                            <tr style="background-color: #09df66be;">
                                <td>Total:</td>
                                <td></td>
                                <td></td>
                                <td>NumOfCustom: {{sumOfCustomers}}</td>
                                <td>NumOfTran: {{sumOfTran}}</td>
                                <td>Amount: {{ sumAmount | number:'1.2-2'}}</td>
                                <td>Charges: {{ sumCharges |number:'1.2-2'}}</td>
                                <td>Comm:Rate: {{ sumCommission |number:'1.2-2'}}</td>
                                <td>Currency: {{ currency }}</td>                
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="pageChange($event)"></pagination-controls>
                    </div>
                    <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>