<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">{{ Title }}</h4>
          </div>

          <div class="col-md-2" *ngIf="showForm === 'false'">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <form
          *ngIf="showForm === 'true'"
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="getWaafiQrPaymentsReport()"
        >
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="selectDistributor">Select Distributor</label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <label for="selectDistributor">Mobile Number</label>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control-sm"
                  formControlName="mobileNumber"
                  placeholder="Mobile Number"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.mobileNumber.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.mobileNumber.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.mobileNumber.errors?.required">
                    Mobile Number is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="startDate">Start Date </label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="startDate"
                  placeholder="Start Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.startDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.startDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.startDate.errors?.required">
                    Settlement Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="endDate">End Date </label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="endDate"
                  placeholder="Start Date"
                  [ngClass]="{
                    'is-invalid': submitted && getForm.controls.endDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.endDate.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.endDate.errors?.required">
                    Settlement Date is required
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>

        <!-- <div *ngIf="showForm === 'false'">
          <h4 class="text-center">Waafi Qr Payments Report</h4>
          <p class="text-center">
            {{ startDate | date : "MMMM dd,yyyy" }}
          </p>
        </div> -->
        <div
          class="row"
          style="margin-bottom: 0px"
          *ngIf="showForm === 'false'"
        >
          <div class="col-md-3">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>

        <div class="table-responsive" *ngIf="showForm === 'false'">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th (click)="sortData('paymentId')" style="cursor: pointer">
                  ID
                </th>

                <th (click)="sortData('cashierName')" style="cursor: pointer">
                  Cashier
                </th>

                <th
                  (click)="sortData('transactionAmount')"
                  style="cursor: pointer"
                >
                  Amount
                </th>
                <th
                  (click)="sortData('transactionFeeAmount')"
                  style="cursor: pointer"
                >
                  Fee
                </th>
                <th (click)="sortData('accountNumber')" style="cursor: pointer">
                  Account Number
                </th>
                <th (click)="sortData('exchangeRate')" style="cursor: pointer">
                  ExchangeRate
                </th>

                <th
                  (click)="sortData('responseMessage')"
                  style="cursor: pointer"
                >
                  Message
                </th>
                <th
                  (click)="sortData('transactionDate')"
                  style="cursor: pointer"
                >
                  Date
                </th>
                <th (click)="sortData('resState')" style="cursor: pointer">
                  State
                </th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td>{{ x.paymentId }}</td>

                <td class="py-1">
                  {{ x.cashierName.toUpperCase() }} ({{ x.cashierIdentifier }})
                </td>
                <td>
                  {{ x.currencySymbol }}
                  {{ formatTransactionAmount(x.transactionAmount) }}
                </td>
                <td>
                  {{ formatTransactionAmount(x.transactionFeeAmount) }}
                </td>
                <td>{{ x.accountNumber }}</td>
                <td>{{ x.exchangeRate }}</td>
                <td>{{ x.responseMessage }}</td>
                <td>{{ x.transactionDate | date : "dd-MMM-yy" }}</td>
                <!-- <td>{{ x.resState }}</td> -->

                <td>
                  <span
                    style="color: #009e44; margin: 4px"
                    *ngIf="x.resState == 'APPROVED'"
                    >●</span
                  >
                  <!-- <span
                    style="color: #300202;  margin: 4px"
                    *ngIf="x.resState != 'APPROVED'"
                    >●</span
                  > -->
                  <span
                    style="color: #e90202; margin: 4px"
                    *ngIf="x.resState != 'APPROVED'"
                    >●</span
                  >
                  {{ x.resState }}
                </td>
              </tr>
              <tr style="background-color: #09df66be">
                <td>Totals:</td>
                <td></td>
                <td>{{ sumTransactionAmount | number : "1.2-2" }}</td>
                <td>{{ sumTransactionFeeAmount | number : "1.2-2" }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="3000">30000</option>
                <option value="5000">5000</option>
                <option value="7000">7000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
