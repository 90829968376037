import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;
@Component({
  selector: 'app-distributor',
  templateUrl: './Branch.component.html',
  styleUrls: ['./Branch.component.css'],
  animations: [
    fade
  ]
})
export class BranchComponent implements OnInit {

  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  submitted = false;
  modal;
  isUpdate: boolean = false;
  listBody: boolean = false;
  distributorId;
  institutionId;
  selected = pagination.selected;
  p: number = pagination.p;
  term;
  selectrRecordId;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private _location: Location,
    private _sessionStorageService: SessionStorageService
  ) {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.distributorId = params['distributorId'];
        this.createForm();
        this.updateForm();
        this.getInstitution()
      } else {
        _location.back();
      }
    });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      loginId: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  updateForm() {
    this.updatesForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      sattledId: ['',]
    });
  }

  ngOnInit(): void {
    this.getBranches();
  }
  backToService() {
    this._location.back();
  }
  hanldeSubmit() {
    if (this.isUpdate) {
      this.OnUpdateBranch();
    } else {
      this.OnCreateBranch();
    }
  }

  getBranches() {
    let payload: any = {
      distributorId: this.distributorId
    };
    this.spinner.getSpinner();
    this.service.getBranches({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getInstitution() {
    let payload: any = {
      distributorId: this.distributorId
    };
    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.institutionId = data.institutionId
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  ShowModelCreateBranch() {
    $('#createBranchModel').modal('show');
    this.isUpdate = false;
    this.updatesForm.reset();
  }
  modelOnCancel() {
    $('#createBranchModel').modal('hide');
    this.isUpdate = false;
    this.updatesForm.reset();
  }
  viewUpdateBranch(x) {
    this.updatesForm = this.fb.group({
      name: [x.name, Validators.required],
      address: [x.addressDesc, Validators.required],
      sattledId: [x.satelliteUid, Validators.required],
    });
    this.selectrRecordId = x.id;
    this.isUpdate = true;
  }

  OnCreateBranch() {
    this.submitted = true;

    if (this.updatesForm.invalid) {
      return;
    }

    let payload: any = {
      name: this.updatesForm.value.name,
      address: this.updatesForm.value.address,
      sattledId: this.updatesForm.value.sattledId,
      PDistributorId: this.institutionId,
    };

    this.spinner.getSpinner();
    this.service.createBranch(payload).subscribe(successResponse => {
      if (successResponse.success) {
        $('#createBranchModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        this.updatesForm.reset();
        this.getBranches()
      } else {
        this.toastr.error(successResponse.records.message)
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  OnUpdateBranch() {
    this.submitted = true;

    if (this.updatesForm.invalid) {
      return;
    }
    let payload: any = {
      recordId: this.selectrRecordId,
      name: this.updatesForm.value.name,
      address: this.updatesForm.value.address,
      sattledId: this.updatesForm.value.sattledId,
    };

    this.spinner.getSpinner();
    this.service.UpdateCardPersoBranches(payload).subscribe(successResponse => {
      if (successResponse.success) {
        $('#createBranchModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success(successResponse.records.message)
        this.updatesForm.reset();
        this.isUpdate = false;
        this.getBranches()
      } else {
        this.toastr.error(successResponse.records.message)
        this.spinner.hideSpinner();
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  OnUpdateDistributorUser() {
    this.submitted = true;

    if (this.updatesForm.invalid) {
      return;
    }
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      name: this.updatesForm.value.name,
      address: this.updatesForm.value.address,

    };
    this.spinner.getSpinner();
    this.service.createBranch(payload).subscribe(successResponse => {
      // console.log("HHHHHH=> ", successResponse)
      if (successResponse.status == 'SUCCESS') {
        $('#createBranchModel').modal('hide');
        this.spinner.hideSpinner();
        this.toastr.success("Seccessfully: You have updated")
        this.serviceForm.reset(this.serviceForm.value);
        this.getBranches();
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
  passCreateView() {
    this.router.navigate(["dashboard/view/createBranch"])
  }
  passDistriputorListView(x) {
    let distributorId = x.referenceId;
    this.router.navigate(["dashboard/view/getDistributorAccountList"], { queryParams: { distributorId } })
  }
  passUpdateView(x) {
    let distributorId = x.referenceId;
    this.router.navigate(["dashboard/view/updateDistributorInfo"], { queryParams: { distributorId } })
  }


}
