<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-11">
                        <h4 class="card-title">View Group Settings</h4>
                    </div>
                    <div class="col-md-1">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backtowebrole()">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-subtitle">Merchant Group Settings</div>
                        <div style="background: #009e44; height: 40px; border-radius: 10px; margin-bottom: 10px;"></div>
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate  (ngSubmit)="onAssignSettings()">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Merchant Group Id</label>
                                        <input type="text" class="form-control" formControlName="merchantGroupId"
                                            placeholder="Merchant Group Id" readonly
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.merchantGroupId.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.merchantGroupId.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.merchantGroupId.errors.required">Merchant Group Id is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Group Name</label>
                                        <input type="text" class="form-control" formControlName="groupName"
                                            placeholder="Group Name"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.groupName.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.groupName.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.groupName.errors.required">Group Name is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Description</label>
                                        <input type="text" class="form-control" formControlName="description"
                                            placeholder="Description"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.description.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.description.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.description.errors.required">Description is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-subtitle">Settings</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-6">          
                                
                                    <table>
                                        <tr formArrayName="dataRecords" *ngFor="let item of dataRecords; let i = index">
                                            <td>
                                                <label>
                                                    <input type="checkbox" [formControlName]="i">
                                                    {{dataRecords[i].featureName}}
                                                </label>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Update</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                            <!-- <button type="submit" class="btn btn-primary">Update</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>