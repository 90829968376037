<div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">Cashier</h4>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="merchantUid">Merchant UID</label>
                                    <input type="text" class="form-control" [(ngModel)]="merchantUid"
                                        placeholder="Merchant UID" readonly>
                                    <!-- <div *ngIf="submitted && serviceForm.controls.MerchantUid.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.MerchantUid.errors.required">Merchant UID is required
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="merchantName">Merchant Name</label>
                                    <input type="text" class="form-control" [(ngModel)]="merchantName"
                                        placeholder="Merchant Name" readonly>
                                    <!-- <div *ngIf="submitted && serviceForm.controls.apiName.errors" class="text-danger">
                                        <div *ngIf="serviceForm.controls.apiName.errors.required">api Name is required
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;" *ngIf="isThirtParty">
                            <button type="submit" class="btn btn-primary" (click)="OnCreateCashierUser()">Add New Cashier</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 grid-margin stretch-card mt-5">
                        <div class="card"   >
                            <nav>
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <a class="nav-item nav-link active" id="nav-pos-tab" data-toggle="tab" href="#nav-pos"
                                        role="tab" aria-controls="nav-pos" aria-selected="true">POS Devices</a>
                                    <a class="nav-item nav-link" id="nav-web-tab" data-toggle="tab" href="#nav-web"
                                        role="tab" aria-controls="nav-web" aria-selected="false">Web Stores</a>
                                    <a class="nav-item nav-link" id="nav-api-tab" data-toggle="tab" href="#nav-api"
                                        role="tab" aria-controls="nav-api" aria-selected="false">API Users</a>
                                </div>
                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="nav-pos" role="tabpanel"
                                    aria-labelledby="nav-pos-tab">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <h4 class="card-title">POS Devices</h4>
                                        </div>
                                        <!-- <div class="col-md-2">
                                            <h4 class="card-title" style="float: right;">
                                                <button type="submit" class="btn btn-primary" (click)="passUserCreateInfo()">Add User</button>
                                            </h4>
                                        </div> -->
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-md-2">
                                            <input type="text" class="form-control-sm" [(ngModel)]="term" aria-hidden="true"
                                                placeholder="Search here..." style="float: left; margin: 5px;">
                                        </div>
                                    
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table-striped display expandable-table" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th>Cashier Id</th>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Contact Number</th>
                                                    <th>Address</th>
                                                    <th>Cashier Name</th>
                                                    <th>Status</th>
                                                    <th>View Info</th>
                                                    <th>Device</th>
                                                    <th>Send Credentials</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                                    <ng-container *ngIf="viewPosInfo(x)">
                                                        <td class="py-1">{{x.referenceId}}</td>
                                                        <td>{{ x.firstName + " " + x.middleName + " " + x.lastName}}</td>
                                                        <td>{{ x.email}}</td>
                                                        <td>{{ x.telephone}}</td>
                                                        <td>{{ x.address}}</td>
                                                        <td>{{ x.userTitle}}</td>
                                                        <td data-toggle="modal" data-target="#updateAccessModel"  style="cursor: pointer">
                                                            <span style="color: #009e44; margin: 4px;" *ngIf="x.profileStatusDesc == 'active'">●</span>
                                                            <span style="color: #e90202; margin: 4px;" *ngIf="x.profileStatusDesc == 'disabled'">●</span>
                                                            <span style="color: #300202; margin: 4px;" *ngIf="x.profileStatusDesc == 'suspended'">●</span>
                                                            <span style="color: #f8a50a; margin: 4px;" *ngIf="x.profileStatusDesc == 'inactive'">●</span>{{ x.profileStatusDesc}}
                                                        </td>
                                                        <td (click)="OnViewCashierUser(x)">
                                                            <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                                        </td>
                                                        <td (click)="viewwebUserInfo(x)">
                                                            <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                                        </td>
                                                        <td (click)="OnViewReSendCredentials(x)">
                                                            <button type="button" class="btn btn-outline-warning btn-sm">Credentials</button>
                                                        </td>
                                                    </ng-container>
    
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div style="width: 100%; margin-top: 1%;">
                                            <h4 class="card-title" style="float: left;">
                                                <select class="form-control-sm" [(ngModel)]="selected">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </h4>
                                            <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                                        </div>
                                        <div *ngIf='!listBody' class="container">
                                            <div style="text-align: center; padding: 24px;">
                                                {{message}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-web" role="tabpanel" aria-labelledby="nav-web-tab">
                                    <div class="row">
                                        <div class="col-md-11">
                                            <h4 class="card-title">Web Stores</h4>
                                        </div>
                                        <!-- <div class="col-md-1">
                                            <h4 class="card-title">
                                                <button type="submit" class="btn btn-primary mr-2" data-toggle="modal"
                                                    data-target="#sysConfigModel">ADD</button>
                                            </h4>
                                        </div> -->
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-md-2">
                                            <input type="text" class="form-control-sm" [(ngModel)]="term" aria-hidden="true"
                                                placeholder="Search here..." style="float: left; margin: 5px;">
                                        </div>
                                    
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table-striped display expandable-table" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th>Cashier Id</th>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Contact Number</th>
                                                    <th>Address</th>
                                                    <th>Cashier Name</th>
                                                    <th>Profile Status</th>
                                                    <th>View Info</th>
                                                    <th>HPP Conf</th>
                                                    <th>Send Credentials</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                                    <ng-container *ngIf="viewWebStoreInfo(x)">
                                                        <td>{{x.referenceId}}</td>
                                                        <td>{{ x.firstName + " " + x.middleName + " " + x.lastName}}</td>
                                                        <td>{{ x.email}}</td>
                                                        <td>{{ x.telephone}}</td>
                                                        <td>{{ x.address}}</td>
                                                        <td>{{ x.userTitle}}</td>
                                                        <td data-toggle="modal" data-target="#updateAccessModel"  style="cursor: pointer">
                                                            <span style="color: #009e44; margin: 4px;" *ngIf="x.profileStatusDesc == 'active'">●</span>
                                                            <span style="color: #e90202; margin: 4px;" *ngIf="x.profileStatusDesc == 'disabled'">●</span>
                                                            <span style="color: #300202; margin: 4px;" *ngIf="x.profileStatusDesc == 'suspended'">●</span>
                                                            <span style="color: #f8a50a; margin: 4px;" *ngIf="x.profileStatusDesc == 'inactive'">●</span>{{ x.profileStatusDesc}}
                                                        </td>
                                                        <td (click)="OnViewCashierUser(x)">
                                                            <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                                        </td>
                                                        <td (click)="viewwebUserInfo(x)">
                                                            <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                                        </td>
                                                        <td (click)="OnViewReSendCredentials(x)">
                                                            <button type="button" class="btn btn-outline-warning btn-sm">Credentials</button>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div style="width: 100%; margin-top: 1%;">
                                            <h4 class="card-title" style="float: left;">
                                                <select class="form-control-sm" [(ngModel)]="selected">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </h4>
                                            <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                                        </div>
                                        <div *ngIf='!listBody' class="container">
                                            <div style="text-align: center; padding: 24px;">
                                                {{message}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-api" role="tabpanel" aria-labelledby="nav-api-tab">
                                    <div class="row">
                                        <div class="col-md-11">
                                            <h4 class="card-title">API Users</h4>
                                        </div>
                                        <!-- <div class="col-md-1">
                                            <h4 class="card-title">
                                                <button type="submit" class="btn btn-primary mr-2" data-toggle="modal"
                                                    data-target="#sysConfigModel">ADD</button>
                                            </h4>
                                        </div> -->
                                    </div>
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-md-2">
                                            <input type="text" class="form-control-sm" [(ngModel)]="term" aria-hidden="true"
                                                placeholder="Search here..." style="float: left; margin: 5px;">
                                        </div>
                                    
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table-striped display expandable-table" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th>Cashier Id</th>
                                                    <th>Full Name</th>
                                                    <th>Email</th>
                                                    <th>Contact Number</th>
                                                    <th>Address</th>
                                                    <th>Cashier Name</th>
                                                    <th>Profile Status</th>
                                                    <th>View Info</th>
                                                    <th>Send Credentials</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let x of dataList | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                                                    <ng-container *ngIf="viewApiUsersInfo(x)">
                                                        <td>{{x.referenceId}}</td>
                                                        <td>{{ x.firstName + " " + x.middleName + " " + x.lastName}}</td>
                                                        <td>{{ x.email}}</td>
                                                        <td>{{ x.telephone}}</td>
                                                        <td>{{ x.address}}</td>
                                                        <td>{{ x.userTitle}}</td>
                                                        <td data-toggle="modal" data-target="#updateAccessModel"  style="cursor: pointer">
                                                            <span style="color: #009e44; margin: 4px;" *ngIf="x.profileStatusDesc == 'active'">●</span>
                                                            <span style="color: #e90202; margin: 4px;" *ngIf="x.profileStatusDesc == 'disabled'">●</span>
                                                            <span style="color: #300202; margin: 4px;" *ngIf="x.profileStatusDesc == 'suspended'">●</span>
                                                            <span style="color: #f8a50a; margin: 4px;" *ngIf="x.profileStatusDesc == 'inactive'">●</span>{{ x.profileStatusDesc}}
                                                        </td>
                                                        <td (click)="OnViewCashierUser(x)">
                                                            <button type="button" class="btn btn-outline-primary btn-sm">view</button>
                                                        </td>
                                                        <td (click)="OnViewReSendCredentials(x)">
                                                            <button type="button" class="btn btn-outline-warning btn-sm">Credentials</button>
                                                        </td>
                                                    </ng-container>
    
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div style="width: 100%; margin-top: 1%;">
                                            <h4 class="card-title" style="float: left;">
                                                <select class="form-control-sm" [(ngModel)]="selected">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </h4>
                                            <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                                        </div>
                                        <div *ngIf='!listBody' class="container">
                                            <div style="text-align: center; padding: 24px;">
                                                {{message}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
<!-- 
<div class="modal fade bd-example-modal-md" id="updateAccessModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Update Status Profile</h4>
                            <form class="forms-sample" [formGroup]="updateAccessForm" (ngSubmit)="onUpdateAccessProfileInfo()">
                                <div class="card-subtitle">Access Profile</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Status</label>
                                    <select class="form-control" formControlName="selectedAccess">
                                        <option value=null disabled selected>--Select--</option>
                                        <option *ngFor="let p of selectAccess" [ngValue]="p.profileStatusId">
                                        {{p.profileStatusDesc}}</option>
                                    </select>
                                    <div *ngIf="submitted && updateAccessForm.controls.selectedAccess.errors" class="text-danger">
                                        <div *ngIf="updateAccessForm.controls.selectedAccess.errors.required">Status Profile is required</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="table-responsive">
    <table class="table-striped display expandable-table" style="width:100%">
        <thead>
            <tr>
                <th>Cashier Id</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Contact Number</th>
                <th>Address</th>
                <th>User Title</th>
                <th>Profile Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let x of dataList">
                <td class="py-1">{{x.referenceId}}</td>
                <td>{{ x.firstName + " " + x.middleName + " " + x.lastName}}</td>
                <td>{{ x.email}}</td>
                <td>{{ x.telephone}}</td>
                <td>{{ x.address}}</td>
                <td>{{ x.userTitle}}</td>
                <td>{{ x.profileStatusDesc}}</td>
                <td (click)="viewwebUserInfo(x)">
                    <a data-toggle="modal" data-target="#viewModel" style="color: #009e44; cursor: pointer;">
                        <i class="material-icons">&#xE417;</i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div> -->