import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "src/app/service/service/service.service";
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-merchant-usage-summary-table-report',
  templateUrl: './merchant-usage-summary-table-report.component.html',
  styleUrls: ['./merchant-usage-summary-table-report.component.css'],
  animations: [
    fade
  ]
})
export class MerchantUsageSummaryTableReportComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  subscripForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;

  merchantId;
  queryMerchantId;
  startDate;
  queryStartDate;
  queryCurrancy;
  queryUserTypeId;
  userTypeId
  accountId;
  endDate;
  queryEndDate;
  payerId;
  queryPayerId;
  statusId;
  queryStatusId;

  queryuserDistributorId: any;
  sub: any;
  srcCurrancy;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  sumOfTran = null;
  sumOfCustomers = null;
  sumAmount = null;
  sumCharges = null;
  sumCommission = null;
  currency;


  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.route.queryParams.subscribe(params => {
      this.queryUserTypeId = params['userTypeId'];
      this.queryCurrancy = params['currency'];
      this.queryStartDate = params['startDate'];
      this.queryEndDate = params['endDate'];
      this.startDate = this.queryStartDate;
      this.endDate = this.queryEndDate;
      this.currency = this.queryCurrancy;
      this.userTypeId = this.queryUserTypeId;
      
      this.fnListCommissionUsageSummaryReports(this.userTypeId, this.currency, this.startDate, this.endDate)
    });
   

  }


  ngOnInit(): void {
  }



  backToService() {
    this._location.back();
  }

  onPrintInvoice(cmpName) {
    let printContents = document.getElementById(cmpName).innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     window.location.reload();;
  }

  fnListCommissionUsageSummaryReports(userTypeId?, currency?, startDate?, endDate?) {
 
    let payload: any = {
      currancy: currency,
      distributorId: userTypeId,
      startDate: startDate,
      endDate: endDate
    };
    console.log("Paylodd My=> ", payload)
   
    this.spinner.getSpinner();
    this.service.getMerchantsSummary({ payload: payload }).subscribe(successResponse => {
      // console.log("haaa", successResponse)
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          // var data = JSON.parse(responseObj);
          var records = JSON.parse(responseObj);
          this.listData = records;
          records.forEach((el, index, arry) => {
            let record = {};
            this.sumOfCustomers += el.customers;
            this.sumOfTran += el.numberOfTran;
            this.sumAmount += el.amount;
            this.sumCharges += el.charges;
            this.sumCommission += el.commission;
            // console.log("gaaaa", record)
          });
          // console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


}
