import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-pos-transaction-table-info',
  templateUrl: './pos-transaction-table-info.component.html',
  styleUrls: ['./pos-transaction-table-info.component.css'],
  animations: [
    fade
  ]
})
export class PosTransactionTableInfoComponent implements OnInit {

  getForm: FormGroup;
  submitted = false;
  _POS_PURCHASE: any = 1;
  _EPAYMENT: any = 4;

  listData;
  listBody: boolean;
  searchButtonText;
  errorMessageList: boolean;

  statusData;
  selectData;
  selectedValue;

  merchantId: any;
  tranStatusId: any;
  transactionId: any;
  cashierId: any;
  amount: any;
  charges: any;
  currency: any;
  description: any;
  invoiceId: any;
  statusId: any;
  posDeviceId: any;
  paymentMethod: any;
  payerId: any;
  payerIdType: any;
  payerBankId: any;
  transactionTypeId: any;
  issuerTransactionId: any;
  createdDate: any;
  paymentChannel: any;
  accountId: any;
  divcancellationbutton: boolean;
  cancelSuccessMessage: boolean;
  cancelFailureMessage: boolean;
  sub: any;
  message;
  data;
  isTrue = false;
  queryMerchantId: any;
  queryTranStatusId: any;
  selectedMerchantValue: any;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private _location: Location,
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.queryMerchantId = +params['merchantId'];
        this.queryTranStatusId = +params['tranStatusId'];
        if (Number.isNaN(this.queryMerchantId || this.queryTranStatusId)) {
          this.merchantId = "";
          this.tranStatusId = "";
        } else {
          this.merchantId = this.queryMerchantId;
          this.tranStatusId = this.queryTranStatusId;
          this.fnListPosTransactionInfo(this.queryMerchantId, this.queryTranStatusId);
        }
        //this.fnListTransactionHistoryWithMerchantId(this.queryMerchantId);
      });
  }

  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }

  fnListPosTransactionInfo(merchantId, tranStatusId) {
    let currentUser = this._sessionStorageService.getSessionData();
    this.listBody = false;
    let payload: any = {
      userId: currentUser.userId,
      merchantId: this.merchantId,
      statusId: this.tranStatusId,
      recordCount: "0"

    };

    if (!tranStatusId) {
      payload.merchantId = merchantId;
      payload.statusId = "0"
      this.merchantId = merchantId;
    } else {
      payload.merchantId = this.merchantId;
      payload.statusId = this.tranStatusId;
    };
    this.spinner.getSpinner();
    this.service.getPosMerchantTransactions({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.data = data.message;
      // console.log("msg: ", data.message)
      if (!successResponse.records.message) {
        this.listBody = true;
      }
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var records = JSON.parse(data);
        // console.log("All ", records)
        this.listData = records;
        this.listBody = true;
        this.isTrue = false;
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.listData = null;
        this.isTrue = true;
        this.message = data.message;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  };

  passTransactionInfo(data) {
    let tranId = data.tranId;
    this.router.navigate(["dashboard/view/transactionView"], { queryParams: { tranId } })
  }
}
