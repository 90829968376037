<div class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title">Merchant</h4>
          </div>

          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label for="merchantUid">Merchant UID</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="merchantUid"
                placeholder="Merchant UID"
                readonly
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group">
              <label for="merchantName">Merchant Name</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="merchantName"
                placeholder="Merchant Name"
                readonly
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="productName">Product Name</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="productName"
                placeholder="product Name"
                readonly
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="merchantIdentifier">Partner UID</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="merchantIdentifier"
                placeholder="merchant Identifier"
                readonly
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="groupName"> group Name </label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="groupName"
                placeholder="group Name"
                readonly
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label for="productName">createdDate</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="createdDate"
                placeholder="created Date"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 grid-margin stretch-card mt-5">
            <div class="card">
              <!-- nav -->
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    class="nav-item nav-link active"
                    id="nav-web-tab"
                    data-toggle="tab"
                    href="#nav-web"
                    role="tab"
                    aria-controls="nav-web"
                    aria-selected="true"
                    >Accounts</a
                  >
                  <a
                    class="nav-item nav-link"
                    id="nav-api-tab"
                    data-toggle="tab"
                    href="#nav-api"
                    role="tab"
                    aria-controls="nav-api"
                    aria-selected="false"
                    >Latest Transactions</a
                  >
                  <a
                    class="nav-item nav-link"
                    id="nav-pos-tab"
                    data-toggle="tab"
                    href="#nav-pos"
                    role="tab"
                    aria-controls="nav-pos"
                    aria-selected="false"
                    >POS Devices</a
                  >
                  <a
                    class="nav-item nav-link"
                    id="nav-cashier-tab"
                    data-toggle="tab"
                    href="#nav-cashier"
                    role="tab"
                    aria-controls="nav-cashier"
                    aria-selected="false"
                    >Cashiers</a
                  >
                  <a
                    class="nav-item nav-link"
                    id="nav-merchant-tab"
                    data-toggle="tab"
                    href="#nav-merchant"
                    role="tab"
                    aria-controls="nav-merchant"
                    aria-selected="false"
                    >Merchant Access</a
                  >
                </div>
              </nav>

              <div class="tab-content" id="nav-tabContent">
                <!-- Accounts -->
                <div
                  class="tab-pane fade show active"
                  id="nav-web"
                  role="tabpanel"
                  aria-labelledby="nav-web-tab"
                >
                  <div class="row">
                    <div class="col-md-11">
                      <h4 class="card-title">Accounts</h4>
                    </div>
                    <!-- <div class="col-md-1">
                                            <h4 class="card-title">
                                                <button type="submit" class="btn btn-primary mr-2" data-toggle="modal"
                                                    data-target="#sysConfigModel">ADD</button>
                                            </h4>
                                        </div> -->
                  </div>
                  <div class="row" style="margin-bottom: 5px">
                    <div class="col-md-2">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [(ngModel)]="term"
                        aria-hidden="true"
                        placeholder="Search here..."
                        style="float: left; margin: 5px"
                      />
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table
                      class="table-striped display expandable-table"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Account Id</th>
                          <th>CreatedBy UserId</th>
                          <th>User Id</th>
                          <th>Settlement Info</th>
                          <th>View</th>
                          <th>Default Account</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="listBody">
                        <tr
                          *ngFor="
                            let x of listData
                              | filter : term
                              | paginate
                                : { itemsPerPage: selected, currentPage: p }
                          "
                        >
                          <td>{{ x.accountId }} ({{ x.currency }})</td>
                          <td>{{ x.createdByUserId }}</td>
                          <td>{{ x.userId }}</td>
                          <td (click)="fnViewMerchantSettlementInfo(x)">
                            <button
                              type="button"
                              class="btn btn-outline-primary btn-sm"
                            >
                              view
                            </button>
                          </td>
                          <td (click)="viewMerchantAccountsInfo(x)">
                            <button
                              type="button"
                              class="btn btn-outline-primary btn-sm"
                            >
                              view
                            </button>
                          </td>
                          <td (click)="updateMerchantDefAccountIds(x)">
                            <div class="custom-control custom-switch">
                              <input
                                [(ngModel)]="x.accountId == accountId"
                                type="checkbox"
                                class="custom-control-input"
                                name="switch"
                              />
                              <label class="custom-control-label"> </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 100%">
                      <pagination-controls
                        style="float: right"
                        (pageChange)="p = $event"
                      ></pagination-controls>
                    </div>
                    <div *ngIf="!listBody" class="container">
                      <div style="text-align: center; padding: 24px">
                        {{ message }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- transections -->
                <div
                  class="tab-pane fade"
                  id="nav-api"
                  role="tabpanel"
                  aria-labelledby="nav-api-tab"
                >
                  <div class="row">
                    <div class="col-md-11">
                      <h4 class="card-title">Latest Transections</h4>
                    </div>
                    <!-- <div class="col-md-1">
                                            <h4 class="card-title">
                                                <button type="submit" class="btn btn-primary mr-2" data-toggle="modal"
                                                    data-target="#sysConfigModel">ADD</button>
                                            </h4>
                                        </div> -->
                  </div>
                  <div class="row" style="margin-bottom: 5px">
                    <div class="col-md-2">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [(ngModel)]="term"
                        aria-hidden="true"
                        placeholder="Search here..."
                        style="float: left; margin: 5px"
                      />
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table
                      class="table-striped display expandable-table"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>PaymentId</th>
                          <th>Cashier</th>
                          <th>Partner UID</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Trace Number</th>
                          <th>Charge Amount</th>
                          <th>RRN</th>
                          <th>Approval Number</th>
                          <th>Settlement</th>
                          <th>State</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let x of posTransectionsData
                              | filter : term
                              | paginate
                                : { itemsPerPage: selected, currentPage: p }
                          "
                        >
                          <!-- <td class="py-1">{{ x.sn }}</td> -->
                          <td>{{ x.paymentId }}</td>
                          <td>{{ x.cashierName }}</td>
                          <td>{{ x.cashierIdentifier }}</td>
                          <td>{{ x.transactionAmount }}</td>
                          <td>
                            {{
                              x.transactionDate | date : "dd-MMM-yy - HH:mm:ss"
                            }}
                          </td>
                          <td>{{ x.traceNumber }}</td>
                          <td>{{ x.transactionFeeAmount }}</td>
                          <td>{{ x.retrievalRefNumber }}</td>
                          <td>{{ x.approvalCode }}</td>
                          <td>{{ x.settlementId }}</td>
                          <td class="font-weight-medium">
                            <div class="{{ x.status }}">{{ x.resState }}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%">
                      <h4 class="card-title" style="float: left">
                        <select class="form-control-sm" [(ngModel)]="selected">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </h4>
                      <pagination-controls
                        style="float: right"
                        (pageChange)="p = $event"
                      ></pagination-controls>
                    </div>
                    <div *ngIf="!listBody" class="container">
                      <div style="text-align: center; padding: 24px">
                        {{ message }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- POS Devices -->
                <div
                  class="tab-pane fade"
                  id="nav-pos"
                  role="tabpanel"
                  aria-labelledby="nav-pos-tab"
                >
                  <div class="row">
                    <div class="col-md-10">
                      <h4 class="card-title">POS Devices</h4>
                    </div>
                    <!-- <div class="col-md-2">
                                           <h4 class="card-title" style="float: right;">
                                               <button type="submit" class="btn btn-primary" (click)="passUserCreateInfo()">Add User</button>
                                           </h4>
                                       </div> -->
                  </div>
                  <div class="row" style="margin-bottom: 5px">
                    <div class="col-md-2">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [(ngModel)]="term"
                        aria-hidden="true"
                        placeholder="Search here..."
                        style="float: left; margin: 5px"
                      />
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table
                      class="table-striped display expandable-table"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Cashier</th>
                          <th>Partner UID</th>
                          <th>Model</th>
                          <th>Registered</th>
                          <th>Last Updated</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let x of posDevicesList
                              | filter : term
                              | paginate
                                : { itemsPerPage: selected, currentPage: p }
                          "
                        >
                          <td class="py-1">
                            {{ x.deviceSn }} ({{ x.deviceId }})
                          </td>
                          <td>{{ x.cashier }} ({{ x.cashierId }})</td>
                          <td>{{ x.cashierIdentifier }}</td>
                          <td>{{ x.model }}</td>
                          <td>
                            {{ x.registered | date : "dd-MMM-yy - HH:mm:ss" }}
                          </td>
                          <td>
                            {{ x.lastUpdated | date : "dd-MMM-yy - HH:mm:ss" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%">
                      <h4 class="card-title" style="float: left">
                        <select class="form-control-sm" [(ngModel)]="selected">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </h4>
                      <pagination-controls
                        style="float: right"
                        (pageChange)="p = $event"
                      ></pagination-controls>
                    </div>
                    <div *ngIf="!listBody" class="container">
                      <div style="text-align: center; padding: 24px">
                        {{ message }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- cashier access -->
                <div
                  class="tab-pane fade"
                  id="nav-cashier"
                  role="tabpanel"
                  aria-labelledby="nav-cashier-tab"
                >
                  <div class="row">
                    <div class="col-md-10">
                      <h4 class="card-title">Cashiers</h4>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 5px">
                    <div class="col-md-2">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [(ngModel)]="term"
                        aria-hidden="true"
                        placeholder="Search here..."
                        style="float: left; margin: 5px"
                      />
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table
                      class="table-striped display expandable-table"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Cashier</th>
                          <th>Telephone</th>
                          <th>Switch Merchant Id</th>
                          <th>Switch Terminal Id</th>
                          <th>Acquirer Currency Code</th>
                          <th>KVC1</th>
                          <th>KVC2</th>
                          <th>KVC</th>
                          <th>Registered</th>
                          <th>Profile Status</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let x of cashierList
                              | filter : term
                              | paginate
                                : { itemsPerPage: selected, currentPage: p }
                          "
                        >
                          <td class="py-1">
                            {{ x.userTitle }} ({{ x.cashierId }})
                          </td>

                          <td>{{ x.telephone }}</td>
                          <td>{{ x.acquirerMerchantId }}</td>
                          <td>{{ x.acquirerTerminalId }}</td>
                          <td>{{ x.acquirerCurrencyCode }}</td>
                          <td>{{ x.kvc1 }}</td>
                          <td>{{ x.kvc2 }}</td>
                          <td>{{ x.acquirerKvcValue }}</td>
                          <td>
                            {{ x.createdDate | date : "dd-MMM-yy" }}
                          </td>
                          <!-- <td>{{ x.profileStatusDesc }}</td> -->
                          <td>
                            <span
                              style="color: #009e44; margin: 4px"
                              *ngIf="x.profileStatusDesc == 'active'"
                              >●</span
                            >
                            <span
                              style="color: #e90202; margin: 4px"
                              *ngIf="x.profileStatusDesc == 'disabled'"
                              >●</span
                            >
                            <span
                              style="color: #300202; margin: 4px"
                              *ngIf="x.profileStatusDesc == 'suspended'"
                              >●</span
                            >
                            <span
                              style="color: #f8a50a; margin: 4px"
                              *ngIf="x.profileStatusDesc == 'inactive'"
                              >●</span
                            >{{ x.profileStatusDesc }}
                          </td>

                          <td (click)="getUpdateInfo(x)">
                            <button
                              data-toggle="modal"
                              data-target="#updateAccessModel"
                              type="button"
                              class="btn btn-outline-primary btn-sm"
                            >
                              view
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%">
                      <h4 class="card-title" style="float: left">
                        <select class="form-control-sm" [(ngModel)]="selected">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </h4>
                      <pagination-controls
                        style="float: right"
                        (pageChange)="p = $event"
                      ></pagination-controls>
                    </div>
                    <div *ngIf="!listBody" class="container">
                      <div style="text-align: center; padding: 24px">
                        {{ message }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- merchant access -->
                <div
                  class="tab-pane fade"
                  id="nav-merchant"
                  role="tabpanel"
                  aria-labelledby="nav-merchant-tab"
                >
                  <div class="row">
                    <div class="col-md-10">
                      <h4 class="card-title">Merchant</h4>
                    </div>
                  </div>
                  <form
                    class="forms-sample"
                    [formGroup]="serviceForm"
                    (ngSubmit)="onUpdateAccessProfileInfoMerchant()"
                  >
                    <!-- <fieldset [disabled]="isDisabled"> -->
                    <div class="card-subtitle">Activation</div>
                    <div
                      style="
                        border: 1px solid #009e44;
                        margin-bottom: 10px;
                        margin-top: 10px;
                        background: #009e44;
                      "
                    ></div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputUsername1">User Id</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="userId"
                            placeholder="User Id"
                            readonly
                            [ngClass]="{
                              'is-invalid':
                                submitted && serviceForm.controls.userId.errors
                            }"
                          />
                          <div
                            *ngIf="
                              submitted && serviceForm.controls.userId.errors
                            "
                            class="text-danger"
                          >
                            <div
                              *ngIf="
                                serviceForm.controls.userId.errors.required
                              "
                            >
                              User Id required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="isActive">Profile Status</label>
                          <select
                            class="form-control"
                            formControlName="selectedAccess"
                            [ngClass]="{
                              'is-invalid':
                                submitted &&
                                serviceForm.controls.selectedAccess.errors
                            }"
                          >
                            <option value="null" disabled selected>
                              --Select--
                            </option>
                            <option
                              *ngFor="let p of selectAccess"
                              [ngValue]="p.profileStatusId"
                            >
                              {{ p.profileStatusDesc }}
                            </option>
                          </select>
                          <div
                            *ngIf="
                              submitted &&
                              serviceForm.controls.selectedAccess.errors
                            "
                            class="text-danger"
                          >
                            <div
                              *ngIf="
                                serviceForm.controls.selectedAccess.errors
                                  .required
                              "
                            >
                              Status Profile is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col text-center">
                        <button
                          type="submit"
                          [disabled]="!btnDisabled"
                          class="btn btn-primary"
                          style="width: 30%"
                        >
                          Update
                        </button>
                        <!-- <button class="btn btn-default">Centered button</button> -->
                      </div>
                    </div>
                    <!-- </fieldset> -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- model -->
<div
  class="modal fade bd-example-modal-md"
  id="updateAccessModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Update Status Profile</h4>
              <form
                class="forms-sample"
                [formGroup]="updateAccessForm"
                (ngSubmit)="onUpdateAccessProfileInfo()"
              >
                <div class="card-subtitle">Access Profile</div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Status</label>
                  <select class="form-control" formControlName="selectedAccess">
                    <option value="null" disabled selected>--Select--</option>
                    <option
                      *ngFor="let p of selectAccess"
                      [ngValue]="p.profileStatusId"
                    >
                      {{ p.profileStatusDesc }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      updateAccessForm.controls.selectedAccess.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        updateAccessForm.controls.selectedAccess.errors.required
                      "
                    >
                      Status Profile is required
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div class="modal fade bd-example-modal-md" id="updateAccessModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-md-md" role="document">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Update Status Profile</h4>
                            <form class="forms-sample" [formGroup]="updateAccessForm" (ngSubmit)="onUpdateAccessProfileInfo()">
                                <div class="card-subtitle">Access Profile</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Status</label>
                                    <select class="form-control" formControlName="selectedAccess">
                                        <option value=null disabled selected>--Select--</option>
                                        <option *ngFor="let p of selectAccess" [ngValue]="p.profileStatusId">
                                        {{p.profileStatusDesc}}</option>
                                    </select>
                                    <div *ngIf="submitted && updateAccessForm.controls.selectedAccess.errors" class="text-danger">
                                        <div *ngIf="updateAccessForm.controls.selectedAccess.errors.required">Status Profile is required</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="table-responsive">
    <table class="table-striped display expandable-table" style="width:100%">
        <thead>
            <tr>
                <th>Cashier Id</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Contact Number</th>
                <th>Address</th>
                <th>User Title</th>
                <th>Profile Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let x of dataList">
                <td class="py-1">{{x.referenceId}}</td>
                <td>{{ x.firstName + " " + x.middleName + " " + x.lastName}}</td>
                <td>{{ x.email}}</td>
                <td>{{ x.telephone}}</td>
                <td>{{ x.address}}</td>
                <td>{{ x.userTitle}}</td>
                <td>{{ x.profileStatusDesc}}</td>
                <td (click)="viewwebUserInfo(x)">
                    <a data-toggle="modal" data-target="#viewModel" style="color: #009e44; cursor: pointer;">
                        <i class="material-icons">&#xE417;</i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div> -->
