<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">View Biller Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate
                                (ngSubmit)="updateBillerInfo()">

                                <div class="card-subtitle">Billers</div>
                                <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Biller Id</label>
                                            <input type="text" class="form-control" formControlName="billerId"
                                                placeholder="Biller Id" readonly
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.billerId.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.billerId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.billerId.errors.required">
                                                    Biller Id is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Biller Code</label>
                                            <input type="text" class="form-control" formControlName="billerCode"
                                                placeholder="Biller Code"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.billerCode.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.billerCode.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.billerCode.errors.required">
                                                    Biller Code is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Biller Name</label>
                                            <input type="text" class="form-control" formControlName="billerName"
                                                placeholder="Biller Name"
                                                [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.billerName.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.billerName.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.billerName.errors.required">
                                                    Biller Name is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Biller Category</label>
                                            <select class="form-control" formControlName="billerCategoryId" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.billerCategoryId.errors }">
                                                <option *ngFor="let p of selectBillingCategory" [ngValue]="p.billerCategoryId">{{p.description}}</option>
                                              </select>
                                            <div *ngIf="submitted && serviceForm.controls.billerCategoryId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.billerCategoryId.errors.required">billerCategoryId is
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Payment Processor</label>
                                            <select class="form-control" formControlName="paymentProcessorId" [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.paymentProcessorId.errors }">
                                                <option *ngFor="let p of selectPaymentProcessorList" [ngValue]="p.paymentProcessorId">{{p.paymentProcessorTypeDesc}}</option>
                                              </select>
                                            <div *ngIf="submitted && serviceForm.controls.paymentProcessorId.errors"
                                                class="text-danger">
                                                <div *ngIf="serviceForm.controls.paymentProcessorId.errors.required">Payment Processor Id
                                                    required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-center">
                                        <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                                        <!-- <button class="btn btn-default">Centered button</button> -->
                                    </div>
                                </div>
                                <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button>
                                <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                            </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

