<div @fade class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Card Mc Hourly Report</h4>
        <p class="card-description">Card Mc Hourly Report</p>
        <form
          class="forms-sample"
          [formGroup]="getForm"
          novalidate
          (ngSubmit)="PassViewReport()"
        >
          <div class="row">
            <!-- <div class="col-md-2">
              <div class="form-group">
                <label for="selectDistributor">Select Distributor</label>
                <select
                  class="form-control-sm"
                  name="selectDistributor"
                  formControlName="selectDistributor"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.selectDistributor.errors
                  }"
                >
                  <option [selected]="true" value="null" disabled selected>
                    --Select--
                  </option>
                  <option
                    *ngFor="let c of distdataList"
                    [ngValue]="c.referenceId"
                  >
                    {{ c.userTitle }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.selectDistributor.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.selectDistributor.errors?.required"
                  >
                    Select Distributor is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="SelectedMonth">Select a Month</label>
                <select
                  class="form-control-sm"
                  formControlName="SelectedMonth"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.SelectedMonth.errors
                  }"
                >
                  <option *ngFor="let c of monthsList" [ngValue]="c.id">
                    {{ c.name }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && getForm.controls.SelectedMonth.errors"
                  class="text-danger"
                >
                  <div *ngIf="getForm.controls.SelectedMonth.errors?.required">
                    please Select Month
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-3">
              <div class="form-group">
                <label for="startDate">Transaction Date </label>
                <input
                  type="date"
                  class="form-control-sm"
                  formControlName="transactionDate"
                  placeholder="Transaction Date"
                  [ngClass]="{
                    'is-invalid':
                      submitted && getForm.controls.transactionDate.errors
                  }"
                />
                <div
                  *ngIf="submitted && getForm.controls.transactionDate.errors"
                  class="text-danger"
                >
                  <div
                    *ngIf="getForm.controls.transactionDate.errors?.required"
                  >
                    Transaction Date is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 mt-2">
              <div class="form-group mt-4">
                <button type="submit" class="btn btn-primary">Search</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
