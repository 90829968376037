import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-settlement-request',
  templateUrl: './settlement-request.component.html',
  styleUrls: ['./settlement-request.component.css'],
  animations: [
    fade
  ]
})
export class SettlementRequestComponent implements OnInit {

  submitted = false;
  getForm: FormGroup;
  dataList;
  optionMenu;
  selected;
  isSelected: boolean;
  isSelected1: boolean;
  distributorId;
  merchantId;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getFrom();

  }

  getFrom() {
    this.getForm = this.fb.group({
      Ids: ['', Validators.required],
      selectedData: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }


  selectInput(event) {
    let selected = event.target.value;
    // console.log("PPP=>", selected)
    if (selected == '0: 0') {
      this.isSelected = true;
    } else if (selected == '1: 1') {
      this.isSelected = true;
    } else {
      this.isSelected = false;
    }
  }


  passSettlementDirectView() {
    this.submitted = true;
    if (this.getForm.invalid) {
      return;
    }

    if (this.getForm.value.selectedData == 0) {
      var merchantId = this.getForm.value.Ids;
      this.router.navigate(["dashboard/view/createRequest"], { queryParams: { merchantId } });
    }
    if (this.getForm.value.selectedData == 1) {
      var distributorId = this.getForm.value.Ids;
      this.router.navigate(["dashboard/view/createRequest"], { queryParams: { distributorId } });
    }
  }


}
