import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import {Router} from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service"
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';


@Component({
  selector: 'app-routconfig',
  templateUrl: './routconfig.component.html',
  styleUrls: ['./routconfig.component.css'],
  animations: [
    fade
  ]
})
export class RoutconfigComponent implements OnInit {
  dataList;
  serviceForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  modal;
  selectBankData;
  selectServiceChargeModeData;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private router: Router,
    private _sessionStorageService: SessionStorageService
  ) {
    this.getForms();
    this.createForm();
    this.getRoutesList();
    // this.getBankList();
  }

  getForms() {
    this.getForm = this.fb.group({
      bankId: ['', Validators.required ],
      routeId: ['', Validators.required ],
      routePrefix: ['', Validators.required ],
      isActive: ['', Validators.required ],
      selectedBank: ['', Validators.required ],
      currencyId: ['', Validators.required ],
      currencyCode: ['', Validators.required ],
      currencyDescription: ['', Validators.required ],
      
    });
  }
  createForm() {
    this.serviceForm = this.fb.group({
      routePrefix: ['', Validators.required ],
      isActive: ['', Validators.required ],
      selectedBank: ['', Validators.required ],
      currencyId: ['', Validators.required ],
    
    });
  }

  ngOnInit(): void {

  }

  getRoutesList(){
    let payload: any = {};
    this.spinner.getSpinner();
    this.service.getRoutesList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.dataList =records;
        // console.log("kkkk", records)
      }else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  resetForm(){
    this.serviceForm.reset();
  }

  passCreation() {
    this.router.navigate(['dashboard/view/createRouteConfig']);
  }
  passUpdated(x) {
    let routeId = x.routeId;
    this.router.navigate(['dashboard/view/updateRouteConfig'], { queryParams: { routeId } });
  }

  // getBankList(){
  //   let payload: any = {};
  //   this.service.getBankList({payload: payload}).subscribe(successResponse => {
  //     if(successResponse.records){
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       var records = JSON.parse(data);
  //       this.selectBankData =records;
  //       // console.log("kkkk", records)
  //     }else {
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }

  // OnCreateRoutesConfig() {
  //   this.submitted = true;
 
  //   if (this.serviceForm.invalid) {
  //       return;
  //   }
  //   let payload: any = {
  //     routePrefix: this.serviceForm.value.routePrefix,
  //     currencyId: this.serviceForm.value.currencyId, 
  //     bankId: this.serviceForm.value.selectedBank,
  //     isActive: this.serviceForm.value.isActive
  //   };
  //   console.log("Payload", payload)
  //   this.service.createRouteConfig(payload).subscribe(successResponse => {

  //     if(successResponse.id){
  //       $('#createModel').modal('hide');
  //       this.toastr.success("Seccessfully: You have created Id",successResponse.id)
  //       this.getRoutesList();
  //     }else {
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });

  // }

  // viewRountesConfig(data){

  //   let payload: any = {
  //     routeId: data.routeId,
  //   };

  //   this.service.getRouteInfo({payload: payload}).subscribe(successResponse => {
  //     if(successResponse.records){
  //       var responseObj = JSON.stringify(successResponse.records);
  //       var data = JSON.parse(responseObj);
  //       // var records = JSON.parse(data);
  //       this.getForm = this.fb.group({
  //         routeId: [data.routeId, Validators.required ],
  //         routePrefix: [data.routePrefix, Validators.required ],
  //         isActive: [data.isActive, Validators.required ],
  //         selectedBank: [data.bankId, Validators.required ],
  //         currencyCode: [data.currencyCode, Validators.required ],
  //         currencyDescription: [data.currencyDescription, Validators.required ],
  //       });
  //       // console.log("kkkk", data)
  //     }else {
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
    
    
  // }

  // updateRoutesConfig(){
  //   this.submitted = true;
 
  //   if (this.getForm.invalid) {
  //       return;
  //   }
  //   var currentUser = this._sessionStorageService.getSessionData();
  //   let payload: any = {
  //     doneByUserId: currentUser.userId,
  //     routeId: this.getForm.value.routeId,
  //     isActive: this.getForm.value.isActive,
  //     routePrefix: this.getForm.value.routePrefix,
  //     bankId: this.getForm.value.selectedBank,
  //     currencyCode: this.getForm.value.currencyCode,
  //     currencyDescription: this.getForm.value.currencyDescription
  //   };
  //   // console.log("Payload", payload)
  //   this.service.updateWebPermissionInfo(payload).subscribe(successResponse => {

  //     if(successResponse.id){
  //       $('#viewModel').modal('hide');
  //       this.toastr.success("Seccessfully: You have created Id",successResponse.id)
  //       this.getRoutesList()
  //     }else {
  //       this.toastr.error("Something went wrong")
  //     }
  //   }, (failureResponse: any) => {
  //     if(failureResponse.status === 401){
  //       window.location.href = 'login';
  //     }else{
  //       console.log("onFailure:" + JSON.stringify(failureResponse));
  //     }
  //   });
  // }


}
