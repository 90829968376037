import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
declare var $: any;
import { Location } from '@angular/common';
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { Router, ActivatedRoute } from '@angular/router';
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-cashier-table',
  templateUrl: './cashier-table.component.html',
  styleUrls: ['./cashier-table.component.css'],
  animations: [
    fade
  ]
})
export class CashierTableComponent implements OnInit {
  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_CASHIER = 40;
  _CASHIERTYPEID_POS = 1;
  _CASHIERTYPEID_STORE = 2;
  _CASHIERTYPEID_APIUSERS = 3;
  dataList;
  serviceForm: FormGroup;
  updateAccessForm: FormGroup;
  submitted = false;
  modal;
  merchantUid;
  merchantName;
  merchantId;
  queryMerchantId;
  data;
  listBody: boolean;
  message;
  isThirtParty: boolean = false;
  accessProfileId;
  selectAccess;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private _sessionStorageService: SessionStorageService
  ) {

    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.queryMerchantId = params['merchantId'];
        this.merchantId = this.queryMerchantId;
        this.getMerchantInfo(this.merchantId);
      }else{ 
        _location.back();
      }
    });
    this.updatedAccessForm();
  }

  updatedAccessForm(){
    this.updateAccessForm = this.fb.group({
      selectedAccess: ['', Validators.required],
    });
  }


  ngOnInit(): void {
    // this.getCashierUserLists();
  }

  getUpdateInfo(data) {
    this.accessProfileId = data.accessProfileId;
    let payload: any = {};
    this.spinner.getSpinner();
      this.service.getUserAccessProfileStatus({ payload: payload }).subscribe(successResponse => {
        this.spinner.hideSpinner();
        let responseObj = JSON.stringify(successResponse.records);
        let data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        // console.log("data :=> ", records);
        this.selectAccess = records;
        // this.selectedAccessProfileValue = selectedRecord.profileStatusId;
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  onUpdateAccessProfileInfo() {
    this.submitted = true;
    // stop here if form is invalid
    var currentUser = this._sessionStorageService.getSessionData();
    if (this.updateAccessForm.invalid) {
      return;
    }
    let payload: any = {
      accessProfileId: this.accessProfileId,
      profileStatusId: this.updateAccessForm.value.selectedAccess
    };
    // console.log("jajaHHH", payload)
    this.spinner.getSpinner();
    this.service.updateAccessProfile({ payload: payload }).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      if(data.accessProfileId){
        this.toastr.success(`You have updated to ${data.profileStatusDesc}`, 'Successfully')
        $('#updateAccessModel').modal('hide');
        this.spinner.hideSpinner();
        this.getMerchantInfo(this.merchantId);
      }else{
        this.spinner.hideSpinner();
        this.toastr.error('InComing request is wrong', 'Error')
      }
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }

  getMerchantInfo(merchantId){
    let currentUser = this._sessionStorageService.getSessionData();
    this.listBody = false;
    let payload: any = {
      userId: currentUser.userId,
      merchantId: merchantId

    };
    this.spinner.getSpinner();
    this.service.getMerchantInfo({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.spinner.hideSpinner();
        this.data = data;
        this.merchantUid = data.merchantUid;
        this.merchantName = data.firstName + " " + data.middleName + " " + data.lastName;
        this.getCashierUserLists(data.merchantId);
        if(data.merchantTypeId == 1){
          this.isThirtParty = true;
        }else {
          this.isThirtParty = false;
        }
        console.log("kkkk", data)
      }else {
        this.toastr.error("Something went wrong")
        this.spinner.hideSpinner();
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  passMerchantCashiersView(){
    this.submitted = true;
 
    if (this.serviceForm.invalid) {
        return;
    }
  }

  

  getCashierUserLists(merchantId){
    let userTypeId = 0;
    let payload: any = {
      merchantId: merchantId

    };
    this.spinner.getSpinner();
    this.service.getMerchantCashiers({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        if(!successResponse.records.message){
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.dataList =records;
          // console.log("kkkk", records)
        }else{
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }
        
      }else {
        this.listBody = false;
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }


  viewPosInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_POS) {
      this.posListBody = true;
      return true;
    }
    this.listBody = true;
    this.message = "Data Not Found";
    return false;
  }

  viewWebStoreInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_STORE) {
      this.webStoreListBody = true;
      return true;
    }
    this.listBody = true;
    this.message = "Data Not Found";
    return false;
  }

  viewApiUsersInfo = function (x) {
    if (x.cashierTypeId == this._CASHIERTYPEID_APIUSERS) {
      this.apiListBody = true;
      return true;
    }
    return false;
  }


  OnCreateCashierUser(){
    let merchantId = this.data.merchantId;
    let merchantUid = this.data.merchantUid;
    this.router.navigate(["dashboard/view/createCashierInfo"], { queryParams: { merchantId, merchantUid}});
  }
  OnViewCashierUser(x){
    let cashierId = x.cashierId;
    this.router.navigate(["dashboard/view/getCashierInfo"], { queryParams: { cashierId }});
  }
  OnViewReSendCredentials(x){
    let cashierId = x.cashierId;
    this.router.navigate(["dashboard/view/getReSendCredentials"], { queryParams: { cashierId }});
  }


}
