import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    private spinner: SpinnerService
    ) { }

  ngOnInit(): void {
    // this.spinner.getSpinner();
    // // spinner.getSpinner(this.spinner)
    // setTimeout(() => {
    //   this.spinner.hideSpinner();
    //   // spinner.hideSpinner(this.spinner);
    // },1000)
  }

}
