<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-11">
                        <h4 class="card-title">Process Distributor Commission</h4>
                    </div>
                    <div class="col-md-1">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backtowebrole()">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" novalidate  (ngSubmit)="OnCreateRoutesConfig()">
                            <div class="card-subtitle">Distributor Commission</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px;"></div>
                            <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Amount</label>
                                            <input type="number" class="form-control"  formControlName="amount" placeholder="Amount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.amount.errors }">
                                            <div *ngIf="submitted && serviceForm.controls.amount.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.amount.errors.required">Amount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="isActive">Remarks</label>
                                            <input type="text" class="form-control"  formControlName="remarks" placeholder="Remarks"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.remarks.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.remarks.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.remarks.errors.required">Remarks is required</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="isActive">GL Accounts</label>
                                            <select class="form-control" formControlName="selectedDistributorValue" 
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectedDistributorValue.errors }">
                                                <option *ngFor="let p of selectDistributorData" [ngValue]="p" [ngClass]="{'is-invalid': isDistributor }">{{p.accountDesc}}</option>
                                            </select>
                                            <div *ngIf="submitted && serviceForm.controls.selectedDistributorValue.errors" class="text-danger">
                                                <div *ngIf="serviceForm.controls.selectedDistributorValue.errors.required">GL Accounts is required</div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Process</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
