<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-10">
            <!-- <h4 class="card-title">{{ Title }}</h4> -->
          </div>

          <div class="col-md-2">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                class="btn btn-primary"
                (click)="backToService()"
              >
                Back
              </button>
            </h4>
          </div>
        </div>

        <div id="section-one">
          <h4 class="text-center">Card Summary By Month Report</h4>
          <!-- <p class="text-center">
            {{ selectedDate | date : "YYYY-MM-dd" }}
          </p> -->
          <p class="text-center">
            {{ startDate | date : "yyyy-MM-dd" }} -
            {{ endDate | date : "yyyy-MM-dd" }}
          </p>
        </div>

        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-3">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control-sm mb-2"
                [(ngModel)]="term"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                (click)="print()"
                type="button"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="fnPDF()"
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="downloadCSV()"
                type="submit"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>

        <div class="table-responsive" id="section-two">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Company</th>
                <th>Month</th>
                <th>Total</th>
                <th>Active</th>
                <th>Inactive</th>
                <th>Printed</th>
                <th>Requested</th>

                <th>NotRequested</th>
                <th>Topups</th>
                <th>Withdrawals</th>
                <th>Purchase</th>
                <th>Charge</th>
                <th>Markup</th>
                <th>Digital Fee</th>
                <th>Plastic Fee</th>
              </tr>
            </thead>
            <tbody *ngIf="listBody">
              <tr
                *ngFor="
                  let x of listData
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <td>{{ x.institutionName }}</td>
                <td>{{ x.month }}</td>
                <td>{{ x.totalCards }}</td>
                <td>{{ x.active }}</td>
                <td>{{ x.isBlocked }}</td>
                <td>{{ x.printed }}</td>
                <td>{{ x.requested }}</td>
                <td>{{ x.notRequested }}</td>
                <td>{{ formatTransactionAmount(x.topups) }}</td>
                <td>{{ formatTransactionAmount(x.withdrawals) }}</td>
                <td>{{ formatTransactionAmount(x.purchase) }}</td>
                <td>{{ formatTransactionAmount(x.charge) }}</td>
                <td>{{ formatTransactionAmount(x.markupAmount) }}</td>
                <td>{{ formatTransactionAmount(x.digitalFee) }}</td>
                <td>{{ formatTransactionAmount(x.plasticFee) }}</td>
              </tr>
              <!-- <tr style="background-color: #09df66be">
                <td>Total:</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ sumSettlementAmount | number : "1.2-2" }}</td>
                <td>{{ sumTransactionAmount | number : "1.2-2" }}</td>
                <td>{{ sumInterchangeAmount | number : "1.2-2" }}</td>
                <td></td>
                <td></td>
              </tr> -->
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%" class="d-print-none">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="4000">4000</option>
                <option value="6000">6000</option>
                <option value="8000">8000</option>
                <option value="10000">10000</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container d-print-none">
            <div style="text-align: center; padding: 24px">{{ message }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
