import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { ServiceService } from "src/app/service/service/service.service";
import { SpinnerService } from 'src/app/service/service/spinner.service';
import { SessionStorageService } from "src/app/service/session-storage/session-storage.service";
declare var $: any;

@Component({
  selector: 'app-get-settlement-summary-report.component',
  templateUrl: './get-settlement-summary-report.component.html',
  styleUrls: ['./get-settlement-summary-report.component.css'],
  animations: [
    fade
  ]
})

export class GetSettlementSummaryReportComponent implements OnInit {

  _USER_TYPE_ID_MERCHANT = 30;
  _USER_TYPE_ID_DISTRIBUTOR = 20;
  dataList;
  listData;
  listBody: boolean;
  serviceForm: FormGroup;
  updatesForm: FormGroup;
  updateAccessForm: FormGroup;
  updateCycleForm: FormGroup;
  updateSubscriptionForm: FormGroup;
  getForm: FormGroup;
  submitted = false;
  getSubmitted = false;
  message;
  modal;
  selectData: any;
  selectProductData;
  distributorId;
  productId;
  isTrue: boolean = false;
  isThirtParty: boolean = false;
  selectAccess;
  accessProfileId;
  selectSubscription;
  subscriptionAutoRenewal;
  merchantId;
  userTypeId;
  queryuserTypeId: any;
  queryMerchantTypeId: any;
  sub: any;
  selectCycle;
  Title;
  lisCustomers;
  ListOfMethods;
  currentRecordId;
  currentCardId;
  selected = 1000;
  // selected = pagination.selected;
  p: number = pagination.p;
  term;
  currentMobileNumber;
  numberOfdays;
  showForm: string = 'true';
  institutionId;
  stateList;
  TranType;
  distdataList;
  sortDirection: boolean = true;
  currentSortField: string;
  endDate;
  startDate
  sumInterchangeAmount = null;
  sumSettlementUsd = null;
  sumSettlementEur = null;

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    // this.showForm = true
    // this.getDistributorList();
    this.getFrom();

    this.TranType = [
      { "id": "%", "name": "All" },
      { "id": "DEDUCT", "name": "DEDUCT" },
      { "id": "LOAD", "name": "LOAD" },
      { "id": "REVERSAL", "name": "REVERSAL" },
    ]
  }
  getFrom() {
    const currentDate = new Date().toISOString().split('T')[0];
    this.getForm = this.fb.group({
      // selectDistributor: ['', Validators.required],
      SelectedTranType: ['', Validators.required],
      startDate: [currentDate, Validators.required],
      endDate: [currentDate, Validators.required],
    });
  }

  getDistributorList() {
    let currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      userTypeId: this._USER_TYPE_ID_DISTRIBUTOR

    };
    this.spinner.getSpinner();
    this.service.getAdminDistributorListByGrouping({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.distdataList = records;
        // console.log("kkkk", records)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  ngOnInit(): void {
    this.Title = "Settlement Summary Report"
    if (this.queryMerchantTypeId == 1) {
      this.isThirtParty = true;
    } else {
      this.isThirtParty = false;

    }
  }

  backToService() {
    // this._location.back();
    this.showForm = "true"
    this.listData = []
    this.getFrom()

  }
  formatTransactionAmount(amount) {
    if (amount === null || amount === undefined || amount === '') {
      return '0.00';
    }
    return amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  getSettlementSummaryReport() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    // institutionId
    // const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      // institutionId: institutionId,
      pTranType: this.getForm.value.SelectedTranType,
      startDate: this.getForm.value.startDate,
      endDate: this.getForm.value.endDate,
    };
    this.startDate = this.getForm.value.startDate
    this.endDate = this.getForm.value.endDate
    this.spinner.getSpinner();
    this.service.getSettlementSummaryReport({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.showForm = 'false'
          this.listData = records;
          records.forEach((el, index, arry) => {
            let record = {};
            this.sumSettlementEur += el.settlementEur;
            this.sumSettlementUsd += el.settlementUsd;
            this.sumInterchangeAmount += el.interchangeAmount;

          });

        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
          this.toastr.warning(this.message)
        }

      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }
  handleSubmit() {
    this.submitted = true
    if (this.getForm.invalid) {
      return;
    }
    const currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      distributorId: this.getForm.value.selectDistributor,
    };
    this.spinner.getSpinner();
    this.service.getInstitution({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        // this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        let institutionId = data.institutionId

        // this.getSettlementSummaryReport(institutionId)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error(successResponse.records.message)
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  SelectedRecodrd(data) {
    this.currentRecordId = data.recordId;
    this.currentCardId = data.cardId;
    this.currentMobileNumber = data.mobileNumber;
  }

  sortData(sortField: string) {
    if (this.currentSortField === sortField) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortDirection = true;
    }
    this.currentSortField = sortField;

    this.listData.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return this.sortDirection ? -1 : 1;
      } else if (a[sortField] > b[sortField]) {
        return this.sortDirection ? 1 : -1;
      } else {
        return 0;
      }
    });
  }
}
