<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">
    <li class="nav-item active">
      <a class="nav-link" routerLink="/dashboard">
        <i class="icon-grid menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </a>
    </li>
    <li class="nav-item" *ngFor="let menuItem of menuData">
      <a
        class="nav-link"
        data-toggle="collapse"
        attr.data-target="#toggle{{ menuItem.parentMenuName | removeSpaces }}"
        aria-expanded="false"
        aria-controls="ui-basic"
      >
        <i
          *ngIf="menuItem.parentMenuName == 'Settings'"
          class="ti-settings menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'User Management'"
          class="ti-user menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Vendor Management'"
          class="ti-wallet menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Transactions'"
          class="ti-bar-chart menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Product Management'"
          class="ti-bag menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Settlement Management'"
          class="ti-credit-card menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Merchant Management'"
          class="ti-bookmark-alt menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Payment Provider Invoices'"
          class="ti-money menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Reports'"
          class="ti-receipt menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Terminal Management'"
          class="ti-layout-menu-v menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Event Logs'"
          class="ti-ticket menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Card Management'"
          class="ti-credit-card menu-icon"
        ></i>
        <i
          *ngIf="menuItem.parentMenuName == 'Card Reports'"
          class="ti-receipt menu-icon"
        ></i>
        <!-- <i class="icon-layout menu-icon"></i> -->
        <span class="menu-title" style="cursor: pointer">{{
          menuItem.parentMenuName
        }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div
        class="collapse"
        id="toggle{{ menuItem.parentMenuName | removeSpaces }}"
      >
        <ul class="nav flex-column sub-menu">
          <li class="nav-item" *ngFor="let menuChildItem of menuItem.childObj">
            <a class="nav-link" routerLink="{{ menuChildItem.childMenuUrl }}">{{
              menuChildItem.childMenuName
            }}</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="https://www.waafipay.net" target="_blank">
        <i class="icon-paper menu-icon"></i>
        <span class="menu-title">Documentation</span>
      </a>
    </li>
  </ul>
</nav>
