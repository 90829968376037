<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-11">
                        <h4 class="card-title">ChargeBack Process</h4>
                    </div>
                    <div class="col-md-1">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                             (click)="backtowebrole()">Back</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" (ngSubmit)="OnCreateChargeBack()">
                            <div class="card-subtitle">ChargeBack</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Case ID</label>
                                        <input type="text" class="form-control" id="referenceId" formControlName="referenceId" placeholder="Case ID"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.referenceId.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.referenceId.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.referenceId.errors.required">Case ID is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Reported Date</label>
                                        <input type="date" class="form-control" id="reportedDate" formControlName="reportedDate" placeholder="Reported Date"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.reportedDate.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.reportedDate.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.reportedDate.errors.required">Reported Date is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Remarks</label>
                                        <input type="text" class="form-control" id="remarks" formControlName="remarks" placeholder="Remarks"
                                        [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.remarks.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.remarks.errors" class="text-danger">
                                            <div *ngIf="serviceForm.controls.remarks.errors.required">Remarks is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>                     
                            <!-- <button type="submit" class="btn btn-primary mr-2">Submit</button> -->
                            <!-- <button class="btn btn-light" data-dismiss="modal">Cancel</button> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
