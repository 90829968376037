import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
declare var $: any;
import { ServiceService } from "../../../../service/service/service.service";
import { SessionStorageService } from "../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-event-logs',
  templateUrl: './event-logs.component.html',
  styleUrls: ['./event-logs.component.css'],
  animations: [
    fade
  ]
})
export class EventLogsComponent implements OnInit {
  sysForm: FormGroup;
  serviceForm: FormGroup;
  submitted = false;
  modal;
  dataList;
  listBody: boolean;
  message;

  selected = pagination.selected;
  p: number = pagination.p;
  term;
  

  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
  }


  ngOnInit(): void {
    this.getListEventLogs();
  }

  getListEventLogs(){
    let payload: any = {
    };
    this.spinner.getSpinner();
    this.service.getEventLogList({payload: payload}).subscribe(successResponse => {
        if (!successResponse.records.message) {
          this.spinner.hideSpinner();
          this.listBody = true;
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          this.dataList = records;
          // console.log("kkkk", records)
        } else {
          this.spinner.hideSpinner();
          this.listBody = false;
          this.message = successResponse.records.message;
          console.log(successResponse.records.message)
        }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  

  passRoleIdValue(user) {
    let userId = user.doneByUserId;
    this.router.navigate(['dashboard/assignUserRoles'], { queryParams: { userId } });
  }
  passUserInfo(user) {
    let userId = user.doneByUserId;
    let eLogId = user.logEventId;
    this.router.navigate(['dashboard/view/eventLogInfo'], { queryParams: { userId, eLogId } });
  }

}
