import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service/service/service.service';
import { ExpressRequestService } from 'src/app/service/express-request/express-request.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { environment } from 'src/assets/config/environment';
import { PasswordValidators } from "./password-validators";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  tab = environment.tab;
  serviceForm: FormGroup;
  submitted = false;
  message;
  sub: any;
  loginId;
  record;

  constructor(
    private service: ServiceService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private _route: ActivatedRoute,
    private expressService: ExpressRequestService

  ) {
    this.sub = this._route
    .queryParams
    .subscribe(params => {
      this.loginId = this.expressService.decryptData(expressService.decoded(params['cid']));
      console.log("ID ==> ", this.loginId)
    });
    // this.createForm();
  

  }


  isWorking = false;

  signupForm = new FormGroup(
    {
      // currentPassword: new FormControl(null, [Validators.required]),
      password: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          PasswordValidators.patternValidator(new RegExp("(?=.*[0-9])"), {
            requiresDigit: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[A-Z])"), {
            requiresUppercase: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[a-z])"), {
            requiresLowercase: true
          }),
          PasswordValidators.patternValidator(new RegExp("(?=.*[$@^!%*?&])"), {
            requiresSpecialChars: true
          })
        ])
      ),
      confirmPassword: new FormControl(null, [
        Validators.required,
        Validators.minLength(8)
      ])
    },
    {
      validators: PasswordValidators.MatchValidator
    }
  );

  get f() {
    return this.signupForm.controls;
  }

  get passwordValid() {
    return this.signupForm.controls["password"].errors === null;
  }

  get requiredValid() {
    return !this.signupForm.controls["password"].hasError("required");
  }

  get minLengthValid() {
    return !this.signupForm.controls["password"].hasError("minlength");
  }

  get requiresDigitValid() {
    return !this.signupForm.controls["password"].hasError("requiresDigit");
  }

  get requiresUppercaseValid() {
    return !this.signupForm.controls["password"].hasError("requiresUppercase");
  }

  get requiresLowercaseValid() {
    return !this.signupForm.controls["password"].hasError("requiresLowercase");
  }

  get requiresSpecialCharsValid() {
    return !this.signupForm.controls["password"].hasError("requiresSpecialChars");
  }



  // createForm() {
  //   this.serviceForm = this.fb.group({
  //     password: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]],
  //     confirm: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(25)]],
  //   }, { validator: this.checkIfMatchingPasswords('password', 'confirm') });
  // }
  // checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  //   return (group: FormGroup) => {
  //     let passwordInput = group.controls[passwordKey],
  //       passwordConfirmationInput = group.controls[passwordConfirmationKey];
  //     if (passwordInput.value !== passwordConfirmationInput.value) {
  //       return passwordConfirmationInput.setErrors({ notEquivalent: true })
  //     }
  //     else {
  //       return passwordConfirmationInput.setErrors(null);
  //     }
  //   }
  // }
  ngOnInit(): void {
  }

  resetMerchantPassword() {
    this.submitted = true;
    if (this.signupForm.invalid) return;

    this.message = null;
    console.log("888 => ", this.record)
    if (this.record) {
      let payload = {
        loginId: this.record.loginId,
        accessProfileId: this.record.accessProfileId,
        profileStatusId: 4,
        password: this.signupForm.value.password
      }
      console.log("payload ", payload);
      this.service.isActive(payload).subscribe(res => {
        if (res.success) {
          this.toastr.success(res.message);
          // console.log("RESULT=>", res)
          window.location.replace('login');
        } else {
          this.toastr.warning(res.message)
        }
        console.log("response => ", res);
      });
    } else {
      if (this.record) {
        var payload = {
          loginId: this.record.loginId,
          password: this.signupForm.value.password
        }
      } else {
        var payload = {
          loginId: this.loginId,
          password: this.signupForm.value.password
        }
      }
      console.log("payload ", payload);
      this.service.resetAdminPasswordUser(payload).subscribe(res => {
        if (res.success === true) {
          this.toastr.success(res.message);
          this.router.navigate(['login']);
        } else {
          this.toastr.warning(res.message)
        }
        console.log("response => ", res);
      });
    }


  }
}
