<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">View Service Rate Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backToService()">Back to Service</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="serviceForm" (ngSubmit)="OnUpdateServiceRate()">
                            <div class="card-subtitle">Service Rate Info</div>
                            <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Sender Rate</label>
                                        <input type="text" class="form-control" formControlName="senderRate"
                                            placeholder="Sender Rate"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.senderRate.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.senderRate.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.senderRate.errors.required">Sender Rate is
                                                required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="selectSenderRateTypeValue">Sender Type</label>
                                        <select class="form-control" formControlName="selectSenderRateTypeValue"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectSenderRateTypeValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectSenderRateTypeData" [ngValue]="p.rateTypeId">
                                                {{p.rateTypeDesc}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.selectSenderRateTypeValue.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.selectSenderRateTypeValue.errors.required">
                                                Sender Type is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="selectRecieverRateTypeValue">Reciever Type</label>
                                        <select class="form-control" formControlName="selectRecieverRateTypeValue"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.selectRecieverRateTypeValue.errors }">
                                            <option value=null disabled selected>--Select--</option>
                                            <option *ngFor="let p of selectRecieverRateTypeData" [ngValue]="p.rateTypeId">{{p.rateTypeDesc}}</option>
                                        </select>
                                        <div *ngIf="submitted && serviceForm.controls.selectRecieverRateTypeValue.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.selectRecieverRateTypeValue.errors.required">
                                                Reciever Type is required</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Min Tran Amount</label>
                                        <input type="text" class="form-control" formControlName="minTxAmount"
                                            placeholder="Min Tran Amount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.minTxAmount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.minTxAmount.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.minTxAmount.errors.required">Min Tran Amount is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Max Tran Amount</label>
                                        <input type="text" class="form-control" formControlName="maxTxAmount"
                                            placeholder="Max Tran Amount"
                                            [ngClass]="{ 'is-invalid': submitted && serviceForm.controls.maxTxAmount.errors }">
                                        <div *ngIf="submitted && serviceForm.controls.maxTxAmount.errors"
                                            class="text-danger">
                                            <div *ngIf="serviceForm.controls.maxTxAmount.errors.required">Max Tran Amount is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col text-center">
                                    <button type="submit" class="btn btn-primary" style="width: 30%;">Submit</button>
                                    <!-- <button class="btn btn-default">Centered button</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>