import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { ServiceService } from "../../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { pagination } from 'src/app/service/service/pagination';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-get-chargebacks-list',
  templateUrl: './get-chargebacks-list.component.html',
  styleUrls: ['./get-chargebacks-list.component.css'],
  animations: [
    fade
  ]
})
export class GetChargebacksListComponent implements OnInit {

  payerId;
  queryPayerId;
  currentDate;
  queryCurrentDate;

  listData;
  listBody: boolean;
  message;
  data;
  isTrue = false;

  selected = pagination.selected;
  p: number = pagination.p;
  term;

  constructor(
    private _location: Location,
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.queryPayerId = params['payerId'];
        this.queryCurrentDate = params['currentDate'];
        this.payerId = atob(decodeURIComponent(this.queryPayerId))
        this.currentDate = atob(decodeURIComponent(this.queryCurrentDate));
        this.getChargeBackList(this.payerId, this.currentDate);
      } else {
        _location.back();
      }
    });
  }

  ngOnInit(): void {
  }

  backToService() {
    this._location.back();
  }

  getChargeBackList(payerId, currentDate) {
    var currentUser = this._sessionStorageService.getSessionData();
    let payload: any = {
      userId: currentUser.userId,
      payerId: payerId,
      createdDate: currentDate,
    };

    this.spinner.getSpinner();
    this.service.getMerchantTranChargeback({ payload: payload }).subscribe(successResponse => {
      // console.log("msg: ", successResponse)
      var responseObj = JSON.stringify(successResponse.records);
      var data = JSON.parse(responseObj);
      this.data = data.message;
      if (!successResponse.records.message) {
        this.listBody = true;
      }
      if (successResponse.success) {
        this.spinner.hideSpinner();
        var records = JSON.parse(data);
        // console.log("All ", records)
        this.listData = records;
        this.listBody = true;
        this.isTrue = false;
      } else {
        this.spinner.hideSpinner();
        this.listBody = false;
        this.listData = null;
        this.isTrue = true;
        this.message = data.message;
      };
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        // this.failureMessage = true;
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });

  }

  passChargeBack(x) {
    // console.log("LLL=>", x)
    let am = encodeURIComponent(btoa(x.amount))
    let tr = encodeURIComponent(btoa(x.tranId))
    let pa = encodeURIComponent(btoa(x.payerId))
    this.router.navigate(['dashboard/view/chargebacks'], { queryParams: { am, tr, pa } });
  }
}
