<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h4 class="card-title">Settlement Invoice</h4>
                    </div>
                    <div class="col-md-4">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary ml-2" data-toggle="modal"
                                data-target="#sysConfigModel" (click)="backToService()">Back To Invoice</button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" style="max-height: 45px;"
                                (click)="onPrintInvoice('section-one')">
                                <i class="material-icons">&#xe555;</i>
                            </button>
                        </h4>
                    </div>
                </div>
                <!-- <div class="container"> -->
                <div class="row" id="section-one">
                    <div class="col-md-12 col-md-offset-3 body-main">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img class="img" alt="Invoce Template"
                                                src="assets/images/{{logoInvoice}}" />
                                        </div>
                                        <div class="col-md-8 text-right">
                                            <h4 style="color: #009e44; margin-top: 40px;"><strong>Waafi Payment</strong></h4>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <h3>INVOICE</h3>
                                            <h5><strong style="color: #009e44;">Attention to:</strong>&nbsp;
                                                {{paymentTo}}</h5>
                                            <h5><strong style="color: #009e44;">Due Date:</strong>&nbsp; {{dueDate |
                                                date:'dd-MMM-YYYY'}}</h5>
                                        </div>
                                        <div class="col-md-8 text-right">

                                            <p>Howlwadag ,Bakara Street</p>
                                            <p>+252615414470</p>
                                            <p>info@waafipay.com</p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <!-- <div class="row">
                                    <div class="col-md-12">
                                        <h5><strong style="color: #009e44;">Attention to:</strong>&nbsp; {{paymentTo}}</h5>
                                        <h5><strong style="color: #009e44;">Due Date:</strong>&nbsp; {{dueDate | date:'dd-MMM-YYYY'}}</h5>
                                    </div>
                                </div> -->
                            <div style="margin-top: 200px;">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <h5>InvoceNo:</h5>
                                            </th>
                                            <th>
                                                <h5>Description</h5>
                                            </th>
                                            <th>
                                                <h5>Payment Terms</h5>
                                            </th>
                                            <th>
                                                <h5>Amount</h5>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="col-md-1">IVNO10001</td>
                                            <td class="col-md-4">{{description}}</td>
                                            <td class="col-md-4">{{paymentTerms}} Net Days</td>
                                            <td class="col-md-1"><i class="fas fa-rupee-sign" area-hidden="true"></i>
                                                {{invoiceAmount}} </td>
                                        </tr>
                                        <tr>
                                            <td class="col-md-1 text-center">
                                            </td>
                                            <td class="col-md-1 text-center">
                                            </td>
                                            <td class="col-md-10 text-right">
                                                <!-- <p> <strong>Payment Gateway Charges:</strong> </p> -->
                                                <p> <strong>Payable Amount: </strong> </p>
                                            </td>
                                            <td>
                                                <!-- <p> <strong><i class="fas fa-rupee-sign" area-hidden="true"></i> 0 </strong> </p> -->
                                                <p> <strong><i class="fas fa-rupee-sign" area-hidden="true"></i>
                                                        {{invoiceAmount}} </strong> </p>
                                            </td>
                                        </tr>
                                        <tr style="color: #009e44;">
                                            <td class="text-right">
                                            </td>
                                            <td class="text-right">
                                            </td>
                                            <td class="text-right">
                                                <h4><strong>Total:</strong></h4>
                                            </td>
                                            <td class="text-left">
                                                <h4><strong><i class="fas fa-rupee-sign" area-hidden="true"></i>
                                                        {{invoiceAmount}} </strong></h4>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <div class="col-md-12">
                                    <p><b>Created Date :</b> {{createdDate | date:'dd-MMM-YYYY'}}</p> <br />
                                    <p><b>Signature</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->

            </div>
        </div>
    </div>
</div>