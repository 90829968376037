<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-9">
                        <h4 class="card-title">Merchant Payment Providor List</h4>
                    </div>
                    <div class="col-md-3">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back</button>
                        </h4>
                        <h4 class="card-title mr-2" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="createPaymentMethod()">Add Payment</button>
                        </h4>
                    </div>
                    <!-- <div class="col-md-2 m-0">
                    </div> -->
                </div>
                <div class="row" style="margin-bottom: 0px;">
                    <div class="col-md-3">
                        <input type="text" class="form-control-sm" [(ngModel)]="term"
                            placeholder="Search here..." style="float: left;">
                    </div>
                    <div class="col-md-9">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-print" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-pdf-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                        <h4 class="card-title" style="float: right;">
                            <button type="submit"
                                style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                                class="btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true" style="color: green;"></i>
                            </button>
                        </h4>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table-striped display expandable-table" style="width:100%">
                        <thead>
                            <tr>
                                <th>Merchant Name</th>
                                <th>Seq Id</th>
                                <th>MerchantUid</th>
                                <th>PaymentProcessorId</th>
                                <th>PaymentProcessorDesc</th>
                                <th>CreatedByUserId</th>
                                <th>CreatedDate</th>
                                <th>Allow | Disallow</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let x of listData | filter:term | paginate: { itemsPerPage: selected, currentPage: p }">
                            <tr style="background: rgba(183, 253, 213, 0.445);">
                                <th style="font-size: 30px;">{{x.merchantName}}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let p of x.records">
                                <td></td>
                                <td>{{ p.seqId}}</td>
                                <td>{{ p.merchantUid}}</td>
                                <td>{{ p.paymentProcessorTypeId}}</td>
                                <td>{{ p.description}}</td>
                                <td>{{ p.createdByUserId}}</td>
                                <td>{{ p.createdDate | date:'dd-MMM-yy - HH:mm:ss'}}</td>
                                <td style="justify-content: space-evenly;">
                                    <a *ngIf="p.isEnabled == 0" type="button" data-toggle="modal"
                                    data-target="#allowModel" class="btn btn-success btn-sm" (click)="fnGetInfo(p)">allow</a>
                                    <div class="modal fade" id="allowModel" tabindex="-1"
                                        aria-labelledby="allowModelLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" style="color: orange;">Warning</h5>
                                                    <a type="button" data-dismiss="modal" aria-label="Close">X</a>
                                                </div>
                                                <div class="modal-body">
                                                   <p> Are you sure you want to Allow this payment method?</p>
                                                   <form class="forms-sample" [formGroup]="resonForm" novalidate (ngSubmit)="fnAllowPayment()">
                                                    <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="exampleInputUsername1">Reson</label>
                                                                <input type="text" class="form-control" formControlName="reson"
                                                                    placeholder="Reson" 
                                                                    [ngClass]="{ 'is-invalid': submitted && resonForm.controls.reson.errors }">
                                                                <div *ngIf="submitted && resonForm.controls.reson.errors" class="text-danger">
                                                                    <div *ngIf="resonForm.controls.reson.errors.required">Resonis required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light"
                                                            data-dismiss="modal">Close</button>
                                                        <button type="submit" class="btn btn-primary">Allow</button>
                                                    </div>
                                                </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a *ngIf="p.isEnabled == 1" type="button" data-toggle="modal" data-target="#disAllowtModel" (click)="fnGetInfo(p)" class="btn btn-danger btn-sm">disallow</a>
                                    <div class="modal fade" id="disAllowtModel" tabindex="-1"
                                        aria-labelledby="disAllowtModelLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" style="color: orange;">Warning</h5>
                                                    <a type="button" data-dismiss="modal" aria-label="Close">X</a>
                                                </div>
                                                <div class="modal-body">
                                                    Are you sure you want to Disallow this payment method?
                                                    <form class="forms-sample" [formGroup]="resonForm" novalidate (ngSubmit)="fnDisAllowPayment()">
                                                        <div style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="exampleInputUsername1">Reson</label>
                                                                    <input type="text" class="form-control" formControlName="reson"
                                                                        placeholder="Reson" 
                                                                        [ngClass]="{ 'is-invalid': submitted && resonForm.controls.reson.errors }">
                                                                    <div *ngIf="submitted && resonForm.controls.reson.errors" class="text-danger">
                                                                        <div *ngIf="resonForm.controls.reson.errors.required">Resonis required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-light"
                                                                data-dismiss="modal">Close</button>
                                                            <button type="submit" class="btn btn-primary">Disallow</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%; margin-top: 1%;">
                        <h4 class="card-title" style="float: left;">
                            <select class="form-control-sm" [(ngModel)]="selected">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </h4>
                        <pagination-controls style="float: right;" (pageChange)="p = $event"></pagination-controls>
                    </div>
                    <div *ngIf='!listBody' class="container">
                        <div style="text-align: center; padding: 24px;">
                            {{message}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>