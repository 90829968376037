import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

import { ExpressRequestService } from '../../service/express-request/express-request.service';
import { ServiceService } from "../../service/service/service.service";
import { SessionStorageService } from "../../service/session-storage/session-storage.service";

@Pipe({ name: "removeSpaces" })
export class RemoveSpaces implements PipeTransform {
  transform(value: string): string {
    return value ? value.replace(/\s/g, "") : value;
    // return value.replace(/\s/g, "");
  }
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(
    private service: ServiceService,
    private _sessionStorageService: SessionStorageService,
    private expressRequestService: ExpressRequestService
  ) { }
  divmenu;
  menuData: any;
  selected: any;

  isHidden: Boolean = true;



  ngOnInit() {

    if (this.checkforUserLogin()) {
      var currentUser = this._sessionStorageService.getSessionData();
      let payload: any = {
        userId: currentUser.userId
      };
      this.service.getMenu({ payload: payload }).subscribe(successResponse => {
        if (successResponse.lenght < 1) {
          console.log("Error", successResponse)
        } else {
          var responseObj = JSON.stringify(successResponse.records);
          var data = JSON.parse(responseObj);
          var records = JSON.parse(data);
          // var userRoles: any = this.expressRequestService.encryptData(records);
          // var userRolesdec: any = this.expressRequestService.decryptData(userRoles);
          this._sessionStorageService.setIUserPermissions(successResponse.records)
          this._sessionStorageService.getPermissions();
          // sessionStorage.setItem('userRoles', userRoles);
        }
        let menudata = this.fnComposeMenuObject(records);
        var validMenuData = menudata.filter(item =>
          item.menuId !== null &&
          item.menuName !== null &&
          item.parentMenuId !== null &&
          item.menuUrl !== null
        );
        this.menuData = validMenuData
      }, (failureResponse: any) => {
        if (failureResponse.status === 401) {
          window.location.href = 'login';
        } else {
          console.log("onFailure:" + JSON.stringify(failureResponse));
        }
      });
    }
  }

  checkforUserLogin() {
    let currentUser = this._sessionStorageService.getSessionData();
    if (currentUser)
      return true;
    return false;

  };

  fnComposeMenuObject(records) {
    var parentMenuObject: any = [];
    var completeMenuObj: any = [];
    if (records != null) {
      var length = records.length;
      for (var i = 0; i < length; i++) {
        var item = records[i];
        if (item.menuId != 0) {
          if (item.parentMenuId != 0) {
            var obj = {
              parentMenuId: item.parentMenuId,
              parentMenuName: item.parentMenuName,
              menuId: item.menuId
            };
            parentMenuObject.push(obj);
          }
        }
      }
      parentMenuObject = parentMenuObject.filter((parentMenuList, index, self) => self.findIndex(t => t.parentMenuId === parentMenuList.parentMenuId) === index);
      parentMenuObject.forEach((element) => {

        var childMenuObject: any = [];
        records.forEach((element1, i) => {

          if (element.parentMenuId == element1.parentMenuId) {
            var childObj: any = {};
            childObj.childMenuUrl = element1.menuUrl;
            childObj.childMenuName = element1.menuName;
            childMenuObject.push(childObj);

          }
        });

        var completeObj = {
          parentMenuId: element.parentMenuId,
          parentMenuName: element.parentMenuName,
          menuId: element.menuId,
          childObj: childMenuObject
        };

        completeMenuObj.push(completeObj)


      });
    };
    return completeMenuObj;
  }

}
