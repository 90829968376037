<div @fade class="row">
  <div class="col-lg-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <h4 class="card-title">{{ merchantTitle }}</h4>
          </div>
          <div class="col-md-9">
            <h4 class="card-title" style="float: right">
              <button
                type="submit"
                (click)="print()"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-print"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                (click)="fnPDF()"
                type="button"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-pdf-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
            <h4 class="card-title" style="float: right">
              <button
                type="button"
                (click)="exportAsXLSX()"
                style="
                  --bs-btn-padding-y: 0.25rem;
                  --bs-btn-padding-x: 0.5rem;
                  --bs-btn-font-size: 0.75rem;
                "
                class="btn"
              >
                <i
                  class="fa fa-file-excel-o"
                  aria-hidden="true"
                  style="color: green"
                ></i>
              </button>
            </h4>
          </div>
        </div>

        <div class="row" style="margin-bottom: 0px">
          <div class="col-md-2">
            <div class="form-group">
              <label for="startDate" className="text-white">Search</label>
              <input
                type="text"
                class="form-control form-control-sm"
                [(ngModel)]="term"
                aria-hidden="true"
                placeholder="Search here..."
                style="float: left"
              />
            </div>
          </div>

          <!-- <div class="col-md-1"></div> -->
          <div class="col-md-10">
            <form
              class="forms-sample"
              [formGroup]="getForm"
              novalidate
              (ngSubmit)="handleSubmit()"
            >
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="pLastFourDigit">Card Last 4 Digits </label>
                    <input
                      formControlName="pLastFourDigit"
                      type="text"
                      maxlength="4"
                      minlength="0"
                      class="form-control form-control-sm"
                      placeholder="last 4 digits of the card"
                      style="float: left"
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="cashierId">Number</label>
                    <input
                      formControlName="pNumber"
                      type="number"
                      maxlength="13"
                      minlength="0"
                      class="form-control form-control-sm"
                      placeholder="Number"
                      style="float: left"
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="transactionId">Transaction Id</label>
                    <input
                      formControlName="transactionId"
                      type="transactionId"
                      maxlength="50"
                      minlength="0"
                      class="form-control form-control-sm"
                      placeholder="transactionId"
                      style="float: left"
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="status">Select Status</label>
                    <select class="form-control" formControlName="status">
                      <option value="null" disabled selected>--Select--</option>
                      <option *ngFor="let p of statusList" [ngValue]="p.id">
                        {{ p.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="startDate">Start Date</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      formControlName="startDate"
                      placeholder="Start Date"
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="endDate">End Date</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      formControlName="endDate"
                      placeholder="End Date"
                    />
                  </div>
                </div>

                <div class="col-md">
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style="margin-top: 30px"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <!-- <div class="col-md">
                  <div class="d-flex justify-content-end"> -->
                <!-- <div class="col-md">
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-warning text-white"
                      style="margin-top: 30px"
                      (click)="getOnloadData()"
                    >
                      Clear
                    </button>
                  </div>
                </div> -->
                <div class="col-md">
                  <h4 class="card-title">
                    <button
                      type="button"
                      class="btn btn-warning text-white"
                      style="
                        background-color: #757474;
                        border-color: #585858;
                        margin-top: 30px;
                      "
                      (click)="backToService()"
                    >
                      Back
                    </button>
                  </h4>
                </div>
                <!-- </div>
                </div> -->
              </div>
            </form>
          </div>
        </div>

        <div class="table-responsive" id="section-one">
          <table
            class="table-striped display expandable-table"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Type</th>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Card</th>
                <th>Amount</th>
                <th>Fee</th>
                <th>Remarks</th>
                <th>Processor Id</th>
                <th>Date</th>
                <th class="d-print-none">State</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let x of cardTransactionList
                    | filter : term
                    | paginate : { itemsPerPage: selected, currentPage: p }
                "
              >
                <!-- <td class="py-1">{{ x.sn }}</td> -->
                <td>{{ x.transactionId }}</td>
                <td>{{ x.tranType }}</td>
                <td style="white-space: nowrap">{{ x.embossingName }}</td>
                <td>{{ x.walletReference }}</td>
                <td style="white-space: nowrap">{{ x.maskedPan }}</td>
                <td>
                  {{ x.currency }}{{ formatTransactionAmount(x.tranAmount) }}
                </td>
                <td>{{ x.currency }}{{ x.tranFee }}</td>
                <td>{{ x.remarks }}</td>
                <td>{{ x.sndTranId }}</td>
                <td style="white-space: nowrap">
                  {{ x.sndTranDate | date : "dd-MMM-yy - HH:mm:ss" }}
                </td>
                <td class="d-print-none">
                  <span
                    class="badge badge-success"
                    *ngIf="x.state == 'Completed'"
                  >
                    {{ x.state }}</span
                  >
                  <span
                    class="badge badge-warning"
                    *ngIf="x.state != 'Completed'"
                  >
                    {{ x.state }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div style="width: 100%; margin-top: 1%">
            <h4 class="card-title" style="float: left">
              <select class="form-control-sm" [(ngModel)]="selected">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </h4>
            <pagination-controls
              style="float: right"
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
          <div *ngIf="!listBody" class="container">
            <div style="text-align: center; padding: 24px">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-md"
  id="updateAccessModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Update Status Profile</h4>
              <form
                class="forms-sample"
                [formGroup]="updateAccessForm"
                (ngSubmit)="onUpdateAccessProfileInfo()"
              >
                <div class="card-subtitle">Access Profile</div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Status</label>
                  <select class="form-control" formControlName="selectedAccess">
                    <option value="null" disabled selected>--Select--</option>
                    <option
                      *ngFor="let p of selectAccess"
                      [ngValue]="p.profileStatusId"
                    >
                      {{ p.profileStatusDesc }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      updateAccessForm.controls.selectedAccess.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        updateAccessForm.controls.selectedAccess.errors.required
                      "
                    >
                      Status Profile is required
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade bd-example-modal-md"
  id="updateSubscriptionModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md-md" role="document">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Merchant Subscription</h4>
              <form
                class="forms-sample"
                [formGroup]="subscripForm"
                (ngSubmit)="onUpdateMerchantSubscriptionInfo()"
              >
                <div class="card-subtitle">Subscription Type</div>
                <div
                  style="
                    border: 1px solid #009e44;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    background: #009e44;
                  "
                ></div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Subscription</label>
                  <select
                    class="form-control"
                    formControlName="selectedSubscription"
                  >
                    <option value="null" disabled selected>--Select--</option>
                    <option
                      *ngFor="let p of selectSubscription"
                      [ngValue]="p.subscriptionTypeId"
                    >
                      {{ p.subscriptionTypeDesc }}
                    </option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      subscripForm.controls.selectedSubscription.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        subscripForm.controls.selectedSubscription.errors
                          .required
                      "
                    >
                      Status Profile is required
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <label class="form-check-label" style="margin: 20px">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        style="height: 20px; width: 20px"
                        formControlName="subscriptionAutoRenewal"
                      />
                      Subscription Auto Renewal
                    </label>
                  </div>
                  <div
                    *ngIf="
                      submitted &&
                      subscripForm.controls.subscriptionAutoRenewal.errors
                    "
                    class="text-danger"
                  >
                    <div
                      *ngIf="
                        subscripForm.controls.subscriptionAutoRenewal.errors
                          .required
                      "
                    >
                      Subscription auto renewal is required
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-primary mr-2">
                  Submit
                </button>
                <button class="btn btn-light" data-dismiss="modal">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
