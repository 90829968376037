<div @fade class="row">
    <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        <h4 class="card-title">View Transaction Info</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="card-title" style="float: right;">
                            <button type="submit" class="btn btn-primary" (click)="backToService()">Back to Payment</button>
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form class="forms-sample" [formGroup]="updatesForm" novalidate
                        (ngSubmit)="OnUpdatePaymentProcessorInfo()">
                        <div class="card-subtitle">Bank Info</div>
                        <div style="border: 1px solid #009e44; margin-bottom: 10px; background: #009e44;"></div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Payment Processor Id</label>
                                    <input type="text" class="form-control" formControlName="paymentProcessorId"
                                        placeholder="Payment Processor Id" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.paymentProcessorId.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.paymentProcessorId.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.paymentProcessorId.errors.required">Payment Processor Id
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">User Title</label>
                                    <input type="text" class="form-control" formControlName="userTitle"
                                        placeholder="user Title" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.userTitle.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.userTitle.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.userTitle.errors.required">userTitle is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Payment Processor Type Desc</label>
                                    <input type="text" class="form-control" formControlName="paymentProcessorTypeDesc"
                                        placeholder="First Name" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.paymentProcessorTypeDesc.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.paymentProcessorTypeDesc.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.paymentProcessorTypeDesc.errors.required">Payment Processor Type Desc is
                                            required</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="card-subtitle">Profile Info</div>
                        <div
                            style="border: 1px solid #009e44; margin-bottom: 10px; margin-top: 10px; background: #009e44;">
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="isActive">First Name</label>
                                    <input type="text" class="form-control" formControlName="firstName"
                                        placeholder="First Name" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.firstName.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.firstName.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.firstName.errors.required">First Name
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Middle Name</label>
                                    <input type="text" class="form-control" formControlName="middleName"
                                        placeholder="Middle Name" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.middleName.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.middleName.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.middleName.errors.required">Middle Name
                                            is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Last Name</label>
                                    <input type="text" class="form-control" formControlName="lastName"
                                        placeholder="Last Name" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.lastName.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.lastName.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.lastName.errors.required">Last Name is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Email</label>
                                    <input type="email" class="form-control" formControlName="email"
                                        placeholder="Email" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.email.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.email.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.email.errors.required">Email is
                                            required</div>
                                        <div *ngIf="updatesForm.controls.email.errors.email">It`s Not An Email
                                            Plz Check</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Contact Number</label>
                                    <input type="number" class="form-control" formControlName="telephone"
                                        placeholder="Contact Number" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.telephone.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.telephone.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.telephone.errors.required">Contact
                                            Number is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Address Line One</label>
                                    <input type="text" class="form-control" formControlName="address"
                                        placeholder="Address One" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.address.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.address.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.address.errors.required">Address One is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Address Line Two</label>
                                    <input type="text" class="form-control" formControlName="address2"
                                        placeholder="Address Two" readonly
                                        [ngClass]="{ 'is-invalid': submitted && updatesForm.controls.address2.errors }">
                                    <div *ngIf="submitted && updatesForm.controls.address2.errors"
                                        class="text-danger">
                                        <div *ngIf="updatesForm.controls.address2.errors.required">Address Two
                                            is required</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
