import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';

@Component({
  selector: 'app-view-info',
  templateUrl: './view-info.component.html',
  styleUrls: ['./view-info.component.css'],
  animations: [
    fade
  ]
})
export class ViewInfoComponent implements OnInit {
  updatesForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  querypaymentProcessorId: number;
  paymentProcessorId: any;
  dataRecords: any;
  sub: Subscription;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.updateForm();
    this.getPaymentProcessorType();
    this.getPaymentProcessorSystem();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.querypaymentProcessorId = +params['paymentProcessorId'];
        if (Number.isNaN(this.querypaymentProcessorId)) {
          this.paymentProcessorId = "";
        } else {
          this.paymentProcessorId = this.querypaymentProcessorId;
          this.viewPaymentProcessorInfo(this.paymentProcessorId)
        }
      });
  }
  backToService(){
    this._location.back();
  }
  updateForm() {
    this.updatesForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', Validators.required],
      address: ['', Validators.required],
      userTitle: ['', Validators.required],
      address2: ['', Validators.required],
      website: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      paymentProcessorTypeId: ['', Validators.required],
      systemId: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  viewPaymentProcessorInfo(paymentProcessor){
    let payload: any = {
      paymentProcessorId: paymentProcessor,
    };
    this.service.getPaymentProcessorInfo({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.updatesForm = this.fb.group({
          firstName: [data.firstName, Validators.required],
          middleName: [data.middleName, Validators.required],
          lastName: [data.lastName, Validators.required],
          email: [data.email, [Validators.required, Validators.email]],
          telephone: [data.telephone, Validators.required],
          address: [data.address, Validators.required],
          userTitle: [data.userTitle, Validators.required],
          address2: [data.address2, Validators.required],
          website: [data.website, Validators.required],
          city: [data.city, Validators.required],
          country: [data.country, Validators.required],
          paymentProcessorTypeId: [data.paymentProcessorTypeId, Validators.required],
          systemId: [data.systemId, Validators.required]
        });
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  getPaymentProcessorType(){
    let payload: any = {};
    this.service.getPmtProcessorTypes({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectProcessorTypeData =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  getPaymentProcessorSystem(){
    let payload: any = {};
    this.service.getPmtProcessorSystemList({payload: payload}).subscribe(successResponse => {
      if(successResponse.records){
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        var records = JSON.parse(data);
        this.selectPaymentProcessorSystemData =records;
        // console.log("kkkk", records)
      }else {
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if(failureResponse.status === 401){
        window.location.href = 'login';
      }else{
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }
  OnUpdatePaymentProcessorInfo(){
    this.submitted = true;
 
    if (this.updatesForm.invalid) {
        return;
    }
  }

}
