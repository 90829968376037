/** App Config File  */
export const environment = {
  production: true,
  cryptoKey: 'pggui@safaripay2018',
  url: '/v1/admin',
  ico: 'waafipay-ico.png',   // waafipay.png - ebirr.png
  nav: 'waafipay-nav.png', // waafipay-nav.png - ebirr-nav.png
  tab: 'waafipay-tab.png', //waafipay-tab.png - ebirr-tab.png
  timeLockedScreen: 180000,
};
