import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
declare var $: any;
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { ServiceService } from "../../../../../service/service/service.service";
import { SessionStorageService } from "../../../../../service/session-storage/session-storage.service";
import { fade } from 'src/app/animations/animations';
import { SpinnerService } from 'src/app/service/service/spinner.service';

@Component({
  selector: 'app-view-bank',
  templateUrl: './view-bank.component.html',
  styleUrls: ['./view-bank.component.css'],
  animations: [
    fade
  ]
})
export class ViewBankComponent implements OnInit {
  serviceForm: FormGroup;
  submitted = false;
  selectProcessorTypeData;
  selectPaymentProcessorSystemData;
  queryBankId: number;
  BankId: any;
  dataRecords: any;
  sub: Subscription;
  constructor(
    private service: ServiceService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private _location: Location,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private _sessionStorageService: SessionStorageService
  ) {
    this.createForm();
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.queryBankId = +params['bankId'];
        if (Number.isNaN(this.queryBankId)) {
          this.BankId = "";
        } else {
          this.BankId = this.queryBankId;
          this.viewBankInfo(this.BankId)
        }
      });
  }

  createForm() {
    this.serviceForm = this.fb.group({
      selectedPaymentProcessorValue: ['', Validators.required],
      bankId: ['', Validators.required],
      address: ['', Validators.required],
      bankName: ['', Validators.required],
      bankCode: ['', Validators.required],
    });
  }

  viewBankInfo(bankId) {
    let payload: any = {
      bankId: bankId
    };
    this.spinner.getSpinner();
    this.service.getBankInfo({ payload: payload }).subscribe(successResponse => {
      if (successResponse.records) {
        this.spinner.hideSpinner();
        var responseObj = JSON.stringify(successResponse.records);
        var data = JSON.parse(responseObj);
        this.serviceForm = this.fb.group({
          selectedPaymentProcessorValue: [data.paymentProcessorId, Validators.required],
          bankId: [data.bankId, Validators.required],
          address: [data.address, Validators.required],
          bankName: [data.bankName, Validators.required],
          bankCode: [data.bankCode, Validators.required],
        });
        // console.log("kkkk", data)
      } else {
        this.spinner.hideSpinner();
        this.toastr.error("Something went wrong")
      }
    }, (failureResponse: any) => {
      if (failureResponse.status === 401) {
        window.location.href = 'login';
      } else {
        this.spinner.hideSpinner();
        console.log("onFailure:" + JSON.stringify(failureResponse));
      }
    });
  }

  backToService() {
    this._location.back();
  }

  ngOnInit(): void {
  }

  OnUpdateBankInfo() {
    if (this.serviceForm.invalid) {
      return;
    }
    let payload: any = {
      bankId: this.serviceForm.value.bankId,
      address: this.serviceForm.value.address,
      paymentProcessorId: this.serviceForm.value.selectedPaymentProcessorValue,
      bankName: this.serviceForm.value.bankName,
      bankCode: this.serviceForm.value.bankCode
    };
    this.spinner.getSpinner();
    this.service.updateBankInfo(payload).subscribe(successResponse => {
      var responseObj = JSON.stringify(successResponse);
      var data = JSON.parse(responseObj);
      if(data.bankId){
        this.spinner.hideSpinner();
        this.toastr.success('You updated', 'Successfuly');
        this._location.back();
      }else{
        this.spinner.hideSpinner();
        this.toastr.error('Something went wron', 'Error');
      }
      // console.log("HAAA=> ", data)
    }, (failureResponse: string) => {
      this.spinner.hideSpinner();
      console.log("onFailure:" + JSON.stringify(failureResponse));
    });
  }
}
